import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { View } from '../../../commons/components/Themed';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { TweetItem, generateRandomTweets } from '../../../commons/utils/gameboard/tweets';
import ThreadTweets from './ThreadTweet';

interface TweetsPositionProps {
  nativeID: PlayerRoundEnum;
}
const TweetsPosition = ({ nativeID }: TweetsPositionProps) => {
  const { rw } = useRatioDimensions();
  const isOpponent = nativeID !== PlayerRoundEnum.PLAYER;
  const [tweets] = useState<TweetItem[]>(generateRandomTweets);

  const styles = StyleSheet.create({
    messageContainer: {
      position: 'absolute',
      top: nativeID === PlayerRoundEnum.OPPONENT ? 0 : undefined,
      bottom: nativeID === PlayerRoundEnum.PLAYER ? 0 : undefined,
      width: rw(7),
      height: rw(7)
    }
  });

  return (
    <View pointerEvents="none" style={styles.messageContainer}>
      <ThreadTweets isOpponent={isOpponent} tweets={tweets} nativeID={nativeID} />
    </View>
  );
};
export default TweetsPosition;
