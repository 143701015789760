import { Actor, ActorEnum } from '../../commons/dtos/actor.dto';

export const actors: Actor[] = [
  {
    key: ActorEnum.DATA_DIRECTOR,
    name: 'Directora Data',
    color: 'red',
    nickname: 'dDATA',
    twitter: 'dData'
  },
  {
    key: ActorEnum.PLAYER,
    name: 'Player',
    color: 'red',
    nickname: 'player',
    twitter: 'player'
  },
  {
    key: ActorEnum.AGENT_F,
    name: 'Agente F',
    color: 'red',
    nickname: 'agentF_',
    twitter: 'agentF'
  },
  {
    key: ActorEnum.AGENT_W,
    name: 'Agente W',
    color: 'red',
    nickname: '_agentW',
    twitter: 'agent_W'
  },
  {
    key: ActorEnum.MOM,
    name: 'Mamá',
    color: 'red',
    nickname: 'mommom',
    twitter: 'moM'
  },
  {
    key: ActorEnum.DAD,
    name: 'Papá',
    color: 'red',
    nickname: 'dadd',
    twitter: 'daD'
  },
  {
    key: ActorEnum.CAT,
    name: 'Alfredo',
    color: 'red',
    nickname: 'miauFred',
    twitter: 'miauuuF'
  },
  {
    key: ActorEnum.BOT_M4L0,
    name: 'Bot M4l0',
    color: 'green',
    nickname: 'M4l0',
    twitter: 'B_M4l0'
  },
  {
    key: ActorEnum.BOT_BU3N0,
    name: 'Bot Bu3n0',
    color: 'green',
    nickname: 'Bu3n0',
    twitter: 'Bu3n0_'
  },
  {
    key: ActorEnum.FAKER_8000,
    name: 'F-8000',
    color: 'red',
    nickname: 'F-8000',
    twitter: 'B_F8000'
  },
  {
    key: ActorEnum.COPYFAKE_BOT,
    name: 'CopyFake Bot',
    color: 'red',
    nickname: 'cOpYfakE',
    twitter: 'cOpYfakE'
  },
  {
    key: ActorEnum.MANOLO_UNCLE,
    name: 'Tío Manolo',
    color: 'red',
    nickname: 'manu',
    twitter: 'manUnClE'
  },
  {
    key: ActorEnum.CHATO,
    name: 'El Chato',
    color: 'red',
    nickname: 'chato',
    twitter: 'elChatO'
  },
  {
    key: ActorEnum.NEGANA,
    name: 'Doctora Negana',
    color: 'red',
    nickname: 'DNegana',
    twitter: 'Dr_Negana'
  },
  {
    key: ActorEnum.POPULETAS,
    name: 'El Populetas',
    color: 'red',
    nickname: 'populetaSS',
    twitter: 'populetaSS'
  },
  {
    key: ActorEnum.ANGRY_MASS,
    name: 'Masa Enfurecida',
    color: 'red',
    nickname: 'furyfury',
    twitter: 'furyFury'
  },
  {
    key: ActorEnum.INFOLISTO,
    name: 'Infolisto',
    color: 'red',
    nickname: 'infolisto',
    twitter: 'infOlistO'
  },
  {
    key: ActorEnum.ROSAURA,
    name: 'Rosaura',
    color: 'red',
    nickname: 'rosaura_',
    twitter: 'rosaura_'
  },
  {
    key: ActorEnum.KONSPIRATRON,
    name: 'Konspiratrón',
    color: 'red',
    nickname: '_konspiratrón',
    twitter: 'Konspiratrón'
  },
  {
    key: ActorEnum.HATER,
    name: 'Hater',
    color: 'red',
    nickname: 'hater_Hater',
    twitter: 'hater_Hater'
  },
  {
    key: ActorEnum.METEOR,
    name: 'Meteor',
    color: 'red',
    nickname: 'meteor',
    twitter: 'MeteoR'
  },
  {
    key: ActorEnum.DIOXI_DOXI,
    name: 'Dioxi Doxi',
    color: 'red',
    nickname: 'dOXI',
    twitter: 'doxidoxi'
  },
  {
    key: ActorEnum.DORA_PIRAT,
    name: 'Pirata Dora',
    color: 'red',
    nickname: 'xDora',
    twitter: 'pirateDora'
  },
  {
    key: ActorEnum.MININOR,
    name: 'Míninor',
    color: 'red',
    nickname: 'míninoRR',
    twitter: 'míniNORR'
  },
  {
    key: ActorEnum.FELINOR,
    name: 'Félinor',
    color: 'red',
    nickname: 'felinoRR',
    twitter: 'felinoRR'
  },
  {
    key: ActorEnum.TROLL_PARENTS,
    name: 'Mamá y Papá Trol',
    color: 'red',
    nickname: 'trollParents',
    twitter: 'trollParents'
  },
  {
    key: ActorEnum.AGENT_F_F_EVIL,
    name: 'Agente F',
    color: 'red',
    nickname: 'agentF_',
    twitter: 'agentF'
  },
  {
    key: ActorEnum.AGENT_F_M_EVIL,
    name: 'Agente F',
    color: 'red',
    nickname: 'agentF_',
    twitter: 'agentF'
  }
];
