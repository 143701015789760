/* eslint-disable camelcase */
import * as AuthSession from 'expo-auth-session';
import { GoogleAuthRequestConfig, Prompt } from 'expo-auth-session';
import { useAuthRequest } from 'expo-auth-session/providers/google';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { GoogleAccessTokenResponse, Player, PlayerTypesEnum } from '../commons/dtos/player.dto';
import { ApiError } from '../commons/errors/api-error';
import { User, actions } from '../redux/Auth/authRedux';
import LoginService from '../services/login.service';

export interface JWT extends JwtPayload {
  email: string;
}

export interface GoogleLoginInfo {
  result: AuthSession.AuthSessionResult;
  exchangeInfo?: GoogleAccessTokenResponse;
}

const loginService = new LoginService();

const googleOauth = process.env.GOOGLE_OAUTH_CLIENT_ID ?? GOOGLE_OAUTH_CLIENT_ID;

export const useGoogle = () => {
  const googleConfig: Partial<GoogleAuthRequestConfig> = {
    expoClientId: googleOauth,
    androidClientId: googleOauth,
    webClientId: googleOauth,
    scopes: ['profile', 'email', 'openid'],
    responseType: 'code',
    shouldAutoExchangeCode: false,
    prompt: Prompt.SelectAccount,
    extraParams: {
      access_type: 'offline'
    },
    redirectUri: Platform.OS !== 'web' && __DEV__ ? process.env.FAKES_OVER_REDIRECT_URI ?? FAKES_OVER_REDIRECT_URI : undefined
  };

  const dispatch = useDispatch();

  const [request, responseToken, promptAsync] = useAuthRequest(googleConfig);

  const signInGoogle = async (): Promise<GoogleLoginInfo | null> => {
    try {
      const result = await promptAsync();
      const { type } = result;
      if (type !== 'success' || !request) return null;
      const exchangeInfo = await loginService.googleAccessToken(result.params.code, request.redirectUri, request.codeVerifier!);
      return { result, exchangeInfo } as GoogleLoginInfo;
    } catch (error) {
      throw new Error(`Error signing in: ${error as Error}`);
    }
  };

  const getCredentials = (result: GoogleLoginInfo): User | null => {
    const { result: loginResult, exchangeInfo } = result;
    if (loginResult.type !== 'success') return null;
    const { id_token: idToken, refresh_token: refreshToken } = exchangeInfo!;
    if (!idToken) return null;
    const decodedToken: JWT = jwtDecode(idToken);
    const { email, sub, exp } = decodedToken;
    const newUser: User = {
      email,
      userId: sub ?? '',
      idToken,
      dateBorn: undefined,
      refreshToken: refreshToken ?? '',
      expires: exp ?? 0,
      type: PlayerTypesEnum.USER
    };
    dispatch(actions.setUser(newUser));
    return newUser;
  };

  const userExists = async (email: string): Promise<{ player: Player | null }> => {
    try {
      const player = await loginService.getPlayerByEmail(email);
      return player ? { player } : { player: null };
    } catch (error: any) {
      if (error instanceof ApiError) {
        if (error?.httpCode !== 404) {
          throw new Error(`Error signing in: ${error as Error}`);
        }
      }
      return { player: null };
    }
  };

  const checkUser = async (result: GoogleLoginInfo): Promise<{ player: Player | null; user: User } | null> => {
    const user = getCredentials(result);
    if (!user) return null;
    const { email } = user;
    const { player } = await userExists(email);
    // Player does not exists
    if (!player) return { player: null, user };
    // Player exists, then login and save their data in storage
    const newUser: User = { ...user, playerId: player.id };
    dispatch(actions.setUser(newUser));
    return { player, user };
  };

  return {
    signInGoogle,
    getCredentials,
    responseToken,
    checkUser,
    userExists
  };
};
