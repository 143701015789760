import React, { useMemo, useState } from 'react';
import { ImageBackground, ScrollView, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { titleBackgroundProfile } from '../../../../constants/Images';
import { Profile } from '../../../../redux/Profile/profileReducer';
import { GendersEnum } from '../../../dtos/player.dto';
import { TitleProperties } from '../../../dtos/title.dto';
import { isNativeOrPWA } from '../../../utils';
import TitleItem from '../TitleItem';

interface UserProfileTitleProps {
  titlesList: TitleProperties[];
  currentTitle: TitleProperties | null | undefined;
  updatePlayerTitle: (item: { title: TitleProperties }) => Promise<void>;
}

const UserProfileTitle = ({ titlesList, currentTitle, updatePlayerTitle }: UserProfileTitleProps) => {
  const player = useSelector(({ profile }: { profile: Profile }) => profile.player);
  const titlesListOrdered = useMemo(
    () =>
      titlesList.sort((a, b) =>
        player?.gender === GendersEnum.FEMALE ? a.femaleText.localeCompare(b.femaleText) : a.maleText.localeCompare(b.maleText)
      ),
    [titlesList]
  );

  const initialSelectedTittle = useMemo(
    () => (currentTitle ? titlesListOrdered.findIndex(({ id }) => id === currentTitle.id) : -1),
    [currentTitle, titlesListOrdered]
  );

  const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedTittle);

  const style = StyleSheet.create({
    container: {
      flexGrow: 1,
      maxWidth: isNativeOrPWA ? 420 : 860,
      minWidth: isNativeOrPWA ? 200 : 600,
      paddingHorizontal: 15,
      alignItems: 'center',
      flex: 1,
      marginBottom: -6
    },
    scrollContainer: {
      paddingHorizontal: isNativeOrPWA ? 20 : 60,
      paddingVertical: isNativeOrPWA ? 20 : 60,
      width: '100%'
    }
  });

  const handleChangeTitle = (titleSelected: TitleProperties): void => {
    const index = titlesListOrdered.findIndex(title => title.id === titleSelected.id);
    if (index < 0) return;
    setSelectedIndex(index);
    updatePlayerTitle({ title: titleSelected });
  };

  return (
    <ImageBackground source={titleBackgroundProfile} style={style.container} resizeMode="stretch">
      <ScrollView style={style.scrollContainer}>
        {titlesListOrdered.map((title, index) => (
          <TitleItem title={title} isChecked={index === selectedIndex} key={index} handleChangeTitle={handleChangeTitle} />
        ))}
      </ScrollView>
    </ImageBackground>
  );
};

export default UserProfileTitle;
