import React from 'react';
import { View, Text, TouchableWithoutFeedback, ImageBackground, StyleSheet, TextStyle, ImageStyle } from 'react-native';
import { useSelector } from 'react-redux';
import { activeTitleBg, checkBoxTitleProfile, checkTitleProfile } from '../../../constants/Images';
import { Profile } from '../../../redux/Profile/profileReducer';
import { Player } from '../../dtos/player.dto';
import { TitleProperties } from '../../dtos/title.dto';
import adjust from '../../styles/TextAdjust';
import { chooseTitle, isNativeMobile } from '../../utils';

interface TitleItemProps {
  isChecked?: boolean;
  title: TitleProperties;
  handleChangeTitle: (title: TitleProperties) => void;
}

interface TitleItemContentProps {
  isChecked?: boolean;
  style: { text: TextStyle; checkboxImage: ImageStyle };
  changeCheck: () => void;
  title: TitleProperties;
  player: Player | undefined;
}

const TitleItem = ({ title, isChecked = false, handleChangeTitle }: TitleItemProps) => {
  const player = useSelector(({ profile }: { profile: Profile }) => profile.player);
  const style = StyleSheet.create({
    itemWrapper: {
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      flexGrow: 1,
      padding: 12,
      paddingHorizontal: isNativeMobile ? 16 : 40
    },
    checkboxImage: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      width: 33,
      height: 33
    },
    text: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      fontWeight: isChecked ? '800' : '500',
      fontSize: adjust(18),
      color: '#FFF'
    }
  });

  const changeCheck = () => handleChangeTitle(title);

  if (isChecked) {
    return (
      <ImageBackground source={activeTitleBg} style={style.itemWrapper} resizeMode="stretch">
        <TitleItemContent
          changeCheck={changeCheck}
          player={player}
          style={{ checkboxImage: style.checkboxImage, text: style.text }}
          title={title}
          isChecked
        />
      </ImageBackground>
    );
  }

  return (
    <View style={style.itemWrapper}>
      <TitleItemContent
        changeCheck={changeCheck}
        player={player}
        style={{ checkboxImage: style.checkboxImage, text: style.text }}
        title={title}
      />
    </View>
  );
};

const TitleItemContent = ({ isChecked = false, style: { checkboxImage, text }, changeCheck, title, player }: TitleItemContentProps) => (
  <>
    <Text style={text}>{chooseTitle(player!.gender!, title)}</Text>
    <TouchableWithoutFeedback onPress={changeCheck}>
      {isChecked ? (
        <ImageBackground source={checkTitleProfile} style={checkboxImage} resizeMode="contain" />
      ) : (
        <ImageBackground source={checkBoxTitleProfile} style={checkboxImage} resizeMode="contain" />
      )}
    </TouchableWithoutFeedback>
  </>
);

export default TitleItem;
