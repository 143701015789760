import { setProfile } from '../../../redux/Profile/profileReducer';
import store from '../../../redux/store';
import CampaignService from '../../../services/campaigns.service';
import LogsService from '../../../services/logs.service';
import PlayerService from '../../../services/player.service';
import TimePlayService from '../../../services/time-play.service';
import { EventLogs } from '../../dtos/create-log.dto';
import type { GameBoardConfiguration } from '../../dtos/game-board-configuration.dto';
import { Mission } from '../../dtos/mission.dto';
import { Level, Progress, MissionProgressStatusEnum } from '../../dtos/progress.dto';
import { divideArrayIntoChunks } from '../../utils';

const logService = new LogsService();
const timePlayService = new TimePlayService();
const playerService = new PlayerService({ isPlaying: true });
const campaignService = new CampaignService({ isPlaying: true });

interface UpdateProgress {
  gameConfig: GameBoardConfiguration;
  handleShowModalTime: () => void;
  round: number;
}

interface UpdateProgressReturn {
  level: Level | undefined;
  gameConfig: GameBoardConfiguration;
  progress: Progress;
}

async function updateProgress({
  gameConfig,
  missionStatus,
  handleShowModalTime,
  round
}: {
  missionStatus: MissionProgressStatusEnum;
  gameConfig: GameBoardConfiguration;
  handleShowModalTime: () => void;
  round: number;
}) {
  await logService.createLog(EventLogs.END_GAME);
  await timePlayService.handleAlert(EventLogs.END_GAME, handleShowModalTime);
  const { id, opponent } = gameConfig;
  if (!id) return null;
  const profile = await playerService.updateProgress(id, missionStatus, opponent?.actionPoints, Math.floor((round + 1) / 2));
  if (profile) store.dispatch(setProfile(profile));
  return profile;
}

export async function updateProgressOnFinished({
  isWinner,
  gameConfig,
  handleShowModalTime,
  round
}: UpdateProgress & { isWinner: boolean }) {
  const { progress } = await playerService.getProfile();
  if (!gameConfig) return false;

  const missionStatus = isWinner ? MissionProgressStatusEnum.COMPLETED : MissionProgressStatusEnum.PLAYED;
  const updatedProfile = await updateProgress({ gameConfig, handleShowModalTime, missionStatus, round });

  const level = updatedProfile && updatedProfile.progress.levelUp ? updatedProfile.progress.level : undefined;
  // 1. A game started and the player wins
  // If the player wins and the mission played is distinct of the profile mission stored then is not replayed.
  if (isWinner && updatedProfile && updatedProfile?.progress.missionsCompleted !== progress.missionsCompleted) {
    const [firstCampaign] = await campaignService.getCampaigns();
    const { missions } = firstCampaign;
    const chunkSize = Math.ceil(missions.length / 10);
    const missionsChunked: Mission[][] = divideArrayIntoChunks(missions, chunkSize);
    const chunkIndexPlayedMission = missionsChunked.findIndex(chunk => chunk.find(mission => mission.id === gameConfig.id));
    const chunkIndexProgressMission = missionsChunked.findIndex(chunk => chunk.find(mission => mission.id === progress.missionId));
    // 2. The chunk of the mission player is the same chunk of the mission stored
    // If played chunk is the same of progress chunk then might not be replayed
    if (chunkIndexPlayedMission === chunkIndexProgressMission) {
      const chunkPlayedMission = missionsChunked[chunkIndexPlayedMission];
      const lastMissionOfChunk = chunkPlayedMission[chunkPlayedMission.length - 1];
      // 3. The mission played is the last mission of the chunk and the event with percentatge must be send.
      if (lastMissionOfChunk.id === gameConfig.id) {
        logService.createLog(EventLogs.PERCENTAGE, (chunkIndexPlayedMission + 1) * 10);
      }
    }
  }

  // remove rewards
  if (progress && (progress.missionId !== gameConfig.id || progress.missionStatus == MissionProgressStatusEnum.COMPLETED)) {
    gameConfig.rewards = [];
  }
  return {
    gameConfig,
    progress,
    level
  };
}

export async function updateProgressOnSurrendered({
  gameConfig,
  handleShowModalTime,
  round
}: UpdateProgress): Promise<UpdateProgressReturn | null> {
  const profile = await updateProgress({ gameConfig, handleShowModalTime, missionStatus: MissionProgressStatusEnum.SURRENDERED, round });
  if (!profile) return null;
  const { progress } = profile;
  const { levelUp } = progress;
  const level = levelUp ? profile.progress.level : undefined;
  gameConfig.rewards = [];
  return {
    gameConfig,
    progress,
    level
  };
}
