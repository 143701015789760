import { useEffect, useState } from 'react';
import { StyleSheet, TouchableHighlight, ImageBackground, Image, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import LegalFooter from '../../commons/components/LegalFooter';
import SnackBar from '../../commons/components/SnackBar';
import { Text, View } from '../../commons/components/Themed';
import ToolTip from '../../commons/components/ToolTip';
import TopHeader from '../../commons/components/TopHeader';
import { ApiError } from '../../commons/errors/api-error';
import adjust from '../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../commons/utils';
import { borderCampaign, borderFreeGame, borderHomeHover, homeAgent, homeBackground, homeBot } from '../../constants/Images';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { setLoadingMissionBackground } from '../../redux/Loading-mission/loadingMissionBackground';
import { Profile, setProfile } from '../../redux/Profile/profileReducer';
import store from '../../redux/store';
import CampaignService from '../../services/campaigns.service';
import PlayerService from '../../services/player.service';
import { HomeProps } from '../../types';

const Home = ({ navigation }: HomeProps) => {
  const [hoverCampaign, setHoverCampaign] = useState<boolean>(false);
  const [hoverFreeGame, setHoverFreeGame] = useState<boolean>(false);
  const [toolTip, setToolTip] = useState(false);
  const [canPlayFreeGame, setCanPlayFreeGame] = useState<boolean>(false);
  const { vh, vw } = useUnits();

  const { player } = useSelector(({ profile }: { profile: Profile }) => profile);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  useEffect(() => {
    const playerService = new PlayerService();
    playerService
      .getProfile()
      .then(profile => store.dispatch(setProfile(profile)))
      .catch((err: any) => {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando el perfil del usuario`, `[Detalle]: ${ex.detail}`);
        } else {
          setMessage(`Error: ${err}`);
        }
      });
  }, [checkShowLoginModal]);

  useEffect(() => {
    const checkCanPlayFreeGame = async () => {
      const campaignService = new CampaignService();
      const [firstCampaign] = await campaignService.getCampaigns();
      const numberOfMissions = firstCampaign.missions.length;
      if (!player?.progress?.missionsCompleted) {
        return false;
      }
      return player?.progress?.missionsCompleted >= numberOfMissions;
    };
    checkCanPlayFreeGame()
      .then(result => setCanPlayFreeGame(result ?? false))
      .catch((err: any) => {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando las misiones`, `[Detalle]: ${ex.detail}`);
        } else {
          setMessage(`Error: ${err}`);
        }
      });

    store.dispatch(setLoadingMissionBackground(undefined));
  }, [checkShowLoginModal]);

  const goCampaign = () => navigation.navigate('Campaign');

  const goDeckSelector = () => (canPlayFreeGame ? navigation.navigate('DeckSelector') : setToolTip(true));

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'relative',
      paddingHorizontal: 10
    },
    homeAgent: {
      position: 'absolute',
      bottom: 10,
      left: 0,
      height: isNativeOrPWA ? vh(80) : vh(85),
      width: vw(35)
    },
    homeBot: {
      position: 'absolute',
      bottom: 10,
      right: 0,
      height: isNativeOrPWA ? vh(80) : vh(85),
      width: vw(35)
    },
    image: {
      width: '100%',
      height: '100%'
    },
    veil: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.55)'
    },
    mainContent: {
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: Platform.OS === 'ios' ? vh(70) : '100%'
    },
    buttonsContainer: {
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      width: isNativeOrPWA ? '50%' : '40%',
      maxWidth: isNativeOrPWA ? (Platform.OS === 'ios' ? 340 : 300) : 600,
      minWidth: isNativeOrPWA ? 280 : 360
    },
    button: {
      flexGrow: 1,
      flexShrink: 1,
      height: '50%',
      width: '100%',
      maxHeight: 240,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    borderImage: {
      width: '100%',
      height: '100%',
      minHeight: 110,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textButton: {
      color: '#FFF',
      fontSize: adjust(34),
      fontWeight: '800'
    },
    toolTipText: { color: 'black', fontWeight: 'bold' },
    footerContainer: {
      alignItems: 'center'
    },
    disabled: {
      opacity: 0.2
    }
  });

  return (
    <ImageBackground style={styles.container} source={homeBackground} resizeMode="cover">
      <View pointerEvents="none" style={styles.homeAgent}>
        <Image style={styles.image} resizeMode="contain" source={homeAgent} accessible accessibilityLabel="agent image" />
      </View>
      <View pointerEvents="none" style={styles.homeBot}>
        <Image style={styles.image} resizeMode="contain" source={homeBot} accessible accessibilityLabel="bot image" />
      </View>
      <View style={styles.veil} />
      <TopHeader />
      <View style={styles.mainContent}>
        <View style={styles.buttonsContainer}>
          <TouchableHighlight
            accessible
            accessibilityLabel="Campaign"
            style={styles.button}
            onPressIn={() => setHoverCampaign(true)}
            onPressOut={() => setHoverCampaign(false)}
            onPress={() => goCampaign()}
            underlayColor="transparent"
          >
            <ImageBackground style={styles.borderImage} source={hoverCampaign ? borderHomeHover : borderFreeGame} resizeMode="stretch">
              <Text style={styles.textButton}>Modo Historia</Text>
            </ImageBackground>
          </TouchableHighlight>
          <TouchableHighlight
            accessible
            accessibilityLabel="Free play"
            style={styles.button}
            onPressIn={() => setHoverFreeGame(true)}
            onPressOut={() => setHoverFreeGame(false)}
            onPress={goDeckSelector}
            onBlur={() => setToolTip(false)}
            underlayColor="transparent"
          >
            <>
              {toolTip && (
                <ToolTip>
                  <Text style={styles.toolTipText}>Finaliza el Modo Historia para poder acceder a este modo de juego</Text>
                </ToolTip>
              )}
              <ImageBackground
                style={canPlayFreeGame ? styles.borderImage : [styles.disabled, styles.borderImage]}
                source={hoverFreeGame ? borderHomeHover : borderCampaign}
                resizeMode="stretch"
              >
                <Text style={styles.textButton}>Juego Libre</Text>
              </ImageBackground>
            </>
          </TouchableHighlight>
        </View>
        <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
      </View>
      <View style={styles.footerContainer}>
        <LegalFooter />
      </View>
    </ImageBackground>
  );
};

export default Home;
