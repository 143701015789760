import { StyleProp, View, ViewStyle } from 'react-native';
import { Card as CardType } from '../../../dtos/card.dto';
import { Shadow } from '../../../dtos/shadow.dto';
import { TagsEnum } from '../../../dtos/tag.dto';
import Actions from '../Actions';
import AttackAndDefense from '../AttackAndDefense';
import { CardActionPoints, CardBackground, CardName, CardSubject } from '../Card';
import Tags from '../Tags';
import { useCardStyles } from '../useCardStyles';
import { CardSizesEnum } from '../utils';
import { useCardEnhace } from './useCardEnhance';

interface BattleCardProps {
  card: CardType;
  cardSize: CardSizesEnum;
  conditionalStyles?: StyleProp<ViewStyle>;
  damage: number;
  shadow: Shadow;
}

const BattleCard = ({ card, cardSize, conditionalStyles, damage, shadow }: BattleCardProps) => {
  const { tags, damagePoints, defensePoints, enhance } = card;
  const hasAction = card?.rules?.[0];

  const enhancePointsResult = useCardEnhace({ enhance });

  const { styles } = useCardStyles(card, cardSize, !!hasAction);

  const showDefensePoints = defensePoints !== null && defensePoints >= 0;

  const hasTags = tags?.some(tag => tag.tagName === TagsEnum.IMPACT || tag.tagName === TagsEnum.FACTCHECK);

  const playableHandCardShadow = conditionalStyles;

  return (
    <View pointerEvents="none" style={[styles.container, playableHandCardShadow, shadow]}>
      <CardBackground card={card} />
      <CardName card={card} cardSize={cardSize} />
      <CardSubject card={card} cardSize={cardSize} />
      <CardActionPoints card={card} cardSize={cardSize} />
      <AttackAndDefense
        card={card}
        cardSize={cardSize}
        iconType="defense"
        points={damage! < 0 ? 0 : damage!}
        enhancePointsResult={{
          damagePoints: enhancePointsResult.damagePoints,
          defensePoints: damage! === defensePoints ? 0 : -1
        }}
      />
      {showDefensePoints && (
        <AttackAndDefense
          card={card}
          cardSize={cardSize}
          iconType="damage"
          points={damagePoints || 0}
          enhancePointsResult={enhancePointsResult}
        />
      )}
      <View style={styles.actionCardContainer}>
        {hasTags && <Tags card={card} />}
        {hasAction && <Actions card={card} />}
      </View>
    </View>
  );
};

export default BattleCard;
