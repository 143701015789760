import LottieView from 'lottie-react-native';
import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import type { Card as CardType } from '../../../commons/dtos/card.dto';
import boardCardsUtils from '../../../commons/utils/gameboard/boardCards';
import { lottieThunder } from '../../../constants/Lotties';
import GameboardModal from '../Modal/GameboardModal';
import CardBattleSide from './CardBattleSide';
interface CardsBattleModalModalProps {
  cards: [CardType, CardType];
}

const CardsBattleModal = ({ cards }: CardsBattleModalModalProps) => {
  const [showFire, setShowFire] = useState(false);

  const [attackingCard, attackedCard] = cards;

  const styles = StyleSheet.create({
    lottieEffect: {
      zIndex: 999,
      position: 'absolute',
      width: '100%',
      margin: '0.5%',
      height: '100%',
      backgroundColor: 'transparent',
      elevation: 25
    }
  });

  useEffect(() => {
    const to = setTimeout(() => setShowFire(true), 500);
    const toStop = setTimeout(() => setShowFire(false), 2500);
    return () => {
      clearTimeout(to);
      clearTimeout(toStop);
    };
  }, []);

  const attackingBattlePoints = boardCardsUtils.getBattlePoints(attackedCard, attackingCard);
  const attackedBattlePoints = boardCardsUtils.getBattlePoints(attackingCard, attackedCard);

  return (
    <GameboardModal lotttie={showFire && <LottieView autoPlay style={styles.lottieEffect} source={lottieThunder} />}>
      <CardBattleSide
        card={attackingCard}
        battlePoints={attackingBattlePoints}
        damagePoints={attackedCard.damagePoints as number}
        showFire={showFire}
      />
      <CardBattleSide
        card={attackedCard}
        battlePoints={attackedBattlePoints}
        damagePoints={attackingCard.damagePoints as number}
        showFire={showFire}
      />
    </GameboardModal>
  );
};

export default CardsBattleModal;
