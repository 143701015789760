import { Video, ResizeMode } from 'expo-av';
import { LinearGradient } from 'expo-linear-gradient';
import { useRef } from 'react';
import { ImageBackground, Text, View } from 'react-native';
import useUnits from 'rxn-units';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard, tutorialPoster } from '../../../../constants/Images';
import { tutorialAttack } from '../../../../constants/Videos';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import BotBlock from '../BotBlock/BotBlock';
import { useStylesHow } from '../useStylesHow';

const HowSectionAttack = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();
  const video = useRef(null);
  const { vh } = useUnits();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <View style={[styles.splitContainer, isNativeOrPWA && { maxWidth: '90%', marginHorizontal: 'auto' }]}>
        <View style={[styles.splitView, isNativeOrPWA && { flexBasis: '60%' }, { position: 'relative', zIndex: 1 }]}>
          <BotBlock small withBot={false} title={howTitle['step-5-title']}>
            <Text style={styles.paragraph}>
              <TextWithHTML text={howText['step-5-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
            </Text>
          </BotBlock>
        </View>
        <View style={[styles.splitView, isNativeOrPWA && { flexBasis: '40%' }]}>
          <Video
            ref={video}
            style={[styles.video, { maxHeight: vh(60) }]}
            source={tutorialAttack}
            useNativeControls={false}
            isMuted
            shouldPlay
            posterSource={tutorialPoster}
            // videoStyle makes work the resize mode
            videoStyle={{ position: 'relative' }}
            resizeMode={ResizeMode.CONTAIN}
            isLooping
          />
        </View>
      </View>
    </ImageBackground>
  );
};

export default HowSectionAttack;
