import { useEffect, useRef } from 'react';
import { View, Image, Animated, ViewStyle, StyleSheet, Platform } from 'react-native';
import { astronaut, starcraft } from '../../../constants/Images';

interface OvniWithAstronautProps {
  parentStyle: ViewStyle;
}

const OvniWithAstronaut = ({ parentStyle }: OvniWithAstronautProps) => {
  const translation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const animationStart = Animated.timing(translation, {
      toValue: -10,
      duration: 1000,
      useNativeDriver: Platform.OS !== 'web'
    });

    const animationEnd = Animated.timing(translation, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: Platform.OS !== 'web'
    });

    const sequence = Animated.sequence([animationStart, animationEnd]);
    const loop = Animated.loop(sequence);
    loop.start();
  }, []);

  const styles = StyleSheet.create({
    starcraft: { position: 'absolute', top: 0, height: '100%', width: '100%', zIndex: 2100 },
    astronaut: { position: 'absolute', top: 0, height: '100%', width: '100%', zIndex: 2200 }
  });

  return (
    <View style={parentStyle}>
      <Image style={styles.starcraft} source={starcraft} resizeMode="contain" />
      <Animated.View style={[styles.astronaut, { transform: [{ translateY: translation }] }]}>
        <Image style={styles.astronaut} source={astronaut} resizeMode="contain" />
      </Animated.View>
    </View>
  );
};

export default OvniWithAstronaut;
