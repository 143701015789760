import { ReactNode } from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Path } from 'react-native-svg';

interface PolyShapeProps {
  children: ReactNode;
  bgColor: string;
  type?: 1 | 2 | 3;
  isPointeable?: boolean;
}

const PolyShape = ({ children, bgColor, type = 1, isPointeable = true }: PolyShapeProps) => {
  let path = 'M13.359 80L0 66.154V6.924L25.763 0h336.832L375 10.77v45.384L343.511 80H13.359z';
  if (type === 2) {
    path =
      'M0.852905 46.5135L4.61528 26.3483L16 3.83834L46 0.0866699L87.5 3.83834L121.5 0.0866699L154 3.83834L221.5 0.0866699L269.692 7.35552L280.637 0.0866699H337.073L371.618 3.83834L380.853 26.3483V50.7342L366.595 71.7127L340.638 80.0867L300.741 75.0706L242.095 78.0437L226.361 71.7127L215.758 78.0437H191.474L101 80.0867L21.6532 76.0939L9.06173 69.0235L0.852905 46.5135Z';
  } else if (type === 3) {
    path = 'M97.1151 80L380.558 71.6418L364.984 5.97015L165.64 -1.87887e-05L0.557685 5.97012L19.2462 63.2836L97.1151 80Z';
  }

  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      height: '100%',
      width: '100%',
      flexGrow: 0,
      flexShrink: 0,
      cursor: isPointeable ? 'pointer' : 'auto',
      flexBasis: 'auto'
    },
    childrenContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  return (
    <View style={styles.container}>
      <Svg preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 380 80" fill="none">
        <Path d={path} fill={bgColor} />
      </Svg>
      <View style={styles.childrenContainer}>{children}</View>
    </View>
  );
};

export default PolyShape;
