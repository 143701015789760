import { Card } from '../../dtos/card.dto';
import { ReactionConfiguration } from '../../dtos/reaction-configuration.dto';
import {
  ReactionsManagementProps,
  isCardTarget,
  isCurrentPlayerPlaying,
  isCurrentPlayerTarget,
  isCurrentRound,
  isEnoughLifePointsDamaged,
  isEnoughLifePointsHit,
  isNotDone,
  isThresholdExceeded,
  setIsDone
} from './reactions-validators';

export function filterRoundStartDialogues(reactionsManagementProps: ReactionsManagementProps): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue =
      isCurrentRound(reactionsManagementProps.round, reactionConfig) &&
      isCurrentPlayerPlaying(reactionsManagementProps, reactionConfig) &&
      isNotDone(reactionConfig);
    setIsDone(showDialogue, reactionConfig);
    return showDialogue;
  });
}

export function filterPlayCardDialogues(reactionsManagementProps: ReactionsManagementProps, card?: Card): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue =
      isCurrentRound(reactionsManagementProps.round, reactionConfig) &&
      isCardTarget(reactionConfig, card!) &&
      isCurrentPlayerPlaying(reactionsManagementProps, reactionConfig) &&
      isNotDone(reactionConfig);
    setIsDone(showDialogue, reactionConfig);
    return showDialogue;
  });
}

export function filterDestroyCardDialogues(
  reactionsManagementProps: ReactionsManagementProps,
  card?: Card,
  player?: number
): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue =
      isCurrentRound(reactionsManagementProps.round, reactionConfig) &&
      isCardTarget(reactionConfig, card!) &&
      isCurrentPlayerTarget(reactionConfig, player!) &&
      isNotDone(reactionConfig);
    setIsDone(showDialogue, reactionConfig);
    return showDialogue;
  });
}

export function filterCardDestroyedDialogues(
  reactionsManagementProps: ReactionsManagementProps,
  card?: Card,
  player?: number
): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue =
      isCurrentRound(reactionsManagementProps.round, reactionConfig) &&
      isCardTarget(reactionConfig, card!) &&
      isCurrentPlayerTarget(reactionConfig, player!) &&
      isNotDone(reactionConfig);
    setIsDone(showDialogue, reactionConfig);
    return showDialogue;
  });
}

export function filterDamageCardDialogues(
  reactionsManagementProps: ReactionsManagementProps,
  card?: Card,
  player?: number
): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue =
      isCurrentRound(reactionsManagementProps.round, reactionConfig) &&
      isCardTarget(reactionConfig, card!) &&
      isCurrentPlayerTarget(reactionConfig, player!) &&
      isEnoughLifePointsHit(reactionConfig, card!) &&
      isNotDone(reactionConfig);
    setIsDone(showDialogue as boolean, reactionConfig);
    return showDialogue;
  });
}

export function filterCardDamagedDialogues(
  reactionsManagementProps: ReactionsManagementProps,
  card?: Card,
  player?: number,
  attackingCard?: Card
): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue =
      isCurrentRound(reactionsManagementProps.round, reactionConfig) &&
      isCardTarget(reactionConfig, card!) &&
      isCurrentPlayerTarget(reactionConfig, player!) &&
      isEnoughLifePointsDamaged(reactionConfig, attackingCard!) &&
      isNotDone(reactionConfig);
    setIsDone(showDialogue as boolean, reactionConfig);
    return showDialogue;
  });
}

export function filterAvatarDamagedDialogues(
  reactionsManagementProps: ReactionsManagementProps,
  card?: Card,
  player?: number
): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue =
      isCurrentRound(reactionsManagementProps.round, reactionConfig) &&
      isCardTarget(reactionConfig, card!) &&
      isCurrentPlayerTarget(reactionConfig, player!) &&
      isEnoughLifePointsHit(reactionConfig, card!) &&
      isThresholdExceeded(reactionsManagementProps, reactionConfig, card!, player!) &&
      isNotDone(reactionConfig);
    setIsDone(showDialogue as boolean, reactionConfig);
    return showDialogue;
  });
}

export function filterWinDialogues(reactionsManagementProps: ReactionsManagementProps): ReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue = isNotDone(reactionConfig);
    setIsDone(showDialogue, reactionConfig);
    return showDialogue;
  });
}
