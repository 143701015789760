import { useEffect } from 'react';
import { ImageBackground, SafeAreaView, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import { actors } from '../../../assets/data/actors';
import { getAvatar } from '../../../commons/components/Dialogues/utils';
import TimeModal from '../../../commons/components/TimeModal/TimeModal';
import { ActorEnum } from '../../../commons/dtos/actor.dto';
import { EventLogs } from '../../../commons/dtos/create-log.dto';
import { GameBoardConfiguration } from '../../../commons/dtos/game-board-configuration.dto';
import { GendersEnum } from '../../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { launchBattleBackground, avatarImage as avatars } from '../../../constants/Images';
import store from '../../../redux/store';
import { showTimeModal } from '../../../redux/Time-advice/timeAdvice';
import LogsService from '../../../services/logs.service';
import TimePlayService from '../../../services/time-play.service';
import { LaunchBattleProps } from '../../../types';
import LaunchBattleSide from './Side/LaunchBattleSide';

const logService = new LogsService();
const timePlayService = new TimePlayService();

const LaunchBattle = ({ navigation, route }: LaunchBattleProps) => {
  const { gameConfig } = route.params;

  const opponentFirst = !!gameConfig?.opponent?.opponentFirst;

  const { vw } = useUnits();
  const dispatch = useDispatch();

  const showModalTime = () => dispatch(showTimeModal());
  const checkShowBattleModal = useSelector((s: { timeAdvice: { showTimeModal: boolean } }) => s.timeAdvice.showTimeModal);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#1F1F1F',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    contentWrapper: {
      width: vw(50),
      maxWidth: 720,
      justifyContent: 'center'
    },
    rowContainer: {
      flexDirection: 'row'
    },
    contentWrapperRight: {
      alignItems: 'flex-start',
      marginLeft: isNativeOrPWA ? -30 : -50,
      marginTop: isNativeOrPWA ? 20 : 40
    },
    contentWrapperLeft: {
      alignItems: 'flex-end',
      marginRight: isNativeOrPWA ? -30 : -50,
      marginTop: isNativeOrPWA ? -20 : -40
    }
  });

  const {
    profile: { player }
  } = store.getState();

  if (!player) return <></>;

  const { playerName, gender, title } = player;

  const titleText = title && gender === GendersEnum.FEMALE ? title.femaleText : title?.maleText;

  const { opponent } = gameConfig as GameBoardConfiguration;

  const actor = actors.find(actor => actor.key === opponent!.key);

  // TODO: Homogenize names
  const avatarImage =
    gameConfig?.order === 18
      ? avatars[player?.gender === GendersEnum.FEMALE ? ActorEnum.AGENT_F_F_EVIL : ActorEnum.AGENT_F_M_EVIL]
      : avatars[actor?.key || ActorEnum.BOT_M4L0];

  const onStartGame = async () => {
    logService.createLog(EventLogs.START_GAME);
    await timePlayService.handleAlert(EventLogs.START_GAME, showModalTime);

    navigation.navigate('GameBoard', { gameConfig });
    return;
  };

  useEffect(() => {
    const onInitStartGame = setTimeout(() => onStartGame(), 4000);
    return () => clearTimeout(onInitStartGame);
  }, []);

  const playerAvatar = getAvatar(player!.avatar!.avatarImage, player!.gender!, gameConfig?.isFreeGame);

  return (
    <SafeAreaView style={styles.container}>
      {checkShowBattleModal && <TimeModal />}
      <ImageBackground style={styles.backgroundImage} source={launchBattleBackground} resizeMode="cover" />
      <View style={styles.rowContainer}>
        <View style={[styles.contentWrapper, styles.contentWrapperLeft]}>
          <LaunchBattleSide
            position={opponentFirst ? 2 : 1}
            avatarImage={playerAvatar}
            nickname={playerName || ''}
            side="left"
            title={titleText || 'Sin título'}
          />
        </View>
        <View style={[styles.contentWrapper, styles.contentWrapperRight]}>
          <LaunchBattleSide
            position={opponentFirst ? 1 : 2}
            avatarImage={avatarImage}
            nickname={actor!.name || 'Oponente'}
            side="right"
            title={gameConfig?.name || ''}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

export default LaunchBattle;
