import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useState } from 'react';
import { View, Text, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useGoogle } from '../../../hooks/useGoogle';
import { Profile } from '../../../redux/Profile/profileReducer';
import { RootStackParamList } from '../../../types';
import { Player, PlayerTypesEnum } from '../../dtos/player.dto';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';
import Modal from '../Modal';

type Status = 'idle' | 'error' | 'duplicated';

interface GuestRegisterModalProps {
  handleClose: () => void;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Campaign', undefined>;

const GuestRegisterModal = ({ handleClose }: GuestRegisterModalProps) => {
  const { rw, rh } = useRatioDimensions();
  const { signInGoogle, checkUser } = useGoogle();

  const [status, setStatus] = useState<Status>('idle');

  const navigation = useNavigation<Navigation>();

  const messages: Record<Status, string> = {
    duplicated: 'Ups! parece que esta cuenta ya tiene un perfil asociado.',
    error: 'Ha ocurrido un error durante el inicio de sesion, por favor vuelva a intentarlo mas tarde.',
    idle: 'Uppps, para seguir disfrutando de Fakesover, regístrate con tu cuenta de Gmail'
  };

  const { player } = useSelector(({ profile }: { profile: Profile }) => profile);

  /**
   * Login and update progress of player with existing data, just updating the existing user.
   *
   * @returns {Promise<void>}
   */
  const googleLogin = async (): Promise<void> => {
    const result = await signInGoogle();
    if (!result) return setStatus('error');
    const res = await checkUser(result);
    if (res?.player) return setStatus('duplicated');
    const newPlayer: Player = {
      ...player!,
      type: PlayerTypesEnum.USER
    };
    navigation.navigate('LoginUpdate', {
      player: newPlayer!
    });
  };

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingVertical: isNativeOrPWA ? 0 : 64
    },
    buttonContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: rh(1),
      minWidth: '38%'
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    },
    registerText: {
      color: '#FFF',
      fontSize: 21,
      fontWeight: '500',
      maxWidth: isNativeOrPWA ? '80%' : 512,
      textAlign: 'center'
    },
    title: {
      color: '#FFF',
      fontSize: 32,
      fontWeight: '800'
    },
    cancelText: {
      color: '#A5A5A5',
      fontSize: 21,
      fontWeight: '500',
      maxWidth: isNativeOrPWA ? '100%' : 512,
      textAlign: 'center'
    },
    explanation: {
      color: status === 'error' ? '#FF6F77' : '#FFF',
      fontSize: 21,
      fontWeight: '500',
      maxWidth: isNativeOrPWA ? '80%' : 512,
      textAlign: 'center'
    },
    modalSizes: {
      width: rw(isNativeOrPWA ? 80 : 40),
      minHeight: isNativeOrPWA ? '90%' : 412,
      minWidth: isNativeOrPWA ? 320 : 812,
      height: isNativeOrPWA ? rh(80) : rw(32) / 1.32
    }
  });

  return (
    <Modal styleParent={styles.container} modalSizes={styles.modalSizes}>
      <Text style={styles.title}>¡Cuidado!</Text>
      <Text style={styles.explanation}>{messages[status]}</Text>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: isNativeOrPWA ? '80%' : 512
        }}
      >
        <TouchableWithoutFeedback onPress={handleClose}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.cancelText}>Cancelar</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={googleLogin}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.registerText}>Registrarse</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </Modal>
  );
};

export default GuestRegisterModal;
