import { useEffect, useMemo, useRef } from 'react';
import { Animated, ImageBackground, Platform, StyleSheet } from 'react-native';
import {
  damagePointsBlackContainer,
  damagePointsBlackContainerEnhance,
  damagePointsContainerError,
  damagePointsWhiteContainer,
  damagePointsWhiteContainerEnhance,
  defensePointsBlackContainer,
  defensePointsBlackContainerEnhance,
  defensePointsContainerError,
  defensePointsWhiteContainer,
  defensePointsWhiteContainerEnhance
} from '../../../constants/Images';
import { Card as CardType } from '../../dtos/card.dto';
import { isGameActionHighlight } from '../../events-management/game-actions/gameActions';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import { Text } from '../Themed';
import { CardSizesEnum, selectSize } from './utils';

interface AtackAndDefenseProps {
  card: CardType;
  cardSize: CardSizesEnum;
  iconType: 'defense' | 'damage';
  points: number;
  enhancePointsResult: { damagePoints: number; defensePoints: number };
}

const sources = [
  {
    defense: { default: defensePointsWhiteContainer, enhance: defensePointsWhiteContainerEnhance, error: defensePointsContainerError },
    damage: { default: damagePointsWhiteContainer, enhance: damagePointsWhiteContainerEnhance, error: damagePointsContainerError }
  },
  {
    defense: { default: defensePointsBlackContainer, enhance: defensePointsBlackContainerEnhance, error: defensePointsContainerError },
    damage: { default: damagePointsBlackContainer, enhance: damagePointsBlackContainerEnhance, error: damagePointsContainerError }
  }
];

const AttackAndDefense = ({ card, cardSize, iconType, points, enhancePointsResult }: AtackAndDefenseProps) => {
  const { rw } = useRatioDimensions();
  const isDefense = iconType === 'defense';
  const scaleImg = useRef(new Animated.Value(0)).current;

  const styles = StyleSheet.create({
    pointsText: {
      fontFamily: 'Inlanders',
      textAlign: 'center',
      textAlignVertical: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color:
        (enhancePointsResult.damagePoints !== 0 && !isDefense) || (enhancePointsResult.defensePoints !== 0 && isDefense) || card.color
          ? '#FFFFFF'
          : '#000000',
      fontSize: selectSize(cardSize, rw(2.2), rw(1.8), rw(1.4), rw(1.25)) as number
    },
    pointsContainer: {
      position: 'absolute',
      top: '37%',
      zIndex: 300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    defensePointsContainer: {
      top: '37.5%'
    },
    imageContainer: { height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' },
    defensePointsText: {
      marginLeft: card.color ? 0 : rw(0.25)
    },
    damagePointsText: {
      marginRight: rw(0.12),
      marginTop: rw(0.12)
    },
    pointsHighlightCard: {
      fontSize: selectSize(cardSize, rw(3), rw(2.5), rw(2), rw(1.65)) as number
    }
  });

  const damagePointsCardHighLight = isGameActionHighlight(`damage-points-card-board-${card.cardCode}`) ? styles.pointsHighlightCard : {};

  const defensePointsCardHighLight = isGameActionHighlight(`defense-points-card-board-${card.cardCode}`) ? styles.pointsHighlightCard : {};
  const sourceDef = useMemo(() => {
    if (isDefense) {
      if (enhancePointsResult.defensePoints > 0 || Object.keys(defensePointsCardHighLight).length > 0) {
        return sources[card.color][iconType].enhance;
      } else if (enhancePointsResult.defensePoints < 0) {
        return sources[card.color][iconType].error;
      } else return sources[card.color][iconType].default;
    } else {
      if (enhancePointsResult.damagePoints > 0 || Object.keys(damagePointsCardHighLight).length > 0) {
        return sources[card.color][iconType].enhance;
      } else if (enhancePointsResult.damagePoints < 0) {
        return sources[card.color][iconType].error;
      } else return sources[card.color][iconType].default;
    }
  }, [card.color, iconType, damagePointsCardHighLight, defensePointsCardHighLight, enhancePointsResult]);

  useEffect(() => {
    const animation = Animated.timing(scaleImg, {
      toValue: 1,
      useNativeDriver: Platform.OS !== 'web',
      duration: 300
    });
    if (enhancePointsResult.damagePoints === 0) return;
    animation.start();
  }, [enhancePointsResult.damagePoints]);

  return (
    <Animated.View
      pointerEvents="none"
      style={[
        {
          transform: [
            {
              scale: scaleImg.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 1.25]
              })
            }
          ]
        },
        styles.pointsContainer,
        isDefense && styles.defensePointsContainer,
        isDefense
          ? {
              width: selectSize(cardSize, rw(4.2), rw(3.8), rw(2.4), rw(2)),
              height: selectSize(cardSize, rw(4.2), rw(3.8), rw(2.4), rw(2)),
              right: selectSize(cardSize, -rw(1.75), -rw(1.6), -rw(1), -rw(0.9))
            }
          : {
              width: selectSize(cardSize, rw(4.9), rw(4.2), rw(3), rw(2.2)),
              height: selectSize(cardSize, rw(4.9), rw(4.2), rw(3), rw(2.2)),
              left: selectSize(cardSize, -rw(2.2), -rw(2), -rw(1.5), -rw(1))
            }
      ]}
    >
      <ImageBackground style={styles.imageContainer} resizeMode={'contain'} source={sourceDef}>
        <Text
          selectable={false}
          style={[
            styles.pointsText,
            isDefense ? styles.defensePointsText : styles.damagePointsText,
            isDefense ? defensePointsCardHighLight : damagePointsCardHighLight
          ]}
        >
          {points}
        </Text>
      </ImageBackground>
    </Animated.View>
  );
};

export default AttackAndDefense;
