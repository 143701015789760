import { Subject } from './subject.dto';
import { Type } from './type.dto';

interface StoppedRule {
  stoppedRound: number;
  stopByTurns: number;
}
export interface AppliedRule {
  [key: string]: Rule | AppliedStoppedRule;
}

interface AppliedStoppedRule {
  [key: string]: StoppedRule;
}
export interface WhenAppliedRules {
  lose: AppliedRule;
  win: AppliedRule;
  alive: AppliedRule;
  stop: AppliedStoppedRule;
  play: AppliedRule;
}
export interface AppliedRules {
  player: WhenAppliedRules;
  opponent: WhenAppliedRules;
}
export interface ContextAppliedRules {
  appliedRules: AppliedRules;
}

export enum RuleTypeEnum {
  POINTS = 'points',
  NEW_CARD = 'newCard',
  COPY_CARD = 'copyCard',
  DESTROY = 'destroy',
  TOKEN = 'token',
  BACK_TO_DECK = 'backToDeck',
  STOP = 'stop'
}
export enum WhenEnum {
  PLAY = 'play',
  LOSE = 'lose',
  WIN = 'win',
  ALIVE = 'alive'
}

export class Rule {
  id!: string;
  ruleName!: string;
  ruleType!: RuleTypeEnum;
  ruleText!: string;
  applyWhen!: WhenEnum;
  applied?: boolean | null;
  applyToOpponent?: boolean | null;
  applyToPlayer?: boolean | null;
  applyToAvatar?: boolean | null;
  applyToAllCards?: boolean | null;
  applyToCurrentCard?: boolean | null;
  applyToType?: Type[] | null;
  applyToSubject?: Subject[] | null;
  applyToColor?: number | null;
  costActionPoints?: number | null;
  damagePoints?: number | null;
  defensePoints?: number | null;
  needTarget?: boolean | null;
  launchCards?: number | null;
  newCards?: number | null;
  destroyCards?: number | null;
  destroyCardsDamagePoints?: number | null;
  destroyCardsDefensePoints?: number | null;
  removeCapabilities?: boolean | null;
  stopByTurns?: number | null;
  haveConditionLessDamagePoints?: boolean | null;
  haveConditionLessDefensePoints?: boolean | null;
  haveConditionLessHandCards?: boolean | null;
  token?: string | null;
  tokenDamagePoints?: number | null;
  tokenDefensePoints?: number | null;

  constructor(properties?: RuleProperties) {
    if (properties) {
      this.ruleName = properties.ruleName;
      this.ruleType = properties.ruleType;
      this.ruleText = properties.ruleText;
      this.applyWhen = properties.applyWhen;
      this.applied = properties.applied;
      this.applyToOpponent = properties.applyToOpponent;
      this.applyToPlayer = properties.applyToPlayer;
      this.applyToAvatar = properties.applyToAvatar;
      this.applyToAllCards = properties.applyToAllCards;
      this.applyToCurrentCard = properties.applyToCurrentCard;
      this.applyToType = properties.applyToType;
      this.applyToSubject = properties.applyToSubject;
      this.applyToColor = properties.applyToColor;
      this.costActionPoints = properties.costActionPoints;
      this.damagePoints = properties.damagePoints;
      this.defensePoints = properties.defensePoints;
      this.needTarget = properties.needTarget;
      this.launchCards = properties.launchCards;
      this.newCards = properties.newCards;
      this.destroyCards = properties.destroyCards;
      this.destroyCardsDamagePoints = properties.destroyCardsDamagePoints;
      this.destroyCardsDefensePoints = properties.destroyCardsDefensePoints;
      this.removeCapabilities = properties.removeCapabilities;
      this.stopByTurns = properties.stopByTurns;
      this.haveConditionLessDamagePoints = properties.haveConditionLessDamagePoints;
      this.haveConditionLessDefensePoints = properties.haveConditionLessDefensePoints;
      this.haveConditionLessHandCards = properties.haveConditionLessHandCards;
      this.token = properties.token;
      this.tokenDamagePoints = properties.tokenDamagePoints;
      this.tokenDefensePoints = properties.tokenDefensePoints;
    }
  }
}

export interface RuleProperties {
  id: string;
  ruleName: string;
  ruleType: RuleTypeEnum;
  ruleText: string;
  applyWhen: WhenEnum;
  applied?: boolean | null;
  applyToOpponent?: boolean | null;
  applyToPlayer?: boolean | null;
  applyToAvatar?: boolean | null;
  applyToAllCards?: boolean | null;
  applyToCurrentCard?: boolean | null;
  applyToType?: Type[] | null;
  applyToSubject?: Subject[] | null;
  applyToColor?: number | null;
  costActionPoints?: number | null;
  damagePoints?: number | null;
  defensePoints?: number | null;
  needTarget?: boolean | null;
  launchCards?: number | null;
  newCards?: number | null;
  destroyCards?: number | null;
  destroyCardsDamagePoints?: number | null;
  destroyCardsDefensePoints?: number | null;
  removeCapabilities?: boolean | null;
  stopByTurns?: number | null;
  haveConditionLessDamagePoints?: boolean | null;
  haveConditionLessDefensePoints?: boolean | null;
  haveConditionLessHandCards?: boolean | null;
  token?: string | null;
  tokenDamagePoints?: number | null;
  tokenDefensePoints?: number | null;
}

export interface BasicRuleProperties {
  id: string;
  ruleName: string;
  applyWhen: WhenEnum;
}
