import React, { useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle, Image } from 'react-native';
import { Position } from 'react-native-drax';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import HandCard from '../../../commons/components/Card/CardTypes/HandCard';
import { CardSizesEnum } from '../../../commons/components/Card/utils';
import { PlayerTypeEnum } from '../../../commons/constants/player';
import { Card as CardType } from '../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { Rule } from '../../../commons/dtos/rule.dto';
import { isNativeMobile } from '../../../commons/utils';
import { rewardCard } from '../../../constants/Images';

export interface HandOfCardsProperties {
  setDragCard: React.Dispatch<React.SetStateAction<string>>;
  playerHand: CardType[];
  opponentHand: CardType[];
  round: number;
  onCardPressed: (card: CardType, side: PlayerRoundEnum, position: ViewStyle) => void;
  onCloseDetailModal: () => void;
  selectingTargetCard: CardType | undefined;
  targetReached: CardType | undefined;
  cardAppliesToTarget: (card: CardType, targetCard: CardType, allRules: Rule[], isSelfRound?: boolean | null) => boolean;
  selectingTargetDrawnCard: CardType | null;
  getApplicableRules: (card: CardType, targetCard: CardType, allRules: Rule[], isSelfRound?: boolean | null) => Rule[];
  onDragMethods: {
    onDragStart: (left: number, top: number, card: CardType, side: PlayerRoundEnum) => void;
    onDragExit: () => void;
    onDragEnd: () => void;
    onDragDrop: (card: CardType) => void;
  };
  dragAttacking: string | null;
  isCurrentPlayerRound: (player: PlayerTypeEnum) => boolean;
  opponentIsArtificialIntelligence: boolean | undefined;
  selectingTargetDrawnCardSetter: (selectingTargetCard: CardType | null) => void;
  isOpponentRound: boolean;
  isPlayerRound: boolean;
  cardIsUpdating: boolean;
  allRules: Rule[];
}

export interface CardSideFrontCoverProperties {
  opponentIsIA: boolean;
  card: CardType;
  index: number;
  conditionalStyles?: StyleProp<ViewStyle>;
}

export const CardSideFrontCover = ({ card, opponentIsIA, conditionalStyles }: CardSideFrontCoverProperties) => {
  return opponentIsIA ? (
    <Image source={rewardCard} resizeMode="stretch" style={{ width: '100%', height: '100%' }} />
  ) : (
    <HandCard card={card} cardSize={CardSizesEnum.SMALL} conditionalStyles={conditionalStyles} />
  );
};

export const useHandOfCards = (isCurrentPlayerRound: Function, opponentIsArtificialIntelligence: boolean) => {
  const [cardsWidth, setCardsWidth] = useState<number>(0);
  const [cardsHeight, setCardsHeight] = useState<number>(0);
  const { rw } = useRatioDimensions();

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      width: 'auto',
      height: '20%',
      bottom: '-5%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      zIndex: 10
    },
    cardsContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isNativeMobile ? 'center' : 'flex-start',
      alignItems: 'center'
    },
    card: {
      width: rw(8),
      height: rw(11.7)
    },
    cardContent: {
      position: 'relative',
      height: '100%'
    },
    cardContentDrag: {
      position: 'relative',
      width: '100%',
      height: '100%',
      zIndex: 12
    },
    cardDrag: {
      bottom: isCurrentPlayerRound(PlayerTypeEnum.OPPONENT) && opponentIsArtificialIntelligence ? 0 : 15,
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 9,
      elevation: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    targetSelector: {
      width: rw(5),
      height: rw(5),
      transform: [
        {
          translateX: -rw(2.5)
        },
        {
          translateY: -rw(2.5)
        }
      ]
    },
    cardContainer: {
      width: rw(8),
      height: rw(11.7)
    },
    lottieEffect: {
      width: rw(8.5),
      height: rw(8.5),
      transform: [
        {
          translateX: -rw(3.5)
        },
        {
          translateY: -rw(5.5)
        }
      ],
      backgroundColor: 'transparent',
      elevation: 25
    },
    battlePointsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: rw(4),
      height: rw(4),
      position: 'relative',
      bottom: -rw(3)
    },
    battlePointsBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    pointsText: {
      position: 'absolute',
      fontSize: rw(2),
      color: '#FFFFFF',
      fontFamily: 'Inlanders',
      textAlign: 'center',
      textAlignVertical: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 200
    },
    deadCardIcon: { position: 'absolute', width: '60%', height: '60%', zIndex: 200 },
    selectingTargetDrawnCardContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
      bottom: '10%'
    },
    playableHandCardShadow: {
      shadowColor: '#ffffff',
      borderRadius: rw(2),
      shadowOffset: {
        width: 0,
        height: -4
      },
      shadowOpacity: 1,
      shadowRadius: 13.0,
      elevation: 25
    }
  });

  const onDragOutAreaCloseDetailModal = (dragTranslation: Position, closeModal: Function) => {
    if (Math.abs(dragTranslation.x) > cardsWidth / 2) {
      closeModal();
    }
    if (Math.abs(dragTranslation.y) > cardsHeight / 2) {
      closeModal();
    }
  };

  return {
    cardsWidth,
    cardsHeight,
    setCardsHeight,
    setCardsWidth,
    onDragOutAreaCloseDetailModal,
    styles
  };
};
