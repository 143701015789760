/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable camelcase */
import { ImageSourcePropType } from 'react-native';
import { ActorEnum } from '../commons/dtos/actor.dto';
import { TweetsImages } from '../commons/dtos/enhance.dto';
export const backgroundDeckEditor = require('../assets/images/backgrounds/deck/background-deck.jpg');
export const gameBoardBackground1 = require('../assets/images/backgrounds/game-board/desktop/game-board-background-1.jpg');
export const gameBoardBackgroundMobile = require('../assets/images/backgrounds/game-board/mobile/game-board-background-1.jpg');

export const errorBoundaryBackground = require('../assets/images/backgrounds/error-boundary/background.png');
export const errorBoundaryBackgroundMobile = require('../assets/images/backgrounds/error-boundary/background.png');

export const cards: { [name: string]: ImageSourcePropType } = {
  white_card: require('../assets/images/cards/white_card.png'),
  black_card: require('../assets/images/cards/black_card.png'),
  faketifico: require('../assets/images/cards/faketifico.png'),
  fakefluencer: require('../assets/images/cards/fakefluencer.png'),
  qanon: require('../assets/images/cards/qanon.png'),
  cerdo_prohibido_escuelas: require('../assets/images/cards/cerdo_prohibido_escuelas.png'),
  chemtrails: require('../assets/images/cards/chemtrails.png'),
  drag_queens_adoctrinan_colegios: require('../assets/images/cards/drag_queens_adoctrinan_colegios.png'),
  enfriamiento_global: require('../assets/images/cards/enfriamiento_global.png'),
  inmigrantes_medicamentos_gratis: require('../assets/images/cards/inmigrantes_medicamentos_gratis.png'),
  musulmanes_vagos: require('../assets/images/cards/musulmanes_vagos.png'),
  prohiben_series_protagonistas_guapas: require('../assets/images/cards/prohiben_series_protagonistas_guapas.png'),
  puente_feminista: require('../assets/images/cards/puente_feminista.png'),
  tractores_generan_calima: require('../assets/images/cards/tractores_generan_calima.png'),
  voto_zombie: require('../assets/images/cards/voto_zombie.png'),
  _00check: require('../assets/images/cards/_00check.png'),
  wayback_machine: require('../assets/images/cards/wayback_machine.png'),
  volcanes_cambio_climatico: require('../assets/images/cards/volcanes_cambio_climatico.png'),
  verificacion_rapida: require('../assets/images/cards/verificacion_rapida.png'),
  alienigenas_mayas: require('../assets/images/cards/alienigenas_mayas.png'),
  asesinatos_covid: require('../assets/images/cards/asesinatos_covid.png'),
  biblia_del_factcheck: require('../assets/images/cards/biblia_del_factcheck.png'),
  birds_are_not_real: require('../assets/images/cards/birds_are_not_real.png'),
  black_fake_friday: require('../assets/images/cards/black_fake_friday.png'),
  busqueda_inversa_de_imagenes: require('../assets/images/cards/busqueda_inversa_de_imagenes.png'),
  cambio_climatico_gran_mentira: require('../assets/images/cards/cambio_climatico_gran_mentira.png'),
  campana_de_spam: require('../assets/images/cards/campana_de_spam.png'),
  cancer_eolico: require('../assets/images/cards/cancer_eolico.png'),
  ciberataque: require('../assets/images/cards/ciberataque.png'),
  ciberpolicia: require('../assets/images/cards/ciberpolicia.png'),
  clickbaiting: require('../assets/images/cards/clickbaiting.png'),
  corresponsal_de_guerra: require('../assets/images/cards/corresponsal_de_guerra.png'),
  corresponsal_extranjero: require('../assets/images/cards/corresponsal_extranjero.png'),
  crema_milagro: require('../assets/images/cards/crema_milagro.png'),
  cripto_donacion: require('../assets/images/cards/cripto_donacion.png'),
  chocolate_zero: require('../assets/images/cards/chocolate_zero.png'),
  deep_fake_detector: require('../assets/images/cards/deep_fake_detector.png'),
  deep_impact: require('../assets/images/cards/deep_impact.png'),
  descredito: require('../assets/images/cards/descredito.png'),
  detector_de_bots: require('../assets/images/cards/detector_de_bots.png'),
  divulgador_cientifico: require('../assets/images/cards/divulgador_cientifico.png'),
  doble_werificacion: require('../assets/images/cards/doble_werificacion.png'),
  el_heraldo_de_la_libertad: require('../assets/images/cards/el_heraldo_de_la_libertad.png'),
  el_imparcial: require('../assets/images/cards/el_imparcial.png'),
  estafa_piramidal: require('../assets/images/cards/estafa_piramidal.png'),
  factchecking_para_dummies: require('../assets/images/cards/factchecking_para_dummies.png'),
  facts_first: require('../assets/images/cards/facts_first.png'),
  fake_followers: require('../assets/images/cards/fake_followers.png'),
  fake_news: require('../assets/images/cards/fake_news.png'),
  filtro_antispam: require('../assets/images/cards/filtro_antispam.png'),
  forensia_de_imagenes: require('../assets/images/cards/forensia_de_imagenes.png'),
  fuente: require('../assets/images/cards/fuente.png'),
  geolocalizacion: require('../assets/images/cards/geolocalizacion.png'),
  gps_covid: require('../assets/images/cards/gps_covid.png'),
  gran_reemplazo: require('../assets/images/cards/gran_reemplazo.png'),
  granja_de_trolls: require('../assets/images/cards/granja_de_trolls.png'),
  hackeo: require('../assets/images/cards/hackeo.png'),
  harry_checker: require('../assets/images/cards/harry_checker.png'),
  hater: require('../assets/images/cards/hater.png'),
  incibe: require('../assets/images/cards/incibe.png'),
  inmigrantes_tiran_comida: require('../assets/images/cards/inmigrantes_tiran_comida.png'),
  invasion_migratoria: require('../assets/images/cards/invasion_migratoria.png'),
  ironcheck: require('../assets/images/cards/ironcheck.png'),
  konspirador: require('../assets/images/cards/konspirador.png'),
  magneto_covid: require('../assets/images/cards/magneto_covid.png'),
  manipulacion_emocional: require('../assets/images/cards/manipulacion_emocional.png'),
  manipulacion_mediatica: require('../assets/images/cards/manipulacion_mediatica.png'),
  mascarillas_toxicas: require('../assets/images/cards/mascarillas_toxicas.png'),
  matahari: require('../assets/images/cards/matahari.png'),
  mecenas: require('../assets/images/cards/mecenas.png'),
  messi_bitcoin: require('../assets/images/cards/messi_bitcoin.png'),
  montaje_hombre_luna: require('../assets/images/cards/montaje_hombre_luna.png'),
  movil_dana_ojos: require('../assets/images/cards/movil_dana_ojos.png'),
  mutantes_covid: require('../assets/images/cards/mutantes_covid.png'),
  nievacionismo: require('../assets/images/cards/nievacionismo.png'),
  observatorio_de_desinformacion: require('../assets/images/cards/observatorio_de_desinformacion.png'),
  ong_montaje_refugiados: require('../assets/images/cards/ong_montaje_refugiados.png'),
  palomicidio_5g: require('../assets/images/cards/palomicidio_5g.png'),
  papa_arrestado: require('../assets/images/cards/papa_arrestado.png'),
  plandemia: require('../assets/images/cards/plandemia.png'),
  plandemica: require('../assets/images/cards/plandemica.png'),
  polarizacion: require('../assets/images/cards/polarizacion.png'),
  populismo: require('../assets/images/cards/populismo.png'),
  potencia_en_yeguas: require('../assets/images/cards/potencia_en_yeguas.png'),
  prensa_libre: require('../assets/images/cards/prensa_libre.png'),
  red_de_werificadores: require('../assets/images/cards/red_de_werificadores.png'),
  repeticion: require('../assets/images/cards/repeticion.png'),
  robot_cocina: require('../assets/images/cards/robot_cocina.png'),
  rueda_de_prensa: require('../assets/images/cards/rueda_de_prensa.png'),
  rumor_tracker: require('../assets/images/cards/rumor_tracker.png'),
  secuestro_cuenta_guasap: require('../assets/images/cards/secuestro_cuenta_guasap.png'),
  servicio_de_verificacion: require('../assets/images/cards/servicio_de_verificacion.png'),
  servicio_de_verificacion_automatizado: require('../assets/images/cards/servicio_de_verificacion_automatizado.png'),
  smishing_banco: require('../assets/images/cards/smishing_banco.png'),
  smishing_gaming: require('../assets/images/cards/smishing_gaming.png'),
  sorteo_fake_videoconsolas: require('../assets/images/cards/sorteo_fake_videoconsolas.png'),
  suplantacion_identidad: require('../assets/images/cards/suplantacion_identidad.png'),
  talleres_verifica2: require('../assets/images/cards/talleres_verifica2.png'),
  telenews: require('../assets/images/cards/telenews.png'),
  the_flash_checker: require('../assets/images/cards/the_flash_checker.png'),
  tonto_lava: require('../assets/images/cards/tonto_lava.png'),
  troll_machista: require('../assets/images/cards/troll_machista.png'),
  trolls_pagados: require('../assets/images/cards/trolls_pagados.png'),
  troyano_correos: require('../assets/images/cards/troyano_correos.png'),
  vacuna_asesina: require('../assets/images/cards/vacuna_asesina.png')
};

export const backOfCard = require('../assets/images/cards/back.png');
export const emptyIcon = require('../assets/images/icons/deck-editor/card-stats-filter/empty.png');
export const selectedEmptyIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-empty.png');
export const scienceIcon = require('../assets/images/icons/deck-editor/card-stats-filter/science.png');
export const selectedScienceIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-science.png');
export const conspiracyIcon = require('../assets/images/icons/deck-editor/card-stats-filter/conspiracy.png');
export const selectedConspiracyIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-conspiracy.png');
export const hateIcon = require('../assets/images/icons/deck-editor/card-stats-filter/hate.png');
export const selectedHateIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-hate.png');
export const scamIcon = require('../assets/images/icons/deck-editor/card-stats-filter/scam.png');
export const selectedScamIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-scam.png');
export const scienceSubjectIconBlack = require('../assets/images/icons/deck-editor/deck-viewer/science-subject-icon-black.png');
export const scienceSubjectIconWhite = require('../assets/images/icons/deck-editor/deck-viewer/science-subject-icon-white.png');
export const conspiracySubjectIconBlack = require('../assets/images/icons/deck-editor/deck-viewer/conspiracy-subject-icon-black.png');
export const conspiracySubjectIconWhite = require('../assets/images/icons/deck-editor/deck-viewer/conspiracy-subject-icon-white.png');
export const hateSubjectIconBlack = require('../assets/images/icons/deck-editor/deck-viewer/hate-subject-icon-black.png');
export const hateSubjectIconWhite = require('../assets/images/icons/deck-editor/deck-viewer/hate-subject-icon-white.png');
export const scamSubjectIconBlack = require('../assets/images/icons/deck-editor/deck-viewer/scam-subject-icon-black.png');
export const scamSubjectIconWhite = require('../assets/images/icons/deck-editor/deck-viewer/scam-subject-icon-white.png');
export const oneNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-1.png');
export const twoNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-2.png');
export const threeNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-3.png');
export const fourNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-4.png');
export const fiveNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-5.png');
export const sixNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-6.png');
export const sevenNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-7.png');
export const sevenMoreNumber = require('../assets/images/icons/deck-editor/card-stats-filter/number-7-more.png');
export const selectedNumberOneIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-number-1.png');
export const selectedNumberTwoIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-number-2.png');
export const selectedNumberThreeIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-number-3.png');
export const selectedNumberFourIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-number-4.png');
export const selectedNumberFiveIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-number-5.png');
export const selectedNumberSixIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-number-6.png');
export const selectedNumberSevenOrMoreIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-number-7-more.png');
export const rareIcon = require('../assets/images/icons/deck-editor/card-stats-filter/rare.png');
export const selectedRareIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-rare.png');
export const uniqueIcon = require('../assets/images/icons/deck-editor/card-stats-filter/unique.png');
export const selectedUniqueIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-unique.png');
export const checkboxIcon = require('../assets/images/icons/deck-editor/card-stats-filter/checkbox.png');
export const selectedCheckboxIcon = require('../assets/images/icons/deck-editor/card-stats-filter/selected-checkbox.png');
export const warningIcon = require('../assets/images/icons/deck-editor/deck-viewer/warning.png');
export const userIcon = require('../assets/images/icons/commons/user-menu/user-icon.png');
export const settingsIcon = require('../assets/images/icons/commons/user-menu/settings-icon.png');
export const userIconBlack = require('../assets/images/icons/commons/user-menu/user-icon-black.png');
export const settingsIconBlack = require('../assets/images/icons/commons/user-menu/settings-icon-black.png');
export const deckIconBlack = require('../assets/images/icons/commons/user-menu/deck-icon-black.png');
export const deckIcon = require('../assets/images/icons/commons/user-menu/deck-icon.png');
export const menuIcon = require('../assets/images/icons/commons/external-header/menu-icon.png');
export const closeIcon = require('../assets/images/icons/deck-editor/deck-editor-menu/close-icon.png');
export const closeIconBlack = require('../assets/images/icons/deck-editor/deck-editor-menu/close-icon-black.png');
export const searchIcon = require('../assets/images/icons/deck-editor/deck-editor-menu/search-icon.png');
export const filterIcon = require('../assets/images/icons/deck-editor/deck-editor-menu/filter-icon.png');
export const backIcon = require('../assets/images/icons/deck-editor/deck-editor-menu/back-icon.png');
export const uniqueBlackIcon = require('../assets/images/icons/commons/card/unique-black-card-icon.png');
export const uniqueWhiteIcon = require('../assets/images/icons/commons/card/unique-white-card-icon.png');
export const rareBlackIcon = require('../assets/images/icons/commons/card/rare-black-card-icon.png');
export const rareWhiteIcon = require('../assets/images/icons/commons/card/rare-white-card-icon.png');
export const damagePointsContainerError = require('../assets/images/components/commons/card/damage-points-container-error.png');
export const defensePointsContainerError = require('../assets/images/components/commons/card/defense-points-container-error.png');
export const damagePointsBlackContainerEnhance = require('../assets/images/components/commons/card/damage-points-black-container-enhance.png');
export const damagePointsWhiteContainerEnhance = require('../assets/images/components/commons/card/damage-points-white-container-enhance.png');
export const defensePointsBlackContainerEnhance = require('../assets/images/components/commons/card/defense-points-black-container-enhance.png');
export const defensePointsWhiteContainerEnhance = require('../assets/images/components/commons/card/defense-points-white-container-enhance.png');
export const damagePointsBlackContainer = require('../assets/images/components/commons/card/damage-points-black-container.png');
export const damagePointsWhiteContainer = require('../assets/images/components/commons/card/damage-points-white-container.png');
export const defensePointsBlackContainer = require('../assets/images/components/commons/card/defense-points-black-container.png');
export const defensePointsWhiteContainer = require('../assets/images/components/commons/card/defense-points-white-container.png');
export const actionPointsBlackContainer = require('../assets/images/components/commons/card/action-points-black-container.png');
export const actionPointsWhiteContainer = require('../assets/images/components/commons/card/action-points-white-container.png');
export const subjectBlackContainer = require('../assets/images/components/commons/card/subject-black-container.png');
export const subjectWhiteContainer = require('../assets/images/components/commons/card/subject-white-container.png');
export const barrierCover = require('../assets/images/components/commons/card/barrier-cover.png');
export const cardNameBlackContainer = require('../assets/images/components/commons/card/card-name-black-container.png');
export const cardTextBlackContainer = require('../assets/images/components/commons/card/black-text-container.png');
export const cardNameWhiteContainer = require('../assets/images/components/commons/card/card-name-white-container.png');
export const cardTextWhiteContainer = require('../assets/images/components/commons/card/white-text-container.png');
export const barrier = require('../assets/images/components/commons/card/barrier.png');
export const undetectable = require('../assets/images/components/commons/card/undetectable.png');
export const typeContainerBorderLeftBlack = require('../assets/images/components/commons/card/type-container-border-left-black.png');
export const typeContainerBorderLeftWhite = require('../assets/images/components/commons/card/type-container-border-left-white.png');
export const typeContainerBorderRightBlack = require('../assets/images/components/commons/card/type-container-border-right-black.png');
export const typeContainerBorderRightWhite = require('../assets/images/components/commons/card/type-container-border-right-white.png');
export const tagExplanationContainerBorderTop = require('../assets/images/components/commons/card/tag-container-border-up.png');
export const tagExplanationContainerBorderBottom = require('../assets/images/components/commons/card/tag-container-border-bottom.png');
export const rayIcon = require('../assets/images/icons/commons/ray.png');

export const rarityBlackContainer = require('../assets/images/icons/commons/card/common/black-container.png');
export const rarityWhiteContainer = require('../assets/images/icons/commons/card/common/white-container.png');
export const platinumIcon = require('../assets/images/icons/commons/card/rarity/platinum-icon.png');
export const silverIcon = require('../assets/images/icons/commons/card/rarity/silver-icon.png');
export const goldIcon = require('../assets/images/icons/commons/card/rarity/gold-icon.png');

export const factcheckIcon = require('../assets/images/icons/commons/card/actions-skills/factcheck-icon.png');
export const impactIcon = require('../assets/images/icons/commons/card/actions-skills/impact-icon.png');
export const loseIcon = require('../assets/images/icons/commons/card/actions-skills/lose-icon.png');
export const winIcon = require('../assets/images/icons/commons/card/actions-skills/win-icon.png');

export const battlePointsContainer = require('../assets/images/components/commons/card/battle-points-container.png');
export const deadCardIcon = require('../assets/images/icons/commons/card/dead-card-icon.png');

export const selectedShape = require('../assets/images/icons/deck-editor/card-stats-filter/selected-shape.png');
export const unselectedShape = require('../assets/images/icons/deck-editor/card-stats-filter/unselected-shape.png');
export const previousButtonActive = require('../assets/images/components/deck-editor/cards-viewer/previous-button-active.png');
export const previousButtonInactive = require('../assets/images/components/deck-editor/cards-viewer/previous-button-inactive.png');
export const nextButtonActive = require('../assets/images/components/deck-editor/cards-viewer/next-button-active.png');
export const nextButtonInactive = require('../assets/images/components/deck-editor/cards-viewer/next-button-inactive.png');
export const starcraft = require('../assets/images/components/commons/error-modal/starcraft.png');
export const astronaut = require('../assets/images/components/commons/error-modal/astronaut.png');
export const irregularModalContainer = require('../assets/images/components/commons/modal/irregular-modal-container.png');
export const returnIcon = require('../assets/images/icons/card-inventory-viewer/card-inventory-viewer-menu/return-icon.png');
export const exportIcon = require('../assets/images/icons/game-board-initializer/export-icon.png');
export const importIcon = require('../assets/images/icons/game-board-initializer/import-icon.png');
export const deckCardContainerBlack = require('../assets/images/components/deck-editor/deck-viewer/deck-card-black.png');
export const deckCardContainerWhite = require('../assets/images/components/deck-editor/deck-viewer/deck-card-white.png');
export const subjectIconContainerBlack = require('../assets/images/components/deck-editor/deck-viewer/black-subject-icon-container.png');
export const subjectIconContainerWhite = require('../assets/images/components/deck-editor/deck-viewer/white-subject-icon-container.png');
export const blockImage = require('../assets/images/components/deck-editor/cards-viewer/block.png');
export const defaultDeckImage = require('../assets/images/components/deck-editor/deck-viewer/default-deck.png');

export const LoginFormBackgroundImage = require('../assets/images/backgrounds/login/background-login-form.png');
export const LoginBackgroundImage = require('../assets/images/backgrounds/login/background-login.png');
export const ButtonSignInGoogleG = require('../assets/images/logos/google-g.png');
export const LogoFad = require('../assets/images/logos/fad-logo.png');
export const GameLogo = require('../assets/images/logos/game-logo.png');
export const LogoGoogle = require('../assets/images/logos/google-logo.png');
export const playerAvatarContainer = require('../assets/images/components/game-board/avatars-container/player-avatar-container.png');
export const oppponentAvatarContainer = require('../assets/images/components/game-board/avatars-container/opponent-avatar-container.png');
export const playerLifePointsContainer = require('../assets/images/components/game-board/avatars-container/player-life-points-container.png');
export const opponentLifePointsContainer = require('../assets/images/components/game-board/avatars-container/opponent-life-points-container.png');
export const nextTurnButton = require('../assets/images/components/game-board/next-turn-button.png');
export const nextTurnButtonHighlight = require('../assets/images/components/game-board/next-turn-button-highlight.png');
export const waitTurnButton = require('../assets/images/components/game-board/wait-turn-button.png');
export const redCardVeil = require('../assets/images/components/commons/card/red-card-veil.png');
export const blackCardVeil = require('../assets/images/components/commons/card/black-card-veil.png');
export const discardIcon = require('../assets/images/components/commons/card/discard-icon.png');
export const whatsappContainer = require('../assets/images/components/commons/card/whatsapp-container.png');
export const twitterContainer = require('../assets/images/components/commons/card/twitter-container.png');
export const twitterLikeIcon = require('../assets/images/components/commons/card/twitter-like-icon.png');
export const twitterCommentIcon = require('../assets/images/components/commons/card/twitter-comment-icon.png');
export const twitterRetweetIcon = require('../assets/images/components/commons/card/twitter-retweet-icon.png');
export const whatsappRecordIcon = require('../assets/images/components/commons/card/whatsapp-record-icon.png');
export const whatsappEmojiIcon = require('../assets/images/components/commons/card/whatsapp-emoji-icon.png');
export const whatsappForwardIcon = require('../assets/images/components/commons/card/whatsapp-forward-icon.png');
export const agenteFMaleAvatar = require('../assets/images/components/login/agente-f-male.png');
export const agenteFFemaleAvatar = require('../assets/images/components/login/agente-f-female.png');
export const agenteFMaleGameAvatar = require('../assets/images/avatars/agente-f-male-game.png');
export const agenteFFemaleGameAvatar = require('../assets/images/avatars/agente-f-female-game.png');
export const target = require('../assets/images/icons/game-board/target.png');

export const thunder = require('../assets/images/components/turn-indicator/thunder.png');

export const buttonW = require('../assets/images/buttons/btn-w.png');
export const buttonD = require('../assets/images/buttons/btn-d.png');
export const buttonLeftB = require('../assets/images/buttons/left-btn-b.png');
export const buttonMidB = require('../assets/images/buttons/middle-btn-b.png');
export const buttonRightB = require('../assets/images/buttons/right-btn-b.png');
export const buttonLeftW = require('../assets/images/buttons/left-btn-w.png');
export const buttonMidW = require('../assets/images/buttons/middle-btn-w.png');
export const buttonRightW = require('../assets/images/buttons/right-btn-w.png');
export const buttonLeftD = require('../assets/images/buttons/left-btn-d.png');
export const buttonMidD = require('../assets/images/buttons/middle-btn-d.png');
export const buttonRightD = require('../assets/images/buttons/right-btn-d.png');
export const buttonLeftG = require('../assets/images/buttons/left-btn-g.png');
export const buttonMidG = require('../assets/images/buttons/middle-btn-g.png');
export const buttonRightG = require('../assets/images/buttons/right-btn-g.png');

export const buttonLeftBB = require('../assets/images/buttons/left-btn-b-b.png');
export const buttonMidBB = require('../assets/images/buttons/middle-btn-b-b.png');
export const buttonRightBB = require('../assets/images/buttons/right-btn-b-b.png');
export const buttonLeftWB = require('../assets/images/buttons/left-btn-w-b.png');
export const buttonMidWB = require('../assets/images/buttons/middle-btn-w-b.png');
export const buttonRightWB = require('../assets/images/buttons/right-btn-w-b.png');
export const buttonLeftDB = require('../assets/images/buttons/left-btn-d-b.png');
export const buttonMidDB = require('../assets/images/buttons/middle-btn-d-b.png');
export const buttonRightDB = require('../assets/images/buttons/right-btn-d-b.png');
export const buttonLeftGB = require('../assets/images/buttons/left-btn-g-b.png');
export const buttonMidGB = require('../assets/images/buttons/middle-btn-g-b.png');
export const buttonRightGB = require('../assets/images/buttons/right-btn-g-b.png');

export const borderFreeGame = require('../assets/images/borders/bg-free-game-init.png');
export const borderCampaign = require('../assets/images/borders/bg-campaign-init.png');
export const borderProgressBar = require('../assets/images/borders/bg-progress-bar-icon-init.png');
export const borderHomeHover = require('../assets/images/borders/bg-home-hover.png');
export const progressBar = require('../assets/images/borders/progress-bar.png');

export const landingBackground = require('../assets/images/backgrounds/landing/background-landing.png');
export const landingCard = require('../assets/images/components/landing/landing-card.png');
export const irregularBlock = require('../assets/images/backgrounds/landing/irregular-block.png');
export const landingSection1Bg = require('../assets/images/backgrounds/landing/background-section-1.jpg');
export const landingSection2Bg = require('../assets/images/backgrounds/landing/background-section-2.jpg');
export const landingSection3Bg = require('../assets/images/backgrounds/landing/background-section-3.jpg');
export const landingSection4Bg = require('../assets/images/backgrounds/landing/background-section-4.jpg');
export const landingCat = require('../assets/images/components/landing/landing-cat.png');
export const landingBadgeImage = require('../assets/images/components/landing/badge-image.png');

export const turn = require('../assets/images/components/how-to-play/turn.png');

export const howAttackPoster = require('../assets/video/how-to-play/attack-poster.jpg');
export const howEnhancePoster = require('../assets/video/how-to-play/enhance-poster.jpg');
export const howArrow = require('../assets/images/components/how-to-play/arrow.png');
export const irregularBlock1 = require('../assets/images/backgrounds/how-to-play/irregular-block-1.png');
export const irregularBlock2 = require('../assets/images/backgrounds/how-to-play/irregular-block-2.png');
export const irregularBlock3 = require('../assets/images/backgrounds/how-to-play/irregular-block-3.png');
export const howSection1Bg = require('../assets/images/backgrounds/how-to-play/how-bg-section-1.jpg');
export const howSection2Bg = require('../assets/images/backgrounds/how-to-play/how-bg-section-2.jpg');
export const howSection3Bg = require('../assets/images/backgrounds/how-to-play/how-bg-section-3.jpg');
export const howSection4Bg = require('../assets/images/backgrounds/how-to-play/how-bg-section-4.jpg');
export const emptyGameboard = require('../assets/images/backgrounds/how-to-play/empty-gameboard-bg.png');
export const avatarsHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/avatars.png');
export const pointsHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/points.png');
export const playButtonsHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/play-button.png');
export const deckcardsHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/deck-cards.png');
export const boardcardHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/board-card.png');
export const boardcardsHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/board-cards.png');
export const boardcardsHowWhiteToPlay = require('../assets/images/components/how-to-play/game-board-elements/board-cards-white.png');
export const boardcardsHowBlackToPlay = require('../assets/images/components/how-to-play/game-board-elements/board-cards-black.png');
export const handcardsHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/hand-cards.png');
export const enemycardsHowToPlay = require('../assets/images/components/how-to-play/game-board-elements/enemy-cards.png');

export const howCard = require('../assets/images/components/how-to-play/card.png');
export const howCard2 = require('../assets/images/components/how-to-play/card-2.png');
export const howCard3 = require('../assets/images/components/how-to-play/card-3.png');
export const howCards = require('../assets/images/components/how-to-play/cards.png');
export const howCards2 = require('../assets/images/components/how-to-play/cards-2.png');
export const howCards3 = require('../assets/images/components/how-to-play/cards-3.png');
export const howStep1 = require('../assets/images/components/how-to-play/how-step-1.png');
export const howTip1 = require('../assets/images/components/how-to-play/tip-1.png');
export const howTip2 = require('../assets/images/components/how-to-play/tip-2.png');
export const howTip3 = require('../assets/images/components/how-to-play/tip-3.png');
export const howTip4 = require('../assets/images/components/how-to-play/tip-4.png');
export const howTip5 = require('../assets/images/components/how-to-play/tip-5.png');
export const howAction1 = require('../assets/images/components/how-to-play/fact-check.png');
export const howAction2 = require('../assets/images/components/how-to-play/impact.png');
export const howAction3 = require('../assets/images/components/how-to-play/lose.png');
export const howAction4 = require('../assets/images/components/how-to-play/win.png');
export const howAction5 = require('../assets/images/components/how-to-play/barrier.png');
export const howAction6 = require('../assets/images/components/how-to-play/ocult.png');
export const howRubi = require('../assets/images/components/how-to-play/rubi.png');
export const howGold = require('../assets/images/components/how-to-play/gold.png');
export const glass1 = require('../assets/images/components/how-to-play/glass-1.png');
export const glass2 = require('../assets/images/components/how-to-play/glass-2.png');
export const glass3 = require('../assets/images/components/how-to-play/glass-3.png');
export const glass4 = require('../assets/images/components/how-to-play/glass-4.png');

export const homeBackground = require('../assets/images/backgrounds/home/background-home.jpg');
export const homeAgent = require('../assets/images/components/home/agent.png');
export const homeBot = require('../assets/images/components/home/bot.png');

export const pwaLinkBg = require('../assets/images/backgrounds/pwa-link/pwa-link-bg.png');
export const catLogin = require('../assets/images/components/login/cat-login.png');
export const familyLogin = require('../assets/images/components/login/family-login.png');
export const imageCardQanon = require('../assets/images/cards/qanon.png');
export const iosAddHome = require('../assets/images/icons/login/iosAddHome.png');
export const iosShare = require('../assets/images/icons/login/iosShare.png');
export const angleLoginDate = require('../assets/images/icons/login/angle-login-date.png');

export const borderNickname = require('../assets/images/borders/bg-nickname-login.png');
export const borderDate = require('../assets/images/borders/bg-date-login.png');

export const goBackIcon = require('../assets/images/icons/login/go-back.png');
export const borderEditNameDeck = require('../assets/images/borders/modal-edit-name.png');
export const borderEditAvatar = require('../assets/images/borders/modal-edit-avatar.png');

export const deckBackground: { [name: string]: ImageSourcePropType } = {
  deck_1: require('../assets/images/components/deck-editor/deck-images/bg/deck-1.png'),
  deck_2: require('../assets/images/components/deck-editor/deck-images/bg/deck-2.png'),
  deck_3: require('../assets/images/components/deck-editor/deck-images/bg/deck-3.png'),
  deck_4: require('../assets/images/components/deck-editor/deck-images/bg/deck-4.png'),
  deck_5: require('../assets/images/components/deck-editor/deck-images/bg/deck-5.png')
};

export const deckBackgroundSlides: { [name: string]: ImageSourcePropType } = {
  deck_1: require('../assets/images/components/deck-editor/deck-images/slider/mazo1.png'),
  deck_2: require('../assets/images/components/deck-editor/deck-images/slider/mazo2.png'),
  deck_3: require('../assets/images/components/deck-editor/deck-images/slider/mazo3.png'),
  deck_4: require('../assets/images/components/deck-editor/deck-images/slider/mazo4.png'),
  deck_5: require('../assets/images/components/deck-editor/deck-images/slider/mazo5.png')
};

export const missionBackground: { [name: string]: ImageSourcePropType } = {
  tutorial_1_male: require('../assets/images/backgrounds/campaign/desktop/tutorial_1_male.jpg'),
  tutorial_1_female: require('../assets/images/backgrounds/campaign/desktop/tutorial_1_female.jpg'),
  tutorial_2_male: require('../assets/images/backgrounds/campaign/desktop/tutorial_2_male.jpg'),
  tutorial_2_female: require('../assets/images/backgrounds/campaign/desktop/tutorial_2_female.jpg'),
  tutorial_3_male: require('../assets/images/backgrounds/campaign/desktop/tutorial_3_male.jpg'),
  tutorial_3_female: require('../assets/images/backgrounds/campaign/desktop/tutorial_3_female.jpg'),
  tutorial_4_male: require('../assets/images/backgrounds/campaign/desktop/tutorial_4_male.jpg'),
  tutorial_4_female: require('../assets/images/backgrounds/campaign/desktop/tutorial_4_female.jpg'),
  mission_1: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-1.jpg'),
  mission_2: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-2.jpg'),
  mission_3: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-3.jpg'),
  mission_4: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-4.jpg'),
  mission_5: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-5.jpg'),
  mission_6: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-6.jpg'),
  mission_7: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-1.jpg'),
  mission_8: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-1.jpg'),
  mission_9_male: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-9_male.jpg'),
  mission_9_female: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-9_female.jpg'),
  mission_10: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-10.jpg'),
  mission_11: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-1.jpg'),
  mission_12: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-1.jpg'),
  mission_13: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-13.jpg'),
  mission_14: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-1.jpg'),
  mission_15_male: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-15_male.jpg'),
  mission_15_female: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-15_female.jpg'),
  mission_16: require('../assets/images/backgrounds/campaign/desktop/campaign-bg-1-16.jpg')
};

export const missionBackgroundMobile: { [name: string]: ImageSourcePropType } = {
  tutorial_1_male: require('../assets/images/backgrounds/campaign/mobile/tutorial_1_male.jpg'),
  tutorial_1_female: require('../assets/images/backgrounds/campaign/mobile/tutorial_1_female.jpg'),
  tutorial_2_male: require('../assets/images/backgrounds/campaign/mobile/tutorial_2_male.jpg'),
  tutorial_2_female: require('../assets/images/backgrounds/campaign/mobile/tutorial_2_female.jpg'),
  tutorial_3_male: require('../assets/images/backgrounds/campaign/mobile/tutorial_3_male.jpg'),
  tutorial_3_female: require('../assets/images/backgrounds/campaign/mobile/tutorial_3_female.jpg'),
  tutorial_4_male: require('../assets/images/backgrounds/campaign/mobile/tutorial_4_male.jpg'),
  tutorial_4_female: require('../assets/images/backgrounds/campaign/mobile/tutorial_4_female.jpg'),
  mission_1: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-1.jpg'),
  mission_2: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-2.jpg'),
  mission_3: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-3.jpg'),
  mission_4: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-4.jpg'),
  mission_5: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-5.jpg'),
  mission_6: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-6.jpg'),
  mission_7: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-1.jpg'),
  mission_8: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-1.jpg'),
  mission_9_male: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-9_male.jpg'),
  mission_9_female: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-9_female.jpg'),
  mission_10: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-10.jpg'),
  mission_11: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-1.jpg'),
  mission_12: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-1.jpg'),
  mission_13: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-13.jpg'),
  mission_14: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-1.jpg'),
  mission_15_male: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-15_male.jpg'),
  mission_15_female: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-15_female.jpg'),
  mission_16: require('../assets/images/backgrounds/campaign/mobile/campaign-bg-1-16.jpg')
};

export const campaignFrame: { [name: string]: ImageSourcePropType } = {
  current: require('../assets/images/components/campaign/frames/campaign-frame-current.png'),
  done: require('../assets/images/components/campaign/frames/campaign-frame-done.png'),
  default: require('../assets/images/components/campaign/frames/campaign-frame-default.png')
};

export const campaignCheck = require('../assets/images/components/campaign/frames/check.png');
export const campaignEnemyUnknown = require('../assets/images/components/campaign/enemies/enemy-unknown.png');

export const missionEnemyImage: { [name: string]: ImageSourcePropType } = {
  tutorial_1: require('../assets/images/components/campaign/enemies/tutorial1.png'),
  tutorial_2: require('../assets/images/components/campaign/enemies/tutorial2.png'),
  tutorial_3: require('../assets/images/components/campaign/enemies/tutorial3.png'),
  tutorial_4: require('../assets/images/components/campaign/enemies/tutorial4.png'),
  mission_1: require('../assets/images/components/campaign/enemies/manolo-uncle.png'),
  mission_2: require('../assets/images/components/campaign/enemies/chato.png'),
  mission_3: require('../assets/images/components/campaign/enemies/negana.png'),
  mission_4: require('../assets/images/components/campaign/enemies/populetas.png'),
  mission_5: require('../assets/images/components/campaign/enemies/angry-mass.png'),
  mission_6: require('../assets/images/components/campaign/enemies/bot-bu3n0.png'),
  mission_7: require('../assets/images/components/campaign/enemies/hater.png'),
  mission_8: require('../assets/images/components/campaign/enemies/dioxi-doxi.png'),
  mission_9: require('../assets/images/components/campaign/enemies/cat.png'),
  mission_10: require('../assets/images/components/campaign/enemies/konspiratron.png'),
  mission_11: require('../assets/images/components/campaign/enemies/troll-parents.png'),
  mission_12: require('../assets/images/components/campaign/enemies/populetas.png'),
  mission_13: require('../assets/images/components/campaign/enemies/dora-pirat.png'),
  mission_14_female: require('../assets/images/components/campaign/enemies/agent-f_f.png'),
  mission_14_male: require('../assets/images/components/campaign/enemies/agent-f_m.png'),
  mission_15: require('../assets/images/components/campaign/enemies/konspiratron.png'),
  mission_16: require('../assets/images/components/campaign/enemies/infolisto.png')
};
export const goBackIconWhite = require('../assets/images/icons/campaign/goBack-white.png');

export const winRight = require('../assets/images/components/game-board/win-lose/winRight.png');
export const winLeft = require('../assets/images/components/game-board/win-lose/winLeft.png');
export const winTitle = require('../assets/images/components/game-board/win-lose/winTitle.png');
export const winBg = require('../assets/images/components/game-board/win-lose/winBg.png');
export const winLoseAvatarBackground = require('../assets/images/components/game-board/win-lose/avatar-background.png');
export const loseRight = require('../assets/images/components/game-board/win-lose/loseRight.png');
export const loseLeft = require('../assets/images/components/game-board/win-lose/loseLeft.png');
export const loseTitle = require('../assets/images/components/game-board/win-lose/loseTitle.png');
export const ellipseWinLose = require('../assets/images/components/game-board/win-lose/ellipse.png');
export const configBg = require('../assets/images/borders/config-bg.png');
export const thumbIcon = require('../assets/images/icons/commons/user-menu/thumb-icon.png');
export const thumbIcon2 = require('../assets/images/icons/commons/user-menu/thumb-icon-2.png');
export const switchBar = require('../assets/images/icons/commons/user-menu/switch-bar.png');

export const backgroundMissionDialog = require('../assets/images/backgrounds/mission-dialog/background-mission-dialog.jpg');

export const backgroundsMissionDialogMobile: { [name: string]: ImageSourcePropType } = {
  bedroom_empty: require('../assets/images/backgrounds/mission-dialog/mobile/bedroom_empty.jpg'),
  bedroom_f: require('../assets/images/backgrounds/mission-dialog/mobile/bedroom_f.jpg'),
  bedroom_m: require('../assets/images/backgrounds/mission-dialog/mobile/bedroom_m.jpg'),
  mission_dialog: require('../assets/images/backgrounds/mission-dialog/mobile/mission_dialog.jpg'),
  hall_afo_empty: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_empty.jpg'),
  hall_afo_scene1: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene1.jpg'),
  hall_afo_f: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_f.jpg'),
  hall_afo_m: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_m.jpg'),
  hall_afo_scene4_f: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene4_f.jpg'),
  hall_afo_scene4_m: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene4_m.jpg'),
  hall_afo_scene5_f: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene5_f.jpg'),
  hall_afo_scene5_m: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene5_m.jpg'),
  hall_afo_scene13_f: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene13_f.jpg'),
  hall_afo_scene13_m: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene13_m.jpg'),
  hall_afo_scene19_f: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene19_f.jpg'),
  hall_afo_scene19_m: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene19_m.jpg'),
  hall_afo_scene19: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene19.jpg'),
  hall_afo_scene20_f: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene20_f.jpg'),
  hall_afo_scene20_m: require('../assets/images/backgrounds/mission-dialog/mobile/hall_afo_scene20_m.jpg'),
  infolisto_scene16: require('../assets/images/backgrounds/mission-dialog/mobile/infolisto_scene16.jpg'),
  intercomunicator_scene12_a: require('../assets/images/backgrounds/mission-dialog/mobile/intercomunicator_scene12_a.jpg'),
  intercomunicator_scene12_b: require('../assets/images/backgrounds/mission-dialog/mobile/intercomunicator_scene12_b.jpg'),
  living_empty: require('../assets/images/backgrounds/mission-dialog/mobile/living_empty.jpg'),
  living_f: require('../assets/images/backgrounds/mission-dialog/mobile/living_f.jpg'),
  living_m: require('../assets/images/backgrounds/mission-dialog/mobile/living_m.jpg'),
  living_scene7: require('../assets/images/backgrounds/mission-dialog/mobile/living_scene7.jpg'),
  living_scene8: require('../assets/images/backgrounds/mission-dialog/mobile/living_scene8.jpg'),
  living_scene14_f: require('../assets/images/backgrounds/mission-dialog/mobile/living_scene14_f.jpg'),
  living_scene14_m: require('../assets/images/backgrounds/mission-dialog/mobile/living_scene14_m.jpg'),
  living_scene17: require('../assets/images/backgrounds/mission-dialog/mobile/living_scene17.jpg'),
  studio_konspiratron_scene18_empty: require('../assets/images/backgrounds/mission-dialog/mobile/studio_konspiratron_scene18_empty.jpg'),
  studio_konspiratron_scene18: require('../assets/images/backgrounds/mission-dialog/mobile/studio_konspiratron_scene18.jpg'),
  studio_konspiratron_scene18_f: require('../assets/images/backgrounds/mission-dialog/mobile/studio_konspiratron_scene18_f.jpg'),
  studio_konspiratron_scene18_m: require('../assets/images/backgrounds/mission-dialog/mobile/studio_konspiratron_scene18_m.jpg'),
  party_scene18_f: require('../assets/images/backgrounds/mission-dialog/mobile/party_scene18_f.jpg'),
  party_scene18_m: require('../assets/images/backgrounds/mission-dialog/mobile/party_scene18_m.jpg'),
  space_scene23: require('../assets/images/backgrounds/mission-dialog/mobile/space_scene23.jpg'),
  office_scene6: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene6.jpg'),
  office_scene6_f: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene6_f.jpg'),
  office_scene6_m: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene6_m.jpg'),
  office_scene9: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene9.jpg'),
  office_scene9_f: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene9_f.jpg'),
  office_scene9_m: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene9_m.jpg'),
  office_scene11_f: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene11_f.jpg'),
  office_scene11_m: require('../assets/images/backgrounds/mission-dialog/mobile/office_scene11_m.jpg')
};

export const backgroundsMissionDialog: { [name: string]: ImageSourcePropType } = {
  bedroom_empty: require('../assets/images/backgrounds/mission-dialog/desktop/bedroom_empty.jpg'),
  bedroom_f: require('../assets/images/backgrounds/mission-dialog/desktop/bedroom_f.jpg'),
  bedroom_m: require('../assets/images/backgrounds/mission-dialog/desktop/bedroom_m.jpg'),
  mission_dialog: require('../assets/images/backgrounds/mission-dialog/desktop/mission_dialog.jpg'),
  hall_afo_empty: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_empty.jpg'),
  hall_afo_scene1: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene1.jpg'),
  hall_afo_f: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_f.jpg'),
  hall_afo_m: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_m.jpg'),
  hall_afo_scene4_f: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene4_f.jpg'),
  hall_afo_scene4_m: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene4_m.jpg'),
  hall_afo_scene5_f: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene5_f.jpg'),
  hall_afo_scene5_m: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene5_m.jpg'),
  hall_afo_scene13_f: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene13_f.jpg'),
  hall_afo_scene13_m: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene13_m.jpg'),
  hall_afo_scene19_f: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene19_f.jpg'),
  hall_afo_scene19_m: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene19_m.jpg'),
  hall_afo_scene19: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene19.jpg'),
  hall_afo_scene20_f: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene20_f.jpg'),
  hall_afo_scene20_m: require('../assets/images/backgrounds/mission-dialog/desktop/hall_afo_scene20_m.jpg'),
  infolisto_scene16: require('../assets/images/backgrounds/mission-dialog/desktop/infolisto_scene16.jpg'),
  intercomunicator_scene12_a: require('../assets/images/backgrounds/mission-dialog/desktop/intercomunicator_scene12_a.jpg'),
  intercomunicator_scene12_b: require('../assets/images/backgrounds/mission-dialog/desktop/intercomunicator_scene12_b.jpg'),
  living_empty: require('../assets/images/backgrounds/mission-dialog/desktop/living_empty.jpg'),
  living_f: require('../assets/images/backgrounds/mission-dialog/desktop/living_f.jpg'),
  living_m: require('../assets/images/backgrounds/mission-dialog/desktop/living_m.jpg'),
  living_scene7: require('../assets/images/backgrounds/mission-dialog/desktop/living_scene7.jpg'),
  living_scene8: require('../assets/images/backgrounds/mission-dialog/desktop/living_scene8.jpg'),
  living_scene14_f: require('../assets/images/backgrounds/mission-dialog/desktop/living_scene14_f.jpg'),
  living_scene14_m: require('../assets/images/backgrounds/mission-dialog/desktop/living_scene14_m.jpg'),
  living_scene17: require('../assets/images/backgrounds/mission-dialog/desktop/living_scene17.jpg'),
  studio_konspiratron_scene18_empty: require('../assets/images/backgrounds/mission-dialog/desktop/studio_konspiratron_scene18_empty.jpg'),
  studio_konspiratron_scene18: require('../assets/images/backgrounds/mission-dialog/desktop/studio_konspiratron_scene18.jpg'),
  studio_konspiratron_scene18_f: require('../assets/images/backgrounds/mission-dialog/desktop/studio_konspiratron_scene18_f.jpg'),
  studio_konspiratron_scene18_m: require('../assets/images/backgrounds/mission-dialog/desktop/studio_konspiratron_scene18_m.jpg'),
  party_scene18_f: require('../assets/images/backgrounds/mission-dialog/desktop/party_scene18_f.jpg'),
  party_scene18_m: require('../assets/images/backgrounds/mission-dialog/desktop/party_scene18_m.jpg'),
  space_scene23: require('../assets/images/backgrounds/mission-dialog/desktop/space_scene23.jpg'),
  office_scene6: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene6.jpg'),
  office_scene6_f: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene6_f.jpg'),
  office_scene6_m: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene6_m.jpg'),
  office_scene9: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene9.jpg'),
  office_scene9_f: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene9_f.jpg'),
  office_scene9_m: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene9_m.jpg'),
  office_scene11_f: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene11_f.jpg'),
  office_scene11_m: require('../assets/images/backgrounds/mission-dialog/desktop/office_scene11_m.jpg')
};

export const characterDialogImage: { [name: string]: ImageSourcePropType } = {
  player_f: require('../assets/images/components/mision-dialog/characters/agent-f-character.png'),
  player_m: require('../assets/images/components/mision-dialog/characters/agent-m-character.png'),
  agent_f_f: require('../assets/images/components/mision-dialog/characters/agent-f-character.png'),
  agent_f_m: require('../assets/images/components/mision-dialog/characters/agent-m-character.png'),
  [ActorEnum.DATA_DIRECTOR]: require('../assets/images/components/mision-dialog/characters/data-character.png'),
  [ActorEnum.MOM]: require('../assets/images/components/mision-dialog/characters/mom.png'),
  [ActorEnum.DAD]: require('../assets/images/components/mision-dialog/characters/dad.png'),
  [ActorEnum.CAT]: require('../assets/images/components/mision-dialog/characters/cat.png'),
  [ActorEnum.AGENT_V]: require('../assets/images/components/mision-dialog/characters/agent-v.png'),
  [ActorEnum.AGENT_W]: require('../assets/images/components/mision-dialog/characters/agent-w.png'),
  [ActorEnum.BOT_M4L0]: require('../assets/images/components/mision-dialog/characters/b0t-m4l0.png'),
  [ActorEnum.BOT_BU3N0]: require('../assets/images/components/mision-dialog/characters/b0t-m4l0.png'),
  [ActorEnum.FAKER_8000]: require('../assets/images/components/mision-dialog/characters/f_8000.png'),
  [ActorEnum.COPYFAKE_BOT]: require('../assets/images/components/mision-dialog/characters/copyfake.png'),
  [ActorEnum.MANOLO_UNCLE]: require('../assets/images/components/mision-dialog/characters/manolo_uncle.png'),
  [ActorEnum.CHATO]: require('../assets/images/components/mision-dialog/characters/chato.png'),
  [ActorEnum.NEGANA]: require('../assets/images/components/mision-dialog/characters/negana.png'),
  [ActorEnum.KONSPIRATRON]: require('../assets/images/components/mision-dialog/characters/konspiratron.png'),
  [ActorEnum.TROLL_PARENTS]: require('../assets/images/components/mision-dialog/characters/troll-parents.png'),
  [ActorEnum.ROSAURA]: require('../assets/images/components/mision-dialog/characters/rosaura.png'),
  [ActorEnum.DORA_PIRAT]: require('../assets/images/components/mision-dialog/characters/pirata-dora.png'),
  [ActorEnum.METEOR]: require('../assets/images/components/mision-dialog/characters/meteor.png'),
  [ActorEnum.DIOXI_DOXI]: require('../assets/images/components/mision-dialog/characters/dioxi-doxi.png'),
  [ActorEnum.HATER]: require('../assets/images/components/mision-dialog/characters/hater.png'),
  [ActorEnum.ANGRY_MASS]: require('../assets/images/components/mision-dialog/characters/angry-mass.png'),
  [ActorEnum.POPULETAS]: require('../assets/images/components/mision-dialog/characters/populetas.png'),
  [ActorEnum.INFOLISTO]: require('../assets/images/components/mision-dialog/characters/infolisto.png'),
  [ActorEnum.AGENT_F_F_EVIL]: require('../assets/images/components/mision-dialog/characters/agent-f-f-evil.png'),
  [ActorEnum.AGENT_F_M_EVIL]: require('../assets/images/components/mision-dialog/characters/agent-f-m-evil.png')
};

export const avatarImage: { [name: string]: ImageSourcePropType } = {
  agent_f_f: require('../assets/images/avatars/agent-f_f.png'),
  agent_f_m: require('../assets/images/avatars/agent-f_m.png'),
  [ActorEnum.DATA_DIRECTOR]: require('../assets/images/avatars/data.png'),
  [ActorEnum.MOM]: require('../assets/images/avatars/mom.png'),
  [ActorEnum.DAD]: require('../assets/images/avatars/dad.png'),
  [ActorEnum.CAT]: require('../assets/images/avatars/cat.png'),
  [ActorEnum.AGENT_V]: require('../assets/images/avatars/agent-v.png'),
  [ActorEnum.AGENT_W]: require('../assets/images/avatars/agent-w.png'),
  [ActorEnum.BOT_M4L0]: require('../assets/images/avatars/bot_m4l0.png'),
  [ActorEnum.BOT_BU3N0]: require('../assets/images/avatars/bot_m4l0.png'),
  [ActorEnum.FAKER_8000]: require('../assets/images/avatars/f_8000.png'),
  [ActorEnum.COPYFAKE_BOT]: require('../assets/images/avatars/copyfake_bot.png'),
  [ActorEnum.MANOLO_UNCLE]: require('../assets/images/avatars/manolo_uncle.png'),
  [ActorEnum.CHATO]: require('../assets/images/avatars/chato.png'),
  [ActorEnum.NEGANA]: require('../assets/images/avatars/negana.png'),
  [ActorEnum.KONSPIRATRON]: require('../assets/images/avatars/konspiratron.png'),
  [ActorEnum.TROLL_PARENTS]: require('../assets/images/avatars/troll-parents.png'),
  [ActorEnum.ROSAURA]: require('../assets/images/avatars/rosaura.png'),
  [ActorEnum.DORA_PIRAT]: require('../assets/images/avatars/dora-pirat.png'),
  [ActorEnum.METEOR]: require('../assets/images/avatars/meteor.png'),
  [ActorEnum.DIOXI_DOXI]: require('../assets/images/avatars/dioxi-doxi.png'),
  [ActorEnum.HATER]: require('../assets/images/avatars/hater.png'),
  [ActorEnum.ANGRY_MASS]: require('../assets/images/avatars/angry_mass.png'),
  [ActorEnum.POPULETAS]: require('../assets/images/avatars/populetas.png'),
  [ActorEnum.INFOLISTO]: require('../assets/images/avatars/infolisto.png'),
  [ActorEnum.FELINOR]: require('../assets/images/avatars/felinor.png'),
  [ActorEnum.AGENT_F_F_EVIL]: require('../assets/images/avatars/agent-f-f-evil.png'),
  [ActorEnum.AGENT_F_M_EVIL]: require('../assets/images/avatars/agent-f-m-evil.png')
};

export const redDialogBubbleTopLeft = require('../assets/images/components/mision-dialog/red/dialog-bubble-top-left.png');
export const redDialogBubbleTopRight = require('../assets/images/components/mision-dialog/red/dialog-bubble-top-right.png');
export const redDialogBubbleMiddle = require('../assets/images/components/mision-dialog/red/dialog-bubble-middle.png');
export const redDialogBubbleBottom = require('../assets/images/components/mision-dialog/red/dialog-bubble-bottom.png');

export const blueDialogBubbleTopLeft = require('../assets/images/components/mision-dialog/blue/dialog-bubble-top-left.png');
export const blueDialogBubbleTopRight = require('../assets/images/components/mision-dialog/blue/dialog-bubble-top-right.png');
export const blueDialogBubbleMiddle = require('../assets/images/components/mision-dialog/blue/dialog-bubble-middle.png');
export const blueDialogBubbleBottom = require('../assets/images/components/mision-dialog/blue/dialog-bubble-bottom.png');

export const blueBubbleTop = require('../assets/images/components/game-board/avatars-container/bubble/bubble_top_blue.png');
export const blueBubbleMiddle = require('../assets/images/components/game-board/avatars-container/bubble/bubble_middle_blue.png');
export const blueBubbleBottom = require('../assets/images/components/game-board/avatars-container/bubble/bubble_bottom_blue.png');
export const blueBubbleTriangle = require('../assets/images/components/game-board/avatars-container/bubble/bubble_triangle_blue.png');
export const yellowBubbleTop = require('../assets/images/components/game-board/avatars-container/bubble/bubble_top_yellow.png');
export const yellowBubbleMiddle = require('../assets/images/components/game-board/avatars-container/bubble/bubble_middle_yellow.png');
export const yellowBubbleBottom = require('../assets/images/components/game-board/avatars-container/bubble/bubble_bottom_yellow.png');
export const yellowBubbleTriangle = require('../assets/images/components/game-board/avatars-container/bubble/bubble_triangle_yellow.png');

export const launchBattleLeft = require('../assets/images/components/launch-battle/launch-battle-bg-orange.png');
export const launchBattleRight = require('../assets/images/components/launch-battle/launch-battle-bg-blue.png');
export const launchBattleTopOrange = require('../assets/images/components/launch-battle/launch-battle-top-blue.png');
export const launchBattleTopBlue = require('../assets/images/components/launch-battle/launch-battle-top-orange.png');
export const launchBattleBackground = require('../assets/images/backgrounds/launch-battle/launch-battle-background.png');
export const launchBattleTitle = require('../assets/images/components/launch-battle/launch-battle-title.png');

export const avatarBackgroundUserProfile = require('../assets/images/components/user-profile/avatar-background.png');
export const avatarBackgroundCheck = require('../assets/images/components/user-profile/avatar-background-check.png');
export const nextRewardProfile = require('../assets/images/components/user-profile/next-reward-bg.png');
export const nextRewardProfileMobile = require('../assets/images/components/user-profile/next-reward-bg-mobile.png');

export const cardRewardProfile = require('../assets/images/icons/user-profile/cards-reward.png');
export const expRewardProfile = require('../assets/images/icons/user-profile/exp-reward.png');
export const avatarRewardProfile = require('../assets/images/icons/user-profile/avatar-reward.png');
export const titleRewardProfile = require('../assets/images/icons/user-profile/title-reward.png');
export const checkIconProfile = require('../assets/images/icons/user-profile/check-icon.png');

export const bottomMenuProfile = require('../assets/images/components/user-profile/bottom-menu.png');
export const bottomMenuItemProfile = require('../assets/images/components/user-profile/bottom-menu-item.png');
export const bottomMenuItemActiveProfile = require('../assets/images/components/user-profile/bottom-menu-item-active.png');

export const titleBackgroundProfile = require('../assets/images/components/user-profile/title-background.png');
export const checkTitleProfile = require('../assets/images/components/user-profile/checkbox-title-profile-checked.png');
export const checkBoxTitleProfile = require('../assets/images/components/user-profile/checkbox-title-profile.png');
export const activeTitleBg = require('../assets/images/components/user-profile/title-active-bg.png');
export const rankingBgProfile = require('../assets/images/components/user-profile/ranking-profile-bg.png');
export const backgroundProfile = require('../assets/images/backgrounds/background-user-profile.png');
export const launchBattleAvatarBgLeft = require('../assets/images/components/launch-battle/avatar-bg-left.png');
export const launchBattleAvatarBgRight = require('../assets/images/components/launch-battle/avatar-bg-right.png');

export const rewardCard = require('../assets/images/components/rewards/reward-card.png');
export const rewardTitle = require('../assets/images/components/rewards/reward-title.png');
export const rewardDeck = require('../assets/images/components/rewards/reward-deck.png');
export const rewardAvatar = require('../assets/images/components/rewards/reward-avatar.png');
export const rewardTitleOpen = require('../assets/images/components/rewards/reward-title-open.png');
export const rewardAvatarBackground = require('../assets/images/components/rewards/reward-avatar-bg.png');
export const backgroundReward = require('../assets/images/backgrounds/rewards/background-rewards.jpg');

export const twitterAvatars: ImageSourcePropType[] = [
  require('../assets/images/components/tweet-msg/avatar1-male.png'),
  require('../assets/images/components/tweet-msg/avatar2-male.png'),
  require('../assets/images/components/tweet-msg/avatar3-female.png'),
  require('../assets/images/components/tweet-msg/avatar4-female.png'),
  require('../assets/images/components/tweet-msg/avatar5-male.png'),
  require('../assets/images/components/tweet-msg/avatar6-female.png'),
  require('../assets/images/components/tweet-msg/avatar7-male.png'),
  require('../assets/images/components/tweet-msg/avatar8-male.png'),
  require('../assets/images/components/tweet-msg/avatar9-female.png'),
  require('../assets/images/components/tweet-msg/avatar10-female.png')
];

export const realTwitterAvatars: Record<TweetsImages, ImageSourcePropType> = {
  _CSIC: require('../assets/images/components/tweet-msg/real/csic.jpg'),
  _EFE_NOTICIAS: require('../assets/images/components/tweet-msg/real/efe.jpg'),
  FACÜA: require('../assets/images/components/tweet-msg/real/facua.jpg'),
  F_B_I: require('../assets/images/components/tweet-msg/real/fbi.jpg'),
  FERNANDOSIMÓN_: require('../assets/images/components/tweet-msg/real/fernandosimon.jpg'),
  IGUALDADGOV: require('../assets/images/components/tweet-msg/real/igualdad.jpg'),
  'LGTBIQ+GOB': require('../assets/images/components/tweet-msg/real/lgtbi.jpg'),
  N_A_S_A: require('../assets/images/components/tweet-msg/real/nasa.jpg'),
  NATTURE: require('../assets/images/components/tweet-msg/real/nature.jpg'),
  '0NU_ES': require('../assets/images/components/tweet-msg/real/onu.jpg'),
  POLICIA__: require('../assets/images/components/tweet-msg/real/policia.jpg'),
  RTVË: require('../assets/images/components/tweet-msg/real/rtve.jpg'),
  CIENCIARTVE_: require('../assets/images/components/tweet-msg/real/rtve_ciencia.jpg'),
  SECTANOTICIAS: require('../assets/images/components/tweet-msg/real/sexta_noticias.jpg')
};

export const tweetBubbleTop = require('../assets/images/components/tweet-msg/bubble/bubble_top.png');
export const tweetBubbleBottom = require('../assets/images/components/tweet-msg/bubble/bubble_bottom.png');
export const tweetBubbleMiddle = require('../assets/images/components/tweet-msg/bubble/bubble_middle.png');
export const tweetVerified = require('../assets/images/components/tweet-msg/check-twitter.png');

export const errorBot = require('../assets/images/components/error-screen/error-bot.png');
export const educationalCard = {
  top: require('../assets/images/components/card-educational/card-educational-top.png'),
  middle: require('../assets/images/components/card-educational/card-educational-middle.png'),
  bottom: require('../assets/images/components/card-educational/card-educational-bottom.png'),
  triangle: require('../assets/images/components/card-educational/card-educational-triangle.png'),
  info: require('../assets/images/components/card-educational/card-educational-info.png')
};

export const rankingBot = require('../assets/images/components/ranking/bot.png');
export const rankingDoxi = require('../assets/images/components/ranking/doxi.png');

export const whatsappCardImages = {
  sendMessage: {
    left: require('../assets/images/components/card-accesories/whatsapp/whatsapp-message-left.png'),
    middle: require('../assets/images/components/card-accesories/whatsapp/whatsapp-message-center.png'),
    right: require('../assets/images/components/card-accesories/whatsapp/whatsapp-message-right.png')
  },
  message: {
    top: require('../assets/images/components/card-accesories/whatsapp/whatsapp-container-top.png'),
    middle: require('../assets/images/components/card-accesories/whatsapp/whatsapp-container-middle.png'),
    bottom: require('../assets/images/components/card-accesories/whatsapp/whatsapp-container-bottom.png')
  },
  audio: require('../assets/images/components/card-accesories/whatsapp/whatsapp-audio.png'),
  background: require('../assets/images/components/card-accesories/whatsapp/whatsapp-bg.png'),
  emoticon: require('../assets/images/components/card-accesories/whatsapp/whatsapp-emoticon.png'),
  goBack: require('../assets/images/components/card-accesories/whatsapp/whatsapp-goback.png'),
  icon: require('../assets/images/components/card-accesories/whatsapp/whatsapp-icon.png')
};

export const twitterCardImages = {
  background: require('../assets/images/components/card-accesories/twitter/twitter-bg.png'),
  icons: {
    retweet: require('../assets/images/components/card-accesories/twitter/twitter-retweet.png'),
    chat: require('../assets/images/components/card-accesories/twitter/twitter-chat.png'),
    like: require('../assets/images/components/card-accesories/twitter/twitter-like.png'),
    likeUpgraded: require('../assets/images/components/card-accesories/twitter/twitter-like-red.png'),
    retweetUpgraded: require('../assets/images/components/card-accesories/twitter/twitter-retweet-blue.png'),
    chatUpgraded: require('../assets/images/components/card-accesories/twitter/twitter-chat-grey.png')
  },
  icon: require('../assets/images/components/card-accesories/twitter/twitter-icon.png')
};

export const missionDialogAnimationPoster: { [name: string]: ImageSourcePropType } = {
  info_listo: require('../assets/video/mission-dialogue/info_listo.jpg'),
  gatito: require('../assets/video/mission-dialogue/gatito.jpg')
};

export const howTop = require('../assets/images/icons/how-to-play/icon-top.png');
export const howBottom = require('../assets/images/icons/how-to-play/icon-bottom.png');
export const backgroundDeckSelector = require('../assets/images/backgrounds/deck-selector/background-deck-selector.png');
export const newDeckDeckSelector = require('../assets/images/components/deck-selector/newDeck.png');
export const newDeckPlus = require('../assets/images/components/deck-selector/newDeckPlus.png');

export const deckSelectorTitlesBg: ImageSourcePropType[] = [
  require('../assets/images/components/deck-selector/title1-bg.png'),
  require('../assets/images/components/deck-selector/title2-bg.png'),
  require('../assets/images/components/deck-selector/title3-bg.png')
];

export const tutorialAfo = require('../assets/images/components/tutorial/afo.png');
export const tutorialBot = require('../assets/images/components/tutorial/bot.png');
export const tutorialStrategy = require('../assets/images/components/tutorial/strategy.png');
export const tutorialPoster = require('../assets/video/tutorial/attack-poster.jpg');

export const jump = require('../assets/images/icons/commons/jump.png');

export const glossaryBg = require('../assets/images/backgrounds/glossary/glossary-bg.jpg');

export const creditsBg = require('../assets/images/backgrounds/credits/credits-bg.jpg');

export const fullScreenIcon = require('../assets/images/icons/game-board/full-screen-icon.png');
export const noFullScreenIcon = require('../assets/images/icons/game-board/no-full-screen-icon.png');
