import { ResizeMode, Video } from 'expo-av';
import { LinearGradient } from 'expo-linear-gradient';
import { useRef } from 'react';
import { ImageBackground, ScrollView, Image, Pressable } from 'react-native';
import useUnits from 'rxn-units';
import { howText, howTitle } from '../../assets/data/configuration/howToplay';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';

import ExternalHeader from '../../commons/components/ExternalHeader/ExternalHeader';
import BotBlock from '../../commons/components/HowToPlaySections/BotBlock/BotBlock';
import HowSectionAttackDefense from '../../commons/components/HowToPlaySections/Sections/HowSectionAttackDefense';
import HowSectionAvatar from '../../commons/components/HowToPlaySections/Sections/HowSectionAvatar';
import HowSectionBlackCards from '../../commons/components/HowToPlaySections/Sections/HowSectionBlackCards';
import HowSectionBoard from '../../commons/components/HowToPlaySections/Sections/HowSectionBoard';
import HowSectionDeck from '../../commons/components/HowToPlaySections/Sections/HowSectionDeck';
import HowSectionHand from '../../commons/components/HowToPlaySections/Sections/HowSectionHand';
import HowSectionIntro from '../../commons/components/HowToPlaySections/Sections/HowSectionIntro';
import HowSectionPlayButton from '../../commons/components/HowToPlaySections/Sections/HowSectionPlayButton';
import HowSectionPower from '../../commons/components/HowToPlaySections/Sections/HowSectionPower';
import HowSectionSubjects from '../../commons/components/HowToPlaySections/Sections/HowSectionSubjects';
import HowSectionThrow from '../../commons/components/HowToPlaySections/Sections/HowSectionThrow';
import HowSectionWhiteCards from '../../commons/components/HowToPlaySections/Sections/HowSectionWhiteCards';
import StepBlock from '../../commons/components/HowToPlaySections/StepBlock/StepBlock';
import { useStylesHow } from '../../commons/components/HowToPlaySections/useStylesHow';
import LegalFooter from '../../commons/components/LegalFooter';
import TextWithHTML from '../../commons/components/TextWithHTML';
import { Text, View } from '../../commons/components/Themed';
import { isNativeOrPWA } from '../../commons/utils';
import {
  howAction1,
  howAction2,
  howAction3,
  howAction4,
  howAction5,
  howAction6,
  howAttackPoster,
  howCard2,
  howCard3,
  howCards3,
  howEnhancePoster,
  howGold,
  howRubi,
  howSection2Bg,
  howSection3Bg,
  howSection4Bg,
  howStep1,
  howTip1,
  howTip2,
  howTip3,
  howTip4,
  howTip5,
  howTop,
  irregularBlock2,
  irregularBlock3,
  turn
} from '../../constants/Images';
import { howAttack, howEnhance } from '../../constants/Videos';

const HowToPlay = () => {
  const { vh } = useUnits();
  const ref = useRef(null);

  const onPressTouch = () => {
    ref.current?.scrollTo({
      y: 0,
      animated: true
    });
  };

  const video = useRef(null);

  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        backgroundColor: '#313131'
      }}
    >
      <ExternalHeader withLogo current="how" />
      <ScrollView ref={ref}>
        <View style={{ flexGrow: 1 }}>
          <HowSectionIntro />
          <HowSectionBoard />
          <HowSectionHand />
          <HowSectionThrow />
          <HowSectionSubjects />
          <HowSectionPower />
          <HowSectionAttackDefense />
          <HowSectionAvatar />
          <HowSectionWhiteCards />
          <HowSectionBlackCards />
          <HowSectionPlayButton />
          <HowSectionDeck />

          <ImageBackground source={howSection2Bg} resizeMode="cover" style={styles.section}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View
              style={[
                styles.splitDesktopContainer,
                { position: 'relative', zIndex: 100, elevation: 100, justifyContent: !isNativeOrPWA ? 'flex-end' : 'center' }
              ]}
            >
              <ImageBackground
                style={[styles.block2, { marginTop: isNativeOrPWA ? vh(2) : vh(15), marginBottom: isNativeOrPWA ? vh(12) : 0 }]}
                source={irregularBlock2}
                resizeMode="stretch"
              >
                <Text style={styles.title}>{howTitle['playing-title']}</Text>
                <Text style={[styles.paragraph, { textAlign: 'center' }]}>
                  <TextWithHTML text={howText['playing-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                </Text>
              </ImageBackground>
            </View>

            <View style={styles.splitDesktopContainer}>
              <View style={styles.splitDesktopView}>
                <StepBlock leftSide={false} size={!isNativeOrPWA ? 's' : 'l'} step={1} title={howTitle['step-1-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['step-1-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </StepBlock>
              </View>
              <View style={styles.splitDesktopView}>
                <Image style={styles.card} resizeMode="contain" source={howStep1} accessible accessibilityLabel="cards image" />
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                marginVertical: vh(20),
                width: '100%',
                marginHorizontal: 'auto',
                maxWidth: isNativeOrPWA ? 780 : 1280,
                alignItems: 'center'
              }}
            >
              <View style={{ flexBasis: '35%', flexGrow: 0, flexShrink: 0 }}>
                <Image
                  style={{ width: '100%', height: 200 }}
                  resizeMode="contain"
                  source={turn}
                  accessible
                  accessibilityLabel="your turn image"
                />
              </View>
              <View style={{ flexBasis: '65%', flexGrow: 0, flexShrink: 0 }}>
                <StepBlock leftSide={false} step={2} size="l" title={howTitle['step-2-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['step-2-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </StepBlock>
              </View>
            </View>
          </ImageBackground>

          <ImageBackground source={howSection3Bg} resizeMode="cover" style={styles.section}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View
              style={{
                flexDirection: 'row',
                marginVertical: vh(20),
                marginHorizontal: 'auto',
                maxWidth: isNativeOrPWA ? 640 : 960,
                alignItems: 'center'
              }}
            >
              <View style={{ flexBasis: '60%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <StepBlock leftSide step={3} title={howTitle['step-3-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['step-3-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </StepBlock>
              </View>
              <View style={{ flexBasis: '40%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Image style={styles.card} resizeMode="contain" source={howCard2} accessible accessibilityLabel="card image" />
              </View>
            </View>
          </ImageBackground>

          <ImageBackground source={howSection4Bg} resizeMode="cover" style={styles.section}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View
              style={{
                flexDirection: 'row',
                marginVertical: vh(20),
                marginHorizontal: 'auto',
                maxWidth: isNativeOrPWA ? 640 : 960,
                alignItems: 'center'
              }}
            >
              <View style={{ flexBasis: '60%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <StepBlock leftSide={false} step={4} title={howTitle['step-4-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['step-4-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </StepBlock>
              </View>
              <View style={{ flexBasis: '40%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Image style={styles.card} resizeMode="contain" source={howCard3} accessible accessibilityLabel="card image" />
              </View>
            </View>
          </ImageBackground>

          <ImageBackground source={howSection4Bg} resizeMode="cover" style={styles.section}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View
              style={{
                marginVertical: vh(20),
                alignItems: 'center'
              }}
            >
              <View style={{ marginTop: vh(10), marginBottom: vh(5) }}>
                <StepBlock leftSide={false} step={5} size={'l'} title={howTitle['step-5-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['step-5-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </StepBlock>
              </View>
              <View style={{ marginBottom: vh(10) }}>
                <Video
                  ref={video}
                  style={styles.video}
                  source={howAttack}
                  useNativeControls={false}
                  isMuted
                  shouldPlay
                  posterSource={howAttackPoster}
                  // videoStyle makes work the resize mode
                  videoStyle={{ position: 'relative' }}
                  resizeMode={ResizeMode.CONTAIN}
                  isLooping
                />
              </View>
            </View>
          </ImageBackground>

          <ImageBackground source={howSection4Bg} resizeMode="cover" style={[styles.section, { paddingBottom: 0 }]}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View
              style={{
                flexDirection: !isNativeOrPWA ? 'row' : 'column',
                marginVertical: vh(20),
                marginHorizontal: 'auto',
                width: '100%',
                maxWidth: isNativeOrPWA ? 640 : 1280,
                alignItems: 'center'
              }}
            >
              <View
                style={{
                  flexBasis: '60%',
                  flexGrow: 0,
                  flexShrink: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  zIndex: 1
                }}
              >
                <StepBlock leftSide step={6} size={isNativeOrPWA ? 'l' : 'm'} title={howTitle['step-6-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['step-6-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </StepBlock>
              </View>
              <View style={{ flexBasis: '40%', flexGrow: 1, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Video
                  ref={video}
                  style={styles.video}
                  source={howEnhance}
                  useNativeControls={false}
                  isMuted
                  shouldPlay
                  posterSource={howEnhancePoster}
                  // videoStyle makes work the resize mode
                  videoStyle={{ position: 'relative' }}
                  resizeMode={ResizeMode.CONTAIN}
                  isLooping
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: vh(20),
                marginHorizontal: 'auto',
                width: '100%',
                maxWidth: 1280,
                alignItems: 'flex-end',
                justifyContent: 'center'
              }}
            >
              <View style={{ flexBasis: '45%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Image
                  style={{ minHeight: 200, minWidth: 500, height: isNativeOrPWA ? vh(70) : vh(65), maxHeight: 360, width: '100%' }}
                  resizeMode="contain"
                  source={howCards3}
                  accessible
                  accessibilityLabel="card image"
                />
              </View>
              <View style={{ flexBasis: '55%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <StepBlock leftSide={false} step={7} size={isNativeOrPWA ? 'm' : 'l'} title={howTitle['step-7-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['step-7-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </StepBlock>
              </View>
            </View>
          </ImageBackground>

          <View style={[styles.section, { justifyContent: 'flex-start' }]}>
            <ImageBackground
              style={[
                styles.block2,
                {
                  marginTop: isNativeOrPWA ? vh(2) : vh(15),
                  maxWidth: isNativeOrPWA ? 380 : 540,
                  minWidth: 320,
                  paddingHorizontal: isNativeOrPWA ? 65 : 100,
                  position: 'relative'
                }
              ]}
              source={irregularBlock3}
              resizeMode="stretch"
            >
              <Text style={styles.title}>{howTitle['advices-title']}</Text>
              <Text style={[styles.paragraph, { textAlign: 'center' }]}>
                <TextWithHTML text={howText['advices-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
              </Text>
            </ImageBackground>

            <View style={[styles.splitDesktopContainer, { marginTop: vh(10) }]}>
              <View style={styles.splitDesktopView}>
                <View
                  style={{
                    flexDirection: !isNativeOrPWA ? 'column' : 'row',
                    marginHorizontal: 'auto',
                    width: '100%',
                    maxWidth: isNativeOrPWA ? 640 : 960
                  }}
                >
                  <View style={{ flexBasis: '40%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <Image
                      style={[styles.card, { marginBottom: isNativeOrPWA ? 25 : 40 }]}
                      resizeMode="contain"
                      source={howTip1}
                      accessible
                      accessibilityLabel="card image"
                    />
                  </View>
                  <View style={{ flexBasis: '60%', flexGrow: 1, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <BotBlock small withBot={false} title={howTitle['advice-1-title']}>
                      <Text style={styles.paragraph}>
                        <TextWithHTML text={howText['advice-1-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                      </Text>
                    </BotBlock>
                  </View>
                </View>
              </View>
              <View style={styles.splitDesktopView}>
                <View
                  style={{
                    flexDirection: !isNativeOrPWA ? 'column' : 'row',
                    marginHorizontal: 'auto',
                    width: '100%',
                    maxWidth: isNativeOrPWA ? 640 : 960
                  }}
                >
                  <View style={{ flexBasis: '40%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <Image
                      style={[styles.card, { marginBottom: isNativeOrPWA ? 25 : 40 }]}
                      resizeMode="contain"
                      source={howTip2}
                      accessible
                      accessibilityLabel="card image"
                    />
                  </View>
                  <View style={{ flexBasis: '60%', flexGrow: 1, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <BotBlock small withBot={false} title={howTitle['advice-2-title']}>
                      <Text style={styles.paragraph}>
                        <TextWithHTML text={howText['advice-2-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                      </Text>
                    </BotBlock>
                  </View>
                </View>
              </View>
            </View>

            <View style={[styles.splitDesktopContainer, { marginTop: vh(10) }]}>
              <View style={styles.splitDesktopView}>
                <View
                  style={{
                    flexDirection: !isNativeOrPWA ? 'column' : 'row',
                    marginHorizontal: 'auto',
                    width: '100%',
                    maxWidth: isNativeOrPWA ? 640 : 960
                  }}
                >
                  <View style={{ flexBasis: '40%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <Image
                      style={[styles.card, { marginBottom: isNativeOrPWA ? 25 : 40 }]}
                      resizeMode="contain"
                      source={howTip3}
                      accessible
                      accessibilityLabel="card image"
                    />
                  </View>
                  <View style={{ flexBasis: '60%', flexGrow: 1, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <BotBlock small withBot={false} title={howTitle['advice-3-title']}>
                      <Text style={styles.paragraph}>
                        <TextWithHTML text={howText['advice-3-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                      </Text>
                    </BotBlock>
                  </View>
                </View>
              </View>
              <View style={styles.splitDesktopView}>
                <View
                  style={{
                    flexDirection: !isNativeOrPWA ? 'column' : 'row',
                    marginHorizontal: 'auto',
                    width: '100%',
                    maxWidth: isNativeOrPWA ? 640 : 960
                  }}
                >
                  <View style={{ flexBasis: '40%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <Image
                      style={[styles.card, { marginBottom: isNativeOrPWA ? 25 : 40 }]}
                      resizeMode="contain"
                      source={howTip4}
                      accessible
                      accessibilityLabel="card image"
                    />
                  </View>
                  <View style={{ flexBasis: '60%', flexGrow: 1, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                    <BotBlock small withBot={false} title={howTitle['advice-4-title']}>
                      <Text style={styles.paragraph}>
                        <TextWithHTML text={howText['advice-4-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                      </Text>
                    </BotBlock>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: !isNativeOrPWA ? 'column' : 'row',
                marginHorizontal: 'auto',
                width: '100%',
                maxWidth: isNativeOrPWA ? 640 : 960,
                marginTop: isNativeOrPWA ? vh(10) : vh(15)
              }}
            >
              <View style={{ flexBasis: '40%', flexGrow: 0, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <Image
                  style={[styles.card, { marginBottom: isNativeOrPWA ? 25 : 40 }]}
                  resizeMode="contain"
                  source={howTip5}
                  accessible
                  accessibilityLabel="card image"
                />
              </View>
              <View style={{ flexBasis: '60%', flexGrow: 1, flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                <BotBlock withBot={false} small title={howTitle['advice-5-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['advice-5-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </BotBlock>
              </View>
            </View>
          </View>

          <ImageBackground source={howSection2Bg} resizeMode="cover" style={styles.section}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <ImageBackground
              style={[
                styles.block2,
                {
                  marginTop: isNativeOrPWA ? vh(2) : vh(15),
                  maxWidth: isNativeOrPWA ? 340 : 640,
                  minWidth: 320,
                  paddingHorizontal: isNativeOrPWA ? 45 : 100,
                  position: 'relative'
                }
              ]}
              source={irregularBlock3}
              resizeMode="stretch"
            >
              <Text style={styles.title}>{howTitle['powers-title']}</Text>
              <Text style={[styles.paragraph, { textAlign: 'center' }]}>
                <TextWithHTML text={howText['powers-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
              </Text>
            </ImageBackground>

            <View style={[styles.splitContainer, styles.elevation, { marginTop: vh(10) }]}>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-1-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['power-1-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action} resizeMode="contain" source={howAction1} accessible accessibilityLabel="fact check image" />
                </BotBlock>
              </View>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-2-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['power-2-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action} resizeMode="contain" source={howAction2} accessible accessibilityLabel="impact image" />
                </BotBlock>
              </View>
            </View>

            <View style={[styles.splitContainer, styles.elevation, { marginTop: vh(10) }]}>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-3-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['power-3-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action} resizeMode="contain" source={howAction3} accessible accessibilityLabel="lose image" />
                </BotBlock>
              </View>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-4-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['power-4-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action} resizeMode="contain" source={howAction4} accessible accessibilityLabel="win image" />
                </BotBlock>
              </View>
            </View>

            <View style={[styles.splitContainer, styles.elevation, { marginTop: vh(10) }]}>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-5-title']}>
                  <Text style={[styles.paragraph, { paddingRight: '15%' }]}>
                    <TextWithHTML text={howText['power-5-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action2} resizeMode="contain" source={howAction5} accessible accessibilityLabel="lose image" />
                </BotBlock>
              </View>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-6-title']}>
                  <Text style={[styles.paragraph, { paddingRight: '15%' }]}>
                    <TextWithHTML text={howText['power-6-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action2} resizeMode="contain" source={howAction6} accessible accessibilityLabel="lose image" />
                </BotBlock>
              </View>
            </View>

            <View style={[styles.splitContainer, styles.elevation, { marginTop: vh(10) }]}>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-7-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['power-7-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </BotBlock>
              </View>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['power-8-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['power-8-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                </BotBlock>
              </View>
            </View>
          </ImageBackground>

          <ImageBackground source={howSection4Bg} resizeMode="cover" style={styles.section}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <ImageBackground
              style={[
                styles.block2,
                {
                  marginTop: isNativeOrPWA ? vh(10) : vh(15),
                  maxWidth: isNativeOrPWA ? 480 : 660,
                  minWidth: 320,
                  paddingHorizontal: isNativeOrPWA ? 45 : 100,
                  position: 'relative'
                }
              ]}
              source={irregularBlock3}
              resizeMode="stretch"
            >
              <Text style={styles.title}>{howTitle['rarities-title']}</Text>
              <Text style={[styles.paragraph, { textAlign: 'center' }]}>
                <TextWithHTML text={howText['rarities-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
              </Text>
            </ImageBackground>

            <View style={[styles.splitContainer, styles.elevation, { marginVertical: vh(20) }]}>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['rarity-1-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['rarity-1-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action} resizeMode="contain" source={howRubi} accessible accessibilityLabel="rubi image" />
                </BotBlock>
              </View>
              <View style={[styles.splitView, { paddingHorizontal: 10 }]}>
                <BotBlock small leftSide={false} withBot={false} title={howTitle['rarity-2-title']}>
                  <Text style={styles.paragraph}>
                    <TextWithHTML text={howText['rarity-2-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
                  </Text>
                  <Image style={styles.action} resizeMode="contain" source={howGold} accessible accessibilityLabel="gold image" />
                </BotBlock>
              </View>
            </View>
          </ImageBackground>
          <View style={styles.footerContainer}>
            <LegalFooter />
          </View>
        </View>
      </ScrollView>
      <View style={styles.scrollTop}>
        <Pressable onPress={onPressTouch}>
          <View style={styles.buttonContainer}>
            <ButtonShape square bgColor="dark" border heightBtn={isNativeOrPWA ? 30 : 46}>
              <View style={styles.button}>
                <Image style={styles.icon} resizeMode="contain" source={howTop} accessible accessibilityLabel="top icon" />
              </View>
            </ButtonShape>
          </View>
        </Pressable>
      </View>
    </View>
  );
};

export default HowToPlay;
