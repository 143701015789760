import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import * as auth from '../redux/Auth/authRedux';
import aspectRatioSlice from './AspectRatio/aspectRatio';
import configSlice from './Configuration/configuration';
import consentSlice from './Consent/consent';
import gameActions from './GameBoard/gameAction';
import reactions from './GameBoard/reactions';
import tweets from './GameBoard/tweets';
import { isOnlineReducer } from './Is-online/isOnline';
import { loadingMissionBackgroundReducer } from './Loading-mission/loadingMissionBackground';
import { loginModalReducer } from './Login-modal/loginModal';
import profileSlice from './Profile/profileReducer';
import { timeAdviceReducer } from './Time-advice/timeAdvice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  tweets: tweets.reducer,
  reactions: reactions.reducer,
  gameActions: gameActions.reducer,
  profile: profileSlice.reducer,
  configuration: configSlice.reducer,
  consent: consentSlice.reducer,
  timeAdvice: timeAdviceReducer,
  loginModal: loginModalReducer,
  loadingMissionBackground: loadingMissionBackgroundReducer,
  isOnline: isOnlineReducer,
  aspectRatio: aspectRatioSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
