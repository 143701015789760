import axios from 'axios';
import { Card } from '../commons/dtos/card.dto';
import { DeckData } from '../commons/dtos/deck-data.dto';
import { DeckIncludesCard } from '../commons/dtos/deck-includes-card.dto';
import { Deck } from '../commons/dtos/deck.dto';
import { getPaginated } from '../commons/utils/paginate';
import { cards } from '../constants/Images';

const base = process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL;

export default class DecksService {
  getDecks = async (playerId: string): Promise<DeckData[]> =>
    await getPaginated<DeckData, { page: number; playerId: string }>({ url: '/decks', params: { page: 1, playerId } });

  getOrderedDecks = async (playerId: string): Promise<DeckData[]> =>
    await getPaginated<DeckData, { page: number; order: string; playerId: string }>({
      url: '/decks',
      params: { page: 1, order: 'updatedAt', playerId }
    });

  getDeck = async (playerId: string, deckId: string) => {
    const { data } = await axios.get(`${base}/decks/${deckId}`, {
      params: { playerId }
    });
    data.cards = data.cards.map((deckIncludesCard: DeckIncludesCard) => {
      deckIncludesCard.card.imageSource = cards[deckIncludesCard.card.illustration as string];
      return deckIncludesCard;
    });
    return data;
  };

  getDeckFreeGame = async (deckId: string, playerId: string): Promise<Array<{ card: Card; order: number }>> => {
    const { data } = await axios.get<Array<{ card: Card; order: number }>>(`${base}/decks/${deckId}/freegame`, {
      params: { playerId, random: true }
    });
    return data;
  };

  addCardToDeck = async (playerId: string, deckId: string, card: Card) => {
    return await axios.patch(`${base}/decks/${deckId}`, { isAdd: true, card });
  };
  removeCardFromDeck = async (playerId: string, deckId: string, card: Card) => {
    return await axios.patch(`${base}/decks/${deckId}`, { isAdd: false, card });
  };

  createDeck = async (playerId: string, deckName: string, deckImage: string, cardIds: string[]): Promise<Deck> => {
    return (await axios.post(`${base}/decks`, { playerId, deckName, deckImage, cardIds })).data;
  };
  updateDeck = async (playerId: string, deckId: string, deckName: string, deckImage: string, cardIds: string[]): Promise<Deck> => {
    return (await axios.patch(`${base}/decks/${deckId}`, { deckName, deckImage, cardIds }, { params: { playerId: playerId } })).data;
  };
  deleteDeck = async (playerId: string, deckId: string): Promise<Deck> => {
    return (await axios.delete(`${base}/decks/${deckId}`)).data;
  };
}
