import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ViewStyle } from 'react-native';
import { useAspectRatio } from '../commons/components/AspectRatioView/useAspectRatio';
import { BASE_ASPECT_RATIO } from '../constants/Values';

type DetailPosition = Pick<ViewStyle, 'left' | 'top'>;

export const useDetailCard = () => {
  const [detailCard, setDetailCard] = useState<ReactNode | null>(null);
  const [detailCardPosition, setDetailCardPosition] = useState<DetailPosition>({ left: 0, top: 0 });
  const [showDetailCard, setShowDetailCard] = useState<boolean>(false);
  const { viewWidth } = useAspectRatio(BASE_ASPECT_RATIO);

  const { hide, show } = useMemo(
    () => ({
      hide: () => setShowDetailCard(false),
      show: () => setShowDetailCard(true)
    }),
    []
  );

  const resetDetailCard = useCallback(() => {
    setDetailCard(null);
    hide();
  }, [hide]);

  const handleDetailCardPosition = useCallback(
    (position: DetailPosition) => {
      const bounds = [viewWidth * 0.25, viewWidth * 0.65];
      // eslint-disable-next-line prefer-destructuring
      let left: number = position.left as number;
      // eslint-disable-next-line prefer-destructuring
      if (left < bounds[0]) left = bounds[0];
      // eslint-disable-next-line prefer-destructuring
      if (left > bounds[1]) left = bounds[1];
      setDetailCardPosition({ ...position, left });
    },
    [viewWidth]
  );

  const updateDetailCard = useCallback(
    (node: ReactNode, position: DetailPosition, showDetail = true) => {
      setDetailCard(node);
      handleDetailCardPosition(position);
      showDetail && show();
    },
    [show, handleDetailCardPosition]
  );

  return {
    detailCard,
    resetDetailCard,
    showDetailCard,
    detailCardPosition,
    updateDetailCard
  };
};
