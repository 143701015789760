import { LinearGradient } from 'expo-linear-gradient';
import { useState } from 'react';
import { StyleSheet, TouchableOpacity, Image, TouchableWithoutFeedback } from 'react-native';
import useUnits from 'rxn-units';
import { closeIconBlack, GameLogo, menuIcon } from '../../../constants/Images';
import { isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';
import { View } from '../Themed';
import LinksMenuModal from './LinksMenuModal';
import { useExternalLinks } from './useExternalLinks';

export type HeaderLinks = 'login' | 'landing' | 'ranking' | 'how';
interface ExternalHeaderProps {
  withLogo?: boolean;
  current?: HeaderLinks;
}

const ExternalHeader = ({ withLogo = false, current }: ExternalHeaderProps) => {
  const { vw } = useUnits();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { isAuthorized, openLogin, openHome } = useExternalLinks();

  const styles = StyleSheet.create({
    header: {
      flexDirection: 'row',
      position: 'absolute',
      zIndex: 10000,
      top: 0,
      left: 0,
      right: 0,
      paddingHorizontal: isNativeOrPWA ? 10 : 30,
      paddingTop: 10
    },
    sectionLogo: {
      maxHeight: isNativeOrPWA ? 66 : 194,
      maxWidth: isNativeOrPWA ? 145.5 : 426,
      width: vw(8) * 2.2,
      height: vw(8),
      flexShrink: 0,
      flexGrow: 0
    },
    sectionLinks: {
      flexDirection: isNativeOrPWA ? 'column' : 'row',
      justifyContent: isNativeOrPWA ? 'space-between' : 'flex-end',
      alignItems: isNativeOrPWA ? 'flex-end' : 'center',
      padding: isNativeOrPWA ? 8 : 16,
      flex: 1
    },
    gameLogo: {
      maxHeight: isNativeOrPWA ? 66 : 194,
      maxWidth: isNativeOrPWA ? 146 : 426,
      width: vw(8) * 2.2,
      height: vw(8)
    },
    buttonContainer: {
      marginHorizontal: vw(0.5),
      paddingTop: 5
    },
    button: {
      padding: vw(1),
      paddingBottom: vw(1.3),
      cursor: 'pointer'
    },
    buttonIcon: { height: isNativeOrPWA ? 18 : 32, width: isNativeOrPWA ? 18 : 32 }
  });

  const menuButton = (
    <View style={styles.sectionLinks}>
      <View style={styles.buttonContainer}>
        <TouchableWithoutFeedback onPress={() => setShowMenu(!showMenu)}>
          <View>
            <ButtonShape square bgColor={showMenu ? 'white' : 'gray'} heightBtn={isNativeOrPWA ? 34 : 56}>
              <View style={styles.button}>
                <Image style={styles.buttonIcon} source={showMenu ? closeIconBlack : menuIcon} resizeMode={'contain'}></Image>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );

  return (
    <>
      <LinearGradient style={styles.header} colors={['rgba(30, 30, 30, 1)', 'transparent']}>
        <View style={styles.sectionLogo}>
          {withLogo &&
            (!isAuthorized ? (
              <TouchableOpacity accessible accessibilityLabel={'Link to Fakesover login page'} onPress={openLogin}>
                <Image resizeMode="contain" source={GameLogo} accessible accessibilityLabel="Game logo" style={styles.gameLogo} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity accessible accessibilityLabel={'Link to Fakesover home page'} onPress={openHome}>
                <Image resizeMode="contain" source={GameLogo} accessible accessibilityLabel="Game logo" style={styles.gameLogo} />
              </TouchableOpacity>
            ))}
        </View>
        {menuButton}
      </LinearGradient>
      {showMenu && <LinksMenuModal closeModal={() => setShowMenu(false)} current={current} />}
    </>
  );
};

export default ExternalHeader;
