import React from 'react';
import { StyleSheet } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { View } from '../../../commons/components/Themed';
import BoardActionPoints from './BoardActionPoints/BoardActionPoints';

interface BoardActionPointsContainerProps {
  opponentActionPoints: number;
  playerActionPoints: number;
  opponentMaxActionPoints: number;
  playerMaxActionPoints: number;
}
const BoardActionPointsContainer = (props: BoardActionPointsContainerProps) => {
  const { rh } = useRatioDimensions();
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      width: '4%',
      height: '27%',
      right: '4%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      top: rh(35)
    }
  });
  return (
    <View style={styles.container} pointerEvents="none">
      <BoardActionPoints maxActionPoints={props.opponentMaxActionPoints} actionPoints={props.opponentActionPoints} isOpponent={true} />
      <BoardActionPoints maxActionPoints={props.playerMaxActionPoints} actionPoints={props.playerActionPoints} isOpponent={false} />
    </View>
  );
};

export default BoardActionPointsContainer;
