// eslint-disable-next-line no-shadow
export enum TypesEnum {
  AGENT = 'faker',
  FAKE = 'fake',
  VERIFICATION = 'verificación',
  ACTION = 'acción',
  TECHNIQUE = 'técnica',
  SUPPORT = 'mejora'
}

/**
 * Type variable type declaration.
 *
 * @interface
 */

export class Type {
  id?: string;

  typeName!: TypesEnum;

  constructor(properties?: TypeProperties) {
    if (properties) {
      this.typeName = properties.typeName;
    }
  }
}

export interface TypeProperties {
  id?: string;
  typeName: TypesEnum;
}
