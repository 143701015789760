import { StyleSheet, TouchableHighlight, Image } from 'react-native';
import ButtonShape from '../../../commons/components/ButtonShape/ButtonShape';
import { View, Text } from '../../../commons/components/Themed';
import adjust from '../../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../../commons/utils';
import { ButtonSignInGoogleG, GameLogo } from '../../../constants/Images';
import LoginFormLogos from './LoginFormLogos';

interface LoginScreenInitProps {
  signInGoogle: Function;
  signInGuest: Function;
}

const LoginScreenInit = ({ signInGoogle, signInGuest }: LoginScreenInitProps) => {
  const styles = StyleSheet.create({
    title: {
      fontWeight: '600',
      maxWidth: 500,
      textAlign: 'center',
      marginTop: isNativeOrPWA ? adjust(16) : adjust(22),
      fontSize: isNativeOrPWA ? adjust(16) : adjust(22)
    },
    logo: {
      height: '20%',
      width: '100%'
    },
    buttons: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      flex: 1
    },
    buttonSignInGoogleG: {
      width: isNativeOrPWA ? adjust(18) : adjust(24),
      height: isNativeOrPWA ? adjust(18) : adjust(24),
      resizeMode: 'contain'
    },
    innerWidth: {
      width: '100%'
    },
    googleButtonSignInText: {
      flexGrow: 0,
      flexShrink: 0,
      fontSize: isNativeOrPWA ? adjust(16) : adjust(16),
      fontWeight: '500',
      color: '#000000',
      fontFamily: 'Roboto_500Medium'
    },
    buttonSignInText: {
      flexGrow: 0,
      flexShrink: 0,
      fontSize: isNativeOrPWA ? adjust(16) : adjust(18),
      fontWeight: '600',
      color: '#fff'
    },
    buttonSignInGoogleText: {
      marginLeft: 14
    },
    buttonInner: {
      paddingHorizontal: 2,
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 5,
      width: '100%',
      maxWidth: !isNativeOrPWA ? 285 : 250,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  });
  return (
    <>
      <Image source={GameLogo} resizeMode={'contain'} style={styles.logo} />
      <Text style={styles.title}>Accede con tu email para jugar.</Text>
      <View style={styles.buttons}>
        <TouchableHighlight
          style={styles.innerWidth}
          underlayColor={'transparent'}
          accessible
          accessibilityLabel={'Login with google'}
          onPress={() => signInGoogle()}
        >
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="white" heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Image style={styles.buttonSignInGoogleG} source={ButtonSignInGoogleG} />
                <Text style={[styles.googleButtonSignInText, styles.buttonSignInGoogleText]}>Continuar con Google</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableHighlight>
        <TouchableHighlight
          style={styles.innerWidth}
          underlayColor={'transparent'}
          accessible
          accessibilityLabel={'Login as guest'}
          onPress={() => signInGuest()}
        >
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.buttonSignInText}>Entrar como invitado</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableHighlight>
      </View>
      <LoginFormLogos />
    </>
  );
};

export default LoginScreenInit;
