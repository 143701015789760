import { useEffect, useState } from 'react';
import { AvatarProperties } from '../../commons/dtos/avatar.dto';
import { Card } from '../../commons/dtos/card.dto';
import { Reward, RewardTypeEnum } from '../../commons/dtos/reward.dto';
import { TitleProperties } from '../../commons/dtos/title.dto';
import store from '../../redux/store';
import RewardsService from '../../services/rewards.service';

export type RewardOptions = RewardTypeEnum | 'list';

export interface Reveal {
  index: number;
  reveal: boolean;
}

export type AvatarReveal = AvatarProperties & Reveal;
export type TitleReveal = TitleProperties & Reveal;
export type CardReveal = Card & Reveal;
export type RewardReveal = AvatarReveal | TitleReveal | CardReveal | CardReveal[];

export interface RewardData {
  index?: number;
  showed?: boolean;
  type: RewardTypeEnum;
  reward: RewardReveal;
}

interface useRewardProps {
  navigateCampaign: () => void;
  navigateRewards: () => void;
  rewards: Reward[] | undefined;
  hasRewards: boolean;
  hasLevelRewards: boolean;
}

export const useReward = ({ hasLevelRewards, hasRewards, navigateCampaign, navigateRewards, rewards }: useRewardProps) => {
  const {
    profile: { player }
  } = store.getState();

  const rewardsService = new RewardsService();

  const [claimedRewards, setRewardsData] = useState<RewardData[]>();

  const [rewardData, setRewardData] = useState<RewardData>();

  const [selectedItem, setSelectedItem] = useState<RewardOptions>('list');

  const [goBack, setGoBack] = useState({
    show: false,
    active: false
  });

  const isSingleReward = claimedRewards?.length === 1;

  const getState = {
    getAvatar: () => (rewardData?.type === RewardTypeEnum.AVATAR ? (rewardData.reward as Required<AvatarReveal>) : null),
    getTitle: () => (rewardData?.type === RewardTypeEnum.TITLE ? (rewardData.reward as Required<TitleReveal>) : null),
    getCard: () => (rewardData?.type === RewardTypeEnum.CARD ? (rewardData.reward as Required<CardReveal>) : null),
    getCardPack: () => (rewardData?.type === RewardTypeEnum.CARD_PACK ? (rewardData.reward as Required<Array<Required<CardReveal>>>) : [])
  };

  const updateState = {
    updateAvatar: (avatar: Required<AvatarReveal>) => setRewardData(prev => ({ ...prev, reward: avatar, type: RewardTypeEnum.AVATAR })),
    updateTitle: (title: Required<TitleReveal>) => setRewardData(prev => ({ ...prev, reward: title, type: RewardTypeEnum.TITLE })),
    updateCard: (card: Required<CardReveal>) => setRewardData(prev => ({ ...prev, reward: card, type: RewardTypeEnum.CARD })),
    updateCardPack: (cardPack: Array<Required<CardReveal>>) =>
      setRewardData(prev => ({ ...prev, reward: cardPack, type: RewardTypeEnum.CARD_PACK }))
  };

  useEffect(() => {
    if (!(claimedRewards && claimedRewards.length === 0)) return;
    if (!hasLevelRewards) return navigateCampaign();
    hasRewards ? navigateRewards() : navigateCampaign();
  }, [claimedRewards]);

  useEffect(() => {
    if (!rewards) return navigateCampaign();

    Promise.all(
      rewards.map(async (reward: Reward, index: number) => {
        const { type } = reward;

        switch (type) {
          case RewardTypeEnum.AVATAR: {
            const rewardAvatar = await rewardsService.claimAvatar(player!.id!, reward);
            return { reward: { ...rewardAvatar, reveal: false }, index, type: RewardTypeEnum.AVATAR } as RewardData;
          }
          case RewardTypeEnum.CARD: {
            const [rewardCard] = await rewardsService.claimCard(player!.id!, reward);
            return { reward: { ...rewardCard, reveal: false }, index, type: RewardTypeEnum.CARD } as RewardData;
          }
          case RewardTypeEnum.CARD_PACK: {
            const rewardCardPack = await rewardsService.claimCardPack(player!.id!, reward);
            const revealCardPack = rewardCardPack.map(cardPack => ({ ...cardPack, reveal: false }));
            return { reward: revealCardPack, index, type: RewardTypeEnum.CARD_PACK } as RewardData;
          }
          case RewardTypeEnum.TITLE: {
            const rewardTitle = await rewardsService.claimTitle(player!.id!, reward);
            return { reward: { ...rewardTitle, reveal: false }, index, type: RewardTypeEnum.TITLE } as RewardData;
          }
        }
      })
    ).then(claimed => {
      setRewardsData(claimed);
    });
  }, [rewards]);

  const handleRewardChange = (index?: number) => {
    if (rewardData) {
      setRewardsData(claimedRewards?.filter(reward => reward.index !== rewardData.index) || []);
    }
    const reward = claimedRewards?.find(rewardData => rewardData && index !== undefined && rewardData.index === index);

    if (reward) {
      if (
        reward.type !== RewardTypeEnum.AVATAR &&
        reward.type !== RewardTypeEnum.CARD &&
        reward.type !== RewardTypeEnum.CARD_PACK &&
        reward.type !== RewardTypeEnum.TITLE
      ) {
        setGoBack({ active: false, show: false });
      } else {
        setRewardData(reward);
        setGoBack({ active: false, show: true });
      }
      setSelectedItem(reward.type);
    } else {
      setSelectedItem('list');
      setGoBack({ active: false, show: false });
    }
  };

  const handleActiveBack = () => setGoBack({ show: true, active: true });

  return {
    claimedRewards,
    selectedItem,
    handleRewardChange,
    handleActiveBack,
    rewardData,
    getState,
    updateState,
    goBack,
    isSingleReward
  };
};
