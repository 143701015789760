import { ImageSourcePropType } from 'react-native';
import { actors } from '../../../assets/data/actors';
import { characterDialogImage, avatarImage as avatars } from '../../../constants/Images';
import { DataDialogue, SideOfScreen } from '../../../types';
import { ActorEnum } from '../../dtos/actor.dto';
import { Dialogue } from '../../dtos/dialog.dto';
import { GendersEnum, Player } from '../../dtos/player.dto';
import { Reaction } from '../../dtos/reaction.dto';
import { BubbleColor } from './Dialogue';

interface Actor {
  avatarImage: ImageSourcePropType;
  characterName: string | null;
  side: SideOfScreen;
  color: BubbleColor;
}

function getActor(
  characterObject: {
    [name: string]: ImageSourcePropType;
  },
  actorKey: ActorEnum | null,
  player?: Player,
  campaign = false
): Actor {
  const resolveActorKey = (actorKey: ActorEnum | null, player?: Player) => {
    if (!actorKey || ActorEnum.AGENT_F_F_EVIL !== actorKey) {
      return actorKey;
    }
    return player?.gender === GendersEnum.FEMALE ? ActorEnum.AGENT_F_F_EVIL : ActorEnum.AGENT_F_M_EVIL;
  };

  actorKey = resolveActorKey(actorKey, player);
  const actor = actors.find(actor => actor.key === actorKey);
  const avatarImage = getActorImage(player!.avatar!.avatarImage, player!.gender, campaign);

  // Invert sides
  if (actorKey && [ActorEnum.PLAYER, ActorEnum.AGENT_F].includes(actorKey)) {
    return {
      avatarImage,
      characterName: actorKey === ActorEnum.AGENT_F ? actor!.name : player!.playerName || actorKey,
      side: 'left',
      color: 'blue'
    };
  } else {
    const actor = actors.find(actor => actor.key === actorKey);
    return {
      avatarImage: characterObject[actor?.key || ActorEnum.AGENT_V],
      characterName: actor?.name || actorKey,
      side: 'right',
      color: 'red'
    };
  }
}

export function getAvatar(avatarImage: string, gender?: GendersEnum, campaign = false): ImageSourcePropType {
  if (campaign) {
    return gender === GendersEnum.FEMALE ? avatars['agent_f_f'] : avatars['agent_f_m'];
  }
  const avatar = avatars[avatarImage.replace('.png', '')];
  return avatar ? avatar : gender === GendersEnum.FEMALE ? avatars['agent_f_f'] : avatars['agent_f_m'];
}

export function getActorImage(actorImage: string, gender?: GendersEnum, campaign = false): ImageSourcePropType {
  if (campaign) {
    return gender === GendersEnum.FEMALE ? characterDialogImage['player_f'] : characterDialogImage['player_m'];
  }
  const actor = characterDialogImage[actorImage.replace('.png', '')];
  return actor ? actor : gender === GendersEnum.FEMALE ? characterDialogImage['player_f'] : characterDialogImage['player_m'];
}

export function getRandomText(dialogues: string[]): string {
  return dialogues[Math.floor(Math.random() * dialogues.length)];
}

export function prepareDialogue(
  { actor, text, color: colorDialogue, side: sideDialogue }: Dialogue,
  isFreeGame: boolean,
  player?: Player
): DataDialogue {
  const playerName = player!.playerName || '';

  const { avatarImage, characterName, color, side } = getActor(characterDialogImage, actor, player, isFreeGame);
  const message = text ? (typeof text === 'string' ? text : getRandomText(text)) : null;
  return {
    avatarImage,
    characterName: characterName ?? '',
    text: message?.toString().replace('[PLAYER]', playerName) || '...',
    color: colorDialogue || color,
    side: sideDialogue || side
  };
}

export function prepareReaction({ text, actor }: Reaction, isFreeGame: boolean, player?: Player): DataDialogue {
  const playerName = player!.playerName || '';

  const { avatarImage, characterName, color } = getActor(characterDialogImage, actor!, player, isFreeGame);
  const message = text ? (typeof text === 'string' ? text : getRandomText(text)) : null;

  return {
    avatarImage,
    characterName: characterName ?? '',
    text: message?.toString().replace('[PLAYER]', playerName) || '...',
    color,
    side: 'right'
  };
}
