import { ImageBackground, StyleSheet, Image, Text, View } from 'react-native';
import { GestureHandlerRootView, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { isNativeOrPWA } from '../../../commons/utils';
import { newDeckDeckSelector, newDeckPlus } from '../../../constants/Images';
import { useDeckSelectorCardStyle } from './useDeckSelectorCardStyle';

interface NewDeckItemProps {
  navigate: () => void;
}

const NewDeckItem = ({ navigate }: NewDeckItemProps) => {
  const { deckItemCardStyle } = useDeckSelectorCardStyle();

  const deckItemStyles = StyleSheet.create({
    deckImageContainer: {
      maxWidth: '20%',
      width: '100%',
      flexGrow: 0
    },
    deckImage: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1
    },
    plus: {
      width: isNativeOrPWA ? 28 : 64,
      height: isNativeOrPWA ? 28 : 64
    },
    text: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? 12 : 21,
      fontWeight: '700',
      marginTop: isNativeOrPWA ? 8 : 32
    }
  });

  return (
    <GestureHandlerRootView>
      <TouchableWithoutFeedback onPress={navigate}>
        <View style={[deckItemStyles.deckImageContainer, deckItemCardStyle.image]}>
          <ImageBackground source={newDeckDeckSelector} style={deckItemStyles.deckImage} resizeMode="contain">
            <Image source={newDeckPlus} style={deckItemStyles.plus} />
            <Text style={deckItemStyles.text}>Nuevo Mazo</Text>
          </ImageBackground>
        </View>
      </TouchableWithoutFeedback>
    </GestureHandlerRootView>
  );
};

export default NewDeckItem;
