import { useEffect, useState } from 'react';
import CardWithAccesory from '../../../../commons/components/CardWithAccesory';
import { Card } from '../../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../../commons/dtos/player.dto';

interface CardHoverProps {
  card: Card;
  enemyKey: string;
  side: PlayerRoundEnum;
}

const CardHover = ({ card, enemyKey, side }: CardHoverProps) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const to = setTimeout(() => setShow(true), 3000);
    return () => clearTimeout(to);
  }, []);

  return <CardWithAccesory card={card} enemyKey={enemyKey} side={side} show={show} hover />;
};

export default CardHover;
