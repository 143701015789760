// eslint-disable-next-line no-shadow
export enum CollectionsEnum {
  NEWTRAL = 'newtral',
  EX_MACHINA = 'ex machina',
  POWERFUL_WOMEN = 'mujeres poderosas'
}

/**
 * Collection variable type declaration.
 *
 * @interface
 */
export class Collection {
  id?: string;

  collectionName!: CollectionsEnum;

  constructor(properties?: CollectionProperties) {
    if (properties) {
      this.collectionName = properties.collectionName;
    }
  }
}

export interface CollectionProperties {
  id?: string;
  collectionName: CollectionsEnum;
}
