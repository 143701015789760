import { useState } from 'react';
import { Image, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { filterIcon } from '../../../constants/Images';
import { CardInventoryFilter } from '../../dtos/card-inventory-filter.dto';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';
import OptionsMenuModal from '../OptionsMenuModal/OptionsMenuModal';
import { View, Text } from '../Themed';
import UserMenu from '../UserMenu';
import CardInventoryViewerMenu from './CardInventoryViewerMenu';

interface InventoryTopHeaderProps {
  title: string;
  setCardInventoryFilters: Function;
  cardInventoryFilters: CardInventoryFilter;
  onGoBack: Function;
  showFilter: Function;
}

const InventoryTopHeader = ({ title, setCardInventoryFilters, cardInventoryFilters, onGoBack, showFilter }: InventoryTopHeaderProps) => {
  const [showConfig, setShowConfig] = useState<boolean>();

  const styles = StyleSheet.create({
    topContainer: {
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'row',
      position: 'absolute',
      justifyContent: 'space-between',
      top: 5,
      left: 0,
      right: 0,
      height: '10%',
      paddingHorizontal: 10
    },
    title: {
      fontWeight: '800',
      textShadowColor: 'rgba(0, 0, 0, 0.75)',
      textShadowOffset: { width: -1, height: 1 },
      textShadowRadius: 10,
      fontSize: adjust(28)
    },
    filterButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 5
    },
    filterButtonImg: {
      marginRight: 10,
      height: isNativeOrPWA ? 16 : 28,
      width: isNativeOrPWA ? 16 : 28
    },
    filterButtonText: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: isNativeOrPWA ? 16 : 22
    }
  });

  return (
    <>
      <View style={styles.topContainer}>
        <CardInventoryViewerMenu
          onGoBack={onGoBack}
          cardInventoryFilters={cardInventoryFilters}
          setCardInventoryFilters={setCardInventoryFilters}
        />
        {!isNativeOrPWA && <Text style={styles.title}>{title}</Text>}
        <TouchableWithoutFeedback onPress={() => showFilter(true)}>
          <View>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 30 : 52}>
              <View style={styles.filterButton}>
                <Image style={styles.filterButtonImg} source={filterIcon} resizeMode={'contain'} />
                <Text style={styles.filterButtonText}>Filtros</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
        <UserMenu openModal={() => setShowConfig(true)} />
      </View>
      {showConfig && <OptionsMenuModal closeModal={() => setShowConfig(false)} />}
    </>
  );
};

export default InventoryTopHeader;
