import { ReactNode } from 'react';
import { StyleSheet, ImageBackground, View, Text } from 'react-native';
import { irregularBlock3 } from '../../../../constants/Images';
import adjust from '../../../styles/TextAdjust';
import { isNativeOrPWA } from '../../../utils';
import ButtonShape from '../../ButtonShape/ButtonShape';

interface BotBlockProps {
  title: string;
  children: ReactNode;
  step?: number;
  leftSide?: boolean;
  small?: boolean;
  size?: 's' | 'm' | 'l';
}

const StepBlock = ({ title, children, step, leftSide = true, size = 'm' }: BotBlockProps) => {
  const small = size === 's';
  const large = size === 'l';

  let maxWidth = adjust(580);
  if (small) maxWidth = !isNativeOrPWA ? 500 : adjust(400);
  if (large) maxWidth = adjust(780);

  const styles = StyleSheet.create({
    container: {
      maxWidth: maxWidth,
      paddingHorizontal: isNativeOrPWA && !large ? 35 : 70,
      paddingVertical: isNativeOrPWA ? 28 : 60,
      width: '100%',
      position: 'relative',
      zIndex: 100
    },
    block: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    shapeContainer: {
      marginVertical: isNativeOrPWA ? adjust(16) : 18,
      justifyContent: 'center',
      width: 'auto',
      maxWidth: isNativeOrPWA ? 220 : 280
    },
    numberContainer: {
      marginVertical: isNativeOrPWA ? adjust(16) : 18,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: isNativeOrPWA ? -52 : -66,
      zIndex: 1,
      elevation: 1
    },
    title: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(15) : 18,
      fontWeight: 'bold'
    },
    number: {
      position: 'absolute',
      zIndex: 1000000,
      elevation: 1111111
    },
    numberText: {
      fontSize: isNativeOrPWA ? adjust(46) : 60,
      fontFamily: 'Inlanders',
      textShadowColor: '#ccc',
      textShadowOffset: { width: isNativeOrPWA ? -2 : -3, height: isNativeOrPWA ? 3 : 6 },
      textShadowRadius: 0,
      position: 'absolute',
      top: isNativeOrPWA ? adjust(-20) : -30,
      left: isNativeOrPWA ? adjust(-9) : -14
    }
  });

  return (
    <View style={styles.container}>
      <ImageBackground style={[styles.block, leftSide && { transform: [{ scaleX: -1 }] }]} source={irregularBlock3} resizeMode="stretch" />
      <View style={{ position: 'relative' }}>
        <View style={styles.shapeContainer}>
          <ButtonShape isPointeable={false} bgColor="white" heightBtn={isNativeOrPWA ? 38 : 48}>
            <Text style={styles.title}>{title}</Text>
          </ButtonShape>
        </View>
        {children}
        {!!step && (
          <View style={[styles.numberContainer, leftSide ? { left: isNativeOrPWA ? -52 : -66 } : { right: isNativeOrPWA ? -52 : -66 }]}>
            <ButtonShape isPointeable={false} bgColor="white" square heightBtn={isNativeOrPWA ? 52 : 66}>
              <View style={styles.number}>
                <Text style={styles.numberText}>{step}</Text>
              </View>
            </ButtonShape>
          </View>
        )}
      </View>
    </View>
  );
};

export default StepBlock;
