import { useMemo, useState } from 'react';
import { TagsEnum } from '../commons/dtos/tag.dto';
import type { BoardCards } from '../screens/GameBoard/gameTypes';
import { handHelper } from './useHand';

export function useBoardCard() {
  const [boardCards, setBoardCards] = useState<BoardCards>([]);

  const boardCardHandler = handHelper(setBoardCards);

  const boardHasNoMovements = boardCards.every(card => !!card.isWaiting);

  const boardCardsHasBarrier = useMemo(
    () => boardCards.some(({ tags }) => (tags || []).some(({ tagName }) => tagName === TagsEnum.BARRIER)),
    [boardCards]
  );

  return {
    boardCards,
    boardCardHandler,
    boardHasNoMovements,
    boardCardsHasBarrier
  };
}
