import { Card } from '../commons/dtos/card.dto';
import { GameBoardConfiguration } from '../commons/dtos/game-board-configuration.dto';
import { useBoardCard } from './useBoardCard';
import { useHand } from './useHand';
import { useSide } from './useSide';

export function usePlayer(
  boardConfiguration: GameBoardConfiguration,
  side: keyof Pick<GameBoardConfiguration, 'player' | 'opponent'>,
  selectedToDiscardCards: number[],
  offset = 0
) {
  const {
    deck: initialDeck,
    random,
    initialHandSize,
    actionPoints: initialActionPoints,
    lifePoints: initialLifePoints
  } = boardConfiguration[side]!;

  const { cardIsTravelling, deck, hand, handMethods, handleDeck, handleHand, travelingCardPosition, handHasMovements } = useHand({
    initialDeck: initialDeck as Card[],
    initialHandSize,
    random: random,
    side,
    selectedToDiscardCards,
    offset
  });

  const {
    actionPoints,
    lifePoints,
    maxActionPoints,
    handleSide,
    isAttackable,
    isAttacking,
    handleAttackable,
    handleAttacking,
    isBeingAttacked,
    handleIsBeingAttacked
  } = useSide({
    actionPoints: initialActionPoints,
    lifePoints: initialLifePoints
  });

  const { boardCardHandler, boardCards, boardHasNoMovements, boardCardsHasBarrier } = useBoardCard();

  return {
    hand: {
      cardIsTravelling,
      deck,
      hand,
      handMethods,
      handleDeck,
      handleHand,
      travelingCardPosition,
      handHasMovements
    },
    side: {
      actionPoints,
      lifePoints,
      maxActionPoints,
      handleSide,
      isAttackable,
      isAttacking,
      handleAttackable,
      handleAttacking,
      isBeingAttacked,
      handleIsBeingAttacked
    },
    boardCard: {
      boardCardHandler,
      boardCards,
      boardHasNoMovements,
      boardCardsHasBarrier
    }
  };
}
