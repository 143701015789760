import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { View, Text, Pressable, Linking } from 'react-native';

import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import GradientContent from '../../commons/components/GradientContent/GradientContent';
import { useLeagalPrivacyStyles } from '../../commons/components/LeagalPrivacy/useLeagalPrivacyStyles';

import { isNativeOrPWA } from '../../commons/utils';
import { RootStackParamList } from '../../types';

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Terms', undefined>;

const TermsText = () => {
  const navigation = useNavigation<Navigation>();
  const styles = useLeagalPrivacyStyles();
  const colorsScreen = ['#313131', 'transparent', 'transparent', '#313131'];

  const openPrivacyURL = async () => {
    navigation.navigate('PrivacyPolicy');
  };
  // const openCookiesURL = async () => {
  //   navigation.navigate('Login');
  // };

  return (
    <GradientContent
      colors={colorsScreen}
      parentStyle={[styles.mainContent, styles.relative, styles.center]}
      scrollViewStyle={styles.textContainer}
      locations={[0, 0.2, 0.8, 1]}
    >
      <>
        <View style={[styles.mainContent, styles.paddingStartText]}>
          <View style={styles.button} pointerEvents="none">
            <ButtonShape bgColor="black" heightBtn={isNativeOrPWA ? 34 : 50}>
              <Text style={styles.buttonColor}>TÉRMINOS Y CONDICIONES DEL JUEGO</Text>
            </ButtonShape>
          </View>
        </View>
        <View>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>Los presentes Términos y Condiciones (en adelante, los </Text>
            <Text style={styles.boldText}> “Términos” o “TyC”</Text>
            <Text>) establecen y regulan la relación entre los usuarios y el titular del Sitio Web</Text>
            <Pressable
              onPress={async () => {
                await Linking.openURL('https://fakesover.com');
              }}
            >
              <Text style={styles.url}>https://fakesover.com</Text>
            </Pressable>
            <Text> (en adelante, el </Text>
            <Text style={styles.boldText}>“Sitio Web”</Text>
            <Text>) cuando interactúan con el juego FAKESOVER (en adelante, el </Text>
            <Text style={styles.boldText}>“Juego”</Text>
            <Text>
              ) y su aceptación implica el conocimiento y aceptación expresa de todos los términos contenidos en el mismo, teniendo la misma
              validez y eficacia que cualquier contrato celebrado por escrito y firmado.
            </Text>
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>
              Su observancia y cumplimiento será exigible respecto de cualquier usuario que acceda al Sitio Web y participe en el
              Juego(también denominado
            </Text>
            <Text style={styles.boldText}> “Jugador”</Text>
            <Text> ). Si el usuario no está de acuerdo con los términos expuestos, no deberá acceder al Juego.</Text>
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>1. DATOS DE LA COMPAÑÍA</Text>
          <View style={styles.listMargin}>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Titular: </Text>
              <Text>Newtral Media Audiovisual, S. L. U. (en adelante “</Text>
              <Text style={styles.boldText}>Newtral</Text>
              <Text>”)</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Domicilio social: </Text>
              <Text> Calle Vandergoten 1, 28014 – Madrid.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} NIF: </Text>
              <Text> B-87938304.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Teléfono: </Text>
              <Text> 910888600 </Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} E-mail: </Text>
              <Text style={styles.url}>info@newtral.es </Text>
            </Text>
          </View>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>2. OBJETO Y ÁMBITO DE APLICACIÓN</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Los presentes Términos regulan el acceso al Juego, sin perjuicio de que el Titular se reserve el derecho a modificar la
            presentación, configuración y contenido del mismo, así como las condiciones requeridas para su acceso y/o utilización. Cualquier
            usuario con conexión a internet podrá participar en el Juego.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>3. CONDICIONES DE PARTICIPACIÓN</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Al acceder al Juego el Jugador garantiza que toda la información suministrada es verdadera, precisa y completa y, más
            específicamente, la referente a la edad, pues el Juego está destinado a personas mayores de 14 años, por lo que, si el Jugador
            introduce una edad inferior, no podrá acceder al Juego.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            El Juego es gratuito, por lo que no se requiere a los jugadores ningún desembolso económico u otra contraprestación económica
            para su participación.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            El Jugador deberá introducir un apodo dentro del juego. El Titular se reserva el derecho a no aceptar los nombres elegidos por
            los usuarios cuando considere, según su discrecionalidad, que pueden ser inapropiados u ofensivos. Los nombres inapropiados u
            ofensivos serán eliminados por NEWTRAL y sustituidos por defecto por otro. Los Jugadores deberán tenerlo en cuenta a la hora de
            elegir su apodo para acceder al Juego.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            <Text>
              El tratamiento de datos personales derivados de las condiciones de participación aquí expuestas se refleja en la
              correspondiente
            </Text>
            <Pressable onPress={openPrivacyURL}>
              <Text style={styles.url}> Política de Privacidad </Text>
            </Pressable>
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>4. PREMIO O RECOMPENSA</Text>
          <Text style={[styles.text, styles.paragraph]}>
            No está previstos que haya premios o recompensas de carácter económico o de otro tipo para el Jugador, más allá de la
            información de carácter educativo obtenida en el marco de su participación.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>5. DERECHOS DEL TITULAR</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Todos los derechos relacionados con el Sitio Web son propiedad única y exclusiva de NEWTRAL. Ninguna información, elemento o
            dato del Sitio Web podrá ser utilizado o reproducido en cualquier formato o soporte sin el consentimiento previo por escrito de
            NEWTRAL que deberá autorizarlo previamente a su utilización. Sin perjuicio de lo anterior, en lo no dispuesto en los presentes
            Términos, serán de aplicación las obligaciones recogidas en el Aviso Legal del Sitio Web.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>6. OPERATIVA DEL JUEGO</Text>
          <Text style={[styles.text, styles.paragraph]}>
            El Juego pertenece a la categoría de cartas estratégicas en el que el usuario jugará contra la máquina para ir avanzando en la
            aventura y dinámica del juego, así como ir desbloqueando las diferentes cartas y recompensas del juego. Por ello, el Jugador irá
            adquiriendo experiencia, lo que le permitirá descubrir nuevas cartas, aumentar su posición en la clasificación y continuar con
            la historia del Juego.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            El Juego cuenta con dos modalidades, Campaña y Juego Libre, en las que el Jugador podrá jugar contra la máquina. El objetivo del
            Juego es sensibilizar a los Jugadores sobre el problema de la desinformación y ayudarles a obtener mecanismos y técnicas que les
            ayuden a cómo detectarlas y protegerse ante esta situación.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            El Juego también dispone de una clasificación donde aparece el apodo del Jugador y su nivel de experiencia, por lo que el resto
            de los jugadores podrán ver y comparar su avance en el Juego.
          </Text>
        </View>

        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>7. RESPONSABILIDAD E INTEGRIDAD </Text>
          <Text style={[styles.text, styles.paragraph]}>
            NEWTRAL no se hace responsable de ningún problema o mal funcionamiento técnico de cualquier red o línea telefónica de banda
            ancha, sistemas informáticos en línea, servidores o proveedores, equipos informáticos, fallos de software de cualquier correo
            electrónico o entrada que se reciba a causa de problemas técnicos o congestión de tráfico en Internet, líneas telefónicas o en
            cualquier sitio web, o cualquier combinación de los mismos, incluyendo cualquier lesión o daño al ordenador o teléfono móvil de
            un Jugador. El Jugador acepta que NEWTRAL no será responsable frente a cualquier reclamación derivada de las lesiones o daños
            reflejados anteriormente. Sin perjuicio de lo anterior, en lo no dispuesto en los presentes Términos, serán de aplicación las
            obligaciones recogidas en el Aviso Legal del Sitio Web.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            En caso de que un tribunal considere que alguna de estas condiciones no puede aplicarse por cualquier motivo, ello no impedirá
            que las demás disposiciones sigan siendo de aplicación.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            El Jugador está obligado a cumplir con los presentes Términos para garantizar su participación en el Juego y, en caso contrario,
            NEWTRAL podrá denegar su participación. Además, NEWTRAL podrá modificar total o parcialmente los presentes Términos en cualquier
            momento, a su entera discreción.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>8. PROTECCIÓN DE DATOS</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Los presentes Términos regulan el acceso al Juego, sin perjuicio de que el Titular se reserve el derecho a modificar la
            presentación, configuración y contenido del mismo, así como las condiciones requeridas para su acceso y/o utilización. Cualquier
            usuario con conexión a internet podrá participar en el Juego.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            <Text>
              De conformidad con el Reglamento (UE) 2016/679 y con la Ley Orgánica 3/2018 de Protección de Datos, así como en la normativa
              vigente, se informa al interesado, que NEWTRAL en calidad de Responsable del tratamiento, tratará sus datos personales de
              conformidad con lo dispuesto en la
            </Text>
            <Pressable onPress={openPrivacyURL}>
              <Text style={styles.url}> Política de Privacidad </Text>
            </Pressable>
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>9. LEGISLACIÓN Y JURISDICCIÓN </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Estas condiciones se rigen por la legislación española. Los Jugadores aceptan que cualquier controversia que pueda surgir en la
            interpretación o ejecución de estos Términos estará sujeta a la jurisdicción de los Tribunales de Madrid, salvo la aplicación
            imperativa de cualquier disposición en contrario que pudiera corresponder.
          </Text>
        </View>
        <View style={[styles.marginTop, styles.paddingEndText]}>
          <Text style={[styles.text, styles.paragraph, styles.boldText]}>Última actualización: 1 de marzo de 2023.</Text>
          <Text style={[styles.text, styles.paragraph, styles.boldText]}>
            © 2023 NEWTRAL MEDIA AUDIOVISUAL, S.L.U., Todos los derechos reservados.
          </Text>
        </View>
      </>
    </GradientContent>
  );
};

export default TermsText;
