import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import SideCharacter from '../../../commons/components/Dialogues/SideCharacter';
import useDialogue from '../../../commons/components/Dialogues/useDialogue';
import { prepareReaction } from '../../../commons/components/Dialogues/utils';
import { Reaction } from '../../../commons/dtos/reaction.dto';
import { isGameActionHighlight } from '../../../commons/events-management/game-actions/gameActions';
import store from '../../../redux/store';

interface GameCommunicationProps {
  message: Reaction;
  next: () => void;
  isFreeGame: boolean;
}

const GameCommunication = ({ message, next, isFreeGame }: GameCommunicationProps) => {
  const {
    profile: { player }
  } = store.getState();

  const reaction = prepareReaction(message, isFreeGame, player);

  const { dataRight, renderText, handleStep, actual } = useDialogue({ next, current: reaction });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden'
    }
  });

  const hideByActions =
    reaction.text === '...' || isGameActionHighlight('player-action-points') || isGameActionHighlight('next-turn-button');

  return (
    <TouchableWithoutFeedback onPress={() => handleStep()}>
      <View style={styles.container}>{!hideByActions && <SideCharacter data={dataRight!} actual={actual} render={renderText} />}</View>
    </TouchableWithoutFeedback>
  );
};

const GameCommunicationWrapper = ({ message, next, isFreeGame }: GameCommunicationProps) =>
  !!message && <GameCommunication message={message} next={next} isFreeGame={isFreeGame} />;

export default GameCommunicationWrapper;
