import { useEffect, useRef } from 'react';
import { ImageBackground, StyleSheet, View, Image, Animated, Platform } from 'react-native';
import { twitterCardImages, whatsappCardImages } from '../../../constants/Images';
import { Tweet } from '../../dtos/tweet.dto';
import { isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import TwitterCard from './TwitterCard';
import { WhatsappCard } from './WhatsappCard';

interface CardAccessoryLayoutProps {
  isUndetectable: boolean;
  text: string;
  tweet: Tweet;
  actorKey: string;
  isPlayer: boolean;
  animated?: boolean;
}

const CardAccesoryLayout = ({ isUndetectable, text, actorKey, isPlayer, tweet, animated = false }: CardAccessoryLayoutProps) => {
  const { icon: whatsappIcon, background: whatsappBackground } = whatsappCardImages;
  const { icon: twitterIcon, background: twitterBackground } = twitterCardImages;
  const { rw } = useRatioDimensions();
  const opacity = useRef(new Animated.Value(1)).current;
  const firstRender = useRef(true);

  const { likes, responses, retweets, user } = tweet;

  useEffect(() => {
    const opacityAnimation = Animated.timing(opacity, {
      toValue: 0,
      duration: 50,
      useNativeDriver: Platform.OS !== 'web'
    });
    const opacityAnimationRestart = Animated.timing(opacity, {
      toValue: 1,
      duration: 400,
      useNativeDriver: Platform.OS !== 'web'
    });
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!animated || firstRender.current) return;
    const sequence = Animated.sequence([opacityAnimation, opacityAnimationRestart]);
    sequence.start();
  }, [likes, responses, retweets, user, animated, text]);

  const style = StyleSheet.create({
    container: {
      position: 'absolute',
      right: '120%',
      bottom: rw(4),
      flexDirection: 'row',
      minWidth: isNativeOrPWA ? 150 : 300,
      width: isNativeOrPWA ? rw(27) : rw(18),
      transform: [
        {
          translateX: rw(2.5)
        }
      ]
    },
    background: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      minWidth: '100%',
      padding: isNativeOrPWA ? (isUndetectable ? 18 : 12) : 24,
      marginBottom: rw(3)
    },
    iconImage: {
      alignSelf: 'flex-end',
      width: isNativeOrPWA ? 24 : rw(2.8),
      height: isNativeOrPWA ? 24 : rw(2.8)
    }
  });

  if (animated) {
    return (
      <Animated.View style={[style.container, { opacity }]} pointerEvents="none">
        <ImageBackground source={isUndetectable ? whatsappBackground : twitterBackground} style={style.background} resizeMode="stretch">
          {isUndetectable ? (
            <WhatsappCard text={text} />
          ) : (
            <TwitterCard text={text} tweet={tweet} actorKey={actorKey} isPlayer={isPlayer} animated={animated} />
          )}
        </ImageBackground>
        <Image source={isUndetectable ? whatsappIcon : twitterIcon} style={style.iconImage} />
      </Animated.View>
    );
  }

  return (
    <View style={style.container} pointerEvents="none">
      <ImageBackground source={isUndetectable ? whatsappBackground : twitterBackground} style={style.background} resizeMode="stretch">
        {isUndetectable ? <WhatsappCard text={text} /> : <TwitterCard text={text} tweet={tweet} actorKey={actorKey} isPlayer={isPlayer} />}
      </ImageBackground>
      <Image source={isUndetectable ? whatsappIcon : twitterIcon} style={style.iconImage} />
    </View>
  );
};

export default CardAccesoryLayout;
