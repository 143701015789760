/* eslint-disable no-console */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EventLogs } from '../commons/dtos/create-log.dto';

interface Accumulated {
  accumulated: number;
}

interface StartDateInfo {
  startDate: Date;
}

export default class TimePlayService {
  handleAlert = async (event: EventLogs, showModal: () => void) => {
    try {
      let accumulated = await getAccumulated();
      if (accumulated) {
        if (accumulated.accumulated >= 60) {
          showRandomAlertMessage(showModal);
          await setAccumulated(0);
        }
      } else {
        await setAccumulated(0);
        accumulated = await getAccumulated();
      }

      const now = new Date();
      const startDateInfo = await getStartDateInfo();
      if (EventLogs.START_GAME === event || startDateInfo === null || isAnotherDay(startDateInfo, now)) {
        await setStartDateInfo(now);
        return;
      }

      const { startDate } = startDateInfo;
      const elapsed = now.getTime() - startDate.getTime();
      const minutesDiff = Math.floor(elapsed / 60000);

      const newAccumulated = accumulated?.accumulated ? accumulated.accumulated + minutesDiff : minutesDiff;
      await setAccumulated(newAccumulated);
    } catch (e) {
      console.error('error general en time_play', e);
    }
  };
}

async function setStartDateInfo(startDate: Date): Promise<void> {
  await AsyncStorage.setItem('time_play_info', JSON.stringify({ startDate: startDate }));
}

async function getStartDateInfo(): Promise<StartDateInfo | null> {
  try {
    const jsonValue = await AsyncStorage.getItem('time_play_info');
    if (jsonValue != null) {
      const timePlayedInfo = JSON.parse(jsonValue);
      return { startDate: new Date(timePlayedInfo.startDate) } as StartDateInfo;
    }
  } catch (e) {
    console.info('no existe time_play_info');
  }
  return null;
}

async function setAccumulated(accumulated: number): Promise<void> {
  await AsyncStorage.setItem('time_play_accumulated', accumulated.toString());
}

async function getAccumulated(): Promise<Accumulated | null> {
  try {
    const jsonValue = await AsyncStorage.getItem('time_play_accumulated');
    if (jsonValue != null) {
      return { accumulated: Number(jsonValue) } as Accumulated;
    }
  } catch (e) {
    console.info('no existe time_play_accumulated');
  }
  return null;
}

function isAnotherDay(startDateInfo: StartDateInfo, now: Date): boolean {
  const { startDate } = startDateInfo;
  // Reset time_play
  return startDate.getDay() != now.getDay();
}

function showRandomAlertMessage(showModal: () => void): void {
  showModal();
}
