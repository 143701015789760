import { useCallback, useState } from 'react';

type PromiseResolvePayload<Action extends string> = { action: Action };

interface Modal {
  component: React.FunctionComponent<any>;
  props?: { [key: string]: unknown };
  resolve: (data: PromiseResolvePayload<'CLOSE'>) => void;
}

interface ModalProps {
  closeModal: (param?: PromiseResolvePayload<'CLOSE'>) => void;
}

interface ModalType {
  showModal<Props extends ModalProps>(options: {
    component: React.FunctionComponent<any>;
    props?: Omit<Props, 'closeModal'>;
  }): Promise<NonNullable<Parameters<Props['closeModal']>[0]> | PromiseResolvePayload<'CLOSE'>>;
  closeModal: (param?: PromiseResolvePayload<'CLOSE'>) => void;
}

export function useModal() {
  const [modal, setModal] = useState<Modal | null>(null);

  const showModal = useCallback<ModalType['showModal']>(({ component, props }) => {
    return new Promise(resolve => setModal({ component, props, resolve }));
  }, []);

  const closeModal: ModalType['closeModal'] = useCallback(
    data => {
      modal?.resolve(data || { action: 'CLOSE' });
      setModal(null);
    },
    [modal]
  );

  return {
    showModal,
    closeModal,
    modal
  };
}
