import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { StyleSheet } from 'react-native';
import { deckIcon } from '../../constants/Images';
import { RootStackParamList } from '../../types';
import { View } from './Themed';
import UserMenu from './UserMenu';
import UserMenuButton from './UserMenu/UserProfileButton';

interface UserMenuExtendedProps {
  openModal: Function;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Logout', undefined>;

const UserMenuExtended = ({ openModal }: UserMenuExtendedProps) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  });

  const navigation = useNavigation<Navigation>();

  const handleDeck = () => navigation.navigate('CardInventoryViewer', {});

  return (
    <View style={styles.container}>
      <UserMenuButton handleNavigate={handleDeck} imageSource={deckIcon} />
      <UserMenu openModal={openModal} />
    </View>
  );
};

export default UserMenuExtended;
