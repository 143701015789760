export const MIN_DECK_SIZE = 1;
export const MAX_CARD_INVENTORY_FETCH_SIZE = 50;
export const MAX_ACTION_POINTS = 10;
export const MAX_HAND_OF_CARDS_LENGTH = 8;
export const MAX_BOARD_CARDS_LENGTH = 8;
export const DISCARD_CORRECTION_FACTOR = 3;
export const DISCARD_MAX_MATCH = 10;
export const DISCARD_MIN_MATCH = 5;

export const BASE_ASPECT_RATIO = 16 / 9;
