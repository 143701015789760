import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

interface GameboardModalProps {
  children: ReactNode;
  lotttie?: ReactNode;
}

const GameboardModal = ({ children, lotttie }: GameboardModalProps) => {
  const styles = StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 20000
    },
    cardsContainer: {
      marginBottom: '5%',
      width: '65%',
      height: '60%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
  return (
    <View style={styles.container}>
      <View style={styles.cardsContainer}>{children}</View>
      {lotttie ? lotttie : null}
    </View>
  );
};

export default GameboardModal;
