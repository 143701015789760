/* eslint-disable @typescript-eslint/no-var-requires */
import { StyleSheet, ScrollView, ImageBackground, Image, TouchableWithoutFeedback, ViewStyle } from 'react-native';
import useUnits from 'rxn-units';
import { subjectIconContainerBlack, subjectIconContainerWhite } from '../../constants/Images';
import { Card } from '../dtos/card.dto';
import { PlayerRoundEnum } from '../dtos/player.dto';
import { SubjectsEnum } from '../dtos/subject.dto';
import { getSubjectIcon } from '../utils';
import { View, Text } from './Themed';

interface CardsListViewerProps {
  cards: Card[];
  onCardPressed?: (card: Card, side: PlayerRoundEnum, left: ViewStyle) => void;
  onLeftoverCard?: Function;
}
const CardsListViewer = (props: CardsListViewerProps) => {
  const { vh, vw } = useUnits();
  const styles = StyleSheet.create({
    container: { width: '90%', height: '110%' },
    deckCardSuperContainer: { position: 'relative', width: '115%', left: '5%', height: vh(9.3), top: vh(0.5) },
    deckCard: {
      width: '82.5%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'center',
      height: vh(10),
      borderRadius: vh(1.5)
    },
    deckCardIllustration: {
      width: '100%',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      borderWidth: vh(0.7),
      height: vh(52),
      borderRadius: vh(1.5)
    },
    cardName: { marginLeft: '15%', width: '60%', zIndex: 10, fontWeight: '900', textAlign: 'left', fontSize: vw(1.1) },
    subjectIconContainer: {
      position: 'absolute',
      height: '100%',
      left: '-3%',
      top: '-15%',
      zIndex: 100,
      elevation: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: vw(2.5)
    },
    subjectIconContainerBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 100,
      elevation: 10
    },
    subjectIcon: { width: '55%', height: '55%', marginTop: '20%', zIndex: 200 }
  });

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      {props.cards.map((card, index) => {
        const cardStyles = StyleSheet.create({
          deckCardIllustration: {
            borderColor: card.color ? '#000' : '#FFF',
            backgroundColor: card.color ? '#01eebd' : '#ffe15e'
          },
          cardName: { color: card.color ? '#002B26' : '#643A00' }
        });
        return (
          <TouchableWithoutFeedback
            key={`${card.cardName}_${index}`}
            onPress={() => props.onLeftoverCard && props.onLeftoverCard(card, index)}
            onLongPress={() => props.onCardPressed && props.onCardPressed(card, PlayerRoundEnum.PLAYER, { left: 0, top: 0 })}
          >
            <View style={styles.deckCardSuperContainer}>
              <View style={styles.subjectIconContainer}>
                {card.subject && card.subject.subjectName !== SubjectsEnum.NEUTRAL && (
                  <Image style={styles.subjectIcon} source={getSubjectIcon(card)} resizeMode={'contain'} />
                )}
                <ImageBackground
                  style={styles.subjectIconContainerBackground}
                  source={card.color ? subjectIconContainerBlack : subjectIconContainerWhite}
                  resizeMode={'contain'}
                />
              </View>
              <View style={styles.deckCard}>
                <View style={[styles.deckCardIllustration, cardStyles.deckCardIllustration]}></View>
                <Text style={[styles.cardName, cardStyles.cardName]} ellipsizeMode="tail" numberOfLines={1}>
                  {card.cardName.toUpperCase()}
                </Text>
              </View>
            </View>
          </TouchableWithoutFeedback>
        );
      })}
    </ScrollView>
  );
};

export default CardsListViewer;
