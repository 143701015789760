import { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import TextWithHTML from '../../../commons/components/TextWithHTML';
import { View } from '../../../commons/components/Themed';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import Bubble from './Bubble';

interface MessagePositionProps {
  message?: string;
  nativeID: PlayerRoundEnum;
  next: Function;
}
const MessagePosition = ({ nativeID, message, next }: MessagePositionProps) => {
  const isOpponent = nativeID !== PlayerRoundEnum.PLAYER;
  const { rw, rh } = useRatioDimensions();

  const bottom = isNativeOrPWA ? rh(3) : rh(1);
  const styles = StyleSheet.create({
    messageContainer: {
      position: 'relative',
      bottom: !isOpponent ? bottom : undefined,
      width: rw(7),
      height: rw(7)
    },
    bubbleText: { color: 'black', width: '100%', textAlign: 'center', fontSize: isNativeOrPWA ? 11 : 15 }
  });

  useEffect(() => {
    if (!message) return;
    const to = setTimeout(next, 10000);
    return () => clearTimeout(to);
  }, [message]);

  return (
    <View pointerEvents="none" style={styles.messageContainer}>
      {!!message && (
        <Bubble color={!isOpponent ? 'yellow' : 'blue'} renderText={!!message}>
          <View style={{ maxWidth: '100%' }}>
            <TextWithHTML
              generalTextStyle={styles.bubbleText}
              htmlStyles={{ bold: { fontWeight: 'bold' }, italic: { fontStyle: 'italic' } }}
              text={message}
            />
          </View>
        </Bubble>
      )}
    </View>
  );
};
export default MessagePosition;
