import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer } from 'redux-persist';
import { put, takeLatest } from 'redux-saga/effects';
import { PlayerTypesEnum } from '../../commons/dtos/player.dto';

export interface authProps {
  auth: AuthState;
}

export interface User {
  playerId?: string;
  email: string;
  userId: string;
  dateBorn: Date | undefined;
  idToken: string;
  refreshToken?: string;
  expires: number;
  type: PlayerTypesEnum;
}

export interface AuthState {
  user?: User;
  idToken?: string;
  refreshToken?: string;
}

export interface AuthStateAction {
  type: string;
  payload: AuthState;
}

interface AuthActions {
  login: Function;
  logout: Function;
  refreshToken: Function;
  requestUser: Function;
  fulfillUser: Function;
  setUser: Function;
}

// eslint-disable-next-line no-shadow
export enum actionTypes {
  Login = '[Login] Action',
  Logout = '[Logout] Action',
  RefreshToken = '[RefreshToken] Action',
  UserRequested = '[Request User] Action',
  UserLoaded = '[Load User] Auth API',
  SetUser = '[Set User] Action'
}

const initialAuthState: AuthState = {};

export const reducer = persistReducer(
  { storage: AsyncStorage, key: 'fakesover', whitelist: ['user', 'idToken', 'refreshToken'] },
  (state: AuthState = initialAuthState, action: AuthStateAction) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { payload } = action;
        return { idToken: payload?.idToken, refreshToken: payload?.refreshToken };
      }

      case actionTypes.RefreshToken: {
        const { payload } = action;
        return { idToken: payload?.idToken, refreshToken: payload?.refreshToken };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { payload } = action;
        return { ...state, user: payload?.user };
      }

      case actionTypes.SetUser: {
        const { payload } = action;
        return { ...state, user: payload?.user };
      }

      default:
        return state;
    }
  }
);

export const actions: AuthActions = {
  login: (user: User, idToken: string, refreshToken: string) => ({
    type: actionTypes.Login,
    payload: { user, idToken, refreshToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  refreshToken: (idToken: string, refreshToken: string) => ({
    type: actionTypes.RefreshToken,
    payload: { idToken, refreshToken }
  }),
  requestUser: (user: User) => ({
    type: actionTypes.UserRequested,
    payload: { user }
  }),
  fulfillUser: (user: User) => ({
    type: actionTypes.UserLoaded,
    payload: { user }
  }),
  setUser: (user: User) => ({ type: actionTypes.SetUser, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* userRequested(state: AuthStateAction) {
    yield put(actions.fulfillUser(state.payload?.user));
  });
}
