import { useEffect } from 'react';
import { ImageBackground, StyleSheet, Text } from 'react-native';
import useUnits from 'rxn-units';
import { GendersEnum } from '../../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { rewardTitleOpen } from '../../../constants/Images';
import store from '../../../redux/store';
import { TitleReveal } from '../useReward';
import { useRewardReveal } from '../useRewardReveal';

interface RewardTitleOpenProps {
  title: Required<TitleReveal> | null;
  updateTitle: (title: Required<TitleReveal>) => void;
  handleActiveBack: () => void;
}

const RewardTitleOpen = ({ title, updateTitle, handleActiveBack }: RewardTitleOpenProps) => {
  const { vh } = useUnits();

  const {
    profile: { player }
  } = store.getState();

  const { handleSingleReveal } = useRewardReveal(updateTitle, title, handleActiveBack);

  useEffect(() => {
    title && handleSingleReveal(title);
  }, []);

  if (!title) return <></>;

  const { femaleText, maleText } = title;

  const correctTitle = player?.gender === GendersEnum.MALE ? maleText : femaleText;

  const calculateFontSize = () => {
    const { length } = correctTitle;
    if (length > 30) {
      return isNativeOrPWA ? 24 : 40;
    } else if (length > 20) {
      return isNativeOrPWA ? 26 : 46;
    } else {
      return isNativeOrPWA ? 30 : 52;
    }
  };

  const style = StyleSheet.create({
    image: {
      width: vh(isNativeOrPWA ? 90 : 70),
      height: vh(isNativeOrPWA ? 90 : 80),
      justifyContent: 'center',
      alignItems: 'center'
    },
    text: {
      textAlign: 'center',
      width: 'auto',
      maxWidth: '60%',
      textTransform: 'uppercase',
      fontSize: calculateFontSize(),
      fontFamily: 'Inlanders',
      color: '#FEFEFE'
    }
  });

  return (
    <ImageBackground source={rewardTitleOpen} resizeMode="stretch" style={style.image}>
      <Text style={style.text}>{correctTitle}</Text>
    </ImageBackground>
  );
};

export default RewardTitleOpen;
