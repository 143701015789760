import { ReactNode, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, ViewStyle, Image, TouchableWithoutFeedback, Animated, Platform } from 'react-native';
import useUnits from 'rxn-units';
import { nextButtonActive } from '../../constants/Images';

interface SlidePickerProps {
  parentStyle: ViewStyle;
  children: ReactNode[];
  loop?: boolean;
  style?: ViewStyle;
  onChange: Function;
  list: any;
  indexSelected?: number;
}

const SlidePicker = ({ children, parentStyle, loop = false, style, onChange, indexSelected, list = [] }: SlidePickerProps) => {
  const { vw } = useUnits();
  const [step, setStep] = useState<number>(indexSelected || 0);
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    const animationStart = Animated.timing(opacity, { toValue: 0.4, useNativeDriver: Platform.OS !== 'web', duration: 0 });
    const animationEnd = Animated.timing(opacity, { toValue: 1, useNativeDriver: Platform.OS !== 'web', duration: 1000 });
    const sequence = Animated.sequence([animationStart, animationEnd]);
    sequence.start();
  }, [step]);

  const styles = StyleSheet.create({
    nextButton: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      justifyContent: 'center'
    },
    nextButtonLeft: {
      left: -vw(3)
    },
    nextButtonRight: {
      right: -vw(3)
    },
    imageNextButton: {
      width: vw(3),
      height: vw(3),
      maxHeight: 40,
      maxWidth: 40
    },
    imageNextButtonLeft: {
      transform: [{ rotate: '180deg' }]
    },
    disabledButton: {
      opacity: 0.4
    }
  });

  const handleStep = (type: string) => {
    let selectedIndex = step;
    if (type === 'prev') {
      selectedIndex = step > 0 ? step - 1 : children.length - 1;
    } else if (type === 'next') {
      selectedIndex = step < children.length - 1 ? step + 1 : 0;
    }
    setStep(selectedIndex);
    onChange(list[selectedIndex]);
  };

  return (
    <View style={parentStyle}>
      <TouchableWithoutFeedback onPress={() => handleStep('prev')} disabled={!loop && step === 0}>
        <View style={[styles.nextButton, styles.nextButtonLeft]}>
          <Image
            source={nextButtonActive}
            resizeMode="contain"
            style={[styles.imageNextButton, styles.imageNextButtonLeft, !loop && step === 0 && styles.disabledButton]}
          />
        </View>
      </TouchableWithoutFeedback>
      {style ? <Animated.View style={[style, { opacity }]}>{children[step]}</Animated.View> : children[step]}

      <TouchableWithoutFeedback onPress={() => handleStep('next')} disabled={!loop && step === children?.length - 1}>
        <View style={[styles.nextButton, styles.nextButtonRight]}>
          <Image
            source={nextButtonActive}
            resizeMode="contain"
            style={[styles.imageNextButton, !loop && step === children?.length - 1 && styles.disabledButton]}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default SlidePicker;
