import { actionPoints, rarities, subjectsStats } from '../../../constants/CardStatsFilters';
import { CardInventoryFilter } from '../../dtos/card-inventory-filter.dto';

export const useCardStatsFilter = (cardInventoryFilters: CardInventoryFilter, setCardInventoryFilters: Function) => {
  const updatedFilters = { ...cardInventoryFilters };
  const activeRaritiesStat = rarities.findIndex(r => r.value === cardInventoryFilters.rarityFilter);
  const activeActionPointsStat = actionPoints.findIndex(a => a.value === cardInventoryFilters.actionPointsFilter);
  const activeSubjectsStat = subjectsStats.findIndex(s => s.value === cardInventoryFilters.subjectFilter);

  const setActiveRaritiesStats = (newValue: number) => {
    if (newValue >= 0) {
      updatedFilters.rarityFilter = rarities[newValue]?.value;
      setCardInventoryFilters(updatedFilters);
    } else {
      updatedFilters.rarityFilter = undefined;
      setCardInventoryFilters(updatedFilters);
    }
  };

  const setActiveActionPointsStats = (newValue: number) => {
    if (newValue >= 0) {
      updatedFilters.actionPointsFilter = actionPoints[newValue]?.value;
      setCardInventoryFilters(updatedFilters);
    } else {
      updatedFilters.actionPointsFilter = undefined;
      setCardInventoryFilters(updatedFilters);
    }
  };

  const setActiveSubjectsStats = (newValue: number) => {
    if (newValue >= 0) {
      updatedFilters.subjectFilter = subjectsStats[newValue]?.value;
      setCardInventoryFilters(updatedFilters);
    } else {
      updatedFilters.subjectFilter = undefined;
      setCardInventoryFilters(updatedFilters);
    }
  };

  return {
    activeSubjectsStat,
    setActiveSubjectsStats,
    activeActionPointsStat,
    setActiveActionPointsStats,
    activeRaritiesStat,
    setActiveRaritiesStats
  };
};
