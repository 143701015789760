import { createSlice } from '@reduxjs/toolkit';
import { ImageSourcePropType } from 'react-native';

export interface LoadingMissionBackgroundConfig {
  loadingMissionBackground: ImageSourcePropType | undefined;
}

const initialState: LoadingMissionBackgroundConfig = {
  loadingMissionBackground: undefined
};

const loadingMissionBackground = createSlice({
  name: 'loadingMissionBackground',
  initialState,
  reducers: {
    setLoadingMissionBackground(state, action: { payload: ImageSourcePropType | undefined; type: string }) {
      return { ...state, loadingMissionBackground: action.payload };
    }
  }
});

export const { reducer: loadingMissionBackgroundReducer } = loadingMissionBackground;
export const { setLoadingMissionBackground } = loadingMissionBackground.actions;
