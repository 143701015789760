/* eslint-disable react-native/no-unused-styles */
import { useMemo } from 'react';
import { StyleSheet, LogBox } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { BoardCardsProps } from './BoardCardsMobile';
import BoardCardsWrapper from './BoardCardsWrapper';

LogBox.ignoreLogs(['ViewPropTypes will be removed']);

const BoardCards = (props: BoardCardsProps) => {
  const { config } = props;
  const { isPlayerRound } = config;
  const { rw } = useRatioDimensions();

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        position: 'absolute',
        width: '70%',
        height: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignContent: 'center',
        zIndex: 1
      },
      dropContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        zIndex: 1
      },
      dropContainerBackground: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        zIndex: 10,
        position: 'absolute',
        top: 0,
        left: 0
      },
      boardCardscontainer: {
        width: 'auto',
        height: '40%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
      cardsContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 20,
        zIndex: isPlayerRound ? 10 : 9
      },
      cardsContainerOpponent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
        zIndex: !isPlayerRound ? 10 : 9
      },
      card: {
        width: rw(7.4),
        height: rw(10.5),
        margin: rw(0.9)
      }
    });
  }, [rw]);

  return <BoardCardsWrapper props={props} styles={styles} />;
};
export default BoardCards;
