import { StyleSheet, Animated } from 'react-native';
import { backOfCard } from '../../constants/Images';

const TravellingCards = ({
  playerTravellingCardPosition,
  playerCardIsTravelling,
  opponentTravellingCardPosition,
  opponentCardIsTravelling
}: {
  playerTravellingCardPosition: Animated.ValueXY;
  playerCardIsTravelling: boolean;
  opponentTravellingCardPosition: Animated.ValueXY;
  opponentCardIsTravelling: boolean;
}) => {
  const rotateCardPlayer = '20deg';
  const rotateCardOpponent = '155deg';
  const styles = StyleSheet.create({
    travellingCard: { position: 'absolute', width: '7%', height: '17%' },
    travellingCardPlayer: { right: '3%', bottom: '15%', transform: [{ rotate: rotateCardPlayer }] },
    travellingCardOpponent: { right: '5%', top: '11%', transform: [{ rotate: rotateCardOpponent }] }
  });

  return (
    <>
      {playerCardIsTravelling && (
        <Animated.Image
          style={[
            styles.travellingCard,
            styles.travellingCardPlayer,
            { transform: [...playerTravellingCardPosition.getTranslateTransform(), { rotate: rotateCardPlayer }] }
          ]}
          source={backOfCard}
          resizeMode={'contain'}
        />
      )}
      {opponentCardIsTravelling && (
        <Animated.Image
          style={[
            styles.travellingCard,
            styles.travellingCardOpponent,
            { transform: [...opponentTravellingCardPosition.getTranslateTransform(), { rotate: rotateCardOpponent }] }
          ]}
          source={backOfCard}
          resizeMode={'contain'}
        />
      )}
    </>
  );
};

export default TravellingCards;
