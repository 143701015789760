import React from 'react';
import { ImageSourcePropType, StyleSheet } from 'react-native';
import { View } from '../../../commons/components/Themed';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { ReactionManagementTweet } from '../../../commons/events-management/tweets/tweets';
import { isNativeOrPWA } from '../../../commons/utils';
import { ItemInfo } from '../../../types/drag';
import AvatarInfo from './AvatarInfo';

export interface AvatarsContainerProps {
  clearActionsDrop: Function;
  setShowAttackingCardDrag: Function;
  playerLifePoints: number;
  isLaunchingCardFromHand: boolean;
  isPlayerAttackable: boolean;
  playerIsBeingAttacked: boolean;
  playerHandleIsBeingAttacked: (isAttacked: boolean) => void;
  isPlayerAttacking: boolean;
  opponentLifePoints: number;
  isOpponentAttackable: boolean;
  isOpponentAttacking: boolean;
  opponentIsBeingAttacked: boolean;
  opponentHandleIsBeingAttacked: (isAttacked: boolean) => void;
  onAttackAvatarWithCard: (attackingCardIndex: number) => void;
  dragCard: string;
  isPlayerRound: boolean;
  enemyImage: ImageSourcePropType;
  enemyName?: string | undefined;
  showAnimationLifePoints: {
    showAnimation: boolean;
    damage: number;
    player: PlayerRoundEnum;
  };
  avatarAttacked: boolean;
  reactionsTweetsManagementProps: (life: number) => ReactionManagementTweet;
  isFreeGame: boolean;
  handleAddItem: (id: string, info: ItemInfo) => void;
  handleShowAvatarModal: () => void;
  playerHasBarrier: boolean;
  opponentHasBarrier: boolean;
}

const AvatarsContainer = ({
  clearActionsDrop,
  dragCard,
  enemyImage,
  enemyName,
  isLaunchingCardFromHand,
  isOpponentAttackable,
  isOpponentAttacking,
  opponentIsBeingAttacked,
  isPlayerAttackable,
  isPlayerAttacking,
  playerIsBeingAttacked,
  onAttackAvatarWithCard,
  opponentLifePoints,
  playerLifePoints,
  opponentHandleIsBeingAttacked,
  playerHandleIsBeingAttacked,
  setShowAttackingCardDrag,
  showAnimationLifePoints,
  isPlayerRound,
  avatarAttacked,
  reactionsTweetsManagementProps,
  isFreeGame,
  handleAddItem,
  handleShowAvatarModal,
  opponentHasBarrier,
  playerHasBarrier
}: AvatarsContainerProps) => {
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      width: '7.5%',
      height: isNativeOrPWA ? '78%' : '85%',
      left: '5%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      textAlign: 'center'
    }
  });

  return (
    <View style={styles.container}>
      <AvatarInfo
        nativeID={PlayerRoundEnum.OPPONENT}
        clearActionsDrop={clearActionsDrop}
        setShowAttackingCardDrag={setShowAttackingCardDrag}
        lifePoints={opponentLifePoints}
        isLaunchingCardFromHand={isLaunchingCardFromHand}
        isAttackable={isOpponentAttackable}
        isBeingAttacked={opponentIsBeingAttacked}
        handleIsBeingAttacked={opponentHandleIsBeingAttacked}
        isAttacking={isOpponentAttacking}
        onAttackAvatarWithCard={onAttackAvatarWithCard}
        dragCard={dragCard}
        isPlayerRound={isPlayerRound}
        lowOpacity={!isPlayerRound && isPlayerAttacking && !isOpponentAttackable}
        avatar={enemyImage}
        name={enemyName}
        showAnimationLifePoints={showAnimationLifePoints}
        avatarAttacked={avatarAttacked}
        reactionsTweetsManagementProps={reactionsTweetsManagementProps}
        isFreeGame={isFreeGame}
        handleAddItem={handleAddItem}
        handleShowAvatarModal={handleShowAvatarModal}
        opponentHasBarrier={opponentHasBarrier}
        playerHasBarrier={playerHasBarrier}
      />
      <AvatarInfo
        nativeID={PlayerRoundEnum.PLAYER}
        clearActionsDrop={clearActionsDrop}
        setShowAttackingCardDrag={setShowAttackingCardDrag}
        lifePoints={playerLifePoints}
        isLaunchingCardFromHand={isLaunchingCardFromHand}
        isAttackable={isPlayerAttackable}
        isBeingAttacked={playerIsBeingAttacked}
        handleIsBeingAttacked={playerHandleIsBeingAttacked}
        isAttacking={isPlayerAttacking}
        onAttackAvatarWithCard={onAttackAvatarWithCard}
        dragCard={dragCard}
        isPlayerRound={isPlayerRound}
        lowOpacity={isPlayerRound && isOpponentAttacking && !isPlayerAttackable}
        showAnimationLifePoints={showAnimationLifePoints}
        avatarAttacked={avatarAttacked}
        reactionsTweetsManagementProps={reactionsTweetsManagementProps}
        isFreeGame={isFreeGame}
        handleAddItem={handleAddItem}
        handleShowAvatarModal={handleShowAvatarModal}
        opponentHasBarrier={opponentHasBarrier}
        playerHasBarrier={playerHasBarrier}
      />
    </View>
  );
};
export default AvatarsContainer;
