import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { StyleSheet, View, Text, Image, TouchableHighlight } from 'react-native';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ButtonSignInGoogleG } from '../../../constants/Images';
import { useGoogle } from '../../../hooks/useGoogle';
import { User, actions, authProps } from '../../../redux/Auth/authRedux';
import { hideLoginModal } from '../../../redux/Login-modal/loginModal';
import { setProfile } from '../../../redux/Profile/profileReducer';
import LoginService from '../../../services/login.service';
import LogsService from '../../../services/logs.service';
import { RootStackParamList } from '../../../types';
import { EventLogs } from '../../dtos/create-log.dto';
import { PlayerTypesEnum } from '../../dtos/player.dto';
import adjust from '../../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';

const loginService = new LoginService();
const logService = new LogsService();

const LoginModalContent = () => {
  const { rh } = useRatioDimensions();

  const dispatch = useDispatch();

  const { user } = useSelector(({ auth }: authProps) => ({
    user: auth.user as User
  }));

  const { isAuthorized } = useSelector(
    ({ auth }: authProps) => ({
      isAuthorized: auth.idToken != null
    }),
    shallowEqual
  );

  const { signInGoogle, userExists, getCredentials } = useGoogle();

  const doSignInGoogle = async () => {
    try {
      const result = await signInGoogle();
      if (result && result?.result?.type === 'success') {
        const user = getCredentials(result);
        if (!user || user === null) {
          throw new Error('Something went wrong signing into Google');
        }
        dispatch(actions.login({ ...user, playerId: user.playerId }, user.idToken));
        const verification = await userExists(user.email);
        const { player } = verification;
        if (!player) return;
        const newUser = { ...user, dateBorn: player.dateBorn };
        dispatch(actions.login({ ...newUser, playerId: player?.id }, newUser.idToken));
        dispatch(setProfile(player));
        dispatch(hideLoginModal());
        logService.createLog(EventLogs.RELOGIN);
      }
    } catch (error) {
      throw new Error(`Error signing in: ${error as Error}`);
    }
  };

  const doSignInGuest = async () => {
    try {
      const timestamp = new Date().getTime();
      const { guestToken } = await loginService.getGuestPlayer(process.env.GOOGLE_OAUTH_CLIENT_ID ?? (GOOGLE_OAUTH_CLIENT_ID as string));
      const { playerId, email, userId, dateBorn } = user;
      const refreshUser: User = {
        playerId,
        email,
        userId,
        dateBorn,
        idToken: guestToken,
        expires: timestamp + 3600 * 1000,
        type: PlayerTypesEnum.GUEST
      };
      dispatch(actions.setUser(refreshUser));
      dispatch(hideLoginModal());
    } catch (error) {
      throw new Error(`Error signing in: ${error as Error}`);
    }
  };
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList, 'Login', undefined>>();
  const doLogout = async () => {
    try {
      if (isAuthorized) {
        const hideModalLogin = () => dispatch(hideLoginModal());
        hideModalLogin();
        return navigation.navigate('Logout');
      }
    } catch (error) {
      throw new Error(`Error refresh signing in: ${error as Error}`);
    }
  };

  const message = 'Tu sesión ha expirado. Por favor, vuelve a entrar dándole clic al siguiente enlace.';

  const styles = StyleSheet.create({
    title: {
      color: '#FFF',
      fontSize: 32,
      fontWeight: '800'
    },
    explanation: {
      color: '#FFF',
      fontSize: 21,
      fontWeight: '500',

      textAlign: 'center'
    },
    buttonContainer: {
      width: isNativeMobile ? '50%' : '20%',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: rh(1),
      minWidth: !isNativeOrPWA ? 300 : 'auto',
      maxWidth: isNativeOrPWA ? 240 : 'auto'
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    },
    googleButtonSignInText: {
      flexGrow: 0,
      flexShrink: 0,
      fontSize: isNativeOrPWA ? adjust(16) : adjust(16),
      fontWeight: '500',
      color: '#000000',
      fontFamily: 'Roboto_500Medium'
    },
    buttonSignInText: {
      flexGrow: 0,
      flexShrink: 0,
      fontSize: isNativeOrPWA ? adjust(16) : adjust(18),
      fontWeight: '600',
      color: '#fff'
    },
    buttonSignInGoogleG: {
      width: isNativeOrPWA ? adjust(18) : adjust(24),
      height: isNativeOrPWA ? adjust(18) : adjust(24),
      resizeMode: 'contain'
    },
    buttonSignInGoogleText: {
      marginLeft: 14
    }
  });

  return (
    <>
      <Text style={styles.title}>¡Cuidado!</Text>
      <Text style={[styles.explanation, { maxWidth: isNativeOrPWA ? '80%' : 512 }]}>{message}</Text>
      {PlayerTypesEnum.USER === user?.type ? (
        <TouchableHighlight
          underlayColor={'transparent'}
          accessible
          accessibilityLabel={'Login with google'}
          onPress={() => doSignInGoogle()}
        >
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="white" heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Image style={styles.buttonSignInGoogleG} source={ButtonSignInGoogleG} />
                <Text style={[styles.googleButtonSignInText, styles.buttonSignInGoogleText]}>Continuar con Google</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableHighlight>
      ) : (
        <TouchableHighlight underlayColor={'transparent'} accessible accessibilityLabel={'Login as guest'} onPress={() => doSignInGuest()}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.buttonSignInText}>Extender sesión de invitado</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableHighlight>
      )}
      <TouchableHighlight underlayColor={'transparent'} accessible accessibilityLabel={'Login with google'} onPress={() => doLogout()}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={[styles.buttonSignInText, styles.buttonSignInGoogleText]}>Cerrar Sesión</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableHighlight>
    </>
  );
};

export default LoginModalContent;
