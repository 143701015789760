import { useMemo } from 'react';
import { ImageBackground, SafeAreaView, StyleSheet, Text, TouchableWithoutFeedback, View, Image } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import adjust from '../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../commons/utils';
import { backgroundReward, nextButtonActive } from '../../constants/Images';
import { rewardsSound } from '../../constants/Sound';
import { useMusic } from '../../hooks/useMusic';
import { Config } from '../../redux/Configuration/configuration';
import { RewardsProps } from '../../types';
import RewardsList from './RewardsList';
import RewardAvatarOpen from './RewardsOpen/RewardAvatarOpen';
import RewardCardOpen from './RewardsOpen/RewardCardOpen';
import { RewardsDeckOpen } from './RewardsOpen/RewardDeckOpen';
import RewardTitle from './RewardsOpen/RewardTitleOpen';
import { RewardOptions, useReward } from './useReward';

interface PossibleRewardInfo {
  text: string;
  subtitle?: string;
  component: JSX.Element;
}

const Rewards = ({ route, navigation }: RewardsProps) => {
  const { vh, vw } = useUnits();
  const { gameConfig, level } = route.params;
  const { dialogMusic } = useSelector(
    ({
      configuration: {
        volume: { dialogMusic }
      }
    }: {
      configuration: Config;
    }) => ({ dialogMusic })
  );
  useMusic({
    loop: false,
    play: dialogMusic,
    soundMusic: rewardsSound
  });

  const hasLevelRewards = !!level;
  const hasRewards = !!(gameConfig && gameConfig.rewards && gameConfig.rewards.length > 0);
  const rewards = useMemo(() => (hasLevelRewards ? level.rewards : gameConfig?.rewards), [level, hasLevelRewards, gameConfig]);

  const goCampaign = () => {
    const state = navigation.getState();
    if (state.routes.length > 0) {
      navigation.pop(0);
    }
    navigation.navigate('Campaign');
  };

  const goRewards = () => {
    const state = navigation.getState();
    if (state.routes.length > 0) {
      navigation.pop(0);
    }
    navigation.navigate('Rewards', { gameConfig });
  };

  const { handleRewardChange, selectedItem, claimedRewards, getState, updateState, goBack, handleActiveBack, isSingleReward } = useReward({
    hasLevelRewards,
    hasRewards,
    navigateCampaign: goCampaign,
    navigateRewards: goRewards,
    rewards
  });

  const style = StyleSheet.create({
    background: {
      position: 'absolute',
      flex: 1,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -1
    },
    container: {
      flex: 1,
      backgroundColor: '#1E1E1E',
      position: 'relative'
    },
    topContainer: {
      marginTop: vh(isNativeOrPWA ? 4 : 3),
      flexDirection: 'row',
      position: 'absolute',
      elevation: 10,
      zIndex: 10
    },
    title: {
      fontWeight: '800',
      fontSize: adjust(23),
      color: '#FFF',
      textAlign: 'center',
      marginTop: vh(isNativeOrPWA ? 2 : 4)
    },
    subTitle: {
      fontWeight: '500',
      fontSize: adjust(18),
      color: '#FFF',
      textAlign: 'center',
      marginTop: vh(1)
    },
    rewardsContainerWrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    returnRewards: {
      marginHorizontal: vw(2),
      flexDirection: 'row',
      alignItems: 'center',
      opacity: goBack.active ? 1 : 0.6,
      cursor: 'pointer'
    },
    returnButtonImgRewards: {
      height: isNativeOrPWA ? 25 : 36,
      width: isNativeOrPWA ? 24 : 36,
      marginRight: isNativeOrPWA ? 12 : 18,
      transform: [
        {
          rotate: '180deg'
        }
      ]
    },
    returnButtonText: { fontSize: isNativeOrPWA ? 16 : 22, fontWeight: '700', color: '#FFF', maxWidth: isNativeOrPWA ? 120 : '100%' }
  });

  const { updateAvatar, updateCard, updateCardPack, updateTitle } = updateState;

  const { getAvatar, getCard, getCardPack, getTitle } = getState;

  const rewardsList: Record<RewardOptions, PossibleRewardInfo> = {
    avatar: {
      text: 'Nuevo avatar desbloqueado',
      component: <RewardAvatarOpen avatar={getAvatar()} updateAvatar={updateAvatar} handleActiveBack={handleActiveBack} />
    },
    cardPack: {
      text: 'Descubre tus nuevas cartas',
      subtitle: 'Debes revelar las cartas para poder continuar',
      component: <RewardsDeckOpen cards={getCardPack()} updateCardPack={updateCardPack} handleActiveBack={handleActiveBack} />
    },
    card: {
      text: 'Nueva carta desbloqueada',
      subtitle: 'Debes revelar la carta para poder continuar',
      component: <RewardCardOpen card={getCard()} updateCard={updateCard} handleActiveBack={handleActiveBack} />
    },
    title: {
      text: 'Nuevo titulo desbloqueado',
      component: <RewardTitle title={getTitle()} updateTitle={updateTitle} handleActiveBack={handleActiveBack} />
    },
    list: {
      text: hasLevelRewards ? 'Descubre tus recompensas de nivel' : 'Descubre tus recompensas de misión',
      component: <RewardsList handleRewardChange={handleRewardChange} rewards={claimedRewards || []} />
    }
  };

  const { component, text, subtitle } = rewardsList[selectedItem];

  return (
    <SafeAreaView style={style.container}>
      <ImageBackground source={backgroundReward} style={style.background} resizeMode="cover" />
      <Text style={style.title}>{text}</Text>
      {subtitle ? <Text style={style.subTitle}>{subtitle}</Text> : null}
      <View style={style.topContainer}>
        {goBack.show && (
          <TouchableWithoutFeedback
            onPress={() => (isSingleReward ? handleRewardChange() : handleRewardChange())}
            disabled={!goBack.active}
          >
            <View style={style.returnRewards}>
              <Image source={nextButtonActive} style={style.returnButtonImgRewards} resizeMode="contain" />
              <Text style={style.returnButtonText}>
                {isSingleReward ? (hasLevelRewards ? 'Recompensas de misión' : 'Inicio') : 'Volver'}
              </Text>
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
      <View style={style.rewardsContainerWrapper}>{component}</View>
    </SafeAreaView>
  );
};

export default Rewards;
