import { StyleSheet, ViewStyle } from 'react-native';
import useUnits from 'rxn-units';
import { ColorCardEnum } from '../commons/constants/colorCard';
import { useGameAction } from './useGameAction';

export function useGameActionHighlight(color: ColorCardEnum, cardCode: string): ViewStyle {
  const colorCard = `${color === ColorCardEnum.BLACK ? 'black' : 'white'}-card-hand-${cardCode}`;

  const { vw } = useUnits();
  const styles = StyleSheet.create({
    whiteCardHighlight: {
      shadowColor: '#fee05d',
      borderRadius: vw(2),
      shadowOffset: {
        width: 0,
        height: -3
      },
      shadowOpacity: 0.9,
      shadowRadius: 14.0,
      elevation: 24
    },
    blackCardHighlight: {
      shadowColor: '#07efbb',
      borderRadius: vw(2),
      shadowOffset: {
        width: 0,
        height: -3
      },
      shadowOpacity: 0.9,
      shadowRadius: 14.0,
      elevation: 24
    }
  });

  const highlight = useGameAction(colorCard);

  if (color === ColorCardEnum.BLACK && highlight) return styles.blackCardHighlight;
  if (color === ColorCardEnum.WHITE && highlight) return styles.whiteCardHighlight;

  return {};
}
