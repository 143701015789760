import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, View, Image } from 'react-native';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard, howTip3 } from '../../../../constants/Images';
import { isNativeOrPWA, onlyDesktop } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import BotBlock from '../BotBlock/BotBlock';
import { useStylesHow } from '../useStylesHow';

const HowSectionFakes = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <View style={[styles.splitContainer, isNativeOrPWA && { maxWidth: '90%', marginHorizontal: 'auto' }]}>
        <View style={[styles.splitView, isNativeOrPWA && { flexBasis: '60%' }]}>
          <BotBlock small withBot={onlyDesktop} title={howTitle['advice-3-title']}>
            <Text style={styles.paragraph}>
              <TextWithHTML text={howText['advice-3-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
            </Text>
          </BotBlock>
        </View>
        <View style={[styles.splitView, isNativeOrPWA && { flexBasis: '40%' }]}>
          <Image style={styles.card} resizeMode="contain" source={howTip3} accessible accessibilityLabel="card image" />
        </View>
      </View>
    </ImageBackground>
  );
};

export default HowSectionFakes;
