import { PlayerTypesEnum } from './player.dto';

export enum EventLogs {
  LOGIN = 'login',
  RELOGIN = 'relogin',
  START_GAME = 'start_game',
  HALF_GAME = 'half_game',
  PERCENTAGE = 'percentage',
  END_GAME = 'end_game',
  LOGOUT = 'logout',
  SIGNUP = 'signup'
}

export class CreateLogDto implements LogProperties {
  userId!: string;
  type!: PlayerTypesEnum;
  event!: EventLogs;
  percentageCampaign?: number;
  dateBorn?: Date;

  constructor(properties?: LogProperties) {
    if (properties) {
      this.type = properties.type;
      this.userId = properties.userId;
      this.event = properties.event;
      this.percentageCampaign = properties.percentageCampaign;
      this.dateBorn = properties.dateBorn;
    }
  }
}

export interface LogProperties {
  userId: string;
  type: PlayerTypesEnum;
  event: EventLogs;
  percentageCampaign?: number;
  dateBorn?: Date;
  device?: string;
  browser?: string;
  timestamp?: Date;
}
