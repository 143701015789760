import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Card as CardType } from '../../dtos/card.dto';
import { AppliedEnhanceRule } from '../../dtos/enhance.dto';
import { Tag, TagsExplanationEnum } from '../../dtos/tag.dto';
import TagInfo, { TagInfoProps } from './TagInfo';
import { CardSizesEnum } from './utils';

interface TagsInfoProps {
  card: CardType;
  cardSize: CardSizesEnum;
}

const TagsInfo = ({ card, cardSize }: TagsInfoProps) => {
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      flexDirection: 'column',
      alignContent: 'flex-start',
      gap: 2,
      height: '100%',
      width: '100%',
      left: '110%'
    }
  });

  const [tags, setTags] = useState<TagInfoProps[]>([]);
  useEffect(() => {
    const tagsUpdate: TagInfoProps[] = [];
    const { tags, enhance } = card;
    if (tags && tags.length > 0) {
      tags.forEach((tag: Tag) => {
        tagsUpdate.push({ tagName: `${tag.tagName.trim().toUpperCase()} `, content: TagsExplanationEnum[tag.tagName], cardSize });
      });
    }
    if (enhance) {
      Object.entries(enhance).forEach(([enhanceCardId]) => {
        const damage = (enhance as AppliedEnhanceRule)[enhanceCardId].ruleDamagePoints;
        const defense = (enhance as AppliedEnhanceRule)[enhanceCardId].ruleDefensePoints;
        const damageText = damage && damage > 0 ? `+${damage}` : damage || 0;
        const defenseText = defense && defense > 0 ? `+${defense}` : defense || 0;
        tagsUpdate.push({
          tagName: `${(enhance as AppliedEnhanceRule)[enhanceCardId].ruleName.trim().toUpperCase()} `,
          content: `${damageText} / ${defenseText}`,
          cardSize
        });
      });
    }
    setTags(tagsUpdate);
  }, [card]);

  return (
    <View style={styles.container}>
      {tags.length > 0 &&
        tags &&
        tags.map((tag, index) => {
          return <TagInfo key={index} cardSize={tag.cardSize} tagName={tag.tagName} content={tag.content} />;
        })}
    </View>
  );
};

export default TagsInfo;
