import { StyleProp, TextStyle } from 'react-native';
import adjust from '../../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../../commons/utils';

export const pickerStyle: StyleProp<TextStyle> = {
  backgroundColor: 'transparent',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
};

export const pickerStyleExtended: StyleProp<TextStyle> = {
  ...pickerStyle,
  justifyContent: 'space-between',
  paddingVertical: isNativeOrPWA ? 10 : 18
};

export const pickerStyleError = {
  color: '#718E89',
  backgroundColor: '#FF2E3A'
};

export const pickerTextStyle: StyleProp<TextStyle> = {
  color: '#FFFFFF',
  fontSize: adjust(17),
  fontWeight: '600'
};

export const pickerRowStyle: StyleProp<TextStyle> = {
  fontSize: adjust(22),
  backgroundColor: '#3E3E3E',
  borderBottomColor: '#C5C5C5',
  borderWidth: 1,
  height: isNativeOrPWA ? adjust(50) : 65,
  paddingVertical: isNativeOrPWA ? 5 : 15,
  paddingHorizontal: 0,
  justifyContent: 'center',
  alignItems: 'center'
};
export const pickerSelectedRowStyle = {
  fontSize: adjust(22),
  color: '#FFFFFF',
  backgroundColor: '#2C3230',
  borderColor: '#324F4B',
  borderWidth: 1,
  paddingVertical: isNativeOrPWA ? 5 : 15,
  paddingHorizontal: 0
};

export const pickerTextRowStyle = {
  color: '#FFFFFF',
  fontSize: 17
};
export const pickerSelectedTextRowStyle = {
  fontSize: adjust(22),
  color: '#62BD5F'
};
