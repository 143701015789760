import { LinearGradient } from 'expo-linear-gradient';
import { useEffect, useMemo, useState } from 'react';
import { ImageBackground, StyleSheet, View, Text, TouchableWithoutFeedback, Image, ImageSourcePropType, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import LegalFooter from '../../commons/components/LegalFooter';
import Slides from '../../commons/components/Slides';
import SnackBar from '../../commons/components/SnackBar';
import TopHeader from '../../commons/components/TopHeader';
import { Card } from '../../commons/dtos/card.dto';
import { DeckData } from '../../commons/dtos/deck-data.dto';
import { Deck } from '../../commons/dtos/deck.dto';
import { GameBoardConfiguration } from '../../commons/dtos/game-board-configuration.dto';
import { Rule } from '../../commons/dtos/rule.dto';
import { ApiError } from '../../commons/errors/api-error';
import adjust from '../../commons/styles/TextAdjust';
import { getUniqueRandom, isNativeOrPWA } from '../../commons/utils';
import boardCards from '../../commons/utils/gameboard/boardCards';
import { paginate } from '../../commons/utils/paginate';
import { backgroundDeckSelector, deckSelectorTitlesBg, howBottom } from '../../constants/Images';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { setLoadingMissionBackground } from '../../redux/Loading-mission/loadingMissionBackground';
import { Profile } from '../../redux/Profile/profileReducer';
import store from '../../redux/store';
import CampaignService from '../../services/campaigns.service';
import DecksService from '../../services/decks.service';
import RulesService from '../../services/rules.service';
import { DeckSelectorProps } from '../../types';
import DeckItem from './items/DeckItem';
import NewDeckItem from './items/NewDeckItem';

type DeckItems = 'DeckItem' | 'NewDeckItem';

interface PreProcessDeck {
  component: DeckItems;
  title?: ImageSourcePropType;
  deck?: Deck;
}

const deckService = new DecksService();
const campaignService = new CampaignService();

const DeckSelector = ({ navigation }: DeckSelectorProps) => {
  const { navigate } = navigation;
  const [selectedDeck, setSelectedDeck] = useState<Deck | null>(null);
  const [deckData, setDeckData] = useState<DeckData[]>([]);

  const { player } = useSelector(({ profile }: { profile: Profile }) => profile as Required<Profile>);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  const { id } = player;

  useEffect(() => {
    const fetchDecks = async () => {
      const decks = await deckService.getOrderedDecks(id!);
      setDeckData(decks);
    };
    fetchDecks().catch((err: any) => {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando las mazos`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
    });
  }, [checkShowLoginModal]);

  const initialStepSlider = 0;
  const itemsPerPageSlider = 4;

  const styles = StyleSheet.create({
    background: {
      width: '100%',
      height: '100%',
      flex: 1
    },
    mainContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    textWrapper: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    headerText: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? 21 : 32,
      width: '100%',
      textAlign: 'center',
      paddingBottom: isNativeOrPWA ? 8 : 24,
      fontWeight: '800'
    },
    subtitleText: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? 12 : 21,
      width: '100%',
      textAlign: 'center',
      fontWeight: '500'
    },
    button: {
      flexDirection: 'row',
      width: isNativeOrPWA ? '17%' : 'auto',
      cursor: 'pointer',
      alignItems: 'center',
      elevation: 10,
      zIndex: 10,
      alignSelf: 'center',
      marginTop: 15,
      marginBottom: isNativeOrPWA ? 0 : '5%'
    },
    buttonInner: {
      paddingHorizontal: 10,
      marginBottom: isNativeOrPWA ? 1 : 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'auto'
    },
    buttonText: {
      fontWeight: '700',
      fontSize: isNativeOrPWA ? adjust(16) : 22,
      color: '#FFF'
    },
    nextImage: {
      width: isNativeOrPWA ? 14 : 24,
      height: isNativeOrPWA ? 14 : 24
    }
  });

  const sliderStyles = StyleSheet.create({
    parentContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: isNativeOrPWA ? 28 : 64,
      width: '94%'
    },
    slideStyle: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center'
    }
  });

  const navigateToDeckEditor = () => navigate('DeckEditor', { isNewDeck: true });

  const fetchRules = async (): Promise<Rule[]> => {
    try {
      const rulesService = new RulesService();
      const rules = await rulesService.getRules();
      return rules;
    } catch (err) {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando las reglas`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
      return [];
    }
  };

  const goToGame = async () => {
    if (!selectedDeck) return;
    try {
      store.dispatch(setLoadingMissionBackground(backgroundDeckSelector));
      const randomDeckOrder = await deckService.getDeckFreeGame(selectedDeck.id, player!.id!);
      const { id } = await campaignService.getFreegame();
      const missionPromise = campaignService.getMissionDetails(id);
      const playerDeck = randomDeckOrder.map(({ card }) => card).flatMap(card => boardCards.formatCard({ card, quantity: 1 }));

      const rulesPromise = fetchRules();
      const [rules, missionDetails] = await Promise.all([rulesPromise, missionPromise]);

      missionDetails.opponent.deck = missionDetails.opponent.deck
        .map(({ card }: { card: Card; order: number }) => card)
        .flatMap((card: Card) => boardCards.formatCard({ card, quantity: 1 }));

      missionDetails.player.deck = playerDeck;
      const gameConfig: GameBoardConfiguration = {
        ...missionDetails,
        dialoguesConfiguration: [],
        reactionsConfiguration: [],
        rules
      };

      store.dispatch(setLoadingMissionBackground(undefined));

      navigate('LaunchBattle', { gameConfig });
    } catch (err) {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]:  No se pudo redirigir al editor de mazos`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
      store.dispatch(setLoadingMissionBackground(undefined));
    }
  };

  const handleChange = (deck: Deck) => setSelectedDeck(deck);

  const decksPreProcessed = useMemo(() => {
    const decks = deckData.map(({ deck }) => deck);
    const decksPaginated = paginate(decks, itemsPerPageSlider - 1);
    const newDecks = decksPaginated
      .map((group): PreProcessDeck[] => {
        const newGroup = group.map((deck): PreProcessDeck => {
          const title = deckSelectorTitlesBg[getUniqueRandom(0, deckSelectorTitlesBg.length - 1, [])];
          return {
            component: 'DeckItem',
            title,
            deck
          };
        });
        const newDeckItem: PreProcessDeck = {
          component: 'NewDeckItem'
        };
        return [newDeckItem, ...newGroup];
      })
      .flat();
    return newDecks;
  }, [deckData]);

  const decksProcessed = useMemo(() => {
    return decksPreProcessed.map((item, index) => {
      const { component, title, deck } = item;

      return component === 'DeckItem' ? (
        <DeckItem
          backgroundTitle={title!}
          handleChange={handleChange}
          deck={deck!}
          selected={selectedDeck?.deckName === deck!.deckName}
          key={deck!.deckName}
        />
      ) : (
        <NewDeckItem navigate={navigateToDeckEditor} key={index} />
      );
    });
  }, [decksPreProcessed, selectedDeck]);

  return (
    <ImageBackground source={backgroundDeckSelector} style={styles.background} resizeMode="stretch">
      <TopHeader withBack />
      <LinearGradient colors={['rgba(0, 0, 0, 0.375)', 'transparent']} style={{ alignItems: 'center' }} />
      <SafeAreaView style={styles.mainContainer}>
        <View style={styles.textWrapper}>
          <Text style={styles.headerText}>Juego Libre</Text>
          <Text style={styles.subtitleText}>Selecciona el mazo con el que quieras jugar</Text>
        </View>
        <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
        <Slides
          initialStep={initialStepSlider}
          parentStyle={sliderStyles.parentContainer}
          style={sliderStyles.slideStyle}
          itemsToRender={itemsPerPageSlider}
        >
          {decksProcessed.map(component => component)}
        </Slides>
      </SafeAreaView>
      <TouchableWithoutFeedback accessible onPress={goToGame} disabled={selectedDeck === null} accessibilityLabel={'Play game'}>
        <View style={[styles.button, { opacity: selectedDeck === null ? 0.4 : 1 }]}>
          <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 30 : 46}>
            <View style={styles.buttonInner}>
              <Text style={[styles.buttonText, { marginRight: 14 }]}>Continuar</Text>
              <Image source={howBottom} resizeMode="contain" style={[styles.nextImage, { transform: [{ rotate: '-90deg' }] }]} />
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
      <LegalFooter />
    </ImageBackground>
  );
};

export default DeckSelector;
