import { useState } from 'react';
import { Card } from '../commons/dtos/card.dto';

export const useCardModal = () => {
  const [detailCard, setDetailCard] = useState<Card | null>(null);
  const [detailCardVisible, setDetailCardVisible] = useState<boolean>(false);

  const handleVisibilityDetailCard = {
    show: () => setDetailCardVisible(true),
    hide: () => setDetailCardVisible(false)
  };

  const handleSelectCardModal = (card: Card) => {
    setDetailCard(card);
    handleVisibilityDetailCard.show();
  };

  return {
    detailCard,
    handleSelectCardModal,
    handleVisibilityDetailCard,
    detailCardVisible
  };
};
