/* eslint-disable react/jsx-key */
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { View, Text, Pressable, StyleSheet, Linking } from 'react-native';

import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import GradientContent from '../../commons/components/GradientContent/GradientContent';
import { useLeagalPrivacyStyles } from '../../commons/components/LeagalPrivacy/useLeagalPrivacyStyles';

import { isNativeOrPWA } from '../../commons/utils';
import { RootStackParamList } from '../../types';
import CookiesTable from './CookiesTable';

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Cookies', undefined>;

const CookiesText = () => {
  const navigation = useNavigation<Navigation>();
  const styles = useLeagalPrivacyStyles();
  const tableStyles = StyleSheet.create({
    text: {
      color: '#fff'
    }
  });
  const colorsScreen = ['#313131', 'transparent', 'transparent', '#313131'];

  const openPrivacyURL = async () => {
    navigation.navigate('PrivacyPolicy');
  };

  const technicalData = {
    tableHead: ['Nombre', 'Titular', 'Finalidad', 'Duración'],
    tableData: [
      [
        <Pressable
          onPress={async () => {
            await Linking.openURL('https://fakesover.com');
          }}
        >
          <Text style={[tableStyles.text, styles.url]}>https://fakesover.com</Text>
        </Pressable>,
        <Text style={tableStyles.text}>Newtral</Text>,
        <Text style={tableStyles.text}>
          Identificar a los usuarios y mantener el progreso en el juego cuándo se participa como invitado
        </Text>,

        <Text style={tableStyles.text}>(…)</Text>
      ]
    ]
  };

  const analyticslData = {
    tableHead: ['Nombre', 'Titular', 'Finalidad', 'Duración'],
    tableData: [
      [
        <Text style={[tableStyles.text, styles.boldText]}>_ga</Text>,
        <Pressable
          onPress={async () => {
            await Linking.openURL('https://policies.google.com/privacy?hl=es');
          }}
        >
          <Text style={[tableStyles.text, styles.url]}>Google</Text>
        </Pressable>,
        <Text style={tableStyles.text}>Identificar a los usuarios</Text>,
        <Text style={tableStyles.text}>2 años</Text>
      ],
      [
        <Text style={[tableStyles.text, styles.boldText]}>_ga_XZSCSLS9ER</Text>,
        <Pressable
          onPress={async () => {
            await Linking.openURL('https://policies.google.com/privacy?hl=es');
          }}
        >
          <Text style={[tableStyles.text, styles.url]}>Google</Text>
        </Pressable>,
        <Text style={tableStyles.text}>(…)</Text>,
        <Text style={tableStyles.text}>(…)</Text>
      ]
    ]
  };

  return (
    <GradientContent
      colors={colorsScreen}
      parentStyle={[styles.mainContent, styles.relative, styles.center]}
      scrollViewStyle={styles.textContainer}
      locations={[0, 0.2, 0.8, 1]}
    >
      <>
        <View style={[styles.mainContent, styles.paddingStartText]}>
          <View style={styles.button} pointerEvents="none">
            <ButtonShape bgColor="black" heightBtn={isNativeOrPWA ? 34 : 50}>
              <Text style={styles.buttonColor}>POLÍTICA DE COOKIES</Text>
            </ButtonShape>
          </View>
        </View>
        <View>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>Esta Política de Cookies es parte integrante de la</Text>
            <Pressable onPress={openPrivacyURL}>
              <Text style={styles.url}> Política de Privacidad </Text>
            </Pressable>
            <Text>de la página web</Text>
            <Pressable
              onPress={async () => {
                await Linking.openURL('https://fakesover.com');
              }}
            >
              <Text style={[tableStyles.text, styles.url]}>https://fakesover.com</Text>
            </Pressable>
            <Text> (en adelante, el “</Text>
            <Text style={styles.boldText}>Sitio Web</Text>
            <Text>”).</Text>
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>Con el fin de facilitar la navegación por el Sitio web, Newtral Media Audiovisual, S.L.U. (en adelante, </Text>
            <Text style={styles.boldText}>“NEWTRAL”</Text>
            <Text>
              ), como titular del mismo, con NIF B-87938304 y domicilio social en Calle Vandergoten 1, 28014 – Madrid, comunica que utiliza
              cookies u otros dispositivos de almacenamiento y recuperación de datos de funcionalidad similares (en adelante, las
            </Text>
            <Text style={styles.boldText}> “Cookies”</Text>
            <Text>).</Text>
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            En este sentido y con el objetivo de garantizar toda la información necesaria para la correcta navegación, ponemos a disposición
            de los usuarios el siguiente texto informativo sobre qué son las cookies, qué tipología de cookies se utilizan en nuestro Sitio
            web y cómo es posible configurarlas o deshabilitarlas.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>1. ¿QUÉ ES UNA COOKIE?</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Las Cookies son archivos que contienen pequeñas cantidades de información que se descargan en el dispositivo del usuario cuando
            visita una página web.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Las Cookies son esenciales para el funcionamiento de internet, ya que ofrecen soluciones técnicas que permiten su navegación; no
            pueden dañar su equipo/dispositivo y, si se encuentran activadas en la configuración de su navegador, ayudan a identificar y
            resolver posibles errores de funcionamiento del Sitio web. También pueden ser utilizadas con finalidades publicitarias o
            analíticas.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>2. USO DE COOKIES POR PARTE DE NEWTRAL</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Mediante el acceso al Sitio Web, los usuarios pueden aceptar de forma expresa la utilización de este tipo de Cookies en sus
            dispositivos. Si desactivan las Cookies, puede que su navegación por el Sitio web no sea óptima y algunas de las utilidades de
            que dispone el Sitio web no funcionen correctamente.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Concretamente, NEWTRAL utiliza cookies propias y cookies pertenecientes a terceras empresas para las finalidades concretas que a
            continuación se exponen. Si en un futuro NEWTRAL utilizase otras con el propósito de otorgar más y mejores servicios, se
            informará de ello a los usuarios.
          </Text>
          <Text style={[styles.paragraph, styles.text, styles.title2]}>(i) Cookies técnicas</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Son aquéllas que permiten al usuario navegar a través de una página web, plataforma o aplicación y la utilización de las
            diferentes opciones o servicios que en ella existan.
          </Text>
          <CookiesTable title="COOKIES TÉCINAS" data={technicalData} />
          <Text style={[styles.text, styles.paragraph]}>
            Estas cookies no requieren la aceptación por parte del usuario ya que son necesarias para el funcionamiento del Sitio Web.
          </Text>
          <Text style={[styles.paragraph, styles.text, styles.title2]}>(ii) Cookies de personalización</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Son aquéllas que permiten recordar información para que el usuario acceda al servicio con determinadas características que
            pueden diferenciar su experiencia de la de otros usuarios.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>Actualmente NEWTRAL no utiliza este tipo de cookies.</Text>
          <Text style={[styles.paragraph, styles.text, styles.title2]}>(iii) Cookies Analíticas</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Estas cookies recopilan información sobre el modo en que los usuarios utilizan el Sitio web. La función de estas cookies es
            obtener estadísticas e informes que permiten mejorar el funcionamiento del Sitio web.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Entre las cookies analíticas utilizadas se encuentran las de Google Analytics con el objetivo de mejorar el funcionamiento del
            Sitio web. Puede encontrar más información sobre las cookies de Google Analytics aquí:
          </Text>
          <Pressable
            onPress={async () => {
              await Linking.openURL('https://developers.google.com/analytics/resources/concepts/gaConceptsCookies');
            }}
          >
            <Text style={[tableStyles.text, styles.url]}>https://developers.google.com/analytics/resources/concepts/gaConceptsCookies</Text>
          </Pressable>
          <Text style={[styles.text, styles.paragraph]}>
            Asimismo, puede evitar la utilización de Google Analytics en relación con su uso en nuestro Sitio Web al descargar e instalar el
            complemento de navegador disponible a través de este enlace:
          </Text>
          <Pressable
            onPress={async () => {
              await Linking.openURL('http://tools.google.com/dlpage/gaoptout?hl=enGB');
            }}
          >
            <Text style={[tableStyles.text, styles.url]}>http://tools.google.com/dlpage/gaoptout?hl=enGB</Text>
          </Pressable>
          <Text style={[styles.text, styles.paragraph]}>Las cookies analíticas se enumeran a continuación:</Text>
          <CookiesTable title="COOKIES ANALÍTICAS" data={analyticslData} />
          <Text style={[styles.text, styles.paragraph]}>
            Estas cookies solo se instalan en el dispositivo del usuario cuando éste las acepta. Puede obtener información adicional en el
            link del titular de las Cookies Analíticas.
          </Text>
          <Text style={[styles.paragraph, styles.text, styles.title2]}>(iv) Cookies de publicidad</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Estas cookies se utilizan para mostrar anuncios que sean relevantes para el usuario. También se utilizan para limitar el número
            de veces que el usuario ve un anuncio, así como para ayudar a medir la eficacia de las campañas publicitarias. Recuerdan que has
            visitado un sitio web y esta información se comparte con otras organizaciones como los anunciantes.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>NEWTRAL informa que no utiliza esta tipología de cookies.</Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>3. PLAZOS DE CONSERVACIÓN</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Las cookies utilizadas por NEWTRAL serán conservadas, de acuerdo con sus opciones de consentimiento, en el dispositivo del
            usuario durante el tiempo necesario para la finalidad por la que fueron instaladas, y como máximo, durante 2 años, salvo que
            usted decida eliminarlas a través de las opciones de configuración de su navegador.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            No obstante, a lo largo de la presente política se muestra el plazo concreto de duración de cada cookie.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>4. TRANSFERENCAS INTERNACIONALES</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Algunos de los datos personales obtenidos a través de cookies serán objeto de transferencias internacionales, en cuyo caso le
            garantizamos que se adoptarán las garantías necesarias para proteger la confidencialidad, integridad y disponibilidad de la
            misma. Para obtener más información sobre las transferencias internacionales realizada por cada tercero, acceda a sus políticas
            de privacidad mostradas en la presente política de cookies.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>5. CÓMO RECHAZAR O BLOQUEAR COOKIES</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Puede cambiar sus preferencias en relación con las cookies analíticas rechazando las mismas en el banner habilitado. Asimismo,
            puede bloquear las Cookies a través de las herramientas de configuración del navegador, o bien, puede configurar su navegador
            para que le avise cuando un servidor quiera guardar una Cookie:
          </Text>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>a) </Text>
              <Text>
                Si utiliza Microsoft Internet Explorer, encontrará la opción en el menú Herramientas{' > '}Opciones de Internet{' > '}
                Privacidad{' > '}Configuración. Para saber más
              </Text>
              <Pressable
                onPress={async () => {
                  await Linking.openURL('http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies');
                }}
              >
                <Text style={[tableStyles.text, styles.url]}> http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</Text>
              </Pressable>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>b) </Text>
              <Text>
                Si utiliza Firefox, encontrará la opción en el menú Herramientas{' > '}Opciones{' > '}Privacidad{' > '}Cookies. Para saber
                más:
              </Text>
              <Pressable
                onPress={async () => {
                  await Linking.openURL('http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we');
                }}
              >
                <Text style={[tableStyles.text, styles.url]}>
                  {' '}
                  http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we
                </Text>
              </Pressable>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>c) </Text>
              <Text>
                Si utiliza Chrome, en la sección de Opciones{' > '}Opciones avanzadas{' > '}Privacidad. Para saber más:
              </Text>
              <Pressable
                onPress={async () => {
                  await Linking.openURL('https://support.google.com/accounts/answer/61416?hl=es');
                }}
              >
                <Text style={[tableStyles.text, styles.url]}> https://support.google.com/accounts/answer/61416?hl=es</Text>
              </Pressable>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>d) </Text>
              <Text>Si utiliza Opera, en la opción de Seguridad y Privacidad, podrá configurar el navegador. Para saber más:</Text>
              <Pressable
                onPress={async () => {
                  await Linking.openURL('http://help.opera.com/Windows/11.50/es-ES/cookies.html');
                }}
              >
                <Text style={[tableStyles.text, styles.url]}> http://help.opera.com/Windows/11.50/es-ES/cookies.html</Text>
              </Pressable>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>e) </Text>
              <Text>Si utiliza Safari encontrará la opción en el menú Preferencias/Privacidad. Más información en:</Text>
              <Pressable
                onPress={async () => {
                  await Linking.openURL('http://support.apple.com/kb/HT1677?viewlocale=es_ES');
                }}
              >
                <Text style={[tableStyles.text, styles.url]}> http://support.apple.com/kb/HT1677?viewlocale=es_ES</Text>
              </Pressable>
            </Text>
          </View>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>6. DERECHOS DE LOS USUARIOS E INFORMACIÓN ADICIONAL</Text>
          <Text style={[styles.text, styles.paragraph]}>
            <Text>
              Puede obtener más información sobre el tratamiento de sus datos realizado por NEWTRAL, así como sobre el modo de ejercitar sus
              derechos en nuestra
            </Text>
            <Pressable onPress={openPrivacyURL}>
              <Text style={styles.url}> Política de Privacidad </Text>
            </Pressable>
            <Text>También puede contactar con NEWTRAL en el email: </Text>
            <Text style={styles.url}>info@newtral.es </Text>
          </Text>
        </View>
        <View style={[styles.marginTop, styles.paddingEndText]}>
          <Text style={[styles.text, styles.paragraph, styles.boldText]}>Última actualización: 1 de marzo de 2023.</Text>
          <Text style={[styles.text, styles.paragraph, styles.boldText]}>
            © 2023 NEWTRAL MEDIA AUDIOVISUAL, S.L.U., Todos los derechos reservados.
          </Text>
        </View>
      </>
    </GradientContent>
  );
};

export default CookiesText;
