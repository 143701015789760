import { ActorTweetEnum } from '../../../commons/dtos/actor.dto';
import { ReactionEventEnum } from '../../../commons/dtos/reaction-configuration.dto';
import { TweetsReactionConfiguration } from '../../../commons/dtos/tweet-reaction-configuration.dto';

export const reactionTweetsConfiguration: TweetsReactionConfiguration[] = [
  {
    trigger: {
      event: ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
      lifePointsThreshold: 25,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.PLAYER
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
      lifePointsThreshold: 20,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.PLAYER
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
      lifePointsThreshold: 15,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.PLAYER
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
      lifePointsThreshold: 10,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.PLAYER
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
      lifePointsThreshold: 5,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.PLAYER
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
      lifePointsThreshold: 25,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.OPPONENT
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
      lifePointsThreshold: 20,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.OPPONENT
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
      lifePointsThreshold: 15,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.OPPONENT
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
      lifePointsThreshold: 10,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.OPPONENT
      }
    ]
  },
  {
    trigger: {
      event: ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
      lifePointsThreshold: 5,
      done: false
    },
    reactions: [
      {
        side: ActorTweetEnum.OPPONENT
      }
    ]
  }
];
