import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useState } from 'react';
import { StyleSheet, View, Image, TouchableWithoutFeedback, Text } from 'react-native';
import useUnits from 'rxn-units';
import { GameLogo, goBackIconWhite } from '../../constants/Images';
import { RootStackParamList } from '../../types';
import adjust from '../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../utils';
import ButtonShape from './ButtonShape/ButtonShape';
import OptionsMenuModal from './OptionsMenuModal/OptionsMenuModal';
import PlayerExperience from './PlayerExperience';
import UserMenuExtended from './UserMenuExtended';

interface TopHeaderProps {
  withBack?: boolean;
}
type Navigation = NativeStackNavigationProp<RootStackParamList, 'GameBoardInitializer', undefined>;

const TopHeader = ({ withBack = false }: TopHeaderProps) => {
  const { vh } = useUnits();
  const navigation = useNavigation<Navigation>();
  const [showConfig, setShowConfig] = useState<boolean>();

  const styles = StyleSheet.create({
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      height: '20%',
      width: isNativeMobile ? 'auto' : '100%',
      maxHeight: 120,
      paddingVertical: vh(2),
      paddingHorizontal: isNativeMobile ? 30 : 10,
      zIndex: 9,
      position: 'relative'
    },
    backContainer: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      cursor: 'pointer',
      paddingHorizontal: 20,
      paddingTop: 5,
      maxWidth: isNativeOrPWA ? 120 : 160
    },
    goBackButton: {
      height: 'auto',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: isNativeOrPWA ? 2 : 6
    },
    goBackButtonIcon: {
      width: isNativeOrPWA ? 11 : 14,
      height: isNativeOrPWA ? 11 : 14,
      paddingBottom: isNativeOrPWA ? 2 : 6
    },
    goBackButtonText: {
      marginLeft: isNativeOrPWA ? 4 : 8,
      fontWeight: '700',
      fontSize: isNativeOrPWA ? adjust(15) : 16,
      color: '#fff'
    },
    gameLogoContainer: {
      flexGrow: 0,
      flexShrink: 0,
      width: '25%',
      maxWidth: isNativeMobile ? 180 : 260,
      cursor: 'pointer'
    },
    gameLogo: {
      width: isNativeMobile ? '100%' : 'auto',
      height: '100%'
    },
    items: {
      justifyContent: 'flex-end',
      flexDirection: 'row',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? 'auto' : '80%'
    },
    menu: {
      minWidth: 100
    }
  });
  const back = (
    <View style={styles.backContainer}>
      <TouchableWithoutFeedback onPress={() => navigation.navigate('Home')}>
        <View style={styles.goBackButton}>
          <ButtonShape bgColor="black" heightBtn={isNativeOrPWA ? 34 : 56} border isPointeable>
            <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10 }}>
              <Image style={styles.goBackButtonIcon} source={goBackIconWhite} resizeMode="contain" />
              <Text style={styles.goBackButtonText}>Inicio</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );

  const logo = (
    <View style={styles.gameLogoContainer}>
      <TouchableWithoutFeedback onPress={() => navigation.navigate('Home')}>
        <Image resizeMode="contain" style={styles.gameLogo} source={GameLogo} />
      </TouchableWithoutFeedback>
    </View>
  );

  return (
    <>
      <View style={styles.topContainer}>
        {withBack ? back : logo}
        <View style={styles.items}>
          <TouchableWithoutFeedback onPress={() => navigation.navigate('UserProfile')}>
            <View>
              <PlayerExperience />
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.menu}>
            <UserMenuExtended openModal={() => setShowConfig(true)} />
          </View>
        </View>
      </View>
      {showConfig && <OptionsMenuModal closeModal={() => setShowConfig(false)} />}
    </>
  );
};

export default TopHeader;
