import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { ImageBackground, StyleSheet, Animated, ViewStyle, Platform } from 'react-native';
import useUnits from 'rxn-units';
import {
  redDialogBubbleBottom,
  redDialogBubbleMiddle,
  redDialogBubbleTopLeft,
  redDialogBubbleTopRight,
  blueDialogBubbleBottom,
  blueDialogBubbleMiddle,
  blueDialogBubbleTopLeft,
  blueDialogBubbleTopRight
} from '../../../constants/Images';
import { SideOfScreen } from '../../../types';
import { isNativeOrPWA } from '../../utils';

export type BubbleColor = 'blue' | 'red';

interface DialogueProps {
  width: number;
  children: ReactNode;
  side: SideOfScreen;
  color: BubbleColor;
  style?: ViewStyle | ViewStyle[] | Animated.WithAnimatedObject<ViewStyle> | Animated.WithAnimatedArray<ViewStyle>;
}

const Dialogue = ({ width, children, side, color, style }: DialogueProps) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const position = useRef(new Animated.Value(0)).current;

  const { vh, vw } = useUnits();
  const [renderText, setRenderText] = useState<boolean>(false);

  useLayoutEffect(() => {
    Animated.timing(position, {
      toValue: 1,
      duration: 300,
      delay: 100,
      useNativeDriver: Platform.OS !== 'web'
    }).start(() => {
      setRenderText(true);
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: Platform.OS !== 'web'
      }).start();
    });
  }, []);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      height: '100%',
      paddingTop: isNativeOrPWA ? vh(40) : 20,
      justifyContent: isNativeOrPWA ? 'flex-start' : 'center'
    },
    side: {
      flexGrow: 0,
      width,
      maxWidth: vw(70),
      height: isNativeOrPWA ? 35 : 50
    },
    bottom: {
      height: isNativeOrPWA ? 25 : 40,
      marginTop: -1
    },
    middle: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      minHeight: isNativeOrPWA ? width / 8 : width / 5.625,
      maxWidth: width,
      marginTop: -1,
      paddingBottom: 16
    },
    childrenContainer: {
      width: '100%',
      height: 'auto',
      maxWidth: width - (isNativeOrPWA ? 40 : 80),
      marginHorizontal: 'auto'
    }
  });
  const topImage = () => {
    if (color === 'red') {
      if (side === 'left') {
        return redDialogBubbleTopLeft;
      } else {
        return redDialogBubbleTopRight;
      }
    } else if (color === 'blue') {
      if (side === 'left') {
        return blueDialogBubbleTopLeft;
      } else {
        return blueDialogBubbleTopRight;
      }
    }
  };
  const middleImage = color === 'red' ? redDialogBubbleMiddle : blueDialogBubbleMiddle;
  const bottomImage = color === 'red' ? redDialogBubbleBottom : blueDialogBubbleBottom;

  return (
    <Animated.View
      style={[
        styles.container,
        style,
        {
          opacity: position,
          transform: [
            {
              translateX: position.interpolate({
                inputRange: [0, 1],
                outputRange: side === 'left' ? [0, 40] : [0, -40]
              })
            }
          ]
        }
      ]}
    >
      <ImageBackground source={topImage()} style={styles.side} resizeMode="stretch" />
      <ImageBackground source={middleImage} style={styles.middle} resizeMode="stretch">
        {renderText && <Animated.View style={[styles.childrenContainer, { opacity: fadeAnim }]}>{children}</Animated.View>}
      </ImageBackground>
      <ImageBackground source={bottomImage} style={[styles.side, styles.bottom]} resizeMode="stretch" />
    </Animated.View>
  );
};

export default Dialogue;
