import { CardInventoryIncludesCard } from '../commons/dtos/card-inventory-includes-card.dto';
import { getPaginated } from '../commons/utils/paginate';
import { cards } from '../constants/Images';
import { MAX_CARD_INVENTORY_FETCH_SIZE } from '../constants/Values';

export default class CardInventoriesService {
  getCardInventory = async (playerId: string): Promise<CardInventoryIncludesCard[]> => {
    const inventoryCards = await getPaginated<CardInventoryIncludesCard, { page: number; limit: number; playerId: string }>({
      url: '/card-inventories',
      params: { playerId, page: 1, limit: MAX_CARD_INVENTORY_FETCH_SIZE }
    });

    const inventoryCardsWithImages = inventoryCards.flat().map(inventory => {
      const { card } = inventory;
      const { illustration } = card;

      inventory.card.imageSource = cards[illustration];
      return inventory;
    });

    return inventoryCardsWithImages;
  };
}
