import LottieView from 'lottie-react-native';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  StyleSheet,
  ImageBackground,
  Animated,
  Image,
  ImageSourcePropType,
  Platform,
  Text,
  Pressable,
  LayoutChangeEvent
} from 'react-native';
import { DraxDragWithReceiverEndEventData, DraxView } from 'react-native-drax';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { getAvatar } from '../../../commons/components/Dialogues/utils';
import PolyShape from '../../../commons/components/PolyShape/PolyShape';
import { View } from '../../../commons/components/Themed';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { ReactionEventEnum } from '../../../commons/dtos/reaction-configuration.dto';
import { isGameActionHighlight } from '../../../commons/events-management/game-actions/gameActions';
import { ReactionManagementTweet, launchTweetReactions } from '../../../commons/events-management/tweets/tweets';
import adjust from '../../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../../commons/utils';
import {
  opponentLifePointsContainer,
  playerAvatarContainer,
  playerLifePointsContainer,
  oppponentAvatarContainer
} from '../../../constants/Images';
import { lottieAvatarCrack } from '../../../constants/Lotties';
import store from '../../../redux/store';
import { ItemInfo } from '../../../types/drag';

interface AvatarInfoProps {
  nativeID: PlayerRoundEnum;
  clearActionsDrop: Function;
  setShowAttackingCardDrag: Function;
  lifePoints: number;
  isLaunchingCardFromHand: boolean;
  isAttackable: boolean;
  isBeingAttacked: boolean;
  handleIsBeingAttacked: (isAttacked: boolean) => void;
  isAttacking: boolean;
  onAttackAvatarWithCard: (attackingCardIndex: number) => void;
  dragCard: string;
  isPlayerRound: boolean;
  lowOpacity?: boolean;
  avatar?: ImageSourcePropType;
  name?: string;
  showAnimationLifePoints: {
    showAnimation: boolean;
    damage: number;
    player: PlayerRoundEnum;
  };
  avatarAttacked: boolean;
  reactionsTweetsManagementProps: (life: number) => ReactionManagementTweet;
  isFreeGame: boolean;
  handleAddItem: (id: string, info: ItemInfo) => void;
  handleShowAvatarModal: () => void;
  opponentHasBarrier: boolean;
  playerHasBarrier: boolean;
}

const animationTextMove: Animated.TimingAnimationConfig = {
  toValue: 1,
  useNativeDriver: Platform.OS !== 'web',
  duration: 400
};
const animationTextReset: Animated.TimingAnimationConfig = {
  toValue: 0,
  useNativeDriver: Platform.OS !== 'web',
  delay: 500,
  duration: 400
};
const animationMinus: Animated.TimingAnimationConfig = {
  toValue: 1,
  useNativeDriver: Platform.OS !== 'web',
  duration: 500
};
const animationMinusText: Animated.TimingAnimationConfig = {
  toValue: 1,
  useNativeDriver: Platform.OS !== 'web',
  duration: 500
};
const animationMinusReset: Animated.TimingAnimationConfig = {
  toValue: 0,
  useNativeDriver: Platform.OS !== 'web',
  duration: 300,
  delay: 750
};

const animationScale: Animated.TimingAnimationConfig = {
  toValue: 1,
  useNativeDriver: Platform.OS !== 'web',
  duration: 300
};
const animationScaleReset: Animated.TimingAnimationConfig = {
  toValue: 0,
  useNativeDriver: Platform.OS !== 'web',
  duration: 100,
  delay: 1600
};

const AvatarInfo = ({
  isPlayerRound,
  dragCard,
  isAttacking,
  onAttackAvatarWithCard,
  handleIsBeingAttacked,
  isBeingAttacked,
  nativeID,
  clearActionsDrop,
  setShowAttackingCardDrag,
  lifePoints,
  isLaunchingCardFromHand,
  isAttackable,
  lowOpacity,
  avatar,
  name,
  showAnimationLifePoints,
  avatarAttacked,
  reactionsTweetsManagementProps,
  isFreeGame,
  handleAddItem,
  handleShowAvatarModal,
  opponentHasBarrier,
  playerHasBarrier
}: AvatarInfoProps) => {
  const { rw } = useRatioDimensions();
  const {
    profile: { player }
  } = store.getState();
  const [showLottieDrop, setShowLottieDrop] = useState<boolean>(false);
  const isOpponent = nativeID === PlayerRoundEnum.OPPONENT;
  const [showName, setShowName] = useState(false);

  const textMove = useRef(new Animated.Value(0)).current;
  const minuxMove = useRef(new Animated.Value(0)).current;
  const minuxTextMove = useRef(new Animated.Value(0)).current;
  const scale = useRef(new Animated.Value(0)).current;
  const { damage, player: playerSide, showAnimation: animation } = showAnimationLifePoints;

  const textExperienceAnimation = Animated.sequence([Animated.timing(textMove, animationTextMove)]);
  const minusAnimation = Animated.sequence([
    Animated.timing(minuxMove, animationMinus),
    Animated.timing(minuxTextMove, animationMinusText)
  ]);

  const stopMinusAnimation = Animated.parallel([
    Animated.timing(textMove, animationTextReset),
    Animated.timing(minuxMove, animationMinusReset),
    Animated.timing(minuxTextMove, animationMinusReset)
  ]);

  const animationSequence = Animated.sequence([textExperienceAnimation, minusAnimation, stopMinusAnimation]);

  // TODO nativeID dep
  useEffect(() => {
    if (!(animation && playerSide === nativeID)) return;
    animationSequence.start();
  }, [showAnimationLifePoints]);

  const styles = StyleSheet.create({
    avatarContainer: {
      position: 'relative'
    },
    lifePointsContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      textAlign: 'center'
    },
    lifePointsHighlightOpponent: {
      borderWidth: 4,
      borderRadius: 40,
      borderColor: '#25bed4',
      backgroundColor: '#25bed4',
      filter: 'drop-shadow(0px 10px 10px rgba(37, 190, 212, 1))'
    },
    lifePointsHighlightPlayer: {
      borderWidth: 4,
      borderRadius: 40,
      borderColor: '#E9AB04',
      backgroundColor: '#E9AB04',
      filter: 'drop-shadow(0px 10px 10px rgba(233, 171, 4, 1))'
    },
    lifePointsContainerBackground: { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' },
    lifePoints: { textAlign: 'center', zIndex: 200, fontFamily: 'Inlanders', color: '#FFFFFF' },
    attackableAvatar: {
      borderColor: '#ff00007d',
      borderWidth: 2,
      shadowColor: '#FF0000',
      borderRadius: 500,
      shadowOffset: {
        width: 10,
        height: 10
      },
      shadowOpacity: 0.99,
      shadowRadius: 30.0,
      elevation: 24
    },
    lottieEffect: {
      position: 'absolute',
      top: '-8%',
      left: '-10%',
      width: '120%',
      height: '120%',
      backgroundColor: 'transparent',
      elevation: 25
    },
    playerCrack: {
      transform: [{ rotate: '47deg' }]
    },
    avatarContainerBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
    },
    diffPositionPlayer: {
      bottom: -120
    },
    diffPositionOponent: {
      top: -120
    },
    avatar: {
      bottom: isNativeOrPWA ? 2 : 6,
      right: rw(0.3),
      left: rw(0.3),
      width: '95%',
      height: '95%',
      position: 'absolute'
    },
    animationText: {
      position: 'absolute',
      fontSize: isNativeOrPWA ? 24 : 42,
      color: damage >= 0 ? '#FFF' : '#CD2D36',
      fontWeight: 'bold'
    },
    animationTextMinus: {
      left: rw(8)
    },
    animationTextMinusDamage: {
      left: rw(8) + 20
    },
    nickname: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      flexWrap: 'wrap',
      maxWidth: '100%',
      minWidth: isNativeOrPWA ? 120 : 'auto',
      minHeight: 36,
      maxHeight: isNativeOrPWA ? 34 : 58,
      marginTop: isNativeOrPWA ? 4 : 12,
      transform: [
        {
          translateX: isNativeOrPWA ? -30 : 0
        }
      ],
      alignItems: 'center',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    nicknameTextMini: {
      fontSize: isNativeOrPWA ? adjust(12) : 16
    },
    nicknameText: {
      fontSize: isNativeOrPWA ? adjust(14) : 20,
      color: '#FFF',
      fontFamily: 'Inlanders',
      textAlign: 'center'
    },
    polyContainer: {
      height: '100%',
      width: '100%'
    }
  });

  const avatarImage = useMemo(() => {
    return isOpponent ? avatar! : getAvatar(player!.avatar!.avatarImage, player!.gender!, isFreeGame);
  }, [isOpponent, player]);

  const nickname = !isOpponent ? player?.playerName : name;

  const isAttackableAvatar = (!isLaunchingCardFromHand && !isAttacking) || (!isLaunchingCardFromHand && isAttackable);

  const handleDragEnter = (): void => {
    if (isAttackableAvatar && isAttackable) {
      handleIsBeingAttacked(true);
    }
  };
  const handleDragExit = ({ cancelled }: DraxDragWithReceiverEndEventData): void => {
    if (isAttackableAvatar && isAttackable) {
      handleIsBeingAttacked(false);
      if (cancelled && isAttackable) {
        clearActionsDrop();
      }
    }
  };
  const handleDragDrop = () => {
    if (isAttackableAvatar && isAttackable) {
      handleIsBeingAttacked(false);
      const isOpponentAttacked = isAttackable && isOpponent && isPlayerRound;

      setTimeout(
        async () => {
          const [, , , attackingCardIndex] = dragCard.split('_');
          await onAttackAvatarWithCard(+attackingCardIndex);
          setShowAttackingCardDrag(false);
        },
        isOpponentAttacked ? 250 : 500
      );
    } else if (isAttackableAvatar && ((isPlayerRound && opponentHasBarrier) || (!isPlayerRound && playerHasBarrier))) {
      handleShowAvatarModal();
    }
  };

  const diffPoints = Math.abs(damage);
  const symbolPoints = damage > 0 ? '+' : '-';

  useEffect(() => {
    if (!avatarAttacked) return;
    if (isPlayerRound && nativeID === PlayerRoundEnum.PLAYER) return;
    if (!isPlayerRound && nativeID === PlayerRoundEnum.OPPONENT) return;

    const animation = Animated.timing(scale, animationScale);
    const reset = Animated.timing(scale, animationScaleReset);

    const sequence = Animated.sequence([animation, reset]);
    sequence.start(() => setShowLottieDrop(true));
  }, [avatarAttacked, isPlayerRound, nativeID]);

  // TODO bad deps
  useEffect(() => {
    launchTweetReactions(
      reactionsTweetsManagementProps(lifePoints),
      nativeID === PlayerRoundEnum.PLAYER ? [ReactionEventEnum.PLAYER_AVATAR_DAMAGED] : [ReactionEventEnum.OPPONENT_AVATAR_DAMAGED]
    );
  }, [lifePoints]);

  const handleLayout = (event: LayoutChangeEvent) => {
    const { nativeEvent } = event;
    const { layout } = nativeEvent;
    const { width, height } = layout;
    const side = isOpponent ? 'avatar-opponent' : 'avatar-player';
    // @ts-ignore
    handleAddItem(side, { height, width, x: layout.left!, y: layout.top, id: side });
  };

  return (
    <Pressable onPress={() => setShowName(!showName)}>
      <Animated.View
        style={[
          styles.avatarContainer,
          !isLaunchingCardFromHand && isBeingAttacked && isAttackable ? styles.attackableAvatar : {},
          {
            width: rw(7),
            height: rw(7),
            opacity: lowOpacity || !isAttackableAvatar ? 0.6 : 1,
            transform: [{ scale: scale.interpolate({ inputRange: [0, 1], outputRange: [1, 1.4] }) }]
          }
        ]}
      >
        <ImageBackground
          style={styles.avatarContainerBackground}
          resizeMode={'contain'}
          source={isOpponent ? oppponentAvatarContainer : playerAvatarContainer}
        />

        <Animated.Text
          style={[
            styles.animationText,
            styles.animationTextMinus,
            {
              opacity: minuxMove,
              transform: [
                { translateY: minuxMove.interpolate({ inputRange: [0, 1], outputRange: isOpponent ? [0, 110] : [0, -110 + -rw(7)] }) }
              ]
            },
            isOpponent ? styles.diffPositionOponent : styles.diffPositionPlayer
          ]}
        >
          {symbolPoints}
        </Animated.Text>
        <Animated.Text
          style={[
            styles.animationText,
            styles.animationTextMinusDamage,
            {
              opacity: minuxMove,
              transform: [
                {
                  translateY: minuxTextMove.interpolate({ inputRange: [0, 1], outputRange: isOpponent ? [0, 110] : [0, -110 + -rw(7)] })
                }
              ]
            },
            isOpponent ? styles.diffPositionOponent : styles.diffPositionPlayer
          ]}
        >
          {diffPoints}
        </Animated.Text>

        <Image style={styles.avatar} source={avatarImage} />
        <DraxView
          nativeID={nativeID}
          style={[
            styles.avatarContainer,
            isBeingAttacked && isAttackable ? styles.attackableAvatar : {},
            { width: rw(7), height: rw(7) },
            { opacity: lowOpacity || !isAttackableAvatar ? 0.6 : 1 }
          ]}
          onReceiveDragEnter={handleDragEnter}
          onReceiveDragExit={handleDragExit}
          onReceiveDragDrop={handleDragDrop}
        >
          {showLottieDrop && (
            <LottieView
              autoPlay
              style={[styles.lottieEffect, isPlayerRound ? styles.playerCrack : {}]}
              source={lottieAvatarCrack}
              onAnimationFinish={() => setShowLottieDrop(false)}
            />
          )}
          <View
            onLayout={handleLayout}
            style={[
              styles.lifePointsContainer,
              {
                width: rw(3),
                height: rw(3.5),
                right: -rw(0.5),
                bottom: -rw(0.75)
              },
              isOpponent && isGameActionHighlight('opponent-lifebar') ? styles.lifePointsHighlightOpponent : {},
              !isOpponent && isGameActionHighlight('player-lifebar') ? styles.lifePointsHighlightPlayer : {}
            ]}
          >
            <ImageBackground
              style={styles.lifePointsContainerBackground}
              resizeMode={'contain'}
              source={isOpponent ? opponentLifePointsContainer : playerLifePointsContainer}
            />
            <Animated.Text
              style={[
                styles.lifePoints,
                {
                  fontSize: rw(1.4),
                  opacity: textMove.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0]
                  }),
                  transform: [
                    {
                      translateY: textMove.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 10]
                      })
                    }
                  ]
                }
              ]}
            >
              {lifePoints}
            </Animated.Text>
          </View>
        </DraxView>
        {showName && (
          <View pointerEvents={!showName ? 'none' : 'auto'} style={styles.nickname}>
            <View style={styles.polyContainer}>
              <PolyShape type={3} bgColor={'#131313'}>
                <Text style={[styles.nicknameText, nickname!.length > 13 && styles.nicknameTextMini]}>{nickname?.toUpperCase()}</Text>
              </PolyShape>
            </View>
          </View>
        )}
      </Animated.View>
    </Pressable>
  );
};
export default AvatarInfo;
