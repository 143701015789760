import { ImageBackground, StyleSheet, View, Text, ImageSourcePropType, Platform, TextStyle, Animated } from 'react-native';
import { useSelector } from 'react-redux';
import { actors } from '../../../assets/data/actors';
import { avatarImage, twitterCardImages } from '../../../constants/Images';
import { useBold } from '../../../hooks/useBold';
import { useHighlight } from '../../../hooks/useHighlight';
import { useSplitText } from '../../../hooks/useSplitText';
import { Profile } from '../../../redux/Profile/profileReducer';
import { Tweet } from '../../dtos/tweet.dto';
import { isNativeOrPWA, randomIntFromInterval } from '../../utils';
import { getAvatar } from '../Dialogues/utils';

interface TwitterInfo {
  avatar: ImageSourcePropType;
  name: string;
  username: string;
}

interface TwitterCardProps {
  text: string;
  actorKey: string;
  tweet: Tweet;
  isPlayer: boolean;
  animated?: boolean;
}

const TwitterCard = ({ text, actorKey, isPlayer, tweet, animated = false }: TwitterCardProps) => {
  const { image, likes, responses, retweets, user } = tweet;

  const likesAnimation = useHighlight({ animated, item: likes });
  const responsesAnimation = useHighlight({ animated, item: responses });
  const retweetAnimation = useHighlight({ animated, item: retweets });

  const boldText = useBold(text, animated);
  const boldLikes = useBold(likes, animated);
  const boldResponses = useBold(responses, animated);
  const boldRetweets = useBold(retweets, animated);

  const { prevText, newText } = useSplitText(text, animated);

  const player = useSelector(({ profile }: { profile: Profile }) => profile.player);

  const getTwitterInfo = (): TwitterInfo => {
    if (isPlayer && player) {
      const { avatar, playerName } = player;
      return {
        avatar: getAvatar(avatar!.avatarImage),
        name: playerName,
        username: playerName
      };
    }
    const actor = actors.find(actor => actor.key === actorKey) || actors[0];
    const { key, nickname, twitter } = actor;
    return {
      avatar: avatarImage[key],
      name: nickname,
      username: twitter
    };
  };

  const { avatar, name, username } = getTwitterInfo();
  const { icons } = twitterCardImages;
  const { chat, like, retweet, chatUpgraded, likeUpgraded, retweetUpgraded } = icons;

  const styles = StyleSheet.create({
    mainContainer: { flexDirection: 'row', justifyContent: 'center', width: '100%' },
    avatarImage: {
      width: isNativeOrPWA ? 28 : 40,
      height: isNativeOrPWA ? 28 : 40
    },
    userInfoContainer: {
      width: '100%',
      flexDirection: 'row',
      height: 'auto',
      overflow: 'hidden',
      alignItems: 'flex-start'
    },
    username: {
      color: '#626262',
      fontWeight: '400',
      fontSize: isNativeOrPWA ? 11 : 14,
      marginLeft: isNativeOrPWA ? 8 : 12,
      maxWidth: '35%'
    },
    name: {
      color: '#000',
      fontWeight: '900',
      fontSize: isNativeOrPWA ? 11 : 14,
      marginLeft: isNativeOrPWA ? 8 : 12,
      maxWidth: '35%'
    },
    time: {
      color: '#626262',
      fontWeight: '400',
      fontSize: isNativeOrPWA ? 11 : 14,
      marginLeft: isNativeOrPWA ? 8 : 12
    },
    contentWrapper: {
      width: '100%',
      flex: 1,
      marginTop: isNativeOrPWA ? 4 : 6,
      marginLeft: 4
    },
    messageText: {
      color: '#626262',
      fontSize: isNativeOrPWA ? 9 : 13,
      width: '100%'
    },
    actionsContainer: {
      flexDirection: 'row',
      marginTop: isNativeOrPWA ? 4 : 16,
      width: '100%',
      justifyContent: 'space-around'
    },
    actionItem: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: isNativeOrPWA ? 4 : 12
    },
    actionImage: {
      width: isNativeOrPWA ? 12 : 16,
      height: isNativeOrPWA ? 12 : 16,
      marginRight: isNativeOrPWA ? 3 : 8
    },
    actionText: {
      color: '#536578',
      fontSize: isNativeOrPWA ? 9 : 14
    },
    boldStyle: {
      fontWeight: boldText ? '900' : '400',
      color: boldText ? '#000' : '#626262'
    }
  });

  return (
    <View style={styles.mainContainer}>
      <ImageBackground source={image || avatar} style={styles.avatarImage} />
      <View style={{ flex: 1 }}>
        <View style={styles.userInfoContainer}>
          <Text
            style={[
              styles.name,
              Platform.OS === 'web'
                ? ({
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    minWidth: 42,
                    textOverflow: 'ellipsis',
                    maxHeight: 42,
                    flex: 1
                  } as TextStyle)
                : {}
            ]}
            ellipsizeMode="tail"
            numberOfLines={Platform.OS === 'web' ? 1 : 2}
          >
            {user ? user : `${name}`}
          </Text>
          <Text
            style={[
              styles.username,
              Platform.OS === 'web'
                ? ({
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    minWidth: 42,
                    textOverflow: 'ellipsis',
                    maxHeight: 42,
                    flex: 1
                  } as TextStyle)
                : {}
            ]}
            ellipsizeMode="tail"
            numberOfLines={Platform.OS === 'web' ? 1 : 2}
          >
            {user ? user : `${username}`}
          </Text>
          <Text style={styles.time}>{`${randomIntFromInterval(0, 6)}h`}</Text>
        </View>
        <View style={styles.contentWrapper}>
          <Text style={styles.messageText}>
            <Text style={newText === null ? styles.boldStyle : {}}>{prevText}</Text>
            {newText !== null ? <Text style={styles.boldStyle}>{newText}</Text> : null}
          </Text>
          <View style={styles.actionsContainer}>
            <Animated.View style={[styles.actionItem, { transform: [{ scale: responsesAnimation }] }]}>
              <ImageBackground source={boldResponses ? chatUpgraded : chat} style={styles.actionImage} resizeMode="contain" />
              <Text style={[styles.actionText, boldResponses ? styles.boldStyle : {}]}>{responses}</Text>
            </Animated.View>
            <Animated.View style={[styles.actionItem, { transform: [{ scale: retweetAnimation }] }]}>
              <ImageBackground source={boldRetweets ? retweetUpgraded : retweet} style={styles.actionImage} resizeMode="contain" />
              <Text style={[styles.actionText, boldRetweets ? styles.boldStyle : {}]}>{retweets}</Text>
            </Animated.View>
            <Animated.View style={[styles.actionItem, { transform: [{ scale: likesAnimation }] }]}>
              <ImageBackground source={boldLikes ? likeUpgraded : like} style={styles.actionImage} resizeMode="contain" />
              <Text style={[styles.actionText, boldLikes ? styles.boldStyle : {}]}>{likes}</Text>
            </Animated.View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default TwitterCard;
