import { useLayoutEffect, useRef } from 'react';
import { ImageBackground, StyleSheet, Animated, ViewStyle, Platform, Image } from 'react-native';
import { View, Text } from '../../../commons/components/Themed';
import { ActorTweetEnum } from '../../../commons/dtos/actor.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { TweetItem } from '../../../commons/utils/gameboard/tweets';
import { tweetBubbleBottom, tweetBubbleMiddle, tweetBubbleTop, tweetVerified } from '../../../constants/Images';
import { clearTweetReactionBySide } from '../../../redux/GameBoard/tweets';
import store from '../../../redux/store';

interface DialogProps {
  index: number;
  total: number;
  isOpponent: boolean;
  tweet: TweetItem;
  style?: ViewStyle | ViewStyle[] | Animated.WithAnimatedObject<ViewStyle> | Animated.WithAnimatedArray<ViewStyle>;
  nativeID: PlayerRoundEnum;
}

const TweetMsg = ({ style, tweet, index, total, nativeID }: DialogProps) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const position = useRef(new Animated.Value(0)).current;
  const isOpponent = `${nativeID}` !== `${PlayerRoundEnum.PLAYER}`;
  const delay = isOpponent ? index : total - index;

  const startPositonAnimation = Animated.timing(position, {
    toValue: 1,
    duration: 200,
    delay: 200 + delay * 350,
    useNativeDriver: Platform.OS !== 'web'
  });

  const fadeAnimation = Animated.timing(fadeAnim, {
    toValue: 1,
    duration: 100,
    useNativeDriver: Platform.OS !== 'web'
  });

  const endPositionAnimation = Animated.timing(position, {
    toValue: 0,
    duration: 200,
    delay: 3200 + delay * 350,
    useNativeDriver: Platform.OS !== 'web'
  });

  useLayoutEffect(() => {
    Animated.sequence([startPositonAnimation, fadeAnimation, endPositionAnimation]).start(
      () =>
        index === total - 1 &&
        store.dispatch(clearTweetReactionBySide(nativeID === PlayerRoundEnum.PLAYER ? ActorTweetEnum.PLAYER : ActorTweetEnum.OPPONENT))
    );
  }, []);

  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      height: 'auto',
      width: isNativeOrPWA ? 295 : 360,
      top: 5,
      marginTop: 5
    },
    top: {
      flexGrow: 0,
      width: '100%',
      height: isNativeOrPWA ? 10 : 14
    },
    bottom: {
      height: isNativeOrPWA ? 22 : 26,
      marginTop: -1,
      flexGrow: 0,
      width: '100%'
    },
    middle: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      minHeight: 40,
      paddingHorizontal: isNativeOrPWA ? 16 : 30,
      paddingVertical: isNativeOrPWA ? 6 : 10,
      marginTop: -1,
      paddingLeft: 26,
      overflow: 'hidden'
    },
    childrenContainer: {
      width: '100%',
      height: 'auto',
      maxHeight: isNativeOrPWA ? 50 : 80,
      marginHorizontal: 'auto'
    },
    avatar: {
      height: isNativeOrPWA ? 36 : 40,
      width: isNativeOrPWA ? 36 : 40,
      position: 'absolute',
      top: isNativeOrPWA ? -12 : -15,
      left: isNativeOrPWA ? -12 : -15,
      zIndex: 10
    },
    bubbleText: { color: 'black', width: '100%', textAlign: 'left', fontSize: isNativeOrPWA ? 11 : 13 },
    user: {
      color: 'black',
      fontWeight: 'bold',
      fontSize: isNativeOrPWA ? 11 : 15
    },
    tweetVerified: {
      width: 12,
      height: 12,
      marginLeft: 6
    }
  });

  return (
    <Animated.View
      pointerEvents="none"
      style={[
        styles.container,
        style,
        {
          opacity: position,
          transform: [
            {
              translateX: position.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 40]
              })
            }
          ]
        }
      ]}
    >
      <Image source={tweet.avatar} style={styles.avatar} resizeMode="contain" />
      <Image source={tweetBubbleTop} style={styles.top} resizeMode="stretch" />
      <ImageBackground source={tweetBubbleMiddle} style={styles.middle} resizeMode="stretch">
        {tweet && (
          <Animated.View style={[styles.childrenContainer, { opacity: fadeAnim }]}>
            <View style={{ maxWidth: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
              <Text style={styles.user}>{tweet.username}</Text>
              <Image source={tweetVerified} style={styles.tweetVerified} resizeMode="contain" />
            </View>
            <View style={{ maxWidth: '100%' }}>
              <Text style={styles.bubbleText}>{tweet.message}</Text>
            </View>
          </Animated.View>
        )}
      </ImageBackground>
      <Image source={tweetBubbleBottom} style={styles.bottom} resizeMode="stretch" />
    </Animated.View>
  );
};

export default TweetMsg;
