import { StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import { isNativeOrPWA } from '../../../commons/utils';

export const useDeckSelectorCardStyle = () => {
  const { vw } = useUnits();

  const proportionCard = 1.3;
  const width = vw(10.5);

  const deckItemCardStyle = StyleSheet.create({
    image: {
      minWidth: isNativeOrPWA ? 90 : 210,
      minHeight: isNativeOrPWA ? 90 * proportionCard : 210 * proportionCard,
      width,
      height: width * proportionCard,
      maxHeight: 360 * proportionCard,
      maxWidth: 360
    }
  });

  return { deckItemCardStyle, width };
};
