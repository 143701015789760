import { useEffect } from 'react';

interface RewardReveal {
  reveal: boolean;
  id: string;
}

export const useRewardReveal = <T extends RewardReveal>(updateState: Function, reward: T | T[] | null, goBack: Function) => {
  useEffect(() => {
    if (!reward) return;
    if (Array.isArray(reward)) {
      reward.every(item => item.reveal) && goBack();
    } else {
      reward.reveal && goBack();
    }
  }, [reward]);

  const handleSingleReveal = (itemToReveal: T): void => {
    if (itemToReveal.reveal || !reward || Array.isArray(reward)) return;
    const copy = { ...reward };
    copy.reveal = true;
    updateState(copy);
  };

  const handleMultipleReveal = (itemToReveal: T): void => {
    if (itemToReveal.reveal || !Array.isArray(reward)) return;

    const copy = [...reward];
    const index = copy.findIndex(item => item.id === itemToReveal.id);
    if (index < 0) return;
    copy[index].reveal = true;
    updateState(copy);
  };

  const exitReward = () => null;

  return {
    handleSingleReveal,
    handleMultipleReveal,
    exitReward
  };
};
