import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image, ImageBackground, TouchableWithoutFeedback } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import { getAvatar } from '../../commons/components/Dialogues/utils';
import OptionsMenuModal from '../../commons/components/OptionsMenuModal/OptionsMenuModal';
import SnackBar from '../../commons/components/SnackBar';
import UserMenuButton from '../../commons/components/UserMenu/UserProfileButton';
import AvatarModal from '../../commons/components/UserProfile/AvatarModal';
import BottomMenuItem from '../../commons/components/UserProfile/BottomMenuItem';
import UserProfileGeneral from '../../commons/components/UserProfile/Steps/UserProfileGeneral';
import UserProfileRanking from '../../commons/components/UserProfile/Steps/UserProfileRanking';
import UserProfileTitle from '../../commons/components/UserProfile/Steps/UserProfileTitle';
import { AvatarProperties } from '../../commons/dtos/avatar.dto';
import { GendersEnum, Player } from '../../commons/dtos/player.dto';
import { TitleProperties } from '../../commons/dtos/title.dto';
import { ApiError } from '../../commons/errors/api-error';
import adjust from '../../commons/styles/TextAdjust';
import { chooseTitle, isNativeOrPWA } from '../../commons/utils';
import { backgroundProfile, backIcon, bottomMenuProfile, settingsIcon } from '../../constants/Images';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { Profile, setProfile } from '../../redux/Profile/profileReducer';
import store from '../../redux/store';
import PlayerService, { Inventory } from '../../services/player.service';
import { RootStackParamList } from '../../types';

const playerService = new PlayerService();

enum StepsProfile {
  GENERAL = 'general',
  TITLE = 'title',
  RANKING = 'ranking'
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'UserProfile', undefined>;

const UserProfileScreen = () => {
  const navigation = useNavigation<Navigation>();
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false);
  const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false);
  const [inventory, setInventory] = useState<Inventory>();
  const [step, setStep] = useState<StepsProfile>(StepsProfile.GENERAL);
  const { vw } = useUnits();

  const player = useSelector(({ profile }: { profile: Profile }) => profile.player!);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  useEffect(() => {
    const fetchInventory = async () => player && (await playerService.getInventory());
    fetchInventory()
      .then(data => setInventory(data))
      .catch((err: any) => {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(
            `[Código: ${ex.httpCode}]: Ocurrió un problema inicializando el inventario. Es posible que algunas funciones no estén disponibles`,
            `[Detalle]: ${ex.detail}`
          );
        } else {
          setMessage(`Error: ${err}`);
        }
      });
  }, [checkShowLoginModal]);

  const { avatar, gender, playerName, progress, title } = player;

  const openAvatarModal = () => setShowAvatarModal(true);
  const closeAvatarModal = () => setShowAvatarModal(false);

  const openOptionsModal = () => setShowOptionsModal(true);
  const closeOptionsModal = () => setShowOptionsModal(false);

  const handleStep = (step: StepsProfile): void => setStep(step);

  const updatePlayer = (updatedPlayer: Player) => store.dispatch(setProfile({ ...updatedPlayer }));

  const updatePlayerTitle = async ({ title }: { title: TitleProperties }) => {
    const updatedPlayer = await playerService.updatePlayer(player!.id!, { title });

    if (updatedPlayer) updatePlayer(updatedPlayer);
  };

  const updatePlayerAvatar = async ({ avatar, gender }: { avatar: AvatarProperties; gender: GendersEnum }) => {
    const updatedPlayer = await playerService.updatePlayer(player!.id!, { avatar, gender });

    if (updatedPlayer) updatePlayer(updatedPlayer);
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#1E1E1E',
      overflow: 'hidden',
      position: 'relative',
      alignItems: 'center'
    },
    topBar: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: isNativeOrPWA ? 24 : 32,
      paddingTop: isNativeOrPWA ? 8 : 16
    },
    topBarWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      elevation: 10,
      zIndex: 10
    },
    goBackButton: {
      width: vw(10),
      maxWidth: 220,
      height: 'auto',
      flexDirection: 'row'
    },
    goBackButtonIcon: {
      width: isNativeOrPWA ? 13 : 20,
      height: isNativeOrPWA ? 13 : 20,
      marginRight: vw(1.5)
    },
    goBackButtonText: {
      fontWeight: '700',
      fontSize: isNativeOrPWA ? adjust(18) : adjust(19),
      color: '#272727'
    },
    usernameText: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? adjust(24) : adjust(32),
      fontWeight: '800'
    },
    mainContent: {
      flex: 1,
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'stretch'
    },
    bottomMenuBackground: {
      width: '100%',
      height: isNativeOrPWA ? 75 : 120,
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'center',
      paddingTop: isNativeOrPWA ? 15 : 25,
      maxWidth: isNativeOrPWA ? 580 : 1200,
      minWidth: isNativeOrPWA ? 290 : 700,
      marginBottom: isNativeOrPWA ? -6 : -10
    },
    background: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  });

  const steps: Record<StepsProfile, JSX.Element> = {
    general: (
      <UserProfileGeneral
        avatarSource={getAvatar(avatar!.avatarImage)}
        nextLevel={progress.nextLevel}
        title={chooseTitle(gender!, title)}
        username={playerName}
        openAvatarModal={openAvatarModal}
      />
    ),
    title: <UserProfileTitle currentTitle={title} titlesList={inventory?.titles ?? []} updatePlayerTitle={updatePlayerTitle} />,
    ranking: <UserProfileRanking setMessage={setMessage} />
  };

  return (
    <View style={styles.container}>
      <ImageBackground source={backgroundProfile} resizeMode="cover" style={styles.background} />
      <View style={styles.topBar}>
        <View style={styles.goBackButton}>
          <ButtonShape bgColor="white" heightBtn={isNativeOrPWA ? 35 : 50} border isPointeable>
            <TouchableWithoutFeedback onPress={() => navigation.navigate('Home')}>
              <View style={styles.topBarWrapper}>
                <Image style={styles.goBackButtonIcon} source={backIcon} resizeMode="contain" />
                <Text style={styles.goBackButtonText}>Volver</Text>
              </View>
            </TouchableWithoutFeedback>
          </ButtonShape>
        </View>
        <Text style={styles.usernameText}>{playerName}</Text>
        <UserMenuButton handleNavigate={openOptionsModal} imageSource={settingsIcon} />
      </View>
      <View style={styles.mainContent}>{steps[step]}</View>
      <ImageBackground source={bottomMenuProfile} style={styles.bottomMenuBackground} resizeMode="stretch">
        <BottomMenuItem text="General" active={step === StepsProfile.GENERAL} handleStep={() => handleStep(StepsProfile.GENERAL)} />
        <BottomMenuItem text="Titulos" active={step === StepsProfile.TITLE} handleStep={() => handleStep(StepsProfile.TITLE)} />
        <BottomMenuItem text="Ranking" active={step === StepsProfile.RANKING} handleStep={() => handleStep(StepsProfile.RANKING)} />
      </ImageBackground>

      {showOptionsModal && <OptionsMenuModal closeModal={closeOptionsModal} />}
      {showAvatarModal && inventory && (
        <AvatarModal
          closeModal={closeAvatarModal}
          avatarList={inventory?.avatars}
          currentAvatar={avatar!}
          username={playerName}
          updateAvatar={updatePlayerAvatar}
        />
      )}
      <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
    </View>
  );
};

export default UserProfileScreen;
