import { useEffect, useState } from 'react';
import { StyleSheet, ImageBackground, Image } from 'react-native';
import useUnits from 'rxn-units';
import ExternalHeader from '../../commons/components/ExternalHeader/ExternalHeader';
import LegalFooter from '../../commons/components/LegalFooter';
import RankingList from '../../commons/components/RankingList/RankingList';
import SnackBar from '../../commons/components/SnackBar';
import { View, Text } from '../../commons/components/Themed';
import { RankingPlayer } from '../../commons/constants/ranking';
import { ApiError } from '../../commons/errors/api-error';
import { isNativeOrPWA } from '../../commons/utils';
import { LoginFormBackgroundImage, LoginBackgroundImage, rankingDoxi, rankingBot } from '../../constants/Images';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import RankingService from '../../services/ranking.service';

const rankingService = new RankingService();

const Ranking = () => {
  const { vh } = useUnits();
  const [ranking, setRanking] = useState<RankingPlayer[]>();
  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  useEffect(() => {
    const fetchRanking = async () => await rankingService.getRanking();
    fetchRanking()
      .then(data => setRanking(data))
      .catch((err: any) => {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(`[Código: ${ex.httpCode}]: No se pudo recuperar el ranking de usuarios`, `[Detalle]: ${ex.detail}`);
        } else {
          setMessage(`Error: ${err}`);
        }
      });
  }, []);

  const styles = StyleSheet.create({
    rankingBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute'
    },
    ranking: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      position: 'relative',
      flex: 1,
      maxHeight: isNativeOrPWA ? vh(84) : vh(92),
      width: isNativeOrPWA ? vh(75) : vh(80)
    },
    background: {
      width: '100%',
      height: '100%',
      position: 'absolute'
    },
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      height: '100%'
    },
    rankingWrapper: {
      maxHeight: '100%',
      flexShrink: 1,
      flexGrow: 1,
      flexBasis: 'auto',
      paddingTop: isNativeOrPWA ? 15 : 55,
      paddingBottom: isNativeOrPWA ? 15 : 40,
      paddingHorizontal: isNativeOrPWA ? 20 : 50,
      maxWidth: 600
    },
    title: {
      flexShrink: 0,
      flexGrow: 0,
      flexBasis: 'auto',
      fontSize: isNativeOrPWA ? 18 : 24,
      color: 'white',
      textAlign: 'center',
      fontWeight: '600',
      paddingVertical: 10
    },
    oxiImage: {
      maxWidth: 831,
      maxHeight: 890,
      position: 'absolute',
      left: 0,
      bottom: isNativeOrPWA ? -40 : -50,
      zIndex: 20,
      width: vh(68),
      height: vh(68) * 1.4,
      transform: [{ translateX: vh(68) > 631 ? -631 / 1.6 : -vh(68) / 1.4 }]
    },
    botImage: {
      maxWidth: 326,
      maxHeight: 402,
      position: 'absolute',
      bottom: isNativeOrPWA ? -5 : -20,
      right: 0,
      zIndex: 20,
      height: vh(45),
      width: vh(45) * 1.15,
      transform: [{ translateX: vh(45) > 346 ? 346 / 1.5 : vh(45) / 1.3 }]
    },
    imageContainerBot: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      height: '100%'
    },
    imageContainerOxi: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      height: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0
    },
    containerMainContent: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    footerContainer: {
      alignItems: 'center',
      flexDirection: 'row'
    }
  });

  return (
    <View style={styles.container}>
      <ImageBackground style={styles.background} source={LoginBackgroundImage} resizeMode="cover" />
      <ExternalHeader current="ranking" withLogo />
      <View style={styles.containerMainContent}>
        <View style={styles.ranking}>
          <ImageBackground style={styles.rankingBackground} source={LoginFormBackgroundImage} resizeMode={'stretch'} />
          <View style={styles.rankingWrapper}>
            <Text style={styles.title}>Top 50</Text>
            <RankingList gradColor="#595959" list={ranking} />
          </View>
          <View style={styles.imageContainerBot} pointerEvents="none">
            <Image style={styles.botImage} resizeMode="contain" source={rankingBot} accessible accessibilityLabel="bot image" />
          </View>
          <View style={styles.imageContainerOxi} pointerEvents="none">
            <Image style={styles.oxiImage} source={rankingDoxi} resizeMode="contain" accessible accessibilityLabel="oxidoxi image" />
          </View>
        </View>
        <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
      </View>
      <View style={styles.footerContainer}>
        <LegalFooter />
      </View>
    </View>
  );
};

export default Ranking;
