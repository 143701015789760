import { useRef } from 'react';
import { ItemInfo, ItemLocationRegister } from '../types/drag';

export function useItemRegistry() {
  const itemRegistry = useRef<ItemLocationRegister>({});

  const handleAddItem = (id: string, info: ItemInfo) => (itemRegistry.current = { ...itemRegistry.current, [id]: info });

  const handleRemoveItem = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: removeItem, ...rest } = itemRegistry.current;
    itemRegistry.current = rest;
  };

  return {
    itemRegistry,
    handleAddItem,
    handleRemoveItem
  };
}
