import { useRef } from 'react';
import { Animated, Image, Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import ButtonShape from '../../../commons/components/ButtonShape/ButtonShape';
import SelectedCard from '../../../commons/components/Card/CardTypes/SelectedCard';
import { CardSizesEnum } from '../../../commons/components/Card/utils';
import { View, Text } from '../../../commons/components/Themed';
import { Card as CardType } from '../../../commons/dtos/card.dto';

import { discardIcon } from '../../../constants/Images';
interface DiscardModalProps {
  cards: CardType[];
  selectedToDiscardCards: number[];
  setSelectedToDiscardCards: Function;
  onConfirmDiscard: Function;
  text: string;
  showDiscardModalButton: boolean;
  onGetAutoDiscardSelection: () => void;
  hiddenCards?: number[];
  replaceCardDiscard: () => void;
}
const DiscardModal = ({
  cards,
  onConfirmDiscard,
  onGetAutoDiscardSelection,
  selectedToDiscardCards,
  setSelectedToDiscardCards,
  showDiscardModalButton,
  text,
  hiddenCards,
  replaceCardDiscard
}: DiscardModalProps) => {
  const { rw } = useRatioDimensions();
  const cardRefs = cards.map(() => useRef(null));
  const areCardsHovered = cardRefs.map(ref => useHover(ref));
  const fadeInAnims = cards.map(() => useRef(new Animated.Value(0)).current);

  const fadeOut = (index: number) => {
    Animated.timing(fadeInAnims[index], {
      toValue: 0,
      duration: 300,
      useNativeDriver: Platform.OS !== 'web'
    }).start();
  };
  const fadeIn = (index: number) => {
    Animated.timing(fadeInAnims[index], {
      toValue: 1,
      duration: 500,
      useNativeDriver: Platform.OS !== 'web'
    }).start();
  };

  const onCardPressed = (cardIndex: number) => {
    if (selectedToDiscardCards.includes(cardIndex)) {
      setSelectedToDiscardCards(selectedToDiscardCards.filter(card => card !== cardIndex));
      return;
    }
    setSelectedToDiscardCards([...selectedToDiscardCards, cardIndex]);
  };

  const styles = StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10
    },
    cardsContainer: {
      marginTop: '2%',
      width: '65%',
      height: '60%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    card: {
      width: rw(18),
      height: rw(25.2),
      marginVertical: '1%',
      marginHorizontal: '4%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    text: { fontWeight: '600', marginTop: '2%', marginBottom: '2%', textAlign: 'center' },
    discardModalButtonText: { fontWeight: '600', color: '#000000', textAlignVertical: 'center', marginBottom: '2%' },
    autoselectButtonText: { color: '#FFFFFF' },
    discardIcon: {
      position: 'absolute',
      width: '60%',
      height: '60%',
      zIndex: 20
    },
    discardText: {
      position: 'absolute',
      bottom: '-10%',
      fontWeight: '700',
      fontSize: rw(1)
    },
    discardMenu: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '50%',
      marginTop: '3%'
    },
    discardModalButton: { marginRight: '1%' }
  });

  const onConfirm = (): void => {
    if (selectedToDiscardCards.length) return replaceCardDiscard();
    return onConfirmDiscard();
  };

  return (
    <View style={styles.container}>
      <View style={styles.cardsContainer}>
        {cards.map((card, index) => {
          if (!hiddenCards?.includes(index)) {
            fadeIn(index);
          } else {
            fadeOut(index);
          }
          return (
            <TouchableWithoutFeedback key={index} onPress={() => onCardPressed(index)}>
              <Animated.View style={[styles.card, { opacity: fadeInAnims[index] }]} ref={cardRefs[index]}>
                {showDiscardModalButton && selectedToDiscardCards.includes(index) && (
                  <Image style={styles.discardIcon} source={discardIcon} resizeMode="contain" />
                )}
                <SelectedCard
                  card={card}
                  cardSize={CardSizesEnum.VERY_BIG}
                  shadow={
                    showDiscardModalButton && selectedToDiscardCards.includes(index)
                      ? {
                          filter:
                            'drop-shadow(rgb(255, 0, 0) 0px 4px 10px) drop-shadow(rgb(255, 0, 0) 1px 1px 30px) grayscale(10%) brightness(70%) sepia(100%) hue-rotate(-50deg) saturate(500%) contrast(1.2)'
                        }
                      : areCardsHovered[index]
                      ? { filter: 'drop-shadow(0px 4px 10px #5C9DFF) drop-shadow(1px 1px 30px #5C9DFF)' }
                      : {
                          filter: 'drop-shadow(0px 4px 10px #17FFE3) drop-shadow(1px 1px 30px #17FFE3)'
                        }
                  }
                />
                {showDiscardModalButton && selectedToDiscardCards.includes(index) && <Text style={styles.discardText}>DESCARTAR</Text>}
              </Animated.View>
            </TouchableWithoutFeedback>
          );
        })}
      </View>
      <View style={styles.discardMenu}>
        <Text style={[styles.text, { fontSize: rw(1.4) }]}>{text}</Text>
        {showDiscardModalButton && (
          <>
            <TouchableWithoutFeedback onPress={onConfirm}>
              <View style={styles.discardModalButton}>
                <ButtonShape bgColor="white" heightBtn={rw(3)}>
                  <Text style={[styles.discardModalButtonText, { fontSize: rw(1.1) }]}>
                    {selectedToDiscardCards.length ? 'Descartar' : 'Conservar'}
                  </Text>
                </ButtonShape>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => onGetAutoDiscardSelection()}>
              <View style={styles.discardModalButton}>
                <ButtonShape bgColor="gray" heightBtn={rw(3)}>
                  <Text style={[styles.discardModalButtonText, styles.autoselectButtonText, { fontSize: rw(1.1) }]}>
                    Elegir Automáticamente
                  </Text>
                </ButtonShape>
              </View>
            </TouchableWithoutFeedback>
          </>
        )}
      </View>
    </View>
  );
};

export default DiscardModal;
