import React from 'react';
import { ImageBackground, View, Image, Text, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import useUnits from 'rxn-units';
import { avatarBackgroundCheck, avatarBackgroundUserProfile, checkIconProfile } from '../../../../constants/Images';
import { Level } from '../../../dtos/progress.dto';
import adjust from '../../../styles/TextAdjust';
import { isNativeOrPWA } from '../../../utils';
import ButtonShape from '../../ButtonShape/ButtonShape';
import NextReward from '../../NextReward';
import PlayerExperience from '../../PlayerExperience';
import PolyShape from '../../PolyShape/PolyShape';

interface UserProfileGeneralProps {
  username: string;
  avatarSource: any;
  nextLevel: Level;
  title: string;
  openAvatarModal: () => void;
}

const UserProfileGeneral = ({ username, avatarSource, nextLevel, title, openAvatarModal }: UserProfileGeneralProps) => {
  const { experience: nextExperience, level: nextLevelNumber, rewards: nextRewards } = nextLevel;
  const { vh, vw } = useUnits();
  const styles = StyleSheet.create({
    topMainContent: {
      height: 'auto',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: isNativeOrPWA ? 12 : 24
    },
    restXPText: {
      fontSize: isNativeOrPWA ? adjust(16) : 16,
      marginTop: isNativeOrPWA ? 12 : 24
    },
    goldText: {
      color: '#FFBE08',
      fontWeight: '700'
    },
    nextLevelText: {
      color: '#FFF',
      fontWeight: '400'
    },
    centerMiddle: {
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 0,
      flexShrink: 1,
      paddingHorizontal: isNativeOrPWA ? 15 : 64,
      maxHeight: 30
    },
    nickname: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      flexWrap: 'wrap',
      marginBottom: vh(2),
      maxWidth: isNativeOrPWA ? 180 : 340,
      minHeight: 36,
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      alignItems: 'center',
      position: 'relative',
      height: isNativeOrPWA ? 40 : '100%',
      width: isNativeOrPWA ? 150 : '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    nicknameText: {
      fontSize: isNativeOrPWA ? 18 : 26,
      color: '#FFF',
      fontFamily: 'Inlanders',
      textAlign: 'center'
    },
    nicknameTextMini: {
      fontSize: isNativeOrPWA ? adjust(18) : 21
    },
    polyContainer: {
      height: '100%',
      width: '100%'
    },
    titleText: {
      fontWeight: '500',
      fontSize: isNativeOrPWA ? adjust(16) : 16,
      color: '#FFF'
    },
    avatarBackground: {
      width: isNativeOrPWA ? vw(12) : 185,
      height: isNativeOrPWA ? vw(12) : 185,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-end',
      position: 'relative'
    },
    nextLevelTitle: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? adjust(17) : 18,
      marginTop: isNativeOrPWA ? 12 : 24
    },
    nextLevelTitleText: {
      fontWeight: '500'
    },
    nextLevelTitleLevel: {
      fontWeight: '800'
    },
    avatarImage: {
      width: '100%',
      height: '100%',
      marginBottom: isNativeOrPWA ? 3 : 6
    },
    checkWrapper: {
      position: 'absolute',
      top: 0,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      elevation: 10,
      zIndex: 10
    },
    checkImage: {
      width: isNativeOrPWA ? 32 : 62,
      height: isNativeOrPWA ? 32 : 62,
      justifyContent: 'center',
      alignItems: 'center'
    },
    checkIconImage: {
      width: isNativeOrPWA ? 20 : 28,
      height: isNativeOrPWA ? 20 : 28
    },
    changeWrapper: {
      position: 'absolute',
      width: isNativeOrPWA ? '100%' : '60%',
      bottom: isNativeOrPWA ? -20 : -18,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      elevation: 10,
      zIndex: 10
    },
    changeWrapperText: {
      fontSize: isNativeOrPWA ? adjust(15) : 16,
      color: '#FFF',
      fontWeight: '700'
    },
    rewardsContainer: {
      flexDirection: 'row',
      marginTop: isNativeOrPWA ? 16 : 32
    },
    mobileContainer: {
      flex: 1,
      flexDirection: 'row',
      marginHorizontal: vw(16),
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    mobileRewardsContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 12,
      paddingHorizontal: vw(4)
    }
  });

  const leftMobile = (
    <>
      <View style={styles.topMainContent}>
        <ImageBackground style={styles.avatarBackground} source={avatarBackgroundUserProfile} resizeMode="contain">
          <Image source={avatarSource} style={styles.avatarImage} resizeMode="contain" />
          <View style={styles.checkWrapper}>
            <ImageBackground style={styles.checkImage} source={avatarBackgroundCheck} resizeMode="contain">
              <Image source={checkIconProfile} style={styles.checkIconImage} resizeMode="contain" />
            </ImageBackground>
          </View>

          <TouchableWithoutFeedback onPress={openAvatarModal}>
            <View style={styles.changeWrapper}>
              <ButtonShape bgColor="dark" heightBtn={isNativeOrPWA ? 28 : 38} border isPointeable>
                <Text style={styles.changeWrapperText}>Cambiar</Text>
              </ButtonShape>
            </View>
          </TouchableWithoutFeedback>
        </ImageBackground>
        <View style={styles.centerMiddle}>
          <View style={styles.nickname}>
            <View style={styles.polyContainer}>
              <PolyShape type={3} bgColor={'#131313'}>
                <Text style={[styles.nicknameText, username.length > 13 && styles.nicknameTextMini]}>{username.toUpperCase()}</Text>
              </PolyShape>
            </View>
          </View>
          <Text style={[styles.titleText, isNativeOrPWA && { alignSelf: 'flex-start' }]}>{title || 'Sin titulo'}</Text>
        </View>
      </View>
      <View style={{ marginVertical: vh(isNativeOrPWA ? 4 : 1) }}>
        <PlayerExperience />
      </View>
      <Text style={styles.restXPText}>
        <Text style={styles.goldText}>{`${nextExperience}XP`}</Text>
        <Text style={styles.nextLevelText}> para el siguiente nivel</Text>
      </Text>
    </>
  );

  const rightMobile = (
    <>
      <Text style={styles.nextLevelTitle}>
        <Text style={styles.nextLevelTitleText}>Al alcanzar </Text>
        <Text style={styles.nextLevelTitleLevel}>{`Nivel ${nextLevelNumber}:`}</Text>
      </Text>
      <View style={isNativeOrPWA ? styles.mobileRewardsContainer : styles.rewardsContainer}>
        {nextRewards.map(({ type }, index) => (
          <NextReward key={index} reward={type} />
        ))}
      </View>
    </>
  );

  if (isNativeOrPWA) {
    return (
      <View style={styles.mobileContainer}>
        <View style={{ alignItems: 'center' }}>{leftMobile}</View>
        <View style={{ alignItems: 'center' }}>{rightMobile}</View>
      </View>
    );
  }

  return (
    <View style={{ justifyContent: 'center' }}>
      <View style={{ alignItems: 'center' }}>{leftMobile}</View>
      <View style={{ alignItems: 'center' }}>{rightMobile}</View>
    </View>
  );
};

export default UserProfileGeneral;
