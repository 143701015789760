import { View, StyleSheet, Text, Image, TouchableWithoutFeedback } from 'react-native';
import { returnIcon } from '../../../constants/Images';
import PrivacyPolicyText from '../../../screens/PrivacyPolicy/PrivacyPolicyText';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';

interface ConfigPrivacyProps {
  handleShowedComponent: Function;
}

const ConfigPrivacy = ({ handleShowedComponent }: ConfigPrivacyProps) => {
  const styles = StyleSheet.create({
    container: {
      width: '80%',
      flex: 1
    },
    topContainer: {
      paddingVertical: 14,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 10
    },
    mainContainer: {
      marginTop: !isNativeOrPWA ? 40 : 55,
      flex: 1,
      flexBasis: 'auto',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    exitContainer: {
      width: 130,
      height: isNativeOrPWA ? 35 : 45,
      position: 'absolute',
      left: 0,
      top: isNativeOrPWA ? 10 : -20
    },
    returnIcon: {
      width: 14,
      height: 14,
      marginBottom: 6
    },
    returnText: {
      fontWeight: '700',
      color: '#FFF',
      fontSize: adjust(16),
      marginLeft: 16,
      marginBottom: 6
    },
    buttonInner: {
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <TouchableWithoutFeedback onPress={() => handleShowedComponent(0)}>
          <View style={styles.exitContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 46}>
              <View style={styles.buttonInner}>
                <Image source={returnIcon} style={styles.returnIcon} resizeMode="contain" />
                <Text style={styles.returnText}>Volver</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
      <View style={styles.mainContainer}>
        <PrivacyPolicyText isModal />
      </View>
    </View>
  );
};

export default ConfigPrivacy;
