import { useRef } from 'react';

export function useSplitText(text: string, animated: boolean) {
  const textRef = useRef<string>(text);

  if (!animated) return { prevText: text, newText: null };

  const newTextAppend = text.replace(textRef.current, '');

  if (newTextAppend === text) return { prevText: text, newText: null };

  return { prevText: textRef.current, newText: newTextAppend };
}
