import { Reward } from './reward.dto';

export enum MissionProgressStatusEnum {
  STARTED = 'started',
  UNLOCKED = 'unlocked',
  PLAYED = 'played',
  COMPLETED = 'completed',
  SURRENDERED = 'surrendered'
}

export interface ProgressMission {
  id: string;
  status: MissionProgressStatusEnum;
}

export interface Level {
  id?: string;
  experience: number;
  level: number;
  rewards: Reward[];
}

export class Progress {
  id?: string;
  missionId!: string;
  complete!: boolean;
  nextLevelOn!: number;
  missionStatus!: MissionProgressStatusEnum;
  missionsCompleted!: number;
  attempts!: number;
  victoriesNumber!: number;
  defeatsNumber!: number;
  experience!: number;
  level!: Level;
  nextLevel!: Level;
  levelUp?: boolean;

  constructor(properties?: ProgressProperties) {
    if (properties) {
      this.id = properties.id;

      this.missionId = properties.missionId;
      this.complete = properties.complete;
      this.level = properties.level;
      this.nextLevelOn = properties.nextLevelOn;
      this.missionStatus = properties.missionStatus;
      this.missionsCompleted = properties.missionsCompleted;

      this.experience = properties.experience;
      this.nextLevelOn = properties.nextLevelOn;

      this.attempts = properties.attempts;
      this.defeatsNumber = properties.defeatsNumber;
      this.victoriesNumber = properties.victoriesNumber;

      this.level = properties.level;
      this.nextLevel = properties.nextLevel;
      this.levelUp = properties.levelUp;
    }
  }
}

export interface ProgressProperties {
  id: string;
  // mission
  missionId: string;
  complete: boolean;
  missionStatus: MissionProgressStatusEnum;
  missionsCompleted: number;
  // stats
  attempts: number;
  victoriesNumber: number;
  defeatsNumber: number;
  // experience
  experience: number;
  level: Level;
  nextLevelOn: number;
  nextLevel: Level;
  levelUp?: boolean;
}
