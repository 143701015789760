import { ReactNode } from 'react';
import { Linking, Text } from 'react-native';
import { HTMLTypeElements, ResultHTML } from '../utils/parser';

interface HTMLElementProps {
  element: ResultHTML;
  index: number;
  children?: ReactNode;
}

const ParsedHTMLElement = ({ element, index, children }: HTMLElementProps) => {
  const { type, parameter, text, listIndex, style, listLast, parent } = element;

  switch (type) {
    case 'normal':
      return (
        <Text key={index} style={style}>
          {text}
          {children ? children : null}
        </Text>
      );
    case HTMLTypeElements.ANCHOR:
      return (
        <Text key={index} onPress={() => parameter && Linking.openURL(parameter)} style={style}>
          {text}
          {children ? children : null}
        </Text>
      );
    case HTMLTypeElements.ORDEREDLIST:
      return (
        <Text key={index} style={style}>
          {listIndex === 1 ? '\n' : ''}
          {parent ? `\n${listIndex}.` : null}
          <Text>
            {text}
            {children ? children : null}
            {parent ? '\n' : null}
          </Text>
          {parent && listLast ? '\n' : null}
        </Text>
      );
    case HTMLTypeElements.UNORDEREDLIST:
      return (
        <Text key={index} style={style}>
          {listIndex === 1 ? '\n' : ''}
          {parent ? `\n${'\u2022'} ` : null}
          <Text>
            {text}
            {children ? children : null}
            {parent ? '\n' : null}
          </Text>
          {parent && listLast ? '\n' : null}
        </Text>
      );
    default:
      return (
        <Text key={index} style={style}>
          {text}
          {children ? children : null}
        </Text>
      );
  }
};

export default ParsedHTMLElement;
