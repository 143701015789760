import { AVPlaybackSource } from 'expo-av';

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-var-requires */
export const howAttack: AVPlaybackSource = require('../assets/video/how-to-play/attack-video.mp4');
export const howEnhance: AVPlaybackSource = require('../assets/video/how-to-play/enhance-video.mp4');

export const missionDialogAnimation: { [name: string]: AVPlaybackSource } = {
  info_listo: require('../assets/video/mission-dialogue/info_listo.mp4'),
  gatito: require('../assets/video/mission-dialogue/gatito.mp4')
};

export const tutorialAttack: AVPlaybackSource = require('../assets/video/tutorial/attack-tutorial.mp4');
