import { cards } from '../../../constants/Images';
import type { BoardCards, Hand } from '../../../screens/GameBoard/gameTypes';
import { CardQuantity } from '../../dtos/card-quantity.dto';
import { Card } from '../../dtos/card.dto';
import { PlayerRoundEnum } from '../../dtos/player.dto';
import { AppliedRules, Rule, RuleTypeEnum } from '../../dtos/rule.dto';
import { TagsEnum } from '../../dtos/tag.dto';
import { TypesEnum } from '../../dtos/type.dto';
import { selectEnhanceEffect } from '../enhaceEffect';
import { checkApplyToAllCards, getCardRules, isApplicableRuleToTarget } from './rules';

function removeAttackableFlagCard(card: Card): Card {
  return { ...card, isAttackable: false };
}

function removeAttackingFlagCard(card: Card): Card {
  return { ...card, isAttacking: false };
}

function removeAttackingFlag(cards: BoardCards): BoardCards {
  return cards.map(removeAttackingFlagCard);
}

function removeAttackableFlag(cards: BoardCards): BoardCards {
  return cards.map(removeAttackableFlagCard);
}

function setAtttackingCardByIndex(cardIndex: number): (cards: BoardCards) => BoardCards {
  return (cards: BoardCards) => cards.map((card, index) => ({ ...card, isAttacking: cardIndex === index }));
}

function removeAttackableFlagByIndex(cardIndex: number): (cards: BoardCards) => BoardCards {
  return (cards: BoardCards) => cards.map((card, index) => (cardIndex === index ? { ...card, isAttackable: false } : card));
}

function removeAttackingFlagByIndex(cardIndex: number): (cards: BoardCards) => BoardCards {
  return (cards: BoardCards) => cards.map((card, index) => (cardIndex === index ? { ...card, isAttacking: false } : card));
}

function isCardSupportOrCardAction(typeName: TypesEnum | undefined): boolean {
  if (!typeName) return false;

  return typeName === TypesEnum.SUPPORT || typeName === TypesEnum.ACTION;
}

function setAttackableCards(originalCards: BoardCards): (cards: BoardCards) => BoardCards {
  return (cards: BoardCards) =>
    cards.map(boardCard => {
      if (cardIsAttackable(boardCard, originalCards)) {
        boardCard.isAttackable = true;
        return boardCard;
      }
      boardCard.isAttackable = false;
      return boardCard;
    });
}

function cardIsAttackable(attackedCard: Card, attackedBoardCards: BoardCards): boolean {
  if (
    attackedBoardCards.some(attackedBoardCard => attackedBoardCard.tags?.some(tag => tag.tagName === TagsEnum.BARRIER)) &&
    !attackedCard.tags?.some(tag => tag.tagName === TagsEnum.BARRIER)
  ) {
    return false;
  }
  if (attackedCard.tags?.some(tag => tag.tagName === TagsEnum.UNDETECTABLE)) return false;
  return true;
}

function filterCardsWithDefensePoints(card: Card): boolean {
  return card.defensePoints === null || card.defensePoints > 0;
}

export function isStoppedCard(boardCardId: string, isOpponent: boolean, appliedRules: AppliedRules, round: number) {
  const currentAppliedRules = { ...appliedRules };
  const checkStoppedCards = (side: PlayerRoundEnum) => {
    return (
      currentAppliedRules[side].stop[boardCardId] &&
      currentAppliedRules[side].stop[boardCardId]?.stopByTurns + currentAppliedRules[side].stop[boardCardId]?.stoppedRound >= round
    );
  };
  return checkStoppedCards(isOpponent ? PlayerRoundEnum.OPPONENT : PlayerRoundEnum.PLAYER);
}

function updateBoardCardStatus(
  boardCards: BoardCards,
  opponentRound: PlayerRoundEnum,
  appliedRules: AppliedRules,
  round: number
): BoardCards {
  const newBoardCards = boardCards.map(boardCard => {
    boardCard.isAttacking = false;
    boardCard.firstTurn = false;

    if (!boardCard.damagePoints) return boardCard;
    boardCard.isWaiting = false;

    if (isStoppedCard(boardCard.id, opponentRound === PlayerRoundEnum.OPPONENT, appliedRules, round)) {
      boardCard.firstTurn = true;
      boardCard.isWaiting = true;
    }
    return boardCard;
  });
  return newBoardCards;
}

function updateBoardCardStatusReduced(
  boardCards: BoardCards,
  opponentRound: PlayerRoundEnum,
  appliedRules: AppliedRules,
  round: number
): BoardCards {
  const newBoardCards = boardCards.map(boardCard => {
    boardCard.isAttacking = false;

    if (isStoppedCard(boardCard.id, opponentRound === PlayerRoundEnum.OPPONENT, appliedRules, round)) {
      boardCard.firstTurn = true;
      boardCard.isWaiting = true;
    }
    return boardCard;
  });
  return newBoardCards;
}

function filterOnlyAliveCards(card: Card): boolean {
  return (card.defensePoints !== null && card.defensePoints > 0) ?? false;
}

function removeCard(card: Card): (cards: BoardCards) => BoardCards {
  return (cards: BoardCards) => cards.map(boardCard => (boardCard.id === card.id ? { ...boardCard, defensePoints: 0 } : boardCard));
}

function cardHasNewCardRules(rules: Rule[]) {
  let hasNewCardRules = false;
  const cardHasMultipleRules = rules.length > 1;
  if (cardHasMultipleRules) {
    hasNewCardRules = rules.filter(rule => rule?.launchCards || rule?.newCards)?.length > 0;
  }
  return hasNewCardRules;
}

function checkPlayingTargetRequiredCard(
  card: Card,
  playerBoardCards: BoardCards,
  opponentBoardCards: BoardCards,
  allRules: Rule[],
  isPlayerRound: boolean,
  isOpponentRound: boolean
): boolean {
  if (!card.rules) return true;
  const actionCardRules = getCardRules(card, allRules);

  if (cardHasNewCardRules(actionCardRules)) {
    actionCardRules.pop();
  }

  return actionCardRules.some(rule => {
    const {
      newCards,
      needTarget,
      applyToAllCards,
      applyToColor,
      applyToSubject,
      applyToType,
      applyToAvatar,
      applyToOpponent,
      applyToPlayer
    } = rule;
    if (newCards && !needTarget) {
      return true;
    }

    if ((!needTarget && (applyToAllCards !== true || (!applyToColor && !applyToSubject && !applyToType))) || applyToAvatar) return true;
    if ((isPlayerRound && applyToOpponent) || (isOpponentRound && applyToPlayer)) {
      if (applyToAllCards) {
        return checkApplyToAllCards(rule, opponentBoardCards);
      }
      return opponentBoardCards.some(opponentCard => isApplicableRuleToTarget(rule, opponentCard, opponentCard.id, isOpponentRound));
    }
    if ((isPlayerRound && applyToPlayer) || (isOpponentRound && applyToOpponent)) {
      if (applyToAllCards) {
        return checkApplyToAllCards(rule, playerBoardCards);
      }
      return playerBoardCards.some(playerCard => isApplicableRuleToTarget(rule, playerCard, playerCard.id, isPlayerRound));
    }
  });
}

function sameTypeRules(rules: Rule[]): boolean {
  if (rules) {
    const [firstRule] = rules;
    const { ruleType: playedCardRuleType, applyToPlayer: playedCardApplyToPlayer, applyToOpponent: playedCardApplyToOpponent } = firstRule;
    return (
      rules.length > 1 &&
      rules.every(
        ({ ruleType, applyToPlayer, applyToOpponent }) =>
          ruleType === playedCardRuleType && applyToPlayer === playedCardApplyToPlayer && applyToOpponent === playedCardApplyToOpponent
      )
    );
  }
  return false;
}

function checkPlayingCards({
  actualActionPoints,
  allRules,
  playerBoardCards,
  opponentBoardCards,
  hand,
  isPlayerRound
}: {
  hand: Hand;
  actualActionPoints: number;
  playerBoardCards: BoardCards;
  opponentBoardCards: BoardCards;
  isPlayerRound: boolean;
  allRules: Rule[];
}): Hand {
  const newHand = hand.map(card => {
    const { type, actionPoints } = card;

    const cardRules = getCardRules(card, allRules);

    if (cardHasNewCardRules(cardRules) && actionPoints <= actualActionPoints) {
      card.isPlayingCard = true;
      return card;
    }

    if (!isPlayerRound) {
      if (
        (type?.typeName === TypesEnum.SUPPORT || type?.typeName === TypesEnum.ACTION) &&
        !checkPlayingTargetRequiredCard(card, playerBoardCards, opponentBoardCards, allRules, isPlayerRound, !isPlayerRound)
      ) {
        card.isPlayingCard = false;
        return card;
      }
    }

    if (actionPoints <= actualActionPoints) {
      card.isPlayingCard = true;
      return card;
    }
    card.isPlayingCard = false;
    return card;
  });

  return newHand;
}

function updateBoardCardsOnPlayCard({
  actionPoints,
  allRules,
  boardCards,
  isPlayerRound,
  hand,
  playedCard,
  rivalBoardCards
}: {
  playedCard: Card;
  boardCards: BoardCards;
  hand: Hand;
  rivalBoardCards: BoardCards;
  allRules: Rule[];
  actionPoints: number;
  isPlayerRound: boolean;
}): [Hand, BoardCards] {
  const newBoardCards = playedCard.type?.typeName === TypesEnum.SUPPORT ? [...boardCards] : [...boardCards, playedCard];

  const newHand = checkPlayingCards({
    actualActionPoints: actionPoints,
    allRules,
    playerBoardCards: isPlayerRound ? newBoardCards : rivalBoardCards,
    opponentBoardCards: isPlayerRound ? rivalBoardCards : newBoardCards,
    hand,
    isPlayerRound
  });

  return [newHand, newBoardCards];
}

function removeUndetectableTag(card: Card): Card {
  const { tags } = card;
  if (!tags) return card;
  card.tags = tags.filter(({ tagName }) => tagName !== TagsEnum.UNDETECTABLE);

  return card;
}

function removeCapabilities(card: Card): Card {
  const { tags } = card;
  if (!tags) return card;
  const excludeTags = [TagsEnum.UNDETECTABLE, TagsEnum.BARRIER, TagsEnum.IMMEDIACY, TagsEnum.IMPACT];
  card.tags = tags.filter(({ tagName }) => !excludeTags.includes(tagName));

  return card;
}

/**
 * Apply enhance effects to all boardCards and if card was upgraded will remove this status
 * @param boardCards collection of cards to process
 * @returns new board cards with enhaceEffects and no upgrade status
 */
function removeUpgradeCardStateAndApplyEffects(boardCards: BoardCards): BoardCards {
  const promises = boardCards.map(card => {
    const newCard = selectEnhanceEffect(card);
    return newCard;
  });

  return promises;
}

function getBattlePoints(attackingCard: Card, cardReceive: Card) {
  const { damagePoints, tags } = attackingCard;
  if (tags?.some(({ tagName }) => tagName === TagsEnum.FACTCHECK)) return 0;

  return (cardReceive.defensePoints as number) - damagePoints!;
}

function launchCardAndEnhanceEffects({
  actionPoints,
  allRules,
  boardCards,
  hand,
  isPlayerRound,
  playedCard,
  rivalBoardCards
}: {
  boardCards: BoardCards;
  rivalBoardCards: BoardCards;
  playedCard: Card;
  hand: Hand;
  actionPoints: number;
  allRules: Rule[];
  isPlayerRound: boolean;
}): [Hand, BoardCards] {
  const [newHand, newBoardCards] = updateBoardCardsOnPlayCard({
    actionPoints,
    allRules,
    boardCards,
    rivalBoardCards,
    isPlayerRound,
    hand,
    playedCard
  });
  removeUpgradeCardStateAndApplyEffects(newBoardCards);
  return [newHand, newBoardCards];
}

function cardAppliesToTarget(card: Card, targetCard: Card, allRules: Rule[], isSelfRound?: boolean | null): boolean {
  const { rules } = card;

  if (!rules) return true;
  const actionCardRules = getCardRules(card, allRules) as Rule[];

  return actionCardRules.some(rule => {
    return rule.needTarget && isApplicableRuleToTarget(rule, targetCard, targetCard.id, isSelfRound);
  });
}

function checkIsSelectingTarget(
  card: Card,
  isReactionExtract = false,
  allRules: Rule[],
  currentSelectingTargetDrawnCard: boolean,
  playerBoardCards: BoardCards,
  opponentBoardCards: BoardCards,
  isPlayerRound: boolean
): boolean {
  const { rules } = card;
  if (!rules) return false;

  const extractedRules = getCardRules(card, allRules) as Rule[];
  const _cardNeedTarget = extractedRules.some(rule => rule && rule.needTarget);
  const _checkPlayingTargetRequiredCard = checkPlayingTargetRequiredCard(
    card,
    playerBoardCards,
    opponentBoardCards,
    allRules,
    isPlayerRound,
    !isPlayerRound
  );

  return _cardNeedTarget && (isReactionExtract || currentSelectingTargetDrawnCard || _checkPlayingTargetRequiredCard);
}

function targetCardIsPlayeable({
  allRules,
  boardCards,
  card,
  rivalBoardCards
}: {
  card: Card;
  boardCards: BoardCards;
  rivalBoardCards: BoardCards;
  allRules: Rule[];
}): boolean {
  const { type } = card;
  return (
    !!type &&
    (((type.typeName === TypesEnum.SUPPORT || type.typeName === TypesEnum.FAKE) &&
      boardCards.filter(boardCard => cardAppliesToTarget(card, boardCard, allRules, true)).length > 0) ||
      (type.typeName === TypesEnum.ACTION &&
        rivalBoardCards.filter(boardCard => cardAppliesToTarget(card, boardCard, allRules, false)).length > 0))
  );
}
const opponentTargetCardIsPlayable = ({
  allRules,
  playerBoardCards,
  card,
  currentSelectingTargetDrawnCard,
  isReactionExtract,
  opponentBoardCards,
  isPlayerRound
}: {
  card: Card;
  playerBoardCards: BoardCards;
  opponentBoardCards: BoardCards;
  allRules: Rule[];
  isReactionExtract?: boolean;
  currentSelectingTargetDrawnCard: boolean;
  isPlayerRound: boolean;
}): boolean => {
  return (
    targetCardIsPlayeable({ allRules, boardCards: opponentBoardCards, card, rivalBoardCards: playerBoardCards }) ||
    (checkIsSelectingTarget(
      card,
      !!isReactionExtract,
      allRules,
      currentSelectingTargetDrawnCard,
      playerBoardCards,
      opponentBoardCards,
      isPlayerRound
    ) &&
      playerBoardCards.filter(boardCard => cardAppliesToTarget(card, boardCard, allRules, false)).length > 0)
  );
};

/**
 *
 * @param param0
 * @returns
 */
const playerTargetCardIsPlayable = ({
  card,
  boardCards,
  rivalBoardCards,
  allRules
}: {
  card: Card;
  boardCards: BoardCards;
  rivalBoardCards: BoardCards;
  allRules: Rule[];
}): boolean => {
  return targetCardIsPlayeable({ allRules, boardCards, card, rivalBoardCards });
};

function selectBestTarget(damage?: number) {
  const commonSteps = (previous: Card, current: Card) => {
    const { damagePoints: prevDamagePoints, defensePoints: prevDefensePoints, actionPoints: prevActionPoints } = previous;
    const { damagePoints, defensePoints, actionPoints } = current;
    if (!damagePoints || !defensePoints || !prevDamagePoints || !prevDefensePoints) return previous;

    const commonCondition =
      actionPoints > prevActionPoints ||
      (actionPoints === prevActionPoints && damagePoints + defensePoints > prevDamagePoints + prevDefensePoints);
    if (!damage) {
      if (commonCondition) {
        return current;
      }
      return previous;
    } else {
      const damageCheck = defensePoints + damage <= 0 || defensePoints + damage <= prevDefensePoints + damage;
      if (damageCheck && commonCondition) {
        return current;
      }
      return previous;
    }
  };
  return commonSteps;
}

/**
 * Selects best target to target requiring cards
 * @param actionCard
 * @param possibleTargets
 * @param allRules
 * @returns
 */
function getBestTargetForActionCard(actionCard: Card, possibleTargets: BoardCards, allRules: Rule[]) {
  if (!actionCard.rules) return [];

  const actionCardRules = getCardRules(actionCard, allRules);
  // Check if action card to use includes DESTROY rule
  const isDestroyActionCard = actionCardRules.some(rule => rule.ruleType === RuleTypeEnum.DESTROY);
  // Check if action card to use includes DAMAGE rule
  const [damageRule] = actionCardRules.filter(
    rule => rule.ruleType === RuleTypeEnum.POINTS && typeof rule.defensePoints === 'number' && rule.defensePoints < 0
  );

  // Action card to use includes DESTROY rule
  if (isDestroyActionCard) {
    // Best possible target will be the card with the most action points, or if tie, most damagepoints +  defense points
    possibleTargets = [possibleTargets.reduce(selectBestTarget(), possibleTargets[0])];
    return possibleTargets;
  }
  // Action card to use includes DESTROY rule
  if (damageRule) {
    const damage = damageRule.defensePoints as number;

    // Best possible target will be card with the most action points, or if tie, mosta damagepoints +  defense points
    possibleTargets = [possibleTargets.reduce(selectBestTarget(damage), possibleTargets[0])];
    return possibleTargets;
  }
  return possibleTargets;
}

function formatCard(cardQuantity: CardQuantity): Card[] {
  const deckCards = [];
  for (let index = 0; index < cardQuantity.quantity; index++) {
    const { card } = cardQuantity;
    deckCards.push({ ...card, imageSource: cards[card.illustration.replace('.png', '')] });
  }
  return deckCards;
}

export default {
  removeAttackingFlag,
  setAtttackingCardByIndex,
  setAttackableCards,
  removeAttackableFlagByIndex,
  filterCardsWithDefensePoints,
  removeAttackingFlagCard,
  removeAttackableFlag,
  cardIsAttackable,
  updateBoardCardStatus,
  updateBoardCardStatusReduced,
  filterOnlyAliveCards,
  removeCard,
  removeAttackingFlagByIndex,
  launchCardAndEnhanceEffects,
  checkPlayingCards,
  opponentTargetCardIsPlayable,
  playerTargetCardIsPlayable,
  checkIsSelectingTarget,
  cardAppliesToTarget,
  getBestTargetForActionCard,
  removeUpgradeCardStateAndApplyEffects,
  updateBoardCardsOnPlayCard,
  removeAttackableFlagCard,
  formatCard,
  removeUndetectableTag,
  removeCapabilities,
  checkPlayingTargetRequiredCard,
  isCardSupportOrCardAction,
  getBattlePoints,
  sameTypeRules
};
