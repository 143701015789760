// eslint-disable-next-line no-shadow
export enum TagsEnum {
  FACTCHECK = 'factcheck',
  UNDETECTABLE = 'oculta',
  IMMEDIACY = 'breaking',
  IMPACT = 'impacto',
  BARRIER = 'barrera'
}
export enum TagsExplanationEnum {
  'factcheck' = 'Destruye a la carta atacada o atacante independientemente de los puntos de resistencia que tenga',
  'oculta' = 'No puede ser atacada por otras hasta que realice su primer ataque',
  'breaking' = 'Puede atacar en el mismo turno en que se ha puesto en juego en el tablero',
  'impacto' = 'Cuando ataca, produce puntos de daño al avatar del jugador contrario si produce más daño que puntos de resistencia tiene la carta atacada',
  'barrera' = 'Mientras está en juego, el jugador contrario no puede atacar al avatar o a otra carta que no sea barrera'
}
/**
 * Tag variable type declaration.
 *
 * @interface
 */

export class Tag {
  id?: string;
  tagName!: TagsEnum;

  constructor(properties?: TagProperties) {
    if (properties) {
      this.tagName = properties.tagName;
    }
  }
}

export interface TagProperties {
  id?: string;
  tagName: TagsEnum;
}
