import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import useUnits from 'rxn-units';
import { Text, View } from '../../commons/components/Themed';
import { RootStackParamList } from '../../types';
import adjust from '../styles/TextAdjust';
import { isMobile } from '../utils';

interface LegalFooterProps {
  styleProp?: StyleProp<ViewStyle>;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Login', undefined>;

const LegalFooter = ({ styleProp }: LegalFooterProps) => {
  const { vw } = useUnits();
  const navigation = useNavigation<Navigation>();
  const openLegalURL = () => navigation.navigate('Legal');

  const openPrivacyURL = () => navigation.navigate('PrivacyPolicy');

  const openTermsURL = () => navigation.navigate('Terms');

  const styles = StyleSheet.create({
    gradient: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: 44,
      padding: 10,
      width: vw(100)
    },
    footerLinksContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      maxWidth: isMobile ? 460 : 700
    },
    footerLink: {
      color: '#92A6A3',
      fontSize: adjust(19),
      fontWeight: '400',
      marginHorizontal: 16
    }
  });
  return (
    <LinearGradient style={[styles.gradient, styleProp]} colors={['transparent', 'rgba(0, 0, 0, 0.375)']} locations={[0.2, 0.6]}>
      <View style={styles.footerLinksContainer}>
        <TouchableOpacity accessible accessibilityLabel={'Link to Legal Advice page'} onPress={openLegalURL}>
          <Text style={styles.footerLink}>Aviso legal</Text>
        </TouchableOpacity>
        <TouchableOpacity accessible accessibilityLabel={'Link to Privacy page'} onPress={openPrivacyURL}>
          <Text style={styles.footerLink}>Política de privacidad</Text>
        </TouchableOpacity>
        <TouchableOpacity accessible accessibilityLabel={'Link to Términos y Condiciones page'} onPress={openTermsURL}>
          <Text style={styles.footerLink}>Términos y Condiciones</Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
};

export default LegalFooter;
