import { useState } from 'react';
import { TouchableWithoutFeedback, TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import adjust from '../../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';

interface ConfigMainScreenProps {
  handleSurrender?: Function;
  handleShowedComponent: Function;
}

const OptionsGameScreen = ({ handleSurrender, handleShowedComponent }: ConfigMainScreenProps) => {
  const { rh } = useRatioDimensions();
  const [confirmSurrender, setConfirmSurrender] = useState(false);
  const currentHandleSurrender = () => {
    if (handleSurrender) handleSurrender();
  };
  const styles = StyleSheet.create({
    container: {
      paddingVertical: 7,
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonContainerExit: {
      marginTop: rh(4),
      width: '100%'
    },
    title: {
      fontSize: adjust(22),
      fontWeight: '600',
      color: '#FFF',
      textAlign: 'center',
      marginBottom: rh(12)
    },
    buttonContainer: {
      width: isNativeMobile ? '80%' : '100%',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: !isNativeOrPWA ? 300 : 'auto',
      maxWidth: isNativeOrPWA ? 240 : 'auto'
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    },
    buttonText: {
      color: '#D3D3D3',
      fontWeight: '500',
      fontSize: adjust(18),
      textAlign: 'center',
      paddingBottom: 0
    },
    privacyPolicyText: {
      fontSize: adjust(17),
      fontWeight: '600',
      color: '#D3D3D3',
      textAlign: 'center',
      marginTop: rh(12)
    },
    buttons: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10
    },
    buttonConfirm: {
      marginHorizontal: 5,
      marginVertical: 20
    },
    confirmText: {
      fontSize: adjust(18),
      color: 'white'
    }
  });
  const content = !confirmSurrender ? (
    <>
      <TouchableWithoutFeedback onPress={() => handleShowedComponent(1)}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={styles.buttonText}>Configuracion</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
      <View style={styles.buttonContainerExit}>
        <TouchableWithoutFeedback onPress={() => setConfirmSurrender(true)}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.buttonText}>Rendirse</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
      <TouchableOpacity onPress={() => handleShowedComponent(2)}>
        <Text style={styles.privacyPolicyText}>Politica de privacidad</Text>
      </TouchableOpacity>
    </>
  ) : (
    <View>
      <Text style={styles.confirmText}>¿Estás seguro que quieres rendirte?</Text>
      <View style={styles.buttons}>
        <TouchableWithoutFeedback onPress={() => setConfirmSurrender(false)}>
          <View style={styles.buttonConfirm}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.buttonText}>Cancelar</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={currentHandleSurrender}>
          <View style={styles.buttonConfirm}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.buttonText}>Rendirse</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Opciones</Text>
      {content}
    </View>
  );
};

export default OptionsGameScreen;
