/**
 * Tag variable type declaration.
 *
 * @interface
 */

export class Title {
  id?: string;

  maleText!: string;

  femaleText!: string;

  constructor(properties?: TitleProperties) {
    if (properties) {
      this.maleText = properties.maleText;
      this.femaleText = properties.femaleText;
    }
  }
}

export interface TitleProperties {
  id?: string;
  maleText: string;
  femaleText: string;
}
