import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../types';

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Tutorial', undefined>;
type Route = RouteProp<RootStackParamList, 'Tutorial'>;

export const useJumpGame = () => {
  const route = useRoute<Route>();
  const navigation = useNavigation<Navigation>();
  const { gameConfig } = route.params;

  const goToGame = () => {
    navigation.goBack();
    navigation.navigate('LaunchBattle', { gameConfig });
  };

  return { goToGame };
};
