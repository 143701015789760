import { createSlice } from '@reduxjs/toolkit';
import { ActionInterface } from '../../commons/dtos/reaction.dto';

export interface GameActionInterface {
  gameAction: ActionInterface | null;
  gameActions: ActionInterface[];
}

const initialState: GameActionInterface = {
  gameAction: null,
  gameActions: []
};

const gameActionsSlice = createSlice({
  name: 'game-actions',
  initialState,
  reducers: {
    setGameActions(state, action: { payload: ActionInterface[] }) {
      return { ...state, gameActions: action.payload };
    },
    nextGameAction(state) {
      const { gameActions } = state;
      if (gameActions.length === 0) return { ...state, gameAction: null };

      const [gameAction, ...restGameActions] = gameActions;
      return { ...state, gameAction: gameAction, gameActions: restGameActions };
    },
    clearCurrentGameAction(state) {
      return { ...state, gameAction: null };
    },
    resetGameActions() {
      return initialState;
    }
  }
});

export const { setGameActions, resetGameActions, nextGameAction } = gameActionsSlice.actions;

export default gameActionsSlice;
