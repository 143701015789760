import { useState, useRef, useEffect } from 'react';
import { StyleSheet, Image, TouchableWithoutFeedback, Animated, TextInput, Platform, ImageBackground } from 'react-native';
import useUnits from 'rxn-units';
import ButtonShape from '../../../commons/components/ButtonShape/ButtonShape';
import { View, Text } from '../../../commons/components/Themed';
import { CardInventoryFilter } from '../../../commons/dtos/card-inventory-filter.dto';
import { backIcon, searchIcon } from '../../../constants/Images';
import { isNativeOrPWA } from '../../utils';

interface CardInventoryViewerMenuProps {
  setCardInventoryFilters: Function;
  cardInventoryFilters: CardInventoryFilter;
  onGoBack: Function;
}
const CardInventoryViewerMenu = (props: CardInventoryViewerMenuProps) => {
  const { vw } = useUnits();
  const [searchActive, setSearchActive] = useState(false);
  const expandSearchBarAnim = useRef(new Animated.Value(vw(3.4))).current;
  const [isSearchBarFocused, setISearchBarFocused] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (searchActive) {
      const animation = Animated.timing(expandSearchBarAnim, {
        toValue: 1,
        duration: 250,
        useNativeDriver: Platform.OS !== 'web'
      });
      animation.start();
    }
    const animation = Animated.timing(expandSearchBarAnim, {
      toValue: 0,
      duration: 250,
      useNativeDriver: Platform.OS !== 'web'
    });
    animation.start();
  }, [searchActive]);

  useEffect(() => {
    const updatedFilters = { ...props.cardInventoryFilters };

    if (search.length > 0) {
      updatedFilters.nameFilter = search;
      props.setCardInventoryFilters(updatedFilters);
      return;
    }
    updatedFilters.nameFilter = undefined;
    props.setCardInventoryFilters(updatedFilters);
  }, [search]);

  const onSearchIconPress = () => {
    if (!search) {
      setSearchActive(!searchActive);
    }
  };

  const styles = StyleSheet.create({
    container: {
      color: 'white',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center'
    },
    buttonContainer: {
      zIndex: 200,
      borderColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: 500,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    button: { zIndex: 200, height: isNativeOrPWA ? 18 : 32, width: isNativeOrPWA ? 18 : 32 },
    returnButtonText: { fontSize: isNativeOrPWA ? 16 : 22, fontWeight: '700', color: '#000' },
    returnButtonImg: { height: isNativeOrPWA ? 16 : 22, width: isNativeOrPWA ? 16 : 22, marginRight: isNativeOrPWA ? 12 : 18 },
    searchBarInput: {
      color: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      borderWidth: 0,
      borderColor: 'transparent',
      outlineWidth: 0,
      outlineColor: 'transparent',
      fontSize: vw(1.3),
      paddingBottom: vw(0.2),
      paddingHorizontal: vw(0.8)
    }
  });
  const barStyles = StyleSheet.create({
    searchButtonContainer: {
      justifyContent: searchActive ? 'space-between' : 'center',
      paddingHorizontal: 0
    },
    button: { opacity: searchActive ? 0.7 : 1 }
  });

  return (
    <View style={styles.container}>
      <TouchableWithoutFeedback onPress={() => props.onGoBack()}>
        <View style={{ marginHorizontal: vw(2) }}>
          <ButtonShape heightBtn={isNativeOrPWA ? 30 : 52}>
            <View style={[styles.buttonContainer, { marginHorizontal: vw(0.8) }]}>
              <ImageBackground style={styles.returnButtonImg} source={backIcon} resizeMode="contain" />
              <Text style={styles.returnButtonText}>Volver</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback onPress={() => onSearchIconPress()}>
        <View>
          <ButtonShape bgColor="gray" heightBtn={isNativeOrPWA ? 32 : 56}>
            <View style={[styles.buttonContainer, barStyles.searchButtonContainer]}>
              {searchActive && (
                <TextInput
                  autoFocus={true}
                  onChangeText={(text: string) => setSearch(text)}
                  onBlur={() => {
                    setISearchBarFocused(false);
                    if (!search) setSearchActive(false);
                  }}
                  onFocus={() => setISearchBarFocused(true)}
                  keyboardAppearance={'dark'}
                  numberOfLines={1}
                  disableFullscreenUI
                  value={search}
                  placeholder="Buscar carta..."
                  style={[
                    styles.searchBarInput,
                    isSearchBarFocused &&
                      // eslint-disable-next-line react-native/no-inline-styles
                      Platform.OS === 'web' && {
                        marginBottom: 0
                      }
                  ]}
                />
              )}
              <Image style={[styles.button, barStyles.button]} resizeMode="contain" source={searchIcon} />
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default CardInventoryViewerMenu;
