import { PlayerTypeEnum } from '../../constants/player';
import { Card } from '../../dtos/card.dto';
import { ReactionConfiguration, ReactionEventEnum, opponentEvents, playerEvents } from '../../dtos/reaction-configuration.dto';

export interface ReactionsManagementProps {
  reactionsConfiguration: ReactionConfiguration[];
  round: number;
  isPlayerRound: boolean;
  playerLifePoints: number;
  opponentLifePoints: number;
}

export function getPlayerByReactionEvent(event: ReactionEventEnum): PlayerTypeEnum {
  return playerEvents.includes(event) ? PlayerTypeEnum.PLAYER : PlayerTypeEnum.OPPONENT;
}

export function isCurrentRound(round: number, reactionConfig: ReactionConfiguration): boolean {
  return reactionConfig.trigger.round === undefined || reactionConfig.trigger.round === round;
}

export function isCurrentPlayerPlaying(reactionsManagementProps: ReactionsManagementProps, reactionConfig: ReactionConfiguration): boolean {
  return (
    (playerEvents.includes(reactionConfig.trigger.event) && reactionsManagementProps.isPlayerRound) ||
    (opponentEvents.includes(reactionConfig.trigger.event) && !reactionsManagementProps.isPlayerRound)
  );
}

export function isCurrentPlayerTarget(reactionConfig: ReactionConfiguration, player: PlayerTypeEnum): boolean {
  return (
    (playerEvents.includes(reactionConfig.trigger.event) && player === PlayerTypeEnum.PLAYER) ||
    (opponentEvents.includes(reactionConfig.trigger.event) && player === PlayerTypeEnum.OPPONENT)
  );
}

export function isNotDone(reactionConfig: ReactionConfiguration): boolean {
  return !reactionConfig.trigger.onlyFirstTime || !reactionConfig.trigger.done;
}

export function isCardTarget(reactionConfig: ReactionConfiguration, card: Card): boolean {
  return !reactionConfig.trigger.cardCode || (card && card.cardCode === reactionConfig.trigger.cardCode);
}

export function isEnoughLifePointsHit(reactionConfig: ReactionConfiguration, card: Card): boolean {
  return !reactionConfig.trigger.lifePoints || (card && !!card.damagePoints && card.damagePoints >= reactionConfig.trigger.lifePoints);
}

export function isEnoughLifePointsDamaged(reactionConfig: ReactionConfiguration, attackingCard: Card): boolean {
  return (
    !reactionConfig.trigger.lifePoints ||
    (attackingCard && !!attackingCard.damagePoints && attackingCard.damagePoints >= reactionConfig.trigger.lifePoints)
  );
}

export function isThresholdExceeded(
  reactionsManagementProps: ReactionsManagementProps,
  reactionConfig: ReactionConfiguration,
  card: Card,
  player: number
) {
  return (
    (reactionConfig.trigger.lifePointsThreshold !== 0 && !reactionConfig.trigger.lifePointsThreshold) ||
    (card &&
      card.damagePoints &&
      (player === PlayerTypeEnum.PLAYER ? reactionsManagementProps.playerLifePoints : reactionsManagementProps.opponentLifePoints) -
        (card.damagePoints as number) <=
        reactionConfig.trigger.lifePointsThreshold)
  );
}

export function setIsDone(showDialogue: boolean, reactionConfig: ReactionConfiguration): void {
  if (showDialogue && reactionConfig.trigger.onlyFirstTime) {
    reactionConfig.trigger.done = true;
  }
}
