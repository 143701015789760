import { Image, View, Text, StyleSheet, TouchableWithoutFeedback, ImageStyle } from 'react-native';
import useUnits from 'rxn-units';
import { isNativeOrPWA } from '../../commons/utils';
import { rewardAvatar, rewardCard, rewardDeck, rewardTitle } from '../../constants/Images';
import { RewardOptions } from './useReward';

export interface RewardProps {
  source: Exclude<RewardOptions, 'list'>;
  text: string;
  handleRewardChange: () => void;
}

interface RewardInfo {
  style: ImageStyle;
  source: any;
}

const Reward = ({ source, text, handleRewardChange }: RewardProps) => {
  const { vw } = useUnits();
  const style = StyleSheet.create({
    container: {
      marginHorizontal: vw(2),
      width: 'auto',
      height: 'auto',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    text: {
      color: '#FFF',
      fontWeight: '600',
      fontSize: 18,
      marginTop: isNativeOrPWA ? 16 : 32
    }
  });

  const possibleRewards: Record<Exclude<RewardOptions, 'list'>, RewardInfo> = {
    avatar: {
      style: {
        maxWidth: 225,
        maxHeight: 212,
        minWidth: isNativeOrPWA ? 85 : 125,
        minHeight: 118,
        width: vw(15),
        height: vw(14.42)
      },
      source: rewardAvatar
    },
    card: {
      style: {
        maxWidth: 208,
        maxHeight: 297,
        minWidth: isNativeOrPWA ? 92 : 120,
        minHeight: isNativeOrPWA ? 134 : 174,
        width: vw(18),
        height: vw(22)
      },
      source: rewardCard
    },
    cardPack: {
      style: {
        maxWidth: 208,
        maxHeight: 297,
        minWidth: isNativeOrPWA ? 110 : 138,
        minHeight: isNativeOrPWA ? 157 : 197,
        width: vw(18),
        height: vw(22)
      },
      source: rewardDeck
    },
    title: {
      style: {
        maxWidth: 325,
        maxHeight: 235,
        minWidth: isNativeOrPWA ? 185 : 225,
        minHeight: isNativeOrPWA ? 135 : 163,
        width: vw(18.44),
        height: vw(14.44)
      },
      source: rewardTitle
    }
  };

  const { style: imageStyle, source: imageSource } = possibleRewards[source];

  return (
    <TouchableWithoutFeedback onPress={() => handleRewardChange()}>
      <View style={style.container}>
        <Image source={imageSource} resizeMode="contain" style={imageStyle} />
        <Text style={style.text}>{text}</Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default Reward;
