/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useState, useEffect } from 'react';
import { StyleSheet, ImageBackground, Vibration } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import { v4 as uuid } from 'uuid';
import CardStatsFilterModal from '../../commons/components/CardStatsFilter/CardStatsFilterModal';
import CardsViewer from '../../commons/components/CardsViewer';
import EditDeckModal from '../../commons/components/EditDeckModal';
import ErrorModal from '../../commons/components/ErrorModal';
import InventoryTopHeader from '../../commons/components/InventoryTopHeader/InventoryTopHeader';
import SnackBar from '../../commons/components/SnackBar';
import { View } from '../../commons/components/Themed';
import { CardInventoryFilter } from '../../commons/dtos/card-inventory-filter.dto';
import { CardInventoryIncludesCard } from '../../commons/dtos/card-inventory-includes-card.dto';
import { Card } from '../../commons/dtos/card.dto';
import { DeckIncludesCard } from '../../commons/dtos/deck-includes-card.dto';
import { ApiError } from '../../commons/errors/api-error';
import { HttpStatus } from '../../commons/errors/http-status.enum';
import { onFilterCardInventory } from '../../commons/utils';
import { backgroundDeckEditor, blockImage } from '../../constants/Images';
import { MIN_DECK_SIZE } from '../../constants/Values';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { Profile } from '../../redux/Profile/profileReducer';
import CardInventoriesService from '../../services/card-inventories.service';
import DecksService from '../../services/decks.service';
import { DeckEditorProps } from '../../types';
import DeckViewer from './DeckViewer/DeckViewer';

const cardInventoriesService = new CardInventoriesService();
const decksService = new DecksService();

const DeckEditor = ({ navigation, route }: DeckEditorProps) => {
  const [cardsViewerPage, setCardsViewerPage] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cardInventory, setCardInventory] = useState<CardInventoryIncludesCard[]>([]);
  const [fullCardInventory, setFullCardInventory] = useState<CardInventoryIncludesCard[]>([]);
  const [updatedDeck, setUpdatedDeck] = useState<DeckIncludesCard[]>([]);
  const [deckId, setDeckId] = useState(route?.params?.deckId);
  const [cardInventoryFilters, setCardInventoryFilters] = useState<CardInventoryFilter>({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [deckName, setDeckName] = useState<string>('');
  const [deckImage, setDeckImage] = useState<string>('');
  const [showSaveButton, setShowSaveButton] = useState<boolean>(true);
  const { vh } = useUnits();

  const { player } = useSelector(({ profile }: { profile: Profile }) => profile);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  const removeDeckCardsFromCardInventory = (cardInventoryData: CardInventoryIncludesCard[], deck: DeckIncludesCard[]) => {
    let updatedCardInventory = cardInventoryData;
    for (const card of deck) {
      updatedCardInventory = updatedCardInventory.map(cardInventoryCard => {
        if (cardInventoryCard.card.cardName === card.card.cardName) {
          cardInventoryCard.quantity -= 1;
        }
        return cardInventoryCard;
      });
    }
    return updatedCardInventory;
  };
  // TODO Check deps
  useEffect(() => {
    const fetchDeckCards = async (deckId: string) => {
      const deckData = await decksService.getDeck(player!.id!, deckId);
      setUpdatedDeck(deckData.cards);
      const reg = /Mazo_\d{10,}/i;
      if (deckData.deck.deckName) setDeckName(reg.test(deckData.deck.deckName) ? '' : deckData.deck.deckName);
      if (deckData.deck.deckImage) setDeckImage(deckData.deck.deckImage);
      const cardInventoryData = removeDeckCardsFromCardInventory(
        await cardInventoriesService.getCardInventory(player!.id!),
        deckData.cards
      );
      setCardInventory(cardInventoryData);
      setFullCardInventory(cardInventoryData);
    };

    const fetchCardsNewDeck = async () => {
      const cardInventoryData = await cardInventoriesService.getCardInventory(player!.id!);
      setCardInventory(cardInventoryData);
      setFullCardInventory(cardInventoryData);

      setDeckName('');
      setDeckId(uuid());
      setUpdatedDeck([]);
    };
    if (route.params.deckId && !route.params.isNewDeck) {
      fetchDeckCards(route.params.deckId as string).catch((err: any) => {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando el mazo`, `[Detalle]: ${ex.detail}`);
        } else {
          setMessage(`Error: ${err}`);
        }
      });
      return;
    }
    fetchCardsNewDeck().catch((err: any) => {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema inicializando el nuevo mazo`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
    });
  }, [checkShowLoginModal]);

  const updateDeck = (isAdd: boolean, card: Card) => {
    const copyUpdatedDeck = [...updatedDeck];
    const newCard = copyUpdatedDeck.find(({ card: cardToFind }) => cardToFind.cardName === card.cardName);
    if (!newCard) {
      const newDeck: DeckIncludesCard = {
        quantity: 1,
        card,
        deck: { id: deckId!.toString(), deckName, deckImage }
      };
      setUpdatedDeck([newDeck, ...copyUpdatedDeck].filter(({ quantity }) => quantity));
    } else {
      newCard.quantity += isAdd ? 1 : -1;
      setUpdatedDeck(copyUpdatedDeck.filter(({ quantity }) => quantity));
    }
  };

  const updateCardInventory = (isAdd: boolean, card: Card) => {
    const copyCardInventory = [...cardInventory];
    const newCard = copyCardInventory.find(({ card: cardToFind }) => cardToFind.cardName === card.cardName);
    if (!newCard) return;
    newCard.quantity += isAdd ? -1 : 1;
    setCardInventory(copyCardInventory);
  };

  const onAddCardToDeck = (card: Card) => {
    try {
      Vibration.vibrate(1);
      updateDeck(true, card);
      updateCardInventory(true, card);
    } catch (err) {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema añadiendo la carta en el mazo`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
    }
  };
  const onRemoveCardFromDeck = (card: Card) => {
    try {
      Vibration.vibrate(1);
      updateDeck(false, card);
      updateCardInventory(false, card);
    } catch (err) {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema eliminando la carta del mazo`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
    }
  };

  const onSaveDeck = async () => {
    try {
      if (updatedDeck.length < MIN_DECK_SIZE) {
        setErrorMessage('Tu mazo no alcanza el mínimo de cartas necesario.');
        setShowErrorModal(true);
        return;
      }
      if (!deckName.length) {
        setErrorMessage('Introduce un nombre para tu mazo.');
        setShowErrorModal(true);
        return;
      }
      if (!deckImage) {
        setErrorMessage('Elige una imagen para tu mazo.');
        setShowErrorModal(true);
        return;
      }
      setShowSaveButton(false);
      const cardIds = updatedDeck.map(card => card.card.id as string);
      if (route.params.isNewDeck) {
        await decksService.createDeck(player!.id!, deckName, deckImage, cardIds);
      }
      if (route.params.deckId) {
        await decksService.updateDeck(player!.id!, route.params.deckId as string, deckName, deckImage, cardIds);
      }
    } catch (err: any) {
      setShowSaveButton(true);
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        if (HttpStatus.CONFLICT === ex.httpCode && ex.detail?.toLowerCase().includes('already exists')) {
          return setMessage('Ya existe un mazo con el mismo nombre.');
        }
        return setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando las misiones de campaña`, `[Detalle]: ${ex.detail}`);
      }

      return setMessage(`Error: ${err}`);
    }
    setMessage('Mazo guardado correctamente');
    setTimeout(() => {
      setShowSaveButton(true);
      navigation.navigate('CardInventoryViewer', { updateData: Date.now() });
    }, 1500);
  };

  useEffect(() => {
    const filteredCardInventory = onFilterCardInventory(fullCardInventory, cardInventoryFilters) as CardInventoryIncludesCard[];
    if (filteredCardInventory) setCardInventory(filteredCardInventory);
    setCardsViewerPage(0);
  }, [cardInventoryFilters]);

  const onGoBack = () => navigation.goBack();

  const onCloseModal = () => {
    setShowErrorModal(false);
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: '#363636'
    },
    block: {
      position: 'absolute',
      width: '74%',
      height: '86%',
      left: 0,
      paddingLeft: '8%',
      paddingRight: '6%',
      bottom: '2%',
      color: 'white',
      paddingTop: vh(3),
      paddingBottom: vh(1)
    },
    blockImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    background: { width: '100%', height: '100%' }
  });

  return (
    <View style={styles.container}>
      <ImageBackground style={styles.background} source={backgroundDeckEditor} resizeMode="cover" />
      <InventoryTopHeader
        onGoBack={onGoBack}
        cardInventoryFilters={cardInventoryFilters}
        setCardInventoryFilters={setCardInventoryFilters}
        title="Crear mazo"
        showFilter={setShowFilterModal}
      />
      <View style={styles.block}>
        <ImageBackground style={styles.blockImage} source={blockImage} resizeMode="stretch" />
        <CardsViewer
          cardsViewerPage={cardsViewerPage}
          setCardsViewerPage={setCardsViewerPage}
          cardsInventory={cardInventory}
          onAddCardToDeck={onAddCardToDeck}
        />
      </View>
      <DeckViewer
        onSaveDeck={onSaveDeck}
        deckName={deckName}
        setDeckName={setDeckName}
        deckImage={deckImage}
        setDeckImage={setDeckImage}
        deckCards={updatedDeck}
        onRemoveCardFromDeck={onRemoveCardFromDeck}
        showModal={() => setShowEditModal(true)}
        showSaveButton={showSaveButton}
      />
      <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
      {showFilterModal && (
        <CardStatsFilterModal
          cardInventoryFilters={cardInventoryFilters}
          setCardInventoryFilters={setCardInventoryFilters}
          onCloseModal={setShowFilterModal}
        />
      )}
      {showErrorModal && (
        <ErrorModal onCloseModal={onCloseModal} message={errorMessage} buttons={['Aceptar']} onButtonPress={[onCloseModal]} />
      )}
      {showEditModal && (
        <EditDeckModal
          closeModal={() => setShowEditModal(false)}
          deckNameFinal={deckName}
          deckImageFinal={deckImage}
          handleDeckName={(text: string) => setDeckName(text)}
          handleDeckImage={(url: string) => setDeckImage(url)}
        />
      )}
    </View>
  );
};

export default DeckEditor;
