import { useEffect, useMemo, useRef, useState } from 'react';
import { Animated, ImageBackground, StyleSheet, View, Image, Text } from 'react-native';
import useUnits from 'rxn-units';
import { useCountUp } from 'use-count-up';
import { borderProgressBar, progressBar, rayIcon } from '../../constants/Images';
import store from '../../redux/store';
import { Progress } from '../dtos/progress.dto';
import adjust from '../styles/TextAdjust';
import { isNativeOrPWA } from '../utils';
import ButtonShape from './ButtonShape/ButtonShape';

interface PlayerExperienceAnimatedProps {
  previousProgress: Progress;
}

const platformAnimationDriver = false;

export const PlayerExperienceAnimated = ({ previousProgress }: PlayerExperienceAnimatedProps) => {
  const { vw } = useUnits();

  const {
    profile: { player }
  } = store.getState();

  const { progress } = player!;

  const {
    level: { experience: levelExperience, level },
    experience,
    nextLevel: { experience: nextLevelExperience }
  } = progress;

  const {
    level: { experience: prevLevelExperience, level: prevLevel },
    experience: prevExperience,
    nextLevel: { experience: prevNextLevelExperience }
  } = previousProgress;

  const gainedXP = experience - prevExperience;
  const prevTotal =
    experience == prevLevelExperience
      ? 0
      : +(((prevExperience - prevLevelExperience) / (prevNextLevelExperience - prevLevelExperience)) * 100).toFixed(2);
  const total =
    experience == levelExperience ? 0 : +(((experience - levelExperience) / (nextLevelExperience - levelExperience)) * 100).toFixed(2);
  const totalWithGainedXP = +(((prevExperience + gainedXP - levelExperience) / (nextLevelExperience - levelExperience)) * 100).toFixed(2);

  const animationProgressImage = useRef(new Animated.Value(prevTotal)).current;
  const animationRayImage = useRef(new Animated.Value(0)).current;
  const animationContainer = useRef(new Animated.Value(0.7)).current;
  const animationContainerLevelUp = useRef(new Animated.Value(0)).current;
  const animationTextLevelMove = useRef(new Animated.Value(0)).current;

  const [upgradedLevel, setUpgradedLevel] = useState({
    textYellow: false,
    textLevel: false
  });

  const [startTextAnimation, setStartTextAnimation] = useState(false);
  const repeated = useRef(false);

  const rayAnimation = (): Animated.CompositeAnimation => {
    //Move down ray
    const startAnimation = Animated.timing(animationRayImage, {
      toValue: 1,
      duration: 300,
      useNativeDriver: platformAnimationDriver
    });
    //Move up ray
    const endAnimation = Animated.timing(animationRayImage, {
      toValue: 0,
      duration: 300,
      useNativeDriver: platformAnimationDriver
    });

    return Animated.sequence([
      Animated.loop(Animated.sequence([startAnimation, endAnimation]), { iterations: 2, resetBeforeIteration: true })
    ]);
  };

  const progressBarAnimation = (): {
    remain: Animated.CompositeAnimation;
    fill: Animated.CompositeAnimation;
    startToEnd: Animated.CompositeAnimation;
    fillSecondLevel: Animated.CompositeAnimation;
  } => {
    //Fill actual XP to the end with new experience
    const remain = Animated.timing(animationProgressImage, {
      duration: 1250,
      toValue: totalWithGainedXP,
      useNativeDriver: platformAnimationDriver
    });
    //Fill 100% of the container
    const fill = Animated.timing(animationProgressImage, {
      duration: 1250,
      toValue: 100,
      useNativeDriver: platformAnimationDriver
    });
    //Make start go left to right when player level up
    const startToEnd = Animated.timing(animationProgressImage, {
      duration: 1000,
      toValue: 0,
      useNativeDriver: platformAnimationDriver
    });
    //If player level up, update the progress with remaining expererience
    const fillSecondLevel = Animated.timing(animationProgressImage, {
      duration: 1250,
      toValue: totalWithGainedXP,
      useNativeDriver: platformAnimationDriver
    });

    return {
      remain,
      fill,
      startToEnd,
      fillSecondLevel
    };
  };

  const containerAnimation = (): { start: Animated.CompositeAnimation; levelUp: Animated.CompositeAnimation } => {
    //Make container bigger
    const scaleUp = Animated.timing(animationContainer, {
      toValue: 1,
      useNativeDriver: platformAnimationDriver,
      duration: 300,
      delay: 100
    });
    //Make container smaller
    const scaleDown = Animated.timing(animationContainer, {
      toValue: 0.8,
      useNativeDriver: platformAnimationDriver,
      duration: 200
    });
    //Move container up when player level up
    const moveUp = Animated.timing(animationContainerLevelUp, {
      toValue: 0,
      useNativeDriver: platformAnimationDriver,
      duration: 250
    });
    //Move container down when player level up
    const moveDown = Animated.timing(animationContainerLevelUp, {
      toValue: 1,
      useNativeDriver: platformAnimationDriver,
      duration: 250
    });

    return {
      start: Animated.sequence([scaleUp, scaleDown]),
      levelUp: Animated.loop(Animated.sequence([moveDown, moveUp]), {
        iterations: 2
      })
    };
  };

  const textLevelUpAnimation = () => {
    //Chande text of current level
    const moveUp = Animated.timing(animationTextLevelMove, {
      toValue: 1,
      delay: 200,
      useNativeDriver: platformAnimationDriver
    });

    return moveUp;
  };

  useEffect(() => {
    const { start, levelUp } = containerAnimation();
    const rayTranslate = rayAnimation();
    const { fill, remain, fillSecondLevel, startToEnd } = progressBarAnimation();
    const textMove = textLevelUpAnimation();

    const containerSequence = Animated.sequence([start, rayTranslate]);

    containerSequence.start(() => {
      setStartTextAnimation(true);
      //User doesnt level up
      if (prevLevel >= level) {
        remain.start();
        return;
      }

      //User level up
      fill.start(() => {
        levelUp.start();
        setUpgradedLevel(prev => ({
          ...prev,
          textYellow: true
        }));
        const parellel = Animated.parallel([fillSecondLevel, textMove]);
        const sequence = Animated.sequence([startToEnd, parellel]);
        sequence.start();

        const to = setTimeout(() => {
          setUpgradedLevel({
            textLevel: true,
            textYellow: false
          });
        }, 1100);

        return () => clearTimeout(to);
      });
    });
  }, []);

  const { end, start } = useMemo((): { end: number; start: number } => {
    if (upgradedLevel.textLevel) {
      return { start: levelExperience, end: experience };
    } else if (prevLevel < level) {
      return { start: prevExperience, end: levelExperience };
    } else {
      return { start: prevExperience, end: gainedXP + prevExperience };
    }
  }, [prevExperience, gainedXP, prevLevel, experience, levelExperience, upgradedLevel, level]);

  const { value } = useCountUp({
    end,
    start,
    duration: 2,
    isCounting: startTextAnimation,
    onComplete: () => {
      const surpassesNextLevel = prevExperience + gainedXP > prevNextLevelExperience;

      if (prevLevel < level && surpassesNextLevel && !repeated.current) {
        repeated.current = true;
        return { shouldRepeat: true };
      }

      return { shouldRepeat: false };
    }
  });

  const styles = StyleSheet.create({
    container: {
      width: 'auto',
      marginHorizontal: 10,
      paddingTop: 5
    },
    content: {
      height: isNativeOrPWA ? '95%' : '90%',
      flexDirection: 'row',
      alignItems: 'center'
    },
    text: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? adjust(15) : 20,
      fontWeight: '700'
    },
    textXP: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? adjust(15) : 20,
      fontWeight: '400'
    },
    progressContainer: {
      height: isNativeOrPWA ? 15 : 20,
      minHeight: 14,
      width: isNativeOrPWA ? 80 : 120
    },
    ray: {
      width: vw(1.2),
      height: vw(1.2) * 1.22,
      maxWidth: 22,
      maxHeight: 22 * 1.22,
      minWidth: 16,
      minHeight: 16 * 1.22
    },
    borderProgressBar: {
      width: '100%',
      height: '100%',
      position: 'relative',
      paddingVertical: 1,
      paddingHorizontal: isNativeOrPWA ? 1 : 4,
      alignItems: upgradedLevel.textYellow ? 'flex-end' : 'flex-start'
    },
    progressBar: {
      height: '90%',
      width: total + '%'
    },
    experience: {
      flexDirection: 'row'
    },
    marginItems: {
      marginHorizontal: isNativeOrPWA ? 4 : 6,
      marginTop: isNativeOrPWA ? -2 : -3
    }
  });

  if (!player) return <></>;

  return (
    <Animated.View
      style={[
        styles.container,
        {
          transform: [
            {
              scale: animationContainer.interpolate({
                inputRange: [0.7, 1],
                outputRange: [0.7, 1]
              })
            },
            {
              translateY: animationContainerLevelUp.interpolate({
                inputRange: [0, 1],
                outputRange: [-3, 3]
              })
            }
          ]
        }
      ]}
    >
      <ButtonShape isPointeable={false} bgColor="gray" heightBtn={isNativeOrPWA ? 34 : 56}>
        <View style={styles.content}>
          <Animated.Text
            style={[
              styles.text,
              styles.marginItems,
              {
                opacity: animationTextLevelMove.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0]
                }),
                transform: [
                  {
                    translateY: animationTextLevelMove.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, -30]
                    })
                  }
                ]
              }
            ]}
          >
            {`N${level}`}
          </Animated.Text>
          <Animated.Text
            style={[
              styles.text,
              styles.marginItems,
              {
                position: 'absolute',
                opacity: animationTextLevelMove,
                transform: [
                  {
                    translateY: animationTextLevelMove.interpolate({
                      inputRange: [0, 1],
                      outputRange: [30, 0]
                    })
                  }
                ]
              }
            ]}
          >{`N${level + 1}`}</Animated.Text>
          <Animated.Image
            style={[
              styles.ray,
              styles.marginItems,
              {
                transform: [
                  {
                    translateY: animationRayImage.interpolate({
                      inputRange: [0, 1],
                      outputRange: [-1, 1]
                    })
                  }
                ]
              }
            ]}
            source={rayIcon}
            resizeMode="contain"
          />
          <View style={[styles.progressContainer, styles.marginItems]}>
            <ImageBackground source={borderProgressBar} resizeMode="stretch" style={styles.borderProgressBar}>
              {gainedXP ? (
                <Animated.Image
                  source={progressBar}
                  resizeMode="stretch"
                  style={[
                    {
                      height: '90%'
                    },
                    {
                      width: animationProgressImage.interpolate({
                        inputRange: [0, 100],
                        outputRange: ['0%', '100%']
                      })
                    }
                  ]}
                />
              ) : (
                <Image source={progressBar} resizeMode="stretch" style={styles.progressBar} />
              )}
            </ImageBackground>
          </View>
          <View style={[styles.experience, styles.marginItems]}>
            <Text style={[styles.text, { color: upgradedLevel.textYellow ? '#FFBE08' : '#FFF' }]}>{value}</Text>
            <Text style={styles.textXP}>XP</Text>
          </View>
        </View>
      </ButtonShape>
    </Animated.View>
  );
};
