export const glossary = [
  {
    term: '<b>Búsqueda inversa de imágenes</b>',
    definition:
      'Herramienta de verificación que permite buscar imágenes similares o relacionadas a una imagen específica. Es como buscar una "huella dactilar" de una imagen.'
  },
  {
    term: '<b>Ciberataque</b>',
    definition:
      'Cualquier amenaza deliberada ─es decir, a propósito─ en el entorno digital y que, generalmente, pretende crear un daño a organizaciones o personas.'
  },
  {
    term: '<b>Clickbait</b>',
    definition:
      'Técnica de desinformación que utiliza un determinado titular o mensaje a modo de señuelo para atraer tu atención como lector. Su objetivo es hacer que cliques en un determinado artículo al que por norma no habrías entrado.'
  },
  {
    term: '<b>Criptodonación</b>',
    definition: 'Es el proceso de donar dinero en criptomonedas, como Bitcoin o Ethereum, a alguna organización o campaña.'
  },
  {
    term: '<b>Deep Fake</b>',
    definition:
      'Los Deep Fake consisten en la modificación de vídeos, imágenes y audios con la ayuda de la Inteligencia Artificial (IA) para hacerlos parecer como reales.'
  },
  {
    term: '<b>Detector de bots</b>',
    definition: 'Herramienta de verificación que detecta cuentas “fake” en redes sociales gestionadas por máquinas (bots).'
  },
  {
    term: '<b>Fact-Checker</b>',
    definition:
      'Los fact-checkers son organizaciones de periodistas que se dedican específicamente a combatir la desinformación. Desmienten las “fake news” consultando los datos públicos disponibles y a los expertos en el asunto en cuestión.'
  },
  {
    term: '<b>Fact-Checking</b>',
    definition:
      'El fact-checking es el proceso de verificar la veracidad de la información y noticias utilizando fuentes confiables. Los pasos fundamentales son: investigación, verificación de fuentes, análisis de datos y publicación de resultados.'
  },
  {
    term: '<b>Fake Follower</b>',
    definition:
      'Los fake followers o falsos seguidores son perfiles creados por bots, es decir, detrás de ellos no hay una persona real, sino, probablemente, una empresa que comercializa con ellos.'
  },
  {
    term: '<b>Fake News</b>',
    definition:
      'Son noticias falsas que se difunden fundamentalmente en internet o en las redes sociales para confundir a la gente. Pueden ser inventadas o exageradas, y su objetivo es engañar o manipular la opinión pública. Es importante verificar la información antes de compartirla.'
  },
  {
    term: '<b>Forensia de imágenes</b>',
    definition: 'La forensia de imágenes es una práctica que sirve para detectar si una imagen ha sido editada digitalmente.'
  },
  {
    term: '<b>Granja de trolls</b>',
    definition:
      'Grupo de personas o empresas que gestionan miles de usuarios falsos (reales o bots, a los que llamamos trolls) y que los pone a disposición del mejor postor. El gran volumen de cuentas falsas les permite modificar el discurso en las redes sociales, generar tendencias e influir en la opinión del gran público.'
  },
  {
    term: '<b>Hackeo</b>',
    definition: 'Acceso de manera no autorizada a un sistema informático o una red con el fin de obtener, manipular o dañar información.'
  },
  {
    term: '<b>Hater</b>',
    definition:
      'Los haters son perfiles de redes sociales que se dedican a generar un clima de odio y negatividad en las redes sociales. Se caracterizan por insultar a personas relevantes o, simplemente, a usuarios con opiniones diferentes a las suyas.'
  },
  {
    term: '<b>Negacionismo</b>',
    definition:
      'Actitud de negar o rechazar de manera deliberada y persistente hechos o evidencias científicas, históricas o sociales que son ampliamente aceptados por la comunidad académica y científica.'
  },
  {
    term: '<b>OSINT</b>',
    definition:
      'Las herramientas de inteligencia de fuentes abiertas (OSINT) se utilizan para recopilar información online sobre individuos, organizaciones, eventos, tendencias… Se utilizan en fact-checking pero también en campos como la seguridad nacional, la investigación criminal, el marketing y la investigación de negocios.'
  },
  {
    term: '<b>Plandemia</b>',
    definition:
      'Teoría de la conspiración que nació en el año 2020. Asegura sin pruebas y, generalmente, sirviéndose de bulos que la pandemia provocada por la COVID-19 obedecía a “un plan mundial de gobiernos y élites mundiales”.'
  },
  {
    term: '<b>Polarización</b>',
    definition:
      'La polarización política es un fenómeno en el que la población se sitúa en los extremos ideológicos. La polarización hace ver al otro como el “enemigo” y hace que las personas dejen de considerar ideas o puntos de vista alternativos.'
  },
  {
    term: '<b>Populismo</b>',
    definition:
      'Forma de discurso que simplifica la realidad para conseguir apoyo popular y que un mensaje político penetre con mayor facilidad entre los votantes. Ofrece soluciones simplistas (y por tanto falsas) a problemas que en realidad son muy complejos.'
  },
  {
    term: '<b>Pseudofuente</b>',
    definition:
      'Las pseudofuentes son fuentes de información falsas o engañosas que se presentan como verdaderas, con el objetivo de difundir desinformación y manipular la opinión pública.'
  },
  {
    term: '<b>QAnon</b>',
    definition:
      'Personaje anónimo que se identifica como ‘Q’ y que forma parte de una teoría de la conspiración en Estados Unidos. Nació en foros de Internet para revelar supuestos secretos del Gobierno y difundir teorías nunca probadas.'
  },
  {
    term: '<b>Smishing</b>',
    definition:
      'Técnica de estafa en la que los estafadores envían mensajes SMS fraudulentos o engañosos a los usuarios, generalmente haciéndose pasar por una entidad legítima, con el objetivo de obtener información personal o financiera.'
  },
  {
    term: '<b>Spam</b>',
    definition:
      'El spam o correo basura hace referencia a los mensajes de correo electrónico, SMS o llamadas no deseadas por los usuarios. Estos mensajes pueden llegar en cantidades masivas e indeseadas.'
  },
  {
    term: '<b>Suplantación de identidad</b>',
    definition:
      'Técnica de desinformación que consiste en hacer creer al usuario que el mensaje ha sido enviado por alguien de confianza, normalmente un personaje público o un organismo oficial.'
  },
  {
    term: '<b>Wayback Machine</b>',
    definition:
      'Es una herramienta que realiza una copia continua de las páginas web de Internet. Los verificadores la utilizan para comprobar si un sitio web ha publicado información falsa en el pasado, pero luego la eliminó o la cambió.'
  }
];
