import React from 'react';
import { Text, View, TouchableWithoutFeedback, StyleSheet, ImageBackground } from 'react-native';
import { bottomMenuItemActiveProfile, bottomMenuItemProfile } from '../../../constants/Images';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';

interface BottomMenuItemProps {
  text: string;
  active?: boolean;
  handleStep: () => void;
}

const BottomMenuItem = ({ text, active = false, handleStep }: BottomMenuItemProps) => {
  const style = StyleSheet.create({
    container: {
      width: '28%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      cursor: 'pointer'
    },
    imageItem: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    textItem: {
      fontWeight: '600',
      fontSize: isNativeOrPWA ? adjust(20) : adjust(24),
      color: '#FFF',
      textAlign: 'center'
    }
  });
  return (
    <TouchableWithoutFeedback onPress={() => handleStep()}>
      <View style={style.container}>
        <ImageBackground source={active ? bottomMenuItemActiveProfile : bottomMenuItemProfile} resizeMode="stretch" style={style.imageItem}>
          <Text style={style.textItem}>{text}</Text>
        </ImageBackground>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default BottomMenuItem;
