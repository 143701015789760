import { TextStyle } from 'react-native';

export enum HTMLTypeElements {
  ANCHOR = 'anchor',
  BOLD = 'bold',
  ITALIC = 'italic',
  ORDEREDLIST = 'orderedList',
  UNORDEREDLIST = 'unorderedList'
}

interface Result {
  type: HTMLTypeElements | 'normal';
  index: number;
  text: string;
  parameter?: string;
  listIndex?: number;
  listLast?: boolean;
  style: TextStyle[];
  endIndex: number;
}

export interface ResultHTML extends Result {
  parent: boolean;
}

type Styles = Partial<Record<HTMLTypeElements, TextStyle | TextStyle[]>>;

export interface HTMLResult {
  parent: ResultHTML;
  childs: ResultHTML[];
}

const regexForHTMLTypes: Record<HTMLTypeElements, RegExp> = {
  anchor: /<a\s+href=["|'](.*?)["|']>(.*?)<\/a>/gm,
  bold: /<b>(.*?)<\/b>/gm,
  italic: /<i>(.*?)<\/i>/gm,
  orderedList: /<ol>(.*?)<\/ol>/gm,
  unorderedList: /<ul>(.*?)<\/ul>/gm
};

const replaceAllTagsRegex = /<(?!br\s*\/?)[^>]+>/g;

/**
 * Removes all <br> tag in the text and transfroms to \n
 * @param text text to be parsed
 * @returns text with <br> replaced by \n
 */
function removeNextLineTag(text: string) {
  if (text && text?.length > 0) {
    return text?.replace(/<br>/g, '\n');
  }
  return text;
}

/**
 * Parse a plain text that contains html into a group of elements with appropiate style
 * @param plain text to be parsed
 * @param htmlStyles styles to each element that want to be parsed
 * @returns Group of elements in order and grouped to be renderened in different Text componenets with the correct style
 */
export function parseHTML(plain: string, htmlStyles: Styles): HTMLResult[] {
  //Plain text in one line
  const plainParsed = plain.replace(/(\r\n|\n|\r)/gm, '');
  //Only parse tags who have styles
  const neededHTMLTags = Object.keys(htmlStyles) as HTMLTypeElements[];
  const entries = Object.entries(regexForHTMLTypes);
  const necesaryEntries = entries.filter(([type]) => [...neededHTMLTags].includes(type as HTMLTypeElements));
  //Execute each regex in to the text
  const parsedEntries = necesaryEntries.map(entry => parseTag(plainParsed, entry, htmlStyles)).flat();
  //Search inside each result obtained from parser
  const searchInside = parsedEntries.map(parseInsideTag).flat();

  //Order items in order to render in the correct order
  const sortedEntries = searchInside.sort((itemA, itemB) => {
    const { index: indexA, type: typeA } = itemA;
    const { index: indexB, type: typeB } = itemB;
    const result = indexA - indexB;
    if (result !== 0) {
      return result;
    } else {
      if (typeA === HTMLTypeElements.ORDEREDLIST || typeA === HTMLTypeElements.UNORDEREDLIST) return -1;
      if (typeB === HTMLTypeElements.ORDEREDLIST || typeB === HTMLTypeElements.UNORDEREDLIST) return 1;
      return 0;
    }
  });

  //Add the elements who dont have any tag
  const groupedText = groupText(plainParsed, sortedEntries);

  //Group elements in parent and child structure, in order to inherit styles
  const groupedElements = groupElements(groupedText);

  return groupedElements;
}

/**
 * Modify the original text, removes the parts which are parsed by another regex
 * @param result Element processed by the appropiate parser
 * @returns An array with the correct index and text updated
 */
function parseInsideTag(result: Result): Result[] {
  const { text, index, type, listIndex, parameter, style, endIndex, listLast } = result;
  //Check if no contains any tag inside
  if (text && !/<[a-z][\s\S]*>/i.test(text)) {
    return [result];
  }

  //split elements who dont have any tag
  const items = text.split(/<[a-z][\s\S]*>/gi);
  //Keep text without any tag inside
  const replacedText = text.replace(/<.*?>(.*?)<\/.*?>/gm, '$1');

  //Remove duplicates and add separate text elements to the rest
  const processIndex: Result[] = [...new Set(items)].map(item => {
    const result = {
      index: replacedText.indexOf(item) + index,
      text: item,
      type,
      listIndex,
      parameter,
      style,
      endIndex: endIndex,
      listLast
    };
    return result;
  });

  return processIndex;
}

/**
 * Execute one regex into the plain text
 * @param plain text to be parsed
 * @param entry array with the type of tag and the regex to parse the text
 * @param styles all styles that will be aplied to the text
 * @returns a group of result with all needed params
 */
function parseTag(plain: string, entry: [string, RegExp], styles: Styles): Result[] {
  const [type, regex] = entry;

  const result: Result[] = [];

  let lastMatch: RegExpExecArray | null;

  const plainReduced = plain.replace(replaceAllTagsRegex, '');

  //For each match in the text
  while ((lastMatch = regex.exec(plain)) !== null) {
    if (lastMatch) {
      processMatch(lastMatch, type as HTMLTypeElements, styles, plain).forEach(match => {
        const { text, index } = match;
        const plainAcoted = plain.slice(index);
        const plainTransformWihoutTags = plainAcoted.replace(replaceAllTagsRegex, '');
        const textReplacedWithoutTags = text.replace(replaceAllTagsRegex, '');
        //Get the index in the plain text without tags
        const indexFinded = plainReduced.indexOf(plainTransformWihoutTags);

        result.push({
          ...match,
          index: indexFinded,
          type: type as HTMLTypeElements,
          endIndex: indexFinded + textReplacedWithoutTags.length
        });
      });
    }

    if (!regex.global) break;
  }

  return result;
}

/**
 * Group elements to keep the styles with inherit
 * @param entries all entries ordered and parsed
 * @returns group of results with a parent and a group of childs
 */
function groupElements(entries: Result[]): HTMLResult[] {
  const visitedElements: Result[] = [];
  const groupedElements: ResultHTML[][] = entries.map(outsideElement => {
    //if (visitedElements.some(elem => elementEndIndex === endIndex && elementIndex === index)) return [];
    if (visitedElements.some(elem => equalElement(elem, outsideElement))) return [];
    const insideEntries = entries
      .filter(insideElement => filterOnlyInsideElements(outsideElement, insideElement))
      .map(elem => {
        visitedElements.push(elem);
        return {
          ...elem,
          style: [...outsideElement.style, ...elem.style],
          parent: equalElement(elem, outsideElement)
          //parent: entryInsideIndex >= index && entryInsideEndIndex <= endIndex && entryInsideType === type && entryInsideText === text
        };
      });

    return insideEntries;
  });

  const removeEmptyElements = groupedElements.filter(elem => elem.length > 0);

  const classified = removeEmptyElements.map(entry => {
    const parent = entry.find(element => element.parent);
    const childs = entry.filter(entry => !entry.parent && entry.text);
    return { parent: parent!, childs };
  });

  return classified;
}

/**
 * Check if one element has elements inside
 * @param outsideElement
 * @param insideElement
 * @returns true if insideElement is inside outsideElement
 */
function filterOnlyInsideElements(outsideElement: Result, insideElement: Result) {
  const { index: outsideElementIndex, endIndex: outsideElementEndIndex } = outsideElement;
  const { index: insideElementIndex, endIndex: insideElementEndIndex } = insideElement;
  return insideElementIndex >= outsideElementIndex && insideElementEndIndex <= outsideElementEndIndex;
}

/**
 * Check if two Results are the same
 * @param element1
 * @param element2
 * @returns true if are the same, false otherwise
 */
function equalElement(element1: Result, element2: Result) {
  const {
    index: element2Index,
    endIndex: element2EndIndex,
    type: element2Type,
    text: element2Text,
    listIndex: element2ListIndex,
    listLast: element2ListLast,
    parameter: element2Parameter
  } = element2;
  const {
    index: element1Index,
    endIndex: element1EndIndex,
    type: element1Type,
    text: element1Text,
    listIndex: element1ListIndex,
    listLast: element1ListLast,
    parameter: element1Parameter
  } = element1;

  return (
    element2Index >= element1Index &&
    element2EndIndex <= element1EndIndex &&
    element2Text === element1Text &&
    element2Type === element1Type &&
    element2ListIndex === element1ListIndex &&
    element2ListLast === element1ListLast &&
    element2Parameter === element1Parameter
  );
}

/**
 * Get the principal properties for each type of tags
 * @param match result of execute the regex in the text
 * @param type type of tag
 * @param styles styles to be applited to the text
 * @param plain text to be parsed
 * @returns Properties that is needed to be process the text
 */
function processMatch(
  match: RegExpExecArray,
  type: HTMLTypeElements,
  styles: Styles,
  plain: string
): Array<Omit<Result, 'type' | 'endIndex'>> {
  const { index } = match;
  const style = [styles[type]] as TextStyle[];
  switch (type) {
    case HTMLTypeElements.ANCHOR: {
      const [, parameter, text] = match;
      return [{ parameter, text, index, style }];
    }
    case HTMLTypeElements.BOLD: {
      const [, text] = match;
      return [{ text, index, style }];
    }
    case HTMLTypeElements.ITALIC: {
      const [, text] = match;
      return [{ text, index, style }];
    }
    case HTMLTypeElements.ORDEREDLIST: {
      const [, text] = match;
      return processListMatch(text, plain, style) || [];
    }
    case HTMLTypeElements.UNORDEREDLIST: {
      const [, text] = match;
      return processListMatch(text, plain, style) || [];
    }
  }
}

/**
 * Process the ordered list and unordered list items
 * @param text all text inside ordered list or unordered list
 * @param plain text to be parsed
 * @returns neccesary props to parse the text
 */
function processListMatch(text: string, plain: string, style: TextStyle[]): Array<Omit<Result, 'type' | 'endIndex'>> {
  const items = text.match(/<li>(.*?)<\/li>/g);
  const parsedItems = items
    ?.map(item => `${item.replace(/<li>(.*?)<\/li>/, '$1')}`)
    .map((item, index) => ({
      text: item,
      index: plain.indexOf(item),
      listIndex: index + 1,
      style: style,
      listLast: items.length - 1 === index
    }));

  return parsedItems ?? [];
}

/**
 * Join all the elements
 * @param plain text to be parsed
 * @param sortedEntries result of parsed all elements
 * @returns group of all elements
 */
function groupText(plain: string, sortedEntries: Result[]): Result[] {
  const result: Result[] = [];
  let startIndex = 0;
  const plainReplaced = plain.replace(replaceAllTagsRegex, '');

  sortedEntries.forEach(entry => {
    const { index, text } = entry;
    const normalText = plainReplaced.slice(startIndex, index);

    if (normalText && normalText.length > 0) {
      result.push(
        {
          text: removeNextLineTag(normalText),
          type: 'normal',
          style: [],
          endIndex: index,
          index: startIndex
        },
        { ...entry, text: removeNextLineTag(text) }
      );
    } else {
      result.push({ ...entry, text: removeNextLineTag(text) });
    }
    startIndex += text.length + normalText.length;
  });

  const endText = plainReplaced.slice(startIndex);

  endText &&
    endText.length > 0 &&
    result.push({
      text: removeNextLineTag(endText),
      type: 'normal',
      style: [],
      endIndex: startIndex + removeNextLineTag(endText).length,
      index: startIndex
    });
  return result;
}
