import { addTweetReaction, clearTweetReactionBySide } from '../../../redux/GameBoard/tweets';
import store from '../../../redux/store';
import { PlayerTypeEnum } from '../../constants/player';
import { ActorTweetEnum } from '../../dtos/actor.dto';
import { ReactionEventEnum } from '../../dtos/reaction-configuration.dto';
import { TweetsReactionConfiguration } from '../../dtos/tweet-reaction-configuration.dto';
import { filterReactions } from '../utils';
import { filterAvatarDamagedDialoguesTweet } from './tweet-filters';

export interface ReactionManagementTweet {
  reactionsConfiguration: TweetsReactionConfiguration[];
  setMessage: Function;
}

interface TweetReactions {
  reactionEvent: ReactionEventEnum;
  reactionContext: ReactionManagementTweet;
}

export function launchTweetReactions(reactionContext: ReactionManagementTweet, reactionEvents: ReactionEventEnum[]): void {
  if (!reactionContext.reactionsConfiguration.length) return;
  for (const reactionEvent of reactionEvents) {
    launchTweetReaction({
      reactionContext,
      reactionEvent
    });
  }
}

export function launchTweetReaction(launchReaction: TweetReactions): void {
  const { reactionEvent, reactionContext } = launchReaction;
  const reactionsFiltered = filterReactions(reactionContext.reactionsConfiguration, reactionEvent);
  const copyReactionContext = { ...reactionContext, reactionsConfiguration: reactionsFiltered };

  const processReactions = (player: PlayerTypeEnum) => {
    const reactions = filterAvatarDamagedDialoguesTweet(copyReactionContext, player);
    const reactionsToLaunch = reactions.map(reactionConfig => reactionConfig.reactions).flat();
    reactionsToLaunch.forEach(reaction => store.dispatch(addTweetReaction(reaction)));
  };

  switch (reactionEvent) {
    case ReactionEventEnum.PLAYER_AVATAR_DAMAGED: {
      store.dispatch(clearTweetReactionBySide(ActorTweetEnum.PLAYER));
      processReactions(PlayerTypeEnum.PLAYER);
      break;
    }
    case ReactionEventEnum.OPPONENT_AVATAR_DAMAGED: {
      store.dispatch(clearTweetReactionBySide(ActorTweetEnum.OPPONENT));
      processReactions(PlayerTypeEnum.OPPONENT);
      break;
    }
  }
}
