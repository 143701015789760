import { StyleSheet } from 'react-native';
import { View } from '../../../commons/components/Themed';
import { ActorEnum } from '../../../commons/dtos/actor.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { Reaction } from '../../../commons/dtos/reaction.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import store from '../../../redux/store';
import MessagePosition from './MessagePosition';

interface MessageContainerProps {
  message?: Reaction | null;
  next: Function;
}
const MessageContainer = ({ message, next }: MessageContainerProps) => {
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      width: '7.5%',
      height: isNativeOrPWA ? '78%' : '85%',
      left: '5%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      textAlign: 'center'
    }
  });

  let opponentMsg, playerMsg;
  const {
    profile: { player }
  } = store.getState();
  const playerName = player!.playerName || '';

  if ([ActorEnum.PLAYER, ActorEnum.AGENT_F].includes(message?.actor as ActorEnum)) {
    playerMsg = typeof message?.text === 'string' ? message?.text : '';
    playerMsg = playerMsg?.toString().replace('[PLAYER]', playerName);
  } else {
    opponentMsg = typeof message?.text === 'string' ? message?.text : '';
    opponentMsg = opponentMsg?.toString().replace('[PLAYER]', playerName);
  }

  return (
    <View pointerEvents="none" style={styles.container}>
      <MessagePosition next={next} nativeID={PlayerRoundEnum.OPPONENT} message={opponentMsg} />
      <MessagePosition next={next} nativeID={PlayerRoundEnum.PLAYER} message={playerMsg} />
    </View>
  );
};
export default MessageContainer;
