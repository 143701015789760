import { AVPlaybackStatus, ResizeMode, Video } from 'expo-av';
import { useEffect, useRef, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, ImageBackground } from 'react-native';
import { Dialogue } from '../../../commons/dtos/dialog.dto';
import { landingSection2Bg, missionDialogAnimationPoster } from '../../../constants/Images';
import { missionDialogAnimation } from '../../../constants/Videos';

interface AnimationProps {
  animation?: string;
  interlocutor?: Dialogue | null;
  next: Function;
}

const Animation = ({ next, animation }: AnimationProps) => {
  const handleStep = () => {
    next();
  };
  const [status, setStatus] = useState<AVPlaybackStatus>();

  useEffect(() => {
    if (!(status && 'didJustFinish' in status && status.didJustFinish)) return;
    const to = setTimeout(handleStep, 1000);
    return () => clearTimeout(to);
  }, [status]);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1000,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden'
    },
    video: {
      height: '100%',
      width: '100%',
      maxWidth: 960,
      maxHeight: 540
    }
  });
  const video = useRef(null);
  if (!animation) return <></>;
  return (
    <TouchableWithoutFeedback onPress={handleStep}>
      <ImageBackground source={landingSection2Bg} style={styles.container}>
        <Video
          pointerEvents="none"
          ref={video}
          style={styles.video}
          source={missionDialogAnimation[animation]}
          useNativeControls={false}
          isMuted
          shouldPlay
          posterSource={missionDialogAnimationPoster[animation]}
          resizeMode={ResizeMode.CONTAIN}
          onPlaybackStatusUpdate={status => setStatus(() => status)}
        />
      </ImageBackground>
    </TouchableWithoutFeedback>
  );
};

const AnimationWrapper = ({ interlocutor, next, animation }: AnimationProps) =>
  interlocutor === null ? <Animation interlocutor={interlocutor} next={next} animation={animation} /> : null;

export default AnimationWrapper;
