import { realTwitterAvatars } from '../../constants/Images';
import type { Card } from '../dtos/card.dto';
import { EnhanceEffect, TweetsImages } from '../dtos/enhance.dto';
import { Tweet } from '../dtos/tweet.dto';
import { randomIntFromInterval } from '../utils';

/**
 * Change the Faketext attribute, replacing or appending text to the original with a single string or a list of string
 * @param card card to modify fakeText attribute
 * @param text text that will be replaced or pushed at the end
 * @param append true append text and the end, false will replace fakeText
 */
const changeFakeText = (card: Card, enhancedText: string[], append: boolean): void => {
  const { fakeText } = card;
  const selectedText = enhancedText.at(randomIntFromInterval(0, enhancedText.length - 1))!;
  card.fakeText = append ? `${fakeText} ${selectedText}` : selectedText;
};

/**
 * Change the tweet action specified in key and increase it by a specified number
 * @param card card to modity tweet attribute
 * @param key key of Tweet actions (responses, likes, retweets)
 * @param number number to increment
 */
const changeTweetsActions = (card: Card, key: keyof Pick<Tweet, 'responses' | 'likes' | 'retweets'>, number: number): void => {
  if (!card || !card.tweet) return;
  card.tweet[key] = card.tweet[key] + number;
};

/**
 * Replace current tweet user by a list of string or single string
 * @param card card to modify user of tweet attribute
 * @param users text or list of texts to replace original user
 */
const replaceUserTweet = (card: Card, users: string[]): void => {
  const selectedText = users.at(randomIntFromInterval(0, users.length - 1))!;
  card.tweet.user = selectedText;
  const image = realTwitterAvatars[selectedText.replace('@', '').toUpperCase().trim() as TweetsImages];
  card.tweet.image = image;
};

/**
 * Apply all enhance effects to the target card
 * @param card card selected to be updated
 * @returns card updated with all enhaced effects applied
 */
export const selectEnhanceEffect = (card: Card): Card => {
  const cardCopy = { ...card };
  const { enhance: enhances } = cardCopy;
  if (!enhances) return cardCopy;

  Object.values(enhances).forEach(enhance => {
    const { enhanceEffect: enhanceEffects } = enhance;
    if (!enhanceEffects || enhance.appliedEnhance) return;
    enhanceEffects.forEach(enhanceEffect => {
      const { effect, condition, max, min, enhancedText } = enhanceEffect;
      const random = randomIntFromInterval(min ?? 0, max ?? 12);
      if (condition && condition !== cardCopy.subject?.subjectName) return;
      switch (effect) {
        case EnhanceEffect.INCREASE_COMMENTS:
          return changeTweetsActions(cardCopy, 'responses', random);
        case EnhanceEffect.INCREASE_LIKES:
          return changeTweetsActions(cardCopy, 'likes', random);
        case EnhanceEffect.INCREASE_RETWEETS:
          return changeTweetsActions(cardCopy, 'retweets', random);
        case EnhanceEffect.APPEND_FAKE_TEXT_RANDOM:
          return changeFakeText(cardCopy, enhancedText ?? [''], true);
        case EnhanceEffect.REPLACE_FAKE_TEXT:
          return changeFakeText(cardCopy, enhancedText ?? [''], false);
        case EnhanceEffect.REPLACE_USER_FAKE:
          return replaceUserTweet(cardCopy, enhancedText ?? ['']);
      }
    });
    enhance.appliedEnhance = true;
  });

  return cardCopy;
};
