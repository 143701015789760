import { useRef } from 'react';
import { TouchableWithoutFeedback, StyleSheet, Animated, Platform, Easing, View } from 'react-native';
import useUnits from 'rxn-units';
import SelectedCard from '../../commons/components/Card/CardTypes/SelectedCard';
import { CardSizesEnum } from '../../commons/components/Card/utils';
import { Card as CardType } from '../../commons/dtos/card.dto';
import { isNativeOrPWA } from '../../commons/utils';
import { rewardCard } from '../../constants/Images';
import { CardReveal } from './useReward';

interface RewardCardProps {
  card: Required<CardReveal> | undefined;
  isOne?: boolean;
  handleReveal: (card: Required<CardReveal>) => void;
  handleSelectModal: (card: CardType) => void;
}

const defaultAnimation: Pick<Animated.TimingAnimationConfig, 'useNativeDriver' | 'duration' | 'easing'> = {
  useNativeDriver: Platform.OS !== 'web',
  duration: 500,
  easing: Easing.bezier(0.68, 0.09, 0.09, 0.73)
};

const RewardCard = ({ card, isOne = false, handleReveal, handleSelectModal }: RewardCardProps) => {
  const { vw } = useUnits();
  const position = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(1)).current;

  const handleClick = (card: Required<CardReveal>) => {
    if (!card || card.reveal) return;
    Animated.timing(position, {
      toValue: -100,
      ...defaultAnimation
    }).start();
    Animated.timing(opacity, {
      toValue: 0,
      ...defaultAnimation
    }).start(() => {
      handleReveal(card);
      handleSelectModal(card);
      Animated.timing(position, {
        toValue: 0,
        ...defaultAnimation
      }).start();
      Animated.timing(opacity, {
        toValue: 1,
        ...defaultAnimation
      }).start();
    });
  };

  const proportionCard = 1.4;

  const style = StyleSheet.create({
    image: {
      maxWidth: isNativeOrPWA ? 177 : 390,
      maxHeight: isNativeOrPWA ? 177 * proportionCard : 390 * proportionCard,
      width: vw(isNativeOrPWA ? 18 : 15.5),
      height: vw(isNativeOrPWA ? 25 : 15.5 * proportionCard),
      minWidth: isNativeOrPWA ? 90 : 190,
      minHeight: isNativeOrPWA ? 180 : 190 * proportionCard,
      marginHorizontal: isNativeOrPWA ? 16 : 48
    },
    oneCard: {
      minWidth: 210,
      minHeight: 210 * proportionCard,
      width: vw(15.5),
      height: vw(15.5) * proportionCard,
      maxHeight: 400 * proportionCard,
      maxWidth: 400
    }
  });

  const styleToRender = isOne ? style.oneCard : style.image;

  if (!card) {
    return <Animated.Image source={rewardCard} resizeMode="stretch" />;
  }

  if (!card.reveal) {
    return (
      <TouchableWithoutFeedback onPress={() => handleClick(card)}>
        <Animated.Image
          source={rewardCard}
          resizeMode="stretch"
          style={[styleToRender, { opacity: opacity, transform: [{ translateY: position }] }]}
        />
      </TouchableWithoutFeedback>
    );
  }

  return (
    <TouchableWithoutFeedback onPress={() => handleSelectModal(card)}>
      <View style={styleToRender}>
        <SelectedCard card={card} cardSize={CardSizesEnum.VERY_BIG} />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default RewardCard;
