export const tweetMsgs = [
  '¡Esto es simplemente ALUCINANTE!😮😮😮¡¡¡Qué engañados nos tenían!!! Comparte entre todos tus contactos, ¡esto tiene que saberse!',
  '¡Y durante tanto tiempo hemos estado sin saberlo! 😵😵 Esto no lo verás en las televisiones!! ¿Por qué? 😡 #MentiraInformativa #Increíble',
  'Si esto te parece 🤪no sabes lo que pasa ahí fuera. El #establishment no quiere que sepamos la verdad. ¡Nos quieren silenciados como 🐑🐑!',
  '🔴¿Y nadie ha dicho nada sobre esto todavía? COMPARTE. COMPARTE. COMPARTE.',
  '😮😮 NO ME LO PUEDO CREER!! 😮😮#Unbelievable',
  'Pero, pero, pero… ¡Qué engañados estábamos! 😵😵',
  'Ahora vendrán esos que verifican a decir que esto es un bulo 🤥. Tu opinión es tuya y que se dejen de datos.',
  'Ellos tendrán sus fuentes pero yo tengo las mías. Se confirma lo que ya creía. 🤥👏👏👏👏👏👏',
  '🙀🙀🙀 Me lo acaba de enviar un amigo. Increíble pero cierto!!! Pásalo!! 👉👉👉👉#OnFire🔥🔥',
  '🤥Mentirosos profesionales🤥. De verdad crees en la “ciencia”? Hoy es cierto y mañana no #QueNoTeControlen #FakeCiencia',
  'Otra basura que me quiere vender el telediario. No me creo más sus “mentiras oficiales”.',
  '¡¡¡Se ríen en tu cara!!! ESTO nunca lo encontrarás en los periódicos. ¿Conspiración? 😶',
  '🔴¡Estoy atónita! 😱😱 ¿Cómo es posible que esto ocurra? BUAHH',
  'Y también dicen que la Tierra se está calentando y este invierno estuvimos a -20 grados.. Mentiras, mentiras y más mentiras..',
  '😠😠 No me creo nada ya. Seguro que me censuran 😨 porque no quieren que despertemos.',
  '🔴 Léelo antes de que lo borren de redes sociales!! No quieren que lo sepas!! 😡 #Exclusiva #Censura',
  '🔴 #URGENTE #MINDBREAKING Se descubre ahora tras años de engaños y mentiras 🙀🙀. Los gobiernos lo sabían y nos lo ocultaron.',
  'Y todo esto está pasando desde que nos vacunaron del COVID. No tengo pruebas pero tampoco dudas. #LasVacunasMatan',
  '🙀Si no lo veo, no lo creo. Comparto!!',
  'Las elecciones están trucadas, nos mienten con eso del cambio climático, nos encerraron inventándose un virus respiratorio… #TodoEsMentira',
  '+1 +1 +1 +1 Nunca lo hubiera imaginado!!!!!',
  'Corre! 🏃No quieren que lo veas 👀.',
  '👉 👉 👉 ¡¡Esto va para el grupo de Guasap de mis amiguis pero ya!! 👉 👉 👉',
  'Y los expertos de la tele decían que esto no pasaba. ¡Yo sabía que tenía razón! #SoyMásListoQueNadie',
  'Los de #LaVerdadQueNoConoces no paran de sacar secretos increíbles como este. ¡¡Síguelos!!',
  '!!Más como esto sin censuras en mi blog: http://www.estaeslaverdad.trust.me. COMPARTELO!!',
  'Nunca lo hubiera creído… 😱😱😱',
  'Lo sabía!! 😡😡😡😡 Ni mi madre me creía!'
];
