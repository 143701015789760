// eslint-disable-next-line no-shadow
export enum SubjectsEnum {
  SCIENCE = 'ciencia',
  HATE = 'discriminación',
  CONSPIRACY = 'teoría conspiración',
  SCAM = 'estafa',
  NEUTRAL = 'neutral'
}

/**
 * Subject variable type declaration.
 *
 * @interface
 */
export class Subject {
  id?: string;

  subjectName!: SubjectsEnum;

  constructor(properties?: SubjectProperties) {
    if (properties) {
      this.subjectName = properties.subjectName;
    }
  }
}

export interface SubjectProperties {
  id?: string;
  subjectName: SubjectsEnum;
}
