import { useState } from 'react';
import { StyleSheet, View, Image, TouchableWithoutFeedback } from 'react-native';
import { settingsIcon } from '../../../constants/Images';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';
import FullScreenWebButton from '../FullScreenWebButton/FullScreenWebButton';
import OptionsMenuModal from '../OptionsMenuModal/OptionsMenuModal';

interface TopHeaderProps {
  surrenderHandler: Function;
}

const GameHeader = ({ surrenderHandler }: TopHeaderProps) => {
  const { rh, rw } = useRatioDimensions();
  const [showConfig, setShowConfig] = useState<boolean>();

  const styles = StyleSheet.create({
    topContainer: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      height: '20%',
      maxHeight: 120,
      paddingVertical: rh(2),
      paddingHorizontal: isNativeMobile ? 30 : 10,
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      elevation: 1
    },
    buttonContainer: {
      marginHorizontal: rw(0.5)
    },
    button: {
      padding: rw(1),
      paddingBottom: isNativeOrPWA ? rw(1.3) : rw(1),
      cursor: 'pointer'
    },
    buttonIcon: { height: isNativeOrPWA ? 18 : 32, width: isNativeOrPWA ? 18 : 32 }
  });

  return (
    <>
      <View style={styles.topContainer}>
        <FullScreenWebButton iconMode />
        <View style={styles.buttonContainer}>
          <TouchableWithoutFeedback onPress={() => setShowConfig(true)}>
            <View>
              <ButtonShape square bgColor="black" border heightBtn={isNativeOrPWA ? 34 : 56}>
                <View style={styles.button}>
                  <Image style={styles.buttonIcon} source={settingsIcon} resizeMode={'contain'}></Image>
                </View>
              </ButtonShape>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
      {showConfig && <OptionsMenuModal surrenderHandler={surrenderHandler} fromGame closeModal={() => setShowConfig(false)} />}
    </>
  );
};

export default GameHeader;
