import { StyleSheet, Image, ImageBackground, TouchableWithoutFeedback, ViewStyle } from 'react-native';
import OvniWithAstronaut from '../../assets/images/components/OvniWithAstronaut';
import { closeIcon, irregularModalContainer } from '../../constants/Images';
import { Card as CardType } from '../dtos/card.dto';
import { PlayerRoundEnum } from '../dtos/player.dto';
import { isNativeMobile, isNativeOrPWA } from '../utils';
import { useRatioDimensions } from './AspectRatioView/useRatioDimensions';
import ButtonShape from './ButtonShape/ButtonShape';
import CardsListViewer from './CardsListViewer';
import { View, Text } from './Themed';
interface ErrorModalProps {
  message: string;
  buttons?: string[];
  cards?: CardType[];
  onButtonPress: Function[];
  onCloseModal: Function;
  onCardPressed?: (card: CardType, side: PlayerRoundEnum, position: ViewStyle) => void;
  onLeftoverCard?: Function;
}
const ErrorModal = (props: ErrorModalProps) => {
  const { rw } = useRatioDimensions();

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    modal: {
      width: rw(50),
      height: rw(30),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center'
    },
    modalSize: {
      maxWidth: 600
    },
    modalBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    spaceCraftContainer: {
      width: '100%',
      height: '80%',
      backgroundColor: 'transparent',
      transform: [{ rotate: '-10deg' }],
      position: 'absolute',
      top: '-22%'
    },
    modalText: { zIndex: 2300, fontWeight: '600', width: '60%', textAlign: 'center', fontSize: rw(1.4) },
    closeContainer: {
      zIndex: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: rw(0.5),
      right: rw(4)
    },
    cardsContainer: {
      marginTop: '5%',
      height: '25%',
      width: '40%',
      position: 'absolute',
      bottom: '15%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    buttonsContainer: {
      height: '30%',
      width: '100%',
      position: 'absolute',
      bottom: '15%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center'
    },
    modalButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      marginVertical: rw(0.2),
      marginHorizontal: 5
    },
    modalButtonText: { fontWeight: '900', color: '#313131', fontSize: rw(1.2), paddingHorizontal: 5 },
    button: {
      padding: rw(1),
      cursor: 'pointer'
    },
    closeImage: { height: isNativeOrPWA ? 14 : 22, width: isNativeOrPWA ? 14 : 22 }
  });

  return (
    <View style={styles.container}>
      <View style={[styles.modal, isNativeMobile && styles.modalSize]}>
        {!props.cards && (
          <View style={styles.closeContainer}>
            <TouchableWithoutFeedback onPress={() => props.onCloseModal()}>
              <View>
                <ButtonShape square bgColor="black" border heightBtn={isNativeOrPWA ? 34 : 56}>
                  <View style={styles.button}>
                    <Image style={styles.closeImage} source={closeIcon} resizeMode="contain"></Image>
                  </View>
                </ButtonShape>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
        <ImageBackground style={styles.modalBackground} source={irregularModalContainer} resizeMode={'stretch'}></ImageBackground>
        <Text style={styles.modalText}>{props.message}</Text>
        <OvniWithAstronaut parentStyle={styles.spaceCraftContainer} />
        {props.buttons && (
          <View style={styles.buttonsContainer}>
            {props.buttons.map((button, index) => (
              <TouchableWithoutFeedback key={index} onPress={() => props.onButtonPress[index]()}>
                <View style={styles.modalButtonContainer}>
                  <ButtonShape heightBtn={isNativeOrPWA ? 34 : 52}>
                    <Text style={styles.modalButtonText}>{button}</Text>
                  </ButtonShape>
                </View>
              </TouchableWithoutFeedback>
            ))}
          </View>
        )}
        {props.cards && (
          <View style={styles.cardsContainer}>
            <CardsListViewer onCardPressed={props.onCardPressed} onLeftoverCard={props.onLeftoverCard} cards={props.cards} />
          </View>
        )}
      </View>
    </View>
  );
};

export default ErrorModal;
