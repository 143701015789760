import { LinearGradient } from 'expo-linear-gradient';
import { memo, useEffect, useRef } from 'react';
import { Animated, StyleSheet, Text, ImageBackground, ImageSourcePropType, Platform } from 'react-native';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';
import Dots from './Dots';

interface LoadingMissionProps {
  background: ImageSourcePropType;
}

const LoadingMission = ({ background }: LoadingMissionProps) => {
  const opacityBg = useRef<Animated.Value>(new Animated.Value(0)).current;

  const selectedMissionAnimation = Animated.timing(opacityBg, {
    toValue: 1,
    useNativeDriver: Platform.OS !== 'web',
    duration: 500
  });

  useEffect(() => {
    selectedMissionAnimation.start();
  }, []);

  const AnimatedImage = Animated.createAnimatedComponent(ImageBackground);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1000,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden'
    },
    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%'
    },
    textTitleStyle: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(42) : 64,
      fontFamily: 'Inlanders',
      color: '#ffffff',
      textShadowColor: '#000000',
      textShadowOffset: { width: isNativeOrPWA ? -2 : -3, height: isNativeOrPWA ? 3 : 6 },
      textShadowRadius: 0,
      marginVertical: isNativeOrPWA ? adjust(42) : 64
    }
  });

  if (!background) {
    return <></>;
  }
  return (
    <AnimatedImage
      source={background}
      style={[
        styles.container,
        {
          opacity: opacityBg
        },
        {
          transform: [
            {
              scale: opacityBg.interpolate({
                inputRange: [0, 1],
                outputRange: [0.8, 1]
              })
            }
          ]
        }
      ]}
    >
      <Text>
        <Text style={styles.textTitleStyle}>{'Cargando'}</Text>
        <Dots
          animation={{
            scale: 1.3,
            duration: 100
          }}
          style={{
            size: 8
          }}
        />
      </Text>
      <LinearGradient style={styles.gradient} colors={['black', 'transparent']} />
    </AnimatedImage>
  );
};

export default memo(LoadingMission);
