import { Dimensions } from 'react-native';

export const deviceHeight = Dimensions.get('window').height;
export const deviceWidth = Dimensions.get('window').width;

const adjust = (size: number) => {
  // catch Android font scaling on small machines
  // where pixel ratio / font scale ratio => 3:3
  if (deviceWidth <= 360) {
    return size * 0.7;
    // Catch other smaller android height sizings
  }
  if (deviceHeight < 667) {
    return size * 0.75;
    // catch in-between size Androids and scale font up
    // a tad but not too much
  }
  if (deviceHeight >= 667 && deviceHeight <= 735) {
    return size * 0.8;
  }
  // catch larger phablet devices
  return size;
};

export default adjust;
