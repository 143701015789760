import { ReactNode } from 'react';
import { View, StyleSheet, ImageBackground } from 'react-native';
import {
  buttonLeftB,
  buttonLeftBB,
  buttonLeftD,
  buttonLeftDB,
  buttonLeftG,
  buttonLeftGB,
  buttonLeftW,
  buttonLeftWB,
  buttonMidB,
  buttonMidBB,
  buttonMidD,
  buttonMidDB,
  buttonMidG,
  buttonMidGB,
  buttonMidW,
  buttonMidWB,
  buttonRightB,
  buttonRightBB,
  buttonRightD,
  buttonRightDB,
  buttonRightG,
  buttonRightGB,
  buttonRightW,
  buttonRightWB
} from '../../../constants/Images';

interface PolyShapeProps {
  children: ReactNode;
  bgColor?: 'white' | 'dark' | 'gray' | 'black';
  square?: boolean;
  border?: boolean;
  heightBtn: number;
  isPointeable?: boolean;
}

const ButtonShape = ({ children, bgColor = 'white', square = false, heightBtn, border = false, isPointeable = true }: PolyShapeProps) => {
  const sources = {
    white: { left: [buttonLeftW, buttonLeftWB], middle: [buttonMidW, buttonMidWB], right: [buttonRightW, buttonRightWB] },
    dark: { left: [buttonLeftD, buttonLeftDB], middle: [buttonMidD, buttonMidDB], right: [buttonRightD, buttonRightDB] },
    gray: { left: [buttonLeftG, buttonLeftGB], middle: [buttonMidG, buttonMidGB], right: [buttonRightG, buttonRightGB] },
    black: { left: [buttonLeftB, buttonLeftBB], middle: [buttonMidB, buttonMidBB], right: [buttonRightB, buttonRightBB] }
  };
  const sourceMid = sources[bgColor].middle[border ? 1 : 0];
  const sourceLeft = sources[bgColor].left[border ? 1 : 0];
  const sourceRight = sources[bgColor].right[border ? 1 : 0];

  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      cursor: isPointeable ? 'pointer' : 'auto',
      flexDirection: 'row',
      width: '100%'
    },
    side: {
      flexGrow: 0
    },
    left: {
      marginLeft: -1
    },
    right: {
      marginRight: -1
    },
    middle: {
      flexGrow: 1,
      flexShrink: 0,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      minWidth: heightBtn / 4
    },
    absolute: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100
    },
    childrenContainer: {
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  return (
    <View style={[styles.container, { height: heightBtn }]}>
      <ImageBackground
        style={[styles.side, styles.right, { width: square ? heightBtn / 2.5 : heightBtn / 2 }]}
        source={sourceLeft}
        resizeMode="stretch"
      />
      <ImageBackground style={styles.middle} source={sourceMid} resizeMode="stretch">
        <View style={[square && styles.absolute, styles.childrenContainer]}>{children}</View>
      </ImageBackground>
      <ImageBackground
        style={[styles.side, styles.left, { width: square ? heightBtn / 2.5 : heightBtn / 2 }]}
        source={sourceRight}
        resizeMode="stretch"
      />
    </View>
  );
};

export default ButtonShape;
