import { LinearGradient } from 'expo-linear-gradient';
import LottieView from 'lottie-react-native';
import { StyleSheet, ImageBackground, ScrollView, Image, TouchableWithoutFeedback } from 'react-native';
import { shallowEqual, useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';

import ExternalHeader from '../../commons/components/ExternalHeader/ExternalHeader';
import LegalFooter from '../../commons/components/LegalFooter';
import PolyShape from '../../commons/components/PolyShape/PolyShape';
import { Text, View } from '../../commons/components/Themed';
import adjust from '../../commons/styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA, isPWA } from '../../commons/utils';
import {
  landingBackground,
  landingSection1Bg,
  landingSection2Bg,
  landingSection3Bg,
  landingSection4Bg,
  irregularBlock,
  landingCard,
  landingCat,
  catLogin,
  landingBadgeImage
} from '../../constants/Images';
import { lottieConfetiWin } from '../../constants/Lotties';
import { authProps } from '../../redux/Auth/authRedux';
import { LandingProps } from '../../types';

const Landing = ({ navigation }: LandingProps) => {
  const { vh, vw } = useUnits();

  const { isAuthorized } = useSelector(
    ({ auth }: authProps) => ({
      isAuthorized: auth.idToken != null
    }),
    shallowEqual
  );

  const goCampaign = async () => {
    if (isAuthorized) navigation.navigate('Home');
    else navigation.navigate('Login');
  };
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'relative',
      backgroundColor: '#313131'
    },
    mainContent: {
      flexGrow: 1
    },
    section: {
      position: 'relative',
      paddingVertical: vh(10),
      paddingHorizontal: 30,
      minHeight: vh(100),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#313131',
      overflow: 'hidden'
    },
    linearGradientStyle: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 100
    },
    splitContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1280,
      margin: 'auto'
    },
    splitView: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '50%'
    },
    splitDesktopContainer: {
      flexDirection: isNativeOrPWA ? 'column' : 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: isNativeOrPWA ? 480 : 1280,
      margin: 'auto'
    },
    splitDesktopView: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: !isNativeMobile ? (isPWA ? 'auto' : '50%') : '100%'
    },
    blockCenter: {
      margin: 'auto',
      paddingHorizontal: isNativeOrPWA ? 80 : 100
    },
    block: {
      maxWidth: isNativeOrPWA ? 508 : 670,
      minWidth: 380,
      paddingHorizontal: isNativeOrPWA ? 55 : 100,
      paddingVertical: isNativeOrPWA ? 65 : 100,
      width: !isNativeMobile ? '100%' : 'auto',
      position: 'relative'
    },
    finalBlock: {
      paddingHorizontal: isNativeOrPWA ? 110 : 140,
      paddingBottom: 0,
      textAlign: 'center'
    },
    catImage: {
      position: 'absolute',
      height: 200,
      width: 400,
      bottom: -50,
      right: -100
    },
    catImageLogin: {
      position: 'absolute',
      height: 300,
      width: 250,
      bottom: -80,
      left: -60
    },
    badgeImage: {
      position: 'absolute',
      height: isNativeOrPWA ? 260 : 320,
      width: isNativeOrPWA ? 200 : 250,
      top: -20,
      right: isNativeOrPWA ? -70 : -100
    },
    padding: {
      paddingHorizontal: isNativeOrPWA ? 20 : 30
    },
    title: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(32) : adjust(44),
      fontFamily: 'Inlanders',
      color: '#ffffff',
      textShadowColor: '#000000',
      textShadowOffset: { width: isNativeOrPWA ? -2 : -3, height: isNativeOrPWA ? 3 : 6 },
      textShadowRadius: 0,
      marginVertical: isNativeOrPWA ? adjust(32) : adjust(44)
    },
    shapeContainer: {
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      justifyContent: 'center'
    },
    shapeText: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(15) : 18,
      fontWeight: 'bold',
      color: '#ffffff'
    },
    subTitle: {
      fontSize: isNativeOrPWA ? adjust(16) : adjust(18),
      fontWeight: 'bold',
      color: '#ffffff',
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      lineHeight: isNativeOrPWA ? adjust(24) : adjust(26)
    },
    landingCard: {
      height: 688,
      maxHeight: vh(140),
      width: '100%'
    },
    landingCardImage: {
      height: '100%',
      width: '100%',
      minWidth: isNativeOrPWA ? 700 : 500
    },
    paragraph: {
      fontSize: isNativeOrPWA ? adjust(16) : adjust(18),
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      lineHeight: isNativeOrPWA ? adjust(24) : adjust(26)
    },
    play: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(32) : adjust(44),
      fontFamily: 'Inlanders',
      color: '#000',
      paddingBottom: 3,
      cursor: 'pointer'
    },
    playContainer: {
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      alignItems: 'center',
      position: 'relative',
      height: isNativeOrPWA ? 50 : '100%',
      width: isNativeOrPWA ? 150 : '100%',
      maxHeight: 100,
      maxWidth: 300,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    polyContainer: {
      height: '100%',
      width: '100%'
    },
    confetti: {
      position: 'absolute',
      top: isNativeMobile ? -30 : vw(-5),
      width: vw(100),
      height: isNativeMobile ? 300 : '450%',
      backgroundColor: 'transparent'
    },
    footerContainer: {
      alignItems: 'center'
    }
  });

  return (
    <View style={styles.container}>
      <ExternalHeader withLogo current="landing" />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.mainContent}>
          <ImageBackground source={landingBackground} resizeMode="cover" style={styles.section}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['transparent', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <ImageBackground style={[styles.block, styles.blockCenter]} source={irregularBlock} resizeMode="stretch">
              <Text style={styles.title}>¿Qué es Fakesover?</Text>
              <View style={styles.shapeContainer}>
                <ButtonShape isPointeable={false} bgColor="black" heightBtn={isNativeOrPWA ? 42 : 56}>
                  <Text style={styles.shapeText}>El juego de cartas contra la desinformación</Text>
                </ButtonShape>
              </View>
              <Text style={styles.subTitle}>
                FakesOver es una iniciativa para luchar contra la desinformación impulsada por la Fad con el apoyo de Google.org.
              </Text>
              <Text style={styles.paragraph}>
                Es un juego de cartas estratégico inspirado en grandes referentes del género como HearthStone o Magic The Gathering.
                FakesOver no sólo es divertido de jugar sino que nos ayuda a comprender las técnicas que se utilizan para desinformar y nos
                da pistas sobre cómo detectarlas.
              </Text>
              <Text style={styles.paragraph}>
                Nuestro principal objetivo es sensibilizar a los más jóvenes contra este problema y ayudar a los jugadores a desarrollar
                mecanismos mentales para protegerse contra el fenómeno de la desinformación.
              </Text>
              <Image style={styles.catImage} resizeMode="contain" source={landingCat} accessible accessibilityLabel="cat image" />
            </ImageBackground>
          </ImageBackground>
          <ImageBackground style={styles.section} resizeMode="cover" source={landingSection1Bg}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View style={styles.splitContainer}>
              <View style={styles.splitView}>
                <ImageBackground style={styles.block} source={irregularBlock} resizeMode="stretch">
                  <View style={styles.shapeContainer}>
                    <ButtonShape isPointeable={false} bgColor="black" heightBtn={isNativeOrPWA ? 42 : 56}>
                      <Text style={styles.shapeText}>Juega seas quien seas</Text>
                    </ButtonShape>
                  </View>
                  <Text style={styles.subTitle}>Si tienes más de 14 años puedes jugar.</Text>
                  <Text style={styles.paragraph}>
                    Las instrucciones y dinámica del juego se han diseñado para que sea fácil de entender para cualquier persona, seas un
                    “gamer” de manual o si necesitas un manual para jugar.
                  </Text>
                </ImageBackground>
              </View>
              <View style={styles.splitView} />
            </View>
          </ImageBackground>
          <ImageBackground style={styles.section} resizeMode="cover" source={landingSection2Bg}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View style={styles.splitDesktopContainer}>
              <View style={styles.splitDesktopView}>
                <View style={styles.padding}>
                  <View style={styles.shapeContainer}>
                    <ButtonShape isPointeable={false} bgColor="black" heightBtn={isNativeOrPWA ? 42 : 56}>
                      <Text style={styles.shapeText}>Colecciona cartas y construye mazos</Text>
                    </ButtonShape>
                  </View>
                  <Text style={styles.paragraph}>
                    Entra en FakesOver y empieza a lanzar fakes con los que golpear a tu adversario mientras te proteges de tu enemigo
                    lanzando cartas de verificación.
                  </Text>
                  <Text style={styles.paragraph}>
                    Las cartas más poderosas pueden cambiar drásticamente el juego, creando potentes cadenas de efectos con las que acabar
                    con el adversario más preparado. Prueba nuevas combinaciones y sé más listo que tu oponente.
                  </Text>
                </View>
              </View>
              <View style={styles.splitView}>
                <View style={styles.landingCard}>
                  <Image
                    style={styles.landingCardImage}
                    resizeMode="contain"
                    source={landingCard}
                    accessible
                    accessibilityLabel="Example Card"
                  />
                </View>
              </View>
            </View>
          </ImageBackground>
          <ImageBackground style={styles.section} resizeMode="cover" source={landingSection3Bg}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', '#171717']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <View style={styles.splitContainer}>
              <View style={styles.splitView}>
                <ImageBackground style={styles.block} source={irregularBlock} resizeMode="stretch">
                  <View style={styles.shapeContainer}>
                    <ButtonShape isPointeable={false} bgColor="black" heightBtn={isNativeOrPWA ? 42 : 56}>
                      <Text style={styles.shapeText}>Diversión instantánea mientras aprendes</Text>
                    </ButtonShape>
                  </View>
                  <Text style={styles.paragraph}>
                    Una partida tiene una duración media de entre 5 y 10 minutos. La duración depende de muchos factores que incluyen el
                    grado de desafío de la máquina, la suerte con las cartas, la estrategia del jugador o la elección del mazo inicial.
                  </Text>
                  <Text style={styles.paragraph}>
                    Durante la partida aprenderás las principales técnicas que se utilizan para desinformar, los escenarios más comunes en
                    los que esto tiene lugar y algunas tácticas para evitar que te manipulen fácilmente.
                  </Text>
                </ImageBackground>
              </View>
              <View style={styles.splitView} />
            </View>
          </ImageBackground>
          <ImageBackground style={styles.section} resizeMode="cover" source={landingSection4Bg}>
            <LinearGradient
              style={styles.linearGradientStyle}
              colors={['#171717', 'transparent', 'transparent', 'transparent']}
              locations={[0, 0.2, 0.8, 1]}
              pointerEvents="none"
            />
            <ImageBackground style={[styles.block, styles.blockCenter, styles.finalBlock]} source={irregularBlock} resizeMode="stretch">
              <View style={styles.shapeContainer}>
                <ButtonShape isPointeable={false} bgColor="black" heightBtn={isNativeOrPWA ? 42 : 56}>
                  <Text style={styles.shapeText}>Campaña y juego libre</Text>
                </ButtonShape>
              </View>
              <Text style={styles.subTitle}>FakesOver ofrece dos modalidades de juego: campaña y juego libre.</Text>
              <Text style={styles.paragraph}>La campaña cuenta una historia compuesta de 20 misiones. ¡Atrévete a acabarla!</Text>
              <Text style={styles.paragraph}>
                El juego libre plantea nuevos desafíos contra la IA del sistema. Juega tanto como quieras, colecciona nuevas cartas y
                convertirte en un campeón de la verificación.
              </Text>
              <View style={styles.playContainer}>
                <LottieView autoPlay loop style={styles.confetti} source={lottieConfetiWin} />
                <TouchableWithoutFeedback onPress={() => goCampaign()}>
                  <View style={styles.polyContainer}>
                    <PolyShape type={2} bgColor={'white'}>
                      <Text style={styles.play}>¡Juega ya!</Text>
                    </PolyShape>
                  </View>
                </TouchableWithoutFeedback>
              </View>
              <Image style={styles.badgeImage} resizeMode="contain" source={landingBadgeImage} accessible accessibilityLabel="badgeImage" />
              <Image style={styles.catImageLogin} resizeMode="contain" source={catLogin} accessible accessibilityLabel="cat image" />
            </ImageBackground>
          </ImageBackground>
          <View style={styles.footerContainer}>
            <LegalFooter />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default Landing;
