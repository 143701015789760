export enum PlayerTypeEnum {
  PLAYER = 1,
  OPPONENT = 2
}
export enum PlayersTurnEnum {
  FIRST = 0,
  SECOND = 1
}
export enum PlayersSuffixEnum {
  FIRST = '1',
  SECOND = '2'
}
