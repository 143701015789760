import { StyleSheet } from 'react-native';
import { Card } from '../../dtos/card.dto';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import { CardSizesEnum, selectSize } from './utils';

export const useCardStyles = (card: Card, cardSize: CardSizesEnum, hasActions: boolean) => {
  const { rw, rh } = useRatioDimensions();

  const isLongCardText = card?.cardText ? card?.cardText.length > 70 : false;

  const descriptionCardText = selectSize(
    cardSize,
    rh(1.8 - (isLongCardText ? (0.1 * card.cardText.length) / 50 : 0)),
    rh(1.4 - (isLongCardText ? (0.1 * card.cardText.length) / 60 : 0) - (hasActions ? 0.2 : 0)),
    rh(1.2 - (isLongCardText ? (0.1 * card.cardText.length) / 65 : 0) - (hasActions ? 0.1 : 0)),
    rh(0.8 - (isLongCardText ? (0.1 * card.cardText.length) / 75 : 0) - (hasActions ? 0.1 : 0))
  ) as number;

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    cardBackground: { width: '100%', height: '100%' },
    cardNameContainer: {
      width: '100%',
      height: '15%',
      position: 'absolute',
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cardNameBackground: { width: '100%', height: '100%', position: 'absolute', top: 0 },
    cardName: {
      zIndex: 300,
      width: '90%',
      fontWeight: '100',
      textAlign: 'center',
      paddingRight: rw(1.5),
      fontFamily: 'Inlanders'
    },
    cardText: { fontWeight: '400', textAlign: 'center', zIndex: 100 },
    cardTextContainer: {
      width: '100%',
      height: isNativeMobile ? '27%' : '25%',
      padding: '2%',
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cardTextBackground: { width: '100%', height: '100%', position: 'absolute', bottom: 0 },
    boldText: { fontWeight: '900', width: '90%', textAlign: 'center' },
    subjectContainer: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '80%',
      height: '50%',
      left: '-22.5%'
    },
    actionPointsContainer: {
      position: 'absolute',
      zIndex: 700,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    actionPointsHighlightBlackCard: {
      borderWidth: 0,
      borderRadius: 40,
      backgroundColor: '#0fd7e6',
      filter: 'drop-shadow(0px 10px 10px rgba(15, 215, 230, 1))'
    },
    actionPointsHighlightWhiteCard: {
      borderWidth: 0,
      borderRadius: 40,
      backgroundColor: '#E9AB04',
      filter: 'drop-shadow(0px 10px 10px rgba(233, 171, 4, 1))'
    },
    subject: {
      position: 'absolute',
      width: '12%',
      height: '12%',
      right: '55%',
      top: '30%',
      zIndex: 400
    },
    pointsText: {
      fontFamily: 'Inlanders',
      textAlign: 'center',
      textAlignVertical: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    actionPointsText: {
      marginBottom: '15%'
    },
    typeContainer: {
      minWidth: '45%',
      height: '35%',
      position: 'absolute',
      top: '-17.5%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    typeContainerBorder: {
      width: '22%',
      height: '100%',
      position: 'relative'
    },
    typeContainerBorderLeft: {
      left: '1%'
    },
    typeContainerBorderRight: {
      right: '1%'
    },
    typeContainerBorderImage: {
      width: '100%',
      height: '100%'
    },
    typeContainerMiddle: {
      minWidth: '40%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    type: {
      fontWeight: '600',
      padding: rw(0.1),
      textAlignVertical: 'center',
      fontFamily: 'Inlanders'
    },
    barrierCoverContainer: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    barrierCover: {
      position: 'absolute',
      right: '-5%',
      left: '-6%',
      bottom: '-5%',
      top: '-2.5%'
    },
    undetectableCoverContainer: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#33333387',
      overflow: 'hidden',
      borderRadius: 15
    },
    undetectableCover: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      paddingTop: 0,
      transform: [{ scale: 1.4 }]
    },
    zzzCoverContainer: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#33333387',
      overflow: 'hidden',
      borderRadius: 15
    },
    zzzCover: {
      position: 'absolute',
      top: 0,
      width: isNativeMobile ? '80%' : '50%',
      height: '100%',
      paddingTop: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    actionCardContainer: {
      width: '33%',
      height: '10%',
      position: 'absolute',
      bottom: isNativeOrPWA ? '-5%' : '-4%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center'
    },
    lottieEffect: {
      position: 'absolute',
      top: '-8%',
      left: '-10%',
      width: '120%',
      height: '120%',
      backgroundColor: 'transparent',
      elevation: 25
    },
    targetLottieEffect: {
      position: 'absolute',
      top: -rw(0.5),
      left: rw(1),
      width: rw(8.5),
      height: rw(8.5),
      backgroundColor: 'transparent',
      elevation: 25
    },
    rotatedLottieEffect: {
      transform: [{ rotate: '180deg' }]
    }
  });
  const propsStyles = StyleSheet.create({
    cardName: {
      color: card.color ? '#FFFFFF' : '#000000',
      fontSize: selectSize(
        cardSize,
        card.cardName?.length > 30 ? rw(1.2) : rw(1.5),
        card.cardName?.length > 30 ? rw(0.9) : rw(1.2),
        card.cardName?.length > 20 ? rw(0.6) : rw(0.9),
        card.cardName?.length > 20 ? rw(0.5) : rw(0.7)
      ) as number,
      paddingLeft: selectSize(cardSize, rw(3.4), rw(2.6), rw(2.2), rw(1.5))
    },
    cardText: {
      color: card.color ? '#FFFFFF' : '#000000',
      fontSize: descriptionCardText
    },
    boldText: {
      color: card.color ? '#FFFFFF' : '#000000',
      fontSize: descriptionCardText
    },
    actionPointsText: {
      color: card.color ? '#FFFFFF' : '#000000'
    },
    type: {
      fontSize: selectSize(cardSize, rw(1.4), rw(1.2), rw(1), rw(0.7)) as number,
      color: '#000000'
    },
    typeContainerMiddle: {
      backgroundColor: card.color ? '#FFFFFF' : '#E1E1E1'
    }
  });
  return { styles, propsStyles };
};
