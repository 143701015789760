import { useEffect, useRef } from 'react';
import { Animated, ImageBackground, Text, Image, StyleSheet, Platform, ImageStyle } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import BattleCard from '../../../commons/components/Card/CardTypes/BattleCard';
import { CardSizesEnum, selectSize } from '../../../commons/components/Card/utils';
import type { Card as CardType } from '../../../commons/dtos/card.dto';
import { Shadow } from '../../../commons/dtos/shadow.dto';
import { isNativeMobile, isNativeOrPWA } from '../../../commons/utils';
import { battlePointsContainer, deadCardIcon, redCardVeil } from '../../../constants/Images';

interface CardBattleSideProps {
  card: CardType;
  showFire: boolean;
  damagePoints: number;
  battlePoints: number;
}

const CardBattleSide = ({ card, damagePoints, battlePoints }: CardBattleSideProps) => {
  const { rw } = useRatioDimensions();

  const fadeInCardAnim = useRef(new Animated.Value(0)).current;
  const fadeInBattleEffectAnim = useRef(new Animated.Value(0)).current;
  const deadCardRotation = useRef(new Animated.Value(0)).current;
  const pause = useRef(new Animated.Value(0)).current;

  const fadeOutCard = Animated.timing(fadeInCardAnim, {
    toValue: 0,
    duration: 300,
    useNativeDriver: Platform.OS !== 'web'
  });

  const rotateCard = Animated.timing(deadCardRotation, {
    toValue: 1,
    duration: 150,
    useNativeDriver: Platform.OS !== 'web'
  });

  const fadeInCard = Animated.timing(fadeInCardAnim, {
    toValue: 1,
    duration: 300,
    delay: 500,
    useNativeDriver: Platform.OS !== 'web'
  });

  const fadeInBattleEffect = Animated.timing(fadeInBattleEffectAnim, {
    toValue: 1,
    duration: 150,
    useNativeDriver: Platform.OS !== 'web'
  });

  const pauseAnim = Animated.timing(pause, {
    toValue: 0,
    delay: 500,
    useNativeDriver: Platform.OS !== 'web'
  });

  const rotateCardLoop = Animated.loop(rotateCard);

  useEffect(() => {
    fadeInCard.start();
    const parallel = Animated.parallel([fadeInBattleEffect, rotateCardLoop]);
    const sequence = Animated.sequence([pauseAnim, parallel]);
    sequence.start();
  }, []);

  useEffect(() => {
    if (battlePoints > 0) return;
    const to = setTimeout(() => fadeOutCard.start(), 3000);
    return () => clearTimeout(to);
  }, [battlePoints]);

  const styles = StyleSheet.create({
    card: {
      width: rw(18),
      height: rw(25.2),
      marginVertical: '1%',
      marginHorizontal: '4%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    damageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: rw(6),
      top: '-25%',
      position: 'absolute'
    },
    actionText: {
      fontFamily: 'Inlanders',
      textAlign: 'center',
      textAlignVertical: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '15%',
      color: '#FFF',
      fontSize: selectSize(CardSizesEnum.VERY_BIG, rw(3), rw(2.4), rw(1.9), rw(1.4))
    },
    pointsText: {
      position: 'absolute',
      fontSize: rw(2),
      color: '#FFFFFF',
      fontFamily: 'Inlanders',
      textAlign: 'center',
      textAlignVertical: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 200
    },
    deadCardVeilContainer: {
      position: 'absolute',
      width: '110%',
      height: '100%',
      top: 0,
      right: '5%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    deadCardVeil: {
      position: 'absolute',
      right: '-6%',
      width: '105%',
      height: '100%',
      top: '0%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    deadIconContainer: {
      position: 'absolute',
      right: '20%',
      width: '40%',
      height: '40%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    deadCardIcon: { width: '60%', height: '60%', zIndex: 200 },
    battlePointsContainer: {
      width: selectSize(CardSizesEnum.VERY_BIG, rw(4.8), rw(4.2), rw(3), rw(2)),
      height: selectSize(CardSizesEnum.VERY_BIG, rw(4.8), rw(4.2), rw(3), rw(2)),
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      top: -rw(1),
      left: rw(2.2)
    }
  });

  const cardShadow: Shadow = {
    shadowColor: isNativeMobile ? '#FD2806' : 'transparent',
    shadowOffset: {
      width: 5,
      height: 5
    },
    shadowOpacity: 0.8,
    shadowRadius: 90.0
  };

  return (
    <Animated.View
      style={[
        styles.card,
        !isNativeMobile && ({ filter: 'drop-shadow(5px 5px 60px #FD2806)' } as ImageStyle),
        {
          opacity: fadeInCardAnim,
          transform:
            battlePoints <= 0
              ? [
                  {
                    rotate: deadCardRotation.interpolate({
                      inputRange: [0, 1],
                      outputRange: ['0deg', '5deg']
                    })
                  }
                ]
              : []
        }
      ]}
    >
      <BattleCard
        card={card}
        cardSize={CardSizesEnum.VERY_BIG}
        shadow={cardShadow}
        damage={card.defensePoints! - damagePoints >= 0 ? card.defensePoints! - damagePoints : 0}
      />

      <Animated.View style={styles.damageContainer}>
        <Text style={[styles.pointsText, { fontSize: isNativeOrPWA ? 32 : 84, color: '#CD2D36' }]}>{-damagePoints}</Text>
      </Animated.View>

      {battlePoints <= 0 && (
        <Animated.View style={[styles.deadCardVeilContainer, { opacity: fadeInBattleEffectAnim }]}>
          <ImageBackground style={styles.deadCardVeil} source={redCardVeil} resizeMode="stretch" />
          <ImageBackground style={styles.battlePointsContainer} resizeMode="contain" source={battlePointsContainer}>
            <Text style={styles.actionText}>{card.actionPoints}</Text>
          </ImageBackground>
          <Animated.View style={styles.deadIconContainer}>
            <Image style={styles.deadCardIcon} source={deadCardIcon} resizeMode="contain" />
          </Animated.View>
        </Animated.View>
      )}
    </Animated.View>
  );
};

export default CardBattleSide;
