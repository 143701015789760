import LottieView from 'lottie-react-native';
import { Animated, GestureResponderEvent, TouchableWithoutFeedback, Image, ImageBackground, View, Text } from 'react-native';
import { DraxView } from 'react-native-drax';
import { PanGestureHandler } from 'react-native-gesture-handler';
import { useRatioDimensions } from '../../../../commons/components/AspectRatioView/useRatioDimensions';
import { PlayerTypeEnum } from '../../../../commons/constants/player';
import { Card as CardType } from '../../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../../commons/dtos/player.dto';
import { Rule } from '../../../../commons/dtos/rule.dto';
import { TagsEnum } from '../../../../commons/dtos/tag.dto';
import { cardHasTag } from '../../../../commons/utils';
import { battlePointsContainer, deadCardIcon, target } from '../../../../constants/Images';
import { lottieTargetReached } from '../../../../constants/Lotties';
import { CardSideFrontCover, HandOfCardsProperties, useHandOfCards } from '../useHandOfCards';

interface DragProps {
  card: CardType;
  index: number;
}

const OpponentHandOfCardsMobile = (props: HandOfCardsProperties) => {
  const { rh, offsetRatioLeft } = useRatioDimensions();
  const {
    setDragCard,
    cardAppliesToTarget,
    onCardPressed,
    onCloseDetailModal,
    onDragMethods,
    opponentHand,
    selectingTargetCard,
    selectingTargetDrawnCard,
    targetReached,
    getApplicableRules,
    opponentIsArtificialIntelligence,
    isCurrentPlayerRound,
    cardIsUpdating
  } = props;
  const { onDragDrop, onDragEnd, onDragExit, onDragStart } = onDragMethods;
  const { cardsHeight, cardsWidth, setCardsHeight, setCardsWidth, onDragOutAreaCloseDetailModal, styles } = useHandOfCards(
    isCurrentPlayerRound,
    !!opponentIsArtificialIntelligence
  );

  const onLongPress = (e: GestureResponderEvent, card: CardType) => {
    if (opponentIsArtificialIntelligence) return;
    const element = e.nativeEvent;
    const left = element.pageX - (cardsWidth / 2 + element.locationX + offsetRatioLeft);
    const top = cardsHeight - rh(2);
    onCardPressed(card, PlayerRoundEnum.OPPONENT, { left, top });
  };

  const DragUIComponent = ({ card, index }: DragProps) => {
    let damagePoints = 0;
    let attackingPointsResult = 0;
    if (selectingTargetCard && selectingTargetCard === card && targetReached && cardAppliesToTarget(card, targetReached, props.allRules)) {
      damagePoints = getApplicableRules(card, targetReached, props.allRules).reduce(
        (accum: number, current: Rule) => (accum += current.defensePoints ? current.defensePoints : 0),
        0
      );
      attackingPointsResult =
        !cardHasTag(targetReached, TagsEnum.FACTCHECK) && targetReached.defensePoints ? targetReached.defensePoints + damagePoints : 0;
    }
    return (
      <Animated.View style={styles.cardContentDrag}>
        <DraxView
          style={styles.cardDrag}
          key={`${card.id}_${index}`}
          dragPayload={`${card.id}_${index}`}
          onDrag={({ dragTranslation }) => onDragOutAreaCloseDetailModal(dragTranslation, onCloseDetailModal)}
          onDragStart={({ dragAbsolutePosition }) => {
            setDragCard('');
            const left = dragAbsolutePosition.x - cardsWidth / 2;
            const top = cardsHeight - rh(2);
            onDragStart(left, top, card, PlayerRoundEnum.OPPONENT);
          }}
          onDragExit={onDragExit}
          onDragEnd={onDragEnd}
          onDragDrop={() => onDragDrop(card)}
          renderHoverContent={() => {
            return selectingTargetCard && selectingTargetCard === card ? (
              targetReached && cardAppliesToTarget(card, targetReached, props.allRules) ? (
                <>
                  {damagePoints < 0 && (
                    <View style={styles.battlePointsContainer}>
                      <ImageBackground style={styles.battlePointsBackground} source={battlePointsContainer}></ImageBackground>
                      {attackingPointsResult > 0 ? (
                        <Text style={styles.pointsText}>{damagePoints}</Text>
                      ) : (
                        <Image style={styles.deadCardIcon} source={deadCardIcon} resizeMode={'contain'}></Image>
                      )}
                    </View>
                  )}
                  <LottieView autoPlay={true} loop={true} style={styles.lottieEffect} source={lottieTargetReached}></LottieView>
                </>
              ) : (
                <Image style={styles.targetSelector} source={target} />
              )
            ) : (
              <CardSideFrontCover card={card} index={index} opponentIsIA={!!opponentIsArtificialIntelligence} />
            );
          }}
        >
          {selectingTargetDrawnCard === card ? (
            <View style={styles.selectingTargetDrawnCardContainer}>
              {selectingTargetCard !== card && (
                <CardSideFrontCover
                  card={card}
                  index={index}
                  opponentIsIA={!!opponentIsArtificialIntelligence}
                  conditionalStyles={selectingTargetCard !== card ? styles.playableHandCardShadow : {}}
                />
              )}
            </View>
          ) : (
            selectingTargetCard !== card && (
              <CardSideFrontCover
                card={card}
                index={index}
                opponentIsIA={!!opponentIsArtificialIntelligence}
                conditionalStyles={selectingTargetCard !== card ? styles.playableHandCardShadow : {}}
              />
            )
          )}
        </DraxView>
      </Animated.View>
    );
  };

  return (
    <Animated.View style={[styles.container, { top: '-5%' }]}>
      <Animated.View nativeID={'opponentHand'} style={styles.cardsContainer}>
        {opponentHand
          .map((card, index) => {
            return (
              <Animated.View
                onLayout={event => {
                  if (cardsHeight < 1) {
                    const { width, height } = event.nativeEvent.layout;
                    setCardsWidth(width);
                    setCardsHeight(height);
                  }
                }}
                key={`${card.id} ${opponentHand.length}`}
                style={[styles.card, { transform: [{ rotate: '180deg' }] }]}
              >
                <PanGestureHandler maxPointers={1}>
                  {(isCurrentPlayerRound(PlayerTypeEnum.OPPONENT) && card.isPlayingCard && !selectingTargetDrawnCard) ||
                  (!isCurrentPlayerRound(PlayerTypeEnum.OPPONENT) && selectingTargetDrawnCard) ? (
                    DragUIComponent({ card, index })
                  ) : (
                    <TouchableWithoutFeedback
                      onLongPress={e => {
                        if (cardIsUpdating) return;
                        onLongPress(e, card);
                      }}
                      onPressOut={() => {
                        if (cardIsUpdating) return;
                        onCloseDetailModal();
                      }}
                    >
                      <View style={styles.cardContent}>
                        <CardSideFrontCover card={card} index={index} opponentIsIA={!!opponentIsArtificialIntelligence} />
                      </View>
                    </TouchableWithoutFeedback>
                  )}
                </PanGestureHandler>
              </Animated.View>
            );
          })
          .reverse()}
      </Animated.View>
    </Animated.View>
  );
};

export default OpponentHandOfCardsMobile;
