export enum ActorEnum {
  PLAYER = 'player',
  DATA_DIRECTOR = 'data-director',
  MOM = 'mom',
  DAD = 'dad',
  CAT = 'cat',
  AGENT_V = 'agent-v',
  AGENT_W = 'agent-w',
  AGENT_F = 'agent-f',
  BOT_M4L0 = 'bot-m4l0',
  BOT_BU3N0 = 'bot-bu3n0',
  COPYFAKE_BOT = 'copyfake-bot',
  FAKER_8000 = 'faker-8000',
  MANOLO_UNCLE = 'manolo-uncle',
  CHATO = 'chato',
  NEGANA = 'negana',
  POPULETAS = 'populetas',
  ANGRY_MASS = 'angry-mass',
  KONSPIRATRON = 'konspiratron',
  HATER = 'hater',
  INFOLISTO = 'infolisto',
  ROSAURA = 'rosaura',
  TROLL_PARENTS = 'troll-parents',
  METEOR = 'meteor',
  DIOXI_DOXI = 'dioxi-doxi',
  MININOR = 'mininor',
  FELINOR = 'felinor',
  DORA_PIRAT = 'dora-pirat',
  AGENT_F_F_EVIL = 'agent-f-f-evil',
  AGENT_F_M_EVIL = 'agent-f-m-evil'
}

export enum ActorTweetEnum {
  PLAYER = 'player',
  OPPONENT = 'opponent'
}

export enum ActorTypeEnum {
  AVATAR = 'avatar',
  ACTOR = 'actor'
}

export class Actor implements ActorProperties {
  id?: string;
  key!: ActorEnum;
  name!: string;
  color!: string;
  nickname!: string;
  twitter!: string;
  constructor(properties?: ActorProperties) {
    if (properties) {
      this.id = properties.id;
      this.key = properties.key;
      this.color = properties.color;
      this.name = properties.name;
      this.nickname = properties.nickname;
      this.twitter = properties.twitter;
    }
  }
}

export interface ActorProperties {
  id?: string;
  key: ActorEnum;
  name: string;
  color: string;
  nickname: string;
  twitter: string;
}
