import { useEffect, useRef } from 'react';
import { Image, ImageBackground, ImageSourcePropType, View, Text, StyleSheet, Animated, Platform, Easing } from 'react-native';
import useUnits from 'rxn-units';
import Avatar from '../../../../commons/components/Avatar';
import PolyShape from '../../../../commons/components/PolyShape/PolyShape';
import adjust from '../../../../commons/styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA, isPWA } from '../../../../commons/utils';
import {
  launchBattleAvatarBgLeft,
  launchBattleAvatarBgRight,
  launchBattleLeft,
  launchBattleRight,
  launchBattleTitle,
  launchBattleTopBlue,
  launchBattleTopOrange
} from '../../../../constants/Images';
import PlayerNumber from '../PlayerNumber/PlayerNumber';

interface LaunchBattleSideProp {
  side: 'left' | 'right';
  avatarImage: ImageSourcePropType;
  nickname: string;
  title: string;
  position: number;
}

const LaunchBattleSide = ({ avatarImage, nickname, side, title, position }: LaunchBattleSideProp) => {
  const backgroundSource = side === 'left' ? launchBattleLeft : launchBattleRight;
  const topSource = side === 'left' ? launchBattleTopOrange : launchBattleTopBlue;
  const { vh, vw } = useUnits();

  const translation = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(0)).current;

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: 'auto'
    },
    topContainerWrapper: {
      width: '100%',
      maxWidth: 580,
      height: isNativeOrPWA ? 30 : 45,
      position: 'relative',
      paddingBottom: isNativeOrPWA ? 15 : 30
    },
    vsTitle: {
      position: 'absolute',
      right: isNativeOrPWA ? -65 : -100,
      top: isNativeOrPWA ? -36 : -62,
      width: isNativeOrPWA ? 100 : 150,
      height: isNativeOrPWA ? 60 : 110
    },
    topContainer: {
      position: 'absolute',
      flexDirection: 'row'
    },
    topContainerLeft: {
      right: isNativeOrPWA ? 42 : 64
    },
    topContainerRight: {
      left: isNativeOrPWA ? 110 : 164
    },
    topItem: {
      width: isNativeOrPWA ? 42 : 84,
      height: isNativeOrPWA ? 15 : 30
    },
    secondTopItem: {
      opacity: 0.6
    },
    thirdItem: {
      opacity: 0.3
    },
    mainContainer: {
      width: '100%',
      height: isNativeOrPWA ? 180 : 270,
      flexDirection: side === 'left' ? 'row-reverse' : 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      position: 'relative'
    },
    bgAvatarStyle: {
      width: isNativeOrPWA ? 110 : 170,
      height: isNativeOrPWA ? 110 : 170,
      marginBottom: vw(1),
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: 25,
      position: 'relative'
    },
    avatarStyle: {
      bottom: 6,
      right: vw(0.2),
      left: vw(0.2),
      width: '95%',
      height: '95%',
      position: 'absolute'
    },
    bgAvatarStyleLeft: {
      marginRight: isNativeOrPWA ? 60 : 100
    },
    bgAvatarStyleRight: {
      marginLeft: isNativeOrPWA ? 60 : 100
    },
    background: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: isNativeMobile ? 0 : 'auto',
      right: isNativeMobile ? 0 : 'auto',
      width: isNativeOrPWA ? 375 : 560
    },
    textWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      flexWrap: 'nowrap',
      flexGrow: 0,
      flexShrink: 1,
      width: '40%',
      minWidth: 125,
      paddingVertical: isNativeOrPWA ? 15 : 50
    },
    textWrapperLeft: {
      right: isNativeOrPWA ? 220 : 340,
      paddingRight: 20
    },
    textWrapperRight: {
      left: isNativeOrPWA ? 220 : 340,
      paddingLeft: 20,
      marginLeft: -30
    },
    nickname: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      flexWrap: 'wrap',
      marginBottom: vh(2),
      maxWidth: isNativeOrPWA ? 180 : 340,
      minHeight: 36,
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      alignItems: 'center',
      position: 'relative',
      height: isNativeOrPWA ? 40 : 'auto',
      width: isNativeOrPWA ? 150 : 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    nicknameTextMini: {
      fontSize: isNativeOrPWA ? adjust(16) : 21
    },
    nicknameText: {
      fontSize: 18,
      color: '#FFF',
      fontFamily: 'Inlanders',
      textAlign: 'center'
    },
    polyContainer: {
      height: '100%',
      width: '100%'
    },
    tittleText: {
      color: '#FFF',
      textShadowColor: 'rgba(0, 0, 0, 0.75)',
      textShadowOffset: { width: -1, height: 1 },
      textShadowRadius: 5,
      fontSize: isNativeOrPWA ? adjust(13) : 22,
      fontWeight: '800',
      width: '100%',
      marginTop: isNativeOrPWA ? 5 : 10,
      paddingLeft: 25
    },
    positionStyle: {
      position: 'absolute'
    },
    circleLeftMobile: {
      left: 180,
      bottom: 25
    },
    circleLeftWeb: {
      right: isPWA ? 180 : 260,
      bottom: 40
    },
    circleRight: {
      left: isNativeOrPWA ? 180 : 260,
      bottom: isNativeOrPWA ? 25 : 40
    },
    endUtil: {
      alignItems: 'flex-end'
    },
    parentFullWidth: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      alignItems: side === 'left' ? 'flex-end' : 'flex-start'
    }
  });

  useEffect(() => {
    const translationAnimation = Animated.timing(translation, {
      toValue: 1,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.bounce,
      duration: 1200
    });
    const opacityAnimation = Animated.timing(opacity, {
      toValue: 1,
      useNativeDriver: Platform.OS !== 'web',
      duration: 200
    });
    const sequence = Animated.sequence([translationAnimation, opacityAnimation]);
    sequence.start();
  }, []);

  return (
    <Animated.View
      style={[
        styles.container,
        side === 'left' && styles.endUtil,
        {
          transform: [
            {
              translateX: translation.interpolate({
                inputRange: [0, 1],
                outputRange: side === 'left' ? [-vw(40), 0] : [vw(40), 0]
              })
            }
          ]
        }
      ]}
    >
      <View style={styles.topContainerWrapper}>
        {side === 'left' && (
          <Animated.Image source={launchBattleTitle} resizeMode="contain" style={[styles.vsTitle, { opacity: opacity }]} />
        )}
        <View style={[styles.topContainer, side === 'left' ? styles.topContainerLeft : styles.topContainerRight]}>
          <Image source={topSource} style={styles.topItem} />
          <Image source={topSource} style={[styles.secondTopItem, styles.topItem]} />
          <Image source={topSource} style={[styles.thirdItem, styles.topItem]} />
        </View>
      </View>
      <View style={styles.parentFullWidth}>
        <View style={styles.mainContainer}>
          <ImageBackground source={backgroundSource} style={styles.background} resizeMode="contain" />

          <Avatar
            avatar={avatarImage}
            avatarStyle={styles.avatarStyle}
            background={side === 'left' ? launchBattleAvatarBgLeft : launchBattleAvatarBgRight}
            bgStyle={[styles.bgAvatarStyle, side === 'left' ? styles.bgAvatarStyleLeft : styles.bgAvatarStyleRight]}
          />

          <PlayerNumber
            position={position}
            styleProp={[
              styles.positionStyle,
              side === 'left' ? (isNativeMobile ? styles.circleLeftMobile : styles.circleLeftWeb) : styles.circleRight
            ]}
            side={side}
          />
        </View>
        <View style={[side === 'left' ? styles.textWrapperLeft : styles.textWrapperRight, styles.textWrapper]}>
          <View style={styles.nickname}>
            <View style={styles.polyContainer}>
              <PolyShape type={3} bgColor={'#131313'}>
                <Text style={[styles.nicknameText, nickname.length > 13 && styles.nicknameTextMini]}>{nickname.toUpperCase()}</Text>
              </PolyShape>
            </View>
          </View>
          <Text style={styles.tittleText}>{title}</Text>
        </View>
      </View>
    </Animated.View>
  );
};

export default LaunchBattleSide;
