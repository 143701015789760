import { useSelector } from 'react-redux';
import { GameActionInterface } from '../redux/GameBoard/gameAction';

export function useGameAction(cardCode: string): boolean {
  const { gameAction } = useSelector((s: { gameActions: GameActionInterface }) => s.gameActions);

  if (!gameAction) return false;

  return cardCode === gameAction.configuration.id;
}
