import { LinearGradient } from 'expo-linear-gradient';
import { useState } from 'react';
import { StyleSheet, Image, TouchableWithoutFeedback } from 'react-native';
import useUnits from 'rxn-units';
import { View, Text } from '../../../../commons/components/Themed';
import { DeckIncludesCard } from '../../../../commons/dtos/deck-includes-card.dto';
import { isNativeOrPWA } from '../../../../commons/utils';
import { warningIcon } from '../../../../constants/Images';
import { MIN_DECK_SIZE } from '../../../../constants/Values';
interface DeckStatsViewerProps {
  deckCards: DeckIncludesCard[];
}

const DeckStatsViewer = (props: DeckStatsViewerProps) => {
  const [hoveredColumn, setHoveredColumn] = useState(-1);
  const [hoverColumnTimeout, setHoverColumnTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isWarningHovered, setIsWarningHovered] = useState(false);
  const [hoverWarningTimeout, setHoverWarningTimeout] = useState<NodeJS.Timeout | null>(null);

  const { vw } = useUnits();

  const histogramData = props.deckCards.reduce(
    (accum, current) => {
      if (current && current.card && current.card.actionPoints) {
        if (current.card.color) {
          accum[current.card.actionPoints - 1].black += 1 * current.quantity;
          return accum;
        }
        accum[current.card.actionPoints - 1].white += 1 * current.quantity;
        return accum;
      }
      return accum;
    },
    [...Array(8).keys()].map(() => {
      return { white: 0, black: 0 };
    })
  );
  const cardsCount = props.deckCards.reduce((accum, current) => {
    accum += current.quantity;
    return accum;
  }, 0);

  const styles = StyleSheet.create({
    container: {
      height: '100%',
      minHeight: vw(4),
      width: '90%',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'row'
    },
    cardsCounter: {
      width: '45%',
      display: 'flex',
      justifyContent: 'center',
      align: 'space-between',
      flexDirection: 'row',
      marginLeft: vw(0.5)
    },
    cardsCounterNumerator: { fontWeight: '900', fontSize: vw(3.4) },
    warning: {
      position: 'absolute',
      top: isNativeOrPWA ? -vw(1.2) : 0,
      width: vw(1.6),
      height: vw(1.4),
      right: isNativeOrPWA ? vw(0.5) : 0
    },
    histogram: {
      width: '52%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'flex-end',
      marginRight: vw(0.5),
      marginBottom: vw(1)
    },
    histogramColumn: {
      width: '13%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    histogramBlackColumn: {
      width: '100%',
      backgroundColor: '#01E2E0',
      borderColor: '#494949'
    },
    histogramWhiteColumn: {
      width: '100%',
      backgroundColor: '#FFE15F',
      borderColor: '#494949',
      zIndex: 500
    },
    histogramGradient: { width: '100%', height: '100%', zIndex: 500 },
    histogramText: { fontSize: vw(1), fontWeight: '600' },
    tooltipContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1000 },
    tooltipContainerBig: { width: vw(5.4), height: vw(2.4), marginBottom: vw(0.4) },
    tooltipContainerMedium: {
      position: 'absolute',
      top: -vw(3.2),
      right: -vw(1.4),
      width: vw(5.4),
      height: vw(2.8),
      marginBottom: vw(0.4)
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 1000,
      padding: vw(0.4),
      width: vw(8),
      height: vw(2.6),
      borderRadius: vw(0.4)
    },
    tooltipTip: {
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderBottomWidth: 0,
      borderBottomColor: 'transparent',
      borderRightColor: 'transparent',
      borderTopColor: '#FFFFFF',
      borderLeftColor: 'transparent',
      zIndex: 1000,
      borderRightWidth: vw(0.6),
      borderTopWidth: vw(0.6),
      borderLeftWidth: vw(0.6)
    },
    tooltipColor: {
      borderRadius: 500,
      width: vw(0.8),
      height: vw(0.8)
    },
    tooltipColorWhite: { backgroundColor: '#fff', borderColor: '#000', borderWidth: 1 },
    tooltipColorBlack: { backgroundColor: '#000' },
    tooltipText: { color: '#000000', fontWeight: '600' },
    tooltipTextMedium: { textAlign: 'center', fontSize: vw(0.7) }
  });
  return (
    <View style={styles.container}>
      <View style={styles.cardsCounter}>
        <Text style={styles.cardsCounterNumerator}>{cardsCount}</Text>
        <Text style={{ fontSize: vw(1.7) }}>/{MIN_DECK_SIZE}</Text>
        {isWarningHovered && (
          <View style={[styles.tooltipContainer, styles.tooltipContainerMedium]}>
            <View style={styles.tooltip}>
              <Text style={[styles.tooltipText, styles.tooltipTextMedium]}>Nº mínimo de cartas no alcanzado</Text>
            </View>
            <View style={styles.tooltipTip}></View>
          </View>
        )}
        {cardsCount < MIN_DECK_SIZE && (
          <TouchableWithoutFeedback
            onPress={() => {
              if (hoverWarningTimeout) {
                clearTimeout(hoverWarningTimeout);
              }
              setIsWarningHovered(true);
              setHoverWarningTimeout(setTimeout(() => setIsWarningHovered(false), 1200));
            }}
          >
            <Image style={styles.warning} source={warningIcon} resizeMode={'contain'}></Image>
          </TouchableWithoutFeedback>
        )}
      </View>
      <View style={styles.histogram}>
        {histogramData.map((stat, index) => (
          <TouchableWithoutFeedback
            key={index}
            onPress={() => {
              if (hoverColumnTimeout) {
                clearTimeout(hoverColumnTimeout);
              }
              setHoveredColumn(index);
              setHoverColumnTimeout(setTimeout(() => setHoveredColumn(-1), 1000));
            }}
          >
            <View style={styles.histogramColumn}>
              {index === hoveredColumn && (
                <View style={[styles.tooltipContainer, styles.tooltipContainerBig]}>
                  <View style={[styles.tooltip, { padding: vw(0.4), width: vw(5.4), height: vw(2.2), borderRadius: vw(0.4) }]}>
                    <View style={[styles.tooltipColor, styles.tooltipColorBlack]}></View>
                    <Text style={[styles.tooltipText, { fontSize: vw(1) }]}>{stat.black}</Text>
                    <View style={[styles.tooltipColor, styles.tooltipColorWhite]}></View>
                    <Text style={[styles.tooltipText, { fontSize: vw(1) }]}>{stat.white}</Text>
                  </View>
                  <View style={styles.tooltipTip}></View>
                </View>
              )}
              {stat.black > 0 && (
                <View
                  style={[styles.histogramBlackColumn, { height: vw(stat.black * 0.2), borderWidth: vw(0.035), borderRadius: vw(0.1) }]}
                >
                  <LinearGradient style={styles.histogramGradient} colors={['#222', '#000']} />
                </View>
              )}
              {stat.white > 0 && (
                <View
                  style={[styles.histogramWhiteColumn, { height: vw(stat.white * 0.2), borderWidth: vw(0.035), borderRadius: vw(0.1) }]}
                >
                  <LinearGradient style={styles.histogramGradient} colors={['#ebebeb', '#fff']} />
                </View>
              )}
              <Text style={styles.histogramText}>{index + 1}</Text>
            </View>
          </TouchableWithoutFeedback>
        ))}
      </View>
    </View>
  );
};

export default DeckStatsViewer;
