import { useCallback, useRef } from 'react';
import { PlayerTypeEnum } from '../../commons/constants/player';
import { Card } from '../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../commons/dtos/player.dto';
import { AppliedRules, Rule, RuleTypeEnum, WhenAppliedRules, WhenEnum } from '../../commons/dtos/rule.dto';
import { SubjectsEnum } from '../../commons/dtos/subject.dto';
import { Token } from '../../commons/dtos/token.dto';
import { Tweet } from '../../commons/dtos/tweet.dto';
import { TypesEnum } from '../../commons/dtos/type.dto';
import { getCopyCount, getRandomUUID, processDuplicatedCards, wait } from '../../commons/utils';
import boardCardsUtils from '../../commons/utils/gameboard/boardCards';
import enhanceUtils from '../../commons/utils/gameboard/enhance';
import {
  getQueueRules,
  checkIfNeccesaryRemoveCardRule,
  getCardRules,
  getPointsByRule,
  getPointsToRandomCardByRule,
  haveConditionAvatarLessDefensePoints,
  haveConditionLessDamagePoints,
  haveDestroyCardsDamagePoints,
  isApplicableRuleCard,
  isApplicableRuleToTarget,
  updateCardPoints,
  slugRuleCardId,
  initialAppliedRules,
  checkDestroyCardByRule,
  checkSomeAliveRule,
  isStoppedCard,
  applyToTarget
} from '../../commons/utils/gameboard/rules';
import sideUtils from '../../commons/utils/gameboard/side';
import CardsService from '../../services/cards.service';
import type { BoardCards, Deck, Hand } from './gameTypes';
import { RulesFunctions } from './rulesTypes';

const cardsService = new CardsService();

interface UseGameBoardRulesProps {
  extractCardFromDeck: (
    player: number,
    hand: Hand,
    deck: Deck,
    type?: TypesEnum,
    subject?: SubjectsEnum,
    isReactionExtract?: boolean,
    actionPoints?: number
  ) => Promise<[Hand, Deck]>;
  setPlayerLifePoints: (increment: number) => void;
  setOpponentLifePoints: (increment: number) => void;
  round: number;
  updateOpponentBoard: (cards: BoardCards) => void;
  opponentDeck: Deck;
  updatePlayerBoard: (cards: BoardCards) => void;
  playerDeck: Deck;
  updatePlayerDeck: (cards: Deck) => void;
  updateOpponentDeck: (cards: Deck) => void;
  activateAnimationLifePoints: (damage: number, player: PlayerRoundEnum) => Promise<void>;
  allRules: Rule[];
  showLeftoverAndRemoveCards: (cards: BoardCards, playedCard: Card, isArtificialIntelligence: boolean) => Promise<BoardCards>;
  opponentIsArtificialIntelligence: boolean;
}

const useGameBoardRules = ({
  activateAnimationLifePoints,
  extractCardFromDeck,
  opponentDeck,
  playerDeck,
  round,
  setOpponentLifePoints,
  setPlayerLifePoints,
  allRules,
  updateOpponentDeck,
  updatePlayerDeck,
  showLeftoverAndRemoveCards,
  opponentIsArtificialIntelligence
}: UseGameBoardRulesProps) => {
  const appliedRules = useRef<AppliedRules>({ ...JSON.parse(JSON.stringify(initialAppliedRules)) });

  const attackingCurrentCard = useRef<Card | null>(null);
  const pendingCards = useRef<BoardCards | null>(null);

  /**
   * Check if neccesary yo remove applied rules to card
   * @param queueRules rules on queue
   * @param playedCard cardPlayed
   * @param boardCards actual player/opponent board cards
   */
  const isNeccesaryRemoveCardRule = useCallback((queueRules: WhenAppliedRules, playedCard: Card, boardCards: BoardCards) => {
    const copyAppliedRules = { ...appliedRules.current };

    const [newBoardCards, newAppliedRules] = checkIfNeccesaryRemoveCardRule(queueRules, playedCard, boardCards, allRules, copyAppliedRules);

    appliedRules.current = newAppliedRules;
    return newBoardCards;
  }, []);

  const applyPointsToCard: RulesFunctions['applyPointsToCard'] = async ({
    boardCard,
    isOpponent,
    opponentBoardCards,
    playedCard,
    playerBoardCards,
    rule,
    enhanceEffect,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const { damagePoints: initialDamagePoints, defensePoints: initialDefensePoints } = boardCard;

    const { applied, boardCard: finalBoardCard } = updateCardPoints({ boardCard, rule });

    const { defensePoints: finalDefensePoints, damagePoints: finalDamagePoints } = finalBoardCard;

    // Avoid loop when finalBoardCard and played card will die
    const sameRulesAndPlayedWillDie =
      playedCard.rules?.some(rule => rule.applyWhen === WhenEnum.LOSE) &&
      finalBoardCard.rules?.some(rule => rule.applyWhen === WhenEnum.LOSE) &&
      (playedCard.defensePoints as number) <= 0;

    let loseAppliedCards: BoardCards = [];

    let finalOpponentLifePoints = opponentLifePoints;
    let finalPlayerLifePoints = playerLifePoints;

    if ((finalDefensePoints as number) <= 0) {
      // Apply attacked card lose rules
      if (finalBoardCard.rules?.some(rule => rule.applyWhen === WhenEnum.LOSE) && !sameRulesAndPlayedWillDie) {
        attackingCurrentCard.current = playedCard;
        const [rule] = Object.values(appliedRules.current[isOpponent ? 'player' : 'opponent'].lose);
        if (rule instanceof Rule) {
          // TODO Check empty hand and deck, args in the hook and update?, not return updated hand and deck
          const {
            applied,
            boardCards: cards,
            opponentLifePoints: updatedOpponentLifePoints,
            playerLifePoints: updatedPlayerLifePoints
          } = await applyCardRulesPlay({
            currentQueueRules: appliedRules.current[isOpponent ? 'player' : 'opponent'],
            // This should be enemy data
            deck: [],
            hand: [],
            isOpponent: !isOpponent,
            opponentLifePoints: finalOpponentLifePoints,
            playerLifePoints: finalPlayerLifePoints,
            opponentBoardCards: isOpponent ? [...opponentBoardCards, playedCard] : opponentBoardCards,
            playerBoardCards: isOpponent ? playerBoardCards : [...playerBoardCards, playedCard],
            playedCard: boardCard,
            rule,
            target: playedCard.id
          });
          if (applied) {
            loseAppliedCards = cards;
            finalOpponentLifePoints = updatedOpponentLifePoints;
            finalPlayerLifePoints = updatedPlayerLifePoints;
          }
        }
      }
    }
    if (!applied)
      return { card: boardCard, loseCards: [], opponentLifePoints: finalOpponentLifePoints, playerLifePoints: finalPlayerLifePoints };

    if (
      (initialDamagePoints && finalDamagePoints && initialDamagePoints < finalDamagePoints) ||
      (initialDefensePoints && finalDefensePoints && initialDefensePoints < finalDefensePoints)
    ) {
      finalBoardCard.upgradedFirstTurn = true;
    }

    enhanceUtils.saveEnhanceCard(finalBoardCard, rule, enhanceEffect);

    return {
      card: finalBoardCard,
      loseCards: loseAppliedCards,
      opponentLifePoints: finalOpponentLifePoints,
      playerLifePoints: finalPlayerLifePoints
    };
  };

  const applyPointsToRandomCardByRule: RulesFunctions['applyPointsToRandomCardByRule'] = async ({
    boardCards,
    isOpponent,
    playedCard,
    rule,
    opponentBoardCards,
    playerBoardCards,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const copyBoardCards = [...boardCards];
    const extractedCard = getPointsToRandomCardByRule(rule, copyBoardCards);

    if (!extractedCard) return { applied: true, boardCards: copyBoardCards, opponentLifePoints, playerLifePoints };

    const {
      card: updatedCard,
      loseCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    } = await applyPointsToCard({
      boardCard: extractedCard,
      rule,
      isOpponent,
      opponentBoardCards,
      playerBoardCards,
      playedCard,
      enhanceEffect: playedCard.enhanceEffect,
      opponentLifePoints,
      playerLifePoints
    });

    if (loseCards.length) pendingCards.current = loseCards;

    const updatedBoardCardIndex = copyBoardCards.findIndex(boardCard => boardCard.idUnique === updatedCard.idUnique);
    if (updatedBoardCardIndex !== -1) copyBoardCards[updatedBoardCardIndex] = updatedCard;

    return {
      applied: true,
      boardCards: copyBoardCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    };
  };

  const applyPointsByRule: RulesFunctions['applyPointsByRule'] = async ({
    boardCards,
    isOpponent,
    opponentBoardCards,
    playedCard,
    playerBoardCards,
    rule,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const applyPoints = getPointsByRule(rule, boardCards);

    let copyBoardCards: BoardCards = [...boardCards];
    let finalPlayerLifePoints = playerLifePoints;
    let finalOpponentLifePoints = opponentLifePoints;
    // This is running in parallel
    await Promise.all(
      applyPoints.map(async boardCard => {
        // TODO Check
        const {
          card: updatedCard,
          opponentLifePoints: updatedOpponentLifePoints,
          playerLifePoints: updatedPlayerLifePoints,
          loseCards
        } = await applyPointsToCard({
          boardCard,
          rule,
          isOpponent,
          opponentBoardCards,
          playerBoardCards,
          playedCard,
          enhanceEffect: playedCard.enhanceEffect,
          opponentLifePoints,
          playerLifePoints
        });
        if (loseCards.length) pendingCards.current = loseCards;

        copyBoardCards = copyBoardCards.map(boardCard => (boardCard.idUnique === updatedCard.idUnique ? updatedCard : boardCard));
        finalPlayerLifePoints = updatedPlayerLifePoints;
        finalOpponentLifePoints = updatedOpponentLifePoints;
      })
    );

    return {
      applied: true,
      boardCards: copyBoardCards,
      opponentLifePoints: finalOpponentLifePoints,
      playerLifePoints: finalPlayerLifePoints
    };
  };

  const applyPointsToAvatar: RulesFunctions['applyPointsToAvatar'] = async ({
    isOpponent,
    isRival,
    rule,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const { defensePoints } = rule;
    const { lifePoints, setLifePoints } = sideUtils.applyToAvatar(
      isOpponent,
      isRival,
      {
        opponentLifePoints,
        playerLifePoints
      },
      { setOpponentLifePoints, setPlayerLifePoints }
    );

    if (!(lifePoints && defensePoints)) return { applied: false, opponentLifePoints, playerLifePoints };

    const player = sideUtils.applyToPlayer(isOpponent, isRival);
    await activateAnimationLifePoints(defensePoints, player);
    setLifePoints(defensePoints);
    return {
      applied: true,
      opponentLifePoints: player === PlayerRoundEnum.PLAYER ? opponentLifePoints : opponentLifePoints + defensePoints,
      playerLifePoints: player === PlayerRoundEnum.PLAYER ? playerLifePoints + defensePoints : playerLifePoints
    };
  };

  const launchNewCard: RulesFunctions['launchNewCard'] = async ({
    deck,
    hand,
    isOpponent,
    rule,
    actionPoints = 0,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const { haveConditionLessDefensePoints, newCards, applyToType, applyToSubject, launchCards } = rule;
    let newHand = [...hand];
    let newDeck = [...deck];

    if (haveConditionLessDefensePoints && !haveConditionAvatarLessDefensePoints({ isOpponent, playerLifePoints, opponentLifePoints })) {
      return { applied: false, hand, deck };
    }
    if (newCards || launchCards) {
      const arrayLaunchCards = Array.from(Array(newCards ?? launchCards).keys());
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for await (const _newCard of arrayLaunchCards) {
        if (applyToType?.length) {
          [newHand, newDeck] = await extractCardFromDeck(
            isOpponent ? PlayerTypeEnum.OPPONENT : PlayerTypeEnum.PLAYER,
            newHand,
            newDeck,
            applyToType[0].typeName,
            undefined,
            undefined,
            actionPoints
          );
        } else if (applyToSubject?.length) {
          [newHand, newDeck] = await extractCardFromDeck(
            isOpponent ? PlayerTypeEnum.OPPONENT : PlayerTypeEnum.PLAYER,
            newHand,
            newDeck,
            undefined,
            applyToSubject[0].subjectName,
            undefined,
            actionPoints
          );
        } else {
          [newHand, newDeck] = await extractCardFromDeck(
            isOpponent ? PlayerTypeEnum.OPPONENT : PlayerTypeEnum.PLAYER,
            newHand,
            newDeck,
            undefined,
            undefined,
            undefined,
            actionPoints
          );
        }
      }

      return { applied: true, hand: newHand, deck: newDeck };
    }

    return { applied: false, hand: newHand, deck: newDeck };
  };

  const destroyCardsByRule: RulesFunctions['destroyCardsByRule'] = ({ boardCards, rule }) => {
    return boardCards.map(boardCard => {
      if (haveDestroyCardsDamagePoints(rule, boardCard) && isApplicableRuleCard(boardCard, rule)) {
        boardCard.defensePoints = 0;
      }
      return boardCard;
    });
  };

  const destroyCardByRule: RulesFunctions['destroyCardByRule'] = ({ boardCards, rule }) => {
    const copyBoardCards = [...boardCards];
    const cardsToDestroy = checkDestroyCardByRule(rule, boardCards);

    if (!cardsToDestroy.length) return boardCards;

    const [cardToDestroy] = cardsToDestroy;

    cardToDestroy.defensePoints = 0;

    const updatedBoardCardIndex = copyBoardCards.findIndex(boardCard => boardCard.idUnique === cardToDestroy.idUnique);
    if (updatedBoardCardIndex !== -1) copyBoardCards[updatedBoardCardIndex] = cardToDestroy;

    return copyBoardCards;
  };

  const destroyTarget: RulesFunctions['destroyTarget'] = ({ rule, targetId, boardCards }) => {
    const cardsApplicable = boardCards.filter(boardCard => isApplicableRuleToTarget(rule, boardCard, targetId));

    if (!cardsApplicable.length) return { applied: false, boardCards };

    const copyBoardCards: BoardCards = [...boardCards];

    const [targetToDestroy] = cardsApplicable;

    const copyTargetToDestroy: Card = { ...targetToDestroy };

    if (copyTargetToDestroy.enhance) {
      Object.entries(copyTargetToDestroy.enhance).forEach(([, rule]) => {
        const { ruleDamagePoints, ruleDefensePoints } = rule;
        copyTargetToDestroy.defensePoints =
          (copyTargetToDestroy.defensePoints || 0) - (ruleDefensePoints || 0) < 0
            ? 0
            : (copyTargetToDestroy.defensePoints || 0) - (ruleDefensePoints || 0);
        copyTargetToDestroy.damagePoints = (copyTargetToDestroy.damagePoints || 0) - (ruleDamagePoints || 0);
      });
      copyTargetToDestroy.enhance = {};
    }

    if (rule.removeCapabilities) {
      boardCardsUtils.removeCapabilities(copyTargetToDestroy);
    }

    const targetIndex = copyBoardCards.findIndex(({ idUnique }) => idUnique === copyTargetToDestroy.idUnique);
    if (targetIndex < 0) return { applied: false, boardCards: copyBoardCards };
    copyBoardCards[targetIndex] = copyTargetToDestroy;

    return { applied: true, boardCards: copyBoardCards };
  };

  const destroyCards: RulesFunctions['destroyCards'] = ({ rule, boardCards }) => {
    const { destroyCards, applyToAllCards } = rule;

    // Destroy any numbers of cards by rule
    if (destroyCards) {
      Array.from(Array(destroyCards).keys()).forEach(() => {
        boardCards = destroyCardByRule({ boardCards, rule });
      });
      return { applied: true, boardCards };
    }

    // Destroy all cards by rule
    if (applyToAllCards) {
      const finalBoardCards = destroyCardsByRule({ boardCards, rule });
      return { applied: true, boardCards: finalBoardCards };
    }

    return { applied: true, boardCards };
  };

  const stopTarget: RulesFunctions['stopTarget'] = ({ boardCards, rule, targetId, isOpponent, isRival }) => {
    const isPlayer = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);

    const stoppedRule = { stoppedRound: round, stopByTurns: rule.stopByTurns as number };

    if (isPlayer) {
      appliedRules.current.player['stop'][targetId] = stoppedRule;
    } else {
      appliedRules.current.opponent['stop'][targetId] = stoppedRule;
    }

    const toStopBoardCards = boardCards.filter(boardCard => isApplicableRuleToTarget(rule, boardCard, targetId));
    if (!toStopBoardCards.length) return { applied: false, boardCards };

    const [toStopCard] = toStopBoardCards;

    const mutatedBoardCards = boardCards.map((boardCard: Card) => {
      if (toStopCard.id === boardCard.id) {
        boardCard.isWaiting = true;
        boardCard.firstTurn = true;
      }
      return boardCard;
    });

    return { applied: true, boardCards: mutatedBoardCards };
  };

  const stopCardsByRule: RulesFunctions['stopCardsByRule'] = ({ boardCards, isOpponent, isRival, rule }): BoardCards => {
    const stoppedRule = { stoppedRound: round, stopByTurns: rule.stopByTurns as number };
    return boardCards.map(boardCard => {
      if (isApplicableRuleCard(boardCard, rule)) {
        boardCard.isWaiting = true;
        boardCard.firstTurn = true;
        if (sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival)) {
          appliedRules.current.player['stop'][boardCard.id] = stoppedRule;
        } else {
          appliedRules.current.opponent['stop'][boardCard.id] = stoppedRule;
        }
      }
      return boardCard;
    });
  };

  const stopCards: RulesFunctions['stopCards'] = ({ boardCards, rule, isOpponent, isRival }) => {
    const { applyToAllCards } = rule;

    if (!applyToAllCards) return { applied: true, boardCards };

    const finalBoardCards = stopCardsByRule({ boardCards, isOpponent, isRival, rule });
    return { applied: true, boardCards: finalBoardCards };
  };

  const copyCardTarget: RulesFunctions['copyCardTarget'] = ({ boardCards, isOpponent, isRival, rule, targetId }) => {
    const { deck } = sideUtils.applyToDeck(isOpponent, isRival, { playerDeck, opponentDeck }, { updateOpponentDeck, updatePlayerDeck });
    let copyBoardCards = [...boardCards];
    const targetCards = copyBoardCards.filter(
      boardCard => haveConditionLessDamagePoints(rule, boardCard) && isApplicableRuleToTarget(rule, boardCard, targetId)
    );

    if (targetCards.length === 0) {
      // eslint-disable-next-line no-console
      console.warn(`Something went wrong copying target for ${targetId}`);
      return { copyBoardCards, deck };
    }
    const [target] = targetCards;
    const originalCardId = target.id;
    const copyCard: Card = { ...target };
    const UUID = getRandomUUID();
    const updatedId = `${originalCardId}-copy-${UUID}-${getCopyCount()}`;
    copyCard.id = updatedId;
    copyCard.idUnique = updatedId;
    copyCard.rules = copyCard.rules
      ? copyCard.rules.map(rule => {
          const copyRule = { ...rule };
          if (copyRule.id === originalCardId) {
            copyRule.id = copyCard.id;
          }
          return copyRule;
        })
      : [];
    copyBoardCards = [{ ...copyCard }, ...copyBoardCards];
    const player = sideUtils.applyToPlayer(isOpponent, isRival);
    const newDeck = processDuplicatedCards([...deck, copyCard], player);
    newDeck.pop();
    return { deck: newDeck, copyBoardCards, copyCard };
  };

  const copyCardsByTarget: RulesFunctions['copyCardsByTarget'] = async ({
    rule,
    targetId,
    isOpponent,
    isRival,
    hand,
    deck,
    actionPoints,
    boardCards,
    opponentBoardCards,
    playerBoardCards,
    opponentLifePoints,
    playerLifePoints,
    isPlayer
  }) => {
    let updatedHand = [...hand];
    let finalDeck = [...deck];
    let finalBoardCards = [...boardCards];
    let finalPlayerLifePoints = playerLifePoints;
    let finalOpponentLifePoints = opponentLifePoints;

    const arrayNewCards = Array.from(Array(rule.newCards).keys());
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for await (const _newCard of arrayNewCards) {
      const { deck: updatedDeck, copyBoardCards, copyCard } = copyCardTarget({ isOpponent, isRival, rule, targetId, boardCards });
      if (!copyCard) {
        return {
          applied: false,
          hand: updatedHand,
          deck: updatedDeck,
          boardCards: finalBoardCards,
          opponentLifePoints: finalOpponentLifePoints,
          playerLifePoints: finalPlayerLifePoints
        };
      }

      const copyCardRules = getCardRules(copyCard, allRules);

      const {
        deck: newDeck,
        hand: newHand,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints,
        opponentBoardCards: newOpponentBoardCards,
        playerBoardCards: newPlayerBoardCards
      } = await applyCardRules({
        deck: updatedDeck,
        hand: updatedHand,
        isOpponent,
        opponentLifePoints: finalOpponentLifePoints,
        playerLifePoints: finalPlayerLifePoints,
        opponentBoardCards: isPlayer ? opponentBoardCards : copyBoardCards,
        playerBoardCards: isPlayer ? copyBoardCards : playerBoardCards,
        actionPoints,
        playedCard: copyCard,
        playedCardRules: copyCardRules,
        targetId: copyCard.id
      });

      const updatedBoardCards = await showLeftoverAndRemoveCards(
        isOpponent ? newOpponentBoardCards : newPlayerBoardCards,
        copyCard,
        !isPlayer && opponentIsArtificialIntelligence
      );

      await wait(500);

      updatedHand = newHand;
      finalDeck = newDeck;
      finalOpponentLifePoints = updatedOpponentLifePoints;
      finalPlayerLifePoints = updatedPlayerLifePoints;
      finalBoardCards = updatedBoardCards;
    }

    return {
      applied: true,
      hand: updatedHand,
      deck: finalDeck,
      boardCards: finalBoardCards,
      opponentLifePoints: finalOpponentLifePoints,
      playerLifePoints: finalPlayerLifePoints
    };
  };

  const cardBackToDeck: RulesFunctions['cardBackToDeck'] = async ({ isOpponent, isRival, rule, targetId }) => {
    const { deck, setDeck } = sideUtils.applyToDeck(
      isOpponent,
      isRival,
      { playerDeck, opponentDeck },
      { updateOpponentDeck, updatePlayerDeck }
    );

    const [cardId] = targetId.split('/');
    const { costActionPoints, damagePoints, defensePoints } = rule;
    const originalCard = await cardsService.getCardById(cardId);
    if (originalCard) {
      if (costActionPoints) {
        originalCard.actionPoints = costActionPoints;
      }
      if (damagePoints) {
        originalCard.damagePoints = originalCard.damagePoints! + damagePoints!;
      }
      if (defensePoints) {
        originalCard.defensePoints = originalCard.defensePoints! + defensePoints!;
      }
      originalCard.tweet = new Tweet();
      const UUID = getRandomUUID();
      const updatedId = `${originalCard.id}-${UUID}`;
      originalCard.id = updatedId;
      originalCard.idUnique = updatedId;
      const player = sideUtils.applyToPlayer(isOpponent, isRival);
      const newDeck = processDuplicatedCards([...deck, originalCard], player);
      setDeck(newDeck);
      return { applied: true, deck: newDeck };
    }
    return { applied: false, deck };
  };

  const isApplicablePlayRule: RulesFunctions['isApplicablePlayRule'] = ({ playedCard, queueRules, rule, isLaunchedCard, targetId }) => {
    const ruleCardId = slugRuleCardId(playedCard.id, rule.id);
    const { applyWhen } = rule;

    // isApplicableRule when rule exists in queue rule or is a lose rule or is play rule
    const isApplicableRule =
      applyWhen === WhenEnum.LOSE ||
      (queueRules[applyWhen][ruleCardId] === undefined && applyWhen === WhenEnum.PLAY && isLaunchedCard) ||
      (!!queueRules[applyWhen][ruleCardId] && !queueRules[applyWhen][ruleCardId].applied) ||
      (!!targetId && applyWhen === WhenEnum.ALIVE && playedCard.id !== targetId);

    return isApplicableRule;
  };

  const getTargetCard: RulesFunctions['getTargetCard'] = ({ boardCards, rule, target }) => {
    const targetCards = boardCards.filter(boardCard => isApplicableRuleToTarget(rule, boardCard, target));
    const [targetCard] = targetCards;
    return targetCard;
  };

  const applyPointsToTarget: RulesFunctions['applyPointsToTarget'] = async ({
    boardCards,
    isOpponent,
    playedCard,
    rule,
    target,
    opponentBoardCards,
    playerBoardCards,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const copyBoardCards = [...boardCards];

    const { applyToCurrentCard, applyToOpponent } = rule;

    let targetCard = getTargetCard({ boardCards: copyBoardCards, rule, target });

    if (applyToCurrentCard && !applyToOpponent) {
      targetCard = playedCard;
    }

    if (!targetCard) return { applied: false, boardCards, opponentLifePoints, playerLifePoints };

    const {
      card: updatedCard,
      loseCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    } = await applyPointsToCard({
      boardCard: targetCard,
      isOpponent,
      opponentBoardCards,
      playedCard,
      playerBoardCards,
      rule,
      enhanceEffect: playedCard.enhanceEffect,
      opponentLifePoints,
      playerLifePoints
    });

    if (loseCards.length) pendingCards.current = loseCards;

    const updatedBoardCardIndex = copyBoardCards.findIndex(boardCard => boardCard.idUnique === updatedCard.idUnique);
    if (updatedBoardCardIndex !== -1) copyBoardCards[updatedBoardCardIndex] = updatedCard;

    return {
      applied: true,
      boardCards: copyBoardCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    };
  };

  const applyRuleToAvatar: RulesFunctions['applyRuleToAvatar'] = async ({
    boardCards,
    isOpponent,
    isRival,
    rule,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const { haveConditionLessDefensePoints } = rule;
    // Check haveConditionLessDefensePoints
    if (haveConditionLessDefensePoints && !haveConditionAvatarLessDefensePoints({ isOpponent, opponentLifePoints, playerLifePoints })) {
      return {
        applied: false,
        boardCards,
        opponentLifePoints,
        playerLifePoints
      };
    }
    const {
      applied,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    } = await applyPointsToAvatar({ isOpponent, isRival, rule, opponentLifePoints, playerLifePoints });

    return {
      applied,
      boardCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    };
  };

  const applyRuleLose: RulesFunctions['applyRuleLose'] = async ({
    boardCards,
    isOpponent,
    playedCard,
    rule,
    target,
    opponentBoardCards,
    playerBoardCards,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const { applyToCurrentCard } = rule;
    // Apply to current card
    if (target !== '' && applyToCurrentCard) {
      return await applyPointsToTarget({
        boardCards,
        isOpponent,
        playedCard,
        rule,
        target,
        opponentBoardCards,
        playerBoardCards,
        opponentLifePoints,
        playerLifePoints
      });
    }
    // Apply to random card
    return await applyPointsToRandomCardByRule({
      boardCards,
      isOpponent,
      playedCard,
      rule,
      opponentBoardCards,
      playerBoardCards,
      opponentLifePoints,
      playerLifePoints
    });
  };

  const getTargetIdByRule: RulesFunctions['getTargetIdByRule'] = ({ playedCard, rule, target, targetId }) => {
    const { applyToCurrentCard, applyToOpponent } = rule;
    // Apply to target opponent
    if (targetId === '' && applyToCurrentCard && applyToOpponent) {
      targetId = attackingCurrentCard.current?.id as string;
    }
    // Apply to current card
    if (targetId === '' && rule?.applyToCurrentCard) {
      targetId = playedCard.id;
    }
    // Apply to selected target
    if (targetId === '') {
      targetId = target;
    }
    return targetId;
  };

  const applyRulePoints: RulesFunctions['applyRulePoints'] = async ({
    boardCards,
    isOpponent,
    isRival,
    opponentBoardCards,
    playedCard,
    playerBoardCards,
    rule,
    target,
    targetId,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const { applyToAvatar, applyToAllCards, applyWhen } = rule;
    // Apply points to avatar
    if (applyToAvatar) {
      return await applyRuleToAvatar({ boardCards, isOpponent, isRival, rule, opponentLifePoints, playerLifePoints });
    }
    // Apply rule lose
    if (applyWhen === WhenEnum.LOSE) {
      const { applied, boardCards: updatedBoardCards } = await applyRuleLose({
        playedCard,
        boardCards,
        isOpponent,
        rule,
        target,
        opponentBoardCards,
        playerBoardCards,
        opponentLifePoints,
        playerLifePoints
      });
      return {
        applied,
        boardCards: updatedBoardCards,
        playerLifePoints,
        opponentLifePoints
      };
    }

    // Apply to target
    if (applyToTarget(targetId, rule)) {
      const {
        applied,
        boardCards: updatedBoardCards,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await applyPointsToTarget({
        boardCards,
        isOpponent,
        playedCard,
        rule,
        target,
        opponentBoardCards,
        playerBoardCards,
        opponentLifePoints,
        playerLifePoints
      });
      return {
        applied,
        boardCards: updatedBoardCards,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      };
    }

    // Apply to all card by rule
    if (applyToAllCards) {
      const {
        applied,
        boardCards: updatedBoardCards,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await applyPointsByRule({
        boardCards,
        isOpponent,
        opponentBoardCards,
        playedCard,
        playerBoardCards,
        rule,
        opponentLifePoints,
        playerLifePoints
      });
      return {
        applied,
        boardCards: updatedBoardCards,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      };
    }
    // Apply to random card
    const {
      applied,
      boardCards: updatedBoardCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    } = await applyPointsToRandomCardByRule({
      boardCards,
      isOpponent,
      playedCard,
      rule,
      opponentBoardCards,
      playerBoardCards,
      opponentLifePoints,
      playerLifePoints
    });
    return {
      applied,
      boardCards: updatedBoardCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    };
  };

  const applyRuleDestroy: RulesFunctions['applyRuleDestroy'] = ({ rule, targetId, boardCards }) => {
    const { needTarget } = rule;
    // Destroy need target
    if (targetId !== '' && needTarget) {
      return destroyTarget({ boardCards, rule, targetId });
    }
    // Destroy random cards
    return destroyCards({ rule, boardCards });
  };

  const applyRuleStop: RulesFunctions['applyRuleStop'] = ({ boardCards, rule, targetId, isOpponent, isRival }) => {
    const { needTarget } = rule;
    // Stop need target
    if (targetId !== '' && needTarget) {
      return stopTarget({ boardCards, isOpponent, isRival, rule, targetId });
    }
    // Stop all cards
    return stopCards({ boardCards, isOpponent, isRival, rule });
  };

  const applyRuleToken: RulesFunctions['applyRuleToken'] = async ({ boardCards, hand, isOpponent, isRival, playedCard, rule }) => {
    const { launchCards, tokenDefensePoints, tokenDamagePoints } = rule;
    let finalBoardCards = [...boardCards];
    const player = sideUtils.applyToPlayer(isOpponent, isRival);
    let { deck: updateDeck } = sideUtils.applyToDeck(
      isOpponent,
      isRival,
      { playerDeck, opponentDeck },
      { updateOpponentDeck, updatePlayerDeck }
    );
    const arrayLaunchCards = Array.from(Array(launchCards).keys());
    const newCards = arrayLaunchCards.map(() => {
      const newToken = new Token({ ...playedCard.token! });
      const newCard = newToken.tokenToCard(tokenDamagePoints!, tokenDefensePoints!);
      return newCard!;
    });
    updateDeck = processDuplicatedCards([...newCards, ...updateDeck], player);
    finalBoardCards = [...newCards, ...finalBoardCards];
    for await (const newCard of newCards) {
      updateDeck.shift();
      finalBoardCards = await showLeftoverAndRemoveCards(
        finalBoardCards,
        newCard,
        player === PlayerRoundEnum.OPPONENT && opponentIsArtificialIntelligence
      );
      await wait(500);
    }
    return { applied: true, hand, deck: updateDeck, boardCards: finalBoardCards };
  };

  const applyCardRulesPlay: RulesFunctions['applyCardRulesPlay'] = async ({
    deck,
    hand,
    isOpponent,
    opponentBoardCards,
    playedCard,
    rule,
    target = '',
    playerBoardCards,
    actionPoints,
    isLaunchedCard,
    currentQueueRules,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const isRival = sideUtils.getIsRival(rule);

    const isPlayer = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);
    const boardCards = isPlayer ? playerBoardCards : opponentBoardCards;

    let targetId = '';

    const isApplicableRule = isApplicablePlayRule({ playedCard, queueRules: currentQueueRules, rule, targetId: target, isLaunchedCard });

    const { ruleType, needTarget, launchCards } = rule;
    if (!isApplicableRule) return { applied: false, hand, deck, boardCards, opponentLifePoints, playerLifePoints };

    // Step 1 - Get targetId
    targetId = getTargetIdByRule({ playedCard, target, targetId, rule });

    // Step 2 - If rule type POINTS
    if (ruleType === RuleTypeEnum.POINTS) {
      const {
        applied: result,
        boardCards: finalBoardCards,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await applyRulePoints({
        boardCards,
        isOpponent,
        isRival,
        opponentBoardCards,
        playedCard,
        playerBoardCards,
        rule,
        target,
        targetId,
        opponentLifePoints,
        playerLifePoints
      });
      return {
        applied: result,
        boardCards: finalBoardCards,
        deck,
        hand,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      };
    }

    // Step 3 - If rule type DESTROY
    if (ruleType === RuleTypeEnum.DESTROY) {
      const { applied, boardCards: finalBoardCards } = applyRuleDestroy({ boardCards, rule, targetId });
      return { applied, hand, deck, boardCards: finalBoardCards, opponentLifePoints, playerLifePoints };
    }

    // Step 4 - If rule type STOP
    if (ruleType === RuleTypeEnum.STOP) {
      const { applied, boardCards: finalBoardCards } = applyRuleStop({ boardCards, isOpponent, isRival, rule, targetId });
      return { applied, hand, deck, boardCards: finalBoardCards, opponentLifePoints, playerLifePoints };
    }

    // Step 5 - If rule type NEW_CARD
    if (ruleType === RuleTypeEnum.NEW_CARD) {
      const {
        applied,
        deck: newDeck,
        hand: newHand
      } = await launchNewCard({ rule, deck, hand, isOpponent, actionPoints, opponentLifePoints, playerLifePoints });
      return { applied, hand: newHand, deck: newDeck, boardCards, opponentLifePoints, playerLifePoints };
    }

    // Step 6 - If rule type COPY_CARD
    if (ruleType === RuleTypeEnum.COPY_CARD && targetId !== '' && needTarget) {
      const {
        applied,
        boardCards: finalBoardCards,
        deck: newDeck,
        hand: newHand,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await copyCardsByTarget({
        boardCards,
        deck,
        hand,
        isOpponent,
        isRival,
        opponentBoardCards,
        playerBoardCards,
        rule,
        targetId,
        actionPoints,
        isPlayer,
        opponentLifePoints,
        playerLifePoints
      });
      return {
        applied,
        hand: newHand,
        deck: newDeck,
        boardCards: finalBoardCards,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      };
    }

    // Step 7 - If rule type BACK_TO_DECK
    if (ruleType === RuleTypeEnum.BACK_TO_DECK) {
      const { applied, deck: newDeck } = await cardBackToDeck({ rule, targetId, isOpponent, isRival });
      return {
        applied,
        hand,
        deck: newDeck,
        boardCards,
        opponentLifePoints,
        playerLifePoints
      };
    }

    // Step 8 - If rule type TOKEN
    if (ruleType === RuleTypeEnum.TOKEN && launchCards! > 0) {
      const {
        applied,
        boardCards: updatedBoardCards,
        deck: updatedDeck,
        hand: updatedHand
      } = await applyRuleToken({ playedCard, isOpponent, isRival, rule, hand, boardCards });
      return {
        applied,
        boardCards: updatedBoardCards,
        deck: updatedDeck,
        hand: updatedHand,
        opponentLifePoints,
        playerLifePoints
      };
    }

    return { applied: false, hand, deck, boardCards, opponentLifePoints, playerLifePoints };
  };

  const updateQueueAppliedRules: RulesFunctions['updateQueueAppliedRules'] = ({ playedCard, queueRules, rule }) => {
    const ruleCardId = slugRuleCardId(playedCard.id, rule.id);
    const { applyWhen } = rule;
    const excludeCards = [TypesEnum.ACTION, TypesEnum.SUPPORT];

    // Card with defensePoints <= 0 and the rule is not LOSE, then remove rule.
    if ((playedCard?.defensePoints as number) <= 0 && applyWhen !== WhenEnum.LOSE) {
      return false;
    }

    // Save rule if it doesn't exist.
    if (queueRules[applyWhen][ruleCardId] === undefined) {
      if (!excludeCards.includes(playedCard.type!.typeName)) {
        queueRules[applyWhen][ruleCardId] = rule;
      }
      // If the rule is LOSE and the card is dead, continue to apply the rule.
      if (applyWhen === WhenEnum.LOSE && (playedCard?.defensePoints as number) <= 0) {
        return true;
      }
      // If the rule isn't ALIVE, return false to apply it later.
      if (applyWhen !== WhenEnum.ALIVE) return false;
    } else {
      // If the rule is.LOSE and defensePoints > 0, return false to apply it later.
      if (applyWhen === WhenEnum.LOSE) {
        if ((playedCard?.defensePoints as number) > 0) return false;
        return true;
      }
      if (applyWhen === WhenEnum.ALIVE) return false;
    }

    return true;
  };

  const applyPreAlivePointsToTarget: RulesFunctions['applyPreAlivePointsToTarget'] = async ({
    rule,
    playedCard,
    isOpponent,
    opponentBoardCards,
    playerBoardCards,
    playerLifePoints,
    opponentLifePoints
  }) => {
    const isRival = sideUtils.getIsRival(rule);
    const isPlayer = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);
    const boardCards = isPlayer ? playerBoardCards : opponentBoardCards;

    if (!isApplicableRuleToTarget(rule, playedCard, playedCard.id, true)) {
      return { boardCards, opponentLifePoints, playerLifePoints };
    }

    const {
      card: updatedCard,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints,
      loseCards
    } = await applyPointsToCard({
      boardCard: playedCard,
      rule,
      isOpponent,
      opponentBoardCards,
      playerBoardCards,
      playedCard,
      enhanceEffect: playedCard.enhanceEffect,
      opponentLifePoints,
      playerLifePoints
    });

    if (loseCards.length) pendingCards.current = loseCards;

    const updatedBoardCards: BoardCards = boardCards.map(boardCard => {
      if (boardCard.idUnique === updatedCard.idUnique) {
        return updatedCard;
      }
      return boardCard;
    });

    return { boardCards: updatedBoardCards, opponentLifePoints: updatedOpponentLifePoints, playerLifePoints: updatedPlayerLifePoints };
  };

  const applyPreAliveRules: RulesFunctions['applyPreAliveRules'] = async ({
    isOpponent,
    opponentBoardCards,
    opponentLifePoints,
    playedCard,
    playerBoardCards,
    playerLifePoints,
    queueRules
  }) => {
    const isPlayer = !isOpponent;
    const boardCards = isPlayer ? playerBoardCards : opponentBoardCards;

    let copyBoardCards: BoardCards = [...boardCards];
    let finalPlayerLifePoints = playerLifePoints;
    let finalOpponentLifePoints = opponentLifePoints;

    await Promise.all(
      Object.values(queueRules[WhenEnum.ALIVE]).map(async ruleAlive => {
        const {
          boardCards,
          opponentLifePoints: updatedOpponentLifePoints,
          playerLifePoints: updatedPlayerLifePoints
        } = await applyPreAlivePointsToTarget({
          rule: ruleAlive as Rule,
          playedCard,
          isOpponent,
          opponentBoardCards,
          playerBoardCards,
          playerLifePoints,
          opponentLifePoints
        });
        copyBoardCards = boardCards;
        finalOpponentLifePoints = updatedOpponentLifePoints;
        finalPlayerLifePoints = updatedPlayerLifePoints;
      })
    );

    return { boardCards: copyBoardCards, opponentLifePoints: finalOpponentLifePoints, playerLifePoints: finalPlayerLifePoints };
  };

  const applyCardAliveRules: RulesFunctions['applyCardAliveRules'] = ({
    deck,
    hand,
    isOpponent,
    opponentBoardCards,
    opponentLifePoints,
    playedCard,
    playerBoardCards,
    playerLifePoints,
    targetId,
    queueRules
  }) => {
    let currentBoardCards = isOpponent ? opponentBoardCards : playerBoardCards;
    let finalOpponentLifePoints = opponentLifePoints;
    let finalPlayerLifePoints = playerLifePoints;
    Object.values(queueRules[WhenEnum.ALIVE]).forEach(async ruleAlive => {
      const {
        applied,
        boardCards,
        deck: newDeck,
        hand: newHand,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await applyCardRulesPlay({
        currentQueueRules: queueRules,
        deck,
        hand,
        isOpponent,
        opponentBoardCards,
        playedCard,
        playerBoardCards,
        rule: ruleAlive as Rule,
        target: targetId,
        opponentLifePoints,
        playerLifePoints
      });
      if (applied) {
        currentBoardCards = boardCards;
        hand = newHand;
        deck = newDeck;
        finalOpponentLifePoints = updatedOpponentLifePoints;
        finalPlayerLifePoints = updatedPlayerLifePoints;
      }
    });
    return { boardCards: currentBoardCards, opponentLifePoints: finalOpponentLifePoints, playerLifePoints: finalPlayerLifePoints };
  };

  const loopApplyCardRules: RulesFunctions['loopApplyCardRules'] = async ({
    deck,
    hand,
    isOpponent,
    opponentLifePoints,
    playerLifePoints,
    opponentBoardCards,
    playedCard,
    playedCardRules,
    playerBoardCards,
    actionPoints,
    isLaunchedCard,
    targetId,
    sameTypeRules
  }) => {
    // Start apply card rules, except its alive rules.
    for (const rule of playedCardRules) {
      const isRival = sideUtils.getIsRival(rule);
      const queueRules = getQueueRules(isOpponent, appliedRules.current);
      const isAppliedToPlayerBoardCards = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);
      const targetInvalid = rule.needTarget && targetId === '' && playedCardRules.length < 2;

      // Step 1 - Need a target and no target is selected
      if (targetInvalid)
        return {
          hand,
          deck,
          opponentBoardCards,
          playerBoardCards,
          opponentLifePoints,
          playerLifePoints
        };

      // Step 2 -  Save / Update queue rules with rule !== WhenEnum.PLAY to apply later
      if (rule.applyWhen !== WhenEnum.PLAY) {
        const continueApplyingRule = updateQueueAppliedRules({ queueRules, playedCard, rule });
        // Check continueApplyingRule
        if (!continueApplyingRule)
          return {
            hand,
            deck,
            opponentBoardCards,
            playerBoardCards,
            opponentLifePoints,
            playerLifePoints
          };
      }

      // Step 3 -  Apply rule
      const {
        applied: appliedRule,
        hand: updatedHand,
        deck: updatedDeck,
        boardCards: appliedBoardCards,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await applyCardRulesPlay({
        currentQueueRules: queueRules,
        deck,
        hand,
        isOpponent,
        opponentLifePoints,
        playerLifePoints,
        opponentBoardCards,
        playedCard,
        playerBoardCards,
        rule,
        actionPoints,
        isLaunchedCard,
        target: targetId
      });

      [hand, deck, playerLifePoints, opponentLifePoints] = [updatedHand, updatedDeck, updatedPlayerLifePoints, updatedOpponentLifePoints];

      // Step 4 -  Return boardcards by isAppliedToPlayerBoardCards
      if (!isAppliedToPlayerBoardCards) {
        opponentBoardCards = appliedBoardCards;
      } else {
        playerBoardCards = appliedBoardCards;
      }

      //  Step 5 -  If the applied rule is of the same type as the rest of the rules, break the bucle.
      if (appliedRule && sameTypeRules) break;
    }

    return {
      hand,
      deck,
      opponentBoardCards,
      playerBoardCards,
      opponentLifePoints,
      playerLifePoints
    };
  };

  const applyCardRules: RulesFunctions['applyCardRules'] = async ({
    deck,
    hand,
    isOpponent,
    opponentBoardCards,
    playedCard,
    playedCardRules,
    playerBoardCards,
    actionPoints,
    isLaunchedCard,
    targetId,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const queueRules = getQueueRules(isOpponent, appliedRules.current);
    const { type, rules } = playedCard;
    const isPlayer = !isOpponent;

    let finalPlayerBoardCards: BoardCards = [...playerBoardCards];
    let finalOpponentBoardCards: BoardCards = [...opponentBoardCards];
    let finalHand: Hand = [...hand];
    let finalDeck: Deck = [...deck];
    let finalPlayerLifePoints = playerLifePoints;
    let finalOpponentLifePoints = opponentLifePoints;

    // Step 1 - Apply previously saved alive rules on game board when card is launched.
    if (isLaunchedCard && type?.typeName !== TypesEnum.ACTION) {
      const {
        boardCards: newBoard,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await applyPreAliveRules({
        queueRules,
        playedCard,
        isOpponent,
        opponentBoardCards,
        playerBoardCards,
        playerLifePoints,
        opponentLifePoints
      });
      finalPlayerBoardCards = isPlayer ? newBoard : playerBoardCards;
      finalOpponentBoardCards = isPlayer ? opponentBoardCards : newBoard;
      finalPlayerLifePoints = updatedPlayerLifePoints;
      finalOpponentLifePoints = updatedOpponentLifePoints;
    }

    // Step 2 - Check if doesn't exist rules.
    if (!rules?.length)
      return {
        hand: finalHand,
        deck: finalDeck,
        playerBoardCards: finalPlayerBoardCards,
        opponentBoardCards: finalOpponentBoardCards,
        opponentLifePoints,
        playerLifePoints
      };

    // Step 3 - Card with multiple rules, check the type of rules.
    const sameTypeRules = boardCardsUtils.sameTypeRules(playedCardRules);
    // Step 4 - Apply card rules, except its alive rules.
    const {
      hand: updatedHand,
      deck: updatedDeck,
      playerLifePoints: updatedPlayerLifePoints,
      opponentLifePoints: updatedOpponentLifePoints,
      opponentBoardCards: updatedOpponentBoardCards,
      playerBoardCards: updatedPlayerBoardCards
    } = await loopApplyCardRules({
      playedCard,
      isOpponent,
      sameTypeRules,
      playedCardRules,
      opponentBoardCards: finalOpponentBoardCards,
      playerBoardCards: finalPlayerBoardCards,
      hand: finalHand,
      deck: finalDeck,
      targetId,
      actionPoints,
      isLaunchedCard,
      opponentLifePoints: finalOpponentLifePoints,
      playerLifePoints: finalPlayerLifePoints
    });

    [finalHand, finalDeck, finalOpponentBoardCards, finalPlayerBoardCards, finalPlayerLifePoints, finalOpponentLifePoints] = [
      updatedHand,
      updatedDeck,
      updatedOpponentBoardCards,
      updatedPlayerBoardCards,
      updatedPlayerLifePoints,
      updatedOpponentLifePoints
    ];

    // Step 5 - If the card is live and has alive rules, apply them.
    const isApplicableAliveRules =
      playedCard.defensePoints! > 0 && !checkSomeAliveRule(playedCardRules) && playedCard.type?.typeName !== TypesEnum.ACTION;

    if (isApplicableAliveRules) {
      const {
        boardCards: appliedBoardCards,
        opponentLifePoints: postOpponentLifePoints,
        playerLifePoints: postPlayerLifePoints
      } = applyCardAliveRules({
        queueRules,
        playedCard,
        isOpponent,
        opponentBoardCards: finalOpponentBoardCards,
        playerBoardCards: finalPlayerBoardCards,
        hand: finalHand,
        deck: finalDeck,
        playerLifePoints: finalPlayerLifePoints,
        opponentLifePoints: finalOpponentLifePoints,
        targetId
      });
      if (isOpponent) {
        finalOpponentBoardCards = appliedBoardCards;
      } else {
        finalPlayerBoardCards = appliedBoardCards;
      }
      finalPlayerLifePoints = postPlayerLifePoints;
      finalOpponentLifePoints = postOpponentLifePoints;
    }

    return {
      hand: finalHand,
      deck: finalDeck,
      opponentBoardCards: finalOpponentBoardCards,
      playerBoardCards: finalPlayerBoardCards,
      opponentLifePoints: finalOpponentLifePoints,
      playerLifePoints: finalPlayerLifePoints
    };
  };

  const afterAttackRemoveRules: RulesFunctions['afterAttackRemoveRules'] = ({ card, isOpponent, opponentBoardCards, playerBoardCards }) => {
    let finalOpponentBoardCards = [...opponentBoardCards];
    let finalPlayerBoardCards = [...playerBoardCards];
    const cardRules = getCardRules(card, allRules);
    const currentQueueRules = getQueueRules(isOpponent, appliedRules.current);
    for (const rule of cardRules) {
      const isRival = sideUtils.getIsRival(rule);
      const isPlayer = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);
      const boardCards = isPlayer ? finalPlayerBoardCards : finalOpponentBoardCards;
      const appliedBoardCards = isNeccesaryRemoveCardRule(currentQueueRules, card, boardCards);
      if (isPlayer) {
        finalPlayerBoardCards = appliedBoardCards;
      } else {
        finalOpponentBoardCards = appliedBoardCards;
      }
    }
    return { playerBoardCards: finalPlayerBoardCards, opponentBoardCards: finalOpponentBoardCards };
  };

  // Mutating original arrays
  const onAttackApplyAttackedCardRules: RulesFunctions['onAttackApplyAttackingCardRules'] = async ({
    attackingCard,
    attackedCard,
    isOpponent,
    opponentBoardCards,
    playerBoardCards,
    hand,
    deck,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const attackedCardRules = getCardRules(attackedCard, allRules);
    if (!attackedCardRules?.length) return { deck, hand, opponentLifePoints, playerLifePoints, opponentBoardCards, playerBoardCards };

    const {
      deck: finalDeck,
      hand: finalHand,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints,
      opponentBoardCards: finalOpponentBoardCards,
      playerBoardCards: finalPlayerBoardCards
    } = await applyCardRules({
      deck,
      hand,
      isOpponent,
      opponentBoardCards,
      playedCard: attackedCard,
      playedCardRules: attackedCardRules,
      playerBoardCards,
      targetId: attackingCard.id,
      opponentLifePoints,
      playerLifePoints
    });

    return {
      deck: finalDeck,
      hand: finalHand,
      opponentBoardCards: finalOpponentBoardCards,
      playerBoardCards: finalPlayerBoardCards,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    };
  };

  const onAttackApplyAttackingCardRules: RulesFunctions['onAttackApplyAttackingCardRules'] = async ({
    attackingCard,
    attackedCard,
    isOpponent,
    opponentBoardCards,
    playerBoardCards,
    hand,
    deck,
    opponentLifePoints,
    playerLifePoints
  }) => {
    const attackingCardRules = getCardRules(attackingCard, allRules);
    if (!attackingCardRules?.length) return { deck, hand, opponentLifePoints, playerLifePoints, opponentBoardCards, playerBoardCards };

    const {
      deck: finalDeck,
      hand: finalHand,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints,
      opponentBoardCards: finalOpponentBoardCards,
      playerBoardCards: finalPlayerBoardCards
    } = await applyCardRules({
      deck,
      hand,
      isOpponent,
      opponentLifePoints,
      playerLifePoints,
      opponentBoardCards,
      playerBoardCards,
      playedCard: attackingCard,
      playedCardRules: attackingCardRules,
      targetId: attackedCard.id
    });

    return {
      deck: finalDeck,
      hand: finalHand,
      opponentLifePoints: updatedOpponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints,
      opponentBoardCards: finalOpponentBoardCards,
      playerBoardCards: finalPlayerBoardCards
    };
  };

  const onAttackApplyCardRules: RulesFunctions['onAttackApplyCardRules'] = async ({
    attackedCard,
    attackingCard,
    deck,
    enemyDeck,
    enemyHand,
    hand,
    isOpponent,
    opponentLifePoints,
    playerLifePoints,
    opponentBoardCards,
    playerBoardCards
  }) => {
    const attackedCardRules = getCardRules(attackedCard, allRules);
    const attackingCardRules = getCardRules(attackingCard, allRules);
    attackingCurrentCard.current = attackingCard;

    let finalOpponentBoardCards = [...opponentBoardCards];
    let finalPlayerBoardCards = [...playerBoardCards];
    let finalDeck = [...deck];
    let finalHand = [...hand];
    let finalEnemyDeck = [...enemyDeck];
    let finalEnemyHand = [...enemyHand];
    let finalPlayerLifePoints = playerLifePoints;
    let finalOpponentLifePoints = opponentLifePoints;

    const updateAttackCards = () => {
      const updatedAttackedCard = (!isOpponent ? finalOpponentBoardCards : finalPlayerBoardCards).find(
        card => card.idUnique === attackedCard.idUnique
      );
      const updatedAttackingCard = (isOpponent ? finalOpponentBoardCards : finalPlayerBoardCards).find(
        card => card.idUnique === attackingCard.idUnique
      );

      if (updatedAttackedCard) attackedCard = updatedAttackedCard;
      if (updatedAttackingCard) attackingCard = updatedAttackingCard;
    };

    // On attack apply rules of cards attacked
    if (attackedCardRules?.length) {
      const {
        deck: updatedEnemyDeck,
        hand: updatedEnemyHand,
        playerLifePoints: updatedPlayerLifepoints,
        opponentLifePoints: updatedOpponentLifePoints,
        opponentBoardCards: updatedOpponentBoardCards,
        playerBoardCards: updatedPlayerBoardCards
      } = await onAttackApplyAttackedCardRules({
        attackedCard,
        attackingCard,
        deck: enemyDeck,
        hand: enemyHand,
        isOpponent: !isOpponent,
        opponentBoardCards: finalOpponentBoardCards,
        playerBoardCards: finalPlayerBoardCards,
        opponentLifePoints,
        playerLifePoints
      });

      [finalEnemyHand, finalEnemyDeck, finalPlayerBoardCards, finalOpponentBoardCards, finalPlayerLifePoints, finalOpponentLifePoints] = [
        updatedEnemyHand,
        updatedEnemyDeck,
        updatedPlayerBoardCards,
        updatedOpponentBoardCards,
        updatedPlayerLifepoints,
        updatedOpponentLifePoints
      ];
      updateAttackCards();
    }
    // On attack apply rules of cards attacking
    const canApplyAttackingRules =
      (attackingCard.rules?.some(rule => rule.applyWhen === WhenEnum.LOSE) || attackingCard.defensePoints! > 0) &&
      attackingCardRules?.length;

    if (canApplyAttackingRules) {
      const {
        deck: updatedDeck,
        hand: updatedHand,
        playerLifePoints: updatedPlayerLifepoints,
        opponentLifePoints: updatedOpponentLifePoints,
        opponentBoardCards: updatedOpponentBoardCards,
        playerBoardCards: updatedPlayerBoardCards
      } = await onAttackApplyAttackingCardRules({
        attackedCard,
        attackingCard,
        deck,
        hand,
        isOpponent,
        opponentBoardCards: finalOpponentBoardCards,
        playerBoardCards: finalPlayerBoardCards,
        opponentLifePoints: finalOpponentLifePoints,
        playerLifePoints: finalPlayerLifePoints
      });

      [finalHand, finalDeck, finalPlayerBoardCards, finalOpponentBoardCards, finalPlayerLifePoints, finalOpponentLifePoints] = [
        updatedHand,
        updatedDeck,
        updatedPlayerBoardCards,
        updatedOpponentBoardCards,
        updatedPlayerLifepoints,
        updatedOpponentLifePoints
      ];
      updateAttackCards();
    }
    if (attackedCard.defensePoints! <= 0) {
      const { opponentBoardCards: updatedOpponentBoardCards, playerBoardCards: updatedPlayerBoardCards } = afterAttackRemoveRules({
        card: attackedCard,
        isOpponent: !isOpponent,
        opponentBoardCards: finalOpponentBoardCards,
        playerBoardCards: finalPlayerBoardCards
      });
      [finalOpponentBoardCards, finalPlayerBoardCards] = [updatedOpponentBoardCards, updatedPlayerBoardCards];
    }
    if (attackingCard.defensePoints! <= 0) {
      const { opponentBoardCards: updatedOpponentBoardCards, playerBoardCards: updatedPlayerBoardCards } = afterAttackRemoveRules({
        card: attackingCard,
        isOpponent,
        opponentBoardCards: finalOpponentBoardCards,
        playerBoardCards: finalPlayerBoardCards
      });
      [finalOpponentBoardCards, finalPlayerBoardCards] = [updatedOpponentBoardCards, updatedPlayerBoardCards];
    }
    return {
      enemy: { deck: finalEnemyDeck, hand: finalEnemyHand },
      player: { deck: finalDeck, hand: finalHand },
      boardCards: { opponentBoardCards: finalOpponentBoardCards, playerBoardCards: finalPlayerBoardCards },
      lifePoints: { opponentLifePoints: finalOpponentLifePoints, playerLifePoints: finalPlayerLifePoints }
    };
  };

  return {
    allRules,
    appliedRules,
    pendingCards,
    applyCardRules,
    onAttackApplyCardRules,
    isStoppedCard: isStoppedCard
  };
};

export default useGameBoardRules;
