import { useState } from 'react';
import { DataDialogue, SideOfScreen } from '../../../types';

interface useDialogueParams {
  next: () => void;
  current: DataDialogue;
  typing?: boolean;
  interlocutor?: DataDialogue | null;
}

const useDialogue = ({ current, next, interlocutor, typing = false }: useDialogueParams) => {
  const [renderText, setRenderText] = useState<boolean>(true);
  const actual: SideOfScreen = current.side;
  const dataLeft = current.side === 'left' ? current : interlocutor;
  const dataRight = current.side === 'right' ? current : interlocutor;

  const handleStepTimeout = (): void => {
    setRenderText(true);
    next();
  };

  const handleStep = (): void => {
    if (renderText && typing) {
      setRenderText(false);
      return;
    }
    handleStepTimeout();
  };

  return {
    handleStep,
    handleStepTimeout,
    renderText,
    actual,
    dataLeft,
    dataRight
  };
};

export default useDialogue;
