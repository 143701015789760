import { useFocusEffect } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Image, ImageBackground, ImageSourcePropType, Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { GestureHandlerRootView, ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { gameConfiguration as gameConfigurationData } from '../../assets/data/configuration';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import GuestRegisterModal from '../../commons/components/GuestRegisterModal/GuestRegisterModal';
import NextReward from '../../commons/components/NextReward';
import Slides from '../../commons/components/Slides';
import SnackBar from '../../commons/components/SnackBar';
import { Text, View } from '../../commons/components/Themed';
import TopHeader from '../../commons/components/TopHeader';
import { CampaignDto } from '../../commons/dtos/campaign.dto';
import { GameBoardConfigurations } from '../../commons/dtos/game-board-configuration.dto';
import { Mission } from '../../commons/dtos/mission.dto';
import { GendersEnum, PlayerRoundEnum, PlayerTypesEnum } from '../../commons/dtos/player.dto';
import { MissionProgressStatusEnum } from '../../commons/dtos/progress.dto';
import { Rule } from '../../commons/dtos/rule.dto';
import { ApiError } from '../../commons/errors/api-error';
import adjust from '../../commons/styles/TextAdjust';
import { isNativeOrPWA, processDuplicatedCards } from '../../commons/utils';
import boardCards from '../../commons/utils/gameboard/boardCards';
import {
  campaignCheck,
  campaignEnemyUnknown,
  campaignFrame,
  missionBackground,
  missionBackgroundMobile,
  missionEnemyImage
} from '../../constants/Images';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { setLoadingMissionBackground } from '../../redux/Loading-mission/loadingMissionBackground';
import { Profile } from '../../redux/Profile/profileReducer';
import store from '../../redux/store';
import CampaignService from '../../services/campaigns.service';
import PlayerService from '../../services/player.service';
import RulesService from '../../services/rules.service';
import { CampaignProps } from '../../types';

const playerService = new PlayerService();
const campaignService = new CampaignService();

const ITEMS_TO_RENDER = 4;

const Campaign = ({ navigation }: CampaignProps) => {
  const [campaign, setCampaign] = useState<CampaignDto>();
  const [gameConfiguration, setGameConfiguration] = useState<GameBoardConfigurations>({ ...gameConfigurationData });
  const [currentMission, setCurrentMission] = useState<Mission>();
  const [selectedMission, setSelectedMission] = useState<Mission>();
  const [showGuestModal, setShowGuestModal] = useState<boolean>(false);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  const { progress, gender, type } = useSelector(({ profile }: { profile: Profile }) => profile.player!);

  const opacityBg = useRef<Animated.Value>(new Animated.Value(0)).current;

  const selectedMissionAnimation = Animated.timing(opacityBg, {
    toValue: 1,
    useNativeDriver: Platform.OS !== 'web',
    duration: 500
  });

  const AnimatedImage = Animated.createAnimatedComponent(ImageBackground);

  const pageIndex = Math.floor(((currentMission?.order ?? 1) - 1) / ITEMS_TO_RENDER);

  const LIMIT_GUEST_MISSIONS = 4;

  const fetchRules = async (): Promise<Rule[]> => {
    try {
      const rulesService = new RulesService();
      const rules = await rulesService.getRules();
      return rules;
    } catch (err) {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando las reglas`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
      return [];
    }
  };

  useEffect(() => {
    campaignService
      .getCampaigns()
      .then(campaigns => {
        const [firstCampaign] = campaigns;
        const mission = progress ? firstCampaign?.missions.find(({ id }) => id === progress.missionId) : firstCampaign?.missions[0];
        setCurrentMission(mission!);
        setSelectedMission(mission!);
        setCampaign(firstCampaign);
      })
      .catch((err: any) => {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando las misiones de campaña`, `[Detalle]: ${ex.detail}`);
        } else {
          setMessage(`Error: ${err}`);
        }
      });
  }, [checkShowLoginModal, progress]);

  useEffect(() => {
    if (!selectedMission) return;
    selectedMissionAnimation.start();
  }, [selectedMission]);

  useFocusEffect(
    useCallback(() => {
      const originalGameConfiguration = () => {
        if (gameConfiguration && JSON.stringify(gameConfiguration).includes('"done":true')) {
          const newGameConfigurationData = JSON.parse(JSON.stringify(gameConfiguration).replace('"done":true', '"done":false'));
          setGameConfiguration({ ...newGameConfigurationData });
        }
      };
      if (selectedMission) originalGameConfiguration();
    }, [selectedMission])
  );

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'relative',
      alignItems: 'center',
      backgroundColor: 'black',
      paddingHorizontal: 0
    },
    campaignBackground: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%'
    },
    mainContent: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
      width: '100%',
      maxHeight: '100%',
      paddingHorizontal: 30
    },
    topContent: {
      width: '100%',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 0,
      maxWidth: isNativeOrPWA ? 420 : 568
    },
    topContentScroll: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 0
    },
    topContentButton: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto'
    },
    bottomContent: {
      width: '100%',
      paddingHorizontal: isNativeOrPWA ? 20 : 40,
      flexDirection: 'row',
      justifyContent: isNativeOrPWA ? 'space-between' : 'center',
      alignItems: 'center',
      paddingVertical: 15
    },
    title: {
      fontSize: isNativeOrPWA ? adjust(22) : 32,
      marginVertical: isNativeOrPWA ? adjust(10) : 22,
      color: 'white',
      fontWeight: '900'
    },
    text: {
      fontSize: isNativeOrPWA ? adjust(14) : 20,
      lineHeight: isNativeOrPWA ? adjust(22) : 30,
      color: 'white',
      fontWeight: '500'
    },
    subtitle: {
      marginTop: isNativeOrPWA ? 15 : 25,
      fontSize: isNativeOrPWA ? adjust(14) : 20,
      lineHeight: isNativeOrPWA ? adjust(22) : 30,
      color: 'white',
      fontWeight: '500'
    },
    buttonContainer: {
      justifyContent: 'center',
      flexDirection: 'row',
      marginLeft: 0,
      flexGrow: 0,
      maxWidth: isNativeOrPWA ? 150 : 188,
      marginTop: isNativeOrPWA ? 0 : 40
    },
    buttonText: {
      paddingHorizontal: isNativeOrPWA ? 10 : 20,
      color: '#000',
      fontWeight: '700',
      fontSize: isNativeOrPWA ? adjust(13) : 18,
      textTransform: 'uppercase',
      paddingBottom: isNativeOrPWA ? 0 : 4,
      textAlign: 'center'
    },
    picker: {
      maxHeight: isNativeOrPWA ? 80 : 135,
      height: isNativeOrPWA ? 80 : 135,
      width: isNativeOrPWA ? 400 : 700,
      maxWidth: isNativeOrPWA ? 400 : 700,
      position: 'relative',
      flexDirection: 'row',
      flexGrow: 1
    },
    slide: {
      width: '25%',
      height: '100%',
      paddingHorizontal: 10,
      position: 'relative'
    },
    slideOpen: {
      cursor: 'pointer',
      position: 'relative'
    },
    slideUnselected: {
      opacity: 0.75
    },
    slideSelected: {
      transform: [{ scale: 1.1 }]
    },
    imageSlide: {
      height: '100%',
      width: '100%'
    },
    boss: {
      position: 'relative',
      left: '7%',
      top: '4%',
      height: '90%',
      width: '90%'
    },
    check: {
      height: isNativeOrPWA ? 34 : 47,
      width: isNativeOrPWA ? 34 : 47,
      position: 'absolute',
      top: -10,
      left: 0,
      zIndex: 1
    },
    slideStyle: {
      flexDirection: 'row',
      width: '100%'
    }
  });

  const getMissionBackground = (): ImageSourcePropType => {
    const bgKey = selectedMission?.backgroundImage.replace('.png', '').replace('[GENDER]', gender);
    const background = isNativeOrPWA ? missionBackgroundMobile : missionBackground;
    if (bgKey) return background[bgKey];
    const [maleTutorial, femaleTutorial] = Object.keys(background);
    return gender === GendersEnum.FEMALE ? background[femaleTutorial] : background[maleTutorial];
  };

  const getMissionEnemyImage = (mission: Mission): ImageSourcePropType => {
    const enemyKey = mission.enemyImage.replace('.png', '').replace('[GENDER]', gender);
    return missionEnemyImage[enemyKey];
  };

  const goCampaign = async (): Promise<void> => {
    if (type === PlayerTypesEnum.GUEST && (selectedMission?.order || 0) > LIMIT_GUEST_MISSIONS) return setShowGuestModal(true);

    store.dispatch(setLoadingMissionBackground(getMissionBackground()));
    const { missions } = gameConfiguration as unknown as GameBoardConfigurations;
    const missionGameConfig = missions.find(mission => selectedMission?.id === mission.id);
    const rulesPromise = fetchRules();
    const missionPromise = campaignService.getMissionDetails(selectedMission!.id);

    const [rules, mission] = await Promise.all([rulesPromise, missionPromise]);

    if (!(missionGameConfig && mission)) return;

    const gameConfig = { ...missionGameConfig, ...mission };

    gameConfig.player!.deck = mission.player.deck.flatMap(boardCards.formatCard);
    gameConfig.opponent!.deck = mission.opponent.deck.flatMap(boardCards.formatCard);

    gameConfig.player!.deck = processDuplicatedCards(gameConfig.player!.deck, PlayerRoundEnum.PLAYER);
    gameConfig.opponent!.deck = processDuplicatedCards(gameConfig.opponent!.deck, PlayerRoundEnum.OPPONENT);

    gameConfig.rules = rules;

    const isNextMission = currentMission!.order + 1 == mission!.order;

    if (
      gameConfig.id &&
      (gameConfig.id === currentMission!.id || (isNextMission && progress?.missionStatus == MissionProgressStatusEnum.COMPLETED))
    ) {
      try {
        playerService.updateProgress(mission!.id, MissionProgressStatusEnum.STARTED);
      } catch (err) {
        store.dispatch(setLoadingMissionBackground(undefined));
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema actualizando el estado de la misión`, `[Detalle]: ${ex.detail}`);
        } else {
          setMessage(`Error: ${err}`);
        }
      }
    }

    const state = navigation.getState();
    if (state.routes.length > 0) {
      navigation.pop(0);
    }

    store.dispatch(setLoadingMissionBackground(undefined));

    if (gameConfig.dialoguesConfiguration && gameConfig.dialoguesConfiguration.length > 0) {
      navigation.navigate('MissionDialogue', { gameConfig });
    } else {
      navigation.navigate('GameBoard', { gameConfig });
    }
  };

  const ButtonCampaign = (
    <TouchableWithoutFeedback onPress={goCampaign}>
      <View style={styles.buttonContainer}>
        <ButtonShape bgColor="white" heightBtn={isNativeOrPWA ? 34 : 52}>
          <Text style={styles.buttonText}>Comenzar</Text>
        </ButtonShape>
      </View>
    </TouchableWithoutFeedback>
  );

  const closeGuestModal = () => setShowGuestModal(false);

  return (
    <GestureHandlerRootView style={styles.container}>
      {showGuestModal && <GuestRegisterModal handleClose={closeGuestModal} />}
      <AnimatedImage
        source={getMissionBackground()}
        style={[
          styles.campaignBackground,
          {
            opacity: opacityBg
          },
          {
            transform: [
              {
                scale: opacityBg.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.8, 1]
                })
              }
            ]
          }
        ]}
      />
      <LinearGradient style={styles.gradient} start={{ x: 0, y: 0.5 }} end={{ x: 1, y: 0.5 }} colors={['black', 'transparent']} />
      <TopHeader withBack />
      <View style={styles.mainContent}>
        <View style={styles.topContent}>
          <ScrollView style={styles.topContentScroll}>
            <Text style={styles.title}>
              {selectedMission?.order}
              {'. '}
              {selectedMission?.name}
            </Text>
            <Text style={styles.text}>{selectedMission?.description}</Text>
            {!!selectedMission?.rewards.length && (
              <>
                <Text style={styles.subtitle}>RECOMPENSAS AL ACABAR LA MISIÓN:</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                  {selectedMission?.rewards.map(({ type }: { type: any }, index) => (
                    <NextReward short key={index} reward={type} />
                  ))}
                </View>
              </>
            )}
          </ScrollView>
          {!isNativeOrPWA && <View style={styles.topContentButton}>{ButtonCampaign}</View>}
        </View>
        <View style={styles.bottomContent}>
          <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
          {campaign && campaign?.missions.length > 1 && isNativeOrPWA && ButtonCampaign}
          <Slides parentStyle={styles.picker} itemsToRender={ITEMS_TO_RENDER} style={styles.slideStyle} initialStep={pageIndex}>
            {campaign?.missions?.map((mission: Mission, index: number) => {
              const isCurrent = currentMission ? mission.id == currentMission.id : mission.order == 1;

              let isOpen = false;
              let isDone = false;
              const isCurrentMissionCompleted = progress!.missionStatus === MissionProgressStatusEnum.COMPLETED;

              if (currentMission) {
                isOpen = mission.order <= currentMission.order || (mission.order == currentMission.order + 1 && isCurrentMissionCompleted);
                if (isCurrent) {
                  isDone = isCurrentMissionCompleted;
                } else {
                  isDone = mission.order < currentMission.order;
                }
              }

              const isSelected = mission.id === selectedMission?.id;
              const boss = !isOpen ? campaignEnemyUnknown : getMissionEnemyImage(mission);
              let bg = isOpen ? campaignFrame.current : campaignFrame.default;
              if (isDone) bg = campaignFrame.done;

              return (
                <TouchableWithoutFeedback key={index} onPress={() => isOpen && setSelectedMission(mission)}>
                  <View
                    style={[
                      styles.slide,
                      isOpen && styles.slideOpen,
                      isOpen && (!isSelected ? styles.slideUnselected : styles.slideSelected)
                    ]}
                  >
                    {isDone && <Image style={styles.check} source={campaignCheck} />}
                    <ImageBackground source={bg} style={styles.imageSlide} resizeMode="contain">
                      <Image style={styles.boss} source={boss} resizeMode="contain" />
                    </ImageBackground>
                  </View>
                </TouchableWithoutFeedback>
              );
            })}
          </Slides>
        </View>
      </View>
    </GestureHandlerRootView>
  );
};

export default Campaign;
