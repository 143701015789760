import { useLayoutEffect, useRef } from 'react';
import { StyleSheet, Text, Animated, Platform, View } from 'react-native';
import { DataDialogue, SideOfScreen } from '../../../types';
import { isNativeOrPWA } from '../../utils';
import AnimatedTyping from '../AnimatedTyping/AnimatedTyping';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import TextWithHTML from '../TextWithHTML';
import Dialog from './Dialogue';

interface SideCharacterProps {
  data: DataDialogue;
  actual: SideOfScreen;
  render: boolean;
  typing?: boolean;
}

const SideCharacter = ({ data, actual, render, typing = false }: SideCharacterProps) => {
  const { rw, rh } = useRatioDimensions();

  const characterOpacity = useRef(new Animated.Value(0)).current;
  const { avatarImage, characterName, text, side, color } = data || ({} as DataDialogue);
  const leftSide = side === 'left';

  useLayoutEffect(() => {
    Animated.timing(characterOpacity, { toValue: 1, useNativeDriver: Platform.OS !== 'web', duration: 1000 }).start();
    return () => Animated.timing(characterOpacity, { toValue: 1, useNativeDriver: Platform.OS !== 'web', duration: 1000 }).reset();
  }, [avatarImage]);

  if (!text || !avatarImage) return null;

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      height: rh(100),
      width: rw(100)
    },
    avatarImage: {
      position: 'absolute',
      top: '10%',
      height: '90%',
      width: isNativeOrPWA ? rw(25) : 380
    },
    avatarLeft: {
      left: 0
    },
    avatarRight: {
      right: 0
    },
    dialogLeft: {
      left: isNativeOrPWA ? rw(15) : 280
    },
    dialogRight: {
      right: isNativeOrPWA ? rw(15) : 280
    },
    textTitleStyle: {
      fontWeight: '400',
      fontSize: isNativeOrPWA ? 20 : 32,
      fontFamily: 'Inlanders',
      color: '#FFF',
      paddingHorizontal: isNativeOrPWA ? 10 : 20,
      marginBottom: 10
    },
    textStyle: {
      fontSize: isNativeOrPWA ? 12 : 16,
      color: '#FFF',
      fontWeight: '500',
      paddingHorizontal: isNativeOrPWA ? 10 : 20
    }
  });

  return (
    <View style={styles.container}>
      <Animated.Image
        source={avatarImage}
        resizeMode="contain"
        style={[styles.avatarImage, { opacity: characterOpacity || 0 }, leftSide ? styles.avatarLeft : styles.avatarRight]}
      />
      {actual === data.side && (
        <Dialog color={color} side={side} width={isNativeOrPWA ? rw(40) : rw(30)} style={leftSide ? styles.dialogLeft : styles.dialogRight}>
          <>
            <Text style={styles.textTitleStyle}>{characterName}</Text>
            {typing ? (
              <AnimatedTyping typing={typing} textTotal={text} textStyles={styles.textStyle} render={render} />
            ) : (
              <TextWithHTML
                generalTextStyle={styles.textStyle}
                htmlStyles={{ bold: { fontWeight: 'bold' }, italic: { fontStyle: 'italic' } }}
                text={text}
              />
            )}
          </>
        </Dialog>
      )}
    </View>
  );
};

export default SideCharacter;
