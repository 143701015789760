import { TouchableWithoutFeedback, View, Image, StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import { isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';

interface UserMenuButtonProps {
  imageSource: any;
  handleNavigate: () => void;
}

const UserMenuButton = ({ handleNavigate, imageSource }: UserMenuButtonProps) => {
  const { vw } = useUnits();

  const styles = StyleSheet.create({
    buttonContainer: {
      marginHorizontal: vw(0.5),
      paddingTop: 5
    },
    button: {
      padding: vw(1),
      paddingBottom: vw(1.3),
      cursor: 'pointer'
    },
    buttonIcon: { height: isNativeOrPWA ? 18 : 32, width: isNativeOrPWA ? 18 : 32 }
  });

  return (
    <View style={styles.buttonContainer}>
      <TouchableWithoutFeedback onPress={handleNavigate}>
        <View>
          <ButtonShape square bgColor="gray" heightBtn={isNativeOrPWA ? 34 : 56}>
            <View style={styles.button}>
              <Image style={styles.buttonIcon} source={imageSource} resizeMode={'contain'}></Image>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default UserMenuButton;
