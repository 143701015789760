/* eslint-disable @typescript-eslint/no-var-requires */
export const lottieFire = require('../assets/lottie/60963-fire-element-effect-animation.json');
export const lottieRedFire = require('../assets/lottie/52717-fire.json');
export const lottieShieldBadge = require('../assets/lottie/31175-shield-badge.json');
export const lottieShield = require('../assets/lottie/37012-shield-guard-vertifiled-check.json');
export const lottieThunder = require('../assets/lottie/61218-thunder-lightening-element-effect-animation.json');
export const lottieRipples = require('../assets/lottie/805-ripples-effects.json');
export const lottieClashSwords = require('../assets/lottie/3027-clash-of-swords.json');
export const lottieSwordBattle = require('../assets/lottie/23664-sword-battle.json');
export const lottieLevelUp = require('../assets/lottie/48816-level-up.json');
export const lottieCharge = require('../assets/lottie/51783-charge.json');
export const lottieRedLighting = require('../assets/lottie/65922-red-lightning.json');
export const lottieLightning = require('../assets/lottie/66672-lightning.json');
export const lottieArrowAnimation = require('../assets/lottie/10157-arrow-animation.json');
export const lottieArrowBouncy = require('../assets/lottie/47503-bouncy-arrow.json');
export const lottieArrowGreen = require('../assets/lottie/58826-arrow-green.json');
export const lottieArrowRight = require('../assets/lottie/97278-arrow-right.json');
export const lottieAvatarCrack = require('../assets/lottie/avatar-crack.json');
export const lottieTargetReached = require('../assets/lottie/target-fakesover.json');
export const lottieConfetiWin = require('../assets/lottie/confeti.json');
export const lottieArrow = require('../assets/lottie/arrow.json');
export const lottieZzz = require('../assets/lottie/zzz.json');
export const lottieEye = require('../assets/lottie/eye.json');
export const lottieExplosion = require('../assets/lottie/explosion.json');
export const lottieMagicSmoke = require('../assets/lottie/magic-smoke.json');
export const lottieEnhance = require('../assets/lottie/enhance-fakesover.json');
export const lottieUpgradeCardBlack = require('../assets/lottie/corazones-likes-emojis.json');
export const lottieUpgradeCardWhite = require('../assets/lottie/verificacion.json');
export const lottieUpgradeCardCoins = require('../assets/lottie/mejora_fakesover_monedas.json');
export const lottieUpgradeCardHearts = require('../assets/lottie/mejora_fakesover_corazones.json');
export const lottieUpgradeModal = require('../assets/lottie/mejora_fakesover.json');
