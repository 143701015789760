import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showTimeModal: false
};

const timeAdvice = createSlice({
  initialState,
  name: 'timeAdvice',
  reducers: {
    showTimeModal: state => {
      state.showTimeModal = true;
    },
    hideTimeModal: state => {
      state.showTimeModal = false;
    }
  }
});

export const { reducer: timeAdviceReducer } = timeAdvice;
export const { hideTimeModal, showTimeModal } = timeAdvice.actions;
