import { ReactNode, useEffect, useRef } from 'react';
import { View, StyleSheet, Animated, ViewStyle, Platform } from 'react-native';
import useUnits from 'rxn-units';
import { isNativeOrPWA } from '../utils';

interface ToolTipProps {
  children: ReactNode;
  containerStyle?: ViewStyle;
  tooltipStyle?: ViewStyle;
  tooltipTipStyle?: ViewStyle;
  animated?: boolean;
}
const animationDown = {
  toValue: -10,
  duration: 1000,
  useNativeDriver: Platform.OS !== 'web'
};

const animationUp = {
  toValue: 0,
  duration: 1000,
  useNativeDriver: Platform.OS !== 'web'
};
const ToolTip = ({ children, containerStyle, tooltipStyle, tooltipTipStyle, animated = true }: ToolTipProps) => {
  const translation = useRef(new Animated.Value(0)).current;
  const { vw } = useUnits();

  useEffect(() => {
    if (!animated) return;
    const sequence = Animated.sequence([Animated.timing(translation, animationDown), Animated.timing(translation, animationUp)]);
    const loop = Animated.loop(sequence);
    loop.start();
  }, [animated]);

  const styles = StyleSheet.create({
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      position: 'absolute',
      top: '-10%',
      left: '50%',
      elevation: 10
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      padding: isNativeOrPWA ? 10 : 20,
      width: 300,
      transform: [{ translateX: -150 }],
      borderRadius: vw(0.4)
    },
    tooltipTip: {
      width: 0,
      height: 0,
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderBottomWidth: 0,
      borderBottomColor: 'transparent',
      borderRightColor: 'transparent',
      borderTopColor: '#FFFFFF',
      borderLeftColor: 'transparent',
      transform: [{ translateX: -150 }],
      zIndex: 1000,
      borderRightWidth: vw(0.6),
      borderTopWidth: vw(0.6),
      borderLeftWidth: vw(0.6)
    }
  });

  return (
    <Animated.View
      pointerEvents="none"
      style={[styles.tooltipContainer, containerStyle || {}, { transform: [{ translateY: translation }] }]}
    >
      <View style={[styles.tooltip, tooltipStyle || {}]}>{children}</View>
      <View style={[styles.tooltipTip, tooltipTipStyle || {}]}></View>
    </Animated.View>
  );
};

export default ToolTip;
