import { RouteProp, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ViewStyle } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { actors } from '../../assets/data/actors';
import { reactionTweetsConfiguration } from '../../assets/data/configuration/tweets-reactions';
import CardHover from '../../assets/images/components/card-accesories/CardHover';
import { useRatioDimensions } from '../../commons/components/AspectRatioView/useRatioDimensions';
import SelectedCard from '../../commons/components/Card/CardTypes/SelectedCard';
import { CardSizesEnum } from '../../commons/components/Card/utils';
import LeftoverCardModal from '../../commons/components/LeftoverCardModal/LeftoverCardModal';
import { ColorCardEnum } from '../../commons/constants/colorCard';
import { PlayersTurnEnum, PlayerTypeEnum } from '../../commons/constants/player';
import { ActorEnum } from '../../commons/dtos/actor.dto';
import { Card, Card as CardType } from '../../commons/dtos/card.dto';
import { GendersEnum, PlayerRoundEnum } from '../../commons/dtos/player.dto';
import { ReactionEventEnum } from '../../commons/dtos/reaction-configuration.dto';
import { Rule } from '../../commons/dtos/rule.dto';
import { SubjectsEnum } from '../../commons/dtos/subject.dto';
import { TagsEnum } from '../../commons/dtos/tag.dto';
import { TweetsReactionConfiguration } from '../../commons/dtos/tweet-reaction-configuration.dto';
import { TypesEnum } from '../../commons/dtos/type.dto';
import { launchReactions, waitForReactions } from '../../commons/events-management/reactions/reactions';
import { ReactionsManagementProps } from '../../commons/events-management/reactions/reactions-validators';
import { ReactionManagementTweet } from '../../commons/events-management/tweets/tweets';
import { wait, cardHasTag, findAndUpdate, randomIntFromInterval } from '../../commons/utils';
import boardCardsUtils from '../../commons/utils/gameboard/boardCards';
import {
  getBestAttackableCard,
  getCardsWillDieAndOurWillDieOrSurvive,
  getNearestToDeath,
  getRandomAIAttackingCard,
  getTargetArtificialIntelligence,
  selectKillAndSurviveCards
} from '../../commons/utils/gameboard/ia';
import { updateProgressOnFinished } from '../../commons/utils/gameboard/progress';
import { contextAppliedRules, getApplicableRules, getCardRules, isActionOrSupportCard } from '../../commons/utils/gameboard/rules';
import { avatarImage } from '../../constants/Images';
import { gameSound } from '../../constants/Sound';
import { MAX_ACTION_POINTS, MAX_BOARD_CARDS_LENGTH } from '../../constants/Values';
import { useCardDrop } from '../../hooks/useCardDrop';
import { useDetailCard } from '../../hooks/useDetailCard';
import { useDiscard } from '../../hooks/useDiscard';
import { useGameboardModal } from '../../hooks/useGameboardModal';
import { useItemRegistry } from '../../hooks/useItemRegistry';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { useModal } from '../../hooks/useModal';
import { useMusic } from '../../hooks/useMusic';
import { usePlayer } from '../../hooks/usePlayer';
import { Config, DifficultyLevel } from '../../redux/Configuration/configuration';
import { resetGameActions } from '../../redux/GameBoard/gameAction';
import { resetGameReactions } from '../../redux/GameBoard/reactions';
import { resetTweetReactions } from '../../redux/GameBoard/tweets';
import { Profile } from '../../redux/Profile/profileReducer';
import store from '../../redux/store';
import { showTimeModal } from '../../redux/Time-advice/timeAdvice';
import { RootStackParamList } from '../../types';
import { AttackingStatus } from '../../types/drag';
import { AvatarsContainerProps } from './AvatarsContainer/AvatarsContainer';
import { BoardCardsProps } from './BoardCards/BoardCardsMobile';
import { BoardCardsHookProps } from './BoardCards/useBoardCards';
import { BoardCards, Deck, Hand, RoundData } from './gameTypes';
import { HandOfCardsProperties } from './HandOfCards/useHandOfCards';
import useGameBoardRules from './useGameBoardRules';

enum GamePhasesEnum {
  INITIAL = 'initial',
  MANUAL = 'manual'
}
enum ExchangeEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low'
}

interface AnimationLifePoints {
  showAnimation: boolean;
  damage: number;
  player: PlayerRoundEnum;
}

export type BattleCard = CardType | 'avatar' | null;

let currentSelectingTargetDrawnCard: CardType | null = null;
export const GameBoardContext = createContext({ ...JSON.parse(JSON.stringify(contextAppliedRules)) });

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Home', undefined>;

/**
 * IA on low level skips turn when only one card is on the board (and is white), Opponent can have more cards and anyone attacks player (develop)
 */
const useGameBoard = (route: RouteProp<RootStackParamList, 'GameBoard'>) => {
  const { rw, rh } = useRatioDimensions();
  const dispatch = useDispatch();
  const navigation = useNavigation<Navigation>();
  const boardConfiguration = route.params.gameConfig!;

  const {
    opponentFirst,
    discard: opponentDiscard,
    isArtificialIntelligence: opponentIsArtificialIntelligence,
    lifePoints: initialOpponentLifePoints
  } = boardConfiguration.opponent!;

  const { discard: playerDiscard, lifePoints: initialPlayerLifePoints } = boardConfiguration.player!;

  const { reactionsConfiguration, isFreeGame } = boardConfiguration!;
  const { initialHandSize: playerInitialHandSize } = boardConfiguration.player!;
  const { initialHandSize: opponentInitialHandSize } = boardConfiguration.opponent!;

  const {
    discardCards,
    discardModalText,
    hiddenCards,
    initialDiscardStep,
    onReplaceDiscard,
    showDiscardModalButton,
    onConfirmDiscard,
    showDiscard,
    handleShowDiscard,
    selectedToDiscardCards,
    onGetAutoDiscardSelection,
    handleSelectedToDiscardCards
  } = useDiscard();

  const {
    boardCard: boardCardsPlayer,
    hand: handPlayer,
    side: playerSide
  } = usePlayer(boardConfiguration, PlayerRoundEnum.PLAYER, selectedToDiscardCards);

  const {
    cardIsTravelling: cardIsTravellingPlayer,
    deck: playerDeck,
    hand: playerHand,
    handMethods: playerHandMethods,
    handleDeck: playerHandleDeck,
    handleHand: playerHandleHand,
    travelingCardPosition: playerTravellingCardPosition,
    handHasMovements: playerHandHasMovements
  } = handPlayer;

  const {
    boardCardHandler: playerBoardCardHandler,
    boardCards: playerBoardCards,
    boardHasNoMovements: playerBoardHasNoMovements,
    boardCardsHasBarrier: playerHasBarrier
  } = boardCardsPlayer;

  const {
    actionPoints: playerActionPoints,
    lifePoints: playerLifePoints,
    maxActionPoints: playerMaxActionPoints,
    handleSide: playerHandleSide,
    isAttackable: playerIsAttackable,
    isAttacking: playerIsAttacking,
    handleAttackable: playerUpdateAttackable,
    handleAttacking: playerUpdateAttacking,
    isBeingAttacked: playerIsBeingAttacked,
    handleIsBeingAttacked: playerHandleIsBeingAttacked
  } = playerSide;

  const {
    boardCard: boardCardsOpponent,
    hand: handOpponent,
    side: opponentSide
  } = usePlayer(boardConfiguration!, PlayerRoundEnum.OPPONENT, selectedToDiscardCards, playerDeck.length);

  const {
    cardIsTravelling: cardIsTravellingOpponent,
    deck: opponentDeck,
    hand: opponentHand,
    handMethods: opponentHandMethods,
    handleDeck: opponentHandleDeck,
    handleHand: opponentHandleHand,
    travelingCardPosition: opponentTravellingCardPosition,
    handHasMovements: opponentHandHasMovements
  } = handOpponent;

  const {
    boardCardHandler: opponentBoardCardHandler,
    boardCards: opponentBoardCards,
    boardHasNoMovements: opponentBoardHasNoMovements,
    boardCardsHasBarrier: opponentHasBarrier
  } = boardCardsOpponent;

  const {
    actionPoints: opponentActionPoints,
    lifePoints: opponentLifePoints,
    maxActionPoints: opponentMaxActionPoints,
    handleSide: opponentHandleSide,
    isAttackable: opponentIsAttackable,
    isAttacking: opponentIsAttacking,
    handleAttackable: opponentUpdateAttackable,
    handleAttacking: opponentUpdateAttacking,
    isBeingAttacked: opponentIsBeingAttacked,
    handleIsBeingAttacked: opponentHandleIsBeingAttacked
  } = opponentSide;

  const copyReactionTweets = useRef(reactionTweetsConfiguration);
  const [round, setRound] = useState<number>(-2); // Initial round -2: both players discard
  const [showNextRoundButton, setShowNextRoundButton] = useState<boolean>(false);
  const [gamePhase, setGamePhase] = useState<GamePhasesEnum>(GamePhasesEnum.INITIAL);

  const [dragCard, setDragCard] = useState<string>('');
  const [dragAttacking, setDragAttacking] = useState<string | null>(null);
  const [selectingTargetCard, setSelectingTargetCard] = useState<CardType>();
  const [showAttackingCardDrag, setShowAttackingCardDrag] = useState<boolean>(false);
  const [winner, setWinner] = useState<number>(0);
  const [targetReached, setTargetReached] = useState<CardType>();
  const [isLaunchingCardFromHand, setIsLaunchingCardFromHand] = useState<boolean>(false);
  const [exchange, setExchange] = useState<ExchangeEnum>(ExchangeEnum.MEDIUM);
  const [selectingTargetDrawnCard, setSelectingTargetDrawnCard] = useState<CardType | null>(null);
  const [AITarget, setAITarget] = useState<CardType | null>(null);
  const [AISelectingTarget, setAISelectingTarget] = useState<CardType | null>(null);

  const leftoverModal = useModal();

  const doDragDropExecutions = (card: CardType) => {
    setIsLaunchingCardFromHand(false);
    onCloseDetailModal();

    onLaunchCard(
      card,
      isOpponentRound ? PlayersTurnEnum.SECOND : PlayersTurnEnum.FIRST,
      opponentBoardCards,
      isOpponentRound ? opponentHand : playerHand,
      isOpponentRound ? opponentDeck : playerDeck,
      gamePhase,
      isOpponentRound ? opponentActionPoints : playerActionPoints,
      targetReached?.id
    );
    setSelectingTargetCard(undefined);
    selectingTargetDrawnCardSetter(null);
  };

  const {
    handleSelection,
    showGameboardModal: showCardSupportModal,
    handleShowSupportCardModal,
    activeSupportCardRef
  } = useCardDrop({ doDragDrop: doDragDropExecutions });
  const {
    handleCloseModal: handleCloseAvatarModal,
    handleShowModal: handleShowAvatarModal,
    showGameboardModal: showAvatarModal
  } = useGameboardModal();
  const { detailCard, detailCardPosition, resetDetailCard, showDetailCard, updateDetailCard } = useDetailCard();
  const [cardMoved, setCardMoved] = useState<boolean>(false);
  const [battleCards, setBattleCards] = useState<[BattleCard, BattleCard]>([null, null]);
  const allRules = boardConfiguration.rules!;
  const [showAnimationLifePoints, setShowAnimationLifePoints] = useState<AnimationLifePoints>({
    showAnimation: false,
    damage: 0,
    player: opponentFirst ? PlayerRoundEnum.OPPONENT : PlayerRoundEnum.PLAYER
  });
  const [showBattleModal, setShowBattleModal] = useState<boolean>(false);
  const [upgradeCards, setUpgradeCards] = useState<[CardType | null, CardType | null]>([null, null]);
  const [yourTurn, setYourTurn] = useState<boolean | null>(null);

  const { handleAddItem, itemRegistry } = useItemRegistry();

  const [attackingStatus, setAttackingStatus] = useState<AttackingStatus>({ from: null, to: null });

  const { battleMusic, difficulty } = useSelector(
    ({
      configuration: {
        volume: { battleMusic },
        difficulty
      }
    }: {
      configuration: Config;
    }) => ({ battleMusic, difficulty })
  );

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  const { unloadSound } = useMusic({ loop: true, soundMusic: gameSound, play: !(winner > 0) && battleMusic });

  const activateAnimationLifePoints = async (damage: number, player: PlayerRoundEnum) => {
    setShowAnimationLifePoints({
      showAnimation: true,
      damage,
      player
    });
    await wait(1200);
    setShowAnimationLifePoints(prev => ({ ...prev, showAnimation: false }));
  };

  const checkIfCardsAreDead = async (opponentBoardCards: Card[], playerBoardCars: Card[]) => {
    // Check if some card is death no reproduce lottie
    if (
      opponentBoardCards.some(({ defensePoints }) => defensePoints! <= 0) ||
      playerBoardCars.some(({ defensePoints }) => defensePoints! <= 0)
    ) {
      await wait(800);
      opponentBoardCards.forEach(card => {
        if (card.defensePoints! <= 0) {
          card.isDead = true;
        }
      });
      playerBoardCars.forEach(card => {
        if (card.defensePoints! <= 0) {
          card.isDead = true;
        }
      });
    }
  };

  const updateBoardOnSelectLeftover = (card: Card, boardCards: BoardCards): BoardCards => {
    const cardToDie = boardCardsUtils.removeCard(card);
    const updatedBoardCards = cardToDie(boardCards);

    return updatedBoardCards;
  };

  const showLeftoverModal = async (boardCards: BoardCards): Promise<any> => {
    const res = await leftoverModal.showModal({
      component: LeftoverCardModal,
      props: { cards: boardCards, updateBoardOnSelectLeftover }
    });
    return res;
  };

  const showLeftoverAndRemoveCards = async (
    cards: BoardCards,
    playedCard: Card,
    isArtificialIntelligence: boolean
  ): Promise<BoardCards> => {
    if (
      cards.length <= MAX_BOARD_CARDS_LENGTH ||
      playedCard.type?.typeName === TypesEnum.SUPPORT ||
      playedCard.type?.typeName === TypesEnum.ACTION
    )
      return cards;
    // ArtificialIntelligence will select random card
    if (isArtificialIntelligence) {
      const randomCardIndex = randomIntFromInterval(0, cards.length - 1);
      const cardToDelete = cards[randomCardIndex];
      const updatedBoardCards = updateBoardOnSelectLeftover(cardToDelete, cards);
      return updatedBoardCards;
    }
    const { value } = await showLeftoverModal(cards);
    const { updatedBoardCards } = value;
    return updatedBoardCards;
  };

  const extractCardFromOpponentDeck = async (
    player: number,
    hand: Hand,
    deck: Deck,
    type?: TypesEnum,
    subject?: SubjectsEnum,
    isReactionExtract?: boolean,
    actionPoints?: number
  ): Promise<[Hand, Deck]> => {
    const isReactionLaunch = true;
    let newOpponentHand = [...hand];
    let newOpponentDeck = [...deck];
    const {
      deck: updatedOpponentDeck,
      extractedCard,
      hand: updatedOpponentHand
    } = await opponentHandMethods.extractCardFromDeck(
      newOpponentHand,
      actionPoints ?? opponentActionPoints,
      isPlayerRound,
      opponentBoardCards,
      playerBoardCards,
      allRules,
      newOpponentDeck,
      type,
      subject
    );

    newOpponentHand = updatedOpponentHand;
    newOpponentDeck = updatedOpponentDeck;
    launchReactions(reactionsManagementProps, [ReactionEventEnum.OPPONENT_GET_CARD], extractedCard);
    if (isReactionExtract && extractedCard && extractedCard.color === ColorCardEnum.WHITE) {
      if (
        boardCardsUtils.opponentTargetCardIsPlayable({
          card: extractedCard,
          allRules,
          playerBoardCards,
          opponentBoardCards,
          currentSelectingTargetDrawnCard: !!selectingTargetDrawnCard,
          isReactionExtract,
          isPlayerRound
        })
      ) {
        if (
          boardCardsUtils.checkIsSelectingTarget(
            extractedCard,
            isReactionExtract,
            allRules,
            !!selectingTargetDrawnCard,
            playerBoardCards,
            opponentBoardCards,
            isPlayerRound
          )
        ) {
          selectingTargetDrawnCardSetter(extractedCard);
          if (opponentIsArtificialIntelligence) {
            await wait(1000);

            await applyCardToTargetArtificialIntelligence(
              newOpponentHand,
              updatedOpponentDeck,
              extractedCard,
              gamePhase,
              opponentBoardCards,
              opponentActionPoints,
              isReactionLaunch
            );
            await wait(1000);
            selectingTargetDrawnCardSetter(null);
          }
        }
        return [newOpponentHand, newOpponentDeck];
      }
      if (
        extractedCard.type &&
        (!isActionOrSupportCard(extractedCard) ||
          !boardCardsUtils.checkIsSelectingTarget(
            extractedCard,
            isReactionExtract,
            allRules,
            !!selectingTargetDrawnCard,
            playerBoardCards,
            opponentBoardCards,
            isPlayerRound
          ))
      ) {
        await wait(1000);
        // TODO Check lifepoints

        const { hand: updatedOpponentHand, deck: updatedOpponentDeck } = await onLaunchCard(
          extractedCard,
          player,
          opponentBoardCards,
          newOpponentHand,
          newOpponentDeck,
          gamePhase,
          opponentActionPoints,
          '',
          isReactionLaunch
        );

        newOpponentHand = updatedOpponentHand;
        newOpponentDeck = updatedOpponentDeck;

        return [newOpponentHand, newOpponentDeck];
      }
    }

    return [newOpponentHand, newOpponentDeck];
  };

  const extractCardFromPlayerDeck = async (
    player: number,
    hand: Hand,
    deck: Deck,
    type?: TypesEnum,
    subject?: SubjectsEnum,
    isReactionExtract?: boolean,
    actionPoints?: number
  ): Promise<[Hand, Deck]> => {
    const playerRound = true;
    const isReactionLaunch = true;
    let newPlayerHand = [...hand];
    let newPlayerDeck = [...deck];

    const {
      deck: updatedPlayerDeck,
      extractedCard,
      hand: updatedPlayerHand
    } = await playerHandMethods.extractCardFromDeck(
      newPlayerHand,
      actionPoints ?? playerActionPoints,
      playerRound,
      opponentBoardCards,
      playerBoardCards,
      allRules,
      newPlayerDeck,
      type,
      subject
    );
    newPlayerHand = updatedPlayerHand;
    newPlayerDeck = updatedPlayerDeck;

    launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_GET_CARD], extractedCard);
    if (isReactionExtract && extractedCard && extractedCard.color === ColorCardEnum.WHITE) {
      if (
        boardCardsUtils.playerTargetCardIsPlayable({
          card: extractedCard,
          allRules,
          boardCards: playerBoardCards,
          rivalBoardCards: opponentBoardCards
        })
      ) {
        if (
          boardCardsUtils.checkIsSelectingTarget(
            extractedCard,
            isReactionExtract,
            allRules,
            !!selectingTargetDrawnCard,
            playerBoardCards,
            opponentBoardCards,
            isPlayerRound
          )
        ) {
          selectingTargetDrawnCardSetter(extractedCard);
        }
        return [newPlayerHand, newPlayerDeck];
      }
      if (
        extractedCard.type &&
        (!isActionOrSupportCard(extractedCard) ||
          !boardCardsUtils.checkIsSelectingTarget(
            extractedCard,
            isReactionExtract,
            allRules,
            !!selectingTargetDrawnCard,
            playerBoardCards,
            opponentBoardCards,
            isPlayerRound
          ))
      ) {
        await wait(1000);
        // TODO Check lifepoints

        const { hand: updatedPlayerHand, deck: updatedPlayerDeck } = await onLaunchCard(
          extractedCard,
          player,
          opponentBoardCards,
          newPlayerHand,
          newPlayerDeck,
          gamePhase,
          playerActionPoints,
          '',
          isReactionLaunch
        );

        newPlayerHand = updatedPlayerHand;
        newPlayerDeck = updatedPlayerDeck;
        // Hand
        return [newPlayerHand, newPlayerDeck];
      }
    }
    return [newPlayerHand, newPlayerDeck];
  };

  const extractCardFromDeck = async (
    player: number,
    hand: Hand,
    deck: Deck,
    type?: TypesEnum,
    subject?: SubjectsEnum,
    isReactionExtract?: boolean,
    actionPoints?: number
  ): Promise<[Hand, Deck]> => {
    // If no remaining deck, navigate to lose
    if (deck.length === 0) {
      await handleWinner(player);
      return [hand, deck];
    }

    if (player === PlayerTypeEnum.PLAYER) {
      const [newPlayerHand, newPlayerDeck] = await extractCardFromPlayerDeck(
        player,
        hand,
        deck,
        type,
        subject,
        isReactionExtract,
        actionPoints
      );
      return [newPlayerHand, newPlayerDeck];
    }

    const [newOpponentHand, newOpponentDeck] = await extractCardFromOpponentDeck(
      player,
      hand,
      deck,
      type,
      subject,
      isReactionExtract,
      actionPoints
    );
    return [newOpponentHand, newOpponentDeck];
  };

  const { appliedRules, pendingCards, applyCardRules, onAttackApplyCardRules } = useGameBoardRules({
    extractCardFromDeck,
    setPlayerLifePoints: (n: number) => playerHandleSide.handleLifePoints.increment(n),
    setOpponentLifePoints: (n: number) => opponentHandleSide.handleLifePoints.increment(n),
    showLeftoverAndRemoveCards,
    updateOpponentDeck: opponentHandleDeck.replace,
    updatePlayerDeck: playerHandleDeck.replace,
    updateOpponentBoard: opponentBoardCardHandler.replace,
    updatePlayerBoard: playerBoardCardHandler.replace,
    activateAnimationLifePoints,
    round,
    opponentDeck,
    playerDeck,
    allRules,
    opponentIsArtificialIntelligence: !!opponentIsArtificialIntelligence
  });

  const playersTurn = Math.abs(round % 2);

  const isOpponentRound = opponentFirst ? playersTurn === PlayersTurnEnum.FIRST : playersTurn === PlayersTurnEnum.SECOND;

  const isPlayerRound = !isOpponentRound;

  const highlightNextTurn = isOpponentRound
    ? !opponentHandHasMovements && opponentBoardHasNoMovements
    : !playerHandHasMovements && playerBoardHasNoMovements;

  /**
   * Check if the player is currently playing the round
   * @param {PlayerTypeEnum} player current player turn
   * @returns {boolean} opponent or player round
   */
  const isCurrentPlayerRound = (player: PlayerTypeEnum) => {
    if (player === PlayerTypeEnum.PLAYER) {
      return isPlayerRound;
    }
    return isOpponentRound;
  };

  const initialLife = useMemo(() => (isPlayerRound ? initialOpponentLifePoints : initialPlayerLifePoints), [isPlayerRound]);

  const filterByUmbral = useCallback(
    (reaction: TweetsReactionConfiguration, life: number) => {
      const reactionEvent = isPlayerRound ? ReactionEventEnum.OPPONENT_AVATAR_DAMAGED : ReactionEventEnum.PLAYER_AVATAR_DAMAGED;

      const { trigger } = reaction;
      const { lifePointsThreshold, event, done } = trigger;

      const condition = lifePointsThreshold! < initialLife && lifePointsThreshold! > life && event === reactionEvent && !done;

      return condition;
    },
    [initialLife, isPlayerRound]
  );

  /**
   * Generate tweets and calculate the tweets that should be displayed
   */
  const tweetsConfiguration = useCallback(
    (life: number) => {
      if (boardConfiguration?.disableReactionTweets) return [];
      const reactions = copyReactionTweets.current.filter(r => filterByUmbral(r, life));
      reactions.forEach(reaction => (reaction.trigger.done = true));
      return reactions;
    },
    [filterByUmbral, copyReactionTweets]
  );

  const reactionsManagementProps: ReactionsManagementProps = useMemo(
    () => ({
      reactionsConfiguration,
      round,
      isPlayerRound,
      playerLifePoints,
      opponentLifePoints
    }),
    [round, isPlayerRound, playerLifePoints, opponentLifePoints]
  );

  const reactionsRoundStart = useCallback(
    async (player: PlayerRoundEnum) => {
      await launchReactions(
        reactionsManagementProps,
        player === PlayerRoundEnum.PLAYER ? [ReactionEventEnum.PLAYER_ROUND_START] : [ReactionEventEnum.OPPONENT_ROUND_START]
      );
    },
    [reactionsManagementProps]
  );

  const reactionsPlayCard = useCallback(
    async (card: CardType, player: PlayerRoundEnum) =>
      await launchReactions(
        reactionsManagementProps,
        player === PlayerRoundEnum.PLAYER ? [ReactionEventEnum.PLAYER_PLAY_CARD] : [ReactionEventEnum.OPPONENT_PLAY_CARD],
        card
      ),
    [reactionsManagementProps]
  );

  const reactionsPlayerWin = useCallback(
    async () => await launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_WIN]),
    [reactionsManagementProps]
  );

  const reactionsOpponentWin = useCallback(
    async () => await launchReactions(reactionsManagementProps, [ReactionEventEnum.OPPONENT_WIN]),
    [reactionsManagementProps]
  );

  const nextTurn = useCallback(async () => {
    await waitForReactions();
    await wait(500);
    setRound(prevRound => prevRound + 1);
  }, []);

  const selectingTargetDrawnCardSetter = (selectingTargetCard: CardType | null) => {
    setSelectingTargetDrawnCard(selectingTargetCard);
    currentSelectingTargetDrawnCard = selectingTargetCard;
  };

  const reactionsTweetsManagementProps = useCallback(
    (life: number): ReactionManagementTweet => {
      return {
        reactionsConfiguration: tweetsConfiguration(life),
        setMessage
      };
    },
    [tweetsConfiguration]
  );

  const onDragStart = (left: number, top: number, card: CardType, side: PlayerRoundEnum) => {
    const isReactionExtract = false;
    if (opponentIsArtificialIntelligence && isOpponentRound) return;
    onCloseDetailModal();
    setIsLaunchingCardFromHand(true);
    onCardPressed(card, side, { left, top });

    if (
      boardCardsUtils.checkIsSelectingTarget(
        card,
        isReactionExtract,
        allRules,
        !!selectingTargetDrawnCard,
        playerBoardCards,
        opponentBoardCards,
        isPlayerRound
      )
    ) {
      setSelectingTargetCard(card);
    }
  };
  const onDragExit = () => onCloseDetailModal();
  const onDragEnd = () => {
    onCloseDetailModal();
    setSelectingTargetCard(undefined);
    setIsLaunchingCardFromHand(false);
  };
  const onDragDrop = (card: CardType) => {
    const shouldWaitUntilModal = handleShowSupportCardModal({ allRules, card, isPlayerRound, opponentBoardCards, playerBoardCards });
    if (shouldWaitUntilModal) {
      return;
    }
    doDragDropExecutions(card);
  };

  const onDragMethods = {
    onDragStart,
    onDragExit,
    onDragEnd,
    onDragDrop
  };

  /**
   * Check current boardCards of both sides, update action points and extract card from deck, finally update hand state
   * @param {Hand} opponentHand: current opponent hand
   * @param {Deck} opponentDeck: current opponent deck
   * @returns {Hand} updated hand
   */
  const playInitialPhaseOpponent = async (opponentHand: Hand, opponentDeck: Deck): Promise<[Hand, Deck, number]> => {
    opponentUpdateAttacking(false);

    const newOpponentBoardCards = boardCardsUtils.updateBoardCardStatus(
      opponentBoardCards,
      PlayerRoundEnum.OPPONENT,
      appliedRules.current,
      round
    );

    opponentBoardCardHandler.replace(newOpponentBoardCards);

    const newPlayerBoardCards = boardCardsUtils.updateBoardCardStatusReduced(
      playerBoardCards,
      PlayerRoundEnum.PLAYER,
      appliedRules.current,
      round
    );

    playerBoardCardHandler.replace(newPlayerBoardCards);

    let currentActionPoints = opponentActionPoints;
    if (opponentMaxActionPoints < MAX_ACTION_POINTS && ((!opponentFirst && round !== 1) || (opponentFirst && round !== 0))) {
      currentActionPoints = opponentMaxActionPoints + 1;
      opponentHandleSide.handleMaxActionPoints.replace(currentActionPoints);
      opponentHandleSide.handleActionPoints.replace(currentActionPoints);
    }
    if (opponentMaxActionPoints >= MAX_ACTION_POINTS) {
      currentActionPoints = MAX_ACTION_POINTS;
      opponentHandleSide.handleActionPoints.replace(MAX_ACTION_POINTS);
    }

    if (opponentDeck.length === 0) {
      await handleWinner(PlayerTypeEnum.PLAYER);
      return [opponentHand, opponentDeck, currentActionPoints];
    }
    const [newOpponentHand, newDeck] = await extractCardFromDeck(
      PlayerTypeEnum.OPPONENT,
      opponentHand,
      opponentDeck,
      undefined,
      undefined,
      undefined,
      currentActionPoints
    );
    return [newOpponentHand, newDeck, currentActionPoints];
  };

  /**
   * Check current boardCards of both sides, update action points and extract card from deck, finally update hand state
   * @param {Hand} playerHand: current player hand
   * @param {Deck} playerDeck: current player deck
   * @returns {Hand} updated hand
   */
  const playInitialPhasePlayer = async (playerHand: Hand, playerDeck: Deck): Promise<[Hand, Deck, number]> => {
    playerUpdateAttacking(false);

    const newPlayerBoardCards = boardCardsUtils.updateBoardCardStatus(
      playerBoardCards,
      PlayerRoundEnum.PLAYER,
      appliedRules.current,
      round
    );

    playerBoardCardHandler.replace(newPlayerBoardCards);

    const newOpponentBoardCards = boardCardsUtils.updateBoardCardStatusReduced(
      opponentBoardCards,
      PlayerRoundEnum.OPPONENT,
      appliedRules.current,
      round
    );

    opponentBoardCardHandler.replace(newOpponentBoardCards);

    let currentActionPoints = playerActionPoints;

    if (playerMaxActionPoints < MAX_ACTION_POINTS && ((!opponentFirst && round !== 0) || (opponentFirst && round !== 1))) {
      currentActionPoints = playerMaxActionPoints + 1;
      playerHandleSide.handleMaxActionPoints.replace(currentActionPoints);
      playerHandleSide.handleActionPoints.replace(currentActionPoints);
    }
    if (playerMaxActionPoints >= MAX_ACTION_POINTS) {
      currentActionPoints = MAX_ACTION_POINTS;
      playerHandleSide.handleActionPoints.replace(currentActionPoints);
    }

    if (playerDeck.length === 0) {
      await handleWinner(PlayerTypeEnum.OPPONENT);
      return [playerHand, playerDeck, currentActionPoints];
    }

    const [newPlayerHand, newDeck] = await extractCardFromDeck(
      PlayerTypeEnum.PLAYER,
      playerHand,
      playerDeck,
      undefined,
      undefined,
      undefined,
      currentActionPoints
    );
    return [newPlayerHand, newDeck, currentActionPoints];
  };

  /**
   * Play phase of both sides, extract cards and check cards that can be played
   * @param {RoundData} data necesary data to play the round
   * @returns {Hand} updated opponent hand
   */
  const playInitialPhase = async (data: RoundData): Promise<[Hand, Deck, number]> => {
    setGamePhase(GamePhasesEnum.INITIAL);

    playerUpdateAttackable(false);
    opponentUpdateAttackable(false);

    const { opponent, player } = data;
    const [opponentHand, opponentDeck] = opponent;
    const [playerHand, playerDeck] = player;

    if (opponentFirst) {
      if (isOpponentRound) {
        return await playInitialPhaseOpponent(opponentHand, opponentDeck);
      }
      return await playInitialPhasePlayer(playerHand, playerDeck);
    } else {
      if (isPlayerRound) {
        return await playInitialPhasePlayer(playerHand, playerDeck);
      }
      return await playInitialPhaseOpponent(opponentHand, opponentDeck);
    }
  };

  /**
   * The function applies a card to a target in an artificial intelligence game, updating the opponent's
   * hand, board cards, and action points.
   * @param {Hand} opponentHand - The `opponentHand` parameter is of type `Hand` and represents the
   * updated hand of the opponent player after applying the card to a target.
   * @param {CardType} card - The `card` parameter is of type `CardType` and represents the card that is
   * being applied to a target.
   * @param {GamePhasesEnum} gamePhase - The `gamePhase` parameter represents the current phase of the
   * game. It is of type `GamePhasesEnum`.
   * @param {BoardCards} opponentBoardCards - An object representing the cards on the opponent's board.
   * It could contain information such as the card's ID, type, and any other relevant data.
   * @param {number} actionPoints - The `actionPoints` parameter represents the number of action points
   * available to the player. Action points are a resource that can be spent to perform certain actions
   * in the game.
   * @param [isReactionLaunch=false] - The `isReactionLaunch` parameter is a boolean flag that indicates
   * whether the card being applied is a reaction card. If it is set to `true`, it means that the card is
   * being played in response to a previous action or event in the game. If it is set to `false` or
   * @returns a Promise that resolves to an array containing `[Hand, BoardCards, number]` or `null`.
   */
  const applyCardToTargetArtificialIntelligence = async (
    opponentHand: Hand,
    deck: Deck,
    card: CardType,
    gamePhase: GamePhasesEnum,
    opponentBoardCards: BoardCards,
    actionPoints: number,
    isReactionLaunch = false
  ): Promise<{
    hand: Hand;
    deck: Deck;
    boardCards: BoardCards;
    actionPoints: number;
    playerLifePoints: number;
    opponentLifePoints: number;
  }> => {
    const randomTargetCard = getTargetArtificialIntelligence({ allRules, card, difficulty, opponentBoardCards, playerBoardCards });
    if (!randomTargetCard)
      return { actionPoints, boardCards: opponentBoardCards, deck, hand: opponentHand, opponentLifePoints, playerLifePoints };

    setAISelectingTarget(card); // show arrow on attacking card
    setAITarget(randomTargetCard); // show target on target card
    // Applies card to target

    const cards = await onLaunchCard(
      card,
      PlayerTypeEnum.PLAYER,
      opponentBoardCards,
      opponentHand,
      deck,
      gamePhase,
      actionPoints,
      randomTargetCard.id,
      isReactionLaunch
    );
    await wait(1000);
    setAITarget(null);
    setAISelectingTarget(null);
    return cards;
  };

  /**
   * Attack IA to player avatar and update player status
   * @param {number} currentPlayerLifePoints actual player life points
   * @param {BoardCards} currentOpponentBoardCards  actual opponent board cards
   * @returns {Promise<[number, BoardCards]>} new life points and updated opponent board cards
   */
  const attackAvatarWithAICard = async (
    currentPlayerLifePoints: number,
    currentOpponentBoardCards: BoardCards
  ): Promise<[number, BoardCards]> => {
    const [attackingCard, attackingCardIndex] = getRandomAIAttackingCard({
      color: ColorCardEnum.BLACK,
      boardCards: currentOpponentBoardCards
    });
    setAISelectingTarget(attackingCard);
    const idAttacking = `drag_${PlayerRoundEnum.OPPONENT}_attacking_${attackingCard.id}_${attackingCardIndex}`;
    setDragCard(idAttacking);
    setDragAttacking(idAttacking);
    const updatedOpponentBoardCards = await onAttackAvatarWithCard(attackingCardIndex as number, currentOpponentBoardCards);
    setShowAttackingCardDrag(false);
    const damage = attackingCard.damagePoints ?? 0;
    return [currentPlayerLifePoints - damage, updatedOpponentBoardCards];
  };

  /**
   * IA Attack on high level
   * @param {number} color card color
   * @param {BoardCards} currentPendingCards pending cards that can attack player
   * @param {BoardCards} currentPlayerBoardCards  actual player board cards
   * @param {BoardCards} currentOpponentBoardCards  actual opponent board cards
   * @param {BoardCards} attackingOpponentBoardCards opponent board cards that can attack player
   * @returns {Promise<[BoardCards, BoardCards, BoardCards, BoardCards]>} [pendingCards, playerBoardCards, attackingOpponentBoardCards, opponentBoardCards]
   */
  const ordinaryAIAttack = async (
    color: number,
    currentPendingCards: BoardCards,
    currentPlayerBoardCards: BoardCards,
    currentOpponentBoardCards: BoardCards,
    attackingOpponentBoardCards: BoardCards,
    playerLifePoints: number
  ): Promise<[BoardCards, BoardCards, BoardCards, BoardCards, number]> => {
    let currentPlayerLifePoints = playerLifePoints;
    // The normal attack routine consisting of two phases: WHITE and BLACK (in this order), begins
    const colorAttackingOpponentBoardCards = attackingOpponentBoardCards.filter(
      card => !card.isWaiting && (color === undefined || card.color === color)
    );
    let i = colorAttackingOpponentBoardCards.length;

    while (i--) {
      // Get opponent attacking cards of selected color
      const [leastDamagePointsBoardCard, cardsWillDie] = selectKillAndSurviveCards({
        boardCards: colorAttackingOpponentBoardCards,
        rivalBoardCards: currentPlayerBoardCards
      });

      if (!leastDamagePointsBoardCard)
        return [currentPendingCards, currentPlayerBoardCards, [], currentOpponentBoardCards, playerLifePoints];

      const leastDamagePointsBoardCardIndex = currentOpponentBoardCards.findIndex(card => card === leastDamagePointsBoardCard);
      if (cardsWillDie.length > 0 && leastDamagePointsBoardCardIndex >= 0) {
        // If there are candidates of this type
        // The best candidate is chosen
        const bestAttackableCard = getBestAttackableCard(cardsWillDie);
        const attackedCardIndex = currentPlayerBoardCards.findIndex(card => card === bestAttackableCard);
        // The best candidate is attacked
        const {
          attackedCards: playerBoardCardsUpdated,
          attackingCards: opponentBoardCardsUpdated,
          playerLifePoints: updatedPlayerLifePoints
        } = await onAttackWithCard(
          leastDamagePointsBoardCardIndex,
          attackedCardIndex,
          currentPlayerBoardCards,
          currentOpponentBoardCards,
          currentPlayerLifePoints
        );
        currentPlayerLifePoints = updatedPlayerLifePoints;
        await wait(1000);
        setShowAttackingCardDrag(false);
        const attackingOpponentBoardCardsNoWaiting = attackingOpponentBoardCards.filter(
          opponentBoardCard => opponentBoardCard.isWaiting !== true
        );
        return [
          currentPendingCards,
          playerBoardCardsUpdated,
          attackingOpponentBoardCardsNoWaiting,
          opponentBoardCardsUpdated,
          currentPlayerLifePoints
        ];
      }

      // In case there are no candidate cards that this card can kill without dying itself, cards that can be killed by our card are identified
      const killAndDieCards = getCardsWillDieAndOurWillDieOrSurvive(leastDamagePointsBoardCard, currentPlayerBoardCards);
      if (killAndDieCards.length > 0 && leastDamagePointsBoardCardIndex >= 0) {
        // If there are candidate cards that can be killed by our card
        const bestAttackableCard = getBestAttackableCard(killAndDieCards);
        // In case EXCHANGE = HIGH the exchange always takes place.
        // If EXCHANGE = MEDIUM the exchange takes place in case the attacking card has a cost in action points up to two points higher than the selected card.
        // in action points up to two points higher than the selected one.
        // If EXCHANGE = LOW, the exchange only occurs in case the attacked card is worth the same or more action points than the attacking card.
        const { actionPoints: leastDamageActionPoints } = leastDamagePointsBoardCard;
        const { actionPoints: bestAttaclableActionsPoints } = bestAttackableCard;
        const doExchange =
          exchange === ExchangeEnum.HIGH ||
          (exchange === ExchangeEnum.MEDIUM && leastDamageActionPoints - bestAttaclableActionsPoints <= 2) ||
          (exchange === ExchangeEnum.LOW && leastDamageActionPoints - bestAttaclableActionsPoints <= 0);

        if (doExchange && bestAttackableCard) {
          // If exchange is done, best attackable card is killed
          const { id: bestAttackableCardId } = bestAttackableCard;
          const attackedCardIndex = currentPlayerBoardCards.findIndex(({ id }) => id === bestAttackableCardId);
          const {
            attackedCards: playerBoardCardsUpdated,
            attackingCards: opponentBoardCardsUpdated,
            playerLifePoints: updatedPlayerLifePoints
          } = await onAttackWithCard(
            leastDamagePointsBoardCardIndex,
            attackedCardIndex,
            currentPlayerBoardCards,
            currentOpponentBoardCards,
            currentPlayerLifePoints
          );
          currentPlayerLifePoints = updatedPlayerLifePoints;
          await wait(1000);
          setShowAttackingCardDrag(false);
          const attackingOpponentBoardCardsNoWaiting = attackingOpponentBoardCards.filter(
            opponentBoardCard => opponentBoardCard.isWaiting !== true
          );
          return [
            currentPendingCards,
            playerBoardCardsUpdated,
            attackingOpponentBoardCardsNoWaiting,
            opponentBoardCardsUpdated,
            currentPlayerLifePoints
          ];
        }
      }
      // If there are not valid candidates, the attacking card is saved in PENDING queue
      if (!currentPendingCards.includes(leastDamagePointsBoardCard))
        currentPendingCards = [...currentPendingCards, leastDamagePointsBoardCard];
      attackingOpponentBoardCards.splice(leastDamagePointsBoardCardIndex, 1);
      attackingOpponentBoardCards = attackingOpponentBoardCards.filter(opponentBoardCard => opponentBoardCard.isWaiting !== true);
    }
    return [currentPendingCards, currentPlayerBoardCards, attackingOpponentBoardCards, currentOpponentBoardCards, currentPlayerLifePoints];
  };

  /**
   * Check pending cards and update player and opponent board cards
   * @param {BoardCards} currentPendingCards actual opponent ia pending cards
   * @param {BoardCards} currentPlayerBoardCards current player board cards
   * @param {BoardCards} currentOpponentBoardCards current opponent board cards
   * @returns {Promise<[BoardCards, BoardCards, BoardCards]>} updated player and opponent board cards and new pending cards
   */
  const processPendingCards = async (
    currentPendingCards: BoardCards,
    currentPlayerBoardCards: BoardCards,
    currentOpponentBoardCards: BoardCards,
    playerLifePoints: number
  ): Promise<[BoardCards, BoardCards, BoardCards, number]> => {
    // Attacks of cards in PENDING are now processed.
    let currentPlayerLifePoints = playerLifePoints;
    if (!currentPendingCards.length) return [currentPlayerBoardCards, currentOpponentBoardCards, [], playerLifePoints];
    for (const pendingCard of currentPendingCards) {
      // Get its index in opponent board cards
      const pendingCardIndex = currentOpponentBoardCards.findIndex(card => card.id === pendingCard.id);
      const playerBarrierCards = currentPlayerBoardCards.filter(playerBoardCard =>
        playerBoardCard.tags?.some(tag => tag.tagName === TagsEnum.BARRIER)
      );

      if (playerBarrierCards.length) {
        const bestAttackableCardIndex = currentPlayerBoardCards.findIndex(card => card.id === playerBarrierCards[0].id);
        if (bestAttackableCardIndex === null || bestAttackableCardIndex < 0) continue;
        if (bestAttackableCardIndex === null) continue;
        // Attack to barrier card
        const {
          attackedCards: updatedPlayerBoardCards,
          attackingCards: updatedOpponentBoardCards,
          playerLifePoints: updatedPlayerLifePoints
        } = await onAttackWithCard(
          pendingCardIndex,
          bestAttackableCardIndex,
          currentPlayerBoardCards,
          currentOpponentBoardCards,
          currentPlayerLifePoints
        );
        currentPlayerBoardCards = updatedPlayerBoardCards;
        currentOpponentBoardCards = updatedOpponentBoardCards;
        currentPlayerLifePoints = updatedPlayerLifePoints;
        setShowAttackingCardDrag(false);
        await wait(1000);
        continue;
      }
      // If EXCHANGE = HIGH
      if (exchange === ExchangeEnum.HIGH) {
        // Get nearest to death card
        const bestAttackableCardIndex = getNearestToDeath(pendingCard, currentPlayerBoardCards);
        if (bestAttackableCardIndex === null || bestAttackableCardIndex < 0) continue;
        if (bestAttackableCardIndex === null) continue;
        // Attack neaerest to death card
        const {
          attackedCards: updatedPlayerBoardCards,
          attackingCards: updatedOpponentBoardCards,
          playerLifePoints: updatedPlayerLifePoints
        } = await onAttackWithCard(
          pendingCardIndex,
          bestAttackableCardIndex,
          currentPlayerBoardCards,
          currentOpponentBoardCards,
          currentPlayerLifePoints
        );
        currentPlayerBoardCards = updatedPlayerBoardCards;
        currentOpponentBoardCards = updatedOpponentBoardCards;
        currentPlayerLifePoints = updatedPlayerLifePoints;
        setShowAttackingCardDrag(false);
        await wait(1000);
        continue;
      }

      // If EXCHANGE != HIGH  attack to avatar
      if (pendingCard.color !== ColorCardEnum.WHITE) {
        currentOpponentBoardCards = await onAttackAvatarWithCard(pendingCardIndex, currentOpponentBoardCards);
      }
      setShowAttackingCardDrag(false);
    }
    return [currentPlayerBoardCards, currentOpponentBoardCards, [], currentPlayerLifePoints];
  };

  /**
   * Play artificial intelligence round with current difficulty
   * @param {Hand} hand current opponent ia hand
   * @returns {Promise<void>}
   */
  const playArtificialIntelligence = async (hand: Hand, deck: Deck, gamePhase: GamePhasesEnum, actionPoints: number) => {
    if (isOpponentRound && round >= 0 && opponentIsArtificialIntelligence) {
      if (boardConfiguration && boardConfiguration.opponent && difficulty && difficulty === DifficultyLevel.EASY) {
        await playArtificialIntelligenceRandomManualPhase(hand, deck, gamePhase, actionPoints);
        return;
      }

      await playArtificialIntelligenceManualPhase(hand, deck, gamePhase, actionPoints);
    }
  };

  /**
   * Launch all cards that can be played to board
   * @param {Hand} hand current opponent hand
   * @param {BoardCards} opponentBoardCards current opponent board cards
   * @returns {Promise<[BoardCards, Hand]>} updated boardcards and hand
   */
  const artificialIntelligenceLaunchCardsPhase = async (
    hand: Hand,
    deck: Deck,
    opponentBoardCards: BoardCards,
    gamePhase: GamePhasesEnum,
    actionPoints: number,
    playerLifePoints: number
  ): Promise<{ hand: Hand; deck: Deck; boardCars: BoardCards; enemyActionPoints: number; enemyLifePoints: number }> => {
    let opponentHand: Hand = [...hand];
    let opponentDeck: Deck = [...deck];

    let currentOpponentBoardCards: BoardCards = [...opponentBoardCards];
    let currentActionPoints: number = actionPoints;
    let currentPlayerLifePoints = playerLifePoints;

    if (!opponentHand.length)
      return {
        hand: opponentHand,
        deck: opponentDeck,
        boardCars: currentOpponentBoardCards,
        enemyActionPoints: currentPlayerLifePoints,
        enemyLifePoints: currentPlayerLifePoints
      };

    while (true) {
      const playingCards = opponentHand.filter(card => card.isPlayingCard);
      const randomPlayingCardIndex = Math.floor(0.1111 * playingCards.length);

      const randomPlayingCard = playingCards[randomPlayingCardIndex]; // randomly select card from the playing ones in the opponent hand to play with it
      if (randomPlayingCard === undefined)
        return {
          hand: opponentHand,
          deck: opponentDeck,
          boardCars: currentOpponentBoardCards,
          enemyActionPoints: currentPlayerLifePoints,
          enemyLifePoints: currentPlayerLifePoints
        };
      await wait(1000);
      // If there is a playing card, launch it
      // If it needs a target when launching
      if (
        boardCardsUtils.opponentTargetCardIsPlayable({
          allRules,
          opponentBoardCards: currentOpponentBoardCards,
          playerBoardCards,
          card: randomPlayingCard,
          currentSelectingTargetDrawnCard: !!selectingTargetDrawnCard,
          isPlayerRound
        })
      ) {
        const isReactionLaunch = false;
        // If it needs a target when launching a target is selected

        const {
          actionPoints: updatedActionPoints,
          boardCards: updatedBoardCards,
          deck: updatedDeck,
          hand: updatedHand,
          playerLifePoints: updatedPlayerLifePoints
        } = await applyCardToTargetArtificialIntelligence(
          opponentHand,
          opponentDeck,
          randomPlayingCard,
          gamePhase,
          currentOpponentBoardCards,
          currentActionPoints,
          isReactionLaunch
        );

        [opponentHand, opponentDeck, currentOpponentBoardCards, currentActionPoints, currentPlayerLifePoints] = [
          updatedHand,
          updatedDeck,
          updatedBoardCards,
          updatedActionPoints,
          updatedPlayerLifePoints
        ];

        await wait(1000);
        continue;
      }

      // Otherwhise it is launched to board
      const {
        actionPoints: updatedActionPoints,
        boardCards: updatedBoardCards,
        deck: updatedDeck,
        hand: updatedHand,
        playerLifePoints: updatedPlayerLifePoints
      } = await onLaunchCard(
        randomPlayingCard,
        PlayerTypeEnum.PLAYER,
        currentOpponentBoardCards,
        opponentHand,
        opponentDeck,
        gamePhase,
        currentActionPoints
      );

      [opponentHand, opponentDeck, currentOpponentBoardCards, currentActionPoints, currentPlayerLifePoints] = [
        updatedHand,
        updatedDeck,
        updatedBoardCards,
        updatedActionPoints,
        updatedPlayerLifePoints
      ];

      await wait(1000);
    }
  };

  /**
   * Pass turn to player
   * @returns {Promise<void>}
   */
  const artificalIntelligenceNextTurn = async (): Promise<void> => {
    await wait(1000);
    await nextTurn();
    setShowNextRoundButton(false);
  };

  /**
   * Launch all cards that can be played and attack to player in easy mode
   * @param {Hand} hand current opponent hand
   * @returns {Promise<void>}
   */
  const playArtificialIntelligenceRandomManualPhase = async (
    hand: Hand,
    deck: Deck,
    gamePhase: GamePhasesEnum,
    actionPoints: number
  ): Promise<void> => {
    // AI algorithm when AI level is 2 or less
    let currentPlayerBoardCards = [...playerBoardCards];

    let { boardCars: currentOpponentBoardCards, enemyLifePoints: currentPlayerLifePoints } = await artificialIntelligenceLaunchCardsPhase(
      hand,
      deck,
      opponentBoardCards,
      gamePhase,
      actionPoints,
      playerLifePoints
    );

    if (currentOpponentBoardCards.some(card => card.upgradedFirstTurn)) await wait(1700);
    // If there are no more playable cards to launch, attack phase starts
    while (true) {
      //while there are attacking cards
      const attackingCards = currentOpponentBoardCards.filter(card => !card.isWaiting);

      if (!attackingCards.length && winner === 0) {
        await artificalIntelligenceNextTurn();
        return;
      }

      const randomAttackingCardIndex = Math.floor(0.1111 * attackingCards.length);
      const randomAttackingCard = attackingCards[randomAttackingCardIndex]; //attacking card randomly chosen
      const randomAttackingIndex = currentOpponentBoardCards.findIndex(attackingCard => attackingCard === randomAttackingCard);

      const idAttacking = `drag_${PlayerRoundEnum.OPPONENT}_attacking_${randomAttackingCard.id}_${randomAttackingIndex}`;

      setDragCard(idAttacking);
      setDragAttacking(idAttacking);
      onSelectedToAttackWithCard(currentOpponentBoardCards, randomAttackingIndex);

      const attackableTargets: Array<CardType | string> = currentPlayerBoardCards.filter(card => card.isAttackable); // get attackable card from player board
      // TODO Bug if card is white never attacks avatar
      const isAvatarAttackable =
        !currentPlayerBoardCards.some(({ tags }) => tags?.some(({ tagName }) => tagName === TagsEnum.BARRIER)) &&
        randomAttackingCard.color === ColorCardEnum.BLACK; // check if avatar is attackable, randomAttackingCard.damagePoints > 0

      if (isAvatarAttackable) attackableTargets.push('avatar'); // if avatar is attackable, is added as another random target list to select target from
      if (!attackableTargets.length) {
        await artificalIntelligenceNextTurn();
        return;
      }

      const randomAttackableTargetIndex = Math.floor(0.1111 * attackableTargets.length);
      const randomAttackableTarget = attackableTargets[randomAttackableTargetIndex]; // target to attack is randomly selected

      if (randomAttackableTarget === 'avatar') {
        // if randomly selected target was avatar, attack it

        currentOpponentBoardCards = await onAttackAvatarWithCard(randomAttackingIndex, currentOpponentBoardCards);
        const damage = currentOpponentBoardCards[randomAttackingIndex].damagePoints;
        currentPlayerLifePoints = currentPlayerLifePoints - (damage as number) < 0 ? 0 : currentPlayerLifePoints - (damage as number);
        setShowAttackingCardDrag(false);

        // continue to attack with next ataccking card
        if (currentPlayerLifePoints <= 0) {
          break;
        }
        continue;
      }

      // if randomly selected target was a player, attack it
      const randomAttackableIndex = currentPlayerBoardCards.findIndex(
        attackableCard => attackableCard === (randomAttackableTarget as CardType)
      );
      const {
        attackedCards: updatedPlayerBoardCards,
        attackingCards: updatedOpponentBoardCards,
        playerLifePoints: updatedPlayerLifePoints
      } = await onAttackWithCard(
        randomAttackingIndex,
        randomAttackableIndex,
        currentPlayerBoardCards,
        currentOpponentBoardCards,
        currentPlayerLifePoints
      );

      [currentPlayerBoardCards, currentOpponentBoardCards, currentPlayerLifePoints] = [
        updatedPlayerBoardCards,
        updatedOpponentBoardCards,
        updatedPlayerLifePoints
      ];
      setShowAttackingCardDrag(false);
    }
  };

  /**
   * Attack all possible player hands
   * @param {BoardCards} opponentBoardCards current opponent board cards
   * @returns {Promise<boolean>} true if player has died, otherwise false
   */
  const artificialIntelligenceAttackingLoop = async (opponentBoardCards: BoardCards, playerLifePoints: number): Promise<boolean> => {
    let currentPlayerLifePoints = playerLifePoints;
    let currentPendingCards = [] as CardType[];
    let currentPlayerBoardCards: BoardCards = [...playerBoardCards]; // get copy of player board cards to work with it across iterations
    let currentOpponentBoardCards: BoardCards = [...opponentBoardCards]; // get copy of opponent board cards to work with it across iterations
    // get currently attacking opponent board cards
    let attackingOpponentBoardCards = currentOpponentBoardCards.filter(opponentBoardCard => opponentBoardCard.isWaiting !== true);

    let i = attackingOpponentBoardCards.length;
    // AI ATTACKING LOOP STARTS
    while (i--) {
      await wait(1000);
      // If there are no more attacking Opponent board cards, break loop
      if (currentPlayerLifePoints <= 0) return true;

      if (!attackingOpponentBoardCards.length) return false;

      // Check damage sum of player and opponent board cards
      const opponentDamageSumToPlayer = attackingOpponentBoardCards
        .filter(({ color }) => color === ColorCardEnum.BLACK)
        .reduce((sum, { damagePoints }) => (sum += damagePoints ?? 0), 0);

      const playerDamageSum = currentPlayerBoardCards.reduce((sum, { damagePoints }) => (sum += damagePoints ?? 0), 0);

      const isAvatarAttackable = !currentPlayerBoardCards.some(({ tags }) => tags?.some(({ tagName }) => tagName === TagsEnum.BARRIER));
      // In  case that the sum of the  damage sum of opponent exceeds the current player resistence points
      if (opponentDamageSumToPlayer >= currentPlayerLifePoints && isAvatarAttackable) {
        // All cards attack to avatar

        [currentPlayerLifePoints, currentOpponentBoardCards] = await attackAvatarWithAICard(
          currentPlayerLifePoints,
          currentOpponentBoardCards
        );
        attackingOpponentBoardCards = currentOpponentBoardCards.filter(opponentBoardCard => opponentBoardCard.isWaiting !== true);
        continue;
      }

      if (playerDamageSum >= opponentLifePoints) {
        // In case the sum of the player cards' damage sum exceeds opponente resistence  points, exchange is set to HIGH
        setExchange(ExchangeEnum.HIGH);
      }
      // Ordinary AI attack routine starts

      // First with white cards

      [currentPendingCards, currentPlayerBoardCards, attackingOpponentBoardCards, currentOpponentBoardCards, currentPlayerLifePoints] =
        await ordinaryAIAttack(
          ColorCardEnum.BLACK,
          currentPendingCards,
          currentPlayerBoardCards,
          currentOpponentBoardCards,
          attackingOpponentBoardCards,
          currentPlayerLifePoints
        );

      // Then with black cards

      [currentPendingCards, currentPlayerBoardCards, attackingOpponentBoardCards, currentOpponentBoardCards, currentPlayerLifePoints] =
        await ordinaryAIAttack(
          ColorCardEnum.WHITE,
          currentPendingCards,
          currentPlayerBoardCards,
          currentOpponentBoardCards,
          attackingOpponentBoardCards,
          currentPlayerLifePoints
        );

      // After ordinary attacks, cards were added to PENDING queue. These queue is now processed
      [currentPlayerBoardCards, currentOpponentBoardCards, currentPendingCards, currentPlayerLifePoints] = await processPendingCards(
        currentPendingCards,
        currentPlayerBoardCards,
        currentOpponentBoardCards,
        currentPlayerLifePoints
      );
      attackingOpponentBoardCards = currentOpponentBoardCards.filter(opponentBoardCard => opponentBoardCard.isWaiting !== true);
      continue;
    }
    return false;
  };

  /**
   * Launch all cards that can be played and attack to player in hard mode
   * @param {Hand} hand current opponent hand
   * @returns {Promise<void>}
   */
  const playArtificialIntelligenceManualPhase = async (
    hand: Hand,
    deck: Deck,
    gamePhase: GamePhasesEnum,
    actionPoints: number
  ): Promise<void> => {
    // AI algorithm when AI level is 3

    const {
      hand: opponentHand,
      boardCars: currentOpponentBoardCards,
      enemyLifePoints: currentPlayerLifePoints
    } = await artificialIntelligenceLaunchCardsPhase(hand, deck, opponentBoardCards, gamePhase, actionPoints, playerLifePoints);

    if (currentOpponentBoardCards.some(card => card.upgradedFirstTurn)) await wait(1700);

    // When there are no more cards to launch, attacking loop starts
    const winner = await artificialIntelligenceAttackingLoop(currentOpponentBoardCards, currentPlayerLifePoints);
    const remainingPlayingCards = opponentHand.filter(card => card.isPlayingCard);
    // When there are no more playing cards, automatically ends AI round
    if (winner) return;

    if (
      !currentSelectingTargetDrawnCard &&
      (!remainingPlayingCards.length ||
        remainingPlayingCards.every(card => card.color === ColorCardEnum.WHITE && card.tags?.some(tag => tag.tagName === TagsEnum.BARRIER)))
    ) {
      await wait(1000);
      await nextTurn();
      setShowNextRoundButton(false);
    }
  };

  /**
   * Steps to play a round
   * @param {RoundData} roundData player and opponent data needed to play the current round
   * @returns {Promise<void>}
   */
  const playRound = async (roundData: RoundData): Promise<void> => {
    // launch reactions
    if (round !== 0) await reactionsRoundStart(isPlayerRound ? PlayerRoundEnum.PLAYER : PlayerRoundEnum.OPPONENT);

    // show indicator turn
    setYourTurn(isPlayerRound);
    await wait(500);

    //start prepare cards
    const [hand, deck, actionPoints] = await playInitialPhase(roundData);
    await wait(200);

    if (isOpponentRound && opponentIsArtificialIntelligence) {
      setShowNextRoundButton(false);
    } else {
      setShowNextRoundButton(true);
    }

    await wait(200);

    setGamePhase(GamePhasesEnum.MANUAL);

    if (opponentIsArtificialIntelligence && isOpponentRound) {
      await playArtificialIntelligence(hand, deck, GamePhasesEnum.MANUAL, actionPoints);
    }
  };

  /**
   * Removes played card from opponent hand
   * @param {CardType} playedCard card launched from opponentHand
   * @param {Hand} hand: current opponent hand
   * @param {boolean} [isReactionLaunch] is a reaction
   * @returns {[Hand, number]} the current opponentHand and updated action points
   */
  const updateOpponentHandOnPlayCard = (
    playedCard: CardType,
    hand: Hand,
    actionPoints: number,
    isReactionLaunch = false
  ): [Hand, number] => {
    const { actionPoints: playedCardActionPoints, idUnique: playedCardIdUnique } = playedCard;
    const newOpponentHand = hand.filter(({ idUnique }) => idUnique !== playedCardIdUnique);

    opponentHandleHand.replace(newOpponentHand);

    if (!isReactionLaunch) {
      opponentHandleSide.handleActionPoints.replace(actionPoints - playedCardActionPoints);
      return [newOpponentHand, actionPoints - playedCardActionPoints];
    }

    return [newOpponentHand, actionPoints];
  };

  /**
   * Removes played card from player hand
   * @param {CardType} playedCard card launched from playerHand
   * @param {Hand} hand: current player hand
   * @param {boolean} [isReactionLaunch] is a reaction
   * @returns {[Hand, number]} the current playerHand and updated action points
   */
  const updatePlayerHandOnPlayCard = (playedCard: CardType, hand: Hand, actionPoints: number, isReactionLaunch = false): [Hand, number] => {
    const { actionPoints: playedCardActionPoints, idUnique: playedCardIdUnique } = playedCard;
    const newPlayerHand = hand.filter(({ idUnique }) => idUnique !== playedCardIdUnique);

    playerHandleHand.replace(newPlayerHand);

    if (!isReactionLaunch) {
      playerHandleSide.handleActionPoints.replace(actionPoints - playedCardActionPoints);
      return [newPlayerHand, actionPoints - playedCardActionPoints];
    }

    return [newPlayerHand, playerActionPoints];
  };

  const showDetailActionOrSupportCard = async (playedCard: CardType, targetCard: Card | undefined, cardUpgradedModal: boolean) => {
    const timeAnimation = 4000;
    const { type, color, useRequirementType } = playedCard;
    if (opponentIsArtificialIntelligence && (type?.typeName === TypesEnum.ACTION || type?.typeName === TypesEnum.SUPPORT)) {
      resetDetailCard();
      setCardMoved(true);
      updateDetailCard(
        <SelectedCard card={playedCard} cardSize={CardSizesEnum.VERY_BIG} />,
        {
          left: rw(10) - rw(2),
          top: rh(25)
        },
        true
      );
      await wait(timeAnimation);
      resetDetailCard();
      setCardMoved(false);

      if (color === ColorCardEnum.BLACK && useRequirementType?.typeName === TypesEnum.FAKE) {
        setUpgradeCards([playedCard, targetCard!]);
        cardUpgradedModal = true;
      }
    }
    return cardUpgradedModal;
  };

  /**
   * Launch a new opponent card from hand to boardCard and apply rules
   * @param {CardType} playedCard card launched from hand
   * @param {string} [targetId] card target id
   * @param {boolean} [isReactionLaunch] is a reaction launch
   * @param {BoardCards} opponentBoardCards current opponent board cards
   * @returns {Promise<[Hand, BoardCards]>}updated opponent hand and boardCards
   */
  const onLaunchCardOpponent = async (
    playedCard: CardType,
    hand: Hand,
    opponentBoardCards: BoardCards,
    actionPoints: number,
    deck: Deck,
    targetId = '',
    isReactionLaunch = false
  ): Promise<{
    hand: Hand;
    deck: Deck;
    boardCards: BoardCards;
    actionPoints: number;
    playerLifePoints: number;
    opponentLifePoints: number;
  }> => {
    const isLaunchedCard = true;
    const opponentRound = true;
    const targetCard = opponentBoardCards.find(({ id }) => id === targetId);
    let newOpponentHand: Hand = [...hand];
    let opponentDeck: Deck = [...deck];
    let currentOpponentActionPoints = actionPoints;

    const playedCardRules = getCardRules(playedCard, allRules);
    let cardUpgradedModal = false;

    if (!opponentIsArtificialIntelligence && opponentBoardCards.length >= MAX_BOARD_CARDS_LENGTH) {
      showLeftoverModal(opponentBoardCards);
    }

    cardUpgradedModal = await showDetailActionOrSupportCard(playedCard, targetCard, cardUpgradedModal);

    const { hasRulesApplicables, targetInvalid } = cardHasRulesApplicables(
      playedCard,
      playedCardRules,
      isReactionLaunch,
      targetId,
      playerBoardCards,
      opponentBoardCards
    );

    if (hasRulesApplicables) {
      if (targetInvalid) {
        return {
          hand: newOpponentHand,
          deck: opponentDeck,
          boardCards: opponentBoardCards,
          actionPoints: currentOpponentActionPoints,
          playerLifePoints,
          opponentLifePoints
        };
      }

      [newOpponentHand, currentOpponentActionPoints] = updateOpponentHandOnPlayCard(
        playedCard,
        newOpponentHand,
        currentOpponentActionPoints,
        isReactionLaunch
      );

      const updatedOpponentHand = boardCardsUtils.checkPlayingCards({
        playerBoardCards,
        actualActionPoints: currentOpponentActionPoints,
        allRules: allRules,
        hand: newOpponentHand,
        isPlayerRound,
        opponentBoardCards
      });

      opponentHandleHand.replace(updatedOpponentHand);

      const [preUpdatedHand, preUpdatedBoardCards] = boardCardsUtils.updateBoardCardsOnPlayCard({
        actionPoints: currentOpponentActionPoints,
        allRules: allRules,
        boardCards: opponentBoardCards,
        rivalBoardCards: playerBoardCards,
        isPlayerRound,
        hand: updatedOpponentHand,
        playedCard
      });

      opponentHandleHand.replace(preUpdatedHand);

      opponentBoardCardHandler.replace(preUpdatedBoardCards);

      const {
        deck: updatedOpponentDeck,
        hand: postUpdatedNewHand,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints,
        opponentBoardCards: opponentBoardCardWithRules,
        playerBoardCards: playerBoardCardWithRules
      } = await applyCardRules({
        deck: opponentDeck,
        hand: updatedOpponentHand,
        isOpponent: opponentRound,
        opponentLifePoints,
        playerLifePoints,
        opponentBoardCards,
        playedCard,
        playedCardRules,
        playerBoardCards,
        actionPoints: currentOpponentActionPoints,
        isLaunchedCard,
        targetId
      });
      opponentDeck = updatedOpponentDeck;

      if (cardUpgradedModal) {
        await wait(900);
        setUpgradeCards([playedCard, opponentBoardCardWithRules.find(card => card.idUnique === targetCard?.idUnique) ?? null]);
      }

      const [updatedHand, updatedBoardCards] = boardCardsUtils.updateBoardCardsOnPlayCard({
        actionPoints: currentOpponentActionPoints,
        allRules: allRules,
        boardCards: [...opponentBoardCardWithRules],
        rivalBoardCards: [...playerBoardCardWithRules],
        isPlayerRound,
        hand: postUpdatedNewHand,
        playedCard
      });

      const boardCardsLeftOver = await showLeftoverAndRemoveCards(updatedBoardCards, playedCard, !!opponentIsArtificialIntelligence);
      const boardCards = pendingCards.current && pendingCards.current.length > 0 ? pendingCards.current : boardCardsLeftOver;

      opponentBoardCardHandler.replace(boardCards);
      playerBoardCardHandler.replace(playerBoardCardWithRules);

      opponentHandleHand.replace(updatedHand);

      await checkIfCardsAreDead(opponentBoardCardWithRules, playerBoardCardWithRules);

      pendingCards.current = null;

      const updatedOpponentBoard = boardCardsUtils.removeUpgradeCardStateAndApplyEffects(boardCards);

      if (cardUpgradedModal) {
        setUpgradeCards([playedCard, updatedOpponentBoard.find(card => card.idUnique === targetCard?.idUnique) ?? null]);
        await wait(2500);
        setUpgradeCards([null, null]);
      }

      const opponentBoardCardsAlive = updatedOpponentBoard.filter(boardCardsUtils.filterOnlyAliveCards);

      playerBoardCardHandler.replace(playerBoardCardWithRules.filter(boardCardsUtils.filterOnlyAliveCards));
      opponentBoardCardHandler.replace(opponentBoardCardsAlive);

      if (cardUpgradedModal) {
        await wait(800);
        setUpgradeCards([null, null]);
      }

      await reactionsPlayCard(playedCard, PlayerRoundEnum.OPPONENT);

      return {
        hand: updatedHand,
        deck: opponentDeck,
        boardCards: opponentBoardCardsAlive,
        actionPoints: currentOpponentActionPoints,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      };
    }

    [newOpponentHand, currentOpponentActionPoints] = updateOpponentHandOnPlayCard(
      playedCard,
      newOpponentHand,
      currentOpponentActionPoints,
      isReactionLaunch
    );

    const updatedOpponentHand = boardCardsUtils.checkPlayingCards({
      playerBoardCards,
      actualActionPoints: currentOpponentActionPoints,
      allRules: allRules,
      hand: newOpponentHand,
      isPlayerRound,
      opponentBoardCards
    });

    opponentHandleHand.replace(updatedOpponentHand);

    const [currentOpponentHand, currentOpponentBoardCards] = boardCardsUtils.launchCardAndEnhanceEffects({
      actionPoints: currentOpponentActionPoints,
      allRules: allRules,
      boardCards: opponentBoardCards,
      hand: updatedOpponentHand,
      isPlayerRound,
      playedCard,
      rivalBoardCards: playerBoardCards
    });

    const boardCardsLeftOver = await showLeftoverAndRemoveCards(currentOpponentBoardCards, playedCard, !!opponentIsArtificialIntelligence);

    opponentHandleHand.replace(currentOpponentHand);
    opponentBoardCardHandler.replace(boardCardsLeftOver);

    await checkIfCardsAreDead(currentOpponentBoardCards, boardCardsLeftOver);

    await reactionsPlayCard(playedCard, PlayerRoundEnum.OPPONENT);

    const opponentBoardCardsAlive = boardCardsLeftOver.filter(boardCardsUtils.filterOnlyAliveCards);

    playerBoardCardHandler.replace(playerBoardCards.filter(boardCardsUtils.filterOnlyAliveCards));
    opponentBoardCardHandler.replace(opponentBoardCardsAlive);

    return {
      hand: currentOpponentHand,
      deck: opponentDeck,
      boardCards: opponentBoardCardsAlive,
      actionPoints: currentOpponentActionPoints,
      opponentLifePoints,
      playerLifePoints
    };
  };

  const setBoardCards = useCallback((card: CardType, side: 'player' | 'opponent') => {
    const updateBoardCardsByUniqueIndex = (card: CardType) => (cards: CardType[]) => {
      const updatedBoardCardIndex = cards.findIndex(boardCard => boardCard.idUnique === card.idUnique);
      if (updatedBoardCardIndex !== -1) cards[updatedBoardCardIndex] = card;
      return cards;
    };

    side === 'opponent'
      ? opponentBoardCardHandler.replaceCb(updateBoardCardsByUniqueIndex(card))
      : playerBoardCardHandler.replaceCb(updateBoardCardsByUniqueIndex(card));
  }, []);

  const cardHasRulesApplicables = (
    playedCard: CardType,
    playedCardRules: Rule[],
    isReactionLaunch: boolean,
    targetId: string,
    playerBoardCards: BoardCards,
    opponentBoardCards: BoardCards
  ) => {
    const cardRequireTarget = boardCardsUtils.checkIsSelectingTarget(
      playedCard,
      isReactionLaunch,
      allRules,
      !!selectingTargetDrawnCard,
      playerBoardCards,
      opponentBoardCards,
      isPlayerRound
    );
    const cardHasRules = playedCardRules?.length;
    const cardHasMultipleRules = cardHasRules > 1;
    const firstRuleNeedTarget = cardHasRules && (playedCardRules as Rule[])[0]?.needTarget;
    const hasRulesApplicables = (cardHasRules && (cardRequireTarget || !firstRuleNeedTarget)) || cardHasMultipleRules;
    const targetInvalid = !cardHasMultipleRules && firstRuleNeedTarget && targetId === '';
    return { hasRulesApplicables, targetInvalid };
  };

  /**
   * Launch a new player card from hand to boardCard and apply rules
   * @param {CardType} playedCard card launched from hand
   * @param {string} [targetId] card target id
   * @param {boolean} [isReactionLaunch] is a reaction launch
   * @returns {Promise<[Hand, BoardCards]>} updated player hand and boardCards
   */
  const onLaunchCardPlayer = async (
    playedCard: CardType,
    playerHand: Hand,
    targetId = '',
    isReactionLaunch = false
  ): Promise<{
    hand: Hand;
    deck: Deck;
    boardCards: BoardCards;
    actionPoints: number;
    playerLifePoints: number;
    opponentLifePoints: number;
  }> => {
    const isLaunchedCard = true;
    const opponentRound = false;
    let newPlayerHand = [...playerHand];
    let currentPlayerActionPoints = playerActionPoints;
    let cardUpgradedModal = false;

    const targetCard = playerBoardCards.find(({ id }) => id === targetId);

    const playedCardRules = getCardRules(playedCard, allRules);

    const { type, color, useRequirementType } = playedCard;

    if (type?.typeName === TypesEnum.ACTION || type?.typeName === TypesEnum.SUPPORT) {
      if (color === ColorCardEnum.BLACK && useRequirementType?.typeName === TypesEnum.FAKE) {
        await wait(500);
        setUpgradeCards([playedCard, targetCard!]);
        cardUpgradedModal = true;
        await wait(500);
      }
    }

    const { hasRulesApplicables, targetInvalid } = cardHasRulesApplicables(
      playedCard,
      playedCardRules,
      isReactionLaunch,
      targetId,
      playerBoardCards,
      opponentBoardCards
    );
    if (hasRulesApplicables) {
      if (targetInvalid) {
        return {
          hand: newPlayerHand,
          deck: playerDeck,
          boardCards: playerBoardCards,
          actionPoints: currentPlayerActionPoints,
          playerLifePoints,
          opponentLifePoints
        };
      }

      [newPlayerHand, currentPlayerActionPoints] = updatePlayerHandOnPlayCard(
        playedCard,
        newPlayerHand,
        currentPlayerActionPoints,
        isReactionLaunch
      );

      const updatedPlayerHand = boardCardsUtils.checkPlayingCards({
        actualActionPoints: currentPlayerActionPoints,
        allRules,
        playerBoardCards,
        hand: newPlayerHand,
        isPlayerRound,
        opponentBoardCards
      });

      playerHandleHand.replace(updatedPlayerHand);

      const [preUpdatedHand, preUpdatedBoardCards] = boardCardsUtils.updateBoardCardsOnPlayCard({
        actionPoints: currentPlayerActionPoints,
        allRules,
        boardCards: playerBoardCards,
        rivalBoardCards: opponentBoardCards,
        isPlayerRound,
        hand: updatedPlayerHand,
        playedCard
      });

      playerHandleHand.replace(preUpdatedHand);

      playerBoardCardHandler.replace(preUpdatedBoardCards);

      const {
        deck: updatedPlayerDeck,
        hand: postPlayerHand,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints,
        opponentBoardCards: opponentBoardCardWithRules,
        playerBoardCards: playerBoardCardWithRules
      } = await applyCardRules({
        playedCard,
        isOpponent: opponentRound,
        playedCardRules,
        opponentBoardCards,
        playerBoardCards,
        hand: updatedPlayerHand,
        deck: playerDeck,
        targetId,
        actionPoints: currentPlayerActionPoints,
        isLaunchedCard,
        opponentLifePoints,
        playerLifePoints
      });

      if (cardUpgradedModal) {
        await wait(900);
        setUpgradeCards([playedCard, playerBoardCardWithRules.find(card => card.idUnique === targetCard?.idUnique) ?? null]);
      }

      const [updatedHand, updatedBoardCards] = boardCardsUtils.updateBoardCardsOnPlayCard({
        actionPoints: currentPlayerActionPoints,
        allRules,
        boardCards: [...playerBoardCardWithRules],
        rivalBoardCards: [...opponentBoardCardWithRules],
        isPlayerRound,
        hand: postPlayerHand,
        playedCard
      });

      playerHandleHand.replace(updatedHand);

      const boardCardsLeftOver = await showLeftoverAndRemoveCards(updatedBoardCards, playedCard, false);

      const boardCards = pendingCards.current ? pendingCards.current : boardCardsLeftOver;
      playerBoardCardHandler.replace(boardCards);
      opponentBoardCardHandler.replace(opponentBoardCardWithRules);

      await checkIfCardsAreDead(opponentBoardCardWithRules, boardCards);

      pendingCards.current = null;

      const updatedPlayerBoard = boardCardsUtils.removeUpgradeCardStateAndApplyEffects(boardCards);

      if (cardUpgradedModal) {
        setUpgradeCards([playedCard, updatedPlayerBoard.find(card => card.idUnique === targetCard?.idUnique) ?? null]);
        await wait(2500);
        setUpgradeCards([null, null]);
      }

      const playerBoardCardsAlive = updatedPlayerBoard.filter(boardCardsUtils.filterOnlyAliveCards);

      playerBoardCardHandler.replace(playerBoardCardsAlive);
      opponentBoardCardHandler.replace(opponentBoardCardWithRules.filter(boardCardsUtils.filterOnlyAliveCards));

      if (cardUpgradedModal) {
        await wait(800);
        setUpgradeCards([null, null]);
      }

      await reactionsPlayCard(playedCard, PlayerRoundEnum.PLAYER);

      return {
        hand: updatedHand,
        deck: updatedPlayerDeck,
        boardCards: playerBoardCardsAlive,
        actionPoints: currentPlayerActionPoints,
        opponentLifePoints: updatedOpponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      };
    }

    [newPlayerHand, currentPlayerActionPoints] = updatePlayerHandOnPlayCard(
      playedCard,
      playerHand,
      currentPlayerActionPoints,
      isReactionLaunch
    );

    const updatedPlayerHand = boardCardsUtils.checkPlayingCards({
      actualActionPoints: currentPlayerActionPoints,
      allRules,
      playerBoardCards,
      hand: newPlayerHand,
      isPlayerRound,
      opponentBoardCards
    });

    playerHandleHand.replace(updatedPlayerHand);

    const [currentPlayerHand, currentPlayerBoardCards] = boardCardsUtils.launchCardAndEnhanceEffects({
      actionPoints: currentPlayerActionPoints,
      allRules,
      boardCards: playerBoardCards,
      hand: newPlayerHand,
      isPlayerRound,
      playedCard,
      rivalBoardCards: opponentBoardCards
    });

    const boardCardsLeftOver = await showLeftoverAndRemoveCards(currentPlayerBoardCards, playedCard, false);

    playerBoardCardHandler.replace(boardCardsLeftOver);

    await checkIfCardsAreDead(opponentBoardCards, boardCardsLeftOver);

    playerHandleHand.replace(currentPlayerHand);

    const playerBoardCardsAlive = boardCardsLeftOver.filter(boardCardsUtils.filterOnlyAliveCards);

    playerBoardCardHandler.replace(playerBoardCardsAlive);
    opponentBoardCardHandler.replace(opponentBoardCards.filter(boardCardsUtils.filterOnlyAliveCards));

    await reactionsPlayCard(playedCard, PlayerRoundEnum.PLAYER);

    return {
      hand: currentPlayerHand,
      deck: playerDeck,
      boardCards: playerBoardCardsAlive,
      actionPoints: currentPlayerActionPoints,
      opponentLifePoints,
      playerLifePoints
    };
  };

  /**
   * Launch a new card to boardCards
   * @param {CardType} card  card to launch to board cards
   * @param {number} player current player in this round
   * @param {BoardCards} opponentBoardCards current opponent board cards
   * @param {string} [targetId] selected target id
   * @param {boolean} [isReactionLaunch] is a reaction launch
   * @returns {Promise<[Hand, BoardCards] | null>} current hand and boardCards or null if cannot launch
   */
  const onLaunchCard = async (
    card: CardType,
    player: number,
    opponentBoardCards: BoardCards,
    hand: Hand,
    deck: Deck,
    gamePhase: GamePhasesEnum,
    actionPoints: number,
    targetId = '',
    isReactionLaunch = false
  ): Promise<{
    hand: Hand;
    deck: Deck;
    boardCards: BoardCards;
    actionPoints: number;
    playerLifePoints: number;
    opponentLifePoints: number;
  }> => {
    const playedCard = { ...card };
    const copyHand = [...hand];

    playedCard.isAttacking = false;
    if (!playedCard.isPlayingCard || gamePhase !== GamePhasesEnum.MANUAL || isCurrentPlayerRound(player))
      return {
        actionPoints,
        boardCards: isOpponentRound ? opponentBoardCards : playerBoardCards,
        deck,
        hand,
        opponentLifePoints,
        playerLifePoints
      };

    if (!playedCard.tags || !playedCard.tags.some(tag => tag.tagName === TagsEnum.IMMEDIACY)) {
      playedCard.firstTurn = true;
      playedCard.isWaiting = true;
    }

    if (isOpponentRound) {
      return await onLaunchCardOpponent(playedCard, copyHand, opponentBoardCards, actionPoints, deck, targetId, isReactionLaunch);
    }
    return await onLaunchCardPlayer(playedCard, copyHand, targetId, isReactionLaunch);
  };

  const onCloseDetailModal = useCallback(() => resetDetailCard(), [resetDetailCard]);

  const onCardPressed = useCallback(
    (card: CardType, side: PlayerRoundEnum, position: ViewStyle): void => {
      if (isLaunchingCardFromHand || cardMoved) return;
      const enemyKey = route.params.gameConfig?.opponent?.key;
      updateDetailCard(<CardHover card={card} enemyKey={enemyKey!} side={side} />, position);
    },
    [isLaunchingCardFromHand, cardMoved, updateDetailCard]
  );

  const onSelectedToAttackWithOpponentCard = (boardCards: BoardCards, boardCardIndex: number): void => {
    if (boardCards[boardCardIndex].isAttacking) {
      // If is attacking, remove attacking flag
      opponentBoardCardHandler.replaceCb(boardCardsUtils.removeAttackingFlag);
      opponentUpdateAttacking(false);

      return;
    }
    if (!boardCards[boardCardIndex].isAttacking && !boardCards[boardCardIndex].isWaiting) {
      opponentUpdateAttacking(false);
      opponentBoardCardHandler.replaceCb(boardCardsUtils.setAtttackingCardByIndex(boardCardIndex));
      opponentUpdateAttacking(true);

      const updatedPlayerBoardCards = [...playerBoardCards];

      const playerAttackableCards = boardCardsUtils.setAttackableCards(playerBoardCards);
      playerBoardCardHandler.replaceCb(playerAttackableCards);
      playerUpdateAttackable(
        !updatedPlayerBoardCards.some(playerBoardCard => playerBoardCard.tags?.some(tag => tag.tagName === TagsEnum.BARRIER))
      );

      return;
    }
    const updatedOpponentBoardCards = [...boardCards];
    opponentUpdateAttacking(false);
    updatedOpponentBoardCards[boardCardIndex].isAttacking = false;
    opponentBoardCardHandler.replaceCb(boardCardsUtils.removeAttackingFlagByIndex(boardCardIndex));
    playerBoardCardHandler.replaceCb(boardCardsUtils.removeAttackableFlag);
    playerUpdateAttackable(false);

    return;
  };

  const onSelectedToAttackWithPlayerCard = (boardCardIndex: number): void => {
    if (playerBoardCards[boardCardIndex].isAttacking) {
      playerUpdateAttacking(false);
      playerBoardCardHandler.replaceCb(boardCardsUtils.removeAttackingFlag);

      return;
    }
    playerUpdateAttacking(false);
    if (!playerBoardCards[boardCardIndex].isAttacking && !playerBoardCards[boardCardIndex].isWaiting) {
      playerBoardCardHandler.replaceCb(boardCardsUtils.setAtttackingCardByIndex(boardCardIndex));
      playerUpdateAttacking(true);

      const updatedOpponentBoardCards = [...opponentBoardCards];
      opponentBoardCardHandler.replaceCb(boardCardsUtils.setAttackableCards(opponentBoardCards));
      opponentUpdateAttackable(
        !updatedOpponentBoardCards.some(opponentBoardCard => opponentBoardCard.tags?.some(tag => tag.tagName === TagsEnum.BARRIER))
      );
      return;
    }
    const updatedPlayerBoardCards = [...playerBoardCards];
    updatedPlayerBoardCards[boardCardIndex].isAttacking = false;
    playerUpdateAttacking(false);
    playerBoardCardHandler.replaceCb(boardCardsUtils.removeAttackingFlagByIndex(boardCardIndex));
    opponentBoardCardHandler.replaceCb(boardCardsUtils.removeAttackableFlag);

    opponentUpdateAttackable(false);
    return;
  };

  const onSelectedToAttackWithCard = (boardCards: BoardCards, boardCardIndex: number): void => {
    if (isOpponentRound) {
      onSelectedToAttackWithOpponentCard(boardCards, boardCardIndex);
      return;
    }
    onSelectedToAttackWithPlayerCard(boardCardIndex);
  };

  const resetAttackCards = (): void => setBattleCards([null, null]);

  const handleShowBattleModal = async (attackingCard: CardType, attackedCard: CardType): Promise<void> => {
    setBattleCards([attackingCard, attackedCard]);
    // If is opponent wait to show the effects
    if (isOpponentRound && opponentIsArtificialIntelligence) {
      await wait(3000);
    }
    setShowBattleModal(true);
    await wait(3500);
    setShowBattleModal(false);
    resetAttackCards();
  };

  /**
   * Attack player with and opponent card
   * @param {number} attackingCardIndex index of attacking card
   * @param {number} attackedCardIndex index of attacked card
   * @param {BoardCards} currentPlayerBoardCards player board cards
   * @param {BoardCards} currentOpponentBoardCards opponent board cards
   * @returns {Promise<[BoardCards, BoardCards]>} updated player and opponent board cards
   */
  const onAttackWithOpponentCard = async (
    attackingCardIndex: number,
    attackedCardIndex: number,
    currentPlayerBoardCards: CardType[],
    currentOpponentBoardCards: CardType[],
    playerLifePoints: number
  ) => {
    const opponentRound = true;
    let copyPlayerBoardCards = [...currentPlayerBoardCards];
    let copyOpponentBoardCards = [...currentOpponentBoardCards];
    let finalPlayerLifePoints = playerLifePoints;
    let finalOpponentLifePoints = opponentLifePoints;

    const attackingCard = copyOpponentBoardCards[attackingCardIndex];
    const attackedCard = copyPlayerBoardCards[attackedCardIndex];

    if (!attackingCard || !attackingCard.type || isActionOrSupportCard(attackingCard)) {
      return {
        attackedCards: copyPlayerBoardCards,
        attackingCards: copyOpponentBoardCards,
        playerLifePoints: finalPlayerLifePoints,
        opponentLifePoints: finalOpponentLifePoints
      };
    }

    const updatedAttackingCard = boardCardsUtils.removeUndetectableTag(attackingCard);
    updatedAttackingCard.isWaiting = true;

    await handleShowBattleModal(updatedAttackingCard, attackedCard);
    launchReactions(reactionsManagementProps, [ReactionEventEnum.OPPONENT_DAMAGE_CARD], updatedAttackingCard);

    attackedCard.defensePoints = cardHasTag(updatedAttackingCard, TagsEnum.FACTCHECK)
      ? 0
      : (attackedCard.defensePoints as number) - (updatedAttackingCard.damagePoints as number);

    launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_CARD_DAMAGED], attackedCard);

    if (cardHasTag(updatedAttackingCard, TagsEnum.IMPACT) && attackedCard?.defensePoints < 0) {
      await activateAnimationLifePoints(
        attackedCard && attackedCard.defensePoints ? attackedCard.defensePoints : 0,
        PlayerRoundEnum.PLAYER
      );

      playerHandleSide.handleLifePoints.increment(attackedCard && attackedCard.defensePoints ? attackedCard.defensePoints : 0);
    }

    updatedAttackingCard.defensePoints = cardHasTag(attackedCard, TagsEnum.FACTCHECK)
      ? 0
      : (updatedAttackingCard.defensePoints as number) - (attackedCard.damagePoints as number);

    if (attackedCard.defensePoints <= 0 || updatedAttackingCard.defensePoints <= 0) {
      const {
        boardCards,
        lifePoints: { opponentLifePoints: updatedOpponentLifePoints, playerLifePoints: updatedPlayerLifePoints }
      } = await onAttackApplyCardRules({
        attackedCard,
        attackingCard: updatedAttackingCard,
        isOpponent: opponentRound,
        deck: opponentDeck,
        enemyDeck: playerDeck,
        hand: opponentHand,
        enemyHand: playerHand,
        opponentLifePoints,
        playerLifePoints,
        opponentBoardCards: copyOpponentBoardCards,
        playerBoardCards: copyPlayerBoardCards
      });

      const { opponentBoardCards: updatedOpponentBoardCards, playerBoardCards: updatedPlayerBoardCards } = boardCards;
      [copyOpponentBoardCards, copyPlayerBoardCards, finalPlayerLifePoints, finalOpponentLifePoints] = [
        updatedOpponentBoardCards,
        updatedPlayerBoardCards,
        updatedPlayerLifePoints,
        updatedOpponentLifePoints
      ];

      launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_CARD_DESTROYED], attackedCard);
      launchReactions(reactionsManagementProps, [ReactionEventEnum.OPPONENT_DESTROY_CARD], updatedAttackingCard);
    }

    updatedAttackingCard.isWaiting = true;
    updatedAttackingCard.isAttacking = false;

    playerBoardCardHandler.replace(copyPlayerBoardCards);
    opponentBoardCardHandler.replace(copyOpponentBoardCards);

    await wait(1000);

    const aliveOpponentBoardCards = copyOpponentBoardCards
      .filter(boardCardsUtils.filterCardsWithDefensePoints)
      .map(card => ({ ...card, upgradedFirstTurn: false }));
    const alivePlayerBoardCards = copyPlayerBoardCards
      .filter(boardCardsUtils.filterCardsWithDefensePoints)
      .map(boardCardsUtils.removeAttackableFlagCard)
      .map(card => ({ ...card, upgradedFirstTurn: false }));

    opponentBoardCardHandler.replace(aliveOpponentBoardCards);
    playerBoardCardHandler.replace(alivePlayerBoardCards);

    return {
      attackedCards: alivePlayerBoardCards,
      attackingCards: aliveOpponentBoardCards,
      playerLifePoints: finalPlayerLifePoints,
      opponentLifePoints: finalOpponentLifePoints
    };
  };

  /**
   * Attack opponent with a player card
   * @param {number} attackingCardIndex index of attacking card
   * @param {number} attackedCardIndex index of attacked card
   * @param {BoardCards} currentPlayerBoardCards player board cards
   * @param {BoardCards} currentOpponentBoardCards opponent board cards
   * @returns {Promise<[BoardCards, BoardCards]>} updated player and opponent board cards
   */
  const onAttackWithPlayerCard = async (
    attackingCardIndex: number,
    attackedCardIndex: number,
    currentPlayerBoardCards: CardType[],
    currentOpponentBoardCards: CardType[],
    playerLifePoints: number
  ) => {
    const opponentRound = false;
    let copyPlayerBoardCards = [...currentPlayerBoardCards];
    let copyOpponentBoardCards = [...currentOpponentBoardCards];
    const finalPlayerLifePoints = playerLifePoints;
    const finalOpponentLifePoints = opponentLifePoints;

    let attackingCard = copyPlayerBoardCards[attackingCardIndex];
    const attackedCard = copyOpponentBoardCards[attackedCardIndex];

    if (!attackingCard || !attackingCard.type || isActionOrSupportCard(attackingCard)) {
      return {
        attackedCards: copyOpponentBoardCards,
        attackingCards: copyPlayerBoardCards,
        playerLifePoints: finalPlayerLifePoints,
        opponentLifePoints: finalOpponentLifePoints
      };
    }

    attackingCard = boardCardsUtils.removeUndetectableTag(attackingCard);
    attackingCard.isWaiting = true;

    await handleShowBattleModal(attackingCard, attackedCard);
    launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_DAMAGE_CARD], attackingCard);

    attackedCard.defensePoints = cardHasTag(attackingCard, TagsEnum.FACTCHECK)
      ? 0
      : (attackedCard.defensePoints as number) - (attackingCard.damagePoints as number);

    if (cardHasTag(attackingCard, TagsEnum.IMPACT) && attackedCard.defensePoints < 0) {
      await activateAnimationLifePoints(
        attackedCard && attackedCard.defensePoints ? attackedCard.defensePoints : 0,
        PlayerRoundEnum.OPPONENT
      );
      opponentHandleSide.handleLifePoints.increment(attackedCard && attackedCard.defensePoints ? attackedCard.defensePoints : 0);
    }

    launchReactions(reactionsManagementProps, [ReactionEventEnum.OPPONENT_CARD_DAMAGED], attackedCard, attackingCard);

    attackingCard.defensePoints = cardHasTag(attackedCard, TagsEnum.FACTCHECK)
      ? 0
      : (attackingCard.defensePoints as number) - (attackedCard.damagePoints as number);

    if (attackedCard.defensePoints <= 0 || attackingCard.defensePoints <= 0) {
      const { boardCards } = await onAttackApplyCardRules({
        attackedCard,
        attackingCard,
        isOpponent: opponentRound,
        deck: playerDeck,
        enemyDeck: opponentDeck,
        hand: playerHand,
        enemyHand: opponentHand,
        opponentLifePoints,
        playerLifePoints,
        opponentBoardCards: copyOpponentBoardCards,
        playerBoardCards: copyPlayerBoardCards
      });
      const { opponentBoardCards: updatedOpponentBoardCards, playerBoardCards: updatedPlayerBoardCards } = boardCards;
      [copyOpponentBoardCards, copyPlayerBoardCards] = [updatedOpponentBoardCards, updatedPlayerBoardCards];
      // TODO Check this reactions
      launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_CARD_DESTROYED], attackedCard);
      launchReactions(reactionsManagementProps, [ReactionEventEnum.OPPONENT_DESTROY_CARD], attackingCard);
      launchReactions(reactionsManagementProps, [ReactionEventEnum.OPPONENT_CARD_DESTROYED], attackedCard);
      launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_DESTROY_CARD], attackingCard);
    }

    attackingCard.isWaiting = true;
    attackingCard.isAttacking = false;

    playerBoardCardHandler.replace(copyPlayerBoardCards);
    opponentBoardCardHandler.replace(copyOpponentBoardCards);

    await wait(1000);
    const updatedPlayerBoardCards = copyPlayerBoardCards.filter(boardCardsUtils.filterCardsWithDefensePoints);

    const updatedOpponentCards = copyOpponentBoardCards
      .filter(boardCardsUtils.filterCardsWithDefensePoints)
      .map(boardCardsUtils.removeAttackingFlagCard);

    playerBoardCardHandler.replace(updatedPlayerBoardCards.map(card => ({ ...card, upgradedFirstTurn: false })));
    opponentBoardCardHandler.replace(updatedOpponentCards.map(card => ({ ...card, upgradedFirstTurn: false })));

    return {
      attackedCards: copyOpponentBoardCards,
      attackingCards: copyPlayerBoardCards,
      playerLifePoints: finalPlayerLifePoints,
      opponentLifePoints: finalOpponentLifePoints
    };
  };

  /**
   * TODO USE FINAL OPPONENT LIFE POINTS TO CALC
   * Attack one side to the another side
   * @param {number} attackingCardIndex attacking card index
   * @param {number} attackedCardIndex attacked card index
   * @param {BoardCards} currentPlayerBoardCards player board cards
   * @param {BoardCards} currentOpponentBoardCards opponent board cards
   * @returns {Promise<{ attackedCards: BoardCards; attackingCards: BoardCards; playerLifePoints: number; opponentLifePoints: number }>} attacked cards and attacking cards updated
   */
  const onAttackWithCard = async (
    attackingCardIndex: number,
    attackedCardIndex: number,
    currentPlayerBoardCards: BoardCards,
    currentOpponentBoardCards: BoardCards,
    playerLifePoints: number
  ): Promise<{ attackedCards: BoardCards; attackingCards: BoardCards; playerLifePoints: number; opponentLifePoints: number }> => {
    if (isOpponentRound || (isPlayerRound && selectingTargetDrawnCard)) {
      const newOpponentBoard = boardCardsUtils.removeUpgradeCardStateAndApplyEffects(currentOpponentBoardCards);
      const {
        attackedCards,
        attackingCards,
        opponentLifePoints,
        playerLifePoints: updatedPlayerLifePoints
      } = await onAttackWithOpponentCard(
        attackingCardIndex,
        attackedCardIndex,
        currentPlayerBoardCards,
        newOpponentBoard,
        playerLifePoints
      );
      playerUpdateAttackable(false);
      opponentUpdateAttacking(false);

      return { attackedCards, attackingCards, opponentLifePoints, playerLifePoints: updatedPlayerLifePoints };
    }
    const newPlayerBoard = boardCardsUtils.removeUpgradeCardStateAndApplyEffects(currentPlayerBoardCards);
    const {
      attackedCards,
      attackingCards,
      opponentLifePoints,
      playerLifePoints: updatedPlayerLifePoints
    } = await onAttackWithPlayerCard(attackingCardIndex, attackedCardIndex, newPlayerBoard, currentOpponentBoardCards, playerLifePoints);
    opponentUpdateAttackable(false);
    playerUpdateAttacking(false);
    return { attackedCards, attackingCards, opponentLifePoints, playerLifePoints: updatedPlayerLifePoints };
  };

  /**
   * Attack player avatar with an opponent card
   * @param {number} attackingCardIndex attacking card index
   * @param {BoardCards} attackingCards attacking cards
   * @returns {Promise<BoardCards>} a promise with the attacking cards updated
   */
  const onAttackPlayerAvatarWithOpponentCard = async (attackingCardIndex: number, attackingCards: BoardCards): Promise<BoardCards> => {
    const copyAttackingCards = [...attackingCards];
    const attackingCard = copyAttackingCards[attackingCardIndex];
    attackingCard.isWaiting = true;
    attackingCard.isAttacking = false;
    const newAttackingCard = boardCardsUtils.removeUndetectableTag(attackingCard);

    opponentBoardCardHandler.replaceCb(cards => {
      return findAndUpdate(cards, attackingCard, newAttackingCard).map(card => ({ ...card, upgradedFirstTurn: false }));
    });
    setAttackingStatus({ from: itemRegistry.current[attackingCard.id], to: itemRegistry.current['avatar-player'] });

    launchReactions(reactionsManagementProps, [ReactionEventEnum.PLAYER_AVATAR_DAMAGED], attackingCard, undefined, extractCardFromDeck);

    activateAnimationLifePoints(-(attackingCard.damagePoints as number) || 0, PlayerRoundEnum.PLAYER);

    playerHandleSide.handleLifePoints.decrement((attackingCard.damagePoints as number) < 0 ? 0 : (attackingCard.damagePoints as number));

    await wait(1000);

    setAISelectingTarget(null);
    copyAttackingCards.splice(attackingCardIndex, 1, newAttackingCard);
    return copyAttackingCards;
  };

  /**
   * Attack opponent avatar with player card
   * @param {number} attackingCardIndex attacking card index
   * @param {BoardCards} attackingCards attacking cards
   * @returns Card in the hand of the actual turn
   */
  const onAttackOpponentAvatarWithPlayerCard = async (attackingCardIndex: number, attackingCards: BoardCards): Promise<BoardCards> => {
    if (!attackingCards.length) {
      attackingCards = [...playerBoardCards];
    }

    const copyAttackingCards = [...attackingCards];
    const attackingCard = copyAttackingCards[attackingCardIndex];
    const copyAttackingCard = { ...attackingCard };
    copyAttackingCard.isWaiting = true;
    copyAttackingCard.isAttacking = false;
    const newAttackingCard = boardCardsUtils.removeUndetectableTag(copyAttackingCard);

    playerBoardCardHandler.replaceCb(cards => {
      return findAndUpdate(cards, attackingCard, newAttackingCard).map(card => ({ ...card, upgradedFirstTurn: false }));
    });

    launchReactions(
      reactionsManagementProps,
      [ReactionEventEnum.OPPONENT_AVATAR_DAMAGED],
      copyAttackingCard,
      undefined,
      extractCardFromDeck
    );

    activateAnimationLifePoints(-(attackingCard.damagePoints as number) || 0, PlayerRoundEnum.OPPONENT);

    opponentHandleSide.handleLifePoints.decrement(
      (newAttackingCard.damagePoints as number) < 0 ? 0 : (newAttackingCard.damagePoints as number)
    );

    await wait(1000);

    copyAttackingCards.splice(attackingCardIndex, 1, newAttackingCard);

    return copyAttackingCards;
  };

  const checkAvatarsAreAlive = useCallback(
    async (player: PlayerTypeEnum) => {
      if (player === PlayerTypeEnum.PLAYER && playerLifePoints < 1) {
        return await handleWinner(PlayerTypeEnum.OPPONENT);
      }
      if (opponentLifePoints < 1) {
        return await handleWinner(PlayerTypeEnum.PLAYER);
      }
    },
    [playerLifePoints, opponentLifePoints]
  );

  const handleWinner = useCallback(
    async (player: PlayerTypeEnum) => {
      const handleShowModalTime = () => dispatch(showTimeModal());

      const isPlayer = player === PlayerTypeEnum.PLAYER;
      isPlayer ? await reactionsPlayerWin() : await reactionsOpponentWin();
      await unloadSound();
      setWinner(isPlayer ? PlayerTypeEnum.PLAYER : PlayerTypeEnum.OPPONENT);
      const result = await updateProgressOnFinished({ gameConfig: boardConfiguration, handleShowModalTime, isWinner: isPlayer, round });
      if (!result) return navigation.navigate('Campaign');
      const { gameConfig, level, progress } = result;
      return navigation.navigate('WinLoseScreen', {
        gameConfig,
        winner: isPlayer,
        level,
        previousProgress: progress
      });
    },
    [reactionsOpponentWin, reactionsPlayerWin, unloadSound]
  );

  const onAttackAvatarWithCard = async (attackingCardIndex: number, attackingCards: BoardCards = []) => {
    let cards: BoardCards = [...attackingCards];

    if (!attackingCards.length) {
      cards = isPlayerRound ? [...playerBoardCards] : [...opponentBoardCards];
    }

    const attackingCard = cards[attackingCardIndex];

    if (isOpponentRound) {
      // Show avatar effect only for IA
      if (opponentIsArtificialIntelligence) {
        setBattleCards([attackingCard, 'avatar']);
        await wait(2000);
        resetAttackCards();
      }

      const updatedBoardCards = await onAttackPlayerAvatarWithOpponentCard(attackingCardIndex, cards);
      await wait(500);
      clearActionsDrop();
      playerUpdateAttackable(false);
      opponentUpdateAttacking(false);
      opponentBoardCardHandler.replaceCb(boardCardsUtils.removeAttackableFlag);

      return updatedBoardCards;
    }

    const updatedBoardCards = await onAttackOpponentAvatarWithPlayerCard(attackingCardIndex, cards);
    await wait(500);
    clearActionsDrop();
    opponentUpdateAttackable(false);
    playerUpdateAttacking(false);

    playerBoardCardHandler.replaceCb(boardCardsUtils.removeAttackableFlag);

    return updatedBoardCards;
  };

  const clearActionsDrop = async () => {
    await wait(250);
    setShowAttackingCardDrag(false);
    await wait(1000);
  };

  const initialDiscardPlayer = useCallback(
    () => initialDiscardStep(playerDeck, playerInitialHandSize),
    [playerDeck, playerInitialHandSize]
  );

  const initialDiscardOpponent = useCallback(
    () => initialDiscardStep(opponentDeck, opponentInitialHandSize),
    [opponentDeck, opponentInitialHandSize]
  );

  const handleAutoDiscardSelection = useCallback(() => {
    const actionsPointsDiscard = isOpponentRound ? opponentActionPoints : playerActionPoints;
    isOpponentRound && !opponentIsArtificialIntelligence
      ? initialDiscardStep(opponentDeck, opponentInitialHandSize)
      : initialDiscardStep(playerDeck, playerInitialHandSize);
    onGetAutoDiscardSelection(actionsPointsDiscard);
  }, [isOpponentRound, opponentActionPoints, playerActionPoints, opponentIsArtificialIntelligence]);

  const discardPlayer = useCallback(async () => {
    if (!playerDiscard) return await nextTurn();
    handleShowDiscard(true);
    initialDiscardPlayer();
  }, [nextTurn, handleShowDiscard, initialDiscardPlayer, playerDiscard]);

  const discardOpponent = useCallback(async () => {
    if (!opponentDiscard) return await nextTurn();
    initialDiscardOpponent();
    if (opponentIsArtificialIntelligence) {
      handleAutoDiscardSelection();
      await onConfirmDiscard(nextTurn);
    } else {
      handleShowDiscard(true);
    }
  }, [nextTurn, handleShowDiscard, initialDiscardOpponent, opponentIsArtificialIntelligence, onConfirmDiscard, opponentDiscard]);

  /**
   * Extract initial deck cards and send to properly side hand
   * @returns {RoundData} The player and opponent necessary data
   */
  const extractInitialHands = async (): Promise<RoundData> => {
    if (opponentFirst) {
      const opponentData = await extractInitialHand(PlayerTypeEnum.OPPONENT);
      const playerData = await extractInitialHand(PlayerTypeEnum.PLAYER);
      return {
        player: playerData,
        opponent: opponentData
      };
    } else {
      const playerData = await extractInitialHand(PlayerTypeEnum.PLAYER);
      const opponentData = await extractInitialHand(PlayerTypeEnum.OPPONENT);
      return {
        player: playerData,
        opponent: opponentData
      };
    }
  };

  const initialDiscard = async () => {
    // No one will discard
    if (!playerDiscard && !opponentDiscard) {
      await wait(1000);
      setRound(0);
      return;
    }

    if (isPlayerRound) {
      await discardPlayer();
    } else {
      await discardOpponent();
    }
  };

  const onReplaceDiscardPlayer = useCallback(
    async () => await onReplaceDiscard(playerInitialHandSize, playerHandMethods.onConfirmDiscard, nextTurn),
    [playerInitialHandSize, playerHandMethods.onConfirmDiscard, nextTurn]
  );

  const onReplaceDiscardOpponent = useCallback(
    async () => await onReplaceDiscard(opponentInitialHandSize, opponentHandMethods.onConfirmDiscard, nextTurn),
    [opponentInitialHandSize, opponentHandMethods.onConfirmDiscard, nextTurn]
  );

  const replaceCardDiscard = useCallback(async () => {
    isOpponentRound ? await onReplaceDiscardOpponent() : await onReplaceDiscardPlayer();
  }, [isOpponentRound, onReplaceDiscardPlayer, onReplaceDiscardOpponent]);

  const onConfirmDiscardHandler = useCallback(async () => await onConfirmDiscard(nextTurn), [onConfirmDiscard]);

  /**
   * Extract cards from Deck from one side
   * @param {number} player current player
   * @returns {[Hand, Deck]} the updated hand and side with extracted cards
   */
  const extractInitialHand = async (player: PlayerTypeEnum): Promise<[Hand, Deck]> => {
    await reactionsRoundStart(player === PlayerTypeEnum.PLAYER ? PlayerRoundEnum.PLAYER : PlayerRoundEnum.OPPONENT);
    let initialHand: Hand = [];

    let initialDeck = player === PlayerTypeEnum.PLAYER ? playerDeck : opponentDeck;

    for (let i = 0; i < (player === PlayerTypeEnum.PLAYER && !opponentFirst ? playerInitialHandSize : opponentInitialHandSize); i++) {
      if (initialDeck.length > 0) {
        [initialHand, initialDeck] = await extractCardFromDeck(player, initialHand, initialDeck);
      }
    }

    return [initialHand, initialDeck];
  };

  const propsDragDrop = {
    clearActionsDrop,
    setDragCard,
    dragCard,
    onCloseDetailModal,
    setDragAttacking,
    dragAttacking,
    setShowAttackingCardDrag,
    showAttackingCardDrag
  };

  const handOfCards: HandOfCardsProperties = {
    ...propsDragDrop,
    playerHand,
    opponentHand,
    round,
    onCardPressed,
    onCloseDetailModal,
    dragAttacking,
    selectingTargetCard,
    cardAppliesToTarget: boardCardsUtils.cardAppliesToTarget,
    targetReached,
    getApplicableRules,
    selectingTargetDrawnCard,
    onDragMethods,
    isCurrentPlayerRound,
    selectingTargetDrawnCardSetter,
    isOpponentRound,
    isPlayerRound,
    opponentIsArtificialIntelligence,
    cardIsUpdating: cardMoved,
    allRules
  };

  const enemyName = actors.find(actor => actor.key === route.params.gameConfig?.opponent!.key)?.name;

  // TODO: Homogenize names
  let enemyAvatarName = route.params.gameConfig?.opponent!.key;
  if (route.params.gameConfig?.order === 18) {
    const player = useSelector(({ profile }: { profile: Profile }) => profile.player);
    enemyAvatarName = player?.gender === GendersEnum.FEMALE ? ActorEnum.AGENT_F_F_EVIL : ActorEnum.AGENT_F_M_EVIL;
  }

  const propsAvatarsContainer: AvatarsContainerProps = {
    ...propsDragDrop,
    playerLifePoints,
    isLaunchingCardFromHand,
    isPlayerAttackable: playerIsAttackable,
    isPlayerAttacking: playerIsAttacking,
    playerIsBeingAttacked,
    playerHandleIsBeingAttacked,
    opponentLifePoints,
    isOpponentAttackable: opponentIsAttackable,
    isOpponentAttacking: opponentIsAttacking,
    opponentIsBeingAttacked,
    opponentHandleIsBeingAttacked,
    onAttackAvatarWithCard,
    showAnimationLifePoints,
    enemyName: enemyName || ActorEnum.BOT_M4L0,
    enemyImage: avatarImage[enemyAvatarName || ActorEnum.BOT_M4L0],
    isPlayerRound,
    avatarAttacked: battleCards.some(item => item === 'avatar'),
    reactionsTweetsManagementProps,
    isFreeGame: isFreeGame!,
    handleAddItem,
    handleShowAvatarModal,
    playerHasBarrier,
    opponentHasBarrier
  };

  const configBoardCard: BoardCardsHookProps = {
    battleCards,
    clearActionsDrop,
    dragCard,
    isCurrentPlayerRound,
    isLaunchingCardFromHand,
    isPlayerRound,
    onAttackWithCard,
    onCardPressed,
    onCloseDetailModal,
    onSelectedToAttackWithCard,
    opponentBoardCards,
    opponentFirst,
    opponentIsArtificialIntelligence,
    playerBoardCards,
    selectingTargetCard,
    selectingTargetDrawnCard,
    setDragAttacking,
    setDragCard,
    setShowAttackingCardDrag,
    setTargetReached,
    showAttackingCardDrag,
    targetReached,
    cardIsUpdating: cardMoved,
    allRules,
    isOpponentRound,
    handleAttackableOpponent: opponentUpdateAttackable,
    handleAttackablePlayer: playerUpdateAttackable,
    playerLifePoints
  };

  const propsBoardCards: BoardCardsProps = {
    AISelectingTarget,
    AITarget,
    config: configBoardCard,
    opponentIsBeingAttacked,
    playerIsBeingAttacked,
    setBoardCards,
    handleAddItem,
    attackingStatus,
    setAttackingStatus
  };

  useEffect(() => {
    // DISCARD ROUNDS
    if (round < 0) {
      store.dispatch(resetGameReactions());
      store.dispatch(resetGameActions());
      store.dispatch(resetTweetReactions());

      initialDiscard();
      return;
    } else if (round === 0) {
      extractInitialHands().then(roundData => playRound(roundData));
    } else {
      playRound({ player: [playerHand, playerDeck], opponent: [opponentHand, opponentDeck] });
    }
    // GAME ROUNDS
  }, [round]);

  useEffect(() => {
    checkAvatarsAreAlive(PlayerTypeEnum.OPPONENT);
  }, [opponentLifePoints]);

  useEffect(() => {
    checkAvatarsAreAlive(PlayerTypeEnum.PLAYER);
  }, [playerLifePoints]);

  return {
    propsAvatarsContainer,
    propsBoardCards,
    detailCard,
    cardDetailModalVisible: showDetailCard,
    onCloseDetailModal,
    playerMaxActionPoints,
    playerActionPoints,
    opponentMaxActionPoints,
    opponentActionPoints,
    round,
    handOfCards,
    showNextRoundButton,
    nextTurn,
    setShowNextRoundButton,
    showDiscard,
    discardCards,
    msg,
    detail,
    clearMessageError,
    selectedToDiscardCards,
    setSelectedToDiscardCards: handleSelectedToDiscardCards,
    onConfirmDiscard: onConfirmDiscardHandler,
    playerTravellingCardPosition,
    playerCardIsTravelling: cardIsTravellingPlayer,
    opponentTravellingCardPosition,
    opponentCardIsTravelling: cardIsTravellingOpponent,
    discardModalText,
    showDiscardModalButton,
    showCardSupportModal,
    onGetAutoDiscardSelection: handleAutoDiscardSelection,
    hiddenCards,
    detailCardPosition,
    GameBoardContext,
    allRules,
    appliedRules,
    showBattleModal,
    battleCards,
    opponentIsArtificialIntelligence,
    selectingTargetDrawnCard,
    isOpponentRound,
    isPlayerRound,
    yourTurn,
    upgradeCards,
    highlightNextTurn: isOpponentRound && opponentIsArtificialIntelligence ? false : highlightNextTurn,
    unloadSound,
    setAttackingStatus,
    handleSelection,
    activeSupportCardRef,
    handleCloseAvatarModal,
    showAvatarModal,
    replaceCardDiscard,
    leftoverModal
  };
};

export default useGameBoard;
