/**
 * Enhance variable type declaration.
 *
 * @interface
 */

import { RuleTypeEnum } from './rule.dto';

export class Enhance {
  id!: string;
  ruleName!: string;
  ruleType!: RuleTypeEnum;
  ruleText!: string;
  ruleDamagePoints?: number | null;
  ruleDefensePoints?: number | null;
  appliedEnhance: boolean;

  constructor(properties?: EnhanceProperties) {
    this.appliedEnhance = false;
    if (properties) {
      this.id = properties.id;
      this.ruleName = properties.ruleName;
      this.ruleType = properties.ruleType;
      this.ruleText = properties.ruleText;
      this.ruleDamagePoints = properties.ruleDamagePoints;
      this.ruleDefensePoints = properties.ruleDefensePoints;
    }
  }
}

export enum EnhanceEffect {
  REPLACE_FAKE_TEXT = 'replace-fake-effect',
  APPEND_FAKE_TEXT_RANDOM = 'append-fake-text-random',
  REPLACE_USER_FAKE = 'replace-user-fake',
  INCREASE_RETWEETS = 'increase-retweets',
  INCREASE_LIKES = 'increase-likes',
  INCREASE_COMMENTS = 'increase-comments',
  COINS = 'coins',
  HEARTS = 'hearts'
}

export type TweetsImages =
  | '_CSIC'
  | '_EFE_NOTICIAS'
  | 'FACÜA'
  | 'F_B_I'
  | 'FERNANDOSIMÓN_'
  | 'IGUALDADGOV'
  | 'LGTBIQ+GOB'
  | 'N_A_S_A'
  | 'NATTURE'
  | '0NU_ES'
  | 'POLICIA__'
  | 'RTVË'
  | 'CIENCIARTVE_'
  | 'SECTANOTICIAS';

export interface CardEnhanceEffect {
  id?: string;
  effect: EnhanceEffect;
  condition?: string;
  text?: string;
  min?: number;
  max?: number;
  enhancedText: string[];
}

export interface EnhanceProperties {
  id: string;
  ruleName: string;
  ruleType: RuleTypeEnum;
  ruleText: string;
  ruleDamagePoints?: number | null;
  ruleDefensePoints?: number | null;
  enhanceEffect?: CardEnhanceEffect[] | null;
  appliedEnhance: boolean;
}

export interface AppliedEnhanceRule {
  [key: string]: EnhanceProperties;
}
