import { useState } from 'react';

export const useLogin = ({ update }: { update: boolean }) => {
  const [error, setError] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [step, setStep] = useState<number>(update ? 2 : 1);

  const nextStep = (): void => setStep(prevStep => prevStep + 1);

  const backStep = (): void => setStep(prevStep => prevStep - 1);

  const handleStep = (step: number): void => setStep(step);

  const handleError = (error: string): void => setError(error);

  const handleMessage = (message: string): void => setMessage(message);

  return {
    step,
    error,
    message,
    nextStep,
    backStep,
    handleError,
    handleMessage,
    handleStep
  };
};
