import { useRef } from 'react';
import type { Card } from '../commons/dtos/card.dto';
import type { Rule } from '../commons/dtos/rule.dto';
import boardCardsUtils from '../commons/utils/gameboard/boardCards';
import type { BoardCards } from '../screens/GameBoard/gameTypes';
import { useGameboardModal } from './useGameboardModal';

export function useCardDrop({ doDragDrop }: { doDragDrop: (card: Card) => void }) {
  const { handleCloseModal, handleShowModal, showGameboardModal } = useGameboardModal();
  const activeSupportCardRef = useRef<Card | null>(null);
  const cardsAllowedDropRef = useRef<string[]>([]);
  const acceptedCardDropRef = useRef<boolean>(false);

  const handleShowSupportCardModal = ({
    allRules,
    card,
    isPlayerRound,
    opponentBoardCards,
    playerBoardCards
  }: {
    card: Card;
    playerBoardCards: BoardCards;
    opponentBoardCards: BoardCards;
    allRules: Rule[];
    isPlayerRound: boolean;
  }): boolean => {
    const { type } = card;
    if (!boardCardsUtils.isCardSupportOrCardAction(type?.typeName)) {
      handleCloseModal();
      return false;
    }
    const hasTarget = boardCardsUtils.checkPlayingTargetRequiredCard(
      card,
      playerBoardCards,
      opponentBoardCards,
      allRules,
      isPlayerRound,
      !isPlayerRound
    );
    if (hasTarget) {
      handleCloseModal();
      return false;
    }
    const canBeDropped = cardsAllowedDropRef.current.some(cardAllowedDrop => cardAllowedDrop === card.id);
    if (!canBeDropped) {
      activeSupportCardRef.current = card;
      handleShowModal();
      return true;
    }
    handleCloseModal();
    return false;
  };

  const handleSelection = (accept: boolean): void => {
    if (!activeSupportCardRef.current) return;

    const { id: cardId } = activeSupportCardRef.current;
    const alreadyExists = cardsAllowedDropRef.current.some(cardAllowedDrop => cardAllowedDrop === cardId);
    if (accept && !alreadyExists) {
      acceptedCardDropRef.current = true;
      cardsAllowedDropRef.current = [...cardsAllowedDropRef.current, cardId];
      doDragDrop(activeSupportCardRef.current);
    } else {
      acceptedCardDropRef.current = false;
    }
    handleCloseModal();
  };

  return {
    showGameboardModal,
    handleSelection,
    handleShowSupportCardModal,
    activeSupportCardRef
  };
}
