import { View, Image, ImageBackground, Text, ImageSourcePropType, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { Deck } from '../../../commons/dtos/deck.dto';
import { isNativeMobile, isNativeOrPWA } from '../../../commons/utils';
import { rewardDeck } from '../../../constants/Images';
import { useDeckSelectorCardStyle } from './useDeckSelectorCardStyle';

interface DeckItemProps {
  deck: Deck;
  backgroundTitle: ImageSourcePropType;
  selected: boolean;
  handleChange: (deck: Deck) => void;
}

const DeckItem = ({ backgroundTitle, deck, selected, handleChange }: DeckItemProps) => {
  const { deckName } = deck;
  const { deckItemCardStyle } = useDeckSelectorCardStyle();

  const deckItemStyles = StyleSheet.create({
    deckContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '25%'
    },
    deckText: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? 14 : 18,
      fontWeight: '800',
      paddingVertical: isNativeOrPWA ? 8 : 21,
      paddingHorizontal: isNativeOrPWA ? 10 : 18,
      width: '100%',
      textAlign: 'center'
    },
    deckTitleBg: {
      width: '100%',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: isNativeOrPWA ? 8 : 18,
      minHeight: isNativeOrPWA ? 21 : 42
    },
    selectedDeck: {
      opacity: 1,
      filter: selected ? 'drop-shadow(0px 4px 14px #17FFE3)' : 'drop-shadow(-13px 13px 4px #242424)'
    }
  });

  return (
    <View style={deckItemStyles.deckContainer}>
      <TouchableWithoutFeedback onPress={() => handleChange(deck)}>
        <Image
          source={rewardDeck}
          style={[
            deckItemCardStyle.image,
            deckItemStyles.selectedDeck,
            selected && {
              opacity: 1,
              shadowColor: '#07efbb',
              shadowOffset: {
                width: 0,
                height: 9
              },
              shadowOpacity: isNativeMobile ? 1 : 0,
              shadowRadius: 6
            }
          ]}
          resizeMode="contain"
        />
      </TouchableWithoutFeedback>
      <ImageBackground style={deckItemStyles.deckTitleBg} source={backgroundTitle} resizeMode="stretch">
        <Text numberOfLines={1} style={deckItemStyles.deckText}>
          {deckName}
        </Text>
      </ImageBackground>
    </View>
  );
};

export default DeckItem;
