/* eslint-disable react-native/no-unused-styles */
import { StyleSheet, Image, TouchableOpacity, TouchableWithoutFeedback, StatusBar } from 'react-native';
import useUnits from 'rxn-units';
import { previousButtonActive, previousButtonInactive, nextButtonActive, nextButtonInactive } from '../../constants/Images';
import { useCardModal } from '../../hooks/useCardModal';
import { CardQuantity } from '../dtos/card-quantity.dto';
import { cardsAlphabeticalOrder, isNativeMobile, isNativeOrPWA } from '../utils';
import CardModal from './Card/CardModal';
import SelectedCard from './Card/CardTypes/SelectedCard';
import { CardSizesEnum } from './Card/utils';
import { View, Text } from './Themed';

StatusBar.setHidden(true);
const CARDS_PER_PAGE = isNativeMobile ? 4 : 8;
interface CardsViewerProps {
  cardsViewerPage: number;
  setCardsViewerPage: Function;
  onAddCardToDeck?: Function;
  cardsInventory: CardQuantity[];
  missedCards?: CardQuantity[];
}
const CardsViewer = ({ cardsInventory, cardsViewerPage, setCardsViewerPage, missedCards, onAddCardToDeck }: CardsViewerProps) => {
  const { vw } = useUnits();
  const { detailCard, detailCardVisible, handleSelectCardModal, handleVisibilityDetailCard } = useCardModal();

  const maxPage = Math.ceil((cardsInventory.length + (missedCards ? missedCards.length : 0)) / CARDS_PER_PAGE) - 1;

  const allCards = (missedCards ? [...cardsInventory, ...missedCards] : cardsInventory).sort(cardsAlphabeticalOrder);

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: isNativeOrPWA ? 'center' : 'flex-start',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
      paddingLeft: '3%'
    },
    cardSuperContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    cardContainer: { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    card: {
      marginVertical: '2%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: vw(1.2)
    },
    quantityContainer: {
      transform: [{ rotate: '45deg' }],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: vw(1.8),
      height: vw(1.8),
      borderWidth: vw(0.15),
      borderRadius: vw(0.5),
      bottom: -vw(0.3),
      right: -vw(0.3)
    },
    quantity: { zIndex: 200, transform: [{ rotate: '-45deg' }], fontWeight: '700', fontSize: vw(1) },
    cardsPageButtonContainer: { position: 'absolute', height: '100%', width: '6%' },
    cardsPageButton: {
      width: '100%',
      top: '50%',
      zIndex: 500,
      height: vw(3),
      transform: [{ translateY: vw(-1.5) }, { translateX: vw(1.5) }]
    },
    outOfStock: {
      color: '#FFFFFF',
      position: 'absolute',
      zIndex: 800,
      fontWeight: '900',
      textAlign: 'center',
      textAlignVertical: 'center',
      fontSize: vw(0.8),
      paddingHorizontal: vw(0.5),
      paddingVertical: vw(0.1),
      borderRadius: vw(0.2)
    },
    noCardsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    },
    noCardsTitle: {
      fontSize: isNativeOrPWA ? 20 : 34,
      textAlign: 'center',
      maxWidth: isNativeOrPWA ? 420 : 580,
      fontWeight: 'bold',
      lineHeight: isNativeOrPWA ? 32 : 60
    }
  });

  const cardStylesBlack = StyleSheet.create({
    outOfStock: {
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    quantityContainer: {
      borderColor: '#000',
      backgroundColor: '#fff'
    },
    quantity: { color: '#000' }
  });

  const cardStylesWhite = StyleSheet.create({
    outOfStock: {
      backgroundColor: 'rgba(154,65,34, 0.5)'
    },
    quantityContainer: {
      borderColor: '#FFFFFF',
      backgroundColor: '#EDEDED'
    },
    quantity: { color: '#000' }
  });

  const selectColor = (color: number) => (color ? cardStylesBlack : cardStylesWhite);

  if (!allCards.length) {
    return (
      <View style={styles.noCardsContainer}>
        <Text style={styles.noCardsTitle}>No hay cartas que coincidan con la búsqueda</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <CardModal card={detailCard} showTagsInfo={false} detailCardVisible={detailCardVisible} hide={handleVisibilityDetailCard.hide} />
      <TouchableOpacity
        onPress={() => cardsViewerPage > 0 && setCardsViewerPage(cardsViewerPage - 1)}
        style={[styles.cardsPageButtonContainer, { left: isNativeMobile ? '-6%' : '-7%' }]}
      >
        <Image
          resizeMode="contain"
          source={cardsViewerPage > 0 ? previousButtonActive : previousButtonInactive}
          style={styles.cardsPageButton}
        />
      </TouchableOpacity>
      {allCards.slice(CARDS_PER_PAGE * cardsViewerPage, CARDS_PER_PAGE * (cardsViewerPage + 1)).map(({ card, quantity }, index) => {
        const { outOfStock: outOfStockStyle, quantity: quantityStyle, quantityContainer: quantityContainerStyle } = selectColor(card.color);
        return (
          <TouchableWithoutFeedback
            key={index}
            onPress={() => quantity && (onAddCardToDeck ? onAddCardToDeck(card) : quantity > 0 && handleSelectCardModal(card))}
            onLongPress={() => quantity > 0 && handleSelectCardModal(card)}
          >
            <View
              style={[styles.card, !isNativeMobile ? { height: vw(11.5 * 1.4), width: vw(11.5) } : { height: vw(13 * 1.4), width: vw(13) }]}
            >
              <View style={[styles.cardSuperContainer, quantity === -1 ? { opacity: 0.75 } : {}]}>
                {quantity < 1 && <Text style={[styles.outOfStock, outOfStockStyle]}>{quantity === -1 ? 'NO DISPONIBLE' : 'AGOTADO'}</Text>}
                <View style={[styles.cardContainer, { opacity: quantity > 0 ? 1 : 0.6 }]}>
                  {card && <SelectedCard card={card} cardSize={CardSizesEnum.MEDIUM} />}
                </View>
                {quantity >= 0 && (
                  <View style={[styles.quantityContainer, quantityContainerStyle]}>
                    <Text style={[styles.quantity, quantityStyle]}>{quantity}</Text>
                  </View>
                )}
              </View>
            </View>
          </TouchableWithoutFeedback>
        );
      })}
      <TouchableOpacity
        onPress={() => cardsViewerPage < maxPage && setCardsViewerPage(cardsViewerPage + 1)}
        style={[styles.cardsPageButtonContainer, { right: isNativeMobile ? '-4%' : '-6%' }]}
      >
        <Image
          resizeMode="contain"
          source={cardsViewerPage < maxPage ? nextButtonActive : nextButtonInactive}
          style={styles.cardsPageButton}
        />
      </TouchableOpacity>
    </View>
  );
};

export default CardsViewer;
