import { useEffect, useRef, useState } from 'react';
import { Animated, GestureResponderEvent, TouchableWithoutFeedback, View, ViewStyle, Platform, Easing } from 'react-native';
import { DraxDragWithReceiverEndEventData, DraxView } from 'react-native-drax';
import { PanGestureHandler } from 'react-native-gesture-handler';
import BoardCard from '../../../commons/components/Card/CardTypes/BoardCard';
import { CardSizesEnum } from '../../../commons/components/Card/utils';
import { PlayerTypeEnum, PlayersSuffixEnum } from '../../../commons/constants/player';
import { Card as CardType } from '../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { AttackingStatus, ItemInfo } from '../../../types/drag';
import AttackedItem from './AttackedItem';
import { useBoardCardItemStyles } from './useBoardCardItemStyles';

interface BoardCardItemProps {
  card: CardType;
  AITarget: CardType | null;
  stylesConditions: ViewStyle[];
  onLongPressHandler: (e: GestureResponderEvent, card: CardType) => void;
  onPressOutHandler: () => void;
  condition: boolean;
  condition2: boolean;
  index: number;
  attackedCard: CardType | null;
  getAttackedPointsResult: (attackedCard: CardType) => number;
  getAttackingPointsResult: (attackingCard: CardType) => number;
  isCurrentPlayerRound: (player: PlayerTypeEnum) => boolean;
  onReceiveMethods: {
    onReceiveDragEnter: (card: CardType) => void;
    onReceiveDragExit: (event: DraxDragWithReceiverEndEventData) => void;
    onReceiveDragDrop: (dropCurrentID: string, index: number) => void;
  };
  playerSuffix: PlayersSuffixEnum;
  showBattlePoints: boolean;
  isOpponentBeingAttacked: boolean;
  isPlayerBeingAttacked: boolean;
  opponentIsArtificialIntelligence: boolean | undefined;
  dragCard: string;
  onDrag: {
    onDragEnter: () => void;
    onDragExit: (card: CardType) => void;
    onDragStart: (card: CardType, index: number) => void;
    onDragEnd: (card: CardType) => void;
    onDragDrop: (card: CardType) => void;
  };
  battleCards: Array<string | number | undefined>;
  getAttackedDefensePoints: (card: CardType) => number;
  getAttackingDefensePoints: (card: CardType) => number;
  side: PlayerRoundEnum;
  setBoardCards: (card: CardType, side: 'player' | 'opponent') => void;
  handleAddItem: (id: string, info: ItemInfo) => void;
  attackingStatus: AttackingStatus;
  setAttacking: Function;
}

interface ReceivingZoneUIComponentProps {
  card: CardType;
  AITarget: CardType | null;
  stylesConditions: ViewStyle[];
  index: number;
  attackedCard: CardType | null;
  getAttackedPointsResult: (attackedCard: CardType) => number;
  getAttackingPointsResult: (attackingCard: CardType) => number;
  isCurrentPlayerRound: (player: PlayerTypeEnum) => boolean;
  onReceiveMethods: {
    onReceiveDragEnter: (card: CardType) => void;
    onReceiveDragExit: (event: DraxDragWithReceiverEndEventData) => void;
    onReceiveDragDrop: (dropCurrentID: string, index: number) => void;
  };
  playerSuffix: PlayersSuffixEnum;
  showBattlePoints: boolean;
  opponentIsArtificialIntelligence: boolean | undefined;
  battleCards: Array<string | number | undefined>;
  getAttackedDefensePoints: (card: CardType) => number;
  side: PlayerRoundEnum;
  styles: any;
  setBoardCards: (card: CardType, side: 'player' | 'opponent') => void;
}

const ReceivingZoneUIComponent = ({
  styles,
  side,
  card,
  AITarget,
  stylesConditions,
  index,
  attackedCard,
  getAttackedPointsResult,
  isCurrentPlayerRound,
  onReceiveMethods,
  playerSuffix,
  showBattlePoints,
  opponentIsArtificialIntelligence,
  battleCards,
  getAttackedDefensePoints,
  setBoardCards
}: ReceivingZoneUIComponentProps) => {
  const { onReceiveDragDrop, onReceiveDragEnter, onReceiveDragExit } = onReceiveMethods;
  const dropCurrentID = `drop_current_${card.id}_${index}_${PlayerRoundEnum.PLAYER}_${playerSuffix}`;
  const damage = showBattlePoints && attackedCard?.id === card?.id ? getAttackedDefensePoints(card) : 0;

  const conditionEffect = side === PlayerRoundEnum.PLAYER && opponentIsArtificialIntelligence && battleCards.includes(card.idUnique!);

  return (
    <Animated.View
      nativeID={dropCurrentID}
      style={[
        stylesConditions,
        conditionEffect && {
          transform: [
            {
              scale: isNativeOrPWA ? 1.05 : 1.2
            }
          ],
          elevation: 100,
          zIndex: 100
        }
      ]}
    >
      <DraxView
        nativeID={dropCurrentID}
        style={stylesConditions}
        key={index}
        onReceiveDragEnter={() => onReceiveDragEnter(card)}
        onReceiveDragDrop={() => onReceiveDragDrop(dropCurrentID, index)}
        onReceiveDragExit={onReceiveDragExit}
      >
        {card && (
          <BoardCard
            AITarget={AITarget}
            card={card}
            cardSize={CardSizesEnum.SMALL}
            setBoardCards={setBoardCards}
            shadow={
              conditionEffect
                ? {
                    filter: 'drop-shadow(rgb(255, 0, 0) 0px 1px 5px) drop-shadow(rgb(255, 0, 0) 1px 1px 8px)'
                  }
                : {}
            }
            hover={showBattlePoints && attackedCard?.id === card?.id}
            damage={damage}
            attackedCard={attackedCard}
          />
        )}
        {showBattlePoints && attackedCard?.id === card?.id && attackedCard && (
          <AttackedItem
            condition={getAttackedPointsResult(card) <= 0}
            parentStyle={[
              styles.battlePointsContainer,
              isCurrentPlayerRound(PlayerTypeEnum.OPPONENT)
                ? styles.battlePointsContainerPlayerAttacked
                : styles.battlePointsContainerOpponentAttacked
            ]}
            textContent={getAttackedPointsResult(card)}
          />
        )}
      </DraxView>
    </Animated.View>
  );
};

const BoardCardItem = ({
  side,
  card,
  AITarget,
  stylesConditions,
  onLongPressHandler,
  onPressOutHandler,
  condition,
  condition2,
  index,
  attackedCard,
  getAttackedPointsResult,
  isCurrentPlayerRound,
  onReceiveMethods,
  playerSuffix,
  showBattlePoints,
  isOpponentBeingAttacked,
  isPlayerBeingAttacked,
  opponentIsArtificialIntelligence,
  dragCard,
  onDrag,
  getAttackingPointsResult,
  battleCards,
  getAttackedDefensePoints,
  getAttackingDefensePoints,
  setBoardCards,
  handleAddItem,
  attackingStatus,
  setAttacking
}: BoardCardItemProps) => {
  const { styles } = useBoardCardItemStyles();
  const moveRef = useRef<Animated.Value>(new Animated.Value(0)).current;
  const ref = useRef(null);
  const isAttacking = attackingStatus.from?.id === card.id;
  const [cardSize, setcardSize] = useState<{ height: null | number; width: null | number }>({ height: null, width: null });

  const moveEffect: Animated.TimingAnimationConfig = {
    toValue: 1,
    useNativeDriver: Platform.OS !== 'web',
    easing: Easing.elastic(1),
    duration: 200
  };
  const moveEffectReset: Animated.TimingAnimationConfig = {
    toValue: 0,
    useNativeDriver: Platform.OS !== 'web',
    easing: Easing.elastic(1),
    duration: 200,
    delay: 400
  };

  const animation = Animated.timing(moveRef, moveEffect);
  const reset = Animated.timing(moveRef, moveEffectReset);

  const sequence = Animated.sequence([animation, reset]);

  useEffect(() => {
    isAttacking && sequence.start(() => setAttacking({ from: null, to: null }));
  }, [isAttacking]);

  const dragUIComponent = (styles: any) => {
    const { onDragDrop, onDragEnd, onDragEnter, onDragExit, onDragStart } = onDrag;
    const conditionEffect = side === PlayerRoundEnum.OPPONENT && opponentIsArtificialIntelligence && battleCards.includes(card.idUnique!);

    const renderHoverContent = () => {
      if (card.isWaiting || (opponentIsArtificialIntelligence && isCurrentPlayerRound(PlayerTypeEnum.OPPONENT))) return;
      const attackingPointsResult = getAttackingPointsResult(card);
      const points = getAttackingDefensePoints(card);

      return (
        <View style={[...stylesConditions, styles.attackingCardShadow]}>
          {card && (
            <BoardCard
              card={card}
              cardSize={CardSizesEnum.SMALL}
              AITarget={AITarget}
              setBoardCards={setBoardCards}
              damage={points}
              hover={showBattlePoints && !isPlayerBeingAttacked && !isOpponentBeingAttacked}
              attackedCard={attackedCard}
            />
          )}
          {showBattlePoints && !isPlayerBeingAttacked && !isOpponentBeingAttacked && attackedCard && (
            <AttackedItem
              parentStyle={[styles.battlePointsContainer]}
              condition={attackingPointsResult <= 0}
              textContent={attackingPointsResult}
            />
          )}
        </View>
      );
    };

    return (
      <Animated.View nativeID={dragCard} style={stylesConditions}>
        <DraxView
          style={stylesConditions}
          hoverStyle={styles.hoverDraggingStyle}
          renderHoverContent={renderHoverContent}
          dragPayload={card}
          key={index}
          onTouchMove={onPressOutHandler}
          onDragEnter={onDragEnter}
          onDragExit={() => onDragExit(card)}
          onDragStart={() => onDragStart(card, index)}
          onDragEnd={() => onDragEnd(card)}
          onDragDrop={() => onDragDrop(card)}
        >
          <Animated.View
            nativeID={`drag_current_${card.id}_${index}_${PlayerRoundEnum.PLAYER}_${playerSuffix}`}
            style={[
              styles.cardDrag,
              conditionEffect && {
                transform: [
                  {
                    scale: isNativeOrPWA ? 1.05 : 1.2
                  }
                ],
                elevation: 100,
                zIndex: 100
              }
            ]}
          >
            {card && (
              <BoardCard
                card={card}
                cardSize={CardSizesEnum.SMALL}
                AITarget={AITarget}
                setBoardCards={setBoardCards}
                shadow={
                  conditionEffect
                    ? {
                        filter: 'drop-shadow(rgb(255, 255, 255) 0px 1px 5px) drop-shadow(rgb(255,255, 255) 1px 1px 8px)'
                      }
                    : {}
                }
                hover={false}
                attackedCard={attackedCard}
              />
            )}
          </Animated.View>
        </DraxView>
      </Animated.View>
    );
  };

  if (condition) {
    return (
      <PanGestureHandler maxPointers={1}>
        <ReceivingZoneUIComponent
          styles={styles}
          side={side}
          card={card}
          AITarget={AITarget}
          stylesConditions={stylesConditions}
          index={index}
          attackedCard={attackedCard}
          getAttackedPointsResult={getAttackedPointsResult}
          isCurrentPlayerRound={isCurrentPlayerRound}
          onReceiveMethods={onReceiveMethods}
          playerSuffix={playerSuffix}
          showBattlePoints={showBattlePoints}
          opponentIsArtificialIntelligence={opponentIsArtificialIntelligence}
          battleCards={battleCards}
          getAttackedDefensePoints={getAttackedDefensePoints}
          setBoardCards={setBoardCards}
          getAttackingPointsResult={getAttackingPointsResult}
        />
      </PanGestureHandler>
    );
  } else if (condition2) {
    return <PanGestureHandler maxPointers={1}>{dragUIComponent(styles)}</PanGestureHandler>;
  } else {
    const conditionEffect = opponentIsArtificialIntelligence && battleCards.includes(card.idUnique!);

    const handleLayout = () => {
      ref &&
        //  @ts-ignore
        ref?.current?.measure((fx: string, fy: string, w: string, h: string, px: string, py: string) => {
          setcardSize({ height: +h, width: +w });
          handleAddItem(card.id, { height: +h ?? 0, width: +w ?? 0, x: +px, y: +py, id: card.id });
        });
    };

    return (
      <PanGestureHandler maxPointers={1}>
        <TouchableWithoutFeedback onLongPress={e => onLongPressHandler(e, card)} onPressOut={onPressOutHandler}>
          <Animated.View
            ref={ref}
            onLayout={handleLayout}
            style={[
              stylesConditions,
              {
                transform: [
                  {
                    translateX: moveRef.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, -(attackingStatus?.from?.x ?? 0 - (attackingStatus.to?.x ?? 0)) + (cardSize?.width ?? 0)]
                    })
                  },
                  {
                    translateY: moveRef.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, (attackingStatus.from?.y ?? 0 - (attackingStatus.to?.y ?? 0)) + (cardSize?.height ?? 0)]
                    })
                  }
                ]
              },
              conditionEffect && {
                transform: [
                  {
                    scale: isNativeOrPWA ? 1.05 : 1.2
                  }
                ],
                elevation: 100,
                zIndex: 100
              }
            ]}
          >
            {card && (
              <BoardCard
                card={card}
                cardSize={CardSizesEnum.SMALL}
                AITarget={AITarget}
                setBoardCards={setBoardCards}
                shadow={
                  conditionEffect
                    ? {
                        filter: isCurrentPlayerRound(PlayerTypeEnum.PLAYER)
                          ? 'drop-shadow(rgb(255, 255, 255) 0px 1px 5px) drop-shadow(rgb(255, 255, 255) 1px 1px 8px)'
                          : 'drop-shadow(rgb(255, 0, 0) 0px 1px 5px) drop-shadow(rgb(255, 0, 0) 1px 1px 8px)'
                      }
                    : {}
                }
                hover={false}
                attackedCard={attackedCard}
              />
            )}
          </Animated.View>
        </TouchableWithoutFeedback>
      </PanGestureHandler>
    );
  }
};

export default BoardCardItem;
