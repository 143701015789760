import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA, isNativeMobile, isPWA } from '../../utils';

export const useStylesHow = () => {
  const { vh, vw } = useUnits();
  const styles = StyleSheet.create({
    section: {
      position: 'relative',
      paddingVertical: vh(5),
      paddingHorizontal: 30,
      minHeight: vh(100),
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden'
    },
    splitContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1280,
      margin: 'auto'
    },
    splitView: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '50%',
      alignItems: 'center',
      position: 'relative'
    },
    splitDesktopContainer: {
      flexDirection: isNativeOrPWA ? 'column' : 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: isNativeOrPWA ? 480 : 1280,
      margin: 'auto'
    },
    splitDesktopView: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: !isNativeMobile ? (isPWA ? 'auto' : '50%') : '100%'
    },
    blockCenter: {
      margin: 'auto',
      paddingHorizontal: isNativeOrPWA ? 80 : 100
    },
    block: {
      maxWidth: isNativeOrPWA ? 508 : 670,
      minWidth: 380,
      paddingHorizontal: isNativeOrPWA ? 55 : 100,
      paddingVertical: isNativeOrPWA ? 40 : 80,
      width: !isNativeMobile ? '100%' : 'auto',
      position: 'relative'
    },
    block2: {
      maxWidth: isNativeOrPWA ? 400 : 560,
      minWidth: 380,
      paddingHorizontal: isNativeOrPWA ? 35 : 80,
      paddingVertical: isNativeOrPWA ? 15 : 20,
      width: !isNativeMobile ? '100%' : 'auto',
      position: 'relative',
      zIndex: 100
    },
    card: {
      minHeight: 200,
      minWidth: 500,
      height: isNativeOrPWA ? vh(70) : vh(65),
      maxHeight: 360,
      width: '100%'
    },
    video: {
      height: 328,
      width: 756,
      maxWidth: vw(92)
    },
    elevation: {
      position: 'relative',
      zIndex: 100,
      elevation: 100
    },
    extraTitle: {
      fontSize: isNativeOrPWA ? adjust(46) : 60,
      fontFamily: 'Inlanders',
      color: '#ffffff',
      textShadowColor: '#000000',
      textShadowOffset: { width: isNativeOrPWA ? -2 : -3, height: isNativeOrPWA ? 3 : 6 },
      textShadowRadius: 0,
      marginVertical: isNativeOrPWA ? adjust(16) : 24
    },
    introParagraph: {
      marginVertical: isNativeOrPWA ? adjust(16) : 18
    },
    introText: {
      color: 'white',
      fontSize: isNativeOrPWA ? adjust(16) : 18,
      lineHeight: isNativeOrPWA ? adjust(22) : 26
    },
    title: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(32) : adjust(44),
      fontFamily: 'Inlanders',
      color: '#ffffff',
      textShadowColor: '#000000',
      textShadowOffset: { width: isNativeOrPWA ? -2 : -3, height: isNativeOrPWA ? 3 : 6 },
      textShadowRadius: 0,
      marginVertical: isNativeOrPWA ? adjust(12) : 24
    },
    bold: {
      fontWeight: '800'
    },
    italic: {
      fontStyle: 'italic'
    },
    link: {
      color: '#74FFE1',
      fontWeight: 'bold',
      textDecorationLine: 'underline'
    },
    linearGradientStyle: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 100
    },
    action: {
      height: isNativeOrPWA ? adjust(82) : 104,
      width: isNativeOrPWA ? adjust(82) : 104,
      position: 'absolute',
      right: isNativeOrPWA ? -adjust(22) : -34,
      top: isNativeOrPWA ? -adjust(42) : -54
    },
    action2: {
      width: isNativeOrPWA ? adjust(102) : 164,
      height: isNativeOrPWA ? adjust(132) : 204,
      position: 'absolute',
      right: '-10%',
      top: '-20%'
    },
    text: {
      fontSize: isNativeOrPWA ? adjust(15) : 16,
      lineHeight: isNativeOrPWA ? adjust(20) : 22,
      fontWeight: '300',
      color: 'white'
    },
    paragraph: {
      marginVertical: isNativeOrPWA ? adjust(16) : 18
    },
    footerContainer: {
      alignItems: 'center'
    },
    statIcon: {
      resizeMode: 'contain',
      width: 24,
      height: 18,
      marginRight: 8
    },
    scrollTop: {
      position: 'absolute',
      padding: 10,
      bottom: vh(4),
      right: isNativeOrPWA ? '4%' : '8%'
    },
    icon: {
      height: isNativeOrPWA ? adjust(18) : 24,
      width: isNativeOrPWA ? adjust(18) : 24
    },
    buttonContainer: {
      marginHorizontal: vw(0.5)
    },
    button: {
      padding: vw(1),
      paddingBottom: vw(1.3),
      cursor: 'pointer'
    }
  });
  const htmlStyles = useMemo(
    () => ({
      anchor: styles.link,
      bold: styles.bold,
      italic: styles.italic,
      orderedList: {},
      unorderedList: {}
    }),
    [styles.link, styles.bold, styles.italic]
  );

  const generalStyle = useMemo(() => styles.text, [styles.text]);

  const introStyle = useMemo(() => styles.introText, [styles.introText]);

  return { styles, htmlStyles, generalStyle, introStyle };
};
