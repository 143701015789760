import { useFocusEffect } from '@react-navigation/native';
import * as DocumentPicker from 'expo-document-picker';
import { useCallback, useEffect, useState } from 'react';
import { StyleSheet, ImageBackground, TouchableWithoutFeedback, Image, TextInput, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import { boardConfiguration } from '../../assets/data/test-board-configuration';
import ErrorModal from '../../commons/components/ErrorModal';
import SnackBar from '../../commons/components/SnackBar';
import { View, Text } from '../../commons/components/Themed';
import TimeModal from '../../commons/components/TimeModal/TimeModal';
import { PlayerTypeEnum } from '../../commons/constants/player';
import { CardQuantity } from '../../commons/dtos/card-quantity.dto';
import { Card } from '../../commons/dtos/card.dto';
import { EventLogs } from '../../commons/dtos/create-log.dto';
import { GameBoardConfiguration } from '../../commons/dtos/game-board-configuration.dto';
import { PlayerRoundEnum } from '../../commons/dtos/player.dto';
import { ReactionConfiguration } from '../../commons/dtos/reaction-configuration.dto';
import { Rule } from '../../commons/dtos/rule.dto';
import { Scene } from '../../commons/dtos/scene.dto';
import { ApiError } from '../../commons/errors/api-error';
import { cardsCodeOrder, downloadFile, getDeckCards, processDuplicatedCards } from '../../commons/utils';
import { backgroundDeckEditor, checkboxIcon, exportIcon, importIcon, selectedCheckboxIcon } from '../../constants/Images';
import { MIN_DECK_SIZE } from '../../constants/Values';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { hideTimeModal, showTimeModal } from '../../redux/Time-advice/timeAdvice';
import CardsService from '../../services/cards.service';
import LogsService from '../../services/logs.service';
import RulesService from '../../services/rules.service';
import TimePlayService from '../../services/time-play.service';
import { GameBoardInitializerProps } from '../../types';
import GameBoardInitializerCardsViewer from './GameBoardInitializerCardsViewer/GameBoardInitializerCardsViewer';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: '#363636'
  },
  background: {
    width: '100%',
    height: '100%',
    opacity: 0.15,
    position: 'absolute',
    top: 0,
    left: 0
  },
  formContainer: {
    width: '70%',
    height: '100%'
  },

  formTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '5%',
    textAlign: 'center',
    fontWeight: '600',
    marginVertical: '2%'
  },
  form: {
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between'
  },
  buttonsContainer: {
    width: '100%',
    height: '7.5%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: '1%'
  },
  filesButtonsContainer: {
    width: '40%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  buttonContainer: {
    width: '20%',
    height: '70%',
    borderRadius: 500,
    borderColor: '#01E2E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(143, 143, 143,0.4)',
    cursor: 'pointer',
    flexDirection: 'row',
    alignContent: 'center'
  },
  buttonText: { fontWeight: '600', color: '#01E2E0' },
  exportButtonContainer: {
    width: '50%',
    borderColor: 'rgba(255, 255, 255, 0.7)',
    backgroundColor: 'rgba(143, 143, 143, 0.4)'
  },
  exportButton: { width: '20%', height: '70%', zIndex: 200, position: 'relative' },
  exportButtonText: { color: '#FFFFFF' },
  playerDeckSelector: {
    width: '48%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.45)',
    borderColor: 'rgba(255,255,255,0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deckCardsManager: {
    width: '95%',
    marginTop: '5%'
  },
  playerDeckScrollViewContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  deckTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10%',
    textAlign: 'center',
    fontWeight: '500',
    marginVertical: '2%'
  },
  pointsForm: {
    height: '10%',
    marginVertical: '2.5%',
    width: '95%',
    backgroundColor: 'rgba(143, 143, 143,0.2)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  pointsFormInput: { width: '33%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
  pointsFormTitle: { fontWeight: '500', color: '#FFFFFF' },
  pointsFormValue: { color: '#AAAAAA', width: '15%', fontWeight: '400', textAlign: 'center' },
  deckContainer: {
    marginTop: '2%',
    width: '95%',
    height: '60%'
  },
  cardCodesTextInput: { height: '30%', width: '100%', marginBottom: '2%', display: 'flex', flexDirection: 'row' },
  cardCodesSearchArea: {
    width: '70%',
    color: '#FFFFFF',
    display: 'flex',
    borderColor: 'rgba(156, 156, 156, 0.7)',
    backgroundColor: 'rgba(36, 36, 36, 0.7)'
  },
  searchCardCodeButtonContainer: {
    height: '100%',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  searchCardCodeButton: {
    height: '30%',
    width: '90%',
    borderRadius: 500,
    borderColor: 'rgba(255,255,255,0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(143, 143, 143,0.4)',
    cursor: 'pointer',
    flexDirection: 'row',
    alignContent: 'center'
  },
  formSelectorsContainer: {
    width: '85%',
    height: '8%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  formSelector: {
    width: '25%',
    right: '2%',
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginVertical: '1%'
  },
  formSelectorText: {
    position: 'relative',
    fontWeight: '600',
    color: '#A1A1A1'
  }
});

const logService = new LogsService();
const cardsService = new CardsService();
const timePlayService = new TimePlayService();

const GameBoardInitializer = ({ navigation }: GameBoardInitializerProps) => {
  const { vw } = useUnits();
  const [allCards, setAllCards] = useState<Card[]>([]);
  const [playerActionPoints, setPlayerActionPoints] = useState<number>(1);
  const [playerLifePoints, setPlayerLifePoints] = useState<number>(30);
  const [playerDeck, setPlayerDeck] = useState<CardQuantity[]>([]);
  const [playerRandom, setPlayerRandom] = useState(true);
  const [playerDiscard, setPlayerDiscard] = useState(false);
  const [playerCardCodes, setPlayerCardCodes] = useState('');
  const [playerInitialHandSize, setPlayerInitialHandSize] = useState<number>(3);
  const [opponentActionPoints, setOpponentActionPoints] = useState<number>(1);
  const [opponentLifePoints, setOpponentLifePoints] = useState<number>(30);
  const [opponentDeck, setOpponentDeck] = useState<CardQuantity[]>([]);
  const [opponentRandom, setOpponentRandom] = useState(true);
  const [opponentDiscard, setOpponentDiscard] = useState(false);
  const [opponentCardCodes, setOpponentCardCodes] = useState('');
  const [opponentInitialHandSize, setOpponentInitialHandSize] = useState<number>(3);
  const [opponentIsArtificialIntelligence, setOpponentIsArtificialIntelligence] = useState(true);
  const [dialoguesConfiguration, setDialoguesConfiguration] = useState<Scene[]>([]);
  const [reactionsConfiguration, setReactionsConfiguration] = useState<ReactionConfiguration[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [opponentFirst, setOpponentFirst] = useState(false);
  const dispatch = useDispatch();

  const checkShowBattleModal = useSelector((s: { timeAdvice: { showTimeModal: boolean } }) => s.timeAdvice.showTimeModal);

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  const fetchCards = async () => {
    try {
      const cards = (await cardsService.getCards()).sort(cardsCodeOrder);
      setAllCards(cards);
    } catch (err) {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema inicializando el tablero de juego`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
    }
  };

  useFocusEffect(
    useCallback(() => {
      if (!allCards.length) fetchCards();
    }, [])
  );

  useEffect(() => {
    dispatch(hideTimeModal());
  }, []);

  const mapDeck = (card: Card | CardQuantity): CardQuantity => {
    if (!card.quantity) {
      return {
        quantity: 1,
        card
      } as CardQuantity;
    }
    return card as CardQuantity;
  };

  const applyConfig = useCallback(async (config: GameBoardConfiguration) => {
    setPlayerLifePoints(config.player!.lifePoints);
    setPlayerActionPoints(config.player!.actionPoints);
    setPlayerInitialHandSize(config.player!.initialHandSize);
    setPlayerDeck(config.player!.deck.flatMap(mapDeck));
    setPlayerRandom(config.player!.random as boolean);
    setPlayerDiscard(config.player!.discard as boolean);
    setOpponentFirst(config.opponent!.opponentFirst as boolean);
    setOpponentActionPoints(config.opponent!.actionPoints);
    setOpponentLifePoints(config.opponent!.lifePoints);
    setOpponentInitialHandSize(config.opponent!.initialHandSize);
    setOpponentDeck(config.opponent!.deck.flatMap(mapDeck));
    setOpponentRandom(config.opponent!.random as boolean);
    setOpponentDiscard(config.opponent!.discard as boolean);
    setOpponentIsArtificialIntelligence(config.opponent!.isArtificialIntelligence as boolean);
    setDialoguesConfiguration(config.dialoguesConfiguration);
    setReactionsConfiguration(config.reactionsConfiguration);
  }, []);

  const onImportFile = async () => {
    try {
      const pickerResult = await DocumentPicker.getDocumentAsync({ type: 'application/json', copyToCacheDirectory: false });
      const config =
        pickerResult.type === 'success' ? JSON.parse(Buffer.from(pickerResult.uri.split('base64,')[1], 'base64').toString('utf8')) : {};
      await applyConfig(config);
    } catch (e: any) {
      setMessage(e.message);
    }
  };
  const getDeckSize = (deck: CardQuantity[]) => {
    return deck.reduce((accum, current) => {
      accum += current.quantity;
      return accum;
    }, 0);
  };
  const showModalTime = () => dispatch(showTimeModal());

  const checkInputs = () => {
    if (getDeckSize(playerDeck) < MIN_DECK_SIZE) {
      setMessage('El mazo del jugador 1 no supera el mínimo de cartas necesario');
      return false;
    }
    if (getDeckSize(opponentDeck) < MIN_DECK_SIZE) {
      setMessage('El mazo del jugador 2 no supera el mínimo de cartas necesario');
      return false;
    }
    if (!playerLifePoints) {
      setMessage('Puntos de vida del jugador 1 no válidos');
      return false;
    }
    if (!playerActionPoints) {
      setMessage('Puntos de acción del jugador 1 no válidos');
      return false;
    }
    if (playerInitialHandSize !== 0 && !playerInitialHandSize) {
      setMessage('Longitud de la mano  del jugador 1 no válida');
      return false;
    }
    if (!opponentLifePoints) {
      setMessage('Puntos de vida del jugador 2 no válidos');
      return false;
    }
    if (!opponentActionPoints) {
      setMessage('Puntos de acción del jugador 2 no válidos');
      return false;
    }
    if (opponentInitialHandSize !== 0 && !opponentInitialHandSize) {
      setMessage('Longitud de la mano  del jugador 2 no válida');
      return false;
    }
    if (showErrorModal) setShowErrorModal(false);
    return true;
  };

  const onExport = async () => {
    try {
      if (!checkInputs()) return;
      const config = {
        player: {
          lifePoints: playerLifePoints,
          actionPoints: playerActionPoints,
          deck: processDuplicatedCards(getDeckCards(playerDeck, playerRandom), PlayerRoundEnum.PLAYER),
          random: playerRandom,
          discard: playerDiscard,
          initialHandSize: playerInitialHandSize
        },
        opponent: {
          lifePoints: opponentLifePoints,
          actionPoints: opponentActionPoints,
          deck: processDuplicatedCards(getDeckCards(opponentDeck, opponentRandom), PlayerRoundEnum.OPPONENT),
          opponentFirst,
          random: opponentRandom,
          isArtificialIntelligence: opponentIsArtificialIntelligence,
          discard: opponentDiscard,
          initialHandSize: opponentInitialHandSize
        }
      };
      const json = JSON.stringify(config, null, 4);
      downloadFile(json, 'game-board-config.json', 'application/json');
    } catch (e: any) {
      setMessage(e.message);
    }
  };
  const onCloseModal = () => {
    setShowErrorModal(false);
  };

  const fetchRules = async (): Promise<Rule[]> => {
    try {
      const rulesService = new RulesService();
      const rules = await rulesService.getRules();
      return rules;
    } catch (err) {
      if (err instanceof ApiError) {
        const ex = err as ApiError;
        setMessage(`[Código: ${ex.httpCode}]: Ocurrió un problema recuperando las reglas`, `[Detalle]: ${ex.detail}`);
      } else {
        setMessage(`Error: ${err}`);
      }
      return [];
    }
  };

  const onStartGame = async () => {
    const rules = await fetchRules();
    boardConfiguration.rules = rules;
    logService.createLog(EventLogs.START_GAME);
    await timePlayService.handleAlert(EventLogs.START_GAME, showModalTime);
    if (!playerDeck.length && !opponentDeck.length) {
      boardConfiguration.player!.deck = processDuplicatedCards(
        getDeckCards(boardConfiguration.player!.deck.flatMap(mapDeck), boardConfiguration.player!.random),
        PlayerRoundEnum.PLAYER
      );
      boardConfiguration.opponent!.deck = processDuplicatedCards(
        getDeckCards(boardConfiguration.opponent!.deck.flatMap(mapDeck), boardConfiguration.opponent!.random),
        PlayerRoundEnum.OPPONENT
      );
      await applyConfig(boardConfiguration);
      navigation.navigate('GameBoard', { gameConfig: boardConfiguration });
      return;
    }

    if (checkInputs()) {
      const gameConfig: GameBoardConfiguration = {
        id: boardConfiguration.id,
        backgroundImage: boardConfiguration.backgroundImage,
        description: boardConfiguration.description,
        name: boardConfiguration.name,
        expPoints: boardConfiguration.expPoints,
        player: {
          lifePoints: playerLifePoints,
          actionPoints: playerActionPoints,
          deck: processDuplicatedCards(getDeckCards(playerDeck, playerRandom), PlayerRoundEnum.PLAYER),
          random: playerRandom,
          discard: playerDiscard,
          initialHandSize: playerInitialHandSize
        },
        opponent: {
          lifePoints: opponentLifePoints,
          actionPoints: opponentActionPoints,
          deck: processDuplicatedCards(getDeckCards(opponentDeck, opponentRandom), PlayerRoundEnum.OPPONENT),
          opponentFirst,
          random: opponentRandom,
          discard: opponentDiscard,
          isArtificialIntelligence: opponentIsArtificialIntelligence,
          initialHandSize: opponentInitialHandSize
        },
        dialoguesConfiguration,
        reactionsConfiguration,
        rewards: boardConfiguration.rewards,
        rules
      };
      navigation.navigate('GameBoard', { gameConfig });
    }
  };
  const validateCardCode = (cardCode: string): boolean => {
    const numbers = cardCode.split('.');
    return numbers.length === 2 && !numbers.some(num => isNaN(parseInt(num))) && allCards.some(card => card.cardCode === cardCode);
  };

  const getCardsFromCodes = (cardCodes: string[]): CardQuantity[] | undefined => {
    const validCardCodes = cardCodes.filter(cardCode => validateCardCode(cardCode)).reverse();
    if (validCardCodes.length !== cardCodes.length) {
      setMessage(`Invalid card codes: ${cardCodes.filter(cardCode => !validateCardCode(cardCode)).join(', ')}`);
      return;
    }
    return validCardCodes.map(cardCode => {
      return { quantity: 1, card: { ...(allCards.find(card => card.cardCode === cardCode) as Card) } };
    });
  };

  const parseCardCodesInput = (player: number) => {
    if (player === PlayerTypeEnum.PLAYER) {
      const cardCodes = playerCardCodes.replaceAll(' ', '').split(',');
      const deckCards = getCardsFromCodes(cardCodes);
      if (deckCards) setPlayerDeck([...deckCards, ...playerDeck]);
      return;
    }
    const cardCodes = opponentCardCodes.replaceAll(' ', '').split(',');

    const deckCards = getCardsFromCodes(cardCodes);
    if (deckCards) setOpponentDeck([...deckCards, ...opponentDeck]);
    return;
  };

  return (
    <View style={styles.container}>
      {checkShowBattleModal && <TimeModal />}
      <ImageBackground style={styles.background} source={backgroundDeckEditor} resizeMode="cover"></ImageBackground>
      <View style={styles.formContainer}>
        <Text style={[styles.formTitle, { fontSize: vw(2) }]}>Inicializar Partida</Text>

        <View style={styles.form}>
          <View style={[styles.playerDeckSelector, { borderWidth: vw(0.2), borderRadius: vw(1.2) }]}>
            <Text style={[styles.deckTitle, { fontSize: vw(1.6) }]}>Jugador 1</Text>

            <View style={[styles.pointsForm, { borderRadius: vw(1) }]}>
              <View style={styles.pointsFormInput}>
                <Text style={[styles.pointsFormTitle, { fontSize: vw(1.4) }]}>Vida</Text>
                <TextInput
                  value={playerLifePoints.toString()}
                  onChangeText={value => setPlayerLifePoints(parseInt(value))}
                  style={[styles.pointsFormValue, { fontSize: vw(1.4) }]}
                  placeholderTextColor="#FFFFFF"
                  keyboardType={'numeric'}
                  maxLength={2}
                />
              </View>
              <View style={styles.pointsFormInput}>
                <Text style={[styles.pointsFormTitle, { fontSize: vw(1.6) }]}>Acción</Text>
                <TextInput
                  value={playerActionPoints.toString()}
                  onChangeText={value => setPlayerActionPoints(parseInt(value))}
                  style={[styles.pointsFormValue, { fontSize: vw(1.4) }]}
                  placeholderTextColor="#FFFFFF"
                  keyboardType={'numeric'}
                  maxLength={2}
                />
              </View>
              <View style={styles.pointsFormInput}>
                <Text style={[styles.pointsFormTitle, { fontSize: vw(1.6) }]}>Mano</Text>
                <TextInput
                  value={playerInitialHandSize.toString()}
                  onChangeText={value => setPlayerInitialHandSize(parseInt(value))}
                  style={[styles.pointsFormValue, { fontSize: vw(1.4) }]}
                  placeholderTextColor="#FFFFFF"
                  keyboardType={'numeric'}
                  maxLength={2}
                />
              </View>
            </View>
            <View style={styles.formSelectorsContainer}>
              <TouchableWithoutFeedback onPress={() => setPlayerRandom(!playerRandom)}>
                <View style={styles.formSelector}>
                  <Text style={[styles.formSelectorText, { fontSize: vw(1.2) }]}>Random</Text>
                  <Image source={playerRandom ? selectedCheckboxIcon : checkboxIcon} style={{ width: vw(2), height: vw(2) }} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => setPlayerDiscard(!playerDiscard)}>
                <View style={styles.formSelector}>
                  <Text style={[styles.formSelectorText, { fontSize: vw(1.2) }]}>Descarte</Text>
                  <Image source={playerDiscard ? selectedCheckboxIcon : checkboxIcon} style={{ width: vw(2), height: vw(2) }} />
                </View>
              </TouchableWithoutFeedback>
            </View>
            <ScrollView
              style={styles.deckCardsManager}
              contentContainerStyle={styles.playerDeckScrollViewContainer}
              showsVerticalScrollIndicator={false}
            >
              <View style={styles.cardCodesTextInput}>
                <TextInput
                  autoFocus={true}
                  onChangeText={(text: string) => setPlayerCardCodes(text)}
                  keyboardAppearance={'dark'}
                  numberOfLines={4}
                  multiline={true}
                  autoCorrect={false}
                  spellCheck={false}
                  disableFullscreenUI={true}
                  value={playerCardCodes}
                  placeholder="Introduce códigos de carta..."
                  style={[
                    styles.cardCodesSearchArea,
                    { fontSize: vw(1.3), paddingBottom: vw(0.2), paddingHorizontal: vw(0.8), borderWidth: vw(0.2), borderRadius: vw(0.5) }
                  ]}
                />
                <View style={styles.searchCardCodeButtonContainer}>
                  <TouchableWithoutFeedback onPress={() => parseCardCodesInput(1)}>
                    <View style={[styles.searchCardCodeButton, { borderWidth: vw(0.2) }]}>
                      <Text>Añadir</Text>
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              </View>

              <View style={styles.deckContainer}>
                <GameBoardInitializerCardsViewer player={0} cards={playerDeck} setCards={setPlayerDeck}></GameBoardInitializerCardsViewer>
              </View>
            </ScrollView>
          </View>
          <View style={[styles.playerDeckSelector, { borderWidth: vw(0.2), borderRadius: vw(1.2) }]}>
            <Text style={[styles.deckTitle, { fontSize: vw(1.6) }]}>Jugador 2</Text>

            <View style={[styles.pointsForm, { borderRadius: vw(1) }]}>
              <View style={styles.pointsFormInput}>
                <Text style={[styles.pointsFormTitle, { fontSize: vw(1.4) }]}>Vida</Text>
                <TextInput
                  value={opponentLifePoints.toString()}
                  onChangeText={value => setOpponentLifePoints(parseInt(value))}
                  style={[styles.pointsFormValue, { fontSize: vw(1.4) }]}
                  placeholderTextColor="#FFFFFF"
                  keyboardType={'numeric'}
                  maxLength={2}
                />
              </View>
              <View style={styles.pointsFormInput}>
                <Text style={[styles.pointsFormTitle, { fontSize: vw(1.6) }]}>Acción</Text>
                <TextInput
                  value={opponentActionPoints.toString()}
                  onChangeText={value => setOpponentActionPoints(parseInt(value))}
                  style={[styles.pointsFormValue, { fontSize: vw(1.4) }]}
                  placeholderTextColor="#FFFFFF"
                  keyboardType={'numeric'}
                  maxLength={2}
                />
              </View>
              <View style={styles.pointsFormInput}>
                <Text style={[styles.pointsFormTitle, { fontSize: vw(1.6) }]}>Mano</Text>
                <TextInput
                  value={opponentInitialHandSize.toString()}
                  onChangeText={value => setOpponentInitialHandSize(parseInt(value))}
                  style={[styles.pointsFormValue, { fontSize: vw(1.4) }]}
                  placeholderTextColor="#FFFFFF"
                  keyboardType={'numeric'}
                  maxLength={2}
                />
              </View>
            </View>
            <View style={styles.formSelectorsContainer}>
              <TouchableWithoutFeedback onPress={() => setOpponentRandom(!opponentRandom)}>
                <View style={styles.formSelector}>
                  <Text style={[styles.formSelectorText, { fontSize: vw(1.2) }]}>Random</Text>
                  <Image source={opponentRandom ? selectedCheckboxIcon : checkboxIcon} style={{ width: vw(2), height: vw(2) }} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => setOpponentIsArtificialIntelligence(!opponentIsArtificialIntelligence)}>
                <View style={styles.formSelector}>
                  <Text style={[styles.formSelectorText, { fontSize: vw(1.2) }]}>IA</Text>
                  <Image
                    source={opponentIsArtificialIntelligence ? selectedCheckboxIcon : checkboxIcon}
                    style={{ width: vw(2), height: vw(2) }}
                  />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => setOpponentFirst(!opponentFirst)}>
                <View style={styles.formSelector}>
                  <Text style={[styles.formSelectorText, { fontSize: vw(1.2) }]}>Start</Text>
                  <Image source={opponentFirst ? selectedCheckboxIcon : checkboxIcon} style={{ width: vw(2), height: vw(2) }} />
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={() => setOpponentDiscard(!opponentDiscard)}>
                <View style={styles.formSelector}>
                  <Text style={[styles.formSelectorText, { fontSize: vw(1.2) }]}>Descarte</Text>
                  <Image source={opponentDiscard ? selectedCheckboxIcon : checkboxIcon} style={{ width: vw(2), height: vw(2) }} />
                </View>
              </TouchableWithoutFeedback>
            </View>
            <ScrollView
              style={styles.deckCardsManager}
              contentContainerStyle={styles.playerDeckScrollViewContainer}
              showsVerticalScrollIndicator={false}
            >
              <View style={styles.cardCodesTextInput}>
                <TextInput
                  autoFocus={true}
                  onChangeText={(text: string) => setOpponentCardCodes(text)}
                  keyboardAppearance={'dark'}
                  numberOfLines={4}
                  multiline={true}
                  autoCorrect={false}
                  spellCheck={false}
                  disableFullscreenUI={true}
                  value={opponentCardCodes}
                  placeholder="Introduce códigos de carta..."
                  style={[
                    styles.cardCodesSearchArea,
                    { fontSize: vw(1.3), paddingBottom: vw(0.2), paddingHorizontal: vw(0.8), borderWidth: vw(0.2), borderRadius: vw(0.5) }
                  ]}
                />
                <View style={styles.searchCardCodeButtonContainer}>
                  <TouchableWithoutFeedback onPress={() => parseCardCodesInput(2)}>
                    <View style={[styles.searchCardCodeButton, { borderWidth: vw(0.2) }]}>
                      <Text>Añadir</Text>
                    </View>
                  </TouchableWithoutFeedback>
                </View>
              </View>

              <View style={styles.deckContainer}>
                <GameBoardInitializerCardsViewer
                  player={0}
                  cards={opponentDeck}
                  setCards={setOpponentDeck}
                ></GameBoardInitializerCardsViewer>
              </View>
            </ScrollView>
          </View>
        </View>
        <View style={styles.buttonsContainer}>
          <View style={styles.filesButtonsContainer}>
            <TouchableWithoutFeedback onPress={async () => onImportFile()}>
              <View style={[styles.buttonContainer, styles.exportButtonContainer, { borderWidth: vw(0.25), marginHorizontal: vw(0.8) }]}>
                <Image style={[styles.exportButton, { left: -vw(1) }]} source={importIcon} resizeMode={'contain'}></Image>
                <Text style={[styles.buttonText, styles.exportButtonText, { fontSize: vw(1.2) }]}>Importar</Text>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => onExport()}>
              <View style={[styles.buttonContainer, styles.exportButtonContainer, { borderWidth: vw(0.25), marginHorizontal: vw(0.8) }]}>
                <Image style={[styles.exportButton, { left: -vw(1) }]} source={exportIcon} resizeMode={'contain'}></Image>
                <Text style={[styles.buttonText, styles.exportButtonText, { fontSize: vw(1.2) }]}>Exportar</Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
          <TouchableWithoutFeedback onPress={() => setShowErrorModal(true)}>
            <View style={[styles.buttonContainer, { marginVertical: vw(0.2), borderWidth: vw(0.2) }]}>
              <Text style={[styles.buttonText, { fontSize: vw(1.2) }]}>Iniciar partida</Text>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
      <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
      {showErrorModal && (
        <ErrorModal
          onCloseModal={onCloseModal}
          message={'La partida va a comenzar. ¿Quieres exportar antes la configuración?'}
          buttons={['Exportar', 'Continuar']}
          onButtonPress={[onExport, onStartGame]}
        />
      )}
    </View>
  );
};

export default GameBoardInitializer;
