import { useEffect } from 'react';
import { connect } from 'react-redux';
import { EventLogs } from '../../commons/dtos/create-log.dto';
import * as auth from '../../redux/Auth/authRedux';
import gameReactionsSlice from '../../redux/GameBoard/reactions';
import { clearProfile } from '../../redux/Profile/profileReducer';
import store from '../../redux/store';

import LogsService from '../../services/logs.service';
const logService = new LogsService();

export interface LogoutProps {
  logout: Function;
}

const Logout = ({ logout }: LogoutProps) => {
  useEffect(() => {
    const signOut = async () => {
      try {
        await logService.createLog(EventLogs.LOGOUT);
        await logout();
        await store.dispatch(clearProfile());
      } catch (error) {
        throw new Error(`Error signing out: ${error as Error}`);
      }
    };
    signOut();
  }, []);

  return <></>;
};

export default connect(null, { ...auth.actions, ...gameReactionsSlice.actions })(Logout);
