import { Modal, Pressable, View, Image, StyleSheet } from 'react-native';
import { closeIconBlack, educationalCard } from '../../../constants/Images';
import { Card as CardType } from '../../dtos/card.dto';
import { isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';
import SelectedCard from './CardTypes/SelectedCard';
import EducationalCardInfo from './EducationalCardInfo';
import { CardSizesEnum } from './utils';

interface CardModalProps {
  detailCardVisible: boolean;
  hide: () => void;
  card: CardType | null;
  showTagsInfo?: boolean;
}

const CardModal = ({ detailCardVisible, hide, card, showTagsInfo = true }: CardModalProps) => {
  const { rw, rh } = useRatioDimensions();
  const modalStyles = StyleSheet.create({
    cardDetailModal: { height: rh(100), width: rw(100), backgroundColor: 'rgba(0, 0, 0, 0.5)' },
    cardDetailModalContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(85,85,85,0.8)',
      borderColor: 'rgba(255,255,255,0.8)'
    },
    cardDetailContainer: {
      width: rw(22.5),
      height: rw(22.5 * 1.4),
      position: 'relative'
    },
    closeIcon: { height: isNativeOrPWA ? 18 : 30, width: isNativeOrPWA ? 12 : 26 },
    closeButtonContainer: {
      position: 'absolute',
      top: '-2%',
      right: '-2%',
      zIndex: 500,
      cursor: 'pointer'
    },
    infoIcon: {
      zIndex: 300,
      width: isNativeOrPWA ? rw(4) : rw(3),
      height: isNativeOrPWA ? rw(4) : rw(3),
      marginRight: 12,
      position: 'absolute',
      bottom: 0,
      right: isNativeOrPWA ? -rw(4) / 2 : -rw(3) / 2
    }
  });

  if (!card) return <></>;
  return (
    <Modal
      style={modalStyles.cardDetailModal}
      animationType="fade"
      statusBarTranslucent
      transparent
      visible={detailCardVisible}
      supportedOrientations={['portrait', 'landscape']}
      onRequestClose={hide}
    >
      <View style={modalStyles.cardDetailModalContent}>
        <View style={modalStyles.cardDetailContainer}>
          <SelectedCard card={card} cardSize={CardSizesEnum.VERY_BIG} showTagsInfo={showTagsInfo} />
          <Image source={educationalCard.info} style={modalStyles.infoIcon} resizeMode="contain" />
          {card.explanation ? <EducationalCardInfo text={card.explanation} /> : null}
          <Pressable style={modalStyles.closeButtonContainer} onPress={hide}>
            <ButtonShape bgColor="white" border square heightBtn={isNativeOrPWA ? 28 : 46}>
              <View>
                <Image source={closeIconBlack} style={modalStyles.closeIcon} resizeMode="contain" />
              </View>
            </ButtonShape>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default CardModal;
