import React from 'react';
import { Animated, GestureResponderEvent, View, ViewStyle } from 'react-native';
import { DraxDragWithReceiverEndEventData } from 'react-native-drax';
import { TapGestureHandler } from 'react-native-gesture-handler';
import { PlayerTypeEnum, PlayersSuffixEnum } from '../../../commons/constants/player';
import type { Card as CardType } from '../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { isNativeMobile } from '../../../commons/utils';
import boardCardsUtils from '../../../commons/utils/gameboard/boardCards';
import { AttackingStatus, ItemInfo } from '../../../types/drag';
import BoardCardItem from './BoardCardItem';

export interface CardConditions {
  isCardReady: boolean;
  isCardAttackable: boolean;
  cardHasDamagePoints: boolean;
}

interface BoardCardsContainerProps {
  cards: CardType[];
  getCardConditions: (card: CardType) => CardConditions;
  getCardStyles: (card: CardType, conditions: CardConditions) => ViewStyle[];
  onHandlerStateChange: (cards: CardType[], card: CardType, isCardReady: boolean, index: number, idAttacking: string) => Promise<void>;
  onMouseEnter: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, card: CardType, idAttacking: string) => void;
  onMouseExit: (e: React.MouseEvent<Element, MouseEvent>) => void;
  AITarget: CardType | null;
  AISelectingTarget: CardType | null;
  onLongPressHandler: (e: GestureResponderEvent, card: CardType) => void;
  onPressOutHandler: () => void;
  getConditions: (card: CardType) => { condition: boolean; condition2: boolean };
  boardCardParentStyle: ViewStyle;
  parentStyle: ViewStyle;
  attackedCard: CardType | null;
  getAttackedPointsResult: (attackedCard: CardType) => number;
  isCurrentPlayerRound: (player: PlayerTypeEnum) => boolean;
  onReceiveMethods: {
    onReceiveDragEnter: (card: CardType) => void;
    onReceiveDragExit: (event: DraxDragWithReceiverEndEventData) => void;
    onReceiveDragDrop: (dropCurrentID: string, index: number) => void;
  };
  playerSuffix: PlayersSuffixEnum;
  showBattlePoints: boolean;
  isOpponentBeingAttacked: boolean;
  isPlayerBeingAttacked: boolean;
  opponentIsArtificialIntelligence: boolean | undefined;
  dragCard: string;
  onDrag: {
    onDragEnter: () => void;
    onDragExit: (card: CardType) => void;
    onDragStart: (card: CardType, index: number) => void;
    onDragEnd: (card: CardType) => void;
    onDragDrop: (card: CardType) => void;
  };
  getAttackingPointsResult: (attackingCard: CardType) => number;
  battleCards: Array<string | number | undefined>;
  cardStyle: ViewStyle;
  getAttackedDefensePoints: (card: CardType) => number;
  getAttackingDefensePoints: (card: CardType) => number;
  side: PlayerRoundEnum;
  setBoardCards: (card: CardType, side: 'player' | 'opponent') => void;
  handleAddItem: (id: string, info: ItemInfo) => void;
  attackingStatus: AttackingStatus;
  setAttacking: Function;
}

const BoardCardsContainer = ({
  side,
  cards,
  onHandlerStateChange,
  onMouseEnter,
  onMouseExit,
  getCardConditions,
  getCardStyles,
  AITarget,
  getConditions,
  onLongPressHandler,
  onPressOutHandler,
  boardCardParentStyle,
  parentStyle,
  attackedCard,
  getAttackedPointsResult,
  isCurrentPlayerRound,
  onReceiveMethods,
  playerSuffix,
  showBattlePoints,
  dragCard,
  isOpponentBeingAttacked,
  isPlayerBeingAttacked,
  onDrag,
  opponentIsArtificialIntelligence,
  getAttackingPointsResult,
  battleCards,
  cardStyle,
  getAttackedDefensePoints,
  setBoardCards,
  getAttackingDefensePoints,
  handleAddItem,
  attackingStatus,
  setAttacking
}: BoardCardsContainerProps) => {
  const excludeCardsActionAndSupport = () => {
    return cards.filter(card => !boardCardsUtils.isCardSupportOrCardAction(card.type?.typeName));
  };
  cards = excludeCardsActionAndSupport();
  const excludeCardsDead = () => {
    return cards.filter(card => !card.isDead);
  };
  cards = excludeCardsDead();

  if (isNativeMobile) {
    return (
      <View style={boardCardParentStyle}>
        <View style={parentStyle}>
          {cards.map((card, index) => {
            const idAttacking = `drag_${side}_attacking_${card.id}_${index}`;
            const conditions = getCardConditions(card);

            const { isCardReady } = conditions;

            const stylesConditions = getCardStyles(card, conditions);
            const { condition2, condition } = getConditions(card);

            return (
              <Animated.View
                key={`${card.id} ${cards.length}`}
                style={cardStyle}
                onTouchStart={() => onHandlerStateChange(cards, card, isCardReady, index, idAttacking)}
              >
                <BoardCardItem
                  side={side}
                  AITarget={AITarget}
                  card={card}
                  condition={condition}
                  condition2={condition2}
                  onLongPressHandler={onLongPressHandler}
                  onPressOutHandler={onPressOutHandler}
                  stylesConditions={stylesConditions}
                  attackedCard={attackedCard}
                  getAttackedPointsResult={getAttackedPointsResult}
                  index={index}
                  isCurrentPlayerRound={isCurrentPlayerRound}
                  onReceiveMethods={onReceiveMethods}
                  playerSuffix={playerSuffix}
                  showBattlePoints={showBattlePoints}
                  dragCard={dragCard}
                  isOpponentBeingAttacked={isOpponentBeingAttacked}
                  isPlayerBeingAttacked={isPlayerBeingAttacked}
                  onDrag={onDrag}
                  opponentIsArtificialIntelligence={opponentIsArtificialIntelligence}
                  getAttackingPointsResult={getAttackingPointsResult}
                  battleCards={battleCards}
                  getAttackedDefensePoints={getAttackedDefensePoints}
                  getAttackingDefensePoints={getAttackingDefensePoints}
                  setBoardCards={setBoardCards}
                  handleAddItem={handleAddItem}
                  attackingStatus={attackingStatus}
                  setAttacking={setAttacking}
                />
              </Animated.View>
            );
          })}
        </View>
      </View>
    );
  }
  return (
    <View style={boardCardParentStyle}>
      <View style={parentStyle}>
        {cards.map((card, index) => {
          const idAttacking = `drag_${side}_attacking_${card.id}_${index}`;
          const conditions = getCardConditions(card);

          const { isCardReady } = conditions;

          const stylesConditions = getCardStyles(card, conditions);
          const { condition2, condition } = getConditions(card);

          return (
            <TapGestureHandler
              key={`${card.id} ${cards.length}`}
              onHandlerStateChange={() => onHandlerStateChange(cards, card, isCardReady, index, idAttacking)}
              numberOfTaps={1}
            >
              <div id={idAttacking} onMouseEnter={e => onMouseEnter(e, card, idAttacking)} onMouseLeave={onMouseExit}>
                <Animated.View style={cardStyle}>
                  <BoardCardItem
                    side={side}
                    getAttackedDefensePoints={getAttackedDefensePoints}
                    getAttackingDefensePoints={getAttackingDefensePoints}
                    AITarget={AITarget}
                    card={card}
                    condition={condition}
                    condition2={condition2}
                    onLongPressHandler={onLongPressHandler}
                    onPressOutHandler={onPressOutHandler}
                    stylesConditions={stylesConditions}
                    attackedCard={attackedCard}
                    getAttackedPointsResult={getAttackedPointsResult}
                    index={index}
                    isCurrentPlayerRound={isCurrentPlayerRound}
                    onReceiveMethods={onReceiveMethods}
                    playerSuffix={playerSuffix}
                    showBattlePoints={showBattlePoints}
                    dragCard={dragCard}
                    isOpponentBeingAttacked={isOpponentBeingAttacked}
                    isPlayerBeingAttacked={isPlayerBeingAttacked}
                    onDrag={onDrag}
                    opponentIsArtificialIntelligence={opponentIsArtificialIntelligence}
                    getAttackingPointsResult={getAttackingPointsResult}
                    battleCards={battleCards}
                    setBoardCards={setBoardCards}
                    handleAddItem={handleAddItem}
                    attackingStatus={attackingStatus}
                    setAttacking={setAttacking}
                  />
                </Animated.View>
              </div>
            </TapGestureHandler>
          );
        })}
      </View>
    </View>
  );
};

export default BoardCardsContainer;
