import { useEffect, useRef, useState } from 'react';

export function useBold(item: number | string, animated: boolean) {
  const firstRender = useRef<boolean>(true);
  const [textChanged, setTextChanged] = useState<boolean>(false);

  useEffect(() => {
    if (firstRender.current && animated) {
      firstRender.current = false;
      return;
    }

    setTextChanged(animated);
  }, [item, animated]);

  return textChanged;
}
