import { ImageSourcePropType } from 'react-native';
import { twitterAvatars } from '../../../constants/Images';
import { tweetMsgs } from '../../../constants/Tweets';
import { randomIntFromInterval } from '../../utils';

export interface TweetItem {
  avatar: ImageSourcePropType;
  message: string;
  username: string;
}

type Gender = 'female' | 'male';

const usernames: Record<Gender, string[]> = {
  male: ['Manuel', 'Pablo', 'Rubén', 'Alejandro', 'Joaquin'],
  female: ['Rosa', 'Lucia', 'María', 'Susana']
};

/**
 * Get gender of user by avatar image name
 * @param {string} image avatar that will be showed on tweet
 * @returns {Gender} Gender of user
 */
function getGenderByAvatar(image: ImageSourcePropType): Gender {
  const isFemale = (image as string).includes('female');
  return isFemale ? 'female' : 'male';
}

/**
 * Get username by user gender
 * @param {Gender} gender gender of user
 * @param {Record<Gender, Set<string>>} usedUsernames record with a set for used usernames
 * @returns {string} non used username
 */
function getNameByGender(gender: Gender, usedUsernames: Record<Gender, Set<string>>): string {
  const avaliableNames = usernames[gender].filter(name => !usedUsernames[gender].has(name));
  const randomIndex = randomIntFromInterval(0, avaliableNames.length - 1);

  const username = avaliableNames[randomIndex];
  usedUsernames[gender].add(username);

  return username;
}

export function generateRandomTweets(): TweetItem[] {
  const tweets: TweetItem[] = [];
  const tweetsQuantity = randomIntFromInterval(2, 4);

  const usedUsernames: Record<Gender, Set<string>> = {
    female: new Set(),
    male: new Set()
  };

  for (let index = 0; index < tweetsQuantity; index++) {
    const avatar = twitterAvatars[randomIntFromInterval(0, twitterAvatars.length - 1)];
    const gender = getGenderByAvatar(avatar);
    const username = getNameByGender(gender, usedUsernames);

    tweets.push({
      message: tweetMsgs[randomIntFromInterval(0, tweetMsgs.length - 1)],
      avatar,
      username
    });
  }

  return tweets;
}
