import axios from 'axios';
import { Rule } from '../commons/dtos/rule.dto';

export default class RulesService {
  getRules = async () => {
    let rulesList: Rule[] = [];
    let rulesPageContent: Rule[] = [];
    let page = 1;

    while (true) {
      const params = { page };
      rulesPageContent = (await axios.get(`${process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL}/rules`, { params })).data;

      if (!rulesPageContent.length) break;

      rulesList = [...rulesList, ...rulesPageContent];
      page += 1;
    }
    return rulesList;
  };
}
