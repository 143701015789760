import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, View } from 'react-native';
import useUnits from 'rxn-units';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import Arrow from '../Arrow/Arrow';
import BotBlock from '../BotBlock/BotBlock';
import GameBoardElements from '../GameBoardElements/GameBoardElements';
import { useStylesHow } from '../useStylesHow';

const HowSectionHand = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();
  const { vh } = useUnits();

  return (
    <ImageBackground
      source={emptyGameboard}
      resizeMode="contain"
      style={[styles.section, isNativeOrPWA && { justifyContent: 'flex-start', paddingTop: vh(20) }]}
    >
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <GameBoardElements handCards />
      <BotBlock withBot={!isNativeOrPWA} title={howTitle['hand-title']}>
        <Text style={styles.paragraph}>
          <TextWithHTML text={howText['hand-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
        </Text>
        <View style={{ position: 'absolute', bottom: isNativeOrPWA ? -60 : -100, left: '50%' }}>
          <Arrow rotate={0} />
        </View>
      </BotBlock>
    </ImageBackground>
  );
};

export default HowSectionHand;
