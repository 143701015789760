import { Dialogue } from './dialog.dto';

export enum TypeSceneEnum {
  STORY = 'historia',
  CURTAIN = 'cortinilla',
  ANIMATION = 'animacion',
  START_GAME = 'iniciando juego',
  END_GAME = 'fin del juego'
}

export class Scene implements SceneProperties {
  scene!: TypeSceneEnum;
  dialogues!: Dialogue[];
  background?: string | undefined;

  constructor(properties?: SceneProperties) {
    if (properties) {
      this.scene = properties.scene;
      this.dialogues = properties.dialogues;
      this.background = properties.background;
    }
  }
}

export interface SceneProperties {
  scene: TypeSceneEnum;
  dialogues: Dialogue[];
  background?: string;
}
