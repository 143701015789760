import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Checkbox } from 'expo-checkbox';
import { Pressable, StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import { Text, View } from '../../../commons/components/Themed';
import adjust from '../../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../../commons/utils';
import { RootStackParamList } from '../../../types';

interface CheckTermsProps {
  setChecked: Function;
  isChecked: boolean;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Login', undefined>;

const CheckTerms = ({ setChecked, isChecked }: CheckTermsProps) => {
  const { vh } = useUnits();

  const navigation = useNavigation<Navigation>();

  const openPrivacyURL = () => navigation.navigate('PrivacyPolicy');

  const openTermsURL = () => navigation.navigate('Terms');

  const styles = StyleSheet.create({
    container: {
      paddingVertical: vh(5),
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100%',
      maxWidth: isNativeOrPWA ? '94%' : 440,
      marginHorizontal: 'auto'
    },
    checkbox: {
      marginTop: isNativeOrPWA ? 1 : 2,
      marginRight: 10,
      height: isNativeOrPWA ? 17 : 15,
      width: isNativeOrPWA ? 17 : 15,
      borderColor: '#aaa'
    },
    text: {
      fontSize: isNativeOrPWA ? adjust(15) : 15,
      fontWeight: '400'
    },
    link: {
      fontSize: isNativeOrPWA ? adjust(15) : 15,
      color: '#00F0AB',
      fontWeight: 'bold'
    }
  });
  return (
    <View style={styles.container}>
      <Checkbox
        style={styles.checkbox}
        value={isChecked}
        onValueChange={() => setChecked(!isChecked)}
        color={isChecked ? '#33F3AB' : undefined}
      />
      <Text style={styles.text}>
        <Text>Confirmo que estoy de acuerdo con los </Text>
        <Pressable onPress={openTermsURL}>
          <Text style={styles.link}>Términos de uso</Text>
        </Pressable>
        <Text> y que he leido la </Text>
        <Pressable onPress={openPrivacyURL}>
          <Text style={styles.link}>Política de privacidad</Text>
        </Pressable>
        <Text> de Fakesover</Text>
      </Text>
    </View>
  );
};

export default CheckTerms;
