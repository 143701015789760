import { createSlice } from '@reduxjs/toolkit';

interface initialStateInterface {
  consent: boolean | null;
}
const initialState: initialStateInterface = {
  consent: null
};

const consentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    enableConsent(state) {
      state.consent = true;
    },
    disableConsent(state) {
      state.consent = false;
    }
  }
});

export const { disableConsent, enableConsent } = consentSlice.actions;

export default consentSlice;
