import { useCallback, useMemo, useState } from 'react';

interface useSideProps {
  actionPoints: number;
  lifePoints: number;
}

export const pointsHelper = (set: React.Dispatch<React.SetStateAction<number>>) => ({
  increment: (value: number) => set(prev => (prev + value <= 0 ? 0 : prev + value)),
  decrement: (value: number) => set(prev => (prev - value <= 0 ? 0 : prev - value)),
  replace: (value: number) => set(value)
});

export function useSide({ actionPoints: actionPointsInitial, lifePoints: lifePointsInitial }: useSideProps) {
  const [actionPoints, setActionPoints] = useState<number>(actionPointsInitial);
  const [maxActionPoints, setMaxActionPoints] = useState<number>(actionPointsInitial);
  const [lifePoints, setLifePoints] = useState<number>(lifePointsInitial);
  const [isBeingAttacked, setIsBeingAttacked] = useState<boolean>(false);
  const [isAttackable, setIsAttackable] = useState<boolean>(false);
  const [isAttacking, setIsAttacking] = useState<boolean>(false);

  const handleActionPoints = useMemo(() => pointsHelper(setActionPoints), []);
  const handleMaxActionPoints = useMemo(() => pointsHelper(setMaxActionPoints), []);
  const handleLifePoints = useMemo(() => pointsHelper(setLifePoints), []);

  const handleIsBeingAttacked = useCallback((isAttacked: boolean) => setIsBeingAttacked(isAttacked), []);
  const handleAttacking = useCallback((value: boolean) => setIsAttacking(value), []);
  const handleAttackable = useCallback((value: boolean) => setIsAttackable(value), []);

  const handleSide = useMemo(
    () => ({ handleActionPoints, handleMaxActionPoints, handleLifePoints }),
    [handleActionPoints, handleMaxActionPoints, handleLifePoints]
  );

  return {
    actionPoints,
    lifePoints,
    maxActionPoints,
    handleSide,
    isBeingAttacked,
    isAttackable,
    isAttacking,
    handleIsBeingAttacked,
    handleAttacking,
    handleAttackable
  };
}
