import LottieView from 'lottie-react-native';
import { useEffect } from 'react';
import { ImageBackground, View } from 'react-native';
import { barrier } from '../../../../constants/Images';
import {
  lottieExplosion,
  lottieMagicSmoke,
  lottieTargetReached,
  lottieUpgradeCardBlack,
  lottieUpgradeCardCoins,
  lottieUpgradeCardHearts,
  lottieUpgradeCardWhite,
  lottieUpgradeModal,
  lottieZzz
} from '../../../../constants/Lotties';
import { useGameActionHighlight } from '../../../../hooks/useGameActionHighlight';
import { Card as CardType } from '../../../dtos/card.dto';
import { EnhanceEffect } from '../../../dtos/enhance.dto';
import { Shadow } from '../../../dtos/shadow.dto';
import { TagsEnum } from '../../../dtos/tag.dto';
import Actions from '../Actions';
import AttackAndDefense from '../AttackAndDefense';
import { CardBackground, CardSubject } from '../Card';
import Tags from '../Tags';
import { useCardStyles } from '../useCardStyles';
import { CardSizesEnum } from '../utils';
import { useCardEnhace } from './useCardEnhance';

interface BoardCardProps {
  card: CardType;
  cardSize: CardSizesEnum;
  AITarget: CardType | null;
  shadow?: Shadow;
  hover: boolean;
  damage?: number;
  setBoardCards: (card: CardType, side: 'player' | 'opponent') => void;
  attackedCard: CardType | null;
}

const BoardCard = ({ AITarget, card, cardSize, hover, setBoardCards, damage, shadow, attackedCard }: BoardCardProps) => {
  const { color, cardCode, damagePoints, defensePoints, enhance, tags, firstTurn, upgradedFirstTurn, isToken, isDead } = card;
  const showExplosion = defensePoints !== null && defensePoints <= 0 && !isDead;
  const enhancePointsResult = useCardEnhace({ enhance });

  useEffect(() => {
    if (!upgradedFirstTurn || setBoardCards === undefined) return;
    const to = setTimeout(() => {
      const copyCard = { ...card };
      copyCard.upgradedFirstTurn = false;
      setBoardCards(copyCard, copyCard.id.includes('player') ? 'player' : 'opponent');
    }, 1800);
    return () => clearTimeout(to);
  }, [upgradedFirstTurn, setBoardCards]);

  const hasAction = card.rules?.[0];

  const { styles } = useCardStyles(card, cardSize, !!hasAction);

  const showDamagePoints = damagePoints !== null && damagePoints >= 0;

  const showDefensePoints = defensePoints !== null && defensePoints >= 0;

  const isBarrier = tags?.some(({ tagName }) => tagName === TagsEnum.BARRIER);

  const hasTags = tags?.some(({ tagName }) => tagName === TagsEnum.IMPACT || tagName === TagsEnum.FACTCHECK);

  const isUndetectable = tags?.some(({ tagName }) => tagName === TagsEnum.UNDETECTABLE);

  const cardHasDamagePoints = damagePoints && damagePoints > 0;

  const isSleeping = firstTurn && !!cardHasDamagePoints;

  const isTokenCard = firstTurn && isToken;

  if (isTokenCard) {
    setTimeout(() => {
      card.isToken = false;
    }, 2000);
  }

  const showTargetLottieEffect = !showExplosion && AITarget && AITarget === card;

  const highlightStyle = useGameActionHighlight(color, cardCode);

  const enhanceValues = enhance
    ? Object.values(enhance)
        .map(enhance => enhance.enhanceEffect)
        .flat()
    : [];

  const upgradeCardWhite = upgradedFirstTurn && color === 0;
  const upgradeCardBlack =
    upgradedFirstTurn &&
    color === 1 &&
    !enhanceValues.some(effect => effect?.effect === EnhanceEffect.COINS) &&
    !enhanceValues.some(effect => effect?.effect === EnhanceEffect.HEARTS);

  const upgradeCardCoins = upgradedFirstTurn && enhanceValues.some(effect => effect?.effect === EnhanceEffect.COINS);
  const upgradeCardHeart = upgradedFirstTurn && enhanceValues.some(effect => effect?.effect === EnhanceEffect.HEARTS);

  if (showExplosion) {
    return (
      <View pointerEvents="none" style={styles.container}>
        <LottieView autoPlay style={styles.lottieEffect} source={lottieExplosion} />
      </View>
    );
  }

  return (
    <View pointerEvents="none" style={[styles.container, highlightStyle, shadow]}>
      <CardBackground card={card} />
      <CardSubject card={card} cardSize={cardSize} />
      {showDefensePoints && (
        <AttackAndDefense
          card={card}
          cardSize={cardSize}
          iconType="defense"
          points={defensePoints || 0}
          enhancePointsResult={enhancePointsResult}
        />
      )}
      {hover && attackedCard && (
        <AttackAndDefense
          card={card}
          cardSize={cardSize}
          iconType="defense"
          points={damage! < 0 ? 0 : damage!}
          enhancePointsResult={{
            damagePoints: enhancePointsResult.damagePoints,
            defensePoints: damage! === defensePoints ? 0 : -1
          }}
        />
      )}
      {showDamagePoints && (
        <AttackAndDefense
          card={card}
          cardSize={cardSize}
          iconType="damage"
          points={damagePoints || 0}
          enhancePointsResult={enhancePointsResult}
        />
      )}

      {isBarrier && (
        <View style={styles.barrierCoverContainer}>
          <ImageBackground resizeMode="stretch" source={barrier} style={styles.barrierCover} />
        </View>
      )}
      {isUndetectable && (
        <View style={styles.undetectableCoverContainer}>
          <LottieView resizeMode="contain" autoPlay style={styles.undetectableCover} source={lottieMagicSmoke} />
        </View>
      )}

      <View style={styles.actionCardContainer}>
        {hasTags && <Tags card={card} />}
        {hasAction && <Actions card={card} />}
      </View>

      {isSleeping && (
        <View style={styles.zzzCoverContainer}>
          <LottieView resizeMode="contain" autoPlay style={styles.zzzCover} source={lottieZzz} />
        </View>
      )}
      {upgradeCardBlack && <LottieView resizeMode="contain" autoPlay style={styles.undetectableCover} source={lottieUpgradeCardBlack} />}
      {upgradeCardCoins && <LottieView resizeMode="contain" autoPlay style={styles.undetectableCover} source={lottieUpgradeCardCoins} />}
      {upgradeCardHeart && <LottieView resizeMode="contain" autoPlay style={styles.undetectableCover} source={lottieUpgradeCardHearts} />}
      {upgradeCardWhite && <LottieView resizeMode="contain" autoPlay style={styles.undetectableCover} source={lottieUpgradeCardWhite} />}
      {isTokenCard && (
        <LottieView resizeMode="contain" autoPlay loop={false} style={styles.undetectableCover} source={lottieUpgradeModal} />
      )}

      {showTargetLottieEffect && <LottieView autoPlay style={styles.targetLottieEffect} source={lottieTargetReached} />}
    </View>
  );
};

export default BoardCard;
