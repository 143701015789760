import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useState } from 'react';
import { StyleSheet, ScrollView, TouchableWithoutFeedback } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import PolyShape from '../../../commons/components/PolyShape/PolyShape';
import PolyShapeImage from '../../../commons/components/PolyShapeImage/PolyShapeImage';
import { View, Text } from '../../../commons/components/Themed';
import ToolTip from '../../../commons/components/ToolTip';
import { DeckData } from '../../../commons/dtos/deck-data.dto';
import { PlayerTypesEnum } from '../../../commons/dtos/player.dto';
import { isNativeMobile, isNativeOrPWA } from '../../../commons/utils';
import { deckBackground } from '../../../constants/Images';
import { Profile } from '../../../redux/Profile/profileReducer';
import { RootStackParamList } from '../../../types';

interface DecksListViewerProps {
  decks: DeckData[];
  onCreateNewDeck: Function;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'CardInventoryViewer', undefined>;

interface DeckProps {
  name: string;
  id: string;
  image: string;
  index: number;
}

const Deck = ({ name, id, image, index }: DeckProps) => {
  const { vw, vh } = useUnits();

  const navigation = useNavigation<Navigation>();
  const styles = StyleSheet.create({
    decksListContainer: { height: '72%', width: '100%', marginTop: vh(3) },
    decksList: { height: '100%', width: '100%', overflow: 'scroll' },
    deckName: { fontWeight: '600', position: 'absolute', bottom: '20%', left: '10%', fontSize: vw(1.35) },
    selectedDeck: {
      opacity: 1,
      shadowProp: {
        shadowColor: '#171717',
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.9,
        shadowRadius: 3
      }
    }
  });
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        navigation.navigate('DeckEditor', { deckId: id, isNewDeck: false });
      }}
    >
      <View style={[styles.selectedDeck, { marginTop: vh(1) }]}>
        <PolyShapeImage imageUri={deckBackground[image]} polyType={index % 4}>
          <Text style={styles.deckName}>{name}</Text>
        </PolyShapeImage>
      </View>
    </TouchableWithoutFeedback>
  );
};

const DecksListViewer = (props: DecksListViewerProps) => {
  const { vw, vh } = useUnits();
  const [toolTip, setToolTip] = useState(false);
  const player = useSelector(({ profile }: { profile: Profile }) => profile.player);
  if (!player) return <></>;

  const canCreateDeck = player.progress?.missionsCompleted >= 20 && player.type !== PlayerTypesEnum.GUEST;

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      paddingLeft: '1%',
      paddingRight: '2%',
      width: '24%',
      height: '90%',
      right: 0,
      bottom: 0,
      color: 'white',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    titleContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '12%' },
    title: { textAlign: 'center', textAlignVertical: 'center', fontWeight: '500', fontSize: isNativeOrPWA ? 18 : 32 },
    createDeckButton: {
      width: '100%',
      flexGrow: 0,
      flexDirection: 'row',
      maxHeight: vh(10),
      maxWidth: isNativeMobile ? '90%' : '100%',
      alignSelf: 'flex-start',
      position: 'relative',
      opacity: canCreateDeck ? 1 : 0.5,
      cursor: canCreateDeck ? 'pointer' : 'default'
    },
    createDeckButtonContainer: {
      width: '100%',
      alignItems: 'center',
      flexGrow: 0
    },
    createDeckButtonText: { color: '#ffffff', fontWeight: '700', fontSize: isNativeOrPWA ? 15 : 26 },
    decksListContainer: { height: '72%', width: '100%', marginTop: vh(3) },
    decksList: { height: '100%', width: '100%', overflow: 'scroll' },
    deckName: { fontWeight: '600', position: 'absolute', bottom: '20%', left: '10%', fontSize: vw(1.35) },
    selectedDeck: {
      opacity: 1,
      shadowProp: {
        shadowColor: '#171717',
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.9,
        shadowRadius: 3
      }
    },
    toolTipText: {
      color: 'black',
      fontWeight: 'bold',
      fontSize: isNativeOrPWA ? 12 : 22
    }
  });

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Tus mazos</Text>
      </View>
      <View style={{ position: 'relative' }}>
        {toolTip && (
          <ToolTip
            tooltipTipStyle={{ top: -vw(0.5), position: 'absolute', transform: [{ translateX: -150 }, { rotate: '180deg' }] }}
            containerStyle={{ top: '120%' }}
            tooltipStyle={{ maxWidth: isNativeOrPWA ? 200 : 300 }}
          >
            <Text style={styles.toolTipText}>
              {player.type === PlayerTypesEnum.GUEST
                ? 'Regístrate para poder crear tus propios mazos'
                : 'Finaliza el Modo Historia para poder crear tu mazo'}
            </Text>
          </ToolTip>
        )}
        <View style={styles.createDeckButton}>
          <TouchableWithoutFeedback
            onPress={() => (canCreateDeck ? props.onCreateNewDeck() : setToolTip(!toolTip))}
            onBlur={() => setToolTip(false)}
          >
            <View style={styles.createDeckButtonContainer}>
              <PolyShape isPointeable={canCreateDeck} bgColor={'#FF1A6C'}>
                <Text style={styles.createDeckButtonText}>+ Nuevo mazo</Text>
              </PolyShape>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>

      <View style={styles.decksListContainer}>
        <ScrollView style={styles.decksList} showsVerticalScrollIndicator={false}>
          {props.decks.map((deck, index) => (
            <Deck key={index} index={index} name={deck.deck.deckName} id={deck.deck.id} image={deck.deck.deckImage} />
          ))}
        </ScrollView>
      </View>
    </View>
  );
};

export default DecksListViewer;
