import { useState } from 'react';

export const useMessageExtended = () => {
  const [msg, setMsg] = useState('');
  const [detail, setDetail] = useState('');

  const setMessage = (msg: string, detail?: string) => {
    setMsg(msg);
    if (detail) {
      setDetail(detail);
    }
  };

  const clearMessageError = () => {
    setMsg('');
    setDetail('');
  };

  return {
    msg,
    detail,
    setMessage,
    clearMessageError
  };
};
