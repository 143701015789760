import { TweetsReactionConfiguration } from '../../dtos/tweet-reaction-configuration.dto';
import { isCurrentPlayerTarget } from './tweet-validatos';

import { ReactionManagementTweet } from './tweets';

export function filterAvatarDamagedDialoguesTweet(
  reactionsManagementProps: ReactionManagementTweet,
  player?: number
): TweetsReactionConfiguration[] {
  return reactionsManagementProps.reactionsConfiguration.filter(reactionConfig => {
    const showDialogue = isCurrentPlayerTarget(reactionConfig, player!);
    return showDialogue;
  });
}
