import React, { useMemo, useState } from 'react';
import { ImageBackground, Image, Text, View, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import { avatarBackgroundUserProfile, avatarImage } from '../../../constants/Images';
import { Profile } from '../../../redux/Profile/profileReducer';
import { AvatarInventory } from '../../../services/player.service';
import { ActorEnum } from '../../dtos/actor.dto';
import { AvatarProperties } from '../../dtos/avatar.dto';
import { GendersEnum } from '../../dtos/player.dto';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';
import { getAvatar } from '../Dialogues/utils';
import Modal from '../Modal';
import PolyShape from '../PolyShape/PolyShape';
import SlidePicker from '../SlidePicker';

interface AvatarModalProps {
  closeModal: () => void;
  currentAvatar: AvatarProperties;
  avatarList: AvatarInventory[];
  username: string;
  updateAvatar: (args: { avatar: AvatarProperties; gender: GendersEnum }) => Promise<void>;
}

const avatarsByGender: Record<'male' | 'female', string[]> = {
  female: [
    `${ActorEnum.AGENT_F}_f`,
    ActorEnum.AGENT_F_F_EVIL,
    ActorEnum.AGENT_V,
    ActorEnum.AGENT_W,
    ActorEnum.DATA_DIRECTOR,
    ActorEnum.DIOXI_DOXI,
    ActorEnum.DORA_PIRAT,
    ActorEnum.HATER,
    ActorEnum.MOM,
    ActorEnum.NEGANA,
    ActorEnum.ROSAURA
  ],
  male: [
    `${ActorEnum.AGENT_F}_m`,
    ActorEnum.AGENT_F_M_EVIL,
    ActorEnum.ANGRY_MASS,
    ActorEnum.BOT_M4L0,
    ActorEnum.CAT,
    ActorEnum.CHATO,
    ActorEnum.COPYFAKE_BOT,
    ActorEnum.DAD,
    ActorEnum.FAKER_8000,
    ActorEnum.FELINOR,
    ActorEnum.INFOLISTO,
    ActorEnum.KONSPIRATRON,
    ActorEnum.MANOLO_UNCLE,
    ActorEnum.METEOR,
    ActorEnum.POPULETAS,
    ActorEnum.TROLL_PARENTS
  ]
};

const AvatarModal = ({ closeModal, currentAvatar, avatarList, username, updateAvatar }: AvatarModalProps) => {
  const { vw, vh } = useUnits();

  const player = useSelector(({ profile }: { profile: Profile }) => profile.player!);

  const [selectedAvatar, setSelectedAvatar] = useState<AvatarProperties>(currentAvatar);
  const currentIndex = useMemo(() => avatarList.findIndex(avatar => avatar.id === currentAvatar.id), [avatarList, currentAvatar]);

  const style = StyleSheet.create({
    parentStyle: {
      width: 'auto',
      height: 'auto',
      paddingHorizontal: isNativeOrPWA ? 32 : 72,
      marginBottom: vh(isNativeOrPWA ? 6 : 4)
    },
    avatarBackground: {
      width: isNativeOrPWA ? vw(12) : 185,
      height: isNativeOrPWA ? vw(12) : 185,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-end',
      position: 'relative'
    },
    avatarImage: {
      width: '100%',
      height: '100%',
      marginBottom: isNativeOrPWA ? 2 : 6
    },
    buttonWrapper: {
      width: '30%',
      height: 'auto',
      alignSelf: 'flex-end'
    },
    buttonText: {
      fontSize: 17,
      color: '#D3D3D3',
      fontWeight: '500'
    },
    centerMiddle: {
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 0,
      flexShrink: 1,
      paddingHorizontal: isNativeOrPWA ? 15 : 64,
      maxHeight: 30
    },
    nickname: {
      flexDirection: 'row',
      flexGrow: 0,
      flexShrink: 0,
      flexWrap: 'wrap',
      maxWidth: isNativeOrPWA ? 180 : 340,
      minHeight: 36
    },
    nicknameText: {
      width: '80%',
      fontSize: isNativeOrPWA ? 18 : 26,
      color: '#FFF',
      fontFamily: 'Inlanders',
      textAlign: 'center'
    },
    nicknameTextMini: {
      fontSize: isNativeOrPWA ? adjust(18) : 21
    },
    modalStyle: {
      justifyContent: 'space-between'
    },
    modalChild: {
      paddingHorizontal: vw(3.2),
      alignItems: 'center'
    },
    avatarContainer: {
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  const handleChangeAvatar = () => {
    const { gender } = player;
    const avatars = avatarsByGender[gender];
    const sameGender = avatars.includes(selectedAvatar.avatarImage.replace('.png', ''));

    updateAvatar({
      avatar: selectedAvatar,
      gender: !sameGender ? (gender === GendersEnum.FEMALE ? GendersEnum.MALE : GendersEnum.FEMALE) : gender
    });

    closeModal();
  };

  return (
    <Modal closeModal={closeModal} styleParent={style.modalStyle}>
      <View style={[style.modalChild, isNativeOrPWA && { paddingTop: 32 }]}>
        <SlidePicker
          onChange={(item: AvatarInventory) => setSelectedAvatar(item)}
          list={avatarList}
          indexSelected={currentIndex}
          parentStyle={style.parentStyle}
        >
          {avatarList.map(avatar => (
            <View key={avatar.id} style={style.avatarContainer}>
              <ImageBackground source={avatarBackgroundUserProfile} style={style.avatarBackground} resizeMode="cover">
                <Image
                  source={getAvatar(avatar.avatarImage) || avatarImage[ActorEnum.FAKER_8000]}
                  style={style.avatarImage}
                  resizeMode="contain"
                />
              </ImageBackground>
            </View>
          ))}
        </SlidePicker>
        <View style={style.centerMiddle}>
          <View style={style.nickname}>
            <PolyShape type={3} bgColor={'#131313'} isPointeable={false}>
              <Text style={[style.nicknameText, username.length > 13 && style.nicknameTextMini]}>{username.toUpperCase()}</Text>
            </PolyShape>
          </View>
        </View>
      </View>
      <TouchableWithoutFeedback onPress={handleChangeAvatar} disabled={currentAvatar.id === selectedAvatar.id}>
        <View style={[style.buttonWrapper, isNativeOrPWA && { paddingBottom: 32 }]}>
          <ButtonShape heightBtn={48} bgColor="black" isPointeable>
            <Text style={style.buttonText}>Seleccionar</Text>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default AvatarModal;
