import { ActorEnum, ActorTypeEnum } from '../../../commons/dtos/actor.dto';
import { GameBoardConfigurations } from '../../../commons/dtos/game-board-configuration.dto';
import { ReactionEventEnum } from '../../../commons/dtos/reaction-configuration.dto';
import { TypeSceneEnum } from '../../../commons/dtos/scene.dto';

export const gameConfiguration: GameBoardConfigurations = {
  id: '189021e7-32e5-4b3c-950a-9b26495f318e',
  missions: [
    {
      // TRAINING DAY
      id: 'bfd41bcc-f6ed-442c-a211-ba7d5008d913',
      disableReactionTweets: true,
      dialoguesConfiguration: [
        {
          // INTRODUCCIÓN
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_[GENDER].png',
          dialogues: [
            {
              text: 'Hola, soy [PLAYER]. ¿Podría decirme dónde estoy?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¿Qué clase de pregunta es esa? ¡Es evidente! Estás en la AFO.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿La AFO? Aham...',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'La Agencia Fakes Over (A - F - O). ¡¡La agencia de verificación MÁS IMPORTANTE DEL MUNDO!!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Ehm... pues... según el navegador de mi móvil esto es un asador de pollos. No una agencia de verificación.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Pone eso como medida de protección frente a nuestros enemigos.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Anda! ¿Qué enemigos?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Tenemos muchos enemigos ahí fuera.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿Los veganos quizás?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡¿Cómo?!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Si yo fuera vegano nunca entraría en un asador de pollos.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡Olvídate de los veganos! Es una técnica de despiste por si alguien...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡¡Da igual!! ¡¿Se puede saber a qué has venido?!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡A por un par de pollos! ¡He cruzado media ciudad pensando que era la única pollería que estaba abierta!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Lo siento. No vendemos pollos. Aquí protegemos al mundo de la desinformación.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Aham…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Frente a relatos falsos, buscamos los hechos objetivos. Como yo siempre digo: “DATO MATA RELATO”.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Allí donde las Fake News, los bulos o las teorías conspirativas amenazan con confundir a la ciudadanía, acude un agente de la AFO para arrojar luz y contar la verdad.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Un momento. ¿Ha dicho BULO?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: '30 minutos antes...',
              actor: null
            }
          ]
        },
        {
          // SALÓN CASA FAMILIAR. FLASHBACK.
          scene: TypeSceneEnum.STORY,
          background: 'living_[GENDER].png',
          dialogues: [
            {
              text: '¡Solo quiero celebrar mi fiesta! ¡¡No podéis obligarme a cancelarla cuando falta una semana!! ¡¡¡UNA!!!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡¡¡He dicho que NO y es que NO!!!',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Es por una buena razón... Dile a tus amigos que es por Alfredo.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Miau.',
              actor: ActorEnum.CAT,
              side: 'right'
            },
            {
              text: '¡No pienso decirle a todos mis amigos que suspendo el FIESTÓN DEL SIGLO porque mis padres creen que <b>MI GATO TIENE WIFI</b>! ¡¡¡ES DE LOCOS!!!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'No lo creemos. Lo sabemos. Y no solo Alfredo. TODOS los gatos lo tienen.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Así es. Y si tus amigos vienen a casa con sus teléfonos móviles pueden provocar una SOBRECARGA DEL ESPECTRO RADIOELÉCTRICO que sea letal para el pobre Alfredo.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¡¡¡Papá, Mamá... POR FAVOR... ALFREDO NO TIENE WIFI!!! ¡Esa teoría es una estupidez, un FAKE, un BULO!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¿Ah, sí? ¡Porque tú lo digas!',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Hagamos un trato. [PLAYER], demuestra CON PRUEBAS que es un <b>BULO</b> y te dejaremos hacer la fiesta. Tienes mi palabra.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Pero, hasta entonces, Alfredo seguirá siendo para nosotros un ROUTER PELUDO.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¡Y DEJA AL GATO EN EL SUELO! ¡¿No ves que su cola es <b>la antena wifi</b> y se la puedes escacharrar?!',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¡Vale!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Miau.',
              actor: ActorEnum.CAT,
              side: 'right'
            },
            {
              text: '¡Y trae un par de pollos para cenar!',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¡Vale!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'De vuelta al presente...',
              actor: null
            }
          ]
        },
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER.
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_[GENDER].png',
          dialogues: [
            {
              text: '¡EY! ¿Sigues ahí? Estoy hablando contigo…',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Eh… Sí… Eh... Mire, una cosa, directora Dato.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Directora Data. DA-TA.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Eso. ¿Qué le parece si le digo que me interesa unirme a la AFO?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡JA! ¡Eso no es tan fácil! ¡Son necesarios <b>AÑOS</b> de preparación! En la agencia solo aceptamos a la <i>crème-de-la-crème.</i>',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Agentes con un currículum TOP...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Una vez saqué un 8 en mates...',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Que tengan capacidades atléticas...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Soy capaz de coger cualquier cosa que se me caiga al suelo haciendo pinza con el dedo gordo del pie!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Experiencia en criptografía...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '“Grafía” es lo de escribir, ¿no? Entonces sí. Uso el Word NIVEL AVANZADO.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Ya… Eh... Mira, lo siento, de verdad, pero no das el perfil que…',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Espere! NECESITO entrar en la AFO. Hay un bulo MUY GRAVE que tengo que neutralizar. Por favor... Please... Plis...',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '[PENSANDO] Hummm… En verdad estamos escasos de gente...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Se lo aseguro. Tengo muuuuucho muuuuuuuuuuucho interés.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡Está bien! Entrarás DE INMEDIATO en nuestro programa de <b>ENTRENAMIENTO</b>. ¡¡No hagas que me arrepienta!!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Genial! ¡Voy a decírselo a mis padres!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡NI HABLAR! ¡No puedes revelarle a nadie dónde trabajas! Sería peligrosisísimo...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿Y qué les digo? Voy a pasar mucho tiempo fuera de casa.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Diles que trabajas en... uhmm... en... uhmm... ¡un asador de pollos!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Prffff…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Por cierto, un dato. ¿Sabías que el único continente donde no hay pollos es la Antártida?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Entonces el que monte una pollería, se forra.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Creo que ya me estoy arrepintiendo…',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          // MÓDULO DE ENTRENAMIENTO. NIVEL 1.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡Hola, pim-pollo! Aquí empieza tu entrenamiento. Soy la Agente V. Data me ha <b>obligado</b> a ser tu tutora.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Vaya... Menudo entusiasmo...',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'El entusiasmo lo pierdo una vez al mes. Cuando cobro la nómina.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Pero eso no importa! Vamos al tema. Tu primer oponente será este: <b>BOT M4L0</b>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Un bot es un <b>programa informático</b> que nuestros enemigos usan para <b>propagar mentiras</b> por las redes.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Piiip. Init: Starting runlevel 1.',
              actor: ActorEnum.BOT_M4L0,
              side: 'left'
            },
            {
              text: '¿Qué ha dicho el MONTÓN DE CHATARRA este?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Piiip. Init: KILLING NEW AGENT…',
              actor: ActorEnum.BOT_M4L0,
              side: 'left'
            },
            {
              text: 'GLUP. Eso sí lo he entendido…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡¡V!! ¿Puedes explicarme qué tengo que hacer antes de que esa cosa empiece a zurrarme?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_ROUND_START,
            onlyFirstTime: true
          },
          reactions: [
            {
              text: '¿Estás preparado?',
              actor: ActorEnum.AGENT_V
            },
            {
              text: '¡¡No!! ¿Es que no me vas a explicar nada más?',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No seas cobardica… ¡¡Empieza el combate!!',
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
            onlyFirstTime: true
          },
          reactions: [
            {
              text: '¡¡Cuidado. Te están atizando bien. Acaba con sus cartas!!',
              actor: ActorEnum.AGENT_V
            },
            {
              text: '¡¡Si tu RESISTENCIA llega a <b>0</b>, pierdes: “FAKES OVER”.',
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_ROUND_START,
            round: 2
          },
          reactions: [
            {
              text: 'Para ganar tienes que acabar con M4L0.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: '¿Cómo hago para golpearlo?',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'ARRASTRA una carta del tablero que tenga el <b>HALO VERDE</b> hasta el personaje de M4L0 y suéltala allí',
              actor: ActorEnum.AGENT_V
            },
            {
              text: '¡Entendido! Se va a enterar la CANICA OXIDADA esta...',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
            onlyFirstTime: true
          },
          reactions: [
            {
              text: '¡¡Eso es!! ¡Le has hecho daño!',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'Piiiiipp... kernel damaged ... Piiiiiiip',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡¡Sigue jugando o PASA EL TURNO si ya no tienes más cartas que mover!!',
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
            lifePointsThreshold: 3,
            onlyFirstTime: true
          },
          reactions: [
            {
              text: ['¡Vigila tu defensa pim-pollo!', 'Piensa antes de atacar.', '¿En serio vas a perder contra ESTO?.'],
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Piiip. ¡Game over!',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Aiiiss…. No había visto a nadie con tan poca ansia en mi larga, larga, larga, laaarga vida.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: '¡Vuelve a intentarlo cuando te hayas preparado mejor!',
              actor: ActorEnum.AGENT_V
            }
          ]
        }
      ],
      rules: []
    },
    {
      // TRAINING DAY - AGAIN
      id: 'abcf0b33-19f6-4f16-8881-2d6e96a25882',
      disableReactionTweets: true,
      dialoguesConfiguration: [
        {
          // MÓDULO DE ENTRENAMIENTO. NIVEL 2.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡Ja! ¿Qué te ha parecido el combate? ¿Impresionada, V?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '...',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Sin palabras, ¿eh? No me extraña. Ese bot ya no vale ni como llavero.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '[SUSPIRO] Siento decírtelo, pero en realidad has luchado contra Bot M4L0 en <b>modo cafetera</b>. Hace unos expresos deliciosos por la mañana.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡¿Me estás diciendo que mi primer gran éxito como agente de la AFO ha sido acabar con una cafetera?!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '[SONRÍE PELIGROSAMENTE] No te preocupes... Ahora te enfrentarás a él en un modo más agresivo. El modo TOSTADORA.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'V… Trae mantequilla y mermelada.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'De verdad que necesito jubilarme ya...',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Piiip. Reboot system complete. ¡¡READY FOR THE FIGHT!!',
              actor: ActorEnum.BOT_M4L0,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_PLAY_CARD,
            cardCode: '302.01',
            onlyFirstTime: true
          },
          reactions: [
            {
              text: '¡Bien hecho [PLAYER]! M4L0 no podrá atacarte (o atacar a otras cartas) hasta que no destruya tu carta BARRERA.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'Piiip. Attack routine blocked. Piiip. Re-evaluating win possibilities!!',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡Cool! Me gustan las cartas barrera… ¿Qué cartas tienen esta capacidad?',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Las que representan MEDIOS DE COMUNICACIÓN. Son una barrera natural contra la desinformación. Evitan que nos creamos los bulos.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'Los FACT-CHECKERS son otro ejemplo de barrera contra la desinformación. Además acaban con los bulos.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: '¡Continúa jugando!',
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: '¡¡Fatal error!! M4L0 needs to be repaired.',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Error code: 0xc0000185.',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Press "Enter" to restart.',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Press F10 for a relaxing cup of NESPRUSSO.',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Lo peor es que me toca repararlo ahora... necesito esa jubilación YA!',
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Piiip. Fake routine complete. ¡¡Opponent destroyed!!',
              actor: ActorEnum.BOT_M4L0,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No hacemos nada bueno de ti, [PLAYER]. ¡Vuelve a intentarlo!',
              actor: ActorEnum.AGENT_V
            }
          ]
        }
      ],
      rules: []
    },
    {
      // V-TRAINING
      id: 'd21a08dc-07da-4bcb-bde7-3f54c133c116',
      disableReactionTweets: true,
      dialoguesConfiguration: [
        {
          // MÓDULO DE ENTRENAMIENTO. NIVEL 3.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡Ahora sí! ¡Ahora sí que sí, V! Reconócelo. Estás pensando que no has visto a nadie con TANTO POTENCIAL como YO en tu vida.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '[PENSANDO EN ALTO] ¿De dónde sacará Data estos merluzos?',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡¿Qué?!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Vamos a ponernos serios... Es casi la hora del bocadillo y hoy traje chicharrones. [Muy ricos]',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Te toca luchar contra el COPYFAKE BOT. Una máquina letal de copiar y pegar mentiras en los <b>muros de las redes sociales</b>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿También hay bots con redes sociales?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Así es. Perfiles falsos, <i>fake followers</i>... Su especialidad es hacer tendencia determinados asuntos por oscuros intereses.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Ten mucho cuidado con este bot, está inundando las redes sociales',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Hashtag: Voy a destruirte. #VoyADestruirte. Y ahora lo repito y lo repito y lo repito…',
              actor: ActorEnum.COPYFAKE_BOT,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_GET_CARD,
            cardCode: '204.02',
            onlyFirstTime: true
          },
          reactions: [
            {
              text: '¡Atención newbie! Has sacado tu primera CARTA DE MEJORA. Usar bien las mejoras es básico para dominar FakesOver.',
              actor: ActorEnum.AGENT_V,
              actions: [
                {
                  driver: 'highlight',
                  configuration: {
                    description: 'Carta clickbaiting en la mano del jugador',
                    component: 'BlackCardHand',
                    id: 'black-card-hand-204.02'
                  }
                }
              ]
            },
            {
              text: 'Esta carta te permite incrementar el ALCANCE y RESISTENCIA de un FAKE.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'Cool!! ¿Cómo la uso?',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Tienes que ARRASTRAR la carta de MEJORA sobre un FAKE que tengas en el tablero para mejorarlo',
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Creo que ahora crearé una cuenta falsa tuya, [PLAYER]. Espera a que la vean tus amigos...',
              actor: ActorEnum.COPYFAKE_BOT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Esto no puede quedar así V. ¡Quiero la revancha contra esta ASPIRADORA RECICLADA!',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // FINAL TRAINING
      id: '987e30a4-46b6-4277-8728-05a3d654fe5c',
      disableReactionTweets: true,
      dialoguesConfiguration: [
        {
          // MÓDULO DE ENTRENAMIENTO. NIVEL 4.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡Lo logré! Y sin despeinarme. ¿Has visto la pelea, V?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¿Disculpa?',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡No me lo puedo creer! ¡Te has perdido una pelea A-LU-CI-NAN-TE!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Lo siento. Estaba distraída con el bocadillo. El bocadillo es SA-GRA-DO.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Terminemos con esto que aún tengo hambre. Última fase del entrenamiento: SKYBOT.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Esto promete…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Cuando usamos Internet no siempre es fácil distinguir si estamos interactuando con un BOT o con una PERSONA de carne y hueso.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '<b>Hay bots capaces de dar respuestas muy humanas</b>. De hecho hay bots más inteligentes que algunas personas… Y este es un ejemplo de ellos.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Las máquinas también tenemos sentimientos. DE ODIO.',
              actor: ActorEnum.FAKER_8000,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: '¡¡Enhorabuena, [PLAYER]!! Ha acabado tu entrenamiento. Bravo, viva, confeti, en fin, me voy a casa.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'ODIO perder…',
              actor: ActorEnum.FAKER_8000,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Me habré ganado por fin el título de agente?',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Máquina 1 - Humano 0.',
              actor: ActorEnum.FAKER_8000
            },
            {
              text: '¿Ves? Esto pasa por no escuchar.',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'ODIO este BOT',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No te desanimes. Has tenido mala suerte con tus cartas. Cada partida os tocarán nuevas cartas ¡Inténtalo otra vez!',
              actor: ActorEnum.AGENT_V
            }
          ]
        }
      ],
      rules: []
    },
    {
      // SIN VERIFICAR, NO COMPARTIRÁS
      id: 'afc92034-688c-483f-b8b7-e6c24af30433',
      dialoguesConfiguration: [
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER.
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene4_[GENDER].png',
          dialogues: [
            {
              text: '¡Directora Data, he superado el entrenamiento! Le dije que no se arrepentiría de darme esta oportunidad.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Eso aún está por ver [PLAYER]. El <b>95,8 %</b> de los nuevos agentes abandonan antes del primer mes.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿Por el estrés?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'No... No aguantan a la agente V.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Well, I can understand por qué…',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'En cualquier caso, felicidades [PLAYER]. Ahora eres un agente más de la AFO.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Tu nombre en clave será F. AGENTE F.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿F? ¿Puedo elegir otra letra?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Me gusta la X. Suena bien. <b>Agente X</b>.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'NO.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Como si fuera un agente mutante, ya sabe.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'NO y NO.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿Y si me llamo agente Fantastic? Fantastic empieza por F.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Y fantoche también. Serás SOLO <b>AGENTE F</b>. Y recuerda que es MUY IMPORTANTE que mantengas tu anonimato ahí fuera.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Oh, yes! Very importante.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Déjame adivinar! Eres agente E. De Erasmus... O tal vez agente T. De Turista',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '[SUSPIRANDO] No... es la agente W y es de La Línea de la Concepción, Cádiz.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'That’s right!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Habla "espanglish" porque le gusta...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Love it! Adoro el British accent.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Anda! Como mi gato, que es raza <i>British Shorthair</i>.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Aham…',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Gracias por aportar ese dato irrelevante. Agente F, la agente W es <b>la mejor</b> desmontando bulos.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Entonces podrás ayudarme con un bulo que no me deja vivir!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Of course!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Te explico. Mis padres piensan que los GATOS TIENEN WIFI. Entre ellos, el mío. Alfredo...',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Espera, espera, espera… ¡¿Qué es eso de que los GATOS TIENEN WIFI?!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'First time que lo escucho.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Ya sabéis, wifi. Dicen que <b>por la cola emiten ondas</b> y se conectan a Internet. No sé...',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿De verdad hay alguien tan tonto, digo… tan <b>"poco informado"</b> para creerse algo ASÍ?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡MIS PADRES! ¡Y por culpa de ese BULO no me dejan celebrar una MEGA-FIESTA que tengo preparada!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'F, olvídate. ¡Hay problemas más importantes que atender!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Pero esto ES IMPORTANTE! ¡MUY IMPORTANTE!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Ahora no es el momento. Tienes que resolver tu primera misión. W te acompañará. Suerte a los dos.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Prfff… ¡¿Y se puede saber qué supervillano nos espera?!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Sure! El TÍO MANOLO. Se dedica a mandar <i>fake messages</i> por Guasáp. Un pesado...',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Creo que voy a pertenecer al 95’8 % de los nuevos agentes…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        },
        {
          // TÍO MANOLO. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: 'Un amigo me ha dicho que su primo de Albacete tiene un pariente que conoce a un pelirrojo que asegura que en los supermercados ya no venden botellas de agua por la sequía.',
              actor: ActorEnum.MANOLO_UNCLE,
              side: 'left'
            },
            {
              text: '¡¿A dónde vamos a llegar?! Menos mal que yo <b>solo</b> bebo cervecita.',
              actor: ActorEnum.MANOLO_UNCLE,
              side: 'left'
            },
            {
              text: 'Voy a compartirlo con todos mis contactos. Así podrán comprar toda el agua que quieran antes de que se acabe. ¡Enviar!',
              actor: ActorEnum.MANOLO_UNCLE,
              side: 'left'
            },
            {
              text: 'Las redes de mensajería privada, como Guasáp o Telegrom, se han convertido en uno de los lugares en que circulan más bulos. We <b>have to be cuidadosos</b>!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'No porque un mensaje te lo envíe un familiar <i>or a friend</i> significa que sea verdad. Very important! SIN VERIFICAR la información, NO SE COMPARTE.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¿Sabías que el CHIRINGUITO ese de la <b>AGENCIA FAKES OVER</b> lo usa el Gobierno para espiar nuestros movimientos?',
              actor: ActorEnum.MANOLO_UNCLE,
              side: 'left'
            },
            {
              text: 'Me lo ha confirmado un amigo que tiene un primo policía cuyo tío segundo tiene un cargo importante en el ministerio. ¡¡Comprobadísimo!! ¡Pásalo!',
              actor: ActorEnum.MANOLO_UNCLE,
              side: 'left'
            },
            {
              text: 'Esto hay que pararlo YA…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Como has perdido te mando fotos de gatitos al grupo para que te animes... ¿Te han contado que tienen wifi? ¡Pásalo!',
              actor: ActorEnum.MANOLO_UNCLE
            },
            {
              text: 'Ya decía mi prima que me tenía que mover a Telegrom...',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No seas uno más del 95,8% Agente F. ¡Vuélvelo a intentar!',
              actor: ActorEnum.DATA_DIRECTOR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: 'Excellent F! Tío Manolo se lo pensará two times antes de compartir otra vez <i>fake information</i>…',
              actor: ActorEnum.AGENT_W
            },
            {
              text: 'A gente como el tío Manolo habría que expulsarlos de los grupos...',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡¡It’s impossible!! ¡¡LOS CREAN ELLOS!!',
              actor: ActorEnum.AGENT_W
            }
          ]
        }
      ],
      rules: []
    },
    {
      // LA BOLA DEL TERRAPLANISMO
      id: '7e31d7a4-1876-4a6b-a880-0be8cf21e5cd',
      dialoguesConfiguration: [
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER.
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene5_[GENDER].png',
          dialogues: [
            {
              text: '¡Chicas, necesito vuestra ayuda! Tenéis que ayudarme a desmontar el bulo de que <b>LOS GATOS TIENEN WIFI</b>. Es cuestión de FIESTA O MUERTE.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿De dónde sale ese bulo? ¿Quién se ha inventado SEMEJANTE ESTUPIDEZ?',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡¡Ni idea!! ¡Pero mis padres SE LO HAN CREÍDO!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'The problem is que la directora Data <b>doesn’t want</b> oír hablar del tema.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Ese bulo me está ARRUINANDO LA VIDA! Mis padres solo me dejan usar el móvil en el jardín para no crearle interferencias a Alfredo.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'I always say: “Todo aquel with the power de hacerte creer fake news, tendrá el poder de hacer que hagas tonterías”.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Siento aguar la fiesta pero hay otra misión. W, tienes que desmantelar <b>una convención de terraplanistas</b>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Terraplanistas?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'People who believe que <b>la Tierra es PLANA</b>.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¿¡Todavía!? ¿En pleno siglo XXI?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Sí, lo sé. El mundo está lleno de... imbéciles.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'F vendrá with <i>me</i>.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡¿F?! ¿El rookie? ¡Si es más torpe que un berberecho! ¡Es una misión muy peligrosa!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Vamos, V. No seas aguafiestas. W y yo APLASTAREMOS a esos terraplanistas. ¿Lo pillas? Aplastar…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'LOL. Yo sí.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '...a mi no me pagan para esto... Está bien, haced lo que queráis...',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: "F, let's go!!!",
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Y yo sigo con mi tarea de desmentir bulos... Al próximo que me pregunte si Walt Disney está congelado le diré que SÍ, y que aparece en “FROZEN”.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            }
          ]
        },
        {
          // LOS TERRAPLANISTAS. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: 'Ni el hombre ha llegado a la luna ni la Tierra es esférica. Esas son las dos grandes MENTIRAS de la historia de la humanidad.',
              actor: ActorEnum.CHATO,
              side: 'left'
            },
            {
              text: 'Pensadlo. La Tierra es plana. Por eso se llama <b>“PLANETA”</b>. Si no la hubieran llamado <b>“REDONDETA”</b>. ¡Ajá! ¡Jaque mate, NASA!',
              actor: ActorEnum.CHATO,
              side: 'left'
            },
            {
              text: 'WTF!! ¡¿Esto va en serio?!',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: 'El Chato is the leader of "the TERRAPLANISTAS".',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Hoy día hay muchas personas dispuestas a <b>creer ciegamente cualquier teoría</b>, por loca que sea y sin atender a lo que dice "the SCIENCE".',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¿Es un fenómeno nuevo?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Siempre hubo people like that, pero hoy <b>parece que son más porque Internet los pone en contacto</b>.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Estamos hartos de vuestras conspiraciones <i>"científicas"</i> para hacernos creer que la Tierra es redonda...',
              actor: ActorEnum.CHATO,
              side: 'left'
            },
            {
              text: 'Pero vamos a ver CHATO… Si la Tierra es plana, ¿donde va a parar el agua de los océanos? ¿Por qué no cae por los lados?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Fácil! ¡Por el muro! El Polo es un <b>gran muro de hielo</b> que rodea el borde de la Tierra y detiene el agua.',
              actor: ActorEnum.CHATO,
              side: 'left'
            },
            {
              text: 'Es una opinión MUY CONTRASTADA por otros que <b>piensan como yo</b>.',
              actor: ActorEnum.CHATO,
              side: 'left'
            },
            {
              text: 'As you see, CHATO no atiende a la razón. Ya sabes cuál es tu misión F!! ',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Este lo que tiene plano es el cerebro!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: '¿Te das cuenta ahora de la verdad? Ningún piloto de avión ha visto jamás la curvatura esa de la Tierra...',
              actor: ActorEnum.CHATO,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿¿¿Como he podido perder frente a este??? W, No se lo cuentes a DATA!!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Tienes "my word". Try again?',
              actor: ActorEnum.AGENT_W
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: 'SUPERB! Ahora vamos a confiscar todo el material de los terraplanistas. <i>See you</i> in the AFO!',
              actor: ActorEnum.AGENT_W
            }
          ]
        }
      ],
      rules: []
    },
    {
      // SERÁ SOLO UN PINCHACITO
      id: '8d6b6c71-6952-4d2e-8dca-84dd769acf58',
      dialoguesConfiguration: [
        {
          // DESPACHO DE LA AGENTE V. - El AGENTE F habla con la AGENTE V
          scene: TypeSceneEnum.STORY,
          background: 'office_scene6_[GENDER].png',
          dialogues: [
            {
              text: '¡¿F estás bien?! W no debió permitir que te enfrentaras tú solo a El Chato. Pudo acabar MUY MAL para ti. Los CONSPIRANOICOS son más peligrosos de lo que parecen',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Pero no fue así gracias a que me entrenó la MEJOR!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No me hagas la pelota... No te servirá de nada.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Una galletita?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No pienso hacer ningún experimento con Alfredo para desmentir que <b>LOS GATOS TIENEN WIFI</b>. PUNTO.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡¿Pero por qué?! ¡A él no le importaría! PALABRA!!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡¿Miau?!',
              actor: ActorEnum.CAT,
              side: 'right'
            },
            {
              text: 'Tengo algo mejor para ti: La DOCTORA NEGANA. Una pseudocientífica <b>negacionista de las vacunas</b>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Y a mí qué me importa una negacionista de las vacunas!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Te importa. Revisando el material confiscado a los terraplanistas vi que había varios libros escritos por ella.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Y? No soy muy de lectura. Prefiero las series de Netflux',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Pues uno se titulaba: <b>“GATOS Y ELECTROMAGNETISMO: EL FUTURO DE LAS TELECOMUNICACIONES”</b>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '<i>"Quizás"</i> la doctora Negana sea la responsable del <b>bulo de los gatos</b>... ¡¡Ve y averígualo!!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Y Data me deja?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'A Data solo le he dicho que vas en una misión contra los antivacunas…',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Oh, bien jugado… ¡¡¡Me largo!!!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Llévate a tu… gato… de aquí… Maldita sea.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Mi-a-u.',
              actor: ActorEnum.CAT,
              side: 'right'
            }
          ]
        },
        {
          // INTERCOMUNICADOR.
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_b.png',
          dialogues: [
            {
              text: 'Agente F, me ha producido gran alegría recibir tu solicitud.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿Mande?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'La agente V me ha dicho que querías ir en una misión contra los antivacunas. ¡¡Por fin te centras en asuntos importantes y te olvidas de fiestas!!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Oh, sí… Desde luego… Los antivacunas y los que no recogen la caca de su perro. Mis dos peores enemigos.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Vivimos en un mundo en el que muchos de los BULOS y FAKE NEWS que corren se ocultan detrás de un aparente conocimiento científico.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Los antivacunas hablan de <i>"estudios"</i> que demuestran que las vacunas causan enfermedades terribles.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Pero ninguno de esos estudios existe de verdad o son mínimamente SERIOS.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'En temas de salud, hay que acudir siempre a las <b>FUENTES OFICIALES</b> o <b>AUTORIDADES SANITARIAS</b>.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Hay que reconocer que la BATA BLANCA que lleva la DOCTORA NEGANA despista...',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Por eso se la pone. Pero en realidad la compró en una TIENDA DE DISFRACES. Un dato: Le costó 2.95 euros en un chino.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          // LOS ANTIVACUNAS. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: 'Las vacunas fueron inventadas para las vacas, no para los humanos. En nosotros provocan <b>muuuuuuuuuuutaciones genéticas</b>.',
              actor: ActorEnum.NEGANA,
              side: 'left'
            },
            {
              text: 'Ya me entendéis… Lo que hay es UN COMPLOT de vacunación mundial para meternos un microchip diminuto y DOMINAR así NUESTRA MENTE.',
              actor: ActorEnum.NEGANA,
              side: 'left'
            },
            {
              text: 'Seriously?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Quieren que seamos un rebaño muuuuuuuuuuuy dócil. Ya me entendéis.',
              actor: ActorEnum.NEGANA,
              side: 'left'
            },
            {
              text: 'Muuuuuuuuuuuy interesante sí... ¿Se puede saber en qué sustentas esta uhmm... "teoría"?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Tengo estudios muy solventes que lo demuestran publicados en la revista "The Wild Science" y en "VacuWorld".',
              actor: ActorEnum.NEGANA,
              side: 'left'
            },
            {
              text: 'F, he stalkeado un poco a NEGANA. La revista ni siquiera existe y "VacuWorld" es un blog creado por cuatro amigos de la infancia...',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: '¡LOS CIENTÍFICOS QUE ME CONTRADICEN SON UNOS VENDIDOS A LAS FARMACÉUTICAS! ¡Sólo YO digo la verdad!',
              actor: ActorEnum.NEGANA,
              side: 'left'
            },
            {
              text: '¿Realmente tiene seguidores esta señora?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Muuuuuuuchos. Así que deténla rápido antes de que haga más daño!!',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Voy a escribir un nuevo paper: "Las 5G, con G de GATO: cómo los felinos dominan las telecomunicaciones..."',
              actor: ActorEnum.NEGANA,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Menos mal que estoy vacunado contra la derrota...',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: 'NEGANA, has perdido. Se acabó.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No he perdido. Tan solo he pospuesto la victoria. Es diferente…',
              actor: ActorEnum.NEGANA,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Lo que tú digas.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Necesito que me respondas a algo. ¿Eres la responsable de la teoría de que <b>LOS GATOS TIENEN WIFI</b>?',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Oh… Ese trabajo me elevará a los más prestigiosos altares científicos…',
              actor: ActorEnum.NEGANA,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Entonces, ¿esa teoría es tuya SÍ o NO?',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡Ojalá! Yo solo confirmé el hallazgo. El estudio me lo encargó alguien que pertenecía a la plataforma <b>ODIUM.NET</b>.',
              actor: ActorEnum.NEGANA,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Odium.net…',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Respóndeme algo "AGENTE". ¿Tienes algún gato en casa?',
              actor: ActorEnum.NEGANA,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Sí.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Y no has notado que va más lento Internet? Es por su culpa. Está <b>multiplexando</b> el servicio de banda ancha.',
              actor: ActorEnum.NEGANA,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡¿Qué?! Por favor, YA BASTA.',
              actor: ActorEnum.AGENT_F
            }
          ]
        }
      ],
      rules: []
    },
    {
      // MENUDO JETA EL POPULETAS
      id: '9164f39e-dc95-43c6-aaba-98077de38fed',
      dialoguesConfiguration: [
        {
          // PAPÁ, MAMÁ y ALFREDO están en el salón de casa.
          scene: TypeSceneEnum.STORY,
          background: 'living_scene7.png',
          dialogues: [
            {
              text: 'Mamá, estoy leyendo un libro muy interesante de una tal DOCTORA NEGANA.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¿DOCTORA NEGANA? Qué nombre tan curioso papá...',
              actor: ActorEnum.MOM,
              side: 'right'
            },
            {
              text: 'Dice que si se toma el sol <b>al desnudo y te frotas un limón</b> por el cuerpo tus células REJUVENECEN.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¡Justo lo que necesito!',
              actor: ActorEnum.MOM,
              side: 'right'
            },
            {
              text: '¿Probamos?',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Si lo dice una doctora…',
              actor: ActorEnum.MOM,
              side: 'right'
            },
            {
              text: 'Voy a la nevera a por un par de limones.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Puaj.',
              actor: ActorEnum.CAT,
              side: 'right'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'Pocos minutos después...',
              actor: null
            }
          ]
        },
        {
          // INTERCOMUNICADOR.
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_b.png',
          dialogues: [
            {
              text: 'Agente F, aquí la directora Data.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡¡¡AAAAAAAAAAAAAAAAAAAAAAAAAAAAAHHHHHHHHHHHH!!!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Agente F, ¿¡estás bien!?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡¡¡NO!!! Acabo de ver a mis padres <b>DESNUDOS</b> en el jardín. ¡¿QUÉ HACÉIS CON ESOS LIMONES?! ¡OH, NO!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡QUÉ ASCO! ¡Genial! Un trauma nuevo…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Miau',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Creo que no quiero saber lo que ha pasado. Agente F, te necesitamos de nuevo.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Hemos detectado una <b>explosión de mentiras</b> durante la campaña electoral.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Prfff... Menuda novedad...',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Esto es distinto. Ha aparecido un nuevo candidato, EL POPULETAS. Va por <b>71 mentiras diarias</b>, y subiendo',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Es nuestro deber destapar las <i>fake news</i> que dicen los políticos.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Así los ciudadanos podrán votar sabiendo exactamente lo que votan. Recuerda. DATO MATA RELATO.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Sí, ya sé… Dato, dato, dato.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Los <b>populismos</b> son un peligro para las democracias. A problemas complejos dan respuestas simples.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Su objetivo es dividir y enfrentar a la gente con un único fin.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Ganar votos… Supongo.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'O lo que es lo mismo, conseguir <b>más poder</b>. Debes ir a un mitin de El Populetas para asegurarnos de que la campaña sea justa.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Cuento contigo.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: '3 horas después. En el mitin de EL POPULETAS.',
              actor: null
            }
          ]
        },
        {
          // EL POPULISMO. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          //background: 'whatsapp_screen.png'
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡F! What a surprise! Still alive! LOL.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Ey! Yo también me alegro de verte… ¿Qué tenemos por aquí?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'This horrible troglodita cavernario, lier, inútil con risa de orangután está intoxicando la campaña con bulos vomitivos.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Vaya, nunca te había visto así de enfadada…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'He is <b>the worst person</b> I’ve ever known! Utiliza su influencia mediática para atacar a los más débiles.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Los inmigrantes, los pobres, el colectivo LGBT… ¡Dale su merecido, F!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Tenéis que creerme! Los que no piensan como nosotros son EL PROBLEMA. <b>LAS ÉLITES…</b> En cambio yo… ¡Miradme! Soy un tipo normal. ¡Del pueblo! ',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: '<b>Soy alguien de fiar</b>. Doy besos a niños, bailo y tengo un cachorrito que huele a leche. ¿Verdad que soy adorable?',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: 'Tengo que rescatar a ese perrete que tiene en brazos. Corre peligro de intoxicarse. Está demasiado cerca de su axila.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Ha quedado claro quién ha recibido la mayoría absoluta de trumpazos. Tú.',
              actor: ActorEnum.POPULETAS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Try again, [PLAYER]. Podemos ganar.',
              actor: ActorEnum.AGENT_W
            },
            {
              text: 'Yes, we can!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // MASA ENFURECIDA
      id: 'd5ae4eb3-f1dd-4a96-99ca-a4db262869f0',
      dialoguesConfiguration: [
        {
          // EL POPULETAS: Post victoria
          scene: TypeSceneEnum.STORY,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡¡¡Maldición!!! ¡¡¡Has fastidiado una campaña perfectamente planificada!!! ¡¡¡Seguro que eres de Odium.net!!!',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: '¿De Odium.net? No. Soy de la Agencia Fakes Over.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Bah… Lo mismo da. La <b>misma ESCORIA…</b>',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: '¿Por qué odias tanto a los de Odium.net?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Quisieron comprarme. ¡A MÍ! Querían que me pasara la campaña diciendo que los gatos son un peligro. ¡Están locos!',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: 'Nadie odia tanto a los gatos. A los hámsteres… todavía.',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: '¿Y qué les dijiste?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Que se fueran al demonio! Aún me queda algo de dignidad…',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: '¿Dignity? ¡¿ESE?!',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: '[IGNORANDO A W] Como me quieren fuera de la competición electoral, pensé que te habían enviado...',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: 'Estamos aquí porque te has pasado toda la campaña MINTIENDO a la gente, Populetas. Se acabó.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'It is over!!!',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: '[SONRIENDO] Más quisieras… La gente todavía ME ADORA. A pesar de mis mentiras.',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: 'Voy a demostrarte qué fácil es UTILIZAR a la masa cuando está ENFURECIDA…',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: '¿Qué vas a hacer?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡¡¡Amigos!!! Los de la Agencia Fakes Over intentan SILENCIARME. ¡¡Quieren acabar con la LIBERTAD DE EXPRESIÓN!!',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: '¿Les vamos a dejar? ¡¿O vamos a ir a por ellos?!',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: 'Oh, oh…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        },
        {
          // EL POPULISMO 2. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '<b>What have you done, F?!</b> Hay cientos de personas que van a por ti. <b>Get out of there right now!</b>',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡No puedo! ¡Están por todas partes! W, ¿has oído lo de <b>ODIUM.NET</b>?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'YES, pero tienes otros problemas ahora, <i>darling</i>. Esto es lo que hace el populismo. Vuelve a la ciudadanía <i>so angry</i>.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '<i>Be careful</i>. El POPULETAS ha jugado con las <i>emotions</i> de sus seguidores para que te vean como <i>"the enemy"</i>. No será una lucha fácil',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Yo me encargo de esta MASA ENFURECIDA. Tú dile a V que investigue quién está detrás de ODIUM.NET... ',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'OK. Good luck.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: '¡La venganza no nos calma! ¡Queremos aniquilar a todo el que piense diferente!',
              actor: ActorEnum.ANGRY_MASS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Ahora soy yo el que quiere venganza. ¿Lo intentamos de nuevo?',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: '¿AGENTE F, Te encuentras bien?. ¡Acabo de enterarme de lo que ha pasado!',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Puff… Ha faltado poco. ¿Cómo se han podido creer eso de la LIBERTAD DE EXPRESIÓN?',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'El populismo divide a la gente <b>EN BANDOS</b>. <b>Nosotros</b> contra <b>ellos</b>. A partir de ese momento, dan igual los DATOS. Es mi peor PESADILLA.',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Un dato: Todas las afirmaciones de El POPULETAS en su campaña eran totalmente INVENTADAS o MANIPULADAS.',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Llegó a afirmar que los inmigrantes TIRAN la COMIDA de las ONGs y comen sushi DEL BUENO en restaurantes 5 estrellas.',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: '¡Totalmente cierto! ¡¡Vienen a robarnos y dejarnos sin sushi!!',
              actor: ActorEnum.ANGRY_MASS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿¡Pero en qué datos os basáis para creeros semejante barbaridad!? ',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '[UNA VOZ] ¡Son 50 millones de inmigrantes!. [OTRA VOZ] No. Son 100 millones. Lo dijo EL POPULETAS!!',
              actor: ActorEnum.ANGRY_MASS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '[TODOS] El POPULETAS siempre da unos DATOS muy buenos. Confirman todo lo que dice SIEMPRE!!',
              actor: ActorEnum.ANGRY_MASS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿100 millones? ¿No véis que eso es EL DOBLE de personas que hay en España? Es imposible.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '...',
              actor: ActorEnum.ANGRY_MASS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡¡EL DOBLE DE PERSONAS!!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡¡Por eso nos quedamos sin sushi!! ¡Es que son MUCHÍSIMOS! ¡¡Una INVASIÓN!!',
              actor: ActorEnum.ANGRY_MASS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Déjalo [PLAYER]. Hace falta mucha experiencia para razonar con ellos. Dejemos que se encargue V. Es una de sus especialidades',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Vuelve a casa. Ha sido un día duro. ¡Buen trabajo!',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Gracias!!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // ESCUDO ANTIMENTIRAS
      id: '0cc07303-1a4d-44e6-b274-7e986711be5f',
      dialoguesConfiguration: [
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'Casa de [PLAYER]',
              actor: null
            }
          ]
        },
        {
          // EN EL SALÓN FAMILIAR
          scene: TypeSceneEnum.STORY,
          background: 'living_scene8.png',
          dialogues: [
            {
              text: 'Mamá, no me apetece salir a pasear. ¿Qué hacemos?',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¿Por qué no pones algo en el chisme este inteligente que te regaló [PLAYER] por tu cumpleaños?',
              actor: ActorEnum.MOM,
              side: 'right'
            },
            {
              text: '¡Buena idea Mamá!',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Miau!',
              actor: ActorEnum.CAT,
              side: 'right'
            },
            {
              text: 'Vamos a ver qué nos recomienda el Yo-Tuve ese...',
              actor: ActorEnum.DAD,
              side: 'left'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'Unas cuantas recomendaciones después...',
              actor: null
            }
          ]
        },
        {
          // MENSAJE DE KONSPIRATRÓN
          scene: TypeSceneEnum.STORY,
          background: 'studio_konspiratron_scene18.png',
          dialogues: [
            {
              text: '¡Ey, ey, ey! KONSPIRATRÓN soy, y solo la verdad os doy.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'En el último siglo, poko a poko, la peña hemos llenado nuestras kasas de APARATOS ELEKTRÓNICOS ke emiten todo el rato <b>ONDAS DE RADIOFREKUENCIA al aire</b>.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'PI, PI, PI… Esas ondas imperceptibles <b>están por todas partes</b>, bros; RODEÁNDONOS, y krean KAMPOS ELEKTROMAGNÉTIKOS muy tochos.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'Los seres humanos somos inmunes, ¿oyap? Todo OK para nosotros. Pero una científika, la DOK-TORA NEGANA, de la universidad de… Bueno, eso da igual.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'Esa pava ha deskubierto algo KE FLIPAS.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: '¡A LOS GATOS LES AFEKTAN LAS ONDAS ESAS! Y los kampos de energía han provokado ke el sistema nervioso de los gatos haya sufrido una <b>MUTACIÓN GENÉTIKA</b>.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: '¡En plan MUTANTE, bros! Ahora son <b>MÁS INTELIGENTES</b>.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'Y, lo más flipante, son kapaces de usar <b>LA KOLA COMO ANTENA WIFI</b> ¡para konektarse a Internet KON SOLO PENSARLO! ¡BUM! Os akaba de explotar la kabeza.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'A mí también. La pregunta es: <b>¿HAY ALGO MÁS KE NO SEPAMOS DE LOS GATOS?</b> ¿Okultan algún siniestro plan? Muy pronto os lo kontaré.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            }
          ]
        },
        {
          // SALÓN CASA FAMILIAR.
          scene: TypeSceneEnum.STORY,
          background: 'living_scene8.png',
          dialogues: [
            {
              text: 'Mamá, ¿estás pensando lo mismo que yo?',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Creo que sí, papá…',
              actor: ActorEnum.MOM,
              side: 'right'
            },
            {
              text: '¡Hay que DESCONECTAR el router!',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¡Miau!',
              actor: ActorEnum.CAT,
              side: 'right'
            },
            {
              text: '¡Corre, YO SUJETO a Alfredo!',
              actor: ActorEnum.DAD,
              side: 'left'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'Mientras tanto en la AFO...',
              actor: null
            }
          ]
        },
        {
          // DESPACHO DE LA AGENTE V. - La AGENTE V corre de un lado a otro
          scene: TypeSceneEnum.STORY,
          background: 'office_scene9_[GENDER].png',
          dialogues: [
            {
              text: 'V, necesito tu ayuda.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Ahora no puedo! ¡Estoy MUY ocupada!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Esto es TOCHO-importante! ¡Tengo nueva información sobre mi caso!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Si sigues molestando te echo a la calle. No por la puerta. ¡Por la ventana!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Vale, vale, vale… Pero ¿qué pasa? ¿Por qué estás tan estresada?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡El servicio de <b>VERIFICACIÓN ONLINE</b> de la AFO está a punto de COLAPSAR!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Qué es eso?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿Cómo es que te haces llamar Agente de la AFO? Es nuestro canal de verificación a través de Guasáp.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Cualquier persona que tenga dudas sobre la veracidad de una noticia nos puede preguntar y nosotros <b>LO COMPROBAMOS</b>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Ah, pues qué chulo ¿no?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'CHULO dice. ¡Estaría bien si no estuviéramos recibiendo CIENTOS de mensajes por minuto! Algún graciosillo ha subido a Internet un vídeo fake titulado “La extinción se acerca”.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Y en él se ve un METEORITO GIGANTE dirigiéndose hacia la Tierra.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Me encantan las películas apocalípticas. ARMAGEDDON, DEEP IMPACT, ICE AGE 5...',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Déjate de películas! ¡El problema es que <b>LA GENTE PIENSA QUE ES VERDAD</b> y ha entrado en PÁNICO!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Estoy tan desbordada que no he podido hacerme siquiera el segundo desayuno!!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'No te preocupes. Te ayudaré a restablecer la normalidad en el servicio de verificación',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        },
        {
          // VERIFICACIÓN ONLINE. COMBATE
          scene: TypeSceneEnum.START_GAME,
          background: 'office_scene9.png',
          dialogues: [
            {
              text: 'En las CATÁSTROFES siempre aparece algún <b>"gracioso"</b> que se dedica a difundir FAKES y crear CAOS. Aprovechan el miedo de la gente y la falta de información fiable',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Por qué lo hacen V?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No lo sé. Es un misterio. Pero esta vez se les ha dado particularmente bien.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Tenemos <b>1323 CONSULTAS</b> en los últimos <b>5 MINUTOS</b>, ¡y SUBIENDO!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿No nos puede echar una mano BOT M4L0? ¡Seguro que es más rápido que nosotros!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Buena idea! Consígueme 8 minutos para reprogramarlo como <b>BOT BU3N0</b>… Mejor 10, que está acabando de hacerme el café.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Loading AntiFakes mode in 10 minutes…',
              actor: ActorEnum.BOT_BU3N0,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Ups… Creo que la directora Data se va a enfadar con nosotros…',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Piii…',
              actor: ActorEnum.BOT_BU3N0
            },
            {
              text: '¿Un cafelito y reintentamos?',
              actor: ActorEnum.AGENT_V
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: '¡BOT BU3N0 conectado al servicio de verificación y preparado para actuar! ¡Bien hecho, F!',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'Piiip. Antifakes mode operational. Searching for verified information....',
              actor: ActorEnum.BOT_BU3N0
            },
            {
              text: 'Pending answers: 1025 ... 343 ... 5 ... 1 ... Prrrrrr... Waiting for more requests',
              actor: ActorEnum.BOT_BU3N0
            },
            {
              text: 'Gratias a ti hemos evitado el colapso. La gente parece estar ahora mucho más tranquila. Aunque a mí casi me da un apechusque…',
              actor: ActorEnum.AGENT_V
            },
            {
              text: 'Piiip. Piiiiiip. Piiiiiiiiip. Defibrilabot loading… Prrrrrr…',
              actor: ActorEnum.BOT_BU3N0
            }
          ]
        }
      ],
      rules: []
    },
    {
      // CULTURA CANCELADA
      id: '45044abb-5d45-4a97-a55b-ecbd3537cb0d',
      dialoguesConfiguration: [
        {
          // DESPACHO DE LA AGENTE V. - Una vez superada la crisis anterior
          scene: TypeSceneEnum.STORY,
          background: 'office_scene6_[GENDER].png',
          dialogues: [
            {
              text: 'Gracias por ayudarme a restablecer el servicio de verificación online, F. Eres un encanto.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Ahora, dime. ¿Qué querías contarme?',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Te ha dicho la agente W que tenemos una NUEVA PISTA? <b>¡Odium.net!</b>',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿W? No me ha dicho nada… Estuvo aquí hace un rato y no mencionó nada de Odium.net. Ni en español. Ni en inglés.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Qué raro… Le pedí que te lo dijera.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Mmm… La noté nerviosa. Mencionó algo acerca de la Directora Data… Algo de un extraño mensaje que le ha enviado.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿En serio? ¿Qué será…?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Bueno. Ve al grano. No pienso hacer horas extras.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'La doctora Negana me dijo que el estudio “científico” sobre que LOS GATOS TIENEN WIFI lo financió la organización ODIUM.NET.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Y hay más! Odium.net trató de comprar a El Populetas. Querían que hablara de gatos durante su campaña electoral pero se negó.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Algo me huele mal en todo esto.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Qué vas a oler tú! Suficiente tienes con respirar y atarte los cordones al mismo tiempo.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Te digo V que aquí hay <b>"gato encerrado"</b>! ¿Investigarás a Odium.net, porfa?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Sí, sí… pero hoy no. He terminado mi jornada laboral y me espera un bocadillo de sobrasada en casa. Oye, te está llamando la jefa. Si pregunta por mí, ¡NO ESTOY!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            }
          ]
        },
        {
          // INTERCOMUNICADOR: Los haters
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_b.png',
          dialogues: [
            {
              text: 'F, tengo algo para ti. ¿Conoces a Rosaura?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿La cantante? ¡Claro! ¡¿Quién no conoce a Rosaura?! Su último disco, “Motochula”, ¡ES PURA FANTASÍA! ¿Lo ha escuchado?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No me interesa.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Vaya…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Lo que sí me interesa es <b>su último PROBLEMA EN REDES</b>. ¿Estás al tanto?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Me temo que no. Mis padres han CORTADO Internet en casa. No quieren que Alfredo se conecte <i>sin supervisión</i>.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'No pregunte. En fin… ¿Qué ha pasado?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Un grupo de HATERS quiere cancelar su gira porque, según ellos, una de sus canciones incita al <b>canibalismo de bebés</b>.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Una que la letra dice: “Baby boy, baby girl, ñam-ñam”.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'TE-MA-ZO. Me sé la coreografía. ¿Se la hago?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Quiero que <b>acabes con las mentiras de los haters de Rosaura</b>. Una cosa es expresar una <b>opinión</b> en redes y otra es DIFAMAR, ACOSAR, INSULTAR y AMENAZAR.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Sólo porque alguien sea famoso no puedes difamarlo en redes como si cualquier cosa. ¡Esas actitudes no pueden consentirse. Llevan al odio!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Tiene toda la razón. Por cierto, dire… ¿Ha hablado con W?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿Cuándo?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Hace poco…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Eh… Pues no. ¡¿Y a ti qué te importa?!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Solo era curiosidad!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Concéntrate en cumplir tu misión. Confío en ti.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          // LOS HATERS. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '“Baby boy, baby girl, ñam-ñam”... ¿De verdad pensaba Rosaura que no nos daríamos cuenta? Esa estrofa es un mensaje directo para sus seguidores caníbales.',
              actor: ActorEnum.HATER,
              side: 'left'
            },
            {
              text: 'Si la dejamos actuar con impunidad, los bares pronto ofrecerán niños en el menú del día.',
              actor: ActorEnum.HATER,
              side: 'left'
            },
            {
              text: '¡Tenemos que protegerlos de esta SECTA CANÍBAL! ¡Hay que CANCELAR a Rosaura!',
              actor: ActorEnum.HATER,
              side: 'left'
            },
            {
              text: 'No me lo puedo creer… Entre estos y los que dicen que LOS GATOS TIENEN WIFI… ¡¡El mundo se está volviendo loco!!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: '¡JA! El próximo concierto de Rosaura ha sido cancelado.',
              actor: ActorEnum.HATER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Noo!!! Y yo que había comprado 3 entradas... ¡Todo es culpa mía!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Vuelve a intentarlo [PLAYER]. Recuerda: DATO MATA A RELATO',
              actor: ActorEnum.DATA_DIRECTOR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // SALVAMENTO CLIMÁTICO
      id: '79a0393f-73f7-4006-867b-4934d5c77b45',
      dialoguesConfiguration: [
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: '1 día después del combate con LA HATER...',
              actor: null
            }
          ]
        },
        {
          // INTERCOMUNICADOR. - Rosaura
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_b',
          dialogues: [
            {
              text: 'AGENTE F, alguien quiere hablar contigo',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¿?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿Hola? ¿Con quién hablo?',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: '¿E-eres Ro-ro-rosaura? ¡¿En pe-persona?! ¡Me mu-muero!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Así es. ¿Eres tú quien me ha salvado de esos HATERS?',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Sí… pe-pero no fue nada. Es mi trabajo. Soy agente de la Agencia Fa-Fakes Over. Jeje. Lo volvería a hacer.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Oooh, mil gracias! ¡De verdad que estoy muy agradecida! No podía ni abrir Twittor sin ver cientos mensajes de odio. Empecé a temer por mi vida.',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: '¡Personas que no me conocían de nada no paraban de insultarme con cosas, cada vez más locas y terribles! Menos mal que has logrado detenerlo.',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Te garantizo que no volverás a verle el pelo a esos haters por un tiempo.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'La gente no debería ACOSAR A NADIE por redes sociales por envidia. Ni inventar FAKE NEWS o BULOS. Como el de que LOS GATOS TIENEN WIFI…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿LOS GATOS TIENEN WIFI…? ¿De verdad alguien se cree eso?',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Digamos que me han prohibido hacer una fiesta por culpa de <b>ese bulo</b>… Es una larga historia. Rosaura, ¿puedo hacer algo más por ti?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Pues ahora que lo dices… Ya sabes que estoy muy concienciada con el cambio climático. Me preocupa mucho la salud de nuestro planeta. Ya sabes, #NoHayPlanetaB.',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'El caso es que hay una negacionista del cambio climático… DIOXI DOXI, se llama. ¿La conoces?',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Por suerte, no.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Quiere construir una FÁBRICA PETROQUÍMICA en medio de una RESERVA NATURAL. ¡Un auténtico desastre ecológico!',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'La cuestión es que está lanzando MENTIRAS para convencer a la gente que su proyecto es bueno, y que <b>EL CALENTAMIENTO GLOBAL no existe</b>.',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: '¿Podrías ir y desmontar sus fakes? Te estaría MUY agradecida.',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'El año pasado tuve que usar el anorak en pleno agosto... Rosaura, cuenta con ello.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        },
        {
          // NEGACIONISMO CLIMÁTICO. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: 'Los ecologistas son unos CENIZOS. Todo les parece mal: los coches, los plásticos, el plutonio, la carne de vaca…',
              actor: ActorEnum.DIOXI_DOXI,
              side: 'left'
            },
            {
              text: '¡Todo! Y ahora van y se inventan lo del cambio climático. ¡Es todo un invento para asustarnos!',
              actor: ActorEnum.DIOXI_DOXI,
              side: 'left'
            },
            {
              text: '¿Un invento? Pero... pero... pero...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Existe un <b>AMPLIO CONSENSO CIENTÍFICO</b> en que los efectos del CAMBIO CLIMÁTICO son evidentes en nuestro día a día. Y la cosa va a peor. Si miras este informe...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: '¡Déjate de informes! Lo sé por un PRIMO que sabe mucho de esto.',
              actor: ActorEnum.DIOXI_DOXI,
              side: 'left'
            },
            {
              text: 'El primo como fuente de información… Me duele el corazón al oír esto.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Vamos mal si es como el mío, que dice que ha visto al yeti en Sierra Nevada. DOS veces.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Unos pocos árboles no pueden parar EL PROGRESO. ¿Estáis de parte de esos cavernícolas <b>abraza-árboles</b>? Yo sólo pienso en generar riqueza [PARA MÍ CLARO]',
              actor: ActorEnum.DIOXI_DOXI,
              side: 'left'
            },
            {
              text: 'No pienso dejar de ganar dinero sólo porque este diciembre haga un poquito de calor. ¡Cambio climático, JA!',
              actor: ActorEnum.DIOXI_DOXI,
              side: 'left'
            },
            {
              text: '¿Un "poquito" de calor? ¡Pero si Papa Noel ha venido este año con tanga y chancletas!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Es una manera de hablar jefa... ',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Agente F, DIOXI DOXI quiere conseguir suficientes firmas con sus mentiras para ARRASAR con una RESERVA NATURAL. Hay que detenerla.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Directora Data, ¿oye eso? ¿Sí? ¿Sabe lo que es? Es la llamada de la Naturaleza.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿Necesitas ir al baño? Vas a tener difícil encontrar uno por aquí cerca.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'No, a lo que me refiero es… Era una manera original de decir que voy a… En fin. Acabo con ella y vuelvo.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Parece que te has quedado "helado" con mi victoria. ¿Lo pillas? Helado... Cambio climático... Uhmm, veo que no estás de humor.',
              actor: ActorEnum.DIOXI_DOXI,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Tengo que darle un "cambio climático" a esto. Rosaura me necesita... y los ecologistas también.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: '¡Buen trabajo F! Has logrado parar el proyecto de Dioxi. La reserva natural está a salvo por ahora',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Gracias jefa. ¿Sabe algo de Rosaura?',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No te preocupes por eso ahora. Vete a casa a descansar, te lo has ganado',
              actor: ActorEnum.DATA_DIRECTOR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // COBERTURA FELINA
      id: '5cffd13a-f9c3-44a7-b90d-6ed1756aa10c',
      dialoguesConfiguration: [
        {
          // INTERCOMUNICADOR. Post Dioxi Doxi
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_b.png',
          dialogues: [
            {
              text: '¡Agente F, lo has logrado! ¡Has neutralizado todas las mentiras de Dioxi Doxi! ¡Ahora la gente no apoya su proyecto!',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Estoy impresionada. ¿Qué podría hacer para agradecértelo?',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Bueno…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Ponte un pin de la AFO!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right',
              animation: {
                // La directora Data, intermitentemente, irrumpe en la conversación ocupando el lugar del agente F.
                // A modo de interferencia. Luego se va y regresa F.
                interference: true
              }
            },
            {
              text: 'A mí lo que me gustaría es que…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡El pin de la AFO!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right',
              animation: {
                // La directora Data, intermitentemente, irrumpe en la conversación ocupando el lugar del agente F.
                // A modo de interferencia. Luego se va y regresa F.
                interference: true
              }
            },
            {
              text: '¿Qué ha sido eso?',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Si te parece bien, claro.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Usar un pin de la AFO!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right',
              animation: {
                // La directora Data, intermitentemente, irrumpe en la conversación ocupando el lugar del agente F.
                // A modo de interferencia. Luego se va y regresa F.
                interference: true
              }
            },
            {
              text: 'Eh… Bueno. Si quieres que lleve un pin de la AFO… Sin problema. En mi próximo concierto me lo pongo.',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Iba a proponerte ir a cantar a tu fiesta. En plan sorpresa, ¿sabes? Pero si prefieres lo otro… ¡OK!',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: '¡¿Qué?!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Nos vemos! ¡Y gracias una vez más! ¡A tope con la AFO!',
              actor: ActorEnum.ROSAURA,
              side: 'left'
            },
            {
              text: 'Es simpática, ¿verdad?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡¿Qué ha dicho de un pin?!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Se lo enviaré ahora mismo. Eso nos dará visibilidad.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Dire, está acabando con mi vida. Pero, ¿podemos centrarnos ya en desmentir lo de que LOS GATOS TIENEN WIFI? Se lo ruego.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right',
              animation: {
                // La directora Data se desconecta del intercomunicador y deja solo al agente F.
                invisible: true
              }
            },
            {
              text: 'Ains…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'De vuelta en casa...',
              actor: null
            }
          ]
        },
        {
          // DORMITORIO DEL AGENTE F.
          scene: TypeSceneEnum.STORY,
          background: 'bedroom_[GENDER].png',
          dialogues: [
            {
              text: 'Alfredo, no sé qué hacer. No sé cómo convencer a PAPÁ y a MAMÁ de que NO TIENES WIFI. Igual <b>si meto tu cola en un enchufe…</b>',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿?',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Así verían que se te chamuscan los bigotes. Como a cualquier bicho viviente, ¿no?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No lo creas. Mis bigotes son CONDUCTORES ELÉCTRICOS muy resistentes.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Sí, claro… Y mi pelo es- WTF!!! ¡¡¿HABLAS?!!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Miau.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Uf, qué susto…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '“Miau” es “sí” en gático.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: '¡¡¡AAAAAAHHHH!!!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No te asustes. Es fruto de los CAMPOS ELECTROMAGNÉTICOS.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'No solo nos han hecho capaces de conectarnos a Internet gracias a nuestro propio wifi. También nos han hecho MUY INTELIGENTES.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'NO PUEDE SER. Los gatos… no tienen… wifi.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Oh, miau. Desde luego. Y de buena calidad. ¿<b>La G del 5G a qué crees que hace referencia</b>?',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'A ge de… ¿gato?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Miau, miau, miau. Veo que no eres TAN tonto como pensaba. ¿Y por qué crees que LA RED ESTÁ REPLETA DE FOTOS NUESTRAS?',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Porque… ¿sois adorables?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Y porque somos muy buenos COMMUNITY MANAGERS.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Entonces, ¿es cierto que eres el único escollo que tengo para celebrar mi megafiesta?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No quería decirlo así, pero… <b>Miau</b>.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Alfredo, lo siento. Pero en este tema seré yo quien se lleve el gato al agua.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Ya está bien de juegos de palabras con "gato"...',
              actor: ActorEnum.CAT,
              side: 'left'
            }
          ]
        },
        {
          // LOS GATOS TIENEN WIFI. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'bedroom_empty.png',
          dialogues: [
            {
              text: 'No te perdonaré que usaras un pepino para asustarme.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Sabes que nosotros no los distinguimos a simple vista y nos parecen serpientes. Casi me da un infarto.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'Qué rancio… No se te puede hacer ninguna broma.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'A ti te sigue dando miedo la oscuridad y yo no te apago la lucecita del pasillo.',
              actor: ActorEnum.CAT,
              side: 'left'
            },
            {
              text: 'No es por miedo. Es por costumbre.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Miau…',
              actor: ActorEnum.CAT,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Quien maúlla el último, ríe mejor.',
              actor: ActorEnum.CAT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Lo que más me fastidia es que tenía razón en que se han hecho MUY INTELIGENTES.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No puedo volver a perder. No puedo volver a perder. No puedo volver a perder...',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: 'In your face, router peludo!!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Miau?',
              actor: ActorEnum.CAT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Ahora no te hagas el tonto. ¡Te has descubierto hace un rato!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Miau? ¿Miau?',
              actor: ActorEnum.CAT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡Venga habla! ¿No vas a contarme que GuGueL también se escribe con G de Gato?',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Marramiau?',
              actor: ActorEnum.CAT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡Oh Dios! Creo que este trabajo ha empezado a afectarme...',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // CONSPIRANOIA
      id: '61128ed3-642f-4bf7-8182-d5b3b5bae54d',
      dialoguesConfiguration: [
        {
          // DESPACHO DE LA AGENTE V - Post sueño con gato wifi
          scene: TypeSceneEnum.STORY,
          background: 'office_scene11_[GENDER].png',
          dialogues: [
            {
              text: 'Fue todo una PESADILLA. Una HORRIBLE PESADILLA en la que Alfredo hablaba y <b>RECONOCÍA TENER WIFI</b>.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: ' Decidme la verdad, chicas. ¿Estoy perdiendo la cabeza?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Oh, no, darling! De ninguna manera.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Oh, sí! Estás como un cencerro. Un gato parlanchín…',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡V!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Yo con lo único que sueño es con mi último día en la AFO. Eso sí que es <b>un sueño bonito</b>…',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Luego me despierto y me doy cuenta de que vivo en una pesadilla.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Tengo que acabar con esto chicas.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿Qué puedo hacer para que la Directora Data me deje INVESTIGAR este BULO? Necesito desmontarlo COMO SEA.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Está <i>ugly</i> el asunto, <i>my friend</i>. I’m going to send you el vídeo que me mandó Data por Guasáp el otro día. TOP SECRET.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            }
          ]
        },
        {
          // INTERCOMUNICADOR. - En esta pantalla se reproduce un vídeo con un mensaje de la directora Data.
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_a.png',
          dialogues: [
            {
              text: 'Agente W, esta comunicación es TOP SECRET. Sé que F está intentando averiguar de dónde procede la absurda teoría de que LOS GATOS TIENEN WIFI.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Y también SÉ que V le está ayudando. Están PERDIENDO EL TIEMPO. Os PROHÍBO tajantemente seguir esa línea de investigación.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'NO ES PRIORITARIA para la AFO. Tienes que ABORTAR cualquier otro intento de F o V de seguir investigando por su cuenta.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Informáme de cualquier cosa que hagan. Eso es todo.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          // DESPACHO DE LA AGENTE V. - Post video
          scene: TypeSceneEnum.STORY,
          background: 'office_scene11_[GENDER].png',
          dialogues: [
            {
              text: 'Ups. Asusta solo con la mirada. Y por eso no le dijiste nada a V de Odium.net…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'That’s it.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Qué raro es este asunto! ¿Y qué más le dará a Data que investiguemos ESTE BULO por nuestra cuenta?',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Told you. Es un tema que le interesa NOTHING.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Adiós a mi fiesta… [MIRANDO A V] Es una pena. Tenía pensado hacer CIENTOS de bocadillos de CHICHARRONES.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿Chicharrones? ¡Sujétame la horchata! He estado haciendo mis averiguaciones y he descubierto <b>ALGO</b> sobre ODIUM.NET.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡V! ¡¿Qué pasa si Data se entera?!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Para lo que me queda en el convento… ¡Ja! <b>ODIUM.NET</b> es una página web de creación muy reciente.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Sirve de <b>CONTENEDOR DE FAKE NEWS</b> de todo el mundo. Solo tienen <b>un bulo original</b>. El de los dichosos gatos.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Qué interesante! ¡Sigue!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'El único usuario que actualiza ese bulo es un tal <b>INFOLISTO</b>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'And do you know quién es él?',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Ni idea.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Entonces... solo tenemos que CERRAR ESA PÁGINA y se acabaron mis problemas. <b>¡Habrá fiesta!</b>',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Eso sería solo un parche. No serviría.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '<b>INFOLISTO</b> podría abrir a <i>new web</i> al <i>next day</i>.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Yes. Digo… sí. Maldita sea, todas las tonterías se pegan.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Tienes que encontrar a INFOLISTO. Así se acabarían tus problemas F',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Pero ¡¿cómo?! No sabemos quién es. ¿Me ayudarás, W? Tú eres la mejor…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No way! ¿Y desobedecer a la Directora Data? No has visto a Data enfadada, <i>darling</i>.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'No sabemos quién es InfoListo... todavía. Pero sí sé quién puede darnos MÁS PISTAS. KONSPIRATRÓN.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿El youtuber?',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Me suena haber visto alguno de sus challenges.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'KONSPIRATRÓN es el influencer que más publicidad le ha dado a esta teoría.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Tiene horas y horas de material en su canal de YouTube analizándola.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Y dice que tiene información de primera mano. Apuesto a que conoce a INFOLISTO.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Así que lo que debería hacer es…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '… ir a hablar right now with Data!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Si habla con Data no le dejará! ¡Tienes que tomar el toro por los cuernos! ¡Y usar el factor sorpresa! ¡Ese es mi consejo!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡V!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'W, ¿me guardarás el secreto? No se lo digas a Data. Please!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'OK… But mucho cuidado.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: 'Creo que me he ganado el pase VIP de esa fiesta tuya.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            }
          ]
        },
        {
          // LOS CONSPIRANOICOS. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'studio_konspiratron_scene18_[GENDER].png',
          dialogues: [
            {
              text: '¡Ey, ey, ey! KONSPIRATRÓN soy, y solo la verdad te doy.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: '¿Kién eres? ¿Uno de mis lokers? ¿Kieres un autógrafo? ¿En el brazo? ¿En la frente? ¿En una nalga? Tú decides.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'No, no, no… Muchas gracias. Me gustan mis <b>nalgas blancas</b> como un <b>huevo cocido</b>.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Vengo porque estoy interesado en la teoría de que LOS GATOS TIENEN WIFI.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Oyap! ¡Ké lokura! Esos mininos tienen AUTÉNTIKO PODER MENTAL. ¡Son PANTERAS de la Red!',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'Sí, sí... Lo que tú digas, "panteras". He oído que INFOLISTO es el gran descubridor <b>de este milagro de la evolución</b>. ¿Le conoces?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Oyap! Es un <b>GENIO</b>. Es mi mejor patrocinador. Todo kriptomonedas. ¿Y tú lo konoces?',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'No lo conozco. Pero me encantaría. Te aseguro que me "encantaría" tener unas palabritas con él.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'O igual sí ke lo konoces… <b>"Agente F"</b>.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: '¿Cómo sabes quién soy? No te he dicho mi nombre.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'De la AFO, ¿verdad? <b>AGENCIA DE FALSEDADEES OLÍMPIKAS</b>. Trabajáis para los poderosos. Para ke todo siga komo está.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'Desmentimos BULOS, FAKES NEWS y TEORÍAS CONSPIRATIVAS. Nos basamos en HECHOS, no en opiniones o suposiciones.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Los hechos pueden tener versiones alternativas Agente F... komo la mía',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'No, no pueden. Por definición <b>un hecho es una cosa que sucede y es</b>.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Oyap! ¿Y lo ke yo opino ké, loker? ¿No vale?',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'No. ¡Porque tu opinión no es un hecho! Puedes opinar que <b>LOS GATOS TIENEN WIFI</b>, como InfoListo, pero si no es verdad, ¡no es verdad!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Y deja de repetir ese BULO porque no me dejan celebrar mi fiesta POR VUESTRA CULPA!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'KONSPIRATRÓN no se kalla. KONSPIRATRÓN soy, y SOLO LA VERDAD DOY.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'Ya estamos con lemas... Lo que tú quieras. Pero dime DÓNDE ESTÁ INFOLISTO.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡Ja!',
              actor: ActorEnum.KONSPIRATRON,
              side: 'left'
            },
            {
              text: 'Que no se diga que al menos lo intenté por las buenas. Se acabaron las teorías de la conspiración en Yo-Tuve.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: '¡Tremenda paliza, loker! Vía libre para predikar la verdad.',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'A este paso voy a akabar kreyéndome que LOS GATOS TIENEN WIFI. ¡Dios, hasta hablo ya komo ese loker!',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
            lifePointsThreshold: 5
          },
          reactions: [
            {
              text: '¡¡¡Arghhh!!! ¡Me abro! Pero esto no akaba akí.',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡KONSPIRATRÓN no ha dicho todavía su última palabra! ¡Y ahora todo el mundo ha visto lo ke es la AFO!',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR,
              actions: [
                {
                  driver: 'leaveGame'
                }
              ]
            }
          ]
        }
      ],
      rules: []
    },
    {
      // INVASIÓN TROLL
      id: 'ab194a0a-249a-4602-a04d-1ad5f63c639f',
      dialoguesConfiguration: [
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER. - Post combate Konspiratrón
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene4_[GENDER].png',
          dialogues: [
            {
              text: '¡KONSPIRATRÓN dejó conectada su webcam! Ahora millones de personas han visto vuestra conversación. O lo que él ha querido que vean…',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Y, por si fuera poco, apareces hurgándote la nariz.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Tenía un moco como un coco. ¿Pero qué ha pasado?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Se ha VIRALIZADO un vídeo en el que sales diciendo que <b>LA OPINIÓN DE LA GENTE NO VALE NADA</b>.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡No! Dije que lo que importa son los HECHOS, no las opiniones. Y lo dije respecto a la teoría de que LOS GATOS TIENEN WIFI.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Directora, está claro que KONSPIRATRÓN ha viralizado un <i>SHALLOW FAKE</i>.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Que me han hecho un qué?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '<b>SHALLOW FAKE</b>. Consiste en manipular un vídeo utilizando un software de edición para hacer que parezca que dices algo diferente a lo que decías en el vídeo original.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Es una técnica básica de manipulación. Pero cualquiera que lo vea llegará a la conclusión de que la AFO es la mismísima Inquisición. En este caso, ¡relato mata dato!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Lo siento, dire. Lo lamento de verdad. Por favor, no me mire así. ¡Eh!, un momento. Sus ojos…',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¿Qué importan mis ojos ahora? En la era de las FAKE NEWS, <b>mucha gente creerá en lo que quiera creer</b>, por más que no sea verdad. Es triste. Pero es así.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Al menos le di una lección al pirado ese, ¿no?',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Fuiste a una misión sin avisar. Has puesto en riesgo a la agencia. Voy a proceder a tu expulsión de la AFO.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡¿Qué?!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Reglamento interno. Página 16. Ejecución de misiones. Párrafo 3, apartado B.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '“Ningún agente puede iniciar una misión sin el permiso de la dirección de la AFO”. O sea, yo.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Lo hice por el bien de la agencia! ¡KONSPIRATRÓN es un conspiranoico!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No. Has actuado para DESMENTIR UN BULO que te afecta PERSONALMENTE. Aquí neutralizamos mentiras que PERJUDICAN A TODA LA SOCIEDAD.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Agente F, deja tu placa antes de irte.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡Pero es injusto!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'No eres consciente todavía de los problemas que le traerá a la AFO tu fanfarronada…',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER. a - Invasión troll
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene4_[GENDER].png',
          dialogues: [
            {
              text: 'Jefa, tenemos problemas.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Una horda de troles CIEGOS DE ODIO están en la puerta de la AFO y quieren entrar para DESTRUIRLO todo.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Pero cómo se ha viralizado tan rápido?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Al parecer El Populetas ha aprovechado el vídeo de KONSPIRATRÓN para lanzar contra nosotros a todos sus seguidores.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Ya sabe. “Por la libertad de expresión” y blablablá. Puedo activar los altavoces e insultarles. Igual se marchan.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Never! Don’t feed the troll!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¿Quién lidera el tumulto? ¿El Populetas?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Sí. Aunque hay dos troles anónimos que son los que más alborotan.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'No. No puede ser. No. No. No. No.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            }
          ]
        },
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER. b - Invasión troll
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene13_[GENDER].png',
          dialogues: [
            {
              text: '¿Qué ocurre F? ¡Parece que hubieras visto al mismísimo diablo!',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Peor! ¡¡¡Son mis padres!!!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: '¡¿No te da vergüenza?!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: '¡¡¡Muchísima!!! ¡Han vuelto a salir de casa en pijama!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Tú y tu familia me estáis dando muchos dolores de cabeza, ¿sabes?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Dire, yo me encargo. Le prometo que haré que entren en razón.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Tú ya no perteneces a la AFO.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Dire, por favor. Son mis padres. Déjeme ir en esta última misión.',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'Está bien. Pero que no descubran tu identidad.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          // LOS TROLES. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: 'Destruiremos la Agencia Fakes Over antes de cenar.',
              actor: ActorEnum.TROLL_PARENTS,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Aprende la lección. Quien ofende a uno de nosotros, nos ofende a todos.',
              actor: ActorEnum.TROLL_PARENTS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Por qué no podré tener unos padres normales como el resto del mundo?',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: '¿Qué es lo que pasa? ¿Dónde estamos? ¿Qué hacemos aquí?',
              actor: ActorEnum.TROLL_PARENTS,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Papá. Mamá. Id a casa, os lo explicaré luego. Ahora tengo que saldar cuentas con un viejo conocido',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // MENUDA TRETA LA DEL POPULETAS
      id: 'ef3149f9-a904-4ee6-a14e-446b7cb54434',
      dialoguesConfiguration: [
        {
          // EL POPULISMO 2. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: 'Nos volvemos a encontrar.',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            },
            {
              text: 'Esta vez te has pasado de la raya. Estás MANIPULANDO a GENTE INOCENTE para cometer ACTOS VIOLENTOS. ¡Detén esta locura!',
              actor: ActorEnum.AGENT_F,
              side: 'right'
            },
            {
              text: 'La AFO no me robará la victoria otra vez. Prepárate para morder el polvo.',
              actor: ActorEnum.POPULETAS,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'No costaba tanto trabajo no llevarme nunca la contraria.',
              actor: ActorEnum.POPULETAS
            }
          ]
        }
      ],
      rules: []
    },
    {
      // ¡A POR LOS CIBERDATOS!
      id: 'c63a2bf0-afeb-47e4-a097-6a1d8cbbc068',
      dialoguesConfiguration: [
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'De vuelta en casa...',
              actor: null
            }
          ]
        },
        {
          // EL POPULISMO 2. COMBATE.
          scene: TypeSceneEnum.STORY,
          background: 'living_scene14_[GENDER].png',
          dialogues: [
            {
              text: 'Nos atacaron.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¿Decís que los agentes de la AFO os atacaron?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Pasábamos por allí y se nos echaron encima.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Aham... Íbais paseando tranquilamente, y al llegar a la puerta de la Agencia Fakes Over… os atacaron por sorpresa.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Así fue!! ¿No crees a tu madre?',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¿Un agente o varios?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Muchos, muchos, muchos.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Muchos… ¿Y qué hacíais por allí?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Fuimos porque lo pidió El Populetas. ¿Va a cambiar este país, sabes? Una manifestación pacífica en apoyo de KONSPIRATRÓN.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Y para PROTEGER LA LIBERTAD DE EXPRESIÓN, mamá. No lo olvides.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¡¿Fuisteis porque un populista os pidió que defendierais a un youtuber conspiranoico?! Es muy fuerte…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Yo fui más por lo de <b>la libertad de expresión</b>. He oído que la Agencia esa quiere poner MICRÓFONOS en las CASAS para ESPIAR todo lo que decimos.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Tonterías… V mataría al que se atreviera a proponer algo así. Supondría demasiado trabajo.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¿Cómo dices?',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Nada, nada.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Mientras esa Agencia siga abierta, la libertad de expresión y la privacidad de nuestras conversaciones están en peligro. Como el pingüino emperador.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¡Anda! ¡Mira, papá! En Instogram me salen vídeos de pingüinos emperadores. Qué casualidad, ¿verdad? A veces pienso que nos escuchan…',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¡¿Por qué mamá tiene internet en su móvil?!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Tuvimos que dejar una línea activa. InfoListo va a hacer en los próximos días un comunicado importante.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Nos va a desvelar cuál es la razón oculta por la que los gatos usan su wifi para conectarse a Internet.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Pero no hablemos muy fuerte… Alfredo no debe oírnos…',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¿Miau?',
              actor: ActorEnum.CAT,
              side: 'right'
            },
            {
              text: 'Se acabó. Si sigo un minuto más en esta casa enloqueceré. Papá, mamá, necesito pasta. Voy a buscarme otro lugar donde vivir. <b>¡Me independizo!</b>',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Eh… ¿Dinero? Jejeje. ¿Del normal y corriente?',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Papá… ¿qué pasa?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Nada, parece que tu madre y yo hemos invertido todos nuestros ahorros en… ¿Cómo se llamaba, mamá?',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Criptoactivos.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Eso mismo.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¿Cómo que “parece”?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Sí, nos llegó un email de ODIUM.NET. Lo abrimos. Pinchamos el enlace… Nos pidió nuestra contraseña.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Y ahora nuestro dinero <b>no está</b>.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Pero ahora tenemos certificados de propiedad de NFTs. ¡El futuro!',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Yo no sé todavía lo que es un NFT de esos, pero los que saben dicen que hay que tener muchos.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¿Qué? Te has quedado con la boca abierta con los modernos de tus padres, ¿a que sí?',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Mia…u.',
              actor: ActorEnum.CAT,
              side: 'right'
            }
          ]
        },
        {
          // INTERCOMUNICADOR. - CIBERESTAFAS
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_b.png',
          dialogues: [
            {
              text: 'V, ¿has visto el correo que te he mandado?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Sí…',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'ODIUM.NET ha desplumado a mis padres y a saber a cuántos más. Necesito que me ayudes a encontrar quién ha sido.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '[HABLANDO EN SUSURROS] No puedo ayudarte, [PLAYER]. Ya no eres miembro de la AFO.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'V…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Qué demonios, me quedan dos telediarios aquí…',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¡Gracias!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Esta estafa es obra de la PIRATA DORA. La mejor cibercriminal. Te mando su ubicación. Quizás ella sepa quién es InfoListo.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Ten cuidado. No te dejes encandilar por su apariencia inofensiva. Nada de lo que hace Dora es inofensivo.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'V, ¡eres la mejor! ¿Te gustan los callos?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Lo que más.',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: 'Date por invitada a unos buenos callos.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '',
              actor: ActorEnum.PLAYER,
              side: 'right',
              animation: {
                // JUGADOR se desconecta del intercomunicador.
                invisible: true
              }
            },
            {
              text: 'Me estoy haciendo vieja. Creo que le he cogido cariño…',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            }
          ]
        },
        {
          // LAS CIBERESTAFAS. COMBATE.
          scene: TypeSceneEnum.STORY,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡Ay, me has asustado! Soy Dora. ¿Quién eres tú?',
              actor: ActorEnum.DORA_PIRAT,
              side: 'left'
            },
            {
              text: 'Soy… De la Agencia Fakes Over. Eres… ¿una niña?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Soy mayor. Tengo 5 para 6. ¿Quieres ver el último gif que he hecho?',
              actor: ActorEnum.DORA_PIRAT,
              side: 'left'
            }
          ]
        },
        {
          scene: TypeSceneEnum.ANIMATION,
          dialogues: [
            {
              animation: 'gatito',
              actor: null,
              text: null
            }
          ]
        },
        {
          // LAS CIBERESTAFAS. COMBATE 2.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: '¡¿Qué ha pasado?!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '¡Picaste! Ahora algunas propiedades de tu control de mandos están bajo mi poder.',
              actor: ActorEnum.DORA_PIRAT,
              side: 'left'
            },
            {
              text: 'Si quieres luchar contra mí, no podrás cambiar tus cartas. Tendrás que jugar con las que te toquen.',
              actor: ActorEnum.DORA_PIRAT,
              side: 'left'
            },
            {
              text: '¿Una piruleta?',
              actor: ActorEnum.DORA_PIRAT,
              side: 'left'
            },
            {
              text: 'Maldita cría… Me has engañado.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Un poco. Estás hablando con la inventora del phishing, vishing, smishing, spoofing y la canción del ‘Baby shark’.',
              actor: ActorEnum.DORA_PIRAT,
              side: 'left'
            },
            {
              text: 'Muy pronto tendrás que añadirle otra línea a tu currículum. La de pedir perdoning.',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: '...',
              actor: ActorEnum.DORA_PIRAT,
              side: 'left'
            },
            {
              text: 'Lo sé. Igual no ha sido mi respuesta más brillante...',
              actor: ActorEnum.PLAYER,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Baby Shark, doo-doo, doo-doo, Baby Shark, doo-doo, doo-doo…',
              actor: ActorEnum.DORA_PIRAT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Baby Shark, doo-doo, doo-doo, Baby Shark, doo-doo, doo-doo…',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Hasta me ha pegado la canción!!! Esto no puede quedar así. Tengo que volver a intentarlo!',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: '¡Quiero la revancha! ¡No es justo, no es justo, no es justo!',
              actor: ActorEnum.DORA_PIRAT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Dora, termina con el berrinche. Has perdido.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'He recuperado el dinero de mis padres y enviaré todos tus discos duros a la AFO para que devuelvan el dinero al resto de pobres incautos a los que has timado.',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'InfoListo va a estar de muy mal humor…',
              actor: ActorEnum.DORA_PIRAT,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Le conoces? Si me dices quién es no le diré nada a tus padres. Te lo prometo.',
              actor: ActorEnum.AGENT_F
            },
            {
              text: 'Uhmm... te creo ... ¿Has oído eso de que “LOS GATOS TIENEN WIFI”?.',
              actor: ActorEnum.DORA_PIRAT
            },
            {
              text: '¿Tú también? No empecemos con eso, POR FAVOR.',
              actor: ActorEnum.AGENT_F
            },
            {
              text: 'Escúchame. Todo lo que necesitas saber está ahí. <b>“LOS GATOS TIENEN WIFI”</b>. Es un mensaje encriptado. Yo le ayudé a diseñarlo.',
              actor: ActorEnum.DORA_PIRAT
            },
            {
              text: 'Aunque igual llegas tarde. Su plan está a punto de finalizar.',
              actor: ActorEnum.DORA_PIRAT
            }
          ]
        }
      ],
      rules: []
    },
    {
      // FIESTA O GATO: ESA ES LA CUESTIÓN
      id: 'fbfd6ce2-687c-4985-931c-b7d91fcba234',
      dialoguesConfiguration: [
        {
          // MENSAJE DE INFOLISTO.
          scene: TypeSceneEnum.STORY,
          background: 'infolisto_scene16.png',
          dialogues: [
            {
              text: 'Los gatos utilizan Internet para estudiarnos. Están aprendiendo sobre nosotros a gran velocidad. Nuestro idioma, nuestra cultura…',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: 'Incluso, han aprendido a usar las REDES SOCIALES para manipular nuestras emociones. He descubierto que DETRÁS DE CADA PERFIL FALSO, HAY UN GATO.',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: 'Gatos que se esconden para INSULTAR o BURLARSE de cualquier humano que interactúe con ellos.',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: 'No son troles. Son gatos.',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: '¿Por qué? Porque su propósito es generar odio entre nosotros para que nos autodestruyamos.',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: 'Quieren terminar de una vez por todas con el dominio del humano sobre el gato.',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: 'El <b>GRAN REEMPLAZO FELINO</b> ya está en marcha. Y yo os pregunto… ¿Nos vamos a quedar de brazos cruzados?',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: '¡Yo digo NO! Tenemos que ANIQUILAR a los gatos antes de que ellos nos aniquilen a nosotros.',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            },
            {
              text: 'La gran pregunta es. ¿Cuento contigo?',
              actor: ActorEnum.INFOLISTO,
              side: 'left'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'Unos pocos minutos después...',
              actor: null
            }
          ]
        },
        {
          // SALÓN CASA FAMILIAR. - PAPÁ y MAMÁ están viendo televisión apaciblemente. Ni rastro de Alfredo.
          scene: TypeSceneEnum.STORY,
          background: 'living_scene17.png',
          dialogues: [
            {
              text: '¡Ya estoy en casa!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Hola, [PLAYER]. Mamá y yo tenemos una buena noticia para ti.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¡Tienes permiso para celebrar MAÑANA tu gran FIESTA!',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¿Y eso? ¿Qué pasa con Alfredo?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Alfredo ya no está.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Se ha vuelto peligroso. Quería reemplazarnos.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: 'Me estáis asustando. Mamá. Papá. ¿Dónde está Alfredo?',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Nos hemos deshecho de él. Obvio.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¡¿HABÉIS MATADO A MI GATO?!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'No, no… Ni hablar. Nosotros no haríamos eso.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: 'Uf, qué susto…',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Lo hemos llevado al estudio de KONSPIRATRÓN. Él hará lo que tenga que hacer con él.',
              actor: ActorEnum.MOM,
              side: 'left'
            },
            {
              text: '¡¿LE HABÉIS ENTREGADO MI GATO A ESE PIRADO?!',
              actor: ActorEnum.PLAYER,
              side: 'right'
            },
            {
              text: 'Cariño, relájate. Es lo mejor para la supervivencia de esta familia.',
              actor: ActorEnum.DAD,
              side: 'left'
            },
            {
              text: '¿De qué te quejas? Ahora podrás celebrar tu fiesta. Es lo que siempre has querido, ¿no?',
              actor: ActorEnum.MOM,
              side: 'left'
            }
          ]
        },
        {
          // CONCIENCIA. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'bedroom_empty.png',
          dialogues: [
            {
              text: '¡Por fin podremos hacer nuestra fiesta! ¡No habrá nadie más guay en el barrio!',
              actor: ActorEnum.AGENT_F_F_EVIL,
              side: 'right'
            },
            {
              text: 'Sí, ¿pero y Alfredo? Está indefenso en manos del zumbado de KONSPIRATRÓN.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Se las apañará.',
              actor: ActorEnum.AGENT_F_F_EVIL,
              side: 'right'
            },
            {
              text: '¡Alfredo no sabe ni pestañear sin ayuda!',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Si vamos a por Alfredo, olvídate de la fiesta. ¡Tampoco le queríamos tanto!',
              actor: ActorEnum.AGENT_F_F_EVIL,
              side: 'right'
            },
            {
              text: 'Habla por ti, psicópata.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'El otro día lo echaste de la cama.',
              actor: ActorEnum.AGENT_F_F_EVIL,
              side: 'right'
            },
            {
              text: 'Estamos en verano y da mucho calor.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Bien. Si ganas tú, vamos a por Alfredo. Y si gano yo, ¡fiesta! ¿Trato?',
              actor: ActorEnum.AGENT_F_F_EVIL,
              side: 'right'
            },
            {
              text: 'Trato.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Prepara el bañador y avisa a todos por el grupo de Guasáp. ¡¡¡Mañana hay fiesta!!!',
              actor: ActorEnum.PLAYER
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: 'La fiesta puede esperar. ¡Tengo que salvar a Alfredo!',
              actor: ActorEnum.AGENT_F
            }
          ]
        }
      ],
      rules: []
    },
    {
      // ¡SALVA AL GATO!
      id: 'c05db286-893f-4b90-aa02-bb39eaee362c',
      dialoguesConfiguration: [
        {
          // EL ESTUDIO DE KONSPIRATRÓN.
          scene: TypeSceneEnum.STORY,
          background: 'studio_konspiratron_scene18_empty.png',
          dialogues: [
            {
              text: '¡Ey, ey, ey! KONSPIRATRÓN soy y hoy… haremos historia. Retransmito en direkto desde el lugar kon mejor konexión wifi del planeta.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'right'
            },
            {
              text: '¡Sé lo ke estáis pensando! Estoy en peligro mortal rodeado de tanto gato con campo elektromagnétiko. Puede ser. Pero alguien tenía ke korrer este riesgo.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'right'
            },
            {
              text: 'Hoy exterminaré a todos estos mininos para salvar a la humanidad del GRAN REEMPLAZO FELINO. Y lo haré delante de todos vosotros.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'right'
            },
            {
              text: 'Kon el patrocinio de Odium.net.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'right'
            },
            {
              text: '¡Si tocas uno solo de esos bigotes, te aseguro que no vivirás para probar el próximo filtro de Instogram!',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¿¡Tú otra vez!?',
              actor: ActorEnum.KONSPIRATRON,
              side: 'right'
            },
            {
              text: 'Si no quieres problemas, sigue este sencillo tutorial. Levanta el culo de tu confortable silla de gamer y sal del estudio echando leches.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¡Traicionas a tu especie!',
              actor: ActorEnum.KONSPIRATRON,
              side: 'right'
            },
            {
              text: 'Estás zumbado.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Tengo un enkargo de InfoListo. ¡Una misión para salvar la humanidad! Y no le fallaré.',
              actor: ActorEnum.KONSPIRATRON,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'Tu derrota la han visto millones de lokers en STREAMING.',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No pienso dejar que este conspiranoico le haga algo a Alfredo.',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡Tiene que haber alguna manera de ganar!',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: 'Nunka pensé ke KONSPIRATRÓN akabaría así… Destronado de Yo-Tuve. Tendré ke konfomarme kon hacer un pódkast…',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'KONSPIRATRÓN, ¿dónde está mi gato Alfredo? No lo veo por ninguna parte.',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¿Te refieres a un gato kon kara de rancio?',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡Ese!',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Se lo kedó InfoListo. Pensó ke podría serle útil. Oh… ¿Y esa kara? Vaya, vaya… aún no sabes todavía kién es InfoListo… ¡JAJAJAJA!',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Dímelo. Por favor. Necesito recuperar a mi gato.',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '¡¿Por kién me tomas?! ¡¿Por un chivato?!',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'O me lo dices o te korto ALGO que te va a doler MUCHO...',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'No, el WIFI no!!',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Estaba pensando en otra cosa pero... SÍ, komo no me lo digas te korto el WIFI loker!!!',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Loker, escúchame!! La klave la tienes delante de tus narices. Escucha ATENTAMENTE. “LOS GATOS TIENEN WIFI".',
              actor: ActorEnum.KONSPIRATRON,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: '“LOS GATOS TIENEN WIFI”... Un mensaje encriptado. (...) WTF!! INFOLISTO ... INFOLISTO ¡¡¡Está en la AFO!!!',
              actor: ActorEnum.PLAYER,
              type: ActorTypeEnum.AVATAR
            }
          ]
        }
      ],
      rules: []
    },
    {
      // LOS GATOS NO TIENEN WIFI
      id: 'c21b36a2-ee8f-4c21-b68a-9c9202d33fc1',
      dialoguesConfiguration: [
        {
          // INTERCOMUNICADOR.
          scene: TypeSceneEnum.STORY,
          background: 'intercomunicator_scene12_b.png',
          dialogues: [
            {
              text: '¿Diga…?',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: '¡V, te necesito!',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¿Otra vez tú? Ya te he dicho que no puedo ayudarte. Y menos usando este canal de comunicación privada de la AFO.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Am…',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Desembucha.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: '¡Voy camino de la Agencia! ¡Necesito que me dejes la puerta abierta y que reúnas a TODOS en el hall! INFOLISTO ESTARÁ ENTRE VOSOTROS.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¡¿Trabaja aquí?!',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Sí, y tiene secuestrado a mi gato.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Así que ese ronroneo que oía no era un fallo de mis sonotones…',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'V, no puedo seguir hablando. ¡Reúnelos a todos!',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¿Y qué quieres que haga?',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'No sé. Invéntate algo. DISTRÁELES mientras llego.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            }
          ]
        },
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER.
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene19.png',
          dialogues: [
            {
              text: '¡Y ahora con tres pelotas! El truco está en mirar siempre arriba. ¿Veis?',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Wow! Amazing!',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Agente V, no nos habrá reunido aquí para demostrarnos sus dotes malabares, ¿verdad?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'Cada minuto que estamos parados sin hacer nada cientos de nuevas FAKE NEWS se quedan sin verificar.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            },
            {
              text: 'También sé escupir fuego. Voy por un mechero y por la botella de orujo que tengo en mi despacho… Ya veréis.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Yes, please! ¡Me encanta el circo!',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: '¡Se acabó el recreo! ¡Que todo el mundo vuelva a su puesto de inmediato!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'left'
            }
          ]
        },
        {
          // HALL PRINCIPAL AGENCIA FAKES OVER. - con jugador
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene19_[GENDER].png',
          dialogues: [
            {
              text: '¡Escuchad todos! ¡La AFO está en peligro!',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Menos mal que has aparecido… Lo siguiente era el numerito con el sable.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Lo siento, V. Tenía el abono de transporte caducado.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'F, you’re here! ¡Qué feliz de volver a verte!',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '[PLAYER], creo que dejé muy claro que ya NO ERES UN AGENTE de la AFO. No puedes estar aquí. Márchate. Tenemos mucho trabajo.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: '¡Espere! Tengo DATOS CLAVE sobre la teoría de que <b>LOS GATOS TIENEN WIFI</b>.',
              actor: ActorEnum.AGENT_W,
              side: 'left'
            },
            {
              text: '¡Jolín! Esto se pone interesante y yo tengo que ir al baño.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: '¿Cuántas veces tengo que decirte que mientras yo sea la directora de esta agencia no vamos a verificar esa TEORÍA DE PARVULARIO?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: '¿Por qué, directora? Será porque quizás usted… ¡Ya sabe quién es InfoListo! ¡Aham!',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'No digas tonterías.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Conteste. ¿Sabe o no sabe quién es InfoListo?',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¡No!',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: '¿Ah, no? Vaya… Yo pensaba que sí.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'No tengo ni la más remota idea.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Le creo, le creo… Pero, ¿y si le dijera que InfoListo trabaja aquí, en la AFO?',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Tengo que aguantarme el pipí como sea.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Esa acusación es MUY GRAVE hacia nuestros agentes, [PLAYER]. Espero que tengas DATOS que lo demuestren.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Al principio pensé que InfoListo era usted. Siempre se negó a desmentir esa teoría. Incluso le mandó un vídeo a la agente W para que me impidiera investigar.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¿Qué dices? Eso es falso.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: '[PLAYER], o vas más rápido o me lo hago encima.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'EXACTO. Es falso. Usted no mandó ningún vídeo. Me di cuenta el día que me expulsó. Sus ojos eran... diferentes a los del vídeo. El vídeo que vi era un logrado deepfake.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'El vídeo que vi era un logrado DEEPFAKE.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¿Alguien ha usurpado mi identidad?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Así es. Y lo hizo para mantener vivo ese bulo. La persona con más capacidad para crear ese DEEPFAKE es V ...',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Yo solo quiero ir al baño... ¿Por qué miráis todos para mí?',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: '... pero eso implicaría hacer horas extras. Así que podemos descartar a V también como sospechosa.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Por último, está la pista que me dio la Pirata Dora: “LOS GATOS TIENEN WIFI” ES UN MENSAJE ENCRIPTADO...',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '... si reordenamos las letras de la teoría obtenemos el nombre del agente de la Agencia Fakes Over que encarna a InfoListo.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Ups... Me he meao. ¿Alguien tiene una fregona?',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            }
          ]
        },
        {
          scene: TypeSceneEnum.ANIMATION,
          dialogues: [
            {
              animation: 'info_listo',
              actor: null,
              text: null
            }
          ]
        },
        {
          // LOS GATOS TIENE WIFI INFOLISTO IS AGENTE W
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene19_[GENDER].png',
          dialogues: [
            {
              text: '¿¿W?? No puede ser...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Jefa, analicemos los DATOS. El anagrama está en espanglish.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Punto uno. El anagrama está en espanglish. W es la única que habla así.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Pim-pollo tiene razón en eso...',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Punto dos. En cuanto llegué a la AFO, W me llevó a misiones que estaban muy por encima de mi nivel. Es probable que tratase de acabar conmigo.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Es cierto. Eras más torpe que un gusano con guantes. No deberías haber ido',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Punto 3. Cuando fui por sorpresa al estudio de KONSPIRATRÓN… él ya me estaba esperando. Alguien le había avisado',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¿Fuiste tú, verdad?',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Wow! Estoy impresionada.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Y le envié a <i>your parents</i> un book de la DOCTORA NEGANA. Just for fun.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: '¿Pero por qué inventaste un bulo así? Tú, que eres LA MEJOR de nuestros agentes…',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Estoy harta de ser <i>the best agent</i>. I want to be the <b>AFO’s directora</b>! Y para lograrlo tenías que cometer UN ERROR, Data.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Solo necesitaba un error. Pero me encontré con que no sueles equivocarte.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Atended todos. No suelo equivocarme.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'Pero tienes <b>un punto débil</b>... Sabía que no le prestarías atención a una theory tan absurda como la de los gatos. Tu desinterés pondría en peligro a <i>thousands of cats</i>.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Unos animales por los que la gente <i>are crazy</i>… Y cuando miles de gatos murieran sin que la AFO hubiera movido <i>a finger</i> para impedirlo, rodarían cabezas.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Bye bye, Data; Hello, W.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Pero llegué yo y te fastidié el plan.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Damm yes… You and your silly party!',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: '¿Dónde está Alfredo?',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Here, in the AFO.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            }
          ]
        },
        {
          // LOS GATOS TIENE WIFI INFOLISTO IS AGENTE W - La imagen de ALFREDO aparece en las pantallas de todo el hall.
          scene: TypeSceneEnum.STORY,
          background: 'hall_afo_scene20_[GENDER].png',
          dialogues: [
            {
              text: 'Uhmm... creo que ese es mi despacho...',
              actor: ActorEnum.AGENT_V,
              side: 'left'
            },
            {
              text: '¿Y no te ha extrañado que hubiera <b>UN GATO GORDO AMORDAZADO</b> sentado a tu lado?',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: 'No lo he visto. Llevo todo el día en la cafetería.',
              actor: ActorEnum.AGENT_V,
              side: 'right'
            },
            {
              text: 'Prfff...',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            },
            {
              text: '¡¡W, devuélveme a Alfredo de inmediato!!',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: '¡Ja! Para eso tendrás que derrotar a "the best agent". Spoiler. No podrás.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Directora Data, solicito autorización para darle su merecido a esta gaticida sin corazón.',
              actor: ActorEnum.PLAYER,
              side: 'left'
            },
            {
              text: 'Autorización concedida, <b>AGENTE F</b>.',
              actor: ActorEnum.DATA_DIRECTOR,
              side: 'right'
            }
          ]
        },
        {
          // LA BATALLA FINAL. COMBATE.
          scene: TypeSceneEnum.START_GAME,
          background: 'mission_dialog.png',
          dialogues: [
            {
              text: 'Todavía no he dicho <i>my last word</i>.',
              actor: ActorEnum.AGENT_W,
              side: 'right'
            },
            {
              text: 'Pronto lo harás.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            }
          ]
        },
        {
          // LA FIESTA.
          scene: TypeSceneEnum.END_GAME,
          background: 'party_scene18_[GENDER].png',
          dialogues: [
            {
              text: 'Después de derrotar a INFOLISTO -bueno, a la agente W con hombreras-, la directora Data CUMPLIÓ SU PALABRA.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: 'La Agencia Fakes Over publicó un largo FACT-CHECK en el que se desmentía CON DATOS y RIGOR CIENTÍFICO la teoría de que LOS GATOS TIENEN WIFI.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: 'Como ella siempre dice: “Dato mata relato”. Y cuando mis padres leyeron dicho artículo, aceptaron su error y cumplieron la promesa que me hicieron.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: '¡Así que pude celebrar en el jardín de casa la fiesta de mis sueños! Aunque, reconozco, que al final se me fue un pelín de las manos…',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: 'Estuve pensando si después de lograr mi objetivo merecía la pena seguir como agente de la AFO. Y la verdad es que sí. Creo que he descubierto mi vocación.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: '<b>CERRARLE LA BOCA a todos aquellos que se dedican a INVENTAR BULOS, FAKE NEWS y TEORÍAS CONSPIRATIVAS</b>.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: 'Y, por desgracia, el mundo está lleno de gente interesada en hacernos dudar y que no conozcamos la verdad de los hechos. <b>YO NO QUIERO VIVIER EN UN MUNDO ASÍ</b>.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            },
            {
              text: 'Quiero vivir en un mundo donde nadie se pierda una buena fiesta por culpa de la desinformación.',
              actor: ActorEnum.AGENT_F,
              side: 'left'
            }
          ]
        },
        {
          scene: TypeSceneEnum.CURTAIN,
          dialogues: [
            {
              text: 'A muchos kilómetros sobre la casa de [PLAYER]...',
              actor: null
            }
          ]
        },
        {
          // UNA NAVE ESPACIAL.
          scene: TypeSceneEnum.END_GAME,
          background: 'space_scene23.png',
          dialogues: [
            {
              text: 'Esta vez casi descubren que estamos entre ellos…',
              actor: ActorEnum.MININOR,
              side: 'left'
            },
            {
              text: '¿Los humanos…? Jijijís. ¡Qué dices! Levantamos tres pirámides y todavía no saben que fuimos nosotros.',
              actor: ActorEnum.FELINOR,
              side: 'right'
            },
            {
              text: 'Rorororororo… Son cortitos los humanos. Mira que creer que los gatos tenemos wifi…',
              actor: ActorEnum.MININOR,
              side: 'left'
            },
            {
              text: 'La verdad es que no me importaría llevar wifi incorporado.',
              actor: ActorEnum.FELINOR,
              side: 'right'
            },
            {
              text: 'Podríamos inventar algo.',
              actor: ActorEnum.MININOR,
              side: 'left'
            },
            {
              text: 'Miau!!',
              actor: ActorEnum.FELINOR,
              side: 'right'
            }
          ]
        }
      ],
      reactionsConfiguration: [
        {
          trigger: {
            event: ReactionEventEnum.OPPONENT_WIN
          },
          reactions: [
            {
              text: 'The party is over para ti.',
              actor: ActorEnum.AGENT_W
            }
          ]
        },
        {
          trigger: {
            event: ReactionEventEnum.PLAYER_WIN
          },
          reactions: [
            {
              text: 'Oh, no… It’s the end of the story. Un maravilloso plan que ha terminado en cat-ástrofe.',
              actor: ActorEnum.AGENT_W,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Dire, aquí tiene a INFOLISTO. Yo voy a ir a liberar a Alfredo. Aunque sea un gato… debe de estar de un humor de perros.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Felicidades, Agente F. Has desmantelado Odium.net y salvado la Agencia Fakes Over. Buen trabajo!!.',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Creo que podríamos desmentir oficialmente el bulo de los gatos, ¿verdad, directora? ¿verdad? ¿verdad?',
              actor: ActorEnum.AGENT_V,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Ainnss… Así es. La AFO va a desmontar por todos los medios la teoría de que los gatos tienen wifi. Ningún otro felino del planeta volverá a correr peligro.',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Me ha emocionado, dire… ¿Puedo darle un brazo?',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            },
            {
              text: 'Ni se te ocurra.',
              actor: ActorEnum.DATA_DIRECTOR
            },
            {
              text: 'Vale.',
              actor: ActorEnum.AGENT_F,
              type: ActorTypeEnum.AVATAR
            }
          ]
        }
      ],
      rules: []
    }
  ]
};
