import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import useUnits from 'rxn-units';
import ButtonShape from '../../../../commons/components/ButtonShape/ButtonShape';
import adjust from '../../../../commons/styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../../../commons/utils';
import LoginFormLogos from '../LoginFormLogos';

interface DateErrorProps {
  error: string;
  setError: Function;
  backStep: Function;
}

const DateError = ({ error, setError, backStep }: DateErrorProps) => {
  const { vh } = useUnits();

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    },
    buttonErrorText: {
      color: '#40FFC8'
    },
    buttonsError: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      flex: 1
    },
    title: {
      fontSize: isNativeOrPWA ? adjust(16) : 28,
      fontWeight: '600',
      width: '100%',
      maxWidth: 500,
      textAlign: 'center',
      marginTop: isNativeOrPWA ? vh(7) : vh(9),
      marginBottom: isNativeOrPWA ? vh(2) : vh(2.5),
      color: '#FF6F77'
    },
    subtitle: {
      fontSize: isNativeOrPWA ? adjust(14) : 24,
      fontWeight: '400',
      width: '100%',
      maxWidth: isNativeOrPWA ? 235 : 420,
      textAlign: 'center',
      color: '#FF6F77'
    },
    innerWidth: {
      width: !isNativeMobile ? '100%' : 'auto'
    },
    buttonSignInText: {
      flexGrow: isNativeOrPWA ? 0 : 1,
      flexShrink: 0,
      fontSize: isNativeOrPWA ? adjust(16) : adjust(22),
      fontWeight: '600',
      color: '#fff'
    },
    buttonInner: {
      paddingHorizontal: 1,
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 5,
      maxWidth: !isNativeOrPWA ? 280 : 180,
      marginHorizontal: 'auto'
    }
  });
  return (
    <View style={styles.container}>
      <Text style={styles.title}>¡Oops!</Text>
      <Text style={styles.subtitle}>{error}</Text>
      <View style={styles.buttonsError}>
        <TouchableHighlight
          style={styles.innerWidth}
          accessible
          underlayColor={'transparent'}
          accessibilityLabel={'Exit'}
          onPress={() => backStep()}
        >
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={[styles.buttonErrorText, styles.buttonSignInText]}>Salir</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableHighlight>
        <TouchableHighlight
          accessible
          underlayColor={'transparent'}
          accessibilityLabel={'Mistake'}
          style={styles.innerWidth}
          onPress={() => setError('')}
        >
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={[styles.buttonErrorText, styles.buttonSignInText]}>Me he equivocado</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableHighlight>
      </View>
      <LoginFormLogos />
    </View>
  );
};

export default DateError;
