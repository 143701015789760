import { useEffect, useRef } from 'react';
import { Animated, Platform } from 'react-native';

interface useHighlightProps {
  animated: boolean;
  item: string | number;
}

export function useHighlight({ animated, item }: useHighlightProps) {
  const firstRender = useRef(true);
  const animationRef = useRef<Animated.Value>(new Animated.Value(1)).current;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!animated || firstRender.current) return;
    const animation = Animated.timing(animationRef, {
      toValue: 1.2,
      useNativeDriver: Platform.OS !== 'web',
      duration: 200,
      delay: 500
    });
    animation.start();
  }, [animated, item]);

  return animationRef;
}
