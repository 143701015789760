import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { View } from '../../../commons/components/Themed';
import { ActorTweetEnum } from '../../../commons/dtos/actor.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { TweetReaction } from '../../../commons/dtos/tweet-reaction.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import TweetsPosition from './TweetsPosition';

interface TweetsContainerProps {
  tweetsReactions: TweetReaction[];
}

const TweetsContainer = ({ tweetsReactions }: TweetsContainerProps) => {
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      width: '7.5%',
      height: isNativeOrPWA ? '78%' : '85%',
      left: '5%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      textAlign: 'center'
    }
  });

  const playerTweets = useMemo(() => tweetsReactions.filter(({ side }) => side === ActorTweetEnum.PLAYER), [tweetsReactions]);
  const opponentTweets = useMemo(() => tweetsReactions.filter(({ side }) => side === ActorTweetEnum.OPPONENT), [tweetsReactions]);

  return (
    <View pointerEvents="none" style={styles.container}>
      {opponentTweets.length > 0 ? <TweetsPosition nativeID={PlayerRoundEnum.OPPONENT} /> : null}
      {playerTweets.length > 0 ? <TweetsPosition nativeID={PlayerRoundEnum.PLAYER} /> : null}
    </View>
  );
};
export default TweetsContainer;
