import { LinearGradient } from 'expo-linear-gradient';
import LottieView from 'lottie-react-native';
import { ImageBackground, Text, View, Image, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard, tutorialBot } from '../../../../constants/Images';
import { lottieConfetiWin } from '../../../../constants/Lotties';
import adjust from '../../../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../../utils';
import PolyShape from '../../PolyShape/PolyShape';
import TextWithHTML from '../../TextWithHTML';
import BotBlock from '../BotBlock/BotBlock';
import { useJumpGame } from '../useJumpGame';
import { useStylesHow } from '../useStylesHow';

const HowSectionEnd = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();
  const { vw } = useUnits();
  const ctaStyles = StyleSheet.create({
    play: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(32) : adjust(44),
      fontFamily: 'Inlanders',
      color: '#000',
      paddingBottom: 3,
      cursor: 'pointer'
    },
    playContainer: {
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      alignItems: 'center',
      position: 'relative',
      height: isNativeOrPWA ? 50 : '100%',
      width: isNativeOrPWA ? 150 : '100%',
      maxHeight: 100,
      maxWidth: 300,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    polyContainer: {
      height: '100%',
      width: '100%'
    },
    confetti: {
      position: 'absolute',
      top: isNativeMobile ? -30 : vw(-5),
      width: vw(100),
      height: isNativeMobile ? 300 : '450%',
      backgroundColor: 'transparent'
    }
  });

  const { goToGame } = useJumpGame();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <View style={[styles.splitContainer, isNativeOrPWA && { maxWidth: '90%', marginHorizontal: 'auto' }]}>
        <View style={[styles.splitView, isNativeOrPWA && { flexBasis: '60%' }]}>
          <BotBlock small withBot={false} title={howTitle['tutorial-end-title']}>
            <Text style={styles.paragraph}>
              <TextWithHTML text={howText['tutorial-end-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
            </Text>
          </BotBlock>
        </View>
        <View style={[styles.splitView, isNativeOrPWA && { flexBasis: '40%' }]}>
          <Image
            style={[styles.card, isNativeOrPWA && { height: '70%' }]}
            resizeMode="contain"
            source={tutorialBot}
            accessible
            accessibilityLabel="bot image"
          />
        </View>
      </View>
      <View style={ctaStyles.playContainer}>
        <LottieView autoPlay loop style={ctaStyles.confetti} source={lottieConfetiWin} />
        <TouchableWithoutFeedback onPress={() => goToGame()}>
          <View style={ctaStyles.polyContainer}>
            <PolyShape type={2} bgColor={'white'}>
              <Text style={ctaStyles.play}>{howText['tutorial-end-cta']}</Text>
            </PolyShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </ImageBackground>
  );
};

export default HowSectionEnd;
