import { LinearGradient } from 'expo-linear-gradient';
import { ReactNode } from 'react';
import { ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface GradientContentProps {
  parentStyle: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
  scrollViewStyle: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
  linearGradientStyle?: StyleProp<ViewStyle> | Array<StyleProp<ViewStyle>>;
  colors: string[];
  children: ReactNode;
  locations?: number[];
}
/* Renders the children inside a ScrollView with a LinearGradient,
    by default the linear gradient is from top to bottom of content.
   Can overwrite the default styles */
const GradientContent = ({
  colors,
  parentStyle,
  scrollViewStyle,
  children,
  locations = [],
  linearGradientStyle = []
}: GradientContentProps) => {
  const styles = StyleSheet.create({
    parentScrollView: {
      flex: 1
    },
    contentContainerStyle: {
      flexGrow: 1
    },
    linearGradientStyle: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 100
    }
  });
  return (
    <View style={[styles.parentScrollView, parentStyle]}>
      <LinearGradient
        style={[styles.linearGradientStyle, linearGradientStyle]}
        colors={colors}
        {...(locations ? { locations } : {})}
        pointerEvents="none"
      />
      <ScrollView showsVerticalScrollIndicator={false} style={scrollViewStyle} contentContainerStyle={styles.contentContainerStyle}>
        {children}
      </ScrollView>
    </View>
  );
};

export default GradientContent;
