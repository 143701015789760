import axios from 'axios';
import { CampaignDto } from '../commons/dtos/campaign.dto';
import { Mission } from '../commons/dtos/mission.dto';

const sortMission = (a: Mission, b: Mission) => {
  if (a.order > b.order) return 1;
  if (a.order < b.order) return -1;
  return 0;
};
const base = process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL;
export default class CampaignService {
  isPlaying: boolean;

  constructor(properties?: CampaignServiceProperties) {
    this.isPlaying = properties?.isPlaying ? properties.isPlaying : false;
  }

  getCampaigns = async (): Promise<CampaignDto[]> => {
    const campaigns = (await axios.get<CampaignDto[]>(`${base}/campaigns`, { headers: { playing: this.isPlaying } })).data;

    return campaigns.map(campaign => {
      const { campaignImage, campaignName, missions } = campaign;
      return { campaignImage, campaignName, missions: missions.sort(sortMission) };
    });
  };

  getMissionDetails = async (missionId: string): Promise<Mission> => {
    const mission = (await axios.get<Mission>(`${base}/missions/${missionId}`)).data;
    return mission;
  };

  getFreegame = async (): Promise<Mission> => {
    const freegame = (await axios.get<CampaignDto[]>(`${base}/freegame`)).data;
    const [firstFreegame] = freegame;
    const [firstMission] = firstFreegame.missions;
    return firstMission;
  };
}

export interface CampaignServiceProperties {
  isPlaying?: boolean;
}
