import { Audio, AVPlaybackSource } from 'expo-av';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Config } from '../redux/Configuration/configuration';

interface useMusicProps {
  soundMusic: AVPlaybackSource;
  loop: boolean;
  play: boolean;
}

export function useMusic({ loop, soundMusic, play }: useMusicProps) {
  const volume = useSelector(({ configuration: { volume } }: { configuration: Config }) => volume);
  const { music, general } = volume;

  const [sound, setSound] = useState<Audio.Sound>();

  async function playSound(soundMusic: AVPlaybackSource) {
    const { sound } = await Audio.Sound.createAsync(soundMusic, {
      isLooping: loop,
      volume: music * general
    });
    setSound(sound);
    await sound.playAsync();
  }

  async function getSoundStatus() {
    if (!sound) return false;
    const result = await sound.getStatusAsync();
    return result.isLoaded;
  }

  const callBackPlay = useCallback(playSound, [music, general, loop]);
  const callBackStatus = useCallback(getSoundStatus, [sound]);

  useEffect(() => {
    callBackStatus().then(result => {
      if (!(result && sound)) return;
      sound.setVolumeAsync(music * general);
    });
  }, [music, general, sound]);

  useEffect(() => {
    play
      ? callBackPlay(soundMusic)
      : callBackStatus().then(res => {
          if (!res) return;
          sound!.pauseAsync();
        });
  }, [play, soundMusic]);

  async function unloadSound() {
    if (!sound) return;
    await sound.unloadAsync();
  }

  const cbUnloadSound = useCallback(unloadSound, [sound]);

  useEffect(() => {
    return () => {
      cbUnloadSound();
    };
  }, [cbUnloadSound]);

  return {
    sound,
    unloadSound: cbUnloadSound
  };
}
