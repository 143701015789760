import { useEffect, useState } from 'react';
import { View } from 'react-native';
import Dot from './Dot';

interface DotsProps {
  animation: {
    scale: number;
    duration?: number;
  };
  style: {
    size: number;
  };
}
const numbers = [1, 2, 3];

const Dots = ({ animation, style }: DotsProps) => {
  const [active, setActive] = useState<number>(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive(prev => (prev + 1) % 4);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      {numbers.map(dot => (
        <Dot active={dot === active} animation={animation} style={style} key={dot} />
      ))}
    </View>
  );
};

export default Dots;
