import { useState } from 'react';
import { StyleSheet, TextInput, Text, ImageBackground } from 'react-native';
import useUnits from 'rxn-units';
import { View } from '../../../commons/components/Themed';
import adjust from '../../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../../commons/utils';
import { borderNickname } from '../../../constants/Images';
import useColorScheme from '../../../hooks/useColorScheme';

interface LoginScreenFieldsProps {
  setNickname: Function;
  nickname: string;
  error: string;
}

const LoginScreenFields = ({ setNickname, nickname, error }: LoginScreenFieldsProps) => {
  const { vw } = useUnits();
  const colorScheme = useColorScheme();

  const [keyboardStatus, setKeyboardStatus] = useState<boolean>(false);

  const onChangeNickname = async (currentNickname: string) => {
    setNickname(currentNickname);
  };

  const styles = StyleSheet.create({
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      flex: 1
    },
    inputWeb: {
      borderWidth: 0,
      outlineWidth: 0,
      outlineColor: 'transparent',
      borderColor: 'transparent'
    },
    input: {
      fontSize: isNativeOrPWA ? adjust(18) : adjust(20),
      fontWeight: '500',
      color: isNativeOrPWA && keyboardStatus ? (colorScheme === 'light' ? '#000' : '#FFF') : '#FFF',
      opacity: 0.7,
      height: '100%',
      width: '100%',
      padding: isNativeOrPWA ? 6 : 12
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    errorMessage: {
      color: '#FF7A82',
      fontSize: isNativeOrPWA ? adjust(13) : 18,
      fontWeight: '700',
      maxWidth: isNativeOrPWA ? '90%' : '70%',
      textAlign: 'center'
    },
    borderImage: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: isNativeOrPWA ? 0 : 8,
      paddingLeft: 4
    },
    containerNickname: {
      justifyContent: 'center',
      alignItems: 'center',
      width: vw(50),
      height: '100%',
      maxHeight: isNativeOrPWA ? 35 : 60,
      maxWidth: isNativeOrPWA ? 220 : 442,
      minHeight: isNativeOrPWA ? 35 : 60
    }
  });

  return (
    <View style={styles.form}>
      <View style={styles.containerNickname}>
        <ImageBackground source={borderNickname} resizeMode="stretch" style={styles.borderImage}>
          <TextInput
            onFocus={() => setKeyboardStatus(true)}
            onBlur={() => setKeyboardStatus(false)}
            style={[styles.input, !isNativeOrPWA && styles.inputWeb]}
            placeholder={'Escribe tu nickname...'}
            onChangeText={onChangeNickname}
            value={nickname}
            placeholderTextColor={'white'}
          />
        </ImageBackground>
      </View>
      {error ? (
        <View style={styles.errorContainer}>
          <Text style={styles.errorMessage}>{error && error}</Text>
        </View>
      ) : null}
    </View>
  );
};

export default LoginScreenFields;
