import { View, StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import ExternalHeader from '../../../commons/components/ExternalHeader/ExternalHeader';
import LegalFooter from '../../../commons/components/LegalFooter';
import PrivacyPolicyText from '../PrivacyPolicyText';

const PrivacyPolicyScreen = () => {
  const { vw } = useUnits();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: '#313131',
      flex: 1,
      height: '100%',
      width: '100%',
      paddingTop: vw(8)
    },
    footer: {
      flexGrow: 0
    },
    legalFooter: {
      padding: 0,
      height: vw(10) / 2.36 > 100 ? 100 : vw(10) / 2.36
    }
  });

  return (
    <View style={styles.container}>
      <ExternalHeader withLogo />
      <PrivacyPolicyText />
      <View style={styles.footer}>
        <LegalFooter styleProp={styles.legalFooter} />
      </View>
    </View>
  );
};

export default PrivacyPolicyScreen;
