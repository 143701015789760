import { Avatar, AvatarProperties } from './avatar.dto';
import { Progress, ProgressProperties } from './progress.dto';
import { Title, TitleProperties } from './title.dto';

export enum PlayerRoundEnum {
  PLAYER = 'player',
  OPPONENT = 'opponent'
}

export enum PlayerTypesEnum {
  USER = 'user',
  IA = 'ia',
  GUEST = 'guest'
}

export enum GendersEnum {
  MALE = 'male',
  FEMALE = 'female'
}

export interface GuestUser {
  id?: string;
  email: string;
  email_verified: boolean;
  name: string;
}

export interface GuestToken {
  guestToken: string;
  guestUser: GuestUser;
}

export interface GoogleAccessTokenResponse {
  access_token: string;
  expires_in: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  id_token: string;
}

export class Player {
  id?: string;
  email!: string;
  dateBorn!: Date;
  playerName!: string;
  gender!: GendersEnum;
  type!: PlayerTypesEnum;
  progress!: Progress;
  avatar!: Avatar | null;
  title!: Title | null;

  constructor(properties?: PlayerProperties) {
    if (properties) {
      this.email = properties.email;
      this.dateBorn = properties.dateBorn;
      this.playerName = properties.playerName;
      this.gender = properties.gender;
      this.type = properties.type;
      this.progress = new Progress(properties.progress);
      this.avatar = properties.avatar ? new Avatar(properties.avatar) : null;
      this.title = properties.title ? new Title(properties.title) : null;
    }
  }
}

export interface PlayerProperties {
  id?: string;
  email: string;
  dateBorn: Date;
  playerName: string;
  gender: GendersEnum;
  type: PlayerTypesEnum;
  avatar: AvatarProperties | null;
  title: TitleProperties | null;
  progress: ProgressProperties;
}
