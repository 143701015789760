import { LinearGradient } from 'expo-linear-gradient';
import { useEffect, useMemo, useState } from 'react';
import { ImageBackground, StyleSheet, TouchableWithoutFeedback, View, Image, Text, ImageSourcePropType } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import ButtonShape from '../../../commons/components/ButtonShape/ButtonShape';
import { Dialogue } from '../../../commons/dtos/dialog.dto';
import { GendersEnum } from '../../../commons/dtos/player.dto';
import { TypeSceneEnum } from '../../../commons/dtos/scene.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { backgroundsMissionDialog, backgroundsMissionDialogMobile, goBackIconWhite, howBottom } from '../../../constants/Images';
import { tutorialSound } from '../../../constants/Sound';
import { useMusic } from '../../../hooks/useMusic';
import { Config } from '../../../redux/Configuration/configuration';
import store from '../../../redux/store';
import { MissionDialogueProps } from '../../../types';
import Animation from './Animation';
import Curtain from './Curtain';
import InterCommunicator from './InterCommunicator';

interface sceneDialogueProperties {
  animation: string | undefined;
  background: string | undefined;
  current: Dialogue;
  interlocutor: Dialogue | null;
  scene: TypeSceneEnum;
  title: string;
}

const MissionDialogue = ({ navigation, route }: MissionDialogueProps) => {
  const { gameConfig } = route.params;

  const {
    profile: { player }
  } = store.getState();

  const [step, setStep] = useState<number>(0);
  const [background, setBackground] = useState<string>('mission_dialog.png');
  const playMusic = useSelector(
    ({
      configuration: {
        volume: { dialogMusic }
      }
    }: {
      configuration: Config;
    }) => dialogMusic
  );
  const { unloadSound } = useMusic({ loop: true, soundMusic: tutorialSound, play: playMusic });

  const scenes = useMemo(() => {
    const scenes: sceneDialogueProperties[] = [];

    const dialogsFiltered =
      gameConfig?.dialoguesConfiguration.filter(
        dialogueConfig =>
          dialogueConfig.scene === TypeSceneEnum.STORY ||
          dialogueConfig.scene === TypeSceneEnum.CURTAIN ||
          dialogueConfig.scene === TypeSceneEnum.ANIMATION ||
          dialogueConfig.scene === TypeSceneEnum.START_GAME
      ) || [];

    for (const dialogue of dialogsFiltered) {
      const sides: { [k: string]: Dialogue | null } = { left: null, right: null };
      const otherSide: { [k: string]: string | null } = { left: 'right', right: 'left' };
      for (const msg of dialogue.dialogues) {
        sides[msg.side || 'left'] = msg;
        scenes.push({
          scene: dialogue.scene,
          background: dialogue.background,
          current: msg,
          animation: msg.animation,
          title: (msg.text && msg.text!.toString()) || '',
          interlocutor: sides[otherSide[msg.side || 'left'] || 'right']
        });
      }
    }
    return scenes;
  }, [gameConfig?.dialoguesConfiguration]);

  const [sceneDialogue, setSceneDialogue] = useState<sceneDialogueProperties>(scenes[step]);

  const next = () => {
    setStep(step + 1);
    setSceneDialogue(scenes[step + 1]);
  };

  const back = () => {
    if (step === 0) return;
    setStep(prevStep => prevStep - 1);
    setSceneDialogue(scenes[step - 1]);
  };

  const getDialogBackground = (): ImageSourcePropType => {
    const gender = player?.gender === GendersEnum.FEMALE ? 'f' : 'm';
    const bgKey = background.replace('.png', '').replace('[GENDER]', gender);
    const backgroundDialog = isNativeOrPWA ? backgroundsMissionDialogMobile : backgroundsMissionDialog;
    if (!bgKey) {
      return backgroundDialog[Object.keys(backgroundDialog)[0]];
    }
    return backgroundDialog[bgKey];
  };

  const goToGame = () => {
    const state = navigation.getState();
    if (state.routes.length > 0) {
      navigation.pop(0);
    }
    unloadSound();
    if (gameConfig?.id === 'bfd41bcc-f6ed-442c-a211-ba7d5008d913') {
      navigation.navigate('Tutorial', { gameConfig });
    } else {
      navigation.navigate('LaunchBattle', { gameConfig });
    }
  };

  // TODO Bad deps
  useEffect(() => {
    if (!(scenes && route.name === 'MissionDialogue')) return;
    if (step >= scenes.length) {
      goToGame();
    }
    const file = scenes[step]?.background || 'mission_dialog.png';
    setBackground(file);
  }, [step]);

  const { vw } = useUnits();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'relative',
      maxWidth: vw(100),
      overflow: 'hidden'
    },
    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    button: {
      flexDirection: 'row',
      position: 'absolute',
      width: 100,
      maxHeight: 48,
      cursor: 'pointer',
      alignItems: 'center',
      elevation: 10,
      zIndex: 1
    },
    buttons: {
      paddingHorizontal: isNativeOrPWA ? 20 : 40,
      flexDirection: 'row',
      position: 'absolute',
      maxHeight: 48,
      left: 0,
      width: vw(100),
      height: 48,
      justifyContent: 'space-between',
      alignItems: 'center',
      elevation: 10,
      zIndex: 1,
      bottom: isNativeOrPWA ? 10 : 20
    },
    stepButton: {
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center'
    },
    nextButton: {},
    backButton: {
      opacity: step === 0 ? 0.4 : 1
    },
    skipButton: {
      top: isNativeOrPWA ? 20 : 30,
      right: isNativeOrPWA ? 50 : 100,
      justifyContent: 'center',
      alignItems: 'center'
    },
    skipImage: {
      width: isNativeOrPWA ? 14 : 18,
      height: isNativeOrPWA ? 14 : 18
    },
    nextBackImage: {
      width: isNativeOrPWA ? 14 : 24,
      height: isNativeOrPWA ? 14 : 24
    },
    buttonText: {
      fontWeight: '700',
      fontSize: isNativeOrPWA ? 16 : 22,
      color: '#FFF'
    },
    buttonInner: {
      paddingHorizontal: 10,
      marginBottom: isNativeOrPWA ? 1 : 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'auto'
    }
  });

  return (
    <>
      <TouchableWithoutFeedback onPress={goToGame}>
        <View style={[styles.button, styles.skipButton]}>
          <Text style={[styles.buttonText, { marginRight: 14 }]}>Saltar</Text>
          <Image source={goBackIconWhite} style={[styles.skipImage, { transform: [{ scaleX: -1 }] }]} />
        </View>
      </TouchableWithoutFeedback>
      <ImageBackground source={getDialogBackground()} resizeMode="cover" style={styles.container}>
        <TouchableWithoutFeedback onPress={next}>
          <LinearGradient style={styles.gradient} colors={['black', 'transparent']}>
            {sceneDialogue &&
              scenes &&
              sceneDialogue.scene !== TypeSceneEnum.CURTAIN &&
              sceneDialogue.scene !== TypeSceneEnum.ANIMATION && (
                <InterCommunicator
                  current={sceneDialogue.current}
                  interlocutor={sceneDialogue.interlocutor}
                  next={next}
                  isFreeGame={!!route.params.gameConfig?.isFreeGame}
                />
              )}
            {sceneDialogue && scenes && sceneDialogue.scene === TypeSceneEnum.CURTAIN && (
              <Curtain title={sceneDialogue.title} next={next} interlocutor={sceneDialogue.interlocutor} />
            )}
            {sceneDialogue && scenes && sceneDialogue.scene === TypeSceneEnum.ANIMATION && (
              <Animation animation={sceneDialogue.animation} next={next} interlocutor={sceneDialogue.interlocutor} />
            )}
          </LinearGradient>
        </TouchableWithoutFeedback>
      </ImageBackground>
      <View style={styles.buttons}>
        <View style={[styles.stepButton, styles.backButton]}>
          <TouchableWithoutFeedback
            accessible
            onPress={back}
            disabled={step === 0}
            accessibilityLabel={'Go back to previus dialogue'}
            style={{ width: 'auto' }}
          >
            <View>
              <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 50}>
                <View style={styles.buttonInner}>
                  <Image source={howBottom} resizeMode="contain" style={[styles.nextBackImage, { transform: [{ rotate: '90deg' }] }]} />
                  <Text style={[styles.buttonText, { marginLeft: 14 }]}>Anterior</Text>
                </View>
              </ButtonShape>
            </View>
          </TouchableWithoutFeedback>
        </View>
        <View style={[styles.stepButton, styles.nextButton]}>
          <TouchableWithoutFeedback accessible onPress={next} accessibilityLabel={'Go to next dialogue'} style={{ width: 'auto' }}>
            <View>
              <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 50}>
                <View style={styles.buttonInner}>
                  <Text style={[styles.buttonText, { marginRight: 14 }]}>Siguiente</Text>
                  <Image source={howBottom} resizeMode="contain" style={[styles.nextBackImage, { transform: [{ rotate: '-90deg' }] }]} />
                </View>
              </ButtonShape>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    </>
  );
};

export default MissionDialogue;
