import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View, ViewStyle, Image, TouchableWithoutFeedback, Animated, Platform } from 'react-native';
import useUnits from 'rxn-units';
import { nextButtonActive } from '../../constants/Images';
import { isNativeOrPWA } from '../utils';
import { paginate } from '../utils/paginate';

interface SlidesProps {
  parentStyle: ViewStyle;
  children?: ReactNode[];
  itemsToRender?: number;
  loop?: boolean;
  style?: ViewStyle;
  initialStep: number;
  buttonsStyles?: { left?: ViewStyle; right?: ViewStyle; common?: ViewStyle };
}

const Slides = ({ children, parentStyle, itemsToRender = 1, loop = false, style, initialStep, buttonsStyles }: SlidesProps) => {
  const { vw } = useUnits();
  const [step, setStep] = useState<number>(initialStep);
  const paginated = useMemo(() => paginate(children || [], itemsToRender), [children, itemsToRender]);
  const opacity = useRef(new Animated.Value(0.4)).current;

  const useNativeDriver = Platform.OS !== 'web';

  const opacityAnimation = Animated.timing(opacity, { toValue: 1, useNativeDriver, duration: 1000 });

  useEffect(() => {
    opacityAnimation.start();
  }, [step]);

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const styles = StyleSheet.create({
    nextButton: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      justifyContent: 'center',
      zIndex: 1
    },
    nextButtonLeft: {
      left: -vw(3)
    },
    nextButtonRight: {
      right: -vw(3)
    },
    imageNextButton: {
      width: '100%',
      height: '100%',
      minWidth: vw(3),
      minHeight: vw(3),
      maxHeight: isNativeOrPWA ? 40 : undefined,
      maxWidth: isNativeOrPWA ? 40 : undefined
    },
    imageNextButtonLeft: {
      transform: [{ rotate: '180deg' }]
    },
    disabledButton: {
      opacity: 0.25
    }
  });

  const previusStep = () => setStep(prevState => (!(prevState === 0) ? (prevState - 1) % paginated.length : paginated.length - 1));
  const nextStep = () => setStep((step + 1) % paginated.length);

  return (
    <View style={parentStyle}>
      <TouchableWithoutFeedback onPress={previusStep} disabled={!loop && step === 0}>
        <View style={[styles.nextButton, styles.nextButtonLeft, buttonsStyles?.left || {}, buttonsStyles?.common || {}]}>
          <Image
            source={nextButtonActive}
            resizeMode="contain"
            style={[styles.imageNextButton, styles.imageNextButtonLeft, !loop && step === 0 && styles.disabledButton]}
          />
        </View>
      </TouchableWithoutFeedback>

      {style ? <Animated.View style={[style, { opacity }]}>{paginated[step]}</Animated.View> : paginated[step]}

      <TouchableWithoutFeedback onPress={nextStep} disabled={!loop && step === paginated.length - 1}>
        <View style={[styles.nextButton, styles.nextButtonRight, buttonsStyles?.right || {}, buttonsStyles?.common || {}]}>
          <Image
            source={nextButtonActive}
            resizeMode="contain"
            style={[styles.imageNextButton, !loop && step === paginated.length - 1 && styles.disabledButton]}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default Slides;
