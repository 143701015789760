import { MouseEvent, useMemo, useState } from 'react';
import { GestureResponderEvent, LogBox, ViewStyle, StyleSheet } from 'react-native';
import { DraxDragWithReceiverEndEventData } from 'react-native-drax';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { ColorCardEnum } from '../../../commons/constants/colorCard';
import { PlayerTypeEnum, PlayersSuffixEnum } from '../../../commons/constants/player';
import { Card as CardType } from '../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { Rule } from '../../../commons/dtos/rule.dto';
import { TagsEnum } from '../../../commons/dtos/tag.dto';
import { wait, isMobile, cardHasTag, isNativeMobile } from '../../../commons/utils';
import boardCardsUtils from '../../../commons/utils/gameboard/boardCards';
import { isActionOrSupportCard } from '../../../commons/utils/gameboard/rules';
import { BoardCards } from '../gameTypes';
import { BattleCard } from '../useGameBoard';
import { CardConditions } from './BoardCardsContainer';

LogBox.ignoreLogs(['ViewPropTypes will be removed']);

const { cardIsAttackable, cardAppliesToTarget } = boardCardsUtils;

export interface BoardCardsHookProps {
  onCloseDetailModal: () => void;
  setDragAttacking: React.Dispatch<React.SetStateAction<string | null>>;
  clearActionsDrop: () => Promise<void>;
  setDragCard: React.Dispatch<React.SetStateAction<string>>;
  setShowAttackingCardDrag: React.Dispatch<React.SetStateAction<boolean>>;
  showAttackingCardDrag: boolean;
  dragCard: string;
  playerBoardCards: CardType[];
  opponentBoardCards: CardType[];
  onCardPressed: (card: CardType, side: PlayerRoundEnum, position: ViewStyle) => void;
  onSelectedToAttackWithCard: (boardCards: CardType[], boardCardIndex: number) => void;
  onAttackWithCard: (
    attackingCardIndex: number,
    attackedCardIndex: number,
    currentPlayerBoardCards: CardType[],
    currentOpponentBoardCards: CardType[],
    playerLifePoints: number
  ) => Promise<{ attackedCards: BoardCards; attackingCards: BoardCards; playerLifePoints: number; opponentLifePoints: number }>;
  opponentIsArtificialIntelligence: boolean | undefined;
  selectingTargetCard: CardType | undefined;
  setTargetReached: React.Dispatch<React.SetStateAction<CardType | undefined>>;
  targetReached: CardType | undefined;
  isLaunchingCardFromHand: boolean;
  selectingTargetDrawnCard: CardType | null;
  opponentFirst: boolean;
  isPlayerRound: boolean;
  isCurrentPlayerRound: (player: PlayerTypeEnum) => boolean;
  battleCards: [BattleCard, BattleCard];
  cardIsUpdating: boolean;
  allRules: Rule[];
  isOpponentRound: boolean;
  handleAttackableOpponent: (value: boolean) => void;
  handleAttackablePlayer: (value: boolean) => void;
  playerLifePoints: number;
}

const useBoardCards = ({
  clearActionsDrop,
  onCardPressed,
  onCloseDetailModal,
  setDragAttacking,
  setDragCard,
  battleCards,
  dragCard,
  isCurrentPlayerRound,
  isLaunchingCardFromHand,
  isPlayerRound,
  onAttackWithCard,
  onSelectedToAttackWithCard,
  opponentBoardCards,
  opponentFirst,
  opponentIsArtificialIntelligence,
  playerBoardCards,
  selectingTargetCard,
  selectingTargetDrawnCard,
  setShowAttackingCardDrag,
  setTargetReached,
  showAttackingCardDrag,
  targetReached,
  cardIsUpdating,
  allRules,
  isOpponentRound,
  handleAttackableOpponent,
  handleAttackablePlayer,
  playerLifePoints
}: BoardCardsHookProps) => {
  const { rw, rh, offsetRatioLeft, offsetRatioTop } = useRatioDimensions();
  const [showBattlePoints, setShowBattlePoints] = useState<boolean>(false);
  const [attackedCard, setAttackedCard] = useState<CardType | null>(null);
  const [attackingCard, setAttackingCard] = useState<CardType | null>(null);

  const playerSuffix = opponentFirst ? PlayersSuffixEnum.SECOND : PlayersSuffixEnum.FIRST;

  const styles = StyleSheet.create({
    cardContent: {
      position: 'relative',
      height: '100%'
    },
    readyCard: {
      shadowColor: '#07efbb',
      borderRadius: rw(1),
      shadowOffset: {
        width: 0,
        height: 12
      },
      shadowOpacity: 0.9,
      shadowRadius: 16.0,
      elevation: 24
    },
    whiteReadyCard: {
      shadowColor: '#fee05d'
    },
    attackingCard: {
      shadowColor: '#F58521'
    },
    cardIsBeingAttacked: {
      shadowColor: '#FF0000',
      borderRadius: rw(1),
      shadowOffset: {
        width: 0,
        height: 12
      },
      shadowOpacity: 0.9,
      shadowRadius: 16.0,
      elevation: 24
    }
  });

  const cleanDragDrop = async () => {
    await wait(500);
    setDragAttacking(null);
    setDragCard('');
    await clearActionsDrop();
  };

  const cleanAttack = async (card: CardType, isScape = false) => {
    if (card.isAttacking) {
      if (!isMobile && isScape) {
        card.isAttacking = false;
      }
      await cleanDragDrop();
    }
  };

  const keyEscapeListener = async (card: CardType) => {
    document.addEventListener(
      'keydown',
      async (e: KeyboardEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === 'Escape') {
          await cleanAttack(card, true);
        }
      },
      { once: true }
    );
  };

  const keyEscapeListenerDetailCard = () => {
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.empty();
    document.addEventListener(
      'keydown',
      (e: KeyboardEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === 'Escape') {
          onCloseDetailModal();
        }
      },
      { once: true }
    );
  };

  const showCardDetail = (e: MouseEvent, card: CardType) => {
    e.preventDefault();
    e.stopPropagation();

    if (cardIsUpdating) return;

    const { target } = e; // Obtiene el elemento al que se hizo click
    if (target instanceof Element) {
      const rect = target.getBoundingClientRect();
      const x = rect.left - offsetRatioLeft;
      const y = rect.top - offsetRatioTop;
      const isPlayerHand = y > rh(50);
      const left = x - rw(3);
      const top = isPlayerHand ? y - rw(24) : y + rw(14) * 1.2;

      onCardPressed(card, isPlayerHand ? PlayerRoundEnum.PLAYER : PlayerRoundEnum.OPPONENT, { left, top });
      keyEscapeListenerDetailCard();
    }
  };

  const onLongPress = (e: GestureResponderEvent, card: CardType) => {
    if (cardIsUpdating) return;
    const x = e.nativeEvent.pageX;
    const left = x - rw(7) - offsetRatioLeft;
    const top = isPlayerRound ? rh(10) : rh(65);
    onCardPressed(card, isPlayerRound ? PlayerRoundEnum.PLAYER : PlayerRoundEnum.OPPONENT, { left, top });
  };

  const closeCardDetail = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (cardIsUpdating) return;
    onCloseDetailModal();
  };

  const getAttackingPointsResult = (attackingCard: CardType) => {
    if (attackedCard && cardHasTag(attackedCard, TagsEnum.FACTCHECK)) {
      return 1;
    }
    const points = attackedCard?.damagePoints ? -attackedCard.damagePoints : 0;

    return !attackingCard.defensePoints || attackingCard.defensePoints + points <= 0 ? 1 : points;
  };

  const getAttackingDefensePoints = (attackingCard: CardType): number => {
    if (!attackedCard) return 0;
    const damage = attackedCard.damagePoints || 0;
    const defense = attackingCard.defensePoints || 0;
    return defense - damage;
  };

  const getAttackedDefensePoints = (attackedCard: CardType): number => {
    if (!attackingCard) return 0;

    const damage = attackingCard.damagePoints || 0;
    const defense = attackedCard.defensePoints || 0;
    return defense - damage;
  };

  const getAttackedPointsResult = (attackedCard: CardType) => {
    let boardCards = playerBoardCards;
    if (isCurrentPlayerRound(PlayerTypeEnum.OPPONENT)) {
      boardCards = opponentBoardCards;
    }

    // Get current index dragCard in position [3] fix(FKOVER-472): process duplicated cards
    // eslint-disable-next-line prefer-destructuring
    const attackingCardIndex = dragCard.split('_')[3];
    const attackingCard = boardCards[+attackingCardIndex];

    if (cardHasTag(attackingCard, TagsEnum.FACTCHECK)) {
      return 1;
    }
    const points = attackingCard.damagePoints;

    return !points || !attackedCard.defensePoints || attackedCard.defensePoints - points <= 0 ? 1 : -points;
  };

  const isCardActiveToSelect = (isSelfRound: boolean, attackingBoardCards: CardType[], card: CardType, attackedBoardCards: CardType[]) => {
    if (!isLaunchingCardFromHand || !selectingTargetCard || !selectingTargetCard.type || !isActionOrSupportCard(selectingTargetCard)) {
      return (
        isSelfRound ||
        !attackingBoardCards.some(card => card.isAttacking) ||
        (attackingBoardCards.some(attackingCard => {
          return attackingCard.isAttacking && cardIsAttackable(card, attackedBoardCards);
        }) &&
          card.isAttackable)
      );
    }

    return selectingTargetCard && cardAppliesToTarget(selectingTargetCard, card, allRules, selectingTargetDrawnCard ? null : isSelfRound);
  };

  const getCardConditions =
    (player: boolean) =>
    (card: CardType): CardConditions => {
      const isCardReady = isCurrentPlayerRound(player ? PlayerTypeEnum.PLAYER : PlayerTypeEnum.OPPONENT) && !card.isWaiting;
      const isCardAttackable = !isCurrentPlayerRound(player ? PlayerTypeEnum.PLAYER : PlayerTypeEnum.OPPONENT) && !!card.isAttackable;
      const cardHasDamagePoints = !!card.damagePoints && card.damagePoints > 0;
      return { isCardReady, isCardAttackable, cardHasDamagePoints };
    };

  const getPlayerCardStyles = (card: CardType, conditions: CardConditions): ViewStyle[] => {
    const { cardHasDamagePoints, isCardReady } = conditions;
    return [
      styles.cardContent,
      isCardReady && cardHasDamagePoints && !showAttackingCardDrag ? styles.readyCard : {},
      isCardReady && cardHasDamagePoints && !card.color && !showAttackingCardDrag ? styles.whiteReadyCard : {},
      isCurrentPlayerRound(PlayerTypeEnum.PLAYER) && cardHasDamagePoints && card.isAttacking && !showAttackingCardDrag
        ? styles.attackingCard
        : {},
      attackedCard === card &&
      (!isLaunchingCardFromHand ||
        (targetReached === card && isCardActiveToSelect(!isPlayerRound, playerBoardCards, card, opponentBoardCards)))
        ? styles.cardIsBeingAttacked
        : {},
      {
        opacity: isCardActiveToSelect(isPlayerRound, opponentBoardCards, card, playerBoardCards) ? 1 : 0.6
      }
    ] as ViewStyle[];
  };

  const getOpponentCardStyles = (card: CardType, conditions: CardConditions): ViewStyle[] => {
    const { cardHasDamagePoints, isCardReady } = conditions;
    return [
      styles.cardContent,
      isCardReady && cardHasDamagePoints && !showAttackingCardDrag ? styles.readyCard : {},
      isCardReady && cardHasDamagePoints && !card.color && !showAttackingCardDrag ? styles.whiteReadyCard : {},
      isCurrentPlayerRound(PlayerTypeEnum.OPPONENT) && cardHasDamagePoints && card.isAttacking && !showAttackingCardDrag
        ? styles.attackingCard
        : {},
      attackedCard === card ? styles.cardIsBeingAttacked : {},
      {
        opacity: isCardActiveToSelect(!isPlayerRound, playerBoardCards, card, opponentBoardCards) ? 1 : 0.6
      }
    ] as ViewStyle[];
  };
  const onHandlerStateChange = async (card: CardType, index: number, boardCards: CardType[]) => {
    onSelectedToAttackWithCard(boardCards, index);
    if (isNativeMobile) return;
    await keyEscapeListener(card);
  };

  const playerOnHandlerStateChange = async (cards: CardType[], card: CardType, isCardReady: boolean, index: number) => {
    if (!(isCurrentPlayerRound(PlayerTypeEnum.PLAYER) && isCardReady && !card.isAttacking)) {
      return;
    }
    await onHandlerStateChange(card, index, cards);
  };

  const opponentOnHandlerStateChange = async (cards: CardType[], card: CardType, isCardReady: boolean, index: number) => {
    if (!(!opponentIsArtificialIntelligence && isCurrentPlayerRound(PlayerTypeEnum.OPPONENT) && isCardReady && !card.isAttacking)) {
      return;
    }
    await onHandlerStateChange(card, index, cards);
  };

  const onMouseEnter = (e: MouseEvent, card: CardType) => showCardDetail(e, card);

  const onMouseLeave = (e: MouseEvent) => closeCardDetail(e);

  const playerOnLongPressHandler = (e: GestureResponderEvent, card: CardType) => {
    if (card.isAttacking === false) {
      onLongPress(e, card);
    }
  };

  const opponentOnLongPressHandler = (e: GestureResponderEvent, card: CardType) => {
    if (card.isAttacking === false && !(opponentIsArtificialIntelligence && isCurrentPlayerRound(PlayerTypeEnum.OPPONENT))) {
      onLongPress(e, card);
    }
  };

  const onPressOutHandler = () => onCloseDetailModal();

  const getPlayerConditions = (card: CardType) => {
    const conditions = getCardConditions(true);
    const { cardHasDamagePoints, isCardAttackable } = conditions(card);
    const condition =
      !!selectingTargetDrawnCard ||
      (!isPlayerRound && isCardAttackable) ||
      (!!selectingTargetCard &&
        cardAppliesToTarget(selectingTargetCard, card, allRules, selectingTargetDrawnCard !== null ? null : isPlayerRound));

    const condition2 = isPlayerRound && !card.isWaiting && !!card.isPlayingCard && cardHasDamagePoints;
    return { condition, condition2 };
  };

  const getOpponentConditions = (card: CardType) => {
    const conditions = getCardConditions(false);
    const { cardHasDamagePoints, isCardAttackable } = conditions(card);
    const condition =
      (isPlayerRound && isCardAttackable) ||
      (!!selectingTargetCard &&
        cardAppliesToTarget(selectingTargetCard, card, allRules, selectingTargetDrawnCard !== null ? null : !isPlayerRound));

    const condition2 = !isPlayerRound && !card.isWaiting && !!card.isPlayingCard && cardHasDamagePoints;
    return { condition, condition2 };
  };

  const onReceive = {
    onReceiveDragEnter: (card: CardType) => {
      onCloseDetailModal();
      if (selectingTargetCard) {
        setAttackedCard(null);
        setTargetReached(card);
      } else {
        setAttackedCard(card);
      }
    },
    onReceiveDragDrop: (dropCurrentID: string, index: number) => {
      setAttackedCard(null);
      setTimeout(async () => {
        // eslint-disable-next-line prefer-destructuring
        const attackingCardIndex = dragCard.split('_')[3];
        if (selectingTargetDrawnCard) return;
        await onAttackWithCard(+attackingCardIndex, index, playerBoardCards, opponentBoardCards, playerLifePoints);
        setShowAttackingCardDrag(false);
        setTargetReached(undefined);
      }, 250);
    },
    onReceiveDragExit: (event: DraxDragWithReceiverEndEventData) => {
      setAttackedCard(null);
      setTargetReached(undefined);
      if (event.cancelled) {
        clearActionsDrop();
      }
    }
  };

  const onDrag = {
    onDrag: () => null,
    onDragEnter: () => {
      setShowBattlePoints(true);
    },
    onDragExit: (card: CardType) => {
      card.isAttacking = false;
      setShowBattlePoints(false);
    },
    onDragStart: (card: CardType, index: number) => {
      if (battleCards.some(card => card !== null)) return;
      onCloseDetailModal();
      setDragCard(`drag_current_${card.id}_${index}_${PlayerRoundEnum.PLAYER}_${playerSuffix}`);
      setAttackingCard(card);
      if (card.color === ColorCardEnum.WHITE) {
        isOpponentRound ? handleAttackablePlayer(false) : handleAttackableOpponent(false);
      }
    },
    // TODO mutating current state
    onDragEnd: (card: CardType) => {
      card.isAttacking = false;
      setShowBattlePoints(false);
      setAttackingCard(null);
      clearActionsDrop();
    },
    // TODO mutating current state
    onDragDrop: (card: CardType) => {
      card.isAttacking = false;
      setShowBattlePoints(false);
      setAttackingCard(null);
      setTimeout(async () => {
        await cleanAttack(card);
        setShowAttackingCardDrag(false);
      }, 550);
    }
  };

  const battleCardIndex = useMemo(() => {
    const onlyCards = battleCards.filter(card => card !== undefined && card !== null && card !== 'avatar') as CardType[];
    return onlyCards.map(card => card.idUnique);
  }, [battleCards]);

  return {
    battleCardIndex,
    onDrag,
    onReceive,
    getOpponentCardStyles,
    getOpponentConditions,
    getPlayerConditions,
    getPlayerCardStyles,
    getAttackedPointsResult,
    getAttackingPointsResult,
    playerOnHandlerStateChange,
    opponentOnHandlerStateChange,
    onMouseEnter,
    onPressOutHandler,
    playerOnLongPressHandler,
    onMouseLeave,
    opponentOnLongPressHandler,
    showBattlePoints,
    playerSuffix,
    attackedCard,
    getCardConditions,
    getAttackingDefensePoints,
    getAttackedDefensePoints
  };
};

export default useBoardCards;
