import { ImageBackground, View, Text, StyleProp, ViewStyle } from 'react-native';
import {
  cardTextBlackContainer,
  cardTextWhiteContainer,
  typeContainerBorderLeftBlack,
  typeContainerBorderLeftWhite,
  typeContainerBorderRightBlack,
  typeContainerBorderRightWhite
} from '../../../../constants/Images';
import { Card as CardType } from '../../../dtos/card.dto';
import { RaritiesEnum } from '../../../dtos/rarity.dto';
import { Shadow } from '../../../dtos/shadow.dto';
import { TagsEnum } from '../../../dtos/tag.dto';
import TextWithHTML from '../../TextWithHTML';
import Actions from '../Actions';
import AttackAndDefense from '../AttackAndDefense';
import { CardActionPoints, CardBackground, CardName, CardSubject } from '../Card';
import Rarity from '../Rarity';
import Tags from '../Tags';
import TagsInfo from '../TagsInfo';
import { useCardStyles } from '../useCardStyles';
import { CardSizesEnum } from '../utils';
import { useCardEnhace } from './useCardEnhance';

interface HandCardProps {
  card: CardType;
  cardSize: CardSizesEnum;
  conditionalStyles?: StyleProp<ViewStyle>;
  shadow?: Shadow;
  showTagsInfo?: boolean;
}

const SelectedCard = ({ card, cardSize, conditionalStyles, shadow, showTagsInfo: propShowTagsInfo }: HandCardProps) => {
  const { color, tags, damagePoints, defensePoints, cardText, type, enhance, rarity } = card;

  const hasAction = card?.rules?.[0];

  const enhancePointsResult = useCardEnhace({ enhance });

  const { styles, propsStyles } = useCardStyles(card, cardSize, !!hasAction);

  const isRarity = rarity?.rarityName !== RaritiesEnum.SILVER;

  const showDamagePoints = damagePoints !== null && damagePoints >= 0;

  const showDefensePoints = defensePoints !== null && defensePoints >= 0;

  const hasTags = tags?.some(({ tagName }) => tagName === TagsEnum.IMPACT || tagName === TagsEnum.FACTCHECK);

  const tagTitle = tags?.map(({ tagName }) => tagName).join(', ');

  const showTagsInfo = ((tags && tags.length > 0) || enhance) && propShowTagsInfo;

  const playableHandCardShadow = conditionalStyles;

  return (
    <View pointerEvents="none" style={[styles.container, playableHandCardShadow, shadow]}>
      <CardBackground card={card} />
      <CardName card={card} cardSize={cardSize} />
      <View style={styles.cardTextContainer}>
        <ImageBackground
          style={styles.cardTextBackground}
          source={color ? cardTextBlackContainer : cardTextWhiteContainer}
          resizeMode="cover"
        />
        <View style={styles.typeContainer}>
          <View style={[styles.typeContainerBorder, styles.typeContainerBorderLeft]}>
            <ImageBackground
              style={styles.typeContainerBorderImage}
              source={color ? typeContainerBorderLeftBlack : typeContainerBorderLeftWhite}
              resizeMode="stretch"
            />
          </View>
          <View style={[styles.typeContainerMiddle, propsStyles.typeContainerMiddle]}>
            <Text selectable={false} style={[styles.type, propsStyles.type]}>
              {type?.typeName.toUpperCase()}
            </Text>
          </View>
          <View style={[styles.typeContainerBorder, styles.typeContainerBorderRight]}>
            <ImageBackground
              style={styles.typeContainerBorderImage}
              source={color ? typeContainerBorderRightBlack : typeContainerBorderRightWhite}
              resizeMode="stretch"
            />
          </View>
        </View>
        <Text selectable={false} style={[styles.cardText, propsStyles.cardText]}>
          {cardText && (
            <TextWithHTML
              text={(tagTitle ? `<b>${tagTitle?.toString().toUpperCase()}</b><br>` : '') + cardText}
              generalTextStyle={[styles.cardText, propsStyles.cardText]}
              htmlStyles={{
                bold: [styles.boldText, propsStyles.boldText]
              }}
            />
          )}
        </Text>
      </View>
      <CardSubject card={card} cardSize={cardSize} />
      <CardActionPoints card={card} cardSize={cardSize} />
      {showDefensePoints && (
        <AttackAndDefense
          card={card}
          cardSize={cardSize}
          iconType="defense"
          points={defensePoints || 0}
          enhancePointsResult={enhancePointsResult}
        />
      )}
      {showDamagePoints && (
        <AttackAndDefense
          card={card}
          cardSize={cardSize}
          iconType="damage"
          points={damagePoints || 0}
          enhancePointsResult={enhancePointsResult}
        />
      )}
      {isRarity && <Rarity card={card} />}
      <View style={styles.actionCardContainer}>
        {hasTags && <Tags card={card} />}
        {hasAction && <Actions card={card} />}
      </View>
      {showTagsInfo && <TagsInfo card={card} cardSize={cardSize} />}
    </View>
  );
};

export default SelectedCard;
