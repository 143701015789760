/* eslint-disable @typescript-eslint/no-var-requires */
import { StyleSheet, Image, TouchableWithoutFeedback, ImageBackground } from 'react-native';
import { vw } from 'rxn-units';
import { selectedShape, unselectedShape } from '../../../../constants/Images';
import { View } from '../../Themed';

interface StatValue {
  name: string;
  inactiveImage: any;
  activeImage: any;
}
interface CardStatsFilterSectionProps {
  activeStat: number;
  setActiveStat: Function;
  stats: StatValue[];
  buttonMobile?: boolean;
}

const CardStatsFilterSection = (props: CardStatsFilterSectionProps) => {
  const onStatClicked = (statIndex: number) => {
    if (props.activeStat === statIndex) {
      props.setActiveStat(-1);
      return;
    }
    props.setActiveStat(statIndex);
  };

  const styles = StyleSheet.create({
    container: {
      justifyContent: 'space-evenly',
      paddingHorizontal: '2%',
      paddingVertical: '0.5%',
      marginHorizontal: '1%'
    },
    statsContainer: { flexDirection: 'row' },
    stat: {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      margin: 1,
      width: props.buttonMobile ? vw(5) : vw(3),
      height: props.buttonMobile ? vw(5) : vw(3)
    },
    statBg: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    statIcon: {
      resizeMode: 'contain',
      width: '55%',
      height: '55%'
    }
  });
  return (
    <View style={styles.container}>
      <View style={styles.statsContainer}>
        {props.stats.map((stat, index) => {
          const statStyles = StyleSheet.create({ statIcon: { opacity: props.activeStat === index ? 1 : 0.5 } });

          return (
            <TouchableWithoutFeedback key={index} onPress={() => onStatClicked(index)}>
              <View style={styles.stat}>
                <ImageBackground
                  style={styles.statBg}
                  source={props.activeStat === index ? selectedShape : unselectedShape}
                ></ImageBackground>
                <Image
                  source={props.activeStat === index ? stat.activeImage : stat.inactiveImage}
                  style={[styles.statIcon, statStyles.statIcon]}
                />
              </View>
            </TouchableWithoutFeedback>
          );
        })}
      </View>
    </View>
  );
};

export default CardStatsFilterSection;
