import { useKeepAwake } from 'expo-keep-awake';
import { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, ImageBackground, TouchableWithoutFeedback, Animated, Easing, Platform } from 'react-native';
import { DraxProvider } from 'react-native-drax';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AspectRatioView from '../../commons/components/AspectRatioView/AspectRatioView';
import { useRatioDimensions } from '../../commons/components/AspectRatioView/useRatioDimensions';
import AvatarModalContent from '../../commons/components/GameboardModal/Contents/AvatarModalContent';
import CardSupportModalContent from '../../commons/components/GameboardModal/Contents/CardSupportModalContent';
import GameboardModal from '../../commons/components/GameboardModal/GameboardModal';
import GameHeader from '../../commons/components/GameHeader/GameHeader';
import SnackBar from '../../commons/components/SnackBar';
import { View } from '../../commons/components/Themed';
import TimeModal from '../../commons/components/TimeModal/TimeModal';
import { Card as CardType } from '../../commons/dtos/card.dto';
import { GameBoardConfiguration } from '../../commons/dtos/game-board-configuration.dto';
import { PlayerRoundEnum } from '../../commons/dtos/player.dto';
import { Level, Progress } from '../../commons/dtos/progress.dto';
import { isGameActionHighlight } from '../../commons/events-management/game-actions/gameActions';
import { isNativeOrPWA } from '../../commons/utils';
import { updateProgressOnSurrendered } from '../../commons/utils/gameboard/progress';
import { gameBoardBackground1, gameBoardBackgroundMobile } from '../../constants/Images';
import { TweetReactionInterface } from '../../redux/GameBoard/tweets';
import { showTimeModal } from '../../redux/Time-advice/timeAdvice';
import { GameBoardProps } from '../../types';
import AvatarsContainer from './AvatarsContainer/AvatarsContainer';
import BoardActionPointsContainer from './BoardActionPointsContainer/BoardActionPointsContainer';
import BoardCards from './BoardCards/BoardCards';
import BoardCardsMobile from './BoardCards/BoardCardsMobile';
import CardsBattleModal from './CardsBattleModal/CardsBattleModal';
import DiscardModal from './DiscardModal/DiscardModal';
import GameReactionContainer from './GameReaction/GameReactionContainer';
import OpponentHandOfCards from './HandOfCards/OpponentHandOfCards/OpponentHandOfCards';
import OpponentHandOfCardsMobile from './HandOfCards/OpponentHandOfCards/OpponentHandOfCardsMobile';
import PlayerHandOfCards from './HandOfCards/PlayerHandOfCards/PlayerHandOfCards';
import PlayerHandOfCardsMobile from './HandOfCards/PlayerHandOfCards/PlayerHandOfCardsMobile';
import UpgradeCardModal from './Modal/UpgradeCardModal';
import NextRoundButton from './NextRoundButton';
import RoundIndicator from './RoundIndicator';
import TravellingCards from './TravellingCards';
import TweetsContainer from './TweetsContainer/TweetsContainer';
import useGameBoard from './useGameBoard';

const GameBoard = ({ navigation, route }: GameBoardProps) => {
  const { rw, rh } = useRatioDimensions();
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    containerDrag: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    background: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      top: 0,
      left: 0,
      flex: 1
    },
    cardDetailModalContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: rw(14),
      height: rw(14 * 1.4),
      top: rh(30),
      left: 0,
      zIndex: 11,
      elevation: 11
    },
    cardDetailContainer: {
      width: rw(17.6),
      height: rw(24.7)
    }
  });

  const {
    propsAvatarsContainer,
    detailCard,
    cardDetailModalVisible,
    onCloseDetailModal,
    propsBoardCards,
    playerMaxActionPoints,
    playerActionPoints,
    opponentMaxActionPoints,
    opponentActionPoints,
    round,
    handOfCards,
    showNextRoundButton,
    nextTurn,
    setShowNextRoundButton,
    showDiscard,
    discardCards,
    selectedToDiscardCards,
    setSelectedToDiscardCards,
    onConfirmDiscard,
    playerTravellingCardPosition,
    playerCardIsTravelling,
    opponentTravellingCardPosition,
    opponentCardIsTravelling,
    discardModalText,
    showDiscardModalButton,
    showCardSupportModal,
    onGetAutoDiscardSelection,
    hiddenCards,
    detailCardPosition,
    GameBoardContext,
    appliedRules,
    showBattleModal,
    opponentIsArtificialIntelligence,
    isOpponentRound,
    msg,
    detail,
    clearMessageError,
    yourTurn,
    battleCards,
    upgradeCards,
    highlightNextTurn,
    unloadSound,
    handleSelection,
    activeSupportCardRef,
    handleCloseAvatarModal,
    showAvatarModal,
    replaceCardDiscard,
    leftoverModal
  } = useGameBoard(route);

  useKeepAwake();

  const detailRef = useRef(new Animated.Value(0)).current;

  const LeftoverModal = leftoverModal.modal?.component;

  const { reactions: tweetReactions } = useSelector((state: { tweets: TweetReactionInterface }) => state.tweets, shallowEqual);

  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const checkShowBattleModal = useSelector((s: { timeAdvice: { showTimeModal: boolean } }) => s.timeAdvice.showTimeModal);

  const enemyKey = route.params.gameConfig?.opponent?.key;

  const [cardUpgrade, cardToUpgrade] = upgradeCards;

  useEffect(() => {
    const animationStart = Animated.timing(detailRef, {
      toValue: 0,
      useNativeDriver: Platform.OS !== 'web'
    });
    animationStart.reset();
    if (!cardDetailModalVisible) return;
    const isPlayerHand = +detailCardPosition.top! > rh(50);
    const animation = Animated.timing(detailRef, {
      toValue: isPlayerHand ? -rh(5) : rh(5),
      easing: Easing.ease,
      duration: 300,
      useNativeDriver: Platform.OS !== 'web'
    });
    animation.start();
  }, [cardDetailModalVisible, detailCardPosition, detailRef]);

  useEffect(() => {
    setDisableButton(!showNextRoundButton);
  }, [showNextRoundButton]);

  const handleNextTurn = async () => {
    if (opponentIsArtificialIntelligence && isOpponentRound) return;
    await nextTurn();
    setShowNextRoundButton(false);
  };

  const onlyBattleCards = useMemo(
    () => battleCards.filter(card => card !== null && card !== undefined && card !== 'avatar') as [CardType, CardType],
    [battleCards]
  );

  const nextTurnButtonActive = highlightNextTurn || isGameActionHighlight('next-turn-button');

  const handleSurrender = async () => {
    const { gameConfig } = route.params;
    if (!gameConfig) return;
    const state = navigation.getState();
    if (state.routes.length > 0) {
      await unloadSound();
      if (state.routes[0].name === 'GameBoardInitializer') {
        return navigation.navigate('GameBoardInitializer');
      }
    }

    const navigateWinLose = (gameConfig: GameBoardConfiguration, winner: boolean, level: Level | undefined, previousProgress: Progress) =>
      navigation.navigate('WinLoseScreen', {
        gameConfig,
        winner,
        level,
        previousProgress
      });

    const handleShowModalTime = () => dispatch(showTimeModal());
    await unloadSound();
    const result = await updateProgressOnSurrendered({ gameConfig, handleShowModalTime, round });
    if (!result) return navigation.navigate('Campaign');
    const { gameConfig: newGameConfig, level, progress } = result;
    navigateWinLose(newGameConfig, false, level, progress);
  };

  return (
    <AspectRatioView style={{ backgroundColor: 'black' }}>
      <GameBoardContext.Provider key="game-board-context" value={{ appliedRules }}>
        {checkShowBattleModal && <TimeModal />}
        <DraxProvider style={styles.container}>
          <GestureHandlerRootView style={styles.containerDrag}>
            <ImageBackground
              style={styles.background}
              source={isNativeOrPWA ? gameBoardBackgroundMobile : gameBoardBackground1}
              resizeMode="cover"
            />
            {cardDetailModalVisible && (
              <TouchableWithoutFeedback onPress={onCloseDetailModal}>
                <Animated.View
                  nativeID={'detailCard'}
                  style={[
                    styles.cardDetailModalContent,
                    detailCardPosition,
                    {
                      transform: [
                        {
                          translateY: detailRef
                        }
                      ]
                    }
                  ]}
                >
                  <View pointerEvents="none" style={styles.cardDetailContainer}>
                    {detailCard}
                  </View>
                </Animated.View>
              </TouchableWithoutFeedback>
            )}
            <AvatarsContainer {...propsAvatarsContainer} />
            {isNativeOrPWA ? <OpponentHandOfCardsMobile {...handOfCards} /> : <OpponentHandOfCards {...handOfCards} />}
            {isNativeOrPWA ? <PlayerHandOfCardsMobile {...handOfCards} /> : <PlayerHandOfCards {...handOfCards} />}
            {isNativeOrPWA ? <BoardCardsMobile {...propsBoardCards} /> : <BoardCards {...propsBoardCards} />}
            <BoardActionPointsContainer
              playerMaxActionPoints={playerMaxActionPoints}
              playerActionPoints={playerActionPoints}
              opponentMaxActionPoints={opponentMaxActionPoints}
              opponentActionPoints={opponentActionPoints}
            />
            <TravellingCards
              playerTravellingCardPosition={playerTravellingCardPosition}
              playerCardIsTravelling={playerCardIsTravelling}
              opponentTravellingCardPosition={opponentTravellingCardPosition}
              opponentCardIsTravelling={opponentCardIsTravelling}
            />
            <NextRoundButton
              nextTurnButtonActive={nextTurnButtonActive}
              showNextRoundButton={showNextRoundButton}
              setDisableButton={setDisableButton}
              handleNextTurn={handleNextTurn}
              disableButton={disableButton}
            />
            {LeftoverModal !== undefined && <LeftoverModal closeModal={leftoverModal.closeModal} {...leftoverModal.modal?.props} />}
            {showDiscard && (
              <DiscardModal
                text={discardModalText}
                showDiscardModalButton={showDiscardModalButton}
                cards={discardCards}
                selectedToDiscardCards={selectedToDiscardCards}
                setSelectedToDiscardCards={setSelectedToDiscardCards}
                onConfirmDiscard={onConfirmDiscard}
                onGetAutoDiscardSelection={onGetAutoDiscardSelection}
                hiddenCards={hiddenCards}
                replaceCardDiscard={replaceCardDiscard}
              />
            )}
            {showCardSupportModal && activeSupportCardRef.current && (
              <GameboardModal>
                <CardSupportModalContent handleSelection={handleSelection} />
              </GameboardModal>
            )}
            {showAvatarModal && (
              <GameboardModal>
                <AvatarModalContent closeModal={handleCloseAvatarModal} />
              </GameboardModal>
            )}
            {showBattleModal && <CardsBattleModal cards={onlyBattleCards} />}
            {cardUpgrade && cardToUpgrade && (
              <UpgradeCardModal enemyKey={enemyKey!} cardToUpgrade={cardToUpgrade} cardUpgrade={cardUpgrade} />
            )}
          </GestureHandlerRootView>
          {tweetReactions.length > 0 && <TweetsContainer tweetsReactions={tweetReactions} />}
          <GameReactionContainer isFreeGame={!!route.params.gameConfig?.isFreeGame} />
          <GameHeader surrenderHandler={handleSurrender} />
          {yourTurn !== null && <RoundIndicator turn={yourTurn ? PlayerRoundEnum.PLAYER : PlayerRoundEnum.OPPONENT} />}
          <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail}></SnackBar>
        </DraxProvider>
      </GameBoardContext.Provider>
    </AspectRatioView>
  );
};

export default GameBoard;
