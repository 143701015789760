import { ReactNode } from 'react';
import { View, StyleSheet, ImageBackground, TouchableWithoutFeedback, Image, ViewStyle } from 'react-native';
import { closeIconBlack, irregularModalContainer } from '../../constants/Images';
import { isNativeOrPWA, isPWA, isPortrait } from '../utils';
import { useRatioDimensions } from './AspectRatioView/useRatioDimensions';
import ButtonShape from './ButtonShape/ButtonShape';

interface ModalProps {
  children: ReactNode;
  closeModal?: () => void;
  styleParent?: ViewStyle;
  modalSizes?: Partial<Pick<ViewStyle, 'width' | 'height' | 'minHeight' | 'minWidth' | 'maxHeight' | 'maxWidth'>>;
}

const Modal = ({ children, closeModal, styleParent, modalSizes }: ModalProps) => {
  const { rw, rh } = useRatioDimensions();
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
      backgroundColor: isNativeOrPWA ? '#494949' : 'rgba(0, 0, 0, 0.5)'
    },
    closeContainer: {
      zIndex: 2300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'absolute',
      right: isNativeOrPWA ? rw(6) : 60,
      top: isNativeOrPWA ? rw(2) : 30
    },
    modalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: rw(isNativeOrPWA ? 100 : 58),
      minHeight: 300,
      height: isNativeOrPWA ? rh(100) : rw(58) / 1.32
    },
    modalSize: {
      minWidth: 920,
      minHeight: isPortrait ? 500 : 700,
      maxWidth: 920,
      maxHeight: isNativeOrPWA ? '99%' : 700
    },
    mainContentContainer: {
      paddingHorizontal: isPWA ? 20 : '10%',
      width: '100%',
      height: isNativeOrPWA ? '100%' : '70%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonContainer: {
      marginHorizontal: rw(0.5)
    },
    button: {
      padding: rw(1),
      paddingBottom: rw(1.3),
      cursor: 'pointer'
    },
    closeImage: { height: isNativeOrPWA ? 18 : 30, width: isNativeOrPWA ? 18 : 30 }
  });

  return (
    <View style={styles.container}>
      <View style={[styles.modal, !isNativeOrPWA && styles.modalSize, modalSizes || {}]}>
        {!isNativeOrPWA && <ImageBackground style={styles.modalBackground} source={irregularModalContainer} resizeMode="stretch" />}
        {closeModal !== undefined && (
          <View style={styles.closeContainer}>
            <TouchableWithoutFeedback onPress={() => closeModal()}>
              <View style={styles.buttonContainer}>
                <ButtonShape square bgColor="white" border heightBtn={isNativeOrPWA ? 34 : 56}>
                  <View style={styles.button}>
                    <Image style={styles.closeImage} source={closeIconBlack} resizeMode="contain"></Image>
                  </View>
                </ButtonShape>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
        <View style={[styles.mainContentContainer, styleParent]}>{children}</View>
      </View>
    </View>
  );
};

export default Modal;
