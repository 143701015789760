import { StyleSheet, ImageBackground, TouchableWithoutFeedback, ImageStyle } from 'react-native';
import { useRatioDimensions } from '../../commons/components/AspectRatioView/useRatioDimensions';
import { View } from '../../commons/components/Themed';
import { nextTurnButton, nextTurnButtonHighlight, waitTurnButton } from '../../constants/Images';

const NextRoundButton = ({
  nextTurnButtonActive,
  showNextRoundButton,
  setDisableButton,
  handleNextTurn,
  disableButton
}: {
  nextTurnButtonActive: boolean;
  showNextRoundButton: boolean;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  handleNextTurn: () => Promise<void>;
  disableButton: boolean;
}) => {
  const { rw, rh } = useRatioDimensions();
  const styles = StyleSheet.create({
    nextTurnButtonContainer: {
      position: 'absolute',
      width: rw(6),
      height: rw(6),
      right: rw(9),
      cursor: 'pointer',
      top: rh(43)
    },
    nextTurnButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  });

  if (!showNextRoundButton)
    return (
      <View style={styles.nextTurnButtonContainer}>
        <ImageBackground style={styles.nextTurnButton} source={waitTurnButton} resizeMode={'contain'} />
      </View>
    );
  return (
    <TouchableWithoutFeedback
      disabled={disableButton}
      onPress={async () => {
        setDisableButton(true);
        await handleNextTurn();
      }}
    >
      <View style={styles.nextTurnButtonContainer}>
        <ImageBackground
          style={[
            styles.nextTurnButton,
            nextTurnButtonActive ? ({ filter: 'drop-shadow(0px 10px 10px rgba(32, 214, 181, 1))' } as ImageStyle) : {}
          ]}
          source={nextTurnButtonActive ? nextTurnButtonHighlight : nextTurnButton}
          resizeMode="contain"
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default NextRoundButton;
