const tintColorDark = '#fff';

export default {
  // Same colors schema for light and dark theme
  dark: {
    text: '#fff',
    background: '#363636',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark
  },
  light: {
    text: '#fff',
    background: '#363636',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark
  }
};
