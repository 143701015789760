/* eslint-disable react-native/no-unused-styles */
import { LinearGradient } from 'expo-linear-gradient';
import { useMemo } from 'react';
import { ImageBackground, StyleSheet, View, Image } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { educationalCard } from '../../../constants/Images';
import { isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import TextWithHTML from '../TextWithHTML';

interface EducationalCardInfoProps {
  text: string;
}

const EducationalCardInfo = ({ text }: EducationalCardInfoProps) => {
  const { bottom, middle, top, triangle } = educationalCard;
  const { rw } = useRatioDimensions();
  const style = useMemo(
    () =>
      StyleSheet.create({
        side: {
          height: 22,
          flexShrink: 0,
          flexGrow: 1,
          width: '100%'
        },
        middle: {
          minHeight: 60,
          height: '100%',
          marginTop: -1
        },
        text: {
          fontSize: isNativeOrPWA ? 12 : 14,
          fontWeight: '500',
          color: '#1E1E1E',
          marginLeft: isNativeOrPWA ? 12 : 24,
          marginRight: isNativeOrPWA ? 12 : 24,
          marginVertical: isNativeOrPWA ? 21 : 38,
          lineHeight: isNativeOrPWA ? 16 : 20
        },
        triangle: {
          position: 'absolute',
          left: isNativeOrPWA ? -14 : -22,
          bottom: isNativeOrPWA ? 0 : -4,
          width: isNativeOrPWA ? 24 : 32,
          height: isNativeOrPWA ? 24 : 32
        },
        infoIcon: {
          zIndex: 300,
          width: isNativeOrPWA ? rw(4) : rw(3),
          height: isNativeOrPWA ? rw(4) : rw(3),
          marginRight: 12
        },
        containerStyle: {
          position: 'absolute',
          left: '110%',
          top: 0,
          bottom: isNativeOrPWA ? 9 : 18,
          width: rw(30),
          maxWidth: 420
        },
        textUrl: {
          fontWeight: '600',
          color: '#0FD7E6',
          textDecorationLine: 'underline'
        },
        textBold: {
          fontWeight: 'bold'
        },
        textItalic: {
          fontStyle: 'italic'
        }
      }),
    []
  );

  const htmlStyles = useMemo(
    () => ({
      anchor: style.textUrl,
      bold: style.textBold,
      italic: style.textItalic,
      orderedList: {},
      unorderedList: {}
    }),
    [style.textUrl, style.textBold, style.textItalic]
  );

  const generalStyle = useMemo(() => style.text, [style.text]);

  return (
    <View style={style.containerStyle}>
      <ImageBackground source={top} style={style.side} resizeMode="stretch" />
      <ImageBackground source={middle} style={style.middle} resizeMode="stretch">
        <LinearGradient
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            zIndex: 100,
            height: isNativeOrPWA ? 22 : 46
          }}
          colors={['#FFF', 'rgba(255, 255, 255, 0)']}
          pointerEvents="none"
        />
        <LinearGradient
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            height: isNativeOrPWA ? 22 : 46
          }}
          colors={['rgba(255, 255, 255, 0)', '#FFF']}
          pointerEvents="none"
        />
        <ScrollView showsVerticalScrollIndicator={false} style={{ height: '100%', width: '100%' }}>
          <TextWithHTML text={text} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
        </ScrollView>
      </ImageBackground>
      <Image source={triangle} style={style.triangle} resizeMode="contain" />
      <ImageBackground source={bottom} style={[style.side, { marginTop: -1 }]} resizeMode="stretch" />
    </View>
  );
};

export default EducationalCardInfo;
