import { StyleSheet, View, Image, TouchableWithoutFeedback, Text } from 'react-native';
import { jump } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import { useJumpGame } from '../useJumpGame';

const Jump = () => {
  const styles = StyleSheet.create({
    skipContainer: {
      flexDirection: 'row',
      position: 'absolute',
      right: isNativeOrPWA ? 10 : 20,
      top: 20,
      width: 100,
      maxHeight: 48,
      cursor: 'pointer',
      alignItems: 'center',
      elevation: 10,
      zIndex: 1
    },
    skipImage: {
      width: 17,
      height: 17
    },
    skipText: {
      fontWeight: '500',
      fontSize: isNativeOrPWA ? 16 : 22,
      color: '#FFF',
      marginLeft: 8
    }
  });
  const { goToGame } = useJumpGame();

  return (
    <TouchableWithoutFeedback onPress={() => goToGame()}>
      <View style={styles.skipContainer}>
        <Image source={jump} resizeMode="contain" style={styles.skipImage} />
        <Text style={styles.skipText}>Saltar</Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default Jump;
