import type { Card as CardType } from '../dtos/card.dto';
import { PlayerRoundEnum } from '../dtos/player.dto';
import { TypesEnum } from '../dtos/type.dto';
import CardAccesoryLayout from './Card/CardAccessoryLayout';
import SelectedCard from './Card/CardTypes/SelectedCard';
import { CardSizesEnum } from './Card/utils';

interface CardWithAccesoryProps {
  card: CardType;
  enemyKey: string;
  side: PlayerRoundEnum;
  show?: boolean;
  animated?: boolean;
  hover?: boolean;
}

const CardWithAccesory = ({ card, enemyKey, side, show = true, animated = false, hover = false }: CardWithAccesoryProps) => {
  const { type, fakeText, tweet, isUndetectable } = card;
  const showFakeText = type?.typeName === TypesEnum.FAKE && !!show && !!fakeText;

  return (
    <>
      <SelectedCard card={card} cardSize={CardSizesEnum.VERY_BIG} showTagsInfo={hover} />
      {showFakeText ? (
        <CardAccesoryLayout
          actorKey={enemyKey}
          isUndetectable={isUndetectable}
          text={fakeText}
          isPlayer={side === PlayerRoundEnum.PLAYER}
          tweet={tweet}
          animated={animated}
        />
      ) : null}
    </>
  );
};

export default CardWithAccesory;
