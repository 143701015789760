import * as WebBrowser from 'expo-web-browser';
import { useEffect, useState } from 'react';
import { StyleSheet, ImageBackground, Image } from 'react-native';
import { Snackbar } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import useUnits from 'rxn-units';
import ExternalHeader from '../../commons/components/ExternalHeader/ExternalHeader';
import LegalFooter from '../../commons/components/LegalFooter';
import { View, Text } from '../../commons/components/Themed';
import { EventLogs } from '../../commons/dtos/create-log.dto';
import { PlayerTypesEnum } from '../../commons/dtos/player.dto';
import { isNativeMobile, isNativeOrPWA, isPWA } from '../../commons/utils';
import { LoginFormBackgroundImage, LoginBackgroundImage, catLogin, familyLogin, GameLogo, errorBot } from '../../constants/Images';
import { GoogleLoginInfo, useGoogle } from '../../hooks/useGoogle';
import { useLogin } from '../../hooks/useLogin';
import { actions, User } from '../../redux/Auth/authRedux';
import { setProfile } from '../../redux/Profile/profileReducer';
import LoginService from '../../services/login.service';
import LogsService from '../../services/logs.service';
import LoginContinue from './LoginForm/LoginContinue';
import LoginScreenInit from './LoginForm/LoginScreenInit';
import PWALink from './PWALink/PWALink';

const loginService = new LoginService();
const logService = new LogsService();

WebBrowser.maybeCompleteAuthSession();

const Login = () => {
  const { vw, vh } = useUnits();
  const [showPWALink, setShowPWALink] = useState<boolean>(false);

  const { backStep, error, handleError, handleMessage, message, nextStep, step, handleStep } = useLogin({ update: false });

  const { responseToken, signInGoogle, checkUser } = useGoogle();

  const dispatch = useDispatch();

  const signInGuest = async () => {
    try {
      const timestamp = new Date().getTime();
      const { guestToken, guestUser } = await loginService.getGuestPlayer(
        process.env.GOOGLE_OAUTH_CLIENT_ID ?? (GOOGLE_OAUTH_CLIENT_ID as string)
      );
      const { id: playerId, email, name: userId } = guestUser;
      const newUser: User = {
        playerId,
        email,
        userId,
        dateBorn: undefined,
        idToken: guestToken,
        expires: timestamp + 3600 * 1000,
        type: PlayerTypesEnum.GUEST
      };
      dispatch(actions.setUser(newUser));
      handleStep(2);
    } catch (error) {
      throw new Error(`Error signing in: ${error as Error}`);
    }
  };

  const screenDate = async (result: GoogleLoginInfo): Promise<void> => {
    const res = await checkUser(result);
    if (!res || res.player === null) return handleStep(2);
    logService.createLog(EventLogs.LOGIN);
    const { user, player } = res;
    dispatch(setProfile(player));
    dispatch(actions.login({ ...user, playerId: player.id }, user.idToken));
  };

  const googleLogin = async (): Promise<void> => {
    const result = await signInGoogle();
    if (!result) return;
    await screenDate(result);
  };

  useEffect(() => {
    if (isPWA && !isNativeMobile && process.env.NODE_ENV === 'production') {
      setShowPWALink(true);
      if (window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches) {
        setShowPWALink(false);
      }
    }
  }, [responseToken]);

  const styles = StyleSheet.create({
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '95%',
      height: '90%',
      alignItems: 'center',
      overflow: 'hidden',
      paddingVertical: isNativeOrPWA ? 6 : 20
    },
    login: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      position: 'relative',
      flex: 1,
      height: '100%',
      width: '100%',
      maxHeight: isNativeOrPWA ? vh(84) : vh(92),
      maxWidth: isNativeOrPWA ? vh(75) : vh(80),
      minWidth: isNativeMobile ? 360 : 320
    },
    background: {
      width: '100%',
      height: '100%'
    },
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      height: '100%'
    },
    snackBar: {
      backgroundColor: '#000',
      zIndex: 2000
    },
    familyImage: {
      maxWidth: 631,
      maxHeight: 890,
      position: 'absolute',
      left: -20,
      bottom: isNativeOrPWA ? -40 : -90,
      zIndex: 20,
      width: vh(68),
      height: vh(68) * 1.5,
      transform: [{ translateX: vh(68) > 631 ? -631 / 1.6 : -vh(68) / 1.4 }]
    },
    catImage: {
      maxWidth: 346,
      maxHeight: 402,
      position: 'absolute',
      bottom: isNativeOrPWA ? -15 : -40,
      right: 0,
      zIndex: 20,
      height: vh(45),
      width: vh(45) * 1.15,
      transform: [{ translateX: vh(45) > 346 ? 346 / 1.5 : vh(45) / 1.3 }]
    },
    gameLogo: {
      display: 'flex',
      position: 'absolute',
      top: isNativeOrPWA ? 10 : 30,
      left: isNativeOrPWA ? 18 : 60,
      maxHeight: isNativeOrPWA ? 66 : 194,
      maxWidth: isNativeOrPWA ? 145 : 426,
      width: vw(8) * 2.2,
      height: vw(8),
      zIndex: 10000
    },
    imageContainerCat: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      height: '100%'
    },
    imageContainerFamily: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      height: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0
    },
    containerMainContent: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    footerContainer: {
      alignItems: 'center',
      flexDirection: 'row'
    }
  });

  if (showPWALink) return <PWALink />;

  return (
    <View style={styles.container}>
      {!showPWALink && (
        <ImageBackground style={styles.background} source={LoginBackgroundImage} resizeMode="cover">
          {step === 1 && <ExternalHeader current="login" />}
          {step >= 2 && <Image source={GameLogo} accessible accessibilityLabel="Game logo" style={styles.gameLogo} />}
          <View style={styles.containerMainContent}>
            <ImageBackground style={styles.login} source={LoginFormBackgroundImage} resizeMode={'stretch'}>
              <View style={styles.form}>
                {step < 2 ? (
                  <LoginScreenInit signInGoogle={googleLogin} signInGuest={signInGuest} />
                ) : (
                  <LoginContinue
                    nextStep={nextStep}
                    backStep={backStep}
                    setMessage={handleMessage}
                    step={step}
                    error={error}
                    setError={handleError}
                  />
                )}
              </View>

              <View style={styles.imageContainerCat} pointerEvents="none">
                {error ? (
                  <Image style={styles.catImage} resizeMode="contain" source={errorBot} accessible accessibilityLabel="bot image" />
                ) : (
                  <Image style={styles.catImage} source={catLogin} resizeMode="contain" accessible accessibilityLabel="cat image" />
                )}
              </View>
              <View style={styles.imageContainerFamily} pointerEvents="none">
                <Image style={styles.familyImage} source={familyLogin} resizeMode="contain" accessible accessibilityLabel="family image" />
              </View>
            </ImageBackground>
          </View>
          <View style={styles.footerContainer}>
            <LegalFooter />
          </View>
          <Snackbar
            duration={1500}
            visible={message.length > 0}
            action={{
              label: 'CERRAR',
              onPress: () => handleMessage('')
            }}
            onDismiss={() => handleMessage('')}
          >
            <View style={styles.snackBar}>
              <Text>{message}</Text>
            </View>
          </Snackbar>
        </ImageBackground>
      )}
    </View>
  );
};

export default Login;
