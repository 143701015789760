import { getRandomUUID } from '../utils';
import { Card } from './card.dto';
import { Collection } from './collection.dto';
import { Rarity } from './rarity.dto';
import { Subject } from './subject.dto';
import { Tag } from './tag.dto';
import { Tweet } from './tweet.dto';
import { Type } from './type.dto';

export class Token implements TokenProperties {
  id!: string;
  tokenName!: string;
  tokenCode!: string;
  tokenText!: string;
  explanation!: string;
  fakeText!: string;
  illustration!: string;
  color!: number;
  actionPoints!: number;
  damagePoints!: number;
  defensePoints!: number;
  collection!: Collection | null;
  rarity!: Rarity | null;
  subject!: Subject | null;
  type!: Type | null;
  tags!: Tag[] | null;
  useRequirementColor?: number | null;
  useRequirementType?: Type | null;
  useRequirementSubject?: Subject | null;
  requirementIsBonus?: number;

  constructor(properties?: TokenProperties) {
    if (properties) {
      this.id = properties.id;
      this.tokenName = properties.tokenName;
      this.tokenCode = properties.tokenCode;
      this.tokenText = properties.tokenText;
      this.explanation = properties.explanation;
      this.fakeText = properties.fakeText;
      this.illustration = properties.illustration;

      // Those properties remains with same value
      this.color = properties.color;
      this.actionPoints = properties.actionPoints;
      this.damagePoints = properties.damagePoints;
      this.defensePoints = properties.defensePoints;

      this.collection = properties.collection ? new Collection(properties.collection) : null;
      this.rarity = properties.rarity ? new Rarity(properties.rarity) : null;
      this.subject = properties.subject ? new Subject(properties.subject) : null;
      this.type = properties.type ? new Type(properties.type) : null;
      if (properties.tags && properties.tags.length > 0) this.tags = properties.tags.map(tag => new Tag(tag));
      this.useRequirementColor = properties.useRequirementColor;
      this.useRequirementSubject = properties.useRequirementSubject;
      this.useRequirementType = properties.useRequirementType;
      this.requirementIsBonus = properties.requirementIsBonus;
    }
  }

  tokenToCard(tokenDamagePoints: number, tokenDefensePoints: number): Card {
    const UUID = getRandomUUID();
    const updatedId = `${this.id}-token-${UUID}`;
    const newCard: Card = {
      id: updatedId,
      idUnique: updatedId,
      cardName: this.tokenName,
      cardCode: this.tokenCode,
      cardText: this.tokenText,
      explanation: this.explanation,
      illustration: this.illustration,
      color: this.color,
      fakeText: this.fakeText,
      tweet: new Tweet(),
      actionPoints: this.actionPoints,
      damagePoints: this.damagePoints + tokenDamagePoints,
      defensePoints: this.defensePoints + tokenDefensePoints,
      collection: this.collection,
      rarity: this.rarity,
      subject: this.subject,
      type: this.type,
      tags: this.tags,
      isPlayingCard: true,
      isWaiting: true,
      isUndetectable: false,
      firstTurn: true,
      useRequirementColor: this.useRequirementColor,
      useRequirementSubject: this.useRequirementSubject,
      useRequirementType: this.useRequirementType,
      requirementIsBonus: this.requirementIsBonus,
      rules: null,
      enhance: null,
      token: null,
      isToken: true,
      upgradedFirstTurn: false
    };
    return new Card({ ...newCard });
  }
}

export interface TokenProperties {
  id: string;
  tokenName: string;
  tokenCode: string;
  tokenText: string;
  fakeText: string;
  explanation: string;
  illustration: string;
  color: number;
  actionPoints: number;
  damagePoints: number;
  defensePoints: number;
  collection: Collection | null;
  rarity: Rarity | null;
  subject: Subject | null;
  type: Type | null;
  tags: Tag[] | null | null;
  useRequirementColor?: number | null;
  useRequirementType?: Type | null;
  useRequirementSubject?: Subject | null;
  requirementIsBonus?: number;
}
