export enum RewardTypeEnum {
  AVATAR = 'avatar',
  CARD = 'card',
  CARD_PACK = 'cardPack',
  TITLE = 'title'
}

export enum RewardRarityEnum {
  SILVER = 'plata',
  GOLD = 'oro',
  PLATINUM = 'platino'
}

export enum RewardColorEnum {
  WHITE = 0,
  BLACK = 1
}

/**
 * Reward variable type declaration.
 *
 * @interface
 */
export class Reward {
  id?: string;
  type!: RewardTypeEnum;
  quantity?: number;
  rarity?: RewardRarityEnum;
  color?: RewardColorEnum;
  rewardId?: string;

  constructor(properties?: RewardProperties) {
    if (properties) {
      this.id = properties.id;
      this.type = properties.type;
      this.quantity = properties.quantity;
      this.rarity = properties.rarity;
      this.color = properties.color;
      this.rewardId = properties.rewardId;
    }
  }
}

export interface RewardProperties {
  id?: string;
  type: RewardTypeEnum;
  quantity?: number;
  rarity?: RewardRarityEnum;
  color?: RewardColorEnum;
  rewardId?: string;
}
