import axios from 'axios';
import { GuestToken, Player, GoogleAccessTokenResponse } from '../commons/dtos/player.dto';

const base = process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL;

export default class LoginService {
  createPlayer = async (player: Pick<Player, 'email' | 'playerName' | 'gender' | 'type' | 'dateBorn'>): Promise<Player> => {
    return (await axios.post(`${base}/players`, player)).data;
  };
  getPlayerByEmail = async (email: string): Promise<Player> => {
    return (await axios.get(`${base}/players/email/${email}`)).data as Player;
  };
  getPlayerByNickname = async (nickname: string): Promise<Player> => {
    return (await axios.get(`${base}/players/name/${nickname}`)).data;
  };
  getGuestPlayer = async (appId: string): Promise<GuestToken> => {
    return (await axios.get(`${base}/players/guest-token/${appId}`)).data;
  };
  googleRefreshToken = async (refreshToken: string): Promise<string> => {
    return (await axios.post(`${base}/players/google-refresh-token`, { refreshToken })).data;
  };
  googleAccessToken = async (code: string, redirectUri: string, codeVerifier: string): Promise<GoogleAccessTokenResponse> => {
    return (await axios.post(`${base}/players/google-access-token`, { code, redirectUri, codeVerifier })).data as GoogleAccessTokenResponse;
  };
}
