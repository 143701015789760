import { useState } from 'react';

export function useGameboardModal() {
  const [showGameboardModal, setShowGameboardModal] = useState<boolean>(false);

  const handleCloseModal = () => setShowGameboardModal(false);

  const handleShowModal = () => setShowGameboardModal(true);

  return {
    showGameboardModal,
    handleCloseModal,
    handleShowModal
  };
}
