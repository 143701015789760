import { View, StyleSheet, Text } from 'react-native';
import { Snackbar } from 'react-native-paper';
import { useSelector } from 'react-redux';

interface SnackBarProps {
  clearMessageError: Function;
  msg: string;
  detail?: string;
}

const SnackBar = ({ clearMessageError, msg, detail }: SnackBarProps) => {
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);
  const style = StyleSheet.create({
    msg: { backgroundColor: '#000', zIndex: 2000 },
    detail: {
      color: 'grey'
    }
  });

  if (detail && !checkShowLoginModal) return null;

  return (
    <Snackbar
      duration={detail ? 10000 : 1500}
      visible={msg.length > 0}
      action={{
        label: 'CERRAR',
        onPress: () => clearMessageError()
      }}
      onDismiss={() => clearMessageError()}
    >
      <View style={style.msg}>
        <Text>{msg}</Text>
        {detail ? <Text style={style.detail}>{detail}</Text> : null}
      </View>
    </Snackbar>
  );
};

export default SnackBar;
