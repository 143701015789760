import { setGameActions } from '../../../redux/GameBoard/gameAction';
import { addGameReaction } from '../../../redux/GameBoard/reactions';
import store from '../../../redux/store';
import { Deck, Hand } from '../../../screens/GameBoard/gameTypes';
import { PlayerTypeEnum } from '../../constants/player';
import { Card } from '../../dtos/card.dto';
import { ReactionConfiguration, ReactionEventEnum } from '../../dtos/reaction-configuration.dto';
import { SubjectsEnum } from '../../dtos/subject.dto';
import { TypesEnum } from '../../dtos/type.dto';
import { wait } from '../../utils';
import { filterReactions } from '../utils';
import {
  filterAvatarDamagedDialogues,
  filterCardDamagedDialogues,
  filterCardDestroyedDialogues,
  filterDamageCardDialogues,
  filterDestroyCardDialogues,
  filterPlayCardDialogues,
  filterRoundStartDialogues,
  filterWinDialogues
} from './reactions-filters';
import { ReactionsManagementProps, getPlayerByReactionEvent } from './reactions-validators';

type ExtractCardFunction = (
  player: number,
  hand: Hand,
  deck: Deck,
  type?: TypesEnum,
  subject?: SubjectsEnum,
  isReactionExtract?: boolean,
  actionPoints?: number
) => Promise<[Hand, Deck]>;

interface LaunchReaction {
  reactionEvent: ReactionEventEnum;
  reactionContext: ReactionsManagementProps;
  card?: Card;
  attackingCard?: Card;
  extractCard?: ExtractCardFunction;
  deck?: Deck;
  hand?: Hand;
}

export async function launchReactions(
  reactionContext: ReactionsManagementProps,
  reactionEvents: ReactionEventEnum[],
  card?: Card,
  attackingCard?: Card,
  extractCard?: ExtractCardFunction
): Promise<void> {
  if (!reactionContext.reactionsConfiguration) return;
  if (!reactionContext.reactionsConfiguration.length) return;

  await waitForReactions();

  for (const reactionEvent of reactionEvents) {
    await launchReaction({
      reactionContext,
      reactionEvent,
      card,
      attackingCard,
      extractCard
    });
  }
}

async function launchReaction(launchReaction: LaunchReaction) {
  let reactions: ReactionConfiguration[] = [];
  const { reactionEvent, reactionContext } = launchReaction;
  const reactionFiltered = filterReactions(reactionContext.reactionsConfiguration, reactionEvent);
  const copyReactionContext: ReactionsManagementProps = { ...reactionContext, reactionsConfiguration: reactionFiltered };
  switch (reactionEvent) {
    case ReactionEventEnum.PLAYER_ROUND_START:
    case ReactionEventEnum.OPPONENT_ROUND_START:
      reactions = filterRoundStartDialogues(copyReactionContext);
      break;
    case ReactionEventEnum.PLAYER_GET_CARD:
    case ReactionEventEnum.OPPONENT_GET_CARD:
      reactions = filterPlayCardDialogues(copyReactionContext, launchReaction.card);
      break;
    case ReactionEventEnum.PLAYER_PLAY_CARD:
    case ReactionEventEnum.OPPONENT_PLAY_CARD:
      reactions = filterPlayCardDialogues(copyReactionContext, launchReaction.card);
      break;
    case ReactionEventEnum.PLAYER_DESTROY_CARD:
      reactions = filterDestroyCardDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.PLAYER);
      break;
    case ReactionEventEnum.OPPONENT_DESTROY_CARD:
      reactions = filterDestroyCardDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.OPPONENT);
      break;
    case ReactionEventEnum.PLAYER_CARD_DESTROYED:
      reactions = filterCardDestroyedDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.PLAYER);
      break;
    case ReactionEventEnum.OPPONENT_CARD_DESTROYED:
      reactions = filterCardDestroyedDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.OPPONENT);
      break;
    case ReactionEventEnum.PLAYER_DAMAGE_CARD:
      reactions = filterDamageCardDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.PLAYER);
      break;
    case ReactionEventEnum.OPPONENT_DAMAGE_CARD:
      reactions = filterDamageCardDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.OPPONENT);
      break;
    case ReactionEventEnum.PLAYER_AVATAR_DAMAGED: {
      reactions = filterAvatarDamagedDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.PLAYER);
      break;
    }
    case ReactionEventEnum.OPPONENT_AVATAR_DAMAGED: {
      reactions = filterAvatarDamagedDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.OPPONENT);
      break;
    }
    case ReactionEventEnum.PLAYER_CARD_DAMAGED:
      reactions = filterCardDamagedDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.PLAYER);
      break;
    case ReactionEventEnum.OPPONENT_CARD_DAMAGED:
      reactions = filterCardDamagedDialogues(copyReactionContext, launchReaction.card, PlayerTypeEnum.OPPONENT);
      break;
    case ReactionEventEnum.PLAYER_WIN:
    case ReactionEventEnum.OPPONENT_WIN:
      reactions = filterWinDialogues(copyReactionContext);
      break;
    default:
      break;
  }
  reactions.forEach(async reactionConfig => {
    for (const reaction of reactionConfig.reactions) {
      store.dispatch(addGameReaction(reaction));
      if (reaction.actions !== undefined) store.dispatch(setGameActions(reaction.actions));
    }
    if (reactionConfig.trigger.extractCard && launchReaction.extractCard) {
      const player = getPlayerByReactionEvent(reactionConfig.trigger.event);
      const { hand, deck } = launchReaction;
      await launchReaction.extractCard(player, hand ?? [], deck ?? [], undefined, undefined, true);
    }
  });
  if (reactions.length === 0) return;
  await waitForReactions();
}

// TODO Think about that
export async function waitForReactions() {
  while (store.getState().reactions.currentReaction) {
    await wait(300);
  }
}
