/* eslint-disable @typescript-eslint/no-var-requires */
import { StyleSheet, Image, TouchableWithoutFeedback, ImageBackground } from 'react-native';
import useUnits from 'rxn-units';
import ButtonShape from '../../../commons/components/ButtonShape/ButtonShape';
import { View, Text } from '../../../commons/components/Themed';
import { DeckIncludesCard } from '../../../commons/dtos/deck-includes-card.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import DeckCardsViewer from './DeckCardsViewer/DeckCardsViewer';
import DeckStatsViewer from './DeckStatsViewer/DeckStatsViewer';

const deckImage = require('../../../assets/images/components/deck-editor/deck-viewer/deck-viewer-bg.png');
const pencil = require('../../../assets/images/icons/deck-editor/deck-viewer/pencil-black.png');
interface DeckViewerProps {
  onRemoveCardFromDeck: Function;
  deckName: string;
  setDeckName: Function;
  deckImage: any;
  setDeckImage: Function;
  deckCards: DeckIncludesCard[];
  onSaveDeck: Function;
  showModal: Function;
  showSaveButton: Boolean;
}

const DeckViewer = (props: DeckViewerProps) => {
  const { vw, vh } = useUnits();

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      paddingLeft: '1%',
      paddingRight: '2%',
      width: '24%',
      height: '88%',
      right: 15,
      bottom: 0,
      color: 'white',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    blockImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    deckNameEditor: {
      marginTop: '5%',
      marginBottom: '2.5%',
      height: '8%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'transparent',
      borderWidth: 1
    },
    deckName: {
      fontWeight: '600',
      fontSize: isNativeOrPWA ? 12 : 20,
      paddingHorizontal: 5
    },
    editDeckNameButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 500,
      borderColor: 'rgba(255, 255, 255, 0.5)',
      cursor: 'pointer',
      height: isNativeOrPWA ? 18 : 30,
      width: isNativeOrPWA ? 18 : 30,
      marginHorizontal: vw(0.8)
    },
    deckStatsViewer: {
      marginVertical: '2.5%',
      maxHeight: 40,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'transparent',
      borderWidth: 1,
      position: 'relative'
    },
    deckCardsViewer: {
      marginVertical: '2.5%',
      flex: 1,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'transparent',
      borderWidth: 1
    },
    margin: {
      marginLeft: isNativeOrPWA ? 8 : 14
    },
    pencil: { width: isNativeOrPWA ? 12 : 16, height: isNativeOrPWA ? 12 : 16 },
    saveButtonContainer: {
      width: '80%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    saveDeckButtonContainer: {
      marginVertical: '2.5%',
      height: '9%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: 'transparent',
      borderWidth: 1,
      bottom: vh(2)
    },
    saveDeckButton: {
      width: '100%',
      height: '55%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative'
    },
    saveDeckButtonText: {
      fontWeight: 'bold',
      fontSize: vw(1.2),
      paddingHorizontal: vw(1.5)
    }
  });

  return (
    <View style={styles.container}>
      <ImageBackground style={styles.blockImage} source={deckImage} resizeMode="stretch" />
      <View style={styles.deckNameEditor}>
        <Text numberOfLines={1} style={styles.deckName}>
          {props.deckName || 'Nombre mazo'}
        </Text>
        <TouchableWithoutFeedback
          onPress={() => {
            props.showModal();
          }}
        >
          <View style={styles.margin}>
            <ButtonShape bgColor="white" square heightBtn={isNativeOrPWA ? 22 : 30}>
              <View style={styles.editDeckNameButton}>
                <Image source={pencil} style={styles.pencil} resizeMode="contain" />
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
      <View style={styles.deckStatsViewer}>
        <DeckStatsViewer deckCards={props.deckCards} />
      </View>
      <View style={styles.deckCardsViewer}>
        <DeckCardsViewer deckCards={props.deckCards} onRemoveCardFromDeck={props.onRemoveCardFromDeck} />
      </View>
      <View style={styles.saveDeckButtonContainer}>
        {props.showSaveButton && (
          <TouchableWithoutFeedback onPress={() => props.onSaveDeck()}>
            <View style={styles.saveButtonContainer}>
              <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 52}>
                <View style={styles.saveDeckButton}>
                  <Text style={styles.saveDeckButtonText}>Guardar mazo</Text>
                </View>
              </ButtonShape>
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
    </View>
  );
};

export default DeckViewer;
