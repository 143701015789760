import { useEffect, useRef } from 'react';
import { StyleSheet, View, Image, Animated, Text, Platform, Easing } from 'react-native';
import PolyShape from '../../commons/components/PolyShape/PolyShape';
import { PlayerRoundEnum } from '../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../commons/utils';
import { thunder } from '../../constants/Images';

const fadeIn: Animated.TimingAnimationConfig = {
  toValue: 1,
  duration: 800,
  easing: Easing.elastic(2),
  useNativeDriver: Platform.OS !== 'web'
};
const fadeOut: Animated.TimingAnimationConfig = {
  toValue: 0,
  duration: 600,
  easing: Easing.elastic(2),
  useNativeDriver: Platform.OS !== 'web'
};

interface RoundIndicatorProps {
  turn: PlayerRoundEnum;
}

const RoundIndicator = ({ turn }: RoundIndicatorProps) => {
  const scale = useRef(new Animated.Value(0.75)).current;
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const animationFadeIn = Animated.parallel([Animated.timing(opacity, fadeIn), Animated.timing(scale, fadeIn)]);
    const animationFadeOut = Animated.parallel([Animated.timing(opacity, fadeOut), Animated.timing(scale, fadeOut)]);
    animationFadeIn.start();
    const timeout = setTimeout(() => animationFadeOut.start(), 1000);
    return () => clearTimeout(timeout);
  }, [turn]);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden'
    },
    containerImages: {
      zIndex: 1000,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      height: isNativeOrPWA ? 90 : '100%',
      width: isNativeOrPWA ? 340 : '100%',
      maxHeight: 150,
      maxWidth: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    mainContent: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      transform: [{ rotate: '-1deg' }]
    },
    title: {
      fontSize: isNativeOrPWA ? 98 : 150,
      fontFamily: 'Inlanders',
      color: '#2F3B44',
      letterSpacing: isNativeOrPWA ? -7 : -12,
      transform: [{ rotate: '-1deg' }]
    },
    thunder: {
      position: 'absolute',
      top: isNativeOrPWA ? -45 : -77,
      right: isNativeOrPWA ? -45 : -77,
      zIndex: 1,
      width: isNativeOrPWA ? 98 : 164,
      height: isNativeOrPWA ? 98 : 164
    }
  });

  return (
    <Animated.View pointerEvents="none" style={[styles.container, { opacity }]}>
      <Animated.View style={[styles.containerImages, { transform: [{ scale }] }]}>
        <Image source={thunder} resizeMode="contain" style={styles.thunder} />
        <View style={styles.mainContent}>
          <PolyShape type={2} bgColor={'white'}>
            <Text style={styles.title}>{turn === PlayerRoundEnum.PLAYER ? 'Tu Turno' : 'Oponente'}</Text>
          </PolyShape>
        </View>
      </Animated.View>
    </Animated.View>
  );
};

export default RoundIndicator;
