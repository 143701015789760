/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { useNetInfo } from '@react-native-community/netinfo';
import { NavigationContainer, DefaultTheme, DarkTheme, useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useKeepAwake } from 'expo-keep-awake';
import * as React from 'react';
import { useEffect } from 'react';
import { ColorSchemeName, Platform } from 'react-native';

import ErrorBoundary from 'react-native-error-boundary';
import { shallowEqual, useSelector } from 'react-redux';
import CookiesBanner from '../commons/components/CookiesBanner/CookiesBanner';
import LoadingMission from '../commons/components/LoadingMission/LoadingMission';
import LoginModal from '../commons/components/LoginModal/LoginModal';
import OfflineModal from '../commons/components/OfflineModal/OfflineModal';
import { isNativeMobile, isPWA } from '../commons/utils';
import { disableAspectRatio } from '../redux/AspectRatio/aspectRatio';
import { authProps } from '../redux/Auth/authRedux';
import { hideIsOnlineModal, showIsOnlineModal } from '../redux/Is-online/isOnline';
import { LoadingMissionBackgroundConfig } from '../redux/Loading-mission/loadingMissionBackground';
import store from '../redux/store';
import Login from '../screens/Auth/Login';
import LoginUpdate from '../screens/Auth/LoginUpdate';
import Logout from '../screens/Auth/Logout';
import Campaign from '../screens/Campaign/Campaign';
import CardInventoryViewer from '../screens/CardInventoryViewer/CardInventoryViewer';
import CookiesScreen from '../screens/Cookies/CookiesScreen/CookiesScreen';
import Credits from '../screens/Credits/Credits';
import DeckEditor from '../screens/DeckEditor/DeckEditor';
import DeckSelector from '../screens/DeckSelector/DeckSelector';
import ErrorScreen from '../screens/ErrorScreen/ErrorScreen';
import GameBoard from '../screens/GameBoard/GameBoard';
import LaunchBattle from '../screens/GameBoard/LaunchBattle/LaunchBattle';
import MissionDialogue from '../screens/GameBoard/MissionDialogue/MissionDialogue';
import WinLoseScreen from '../screens/GameBoard/WinLoseScreen';
import GameBoardInitializer from '../screens/GameBoardInitializer/GameBoardInitializer';
import Glossary from '../screens/Glossary/Glossary';
import Home from '../screens/Home/Home';
import HowToPlay from '../screens/HowToPlay/HowToPlay';
import Landing from '../screens/Landing/Landing';
import LegalScreen from '../screens/Legal/LegalScreen/LegalScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicy/PrivacyPolicyScreen/PrivacyPolicyScreen';
import Ranking from '../screens/Ranking/Ranking';
import Rewards from '../screens/Rewards/Rewards';
import TermsScreen from '../screens/Terms/TermsScreen/TermsScreen';
import Tutorial from '../screens/Tutorial/Tutorial';
import UserProfileScreen from '../screens/UserProfile/UserProfileScreen';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';

interface AppProps {
  colorScheme?: ColorSchemeName;
}

export default function Navigation({ colorScheme }: AppProps) {
  const { consent } = useSelector((s: { consent: { consent: boolean | null } }) => s.consent);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);
  const isOnline = useSelector((s: { isOnline: { isOnline: boolean } }) => s.isOnline.isOnline);
  const netInfo = useNetInfo();
  useEffect(() => {
    const isConnected = navigator.onLine || (netInfo.isConnected && netInfo.isInternetReachable);
    store.dispatch(isConnected ? hideIsOnlineModal() : showIsOnlineModal());
  }, [isOnline, netInfo]);

  useEffect(() => {
    store.dispatch(disableAspectRatio());
  }, []);

  const { loadingMissionBackground } = useSelector(
    ({ loadingMissionBackground: { loadingMissionBackground } }: { loadingMissionBackground: LoadingMissionBackgroundConfig }) => ({
      loadingMissionBackground
    })
  );
  const { isAuthorized } = useSelector(
    ({ auth }: authProps) => ({
      isAuthorized: auth.idToken != null
    }),
    shallowEqual
  );
  const errorHandler = (error: Error, stackTrace: string) => {
    // eslint-disable-next-line no-console
    console.error('Fallo general de la aplicación', error, stackTrace);
  };

  return (
    <NavigationContainer linking={LinkingConfiguration} theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <ErrorBoundary FallbackComponent={ErrorScreen} onError={errorHandler}>
        <RootNavigator />
        {consent === null && <CookiesBanner />}
        {loadingMissionBackground && <LoadingMission background={loadingMissionBackground} />}
        {isOnline ? isAuthorized && checkShowLoginModal && <LoginModal /> : <OfflineModal />}
      </ErrorBoundary>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.n fghjkjhgfghj
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const [statusPage, setStatusPage] = React.useState<string>();
  const navigation = useNavigation();
  const isProduction = ((isNativeMobile ? process.env.NODE_ENV ?? NODE_ENV : NODE_ENV ?? process.env.NODE_ENV) as string) === 'production';
  const state = navigation.getState();
  useKeepAwake();

  const { isAuthorized } = useSelector(
    ({ auth }: authProps) => ({
      isAuthorized: auth.idToken != null
    }),
    shallowEqual
  );

  useEffect(() => {
    if (Platform.OS === 'web' && !isPWA) {
      const statusPageNavigation =
        window.performance && (performance.getEntriesByType('navigation').shift() as PerformanceNavigationTiming);
      setStatusPage(statusPageNavigation?.type);
    }
    if (Platform.OS === 'web' && isPWA) {
      const wakeLockScreen = async () => {
        try {
          const wakeLock = await (navigator as any).wakeLock.request('screen');
          wakeLock.release();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      };
      wakeLockScreen();
    }
  });

  const { consent } = useSelector((s: any) => s.consent);

  useEffect(() => {
    if (!consent || !isProduction) return;
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-XZSCSLS9ER';
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.text =
      'window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); }; gtag("js", new Date()); gtag("config", "G-XZSCSLS9ER");';
    document.head.appendChild(script2);
  }, [consent]);

  const isReloaded = statusPage !== 'reload' && statusPage !== 'navigate' && !isPWA;
  const redirectToHome = isReloaded && isProduction;
  const currentRouteName = state?.routes.length > 0 ? [...state.routes]?.shift()?.name : '';
  const redirectToHomeDevelopment = isReloaded && !isProduction && currentRouteName && currentRouteName !== 'GameBoardInitializer';

  return (
    <Stack.Navigator screenOptions={{ headerShown: false, navigationBarHidden: true }}>
      <Stack.Group>
        {!isAuthorized ? (
          <Stack.Screen name="Login" component={Login} />
        ) : redirectToHome || redirectToHomeDevelopment ? (
          <Stack.Screen name="Home" component={Home} />
        ) : (
          <Stack.Group>
            <Stack.Screen name="Home" component={Home} />
            <Stack.Screen name="Campaign" component={Campaign} />
            <Stack.Screen name="CardInventoryViewer" component={CardInventoryViewer} />
            <Stack.Screen name="DeckEditor" component={DeckEditor} />
            <Stack.Screen name="DeckSelector" component={DeckSelector} />
            <Stack.Screen name="Logout" component={Logout} />
            <Stack.Screen name="RefreshToken" component={Login} />
            <Stack.Screen name="GameBoard" component={GameBoard} />
            <Stack.Screen name="GameBoardInitializer" component={GameBoardInitializer} />
            <Stack.Screen name="MissionDialogue" component={MissionDialogue} />
            <Stack.Screen name="LaunchBattle" component={LaunchBattle} />
            <Stack.Screen name="WinLoseScreen" component={WinLoseScreen} />
            <Stack.Screen name="Rewards" component={Rewards} />
            <Stack.Screen name="UserProfile" component={UserProfileScreen} />
            <Stack.Screen name="Tutorial" component={Tutorial} />
            <Stack.Screen name="Glossary" component={Glossary} />
            <Stack.Screen name="Credits" component={Credits} />
            <Stack.Screen name="LoginUpdate" component={LoginUpdate} />
          </Stack.Group>
        )}
        <Stack.Screen name="Landing" component={Landing} />
        <Stack.Screen name="HowToPlay" component={HowToPlay} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
        <Stack.Screen name="Legal" component={LegalScreen} />
        <Stack.Screen name="Terms" component={TermsScreen} />
        <Stack.Screen name="Cookies" component={CookiesScreen} />
        <Stack.Screen name="Ranking" component={Ranking} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
