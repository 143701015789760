import { StyleSheet, Text, View } from 'react-native';
import { DataTable } from 'react-native-paper';
import useUnits from 'rxn-units';

interface CookiesTableProps {
  title: string;
  data: { tableHead: string[]; tableData: any[] };
}

const CookiesTable = ({ title, data }: CookiesTableProps) => {
  const { vh } = useUnits();
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      marginVertical: vh(2)
    },
    text: {
      color: '#fff'
    },
    title: {
      padding: 10,
      textAlign: 'center',
      backgroundColor: '#718E89',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    table: {
      backgroundColor: '#324F4B'
    }
  });

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <DataTable style={styles.table}>
        <DataTable.Header>
          {data.tableHead.map((header: string) => (
            <DataTable.Title key={header}>
              <View>
                <Text style={[styles.text, { fontWeight: 'bold' }]}>{header}</Text>
              </View>
            </DataTable.Title>
          ))}
        </DataTable.Header>
        {data.tableData.map((row: any[], i: number) => (
          <DataTable.Row key={`row-${i}`}>
            {row.map((cell, j) => (
              <DataTable.Cell key={`cell-${j}/${i}`}>{cell}</DataTable.Cell>
            ))}
          </DataTable.Row>
        ))}
      </DataTable>
    </View>
  );
};

export default CookiesTable;
