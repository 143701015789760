import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { StyleSheet, ImageBackground, Image, TouchableWithoutFeedback, View, Text } from 'react-native';
import useUnits from 'rxn-units';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import adjust from '../../commons/styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../commons/utils';
import { errorBot, GameLogo, errorBoundaryBackground } from '../../constants/Images';
import { RootStackParamList } from '../../types';

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Home', undefined>;

interface ErrorScreenProps {
  resetError: Function;
}

const ErrorScreen = ({ resetError }: ErrorScreenProps) => {
  const { vh } = useUnits();

  const navigation = useNavigation<Navigation>();

  const goHome = () => {
    resetError();
    // Works in PWA and web
    if (!isNativeMobile) {
      window.history.replaceState({}, document.title, '/');
      window.location.reload();
    }
    navigation?.navigate('Home');
  };
  const styles = StyleSheet.create({
    container: {
      flexGrow: 1,
      position: 'relative',
      backgroundColor: '#313131',
      alignItems: 'center',
      justifyContent: 'center'
    },
    splitContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: 1280,
      margin: 'auto',
      height: isNativeOrPWA ? '100%' : vh(80),
      maxHeight: 540
    },
    splitView: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '50%',
      height: '100%'
    },
    gameLogoContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginVertical: isNativeOrPWA ? adjust(26) : 50,
      width: isNativeOrPWA ? 170 : 280,
      height: isNativeOrPWA ? 100 : 150
    },
    gameLogo: {
      width: isNativeMobile ? '100%' : 'auto',
      height: '100%'
    },
    botImage: {
      width: '100%',
      height: '100%'
    },
    shapeContainer: {
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      justifyContent: 'center',
      marginHorizontal: 'auto',
      width: 'auto',
      maxWidth: '50%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    shapeText: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(15) : 18,
      fontWeight: 'bold',
      color: '#ffffff',
      paddingBottom: 5,
      paddingHorizontal: isNativeOrPWA ? adjust(15) : 26
    },
    subTitle: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(16) : adjust(18),
      color: '#ffffff',
      marginVertical: isNativeOrPWA ? adjust(16) : adjust(18),
      lineHeight: isNativeOrPWA ? adjust(24) : adjust(26)
    }
  });

  return (
    <ImageBackground style={styles.container} source={errorBoundaryBackground} resizeMode="stretch">
      <View style={styles.splitContainer}>
        <View style={styles.splitView}>
          <View style={styles.gameLogoContainer}>
            <Image resizeMode="contain" style={styles.gameLogo} source={GameLogo} />
          </View>
          <Text style={styles.subTitle}>Ha ocurrido un error</Text>
          <View>
            <TouchableWithoutFeedback onPress={() => goHome()}>
              <View style={styles.shapeContainer}>
                <ButtonShape bgColor="gray" heightBtn={isNativeOrPWA ? 40 : 56}>
                  <Text style={styles.shapeText}>Volver a Inicio</Text>
                </ButtonShape>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
        <View style={styles.splitView}>
          <Image style={styles.botImage} resizeMode="contain" source={errorBot} accessible accessibilityLabel="bot image" />
        </View>
      </View>
    </ImageBackground>
  );
};

export default ErrorScreen;
