import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, View } from 'react-native';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import Arrow from '../Arrow/Arrow';
import BotBlock from '../BotBlock/BotBlock';
import GameBoardElements from '../GameBoardElements/GameBoardElements';
import { useStylesHow } from '../useStylesHow';

const HowSectionAvatar = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <GameBoardElements avatars />
      <BotBlock small withBot={!isNativeOrPWA} leftSide={false} title={howTitle['avatar-title']}>
        <Text style={styles.paragraph}>
          <TextWithHTML text={howText['avatar-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
        </Text>
        <View style={{ position: 'absolute', bottom: isNativeOrPWA ? -30 : -50, left: 0 }}>
          <Arrow rotate={70} />
        </View>
      </BotBlock>
    </ImageBackground>
  );
};

export default HowSectionAvatar;
