import { useState, useEffect } from 'react';
import { Platform } from 'react-native';

const useFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (Platform.OS === 'web') {
      if (!isFullScreen) {
        document?.documentElement.requestFullscreen
          ? document?.documentElement.requestFullscreen()
          : document?.documentElement.mozRequestFullScreen
          ? document?.documentElement.mozRequestFullScreen()
          : document?.documentElement.webkitRequestFullscreen
          ? document?.documentElement.webkitRequestFullscreen()
          : document?.documentElement.msRequestFullscreen
          ? document?.documentElement.msRequestFullscreen()
          : null;
      } else {
        document?.exitFullscreen
          ? document?.exitFullscreen()
          : document?.mozCancelFullScreen
          ? document?.mozCancelFullScreen()
          : document?.webkitExitFullscreen
          ? document?.webkitExitFullscreen()
          : document?.msExitFullscreen
          ? document?.msExitFullscreen()
          : null;
      }
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleFullScreenChange = () => {
        setIsFullScreen(
          document?.fullscreenElement ||
            document?.mozFullScreenElement ||
            document?.webkitFullscreenElement ||
            document?.msFullscreenElement
        );
      };

      document?.addEventListener('fullscreenchange', handleFullScreenChange);
      document?.addEventListener('mozfullscreenchange', handleFullScreenChange);
      document?.addEventListener('webkitfullscreenchange', handleFullScreenChange);
      document?.addEventListener('msfullscreenchange', handleFullScreenChange);

      return () => {
        document?.removeEventListener('fullscreenchange', handleFullScreenChange);
        document?.removeEventListener('mozfullscreenchange', handleFullScreenChange);
        document?.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
        document?.removeEventListener('msfullscreenchange', handleFullScreenChange);
      };
    }
  }, []);

  return { isFullScreen, toggleFullScreen };
};

export default useFullScreen;
