import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { TouchableWithoutFeedback, TouchableOpacity, Text, View, StyleSheet } from 'react-native';
import { RootStackParamList } from '../../../types';
import adjust from '../../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';

interface ConfigMainScreenProps {
  handleShowedComponent: Function;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Logout', undefined>;

const OptionsMainScreen = ({ handleShowedComponent }: ConfigMainScreenProps) => {
  const { rh } = useRatioDimensions();
  const navigation = useNavigation<Navigation>();
  const styles = StyleSheet.create({
    container: {
      paddingVertical: 7
    },
    title: {
      fontSize: adjust(22),
      fontWeight: '600',
      color: '#FFF',
      textAlign: 'center',
      marginBottom: rh(4)
    },
    buttonText: {
      color: '#D3D3D3',
      fontWeight: '500',
      fontSize: adjust(18),
      textAlign: 'center',
      paddingBottom: 4
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    },
    buttonContainer: {
      width: isNativeMobile ? '80%' : '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: rh(1),
      minWidth: !isNativeOrPWA ? 300 : 'auto',
      maxWidth: isNativeOrPWA ? 240 : 'auto'
    },
    buttonContainerExit: {
      marginTop: rh(4),
      width: '100%'
    },
    privacyPolicyText: {
      fontSize: adjust(17),
      fontWeight: '600',
      color: '#D3D3D3',
      textAlign: 'center',
      marginTop: rh(3)
    }
  });
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Opciones</Text>
      <TouchableWithoutFeedback accessible accessibilityLabel="Open Configuración" onPress={() => handleShowedComponent(1)}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={styles.buttonText}>Configuración</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback accessible accessibilityLabel="Link to Glosario page" onPress={() => navigation.navigate('Glossary')}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={styles.buttonText}>Glosario</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback accessible accessibilityLabel="Link to Cómo Jugar page" onPress={() => navigation.navigate('HowToPlay')}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={styles.buttonText}>Cómo Jugar</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback accessible accessibilityLabel="Link to Créditos page" onPress={() => navigation.navigate('Credits')}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={styles.buttonText}>Créditos</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>

      <View style={styles.buttonContainerExit}>
        <TouchableWithoutFeedback accessible accessibilityLabel="Logout action" onPress={() => navigation.navigate('Logout')}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.buttonText}>Salir</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>

      <TouchableOpacity accessible accessibilityLabel="Open Política de privacidad" onPress={() => handleShowedComponent(2)}>
        <Text style={styles.privacyPolicyText}>Política de privacidad</Text>
      </TouchableOpacity>
    </View>
  );
};

export default OptionsMainScreen;
