import { View, StyleProp, ViewStyle } from 'react-native';
import { useGameActionHighlight } from '../../../../hooks/useGameActionHighlight';
import { Card as CardType } from '../../../dtos/card.dto';
import { TagsEnum } from '../../../dtos/tag.dto';
import Actions from '../Actions';
import { CardActionPoints, CardBackground, CardName, CardSubject } from '../Card';
import Tags from '../Tags';
import { useCardStyles } from '../useCardStyles';
import { CardSizesEnum } from '../utils';

interface HandCardProps {
  card: CardType;
  cardSize: CardSizesEnum;
  conditionalStyles?: StyleProp<ViewStyle>;
}

const HandCard = ({ card, cardSize, conditionalStyles }: HandCardProps) => {
  const { tags, cardCode, color } = card;
  const hasAction = card?.rules?.[0];

  const { styles } = useCardStyles(card, cardSize, !!hasAction);

  const hasTags = tags?.some(tag => tag.tagName === TagsEnum.IMPACT || tag.tagName === TagsEnum.FACTCHECK);

  const playableHandCardShadow = conditionalStyles;

  const highlightStyle = useGameActionHighlight(color, cardCode);

  return (
    <View pointerEvents="none" style={[styles.container, playableHandCardShadow, highlightStyle]}>
      <CardBackground card={card} />
      <CardName card={card} cardSize={cardSize} />
      <CardSubject card={card} cardSize={cardSize} />
      <CardActionPoints card={card} cardSize={cardSize} />
      <View style={styles.actionCardContainer}>
        {hasTags && <Tags card={card} />}
        {hasAction && <Actions card={card} />}
      </View>
    </View>
  );
};

export default HandCard;
