import { Image, ImageBackground, View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import { borderProgressBar, progressBar, rayIcon } from '../../constants/Images';
import { Profile } from '../../redux/Profile/profileReducer';
import adjust from '../styles/TextAdjust';
import { isNativeOrPWA } from '../utils';
import ButtonShape from './ButtonShape/ButtonShape';

const PlayerExperience = () => {
  const { vw } = useUnits();

  const player = useSelector(({ profile }: { profile: Profile }) => profile.player);

  if (!player) return <></>;

  const { progress } = player;
  const {
    level: { experience: levelExperience, level },
    experience,
    nextLevel: { experience: nextLevelExperience }
  } = progress;
  const total = +(((experience - levelExperience) / (nextLevelExperience - levelExperience)) * 100).toFixed(2);

  const styles = StyleSheet.create({
    container: {
      width: 'auto',
      marginHorizontal: 10,
      paddingTop: 5
    },
    content: {
      height: isNativeOrPWA ? '95%' : '90%',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer'
    },
    text: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? adjust(15) : 20,
      fontWeight: '700'
    },
    textXP: {
      color: '#FFF',
      fontSize: isNativeOrPWA ? adjust(15) : 20,
      fontWeight: '400'
    },
    progressContainer: {
      height: isNativeOrPWA ? 15 : 20,
      minHeight: 14,
      width: isNativeOrPWA ? 80 : 120
    },
    ray: {
      width: vw(1.2),
      height: vw(1.2) * 1.22,
      maxWidth: 22,
      maxHeight: 22 * 1.22,
      minWidth: 16,
      minHeight: 16 * 1.22
    },
    borderProgressBar: {
      width: '100%',
      height: '100%',
      position: 'relative',
      paddingVertical: 1,
      paddingHorizontal: isNativeOrPWA ? 1 : 4
    },
    progressBar: {
      height: '90%',
      width: total + '%'
    },
    experience: {
      flexDirection: 'row'
    },
    marginItems: {
      marginHorizontal: isNativeOrPWA ? 4 : 6,
      marginTop: isNativeOrPWA ? -2 : -3
    }
  });
  return (
    <View style={styles.container}>
      <ButtonShape isPointeable={false} bgColor="gray" heightBtn={isNativeOrPWA ? 34 : 56}>
        <View style={styles.content}>
          <Text style={[styles.text, styles.marginItems]}>{`N${level}`}</Text>
          <Image style={[styles.ray, styles.marginItems]} source={rayIcon} resizeMode="contain" />
          <View style={[styles.progressContainer, styles.marginItems]}>
            <ImageBackground source={borderProgressBar} resizeMode="stretch" style={styles.borderProgressBar}>
              <Image source={progressBar} resizeMode="stretch" style={styles.progressBar} />
            </ImageBackground>
          </View>
          <View style={[styles.experience, styles.marginItems]}>
            <Text style={styles.text}>{experience}</Text>
            <Text style={styles.textXP}>XP</Text>
          </View>
        </View>
      </ButtonShape>
    </View>
  );
};

export default PlayerExperience;
