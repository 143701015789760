import { LinearGradient } from 'expo-linear-gradient';
import { useMemo, useRef, useState } from 'react';
import { StyleSheet, ImageBackground, ScrollView, Image, Pressable, View, Text } from 'react-native';
import useUnits from 'rxn-units';
import { credits } from '../../assets/data/configuration/credits';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import LegalFooter from '../../commons/components/LegalFooter';
import OptionsMenuModal from '../../commons/components/OptionsMenuModal/OptionsMenuModal';
import TextWithHTML from '../../commons/components/TextWithHTML';
import TopHeader from '../../commons/components/TopHeader';
import adjust from '../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../commons/utils';
import { creditsBg, howTop } from '../../constants/Images';

const Credits = () => {
  const { vh, vw } = useUnits();
  const ref = useRef(null);
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false);
  const closeOptionsModal = () => setShowOptionsModal(false);

  const onPressTouch = () => {
    ref.current?.scrollTo({
      y: 0,
      animated: true
    });
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      overflow: 'hidden',
      position: 'relative',
      height: vh(100),
      backgroundColor: '#171717'
    },
    linearGradientStyle: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1
    },
    row: {
      width: '100%',
      marginTop: isNativeOrPWA ? 7 : 10,
      marginBottom: isNativeOrPWA ? 7 : 10,
      marginLeft: isNativeOrPWA ? '5%' : '15%',
      marginRight: isNativeOrPWA ? '5%' : '15%'
    },
    section: {
      width: '100%',
      maxWidth: isNativeOrPWA ? 500 : 700,
      marginTop: isNativeOrPWA ? 14 : 20,
      marginBottom: isNativeOrPWA ? 14 : 20,
      marginLeft: isNativeOrPWA ? '10%' : '25%',
      marginRight: isNativeOrPWA ? '10%' : '25%'
    },
    shapeText: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(22) : 30,
      fontWeight: 'bold',
      color: '#ffffff',
      marginBottom: isNativeOrPWA ? adjust(3) : 6
    },
    shapeContainer: {
      marginTop: adjust(26),
      marginBottom: adjust(26),
      marginLeft: isNativeOrPWA ? '10%' : '25%',
      justifyContent: 'center',
      maxWidth: 140
    },
    title: {
      fontSize: isNativeOrPWA ? adjust(20) : 26,
      fontWeight: '800',
      color: '#ffffff',
      marginBottom: isNativeOrPWA ? adjust(3) : 6
    },
    text: {
      fontSize: adjust(18),
      lineHeight: adjust(22),
      fontWeight: '300',
      color: 'white'
    },
    bold: {
      fontWeight: '600'
    },
    italic: {
      fontStyle: 'italic'
    },
    link: {
      color: '#74FFE1',
      fontWeight: 'bold',
      textDecorationLine: 'underline'
    },
    footerContainer: {
      alignItems: 'center',
      position: 'relative',
      zIndex: 100
    },
    scrollTop: {
      position: 'absolute',
      padding: 10,
      bottom: vh(4),
      right: isNativeOrPWA ? '4%' : '8%',
      zIndex: 100
    },
    icon: {
      height: isNativeOrPWA ? adjust(18) : 24,
      width: isNativeOrPWA ? adjust(18) : 24
    },
    buttonContainer: {
      marginHorizontal: vw(0.5),
      position: 'relative'
    },
    button: {
      padding: vw(1),
      paddingBottom: vw(1.3),
      cursor: 'pointer'
    }
  });
  const htmlStyles = useMemo(
    () => ({
      anchor: styles.link,
      bold: styles.bold,
      italic: styles.italic,
      orderedList: {},
      unorderedList: {}
    }),
    [styles.link, styles.bold, styles.italic]
  );
  const generalStyle = useMemo(() => styles.text, [styles.text]);

  return (
    <ImageBackground style={styles.container} source={creditsBg}>
      <TopHeader />
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.25, 0.8, 1]}
        pointerEvents="none"
      />
      <ScrollView ref={ref}>
        <View style={{ flexGrow: 1, paddingTop: vh(5), paddingBottom: vh(5) }}>
          <View style={styles.shapeContainer}>
            <ButtonShape isPointeable={false} bgColor="black" heightBtn={isNativeOrPWA ? 42 : 56}>
              <Text style={styles.shapeText}>Créditos</Text>
            </ButtonShape>
          </View>

          {credits.map((s, i) => (
            <View key={i} style={styles.section}>
              <Text style={styles.title}>{s.sectionName}</Text>
              {s?.positions.map((p, j) => (
                <View key={i + '-' + j} style={styles.row}>
                  {p.name && <TextWithHTML text={p.name} generalTextStyle={[generalStyle, { marginBottom: 5 }]} htmlStyles={htmlStyles} />}
                  {p.job && <TextWithHTML text={p.job} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />}
                </View>
              ))}
            </View>
          ))}
        </View>
      </ScrollView>
      <View style={styles.footerContainer}>
        <LegalFooter />
      </View>
      <View style={styles.scrollTop}>
        <Pressable onPress={onPressTouch}>
          <View style={styles.buttonContainer}>
            <ButtonShape square bgColor="dark" border heightBtn={isNativeOrPWA ? 30 : 46}>
              <View style={styles.button}>
                <Image style={styles.icon} resizeMode="contain" source={howTop} accessible accessibilityLabel="top icon" />
              </View>
            </ButtonShape>
          </View>
        </Pressable>
      </View>
      {showOptionsModal && <OptionsMenuModal closeModal={closeOptionsModal} />}
    </ImageBackground>
  );
};

export default Credits;
