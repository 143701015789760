import { Card } from '../../dtos/card.dto';
import { PlayerRoundEnum } from '../../dtos/player.dto';
import { Rule } from '../../dtos/rule.dto';

function getIsRival({ applyToOpponent }: Rule): boolean {
  return applyToOpponent ?? false;
}

function isAppliedToPlayerBoardCards(isOpponent: boolean, isRival: boolean) {
  return (!isOpponent && !isRival) || (isOpponent && isRival);
}

function applyToBoardCards(isOpponent: boolean, isRival: boolean, currentOpponentBoardCards: Card[], currentPlayerBoardCards: Card[]) {
  return isAppliedToPlayerBoardCards(isOpponent, isRival) ? currentPlayerBoardCards : currentOpponentBoardCards;
}

function applyToPlayer(isOpponent: boolean, isRival: boolean) {
  return isAppliedToPlayerBoardCards(isOpponent, isRival) ? PlayerRoundEnum.PLAYER : PlayerRoundEnum.OPPONENT;
}

function applyToDeck(
  isOpponent: boolean,
  isRival: boolean,
  decks: { playerDeck: Card[]; opponentDeck: Card[] },
  updateDecks: { updatePlayerDeck: Function; updateOpponentDeck: Function }
) {
  const isPlayer = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);

  const { opponentDeck, playerDeck } = decks;
  const { updateOpponentDeck, updatePlayerDeck } = updateDecks;

  return { deck: isPlayer ? playerDeck : opponentDeck, setDeck: isPlayer ? updatePlayerDeck : updateOpponentDeck };
}

function applyToAvatar(
  isOpponent: boolean,
  isRival: boolean,
  lifePoints: { playerLifePoints: number; opponentLifePoints: number },
  handleLifePoints: { setPlayerLifePoints: Function; setOpponentLifePoints: Function }
) {
  const isPlayer = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);
  const { opponentLifePoints, playerLifePoints } = lifePoints;
  const { setOpponentLifePoints, setPlayerLifePoints } = handleLifePoints;

  return {
    lifePoints: isPlayer ? playerLifePoints : opponentLifePoints,
    setLifePoints: isPlayer ? setPlayerLifePoints : setOpponentLifePoints
  };
}

const sideUtils = {
  getIsRival,
  applyToBoardCards,
  applyToPlayer,
  isAppliedToPlayerBoardCards,
  applyToAvatar,
  applyToDeck
};

export default sideUtils;
