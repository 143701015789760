import React from 'react';
import { StyleSheet, ImageBackground, ScrollView, TouchableWithoutFeedback, Image } from 'react-native';
import useUnits from 'rxn-units';
import { View, Text } from '../../../commons/components/Themed';
import { CardQuantity } from '../../../commons/dtos/card-quantity.dto';
import { SubjectsEnum } from '../../../commons/dtos/subject.dto';
import { getSubjectIcon, moveElement } from '../../../commons/utils';
import {
  subjectIconContainerBlack,
  subjectIconContainerWhite,
  actionPointsBlackContainer,
  actionPointsWhiteContainer
} from '../../../constants/Images';

interface GameBoardInitializerCardsViewerProps {
  cards: CardQuantity[];
  setCards: Function;
  player: number;
}
const GameBoardInitializerCardsViewer = (props: GameBoardInitializerCardsViewerProps) => {
  const { vh, vw } = useUnits();
  const styles = StyleSheet.create({
    cardsViewerContainer: { width: '100%', height: '110%' },
    deckCardSuperContainer: { position: 'relative', width: '100%', left: '5%', height: vh(9.3), top: vh(0.5) },
    deckCard: {
      width: '82.5%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'center'
    },
    deckCardIllustration: {
      width: '100%',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      height: vh(52),
      borderRadius: vh(1.5),
      borderWidth: vh(0.7)
    },
    cardName: { marginLeft: '2%', width: '50%', marginRight: '10%', zIndex: 10, fontWeight: '900', textAlign: 'left', fontSize: vw(0.9) },
    subjectIconContainer: {
      position: 'absolute',
      height: '100%',
      left: '-3%',
      top: '-15%',
      zIndex: 100,
      elevation: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    subjectIconContainerBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 100,
      elevation: 10
    },
    subjectIcon: { width: '55%', height: '55%', marginTop: '20%', zIndex: 200 },
    quantityContainer: {
      backgroundColor: 'black',
      marginLeft: '-1%',
      transform: [{ rotate: '45deg' }],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: vw(0.1),
      borderRadius: vw(0.2),
      width: vw(1.6),
      height: vw(1.6)
    },
    actionPointsContainer: {
      zIndex: 300,
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.5,
      shadowRadius: 1,
      borderRadius: 500,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '18%'
    },
    pointsText: {
      fontFamily: 'Inlanders',
      textAlign: 'center',
      textAlignVertical: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: vw(1.4)
    },
    rotatedPointsText: { fontWeight: '200', transform: [{ rotate: '-45deg' }] },
    quantityButtonsContainer: {
      width: '10%',
      height: '100%',
      position: 'absolute',
      right: '5%',
      top: 0,
      zIndex: 10000,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    moveButtonsContainer: {
      marginLeft: '5%',
      width: '10%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 10000,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },

    quantityButton: {
      borderRadius: 500,
      borderColor: 'rgba(255, 255, 255, 0.7)',
      backgroundColor: 'rgba(143, 143, 143, 0.4)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      width: vw(2),
      height: vw(1.4),
      borderWidth: vw(0.02),
      marginVertical: vw(0.2)
    }
  });

  const moveCard = (oldIndex: number, newIndex: number) => {
    if (newIndex < 0) {
      newIndex = props.cards.length - 1;
    }
    if (newIndex >= props.cards.length) {
      newIndex = 0;
    }
    const updatedCards = moveElement(props.cards, oldIndex, newIndex);
    props.setCards([...updatedCards]);
  };
  const changeQuantity = (cardIndex: number, quantity: number) => {
    if (quantity < 0) return;
    const updatedCards = [...props.cards];

    if (quantity === 0) {
      updatedCards.splice(cardIndex, 1);
      props.setCards(updatedCards);
      return;
    }
    updatedCards[cardIndex].quantity = quantity;
    props.setCards(updatedCards);
  };

  return (
    <ScrollView style={styles.cardsViewerContainer} showsVerticalScrollIndicator={false}>
      {props.cards.map((card, index) => {
        const cardStyles = StyleSheet.create({
          deckCardSuperContainer: { opacity: card.quantity > 0 ? 1 : 0.75 },
          deckCardIllustration: {
            borderColor: card.card.color ? '#000' : '#FFF',
            backgroundColor: card.card.color ? '#01eebd' : '#ffe15e'
          },
          cardName: { color: card.card.color ? '#002B26' : '#643A00' },
          quantityContainer: {
            backgroundColor: card.card.color ? 'rgba(0, 129, 109, 0.2)' : 'rgba(236, 110, 75, 0.2)',
            borderColor: card.card.color ? '#008073' : '#9F2A0A'
          },
          rotatedPointsText: { color: card.card.color ? '#002F2A' : '#8C1E00' },
          quantityButton: {
            opacity: card.quantity === 0 ? 0.4 : 1
          }
        });
        return (
          <View key={`${props.player}-${index}`} style={[styles.deckCardSuperContainer, cardStyles.deckCardSuperContainer]}>
            <View style={[styles.subjectIconContainer, { width: vw(2.5) }]}>
              {card.card.subject && card.card.subject.subjectName !== SubjectsEnum.NEUTRAL ? (
                <Image style={styles.subjectIcon} source={getSubjectIcon(card.card)} resizeMode={'contain'}></Image>
              ) : (
                <></>
              )}
              <ImageBackground
                style={styles.subjectIconContainerBackground}
                source={card.card.color ? subjectIconContainerBlack : subjectIconContainerWhite}
                resizeMode={'contain'}
              ></ImageBackground>
            </View>
            <View style={styles.moveButtonsContainer}>
              <TouchableWithoutFeedback
                onPress={() => {
                  moveCard(index, index - 1);
                }}
              >
                <View style={[styles.quantityButton, { width: vw(2), height: vw(1.4), borderWidth: vw(0.02), marginVertical: vw(0.2) }]}>
                  <Text>▲</Text>
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback
                onPress={() => {
                  moveCard(index, index + 1);
                }}
              >
                <View style={[styles.quantityButton, { width: vw(2), height: vw(1.4), borderWidth: vw(0.02), marginVertical: vw(0.2) }]}>
                  <Text>▼</Text>
                </View>
              </TouchableWithoutFeedback>
            </View>
            <TouchableWithoutFeedback key={index}>
              <View style={[styles.deckCard, { height: vh(10), borderRadius: vh(1.5) }]}>
                <View style={[styles.deckCardIllustration, cardStyles.deckCardIllustration]}></View>
                {
                  <ImageBackground
                    resizeMode={'contain'}
                    style={[
                      styles.actionPointsContainer,
                      {
                        width: vw(2.7),
                        height: vw(2.7)
                      }
                    ]}
                    source={card.card.color ? actionPointsBlackContainer : actionPointsWhiteContainer}
                  >
                    <Text style={[styles.pointsText, { height: vw(2), fontSize: vw(1.7) }]}>{card.card.actionPoints}</Text>
                  </ImageBackground>
                }
                <Text style={[styles.cardName, cardStyles.cardName]} ellipsizeMode="tail" numberOfLines={1}>
                  {`${card.card.cardCode} ${card.card.cardName.toUpperCase()}`}
                </Text>

                {
                  <View style={[styles.quantityContainer, cardStyles.quantityContainer]}>
                    <Text style={[styles.pointsText, styles.rotatedPointsText, cardStyles.rotatedPointsText]}>{card.quantity}</Text>
                  </View>
                }
              </View>
            </TouchableWithoutFeedback>
            <View style={styles.quantityButtonsContainer}>
              <TouchableWithoutFeedback
                onPress={() => {
                  changeQuantity(index, card.quantity + 1);
                }}
              >
                <View style={[styles.quantityButton, { width: vw(2), height: vw(1.4), borderWidth: vw(0.02), marginVertical: vw(0.2) }]}>
                  <Text>+</Text>
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback
                onPress={() => {
                  changeQuantity(index, card.quantity - 1);
                }}
              >
                <View style={[styles.quantityButton, cardStyles.quantityButton]}>
                  <Text>-</Text>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
};

export default React.memo(GameBoardInitializerCardsViewer);
