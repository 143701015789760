import { LinearGradient } from 'expo-linear-gradient';
import { Text, StyleSheet, ViewStyle } from 'react-native';
import useUnits from 'rxn-units';
import { isNativeOrPWA } from '../../../../commons/utils';

interface PlayerNumberProps {
  position: number;
  styleProp: ViewStyle | ViewStyle[];
  side: 'right' | 'left';
}

const PlayerNumber = ({ position, styleProp, side }: PlayerNumberProps) => {
  const { vw } = useUnits();

  const style = StyleSheet.create({
    container: {
      width: vw(6),
      height: vw(6),
      maxHeight: 70,
      maxWidth: 70,
      borderRadius: vw(10) / 2,
      borderColor: '#FFF',
      borderWidth: isNativeOrPWA ? 2 : 5,
      backgroundColor: '#FF7E31',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textStyle: {
      color: '#FFF',
      fontWeight: '900',
      fontSize: isNativeOrPWA ? 18 : 30
    }
  });

  const colors = side === 'left' ? ['#FF7E31', '#FFC237'] : ['#00B3A4', '#01EFBE'];

  return (
    <LinearGradient colors={colors} style={[style.container, styleProp]}>
      <Text style={style.textStyle}>{`${position}º`}</Text>
    </LinearGradient>
  );
};

export default PlayerNumber;
