import {
  cards,
  conspiracySubjectIconBlack,
  factcheckIcon,
  goldIcon,
  hateSubjectIconBlack,
  impactIcon,
  loseIcon,
  platinumIcon,
  scamSubjectIconBlack,
  scienceSubjectIconBlack,
  silverIcon,
  winIcon
} from '../../../constants/Images';
import { Card } from '../../dtos/card.dto';
import { AppliedEnhanceRule } from '../../dtos/enhance.dto';
import { RaritiesEnum } from '../../dtos/rarity.dto';
import { WhenEnum } from '../../dtos/rule.dto';
import { SubjectsEnum } from '../../dtos/subject.dto';
import { TagsEnum } from '../../dtos/tag.dto';

export enum CardSizesEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
  VERY_BIG = 'very big'
}

export const selectSize = <T extends string | number>(size: CardSizesEnum, veryBigSize: T, bigSize: T, mediumSize: T, smallSize: T): T => {
  if (size === CardSizesEnum.VERY_BIG) {
    return veryBigSize;
  }
  if (size === CardSizesEnum.BIG) {
    return bigSize;
  }
  if (size === CardSizesEnum.MEDIUM) {
    return mediumSize;
  }
  return smallSize;
};

export const getEnhancePointsResult = (enhance: AppliedEnhanceRule) => {
  const pointsResult = { damagePoints: 0, defensePoints: 0 };
  if (!enhance) {
    return pointsResult;
  }
  for (const id in enhance) {
    if ((enhance as AppliedEnhanceRule)[id]) {
      const enhanceRule = (enhance as AppliedEnhanceRule)[id];
      pointsResult.damagePoints += enhanceRule.ruleDamagePoints as number;
      pointsResult.defensePoints += enhanceRule.ruleDefensePoints as number;
    }
  }
  return pointsResult;
};

export const getImageCard = (card: Card) => {
  let imageCard = cards[card.imageSource as string];
  if (!imageCard) {
    const imageCardSplit = card.illustration?.split('.');
    if (!imageCardSplit) return;
    imageCardSplit.pop();
    imageCard = cards[imageCardSplit.join('.')];
  }
  return imageCard;
};

export const getRarityIcon = (card: Card) => {
  if (card.rarity?.rarityName === RaritiesEnum.SILVER) {
    return silverIcon;
  }
  if (card.rarity?.rarityName === RaritiesEnum.GOLD) {
    return goldIcon;
  }
  if (card.rarity?.rarityName === RaritiesEnum.PLATINUM) {
    return platinumIcon;
  }
  return null;
};

export const getTagsIcon = (tagName: string) => {
  if (tagName === TagsEnum.IMPACT) {
    return impactIcon;
  }
  if (tagName === TagsEnum.FACTCHECK) {
    return factcheckIcon;
  }
  return null;
};

export const getActionsIcon = (actionName: string) => {
  if (actionName === WhenEnum.WIN) {
    return winIcon;
  }
  if (actionName === WhenEnum.LOSE) {
    return loseIcon;
  }
  return null;
};

export const getSubjectIcon = (card: Card) => {
  if (card.subject?.subjectName === SubjectsEnum.SCIENCE) {
    return scienceSubjectIconBlack;
  }
  if (card.subject?.subjectName === SubjectsEnum.CONSPIRACY) {
    return conspiracySubjectIconBlack;
  }
  if (card.subject?.subjectName === SubjectsEnum.HATE) {
    return hateSubjectIconBlack;
  }
  if (card.subject?.subjectName === SubjectsEnum.SCAM) {
    return scamSubjectIconBlack;
  }
};
