import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image, TouchableHighlight } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import useUnits from 'rxn-units';
import ButtonShape from '../../../commons/components/ButtonShape/ButtonShape';
import { EventLogs } from '../../../commons/dtos/create-log.dto';
import { GendersEnum, Player, PlayerTypesEnum } from '../../../commons/dtos/player.dto';
import { ApiError } from '../../../commons/errors/api-error';
import adjust from '../../../commons/styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../../commons/utils';
import { goBackIcon } from '../../../constants/Images';
import { actions, authProps } from '../../../redux/Auth/authRedux';
import LoginService from '../../../services/login.service';
import LogsService from '../../../services/logs.service';
import PlayerService from '../../../services/player.service';
import { RootStackParamList } from '../../../types';
import CheckTerms from './CheckTerms';
import DateError from './Error/DateError';
import LoginAvatar from './LoginAvatar';
import LoginScreenDate from './LoginScreenDate';
import LoginScreenFields from './LoginScreenFields';

const logService = new LogsService();

interface LoginContinueProps {
  step: number;
  setMessage: Function;
  nextStep: Function;
  backStep: Function;
  error: string;
  setError: Function;
  player?: Player;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Login', undefined>;

const loginService = new LoginService();

const LoginContinue = ({ step, nextStep, setMessage, backStep, error, setError, player }: LoginContinueProps) => {
  const { user } = useSelector(({ auth }: authProps) => ({
    user: auth.user
  }));

  const [initialYear, initialMonth, initialDay] = player?.dateBorn.toString().split('-') ?? [null, null, null];

  const { vh } = useUnits();
  const [checkTerms, setCheckTerms] = useState<boolean>(false);
  const [dateBorn, setDateBorn] = useState<Date>(new Date());
  const [gender, setGender] = useState<GendersEnum | undefined>(player?.gender);
  const [nickname, setNickname] = useState(player?.playerName ?? '');
  const [day, setDay] = useState<number | null>(Number(initialDay) || null);
  const [month, setMonth] = useState<number | null>(Number(initialMonth) || null);
  const [year, setYear] = useState<number | null>(Number(initialYear) || null);
  const currentYear = new Date().getFullYear();

  const dispatch = useDispatch();
  const navigation = useNavigation<Navigation>();

  useEffect(() => {
    setError('');
  }, [nickname]);

  const handleDate = (): void => {
    if (!checkTerms) {
      const errorMessage = 'Debes confirmar los Términos de uso de Fakesover';
      setError(errorMessage);
    }
    if (year && day && month) {
      setError('');
      let errorMessage = 'Sólo los mayores de 14 años pueden jugar a FakesOver.';
      if (year && currentYear - year < 14) {
        setError(errorMessage);
        setMessage(errorMessage);
        return;
      } else if (year && new Date().getFullYear() - year >= 90) {
        errorMessage = '¿Estás algo mayor para juegos no crees?';
        setError(errorMessage);
        setMessage(errorMessage);
        return;
      }
      const dateBorn = new Date(`${year}-${month}-${day}`);
      if (dateBorn instanceof Date) {
        setDateBorn(dateBorn);
        setError('');
        nextStep();
      }
    } else {
      setMessage('Porfavor, completa todos los campos');
      setError('Porfavor, completa todos los campos');
    }
  };

  const handleNickname = (): void => {
    const patternNickname = /^[a-zA-Z0-9_-]{5,16}$/;
    if (!patternNickname.test(nickname)) {
      setError(
        'El nombre de usuario, solo puede contener mayúsculas, minúsculas, números y guiones. Un mínimo de 8 caracteres y un máximo de 16.'
      );
      setMessage(
        'El nombre de usuario, solo puede contener mayúsculas, minúsculas, números y guiones. Un mínimo de 8 caracteres y un máximo de 16.'
      );
      return;
    }
    loginService
      .getPlayerByNickname(nickname)
      .then(() => {
        if (player && player.playerName === nickname) {
          return finalLogin();
        }
        setError('El nombre de usuario ya existe, por favor elige otro.');
        setMessage('El nombre de usuario ya existe, por favor elige otro.');
      })
      .catch((error: any) => {
        if (player) return finalLogin();
        nextStep();
        if (error instanceof ApiError) {
          if (error?.httpCode !== 404) {
            throw new Error(`Error signing in: ${error as Error}`);
          }
        }
      });
  };

  const finalLogin = async (): Promise<void> => {
    if (!user || !gender) return;
    const { email, type } = user;

    const playerDateBorn = new Date(dateBorn);
    if (player) {
      const existingPlayer: Player = {
        ...player,
        email,
        dateBorn: playerDateBorn,
        playerName: nickname,
        gender,
        type
      };
      const { ...restPlayer } = existingPlayer;
      const updatedUser = await new PlayerService().updatePlayer(player.id!, restPlayer);
      dispatch(actions.login({ ...user, playerId: updatedUser.id, player: updatedUser, dateBorn: playerDateBorn }, user?.idToken));
      navigation.navigate('Home');
    } else {
      const newPlayer = {
        email,
        dateBorn: playerDateBorn,
        playerName: nickname,
        gender,
        type
      };
      const userCreated = await loginService.createPlayer(newPlayer);
      const userWithPlayerId = { ...user, playerId: userCreated.id, dateBorn: playerDateBorn };
      dispatch(actions.login({ ...userWithPlayerId, playerId: userCreated.id, player: userCreated }, user?.idToken));
      logService.createLog(EventLogs.LOGIN);
      logService.createLog(EventLogs.SIGNUP);
    }
  };

  const handleNext = (): void => {
    switch (step) {
      case 2:
        handleDate();
        break;
      case 3:
        handleNickname();

        break;
      case 4:
        finalLogin();
        break;
    }
  };

  const isDisabled = (): boolean => {
    switch (step) {
      case 2:
        return !(error === '') || !(year && month && day && checkTerms);
      case 3:
        return !(error === '') || nickname.length < 5;
      case 4:
        return !(error === '') || !gender;
      default:
        return true;
    }
  };

  const styles = StyleSheet.create({
    title: {
      fontSize: isNativeOrPWA ? adjust(16) : 28,
      fontWeight: '600',
      width: '100%',
      maxWidth: 500,
      textAlign: 'center',
      marginTop: isNativeOrPWA ? vh(6) : vh(9),
      marginBottom: isNativeOrPWA ? vh(1.5) : vh(2.5),
      color: '#FFFFFF'
    },
    subtitle: {
      fontSize: isNativeOrPWA ? adjust(14) : 24,
      fontWeight: '400',
      width: '100%',
      maxWidth: isNativeOrPWA ? 235 : 400,
      textAlign: 'center',
      color: '#FFFFFF'
    },
    formBody: {
      alignItems: 'center',
      justifyContent: 'space-around',
      height: '100%',
      width: '90%',
      flex: 1
    },
    dateContainer: {
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttons: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: isNativeOrPWA ? '90%' : '80%'
    },
    borderButton: {
      flexDirection: 'row',
      height: isNativeOrPWA ? 50 : 70,
      alignItems: 'center',
      justifyContent: 'center'
    },
    goBackButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    goBackButtonText: {
      color: 'white',
      marginLeft: isNativeOrPWA ? 4 : 8
    },
    goBackImage: {
      width: isNativeOrPWA ? 12 : 16,
      height: isNativeOrPWA ? 12 : 16,
      marginTop: isNativeOrPWA ? '-2%' : 0,
      paddingBottom: isNativeOrPWA ? 0 : '2%'
    },
    buttonTextColor: {
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: adjust(2)
    },
    buttonTextColorError: {
      color: '#FF5963',
      fontWeight: 'bold'
    },
    textContainer: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    innerWidth: {
      width: 'auto'
    },
    buttonSignInText: {
      flexGrow: isNativeMobile ? 0 : 1,
      flexShrink: 0,
      fontSize: isNativeOrPWA ? adjust(16) : 22,
      fontWeight: '600',
      color: '#fff'
    },
    buttonInner: {
      paddingHorizontal: 10,
      marginBottom: isNativeOrPWA ? 1 : 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'auto'
    },
    buttonContainer: {
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: isNativeOrPWA ? 5 : 15,
      marginBottom: 5,
      maxWidth: !isNativeOrPWA ? 310 : 185,
      marginHorizontal: 'auto'
    }
  });

  if (step === 2 && error) {
    return <DateError error={error} setError={setError} backStep={backStep} />;
  }

  const steps = {
    2: {
      component: (
        <View style={styles.dateContainer}>
          <LoginScreenDate setDay={setDay} setMonth={setMonth} setYear={setYear} day={day} month={month} year={year} />
          <CheckTerms setChecked={setCheckTerms} isChecked={checkTerms} />
        </View>
      ),
      title: 'Introduce tu edad para continuar'
    },
    3: {
      component: <LoginScreenFields nickname={nickname} setNickname={setNickname} error={error} />,
      title: 'Este es el nombre que tendrás durante el juego. Debe tener al menos 5 caracteres.'
    },
    4: {
      component: <LoginAvatar gender={gender} setGender={setGender} />,
      title: 'Selecciona Avatar'
    }
  };

  return (
    <View style={styles.formBody}>
      <View style={styles.textContainer}>
        <Text style={styles.title}>Configura tu perfil</Text>
        {<Text style={styles.subtitle}>{steps[step as 2 | 3 | 4].title}</Text>}
      </View>
      {steps[step as 2 | 3 | 4].component}
      <View style={styles.buttons}>
        <TouchableHighlight
          style={[styles.borderButton, user?.type === PlayerTypesEnum.USER && { opacity: 0.3 }, { height: 30 }]}
          accessible
          underlayColor={'transparent'}
          accessibilityLabel={'Back to login'}
          onPress={() => backStep()}
        >
          <View style={styles.goBackButton}>
            <Image style={styles.goBackImage} source={goBackIcon} resizeMode="contain" />
            <Text style={[styles.buttonSignInText, styles.goBackButtonText]}>Volver atrás</Text>
          </View>
        </TouchableHighlight>

        <TouchableHighlight
          accessible
          disabled={isDisabled()}
          underlayColor={'transparent'}
          accessibilityLabel={'Login as guest'}
          style={[styles.innerWidth, isDisabled() && { opacity: 0.3 }]}
          onPress={() => handleNext()}
        >
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 50}>
              <View style={styles.buttonInner}>
                <Text style={[styles.buttonSignInText, !error ? styles.buttonTextColor : styles.buttonTextColorError]}>Continuar</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableHighlight>
      </View>
    </View>
  );
};

export default LoginContinue;
