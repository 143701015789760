import { Platform, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import Jump from '../../commons/components/HowToPlaySections/Jump/Jump';
import HowSectionAttack from '../../commons/components/HowToPlaySections/Sections/HowSectionAttack';
import HowSectionAttackDefense from '../../commons/components/HowToPlaySections/Sections/HowSectionAttackDefense';
import HowSectionAvatar from '../../commons/components/HowToPlaySections/Sections/HowSectionAvatar';
import HowSectionBarrier from '../../commons/components/HowToPlaySections/Sections/HowSectionBarrier';
import HowSectionBlackCards from '../../commons/components/HowToPlaySections/Sections/HowSectionBlackCards';
import HowSectionBoard from '../../commons/components/HowToPlaySections/Sections/HowSectionBoard';
import HowSectionEnd from '../../commons/components/HowToPlaySections/Sections/HowSectionEnd';
import HowSectionEnhance from '../../commons/components/HowToPlaySections/Sections/HowSectionEnhance';
import HowSectionFactCheckers from '../../commons/components/HowToPlaySections/Sections/HowSectionFactCheckers';
import HowSectionFakes from '../../commons/components/HowToPlaySections/Sections/HowSectionFakes';
import HowSectionFirstCard from '../../commons/components/HowToPlaySections/Sections/HowSectionFirstCard';
import HowSectionHand from '../../commons/components/HowToPlaySections/Sections/HowSectionHand';
import HowSectionPlayButton from '../../commons/components/HowToPlaySections/Sections/HowSectionPlayButton';
import HowSectionPower from '../../commons/components/HowToPlaySections/Sections/HowSectionPower';
import HowSectionStart from '../../commons/components/HowToPlaySections/Sections/HowSectionStart';
import HowSectionStrategy from '../../commons/components/HowToPlaySections/Sections/HowSectionStrategy';
import HowSectionSubjects from '../../commons/components/HowToPlaySections/Sections/HowSectionSubjects';
import HowSectionThrow from '../../commons/components/HowToPlaySections/Sections/HowSectionThrow';
import HowSectionTurn from '../../commons/components/HowToPlaySections/Sections/HowSectionTurn';
import HowSectionWhiteCards from '../../commons/components/HowToPlaySections/Sections/HowSectionWhiteCards';
import Slides from '../../commons/components/Slides';
import { View } from '../../commons/components/Themed';
import { isNativeOrPWA } from '../../commons/utils';
import { tutorialSound } from '../../constants/Sound';
import { useMusic } from '../../hooks/useMusic';
import { Config } from '../../redux/Configuration/configuration';
import store from '../../redux/store';

const Tutorial = () => {
  const playMusic = useSelector(
    ({
      configuration: {
        volume: { dialogMusic }
      }
    }: {
      configuration: Config;
    }) => dialogMusic
  );
  useMusic({ loop: true, soundMusic: tutorialSound, play: playMusic });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'relative',
      alignItems: 'center',
      backgroundColor: '#202020'
    },
    picker: {
      height: '100%',
      width: '100%',
      position: 'relative',
      flexDirection: 'row',
      flexGrow: 1
    },
    slide: {
      width: '100%',
      height: '100%',
      paddingHorizontal: 10,
      position: 'relative'
    },
    slideStyle: {
      flexDirection: 'row',
      width: '100%'
    },
    buttonStyleLeft: {
      left: Platform.OS === 'ios' ? '6%' : '3%'
    },
    buttonStyleRight: {
      right: Platform.OS === 'ios' ? '6%' : '3%'
    },
    buttonStyle: {
      minHeight: isNativeOrPWA ? 32 : 50,
      minWidth: isNativeOrPWA ? 32 : 50
    }
  });

  const {
    profile: { player }
  } = store.getState();

  if (!player) return <></>;

  return (
    <View style={styles.container}>
      <Jump />
      <Slides
        buttonsStyles={{ left: styles.buttonStyleLeft, right: styles.buttonStyleRight, common: styles.buttonStyle }}
        parentStyle={styles.picker}
        itemsToRender={1}
        style={styles.slideStyle}
        initialStep={0}
      >
        {[
          <View key="start" style={styles.slide}>
            <HowSectionStart />
          </View>,
          <View key="board" style={styles.slide}>
            <HowSectionBoard />
          </View>,
          <View key="hand" style={styles.slide}>
            <HowSectionHand />
          </View>,
          <View key="throw" style={styles.slide}>
            <HowSectionThrow />
          </View>,
          <View key="deck" style={styles.slide}>
            <HowSectionFirstCard />
          </View>,
          <View key="turn" style={styles.slide}>
            <HowSectionTurn />
          </View>,
          <View key="subjects" style={styles.slide}>
            <HowSectionSubjects />
          </View>,
          <View key="power" style={styles.slide}>
            <HowSectionPower />
          </View>,
          <View key="attack&Defense" style={styles.slide}>
            <HowSectionAttackDefense />
          </View>,
          <View key="attack" style={styles.slide}>
            <HowSectionAttack />
          </View>,
          <View key="avatar" style={styles.slide}>
            <HowSectionAvatar />
          </View>,
          <View key="barrier" style={styles.slide}>
            <HowSectionBarrier />
          </View>,
          <View key="white" style={styles.slide}>
            <HowSectionWhiteCards />
          </View>,
          <View key="black" style={styles.slide}>
            <HowSectionBlackCards />
          </View>,
          <View key="enhance" style={styles.slide}>
            <HowSectionEnhance />
          </View>,
          <View key="fakes" style={styles.slide}>
            <HowSectionFakes />
          </View>,
          <View key="fact-checkers" style={styles.slide}>
            <HowSectionFactCheckers />
          </View>,
          <View key="play" style={styles.slide}>
            <HowSectionPlayButton />
          </View>,
          <View key="strategy" style={styles.slide}>
            <HowSectionStrategy />
          </View>,
          <View key="end" style={styles.slide}>
            <HowSectionEnd />
          </View>
        ]}
      </Slides>
    </View>
  );
};

export default Tutorial;
