import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';

export const useRatioDimensions = () => {
  const { vh, vw } = useUnits();
  const aspectRatio = useSelector(
    ({ aspectRatio }: { aspectRatio: { aspectRatio: { viewWidth: number; viewHeight: number } } }) => aspectRatio.aspectRatio
  );
  const rh = (p: number) => {
    if (aspectRatio?.viewHeight) return aspectRatio?.viewHeight * (p / 100);
    return vh(p);
  };
  const rw = (p: number) => {
    if (aspectRatio?.viewWidth) return aspectRatio?.viewWidth * (p / 100);
    return vw(p);
  };

  const offsetRatioLeft = Math.round((vw(100) - rw(100)) / 2);
  const offsetRatioTop = Math.round((vh(100) - rh(100)) / 2);
  return {
    rh,
    rw,
    offsetRatioLeft,
    offsetRatioTop
  };
};
