import { ImageBackground, Image, ImageSourcePropType, ImageStyle } from 'react-native';

interface AvatarProps {
  background: ImageSourcePropType;
  avatar: ImageSourcePropType;
  bgStyle: ImageStyle | ImageStyle[];
  avatarStyle: ImageStyle | ImageStyle[];
}

const Avatar = ({ avatar, avatarStyle, background, bgStyle }: AvatarProps) => {
  return (
    <ImageBackground source={background} style={bgStyle} resizeMode="contain">
      <Image source={avatar} style={avatarStyle} resizeMode="contain" />
    </ImageBackground>
  );
};

export default Avatar;
