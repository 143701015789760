/* eslint-disable @typescript-eslint/no-var-requires */
import { LinearGradient } from 'expo-linear-gradient';
import { StyleSheet, ScrollView, ImageBackground, TouchableWithoutFeedback } from 'react-native';
import useUnits from 'rxn-units';
import { View, Text } from '../../../../commons/components/Themed';
import { DeckIncludesCard } from '../../../../commons/dtos/deck-includes-card.dto';
import { isNativeOrPWA } from '../../../../commons/utils';
import {
  deckCardContainerWhite,
  deckCardContainerBlack,
  actionPointsBlackContainer,
  actionPointsWhiteContainer
} from '../../../../constants/Images';

interface DeckCardsViewerProps {
  onRemoveCardFromDeck: Function;
  deckCards: DeckIncludesCard[];
}
const DeckCardsViewer = (props: DeckCardsViewerProps) => {
  const { vw, vh } = useUnits();
  const styles = StyleSheet.create({
    container: { width: '100%', height: '100%', position: 'relative', paddingHorizontal: '5%' },
    gradient: {
      position: 'absolute',
      width: '94%',
      zIndex: 100,
      left: '3%',
      right: '3%'
    },
    deckCardSuperContainer: { position: 'relative' },
    deckCard: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'center',
      cursor: 'pointer'
    },
    cardName: { fontWeight: '900', textAlign: 'center', width: '100%', marginHorizontal: 'auto', paddingHorizontal: '5%' },
    actionPointsContainer: {
      zIndex: 300,
      position: 'absolute',
      left: 5,
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    quantityContainer: {
      backgroundColor: 'white',
      transform: [{ rotate: '45deg' }],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 2,
      top: 5,
      borderWidth: vw(0.25),
      borderRadius: vw(0.5),
      width: vw(2),
      height: vw(2)
    },
    pointsText: {
      fontFamily: 'Inlanders',
      fontSize: vw(1.7),
      marginBottom: 3
    }
  });

  return (
    <>
      <LinearGradient style={[styles.gradient, { height: vh(4), top: vh(0) }]} colors={['#434343', 'rgba(67, 67, 67, 0)']} />
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        {props.deckCards.map((deckCard, index) => {
          const cardStyles = StyleSheet.create({
            color: { color: deckCard.card.color ? '#fff' : '#000' },
            pointsText: { color: deckCard.card.color ? '#002F2A' : '#8C1E00' },
            separator: { marginTop: index > 0 ? (!isNativeOrPWA ? vh(-4) : vh(-5)) : vh(2) },
            heightCard: { height: !isNativeOrPWA ? vh(12.5) : vh(16) }
          });
          return (
            <ImageBackground
              source={deckCard.card.color ? deckCardContainerBlack : deckCardContainerWhite}
              resizeMode={'stretch'}
              key={index}
              style={[styles.deckCardSuperContainer, cardStyles.heightCard, cardStyles.separator]}
            >
              <TouchableWithoutFeedback key={index} onPress={() => deckCard.quantity && props.onRemoveCardFromDeck(deckCard.card)}>
                <View style={styles.deckCard}>
                  <Text
                    style={[styles.cardName, cardStyles.color, { fontSize: vw(1.1), marginTop: vh(5) }]}
                    ellipsizeMode="tail"
                    numberOfLines={1}
                  >
                    {deckCard.card.cardName.toUpperCase()}
                  </Text>
                  {deckCard.quantity && (
                    <View style={styles.quantityContainer}>
                      <Text style={[styles.pointsText, cardStyles.pointsText, { transform: [{ rotate: '-45deg' }] }]}>
                        {deckCard.quantity}
                      </Text>
                    </View>
                  )}
                  {
                    <ImageBackground
                      resizeMode={'contain'}
                      style={[
                        styles.actionPointsContainer,
                        {
                          width: vw(2.7),
                          height: vw(2.7)
                        }
                      ]}
                      source={deckCard.card.color ? actionPointsBlackContainer : actionPointsWhiteContainer}
                    >
                      <Text style={[styles.pointsText, cardStyles.color]}>{deckCard.card.actionPoints}</Text>
                    </ImageBackground>
                  }
                </View>
              </TouchableWithoutFeedback>
            </ImageBackground>
          );
        })}
      </ScrollView>
      <LinearGradient style={[styles.gradient, { height: vh(5), bottom: vh(0) }]} colors={['rgba(67, 67, 67, 0)', '#434343']} />
    </>
  );
};

export default DeckCardsViewer;
