import { EmptyObject, EnhancedStore, MiddlewareArray } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import jwtDecode from 'jwt-decode';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { EventLogs } from '../commons/dtos/create-log.dto';
import { PlayerTypesEnum } from '../commons/dtos/player.dto';
import { ApiError } from '../commons/errors/api-error';
import { HttpStatus } from '../commons/errors/http-status.enum';
import { JWT } from '../hooks/useGoogle';
import { AuthState, AuthStateAction, actions } from '../redux/Auth/authRedux';
import { setLoadingMissionBackground } from '../redux/Loading-mission/loadingMissionBackground';
import { showLoginModal } from '../redux/Login-modal/loginModal';
import { setProfile } from '../redux/Profile/profileReducer';
import store from '../redux/store';
import LoginService from './login.service';
import LogsService from './logs.service';

const loginService = new LoginService();
const logService = new LogsService();

export default function axiosInterceptor(
  axios: AxiosStatic,
  reduxStore: EnhancedStore<
    EmptyObject & {
      auth: AuthState & PersistPartial;
    } & PersistPartial,
    AuthStateAction,
    MiddlewareArray<any>
  >
) {
  const errorDetail = async (message: string | string[] | undefined) => {
    if (!message) {
      return undefined;
    }
    if (message instanceof Array) {
      return message.length > 0 ? message[0] : undefined;
    }
    return message;
  };
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const {
        auth: { user }
      } = reduxStore.getState();

      if (!config.headers) {
        config.headers = {};
      }
      if (user?.idToken) {
        config.headers.Authorization = `Bearer ${user.idToken}`;
      }
      return config;
    },
    (err: AxiosError) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (err: AxiosError) => {
      const { message: mainMessage } = err;
      const data = (err.response?.data as any) || {};
      const { message } = data ?? undefined;
      const detail = await errorDetail(message);
      const {
        auth: { user }
      } = reduxStore.getState();
      if (data?.name === 'TokenExpiredError' || data?.name === 'UnauthorizedException' || detail === 'Invalid Token') {
        if (PlayerTypesEnum.GUEST === user?.type) {
          const showModalLogin = () => store.dispatch(showLoginModal());
          showModalLogin();
        } else {
          if (user) {
            const { idToken: expiredIdToken, refreshToken, playerId } = user;
            if (isAnotherDay(expiredIdToken) || !refreshToken) {
              const showModalLogin = () => store.dispatch(showLoginModal());
              showModalLogin();
            } else {
              const newIdToken = await loginService.googleRefreshToken(refreshToken!);
              if (newIdToken) {
                const userWithNewToken = { ...user, idToken: newIdToken };
                store.dispatch(actions.setUser(userWithNewToken));
                const player = await loginService.getPlayerByEmail(user.email);
                if (!player) return;
                store.dispatch(actions.login({ ...userWithNewToken, playerId: playerId }, newIdToken));
                store.dispatch(setProfile(player));
                logService.createLog(EventLogs.RELOGIN);
              }
            }
          }
        }
      }
      store.dispatch(setLoadingMissionBackground(undefined));
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const status = err.response?.status || HttpStatus.INTERNAL_SERVER_ERROR;
      // we can handle global errors here
      switch (status) {
        // authentication (token related issues)
        case HttpStatus.UNAUTHORIZED: {
          return Promise.reject(new ApiError(mainMessage, HttpStatus.UNAUTHORIZED, detail));
        }
        // forbidden (permission related issues)
        case HttpStatus.FORBIDDEN: {
          return Promise.reject(new ApiError(mainMessage, HttpStatus.FORBIDDEN, detail));
        }
        // bad request
        case HttpStatus.BAD_REQUEST: {
          return Promise.reject(new ApiError(mainMessage, HttpStatus.BAD_REQUEST, detail));
        }
        // not found
        case HttpStatus.NOT_FOUND: {
          return Promise.reject(new ApiError(mainMessage, HttpStatus.NOT_FOUND, detail));
        }
        // conflict
        case HttpStatus.CONFLICT: {
          return Promise.reject(new ApiError(mainMessage, HttpStatus.CONFLICT, detail));
        }
        // unprocessable
        case HttpStatus.UNPROCESSABLE_ENTITY: {
          return Promise.reject(new ApiError(mainMessage, HttpStatus.UNPROCESSABLE_ENTITY, detail));
        }
        // generic api error (server related) unexpected
        default: {
          return Promise.reject(new ApiError(mainMessage, HttpStatus.INTERNAL_SERVER_ERROR));
        }
      }
    }
  );

  function isAnotherDay(idToken: string): boolean {
    const decodedToken: JWT = jwtDecode(idToken);
    const { exp } = decodedToken;
    const tokenDate = new Date(exp! * 1000);
    // Reset time_play
    return tokenDate.getDay() != new Date().getDay();
  }
}
