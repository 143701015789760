import { HttpStatus } from './http-status.enum';

export class ApiError extends Error {
  httpCode: number;
  error: string;
  detail: string | undefined;

  constructor(error?: string, httpCode?: number, detail?: string | undefined) {
    super(error);
    this.httpCode = httpCode ?? HttpStatus.INTERNAL_SERVER_ERROR;
    this.error = error ?? 'Ocurrió un error al recuperar datos';
    this.detail = detail;
  }
}
