import { ReactNode } from 'react';
import { StyleSheet, ImageBackground, View, Text, Image } from 'react-native';
import { characterDialogImage, irregularBlock2 } from '../../../../constants/Images';
import { ActorEnum } from '../../../dtos/actor.dto';
import adjust from '../../../styles/TextAdjust';
import { isNativeOrPWA, isNativeMobile } from '../../../utils';
import ButtonShape from '../../ButtonShape/ButtonShape';

interface BotBlockProps {
  title: string;
  children: ReactNode;
  withBot?: boolean;
  leftSide?: boolean;
  small?: boolean;
}

const BotBlock = ({ title, children, withBot = true, leftSide = true, small = false }: BotBlockProps) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '100%',
      position: 'relative',
      zIndex: 100
    },
    bot: {
      height: isNativeOrPWA ? 125 : 180,
      width: isNativeOrPWA ? 125 : 180
    },
    block: {
      maxWidth: small ? (!isNativeOrPWA ? 500 : adjust(400)) : adjust(580),
      paddingHorizontal: isNativeOrPWA ? 35 : 70,
      paddingVertical: isNativeOrPWA ? 18 : 32,
      width: !isNativeMobile ? '100%' : 'auto',
      position: 'relative'
    },
    shapeContainer: {
      marginVertical: isNativeOrPWA ? adjust(16) : 18,
      justifyContent: 'center',
      position: 'absolute',
      top: isNativeOrPWA ? -32 : -44
    },
    title: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(15) : 18,
      fontWeight: 'bold',
      color: '#ffffff'
    }
  });

  return (
    <View style={styles.container}>
      {withBot && leftSide && (
        <Image
          resizeMode="contain"
          source={characterDialogImage[ActorEnum.BOT_M4L0]}
          accessible
          accessibilityLabel="Bot avatar"
          style={styles.bot}
        />
      )}
      <ImageBackground style={styles.block} source={irregularBlock2} resizeMode="stretch">
        <View style={styles.shapeContainer}>
          <ButtonShape isPointeable={false} bgColor="black" heightBtn={isNativeOrPWA ? 42 : 56}>
            <Text style={styles.title}>{title}</Text>
          </ButtonShape>
        </View>
        {children}
      </ImageBackground>
      {withBot && !leftSide && (
        <Image
          resizeMode="contain"
          source={characterDialogImage[ActorEnum.BOT_M4L0]}
          accessible
          accessibilityLabel="Bot avatar"
          style={styles.bot}
        />
      )}
    </View>
  );
};

export default BotBlock;
