import { useEffect, useRef } from 'react';
import { Animated, Platform, StyleSheet } from 'react-native';

interface DotProps {
  animation: {
    scale: number;
    duration?: number;
  };
  active: boolean;
  style: {
    size: number;
  };
}

const Dot = ({ animation, active, style }: DotProps) => {
  const scaleRef = useRef<Animated.Value>(new Animated.Value(0)).current;

  const { scale, duration } = animation;
  const { size } = style;

  const styles = StyleSheet.create({
    dot: {
      height: size,
      width: size,
      borderRadius: size / 2,
      marginHorizontal: 6,
      backgroundColor: active ? '#ffffff' : '#888888'
    }
  });

  const scaleUp = Animated.timing(scaleRef, {
    toValue: scale,
    useNativeDriver: Platform.OS !== 'web',
    duration
  });

  const scaleDown = Animated.timing(scaleRef, {
    toValue: 1,
    useNativeDriver: Platform.OS !== 'web',
    duration
  });

  useEffect(() => {
    active ? scaleUp.start() : scaleDown.start();
  }, [active]);

  return <Animated.View style={[styles.dot, { transform: [{ scale: scaleRef }] }]} />;
};

export default Dot;
