import { StyleSheet, Image, TouchableWithoutFeedback, View, Text, ImageBackground } from 'react-native';
import useUnits from 'rxn-units';
import { actionPoints, rarities, subjectsStats } from '../../../constants/CardStatsFilters';
import { selectedShape, unselectedShape, closeIcon, irregularModalContainer } from '../../../constants/Images';
import { CardInventoryFilter } from '../../dtos/card-inventory-filter.dto';
import { isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';
import CardStatsFilterSection from './CardStatsFilterSection/CardStatsFilterSection';
import { useCardStatsFilter } from './useCardStatsFilter';

interface CardStatsFilterModalProps {
  setCardInventoryFilters: Function;
  cardInventoryFilters: CardInventoryFilter;
  showViewAllFilter?: boolean;
  showAllCards?: boolean;
  setShowAllCards?: Function;
  onCloseModal: Function;
}
const CardStatsFilterModal = (props: CardStatsFilterModalProps) => {
  const { vw, vh } = useUnits();
  const { setCardInventoryFilters, cardInventoryFilters } = props;
  const {
    activeSubjectsStat,
    setActiveSubjectsStats,
    activeActionPointsStat,
    setActiveActionPointsStats,
    activeRaritiesStat,
    setActiveRaritiesStats
  } = useCardStatsFilter(cardInventoryFilters, setCardInventoryFilters);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
      backgroundColor: isNativeOrPWA ? '#494949' : 'rgba(0, 0, 0, 0.5)'
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: vw(isNativeOrPWA ? 100 : 58),
      minHeight: 300,
      minWidth: 640,
      height: isNativeOrPWA ? vh(100) : vw(58) / 1.32
    },
    modalSize: {
      maxHeight: isNativeOrPWA ? '99%' : '85%',
      padding: isNativeOrPWA ? 30 : '3%'
    },
    title: {
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 26,
      color: 'white',
      position: 'relative',
      marginTop: isNativeOrPWA ? 10 : '3%'
    },
    subtitle: {
      fontSize: 22,
      fontWeight: '600',
      color: '#A1A1A1',
      marginBottom: 10
    },
    modalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    filters: {
      width: '100%',
      flexDirection: 'row',
      paddingHorizontal: 60,
      justifyContent: 'space-between'
    },
    buttonContainer: {
      zIndex: 200,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: vw(1),
      right: vw(4)
    },
    allSelector: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-start',
      cursor: 'pointer'
    },
    allSelectorText: {
      position: 'relative',
      fontWeight: '900',
      textTransform: 'uppercase',
      color: '#A1A1A1',
      width: 100,
      fontSize: 22
    },
    button: {
      padding: vw(1),
      cursor: 'pointer'
    }
  });

  return (
    <View style={styles.container}>
      <View style={[styles.modal, !isNativeOrPWA && styles.modalSize]}>
        {!isNativeOrPWA && <ImageBackground style={styles.modalBackground} source={irregularModalContainer} resizeMode="stretch" />}
        <Text style={styles.title}>Filtros</Text>
        <TouchableWithoutFeedback onPress={() => props.onCloseModal(false)}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="black" square heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.button}>
                <Image source={closeIcon} style={{ width: vw(1.6), height: vw(1.6) }} resizeMode="contain" />
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
        <View style={styles.filters}>
          <View style={{ paddingTop: vh(15) }}>
            <View style={{ marginBottom: vh(10) }}>
              <Text style={styles.subtitle}>Temática</Text>
              <CardStatsFilterSection
                buttonMobile={isNativeOrPWA}
                activeStat={activeSubjectsStat}
                setActiveStat={setActiveSubjectsStats}
                stats={subjectsStats}
              />
            </View>
            <View style={{ marginBottom: vh(10) }}>
              <Text style={styles.subtitle}>Puntos de acción</Text>
              <CardStatsFilterSection
                buttonMobile={isNativeOrPWA}
                activeStat={activeActionPointsStat}
                setActiveStat={setActiveActionPointsStats}
                stats={actionPoints}
              />
            </View>
          </View>
          <View style={{ paddingTop: vh(15) }}>
            <View style={{ marginBottom: vh(10) }}>
              <Text style={styles.subtitle}>Rareza</Text>
              <CardStatsFilterSection
                buttonMobile={isNativeOrPWA}
                activeStat={activeRaritiesStat}
                setActiveStat={setActiveRaritiesStats}
                stats={rarities}
              />
            </View>
            {props.showViewAllFilter && (
              <View style={{ marginBottom: vh(10) }}>
                <View style={styles.allSelector}>
                  <Text style={styles.allSelectorText}>Todo</Text>
                  <TouchableWithoutFeedback onPress={() => props.setShowAllCards && props.setShowAllCards(!props.showAllCards)}>
                    <Image
                      source={props.showAllCards ? selectedShape : unselectedShape}
                      style={{ width: isNativeOrPWA ? vw(5) : vw(3), height: isNativeOrPWA ? vw(5) : vw(3) }}
                    />
                  </TouchableWithoutFeedback>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default CardStatsFilterModal;
