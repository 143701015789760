import CardModal from '../../../commons/components/Card/CardModal';
import { useCardModal } from '../../../hooks/useCardModal';
import RewardCard from '../RewardCard';
import { CardReveal } from '../useReward';
import { useRewardReveal } from '../useRewardReveal';

interface RewardCardOpenProps {
  card: Required<CardReveal> | null;
  updateCard: (card: Required<CardReveal>) => void;
  handleActiveBack: () => void;
}

const RewardCardOpen = ({ card, updateCard, handleActiveBack }: RewardCardOpenProps) => {
  const { handleSingleReveal } = useRewardReveal(updateCard, card, handleActiveBack);
  const { detailCard, detailCardVisible, handleSelectCardModal, handleVisibilityDetailCard } = useCardModal();
  if (!card) return <></>;

  return (
    <>
      <CardModal showTagsInfo={false} detailCardVisible={detailCardVisible} hide={handleVisibilityDetailCard.hide} card={detailCard} />
      <RewardCard card={card} handleReveal={handleSingleReveal} isOne handleSelectModal={handleSelectCardModal} />
    </>
  );
};

export default RewardCardOpen;
