import { StyleSheet, TouchableOpacity, Image, Linking } from 'react-native';
import useUnits from 'rxn-units';
import { View } from '../../../commons/components/Themed';
import { LogoFad, LogoGoogle } from '../../../constants/Images';

const LoginFormLogos = () => {
  const { vh } = useUnits();
  const openGoogleURL = async () => {
    await Linking.openURL('https://about.google/');
  };
  const openFadURL = async () => {
    await Linking.openURL('https://www.fad.es/');
  };
  const styles = StyleSheet.create({
    containerLogos: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    logoContainer: {
      width: '25%'
    },
    logoFad: {
      width: '100%',
      height: vh(8)
    },
    logo: {
      width: '100%',
      height: vh(10)
    }
  });
  return (
    <View style={styles.containerLogos}>
      <View style={styles.logoContainer}>
        <TouchableOpacity accessible accessibilityLabel={'Link to FAD (Fundación FAD Juventud) page'} onPress={openFadURL}>
          <Image style={styles.logoFad} source={LogoFad} resizeMode="contain" />
        </TouchableOpacity>
      </View>
      <View style={styles.logoContainer}>
        <TouchableOpacity accessible accessibilityLabel={'Link to Google about page'} onPress={openGoogleURL}>
          <Image style={styles.logo} source={LogoGoogle} resizeMode="contain" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LoginFormLogos;
