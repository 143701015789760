import { View, ImageBackground, Image, StyleSheet } from 'react-native';
import { rarityBlackContainer, rarityWhiteContainer } from '../../../constants/Images';
import { Card } from '../../dtos/card.dto';
import { getRarityIcon } from './utils';

interface RarityProps {
  card: Card;
}

const Rarity = ({ card }: RarityProps) => {
  const styles = StyleSheet.create({
    rarityCardContainer: {
      width: '11.5%',
      height: '8.5%',
      position: 'absolute',
      bottom: '21%',
      right: '3%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rarityCardContainerBackground: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rarityIcon: { width: '75%', height: '75%' }
  });
  return (
    <View style={styles.rarityCardContainer}>
      <ImageBackground style={styles.rarityCardContainerBackground} source={card.color ? rarityBlackContainer : rarityWhiteContainer}>
        <Image style={styles.rarityIcon} source={getRarityIcon(card)} />
      </ImageBackground>
    </View>
  );
};

export default Rarity;
