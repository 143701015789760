import { ImageBackground, ImageSourcePropType, View, Text, Image } from 'react-native';
import {
  actionPointsBlackContainer,
  actionPointsWhiteContainer,
  cardNameBlackContainer,
  cardNameWhiteContainer,
  subjectBlackContainer
} from '../../../constants/Images';
import { ColorCardEnum } from '../../constants/colorCard';
import { Card } from '../../dtos/card.dto';
import { SubjectsEnum } from '../../dtos/subject.dto';
import { isGameActionHighlight } from '../../events-management/game-actions/gameActions';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import { useCardStyles } from './useCardStyles';
import { CardSizesEnum, getImageCard, getSubjectIcon, selectSize } from './utils';

interface CardComponentsProps {
  card: Card;
  cardSize: CardSizesEnum;
}

export const CardBackground = ({ card }: Pick<CardComponentsProps, 'card'>) => {
  return (
    <ImageBackground style={{ width: '100%', height: '100%' }} source={getImageCard(card) as ImageSourcePropType} resizeMode="stretch" />
  );
};

export const CardName = ({ card, cardSize }: CardComponentsProps) => {
  const { cardName, color, rules } = card;
  const hasAction = rules?.[0];
  const { propsStyles, styles } = useCardStyles(card, cardSize, !!hasAction);

  return (
    <View style={styles.cardNameContainer}>
      <ImageBackground
        style={styles.cardNameBackground}
        source={color ? cardNameBlackContainer : cardNameWhiteContainer}
        resizeMode="cover"
      />
      <Text style={[styles.cardName, propsStyles.cardName]} ellipsizeMode="tail" numberOfLines={2}>
        {cardName?.toUpperCase()}
      </Text>
    </View>
  );
};

export const CardSubject = ({ card, cardSize }: CardComponentsProps) => {
  const { subject, rules } = card;
  const hasAction = rules?.[0];

  const { styles } = useCardStyles(card, cardSize, !!hasAction);

  if (!(subject?.subjectName && subject.subjectName !== SubjectsEnum.NEUTRAL)) return null;

  return (
    <ImageBackground
      resizeMode="contain"
      style={[
        styles.subjectContainer,
        {
          top: selectSize(cardSize, '2%', '5%', '2%', '2%')
        }
      ]}
      source={subjectBlackContainer}
    >
      <Image style={styles.subject} source={getSubjectIcon(card)} resizeMode="contain" />
    </ImageBackground>
  );
};

export const CardActionPoints = ({ card, cardSize }: CardComponentsProps) => {
  const { rules, color, actionPoints, cardCode } = card;
  const hasAction = rules?.[0];
  const { rw } = useRatioDimensions();
  const { styles, propsStyles } = useCardStyles(card, cardSize, !!hasAction);

  const handHighLight = isGameActionHighlight(`player-action-points-card-hand-${cardCode}`);

  const actionPointsHighlight = handHighLight
    ? color === ColorCardEnum.BLACK
      ? styles.actionPointsHighlightBlackCard
      : styles.actionPointsHighlightWhiteCard
    : {};

  return (
    <ImageBackground
      resizeMode="contain"
      style={[
        styles.actionPointsContainer,
        actionPointsHighlight,
        {
          width: selectSize(cardSize, rw(4.8), rw(4.2), rw(3), rw(2)),
          height: selectSize(cardSize, rw(4.8), rw(4.2), rw(3), rw(2)),
          left: selectSize(cardSize, -rw(0.8), -rw(1), -rw(0.8), -rw(0.4)),
          top: selectSize(cardSize, -rw(0.8), -rw(1), -rw(0.8), -rw(0.5))
        }
      ]}
      source={color ? actionPointsBlackContainer : actionPointsWhiteContainer}
    >
      <Text
        selectable={false}
        style={[
          styles.pointsText,
          styles.actionPointsText,
          propsStyles.actionPointsText,
          handHighLight
            ? {
                fontSize: selectSize(cardSize, rw(3.5), rw(3), rw(2.4), rw(1.9)) as number
              }
            : {
                fontSize: selectSize(cardSize, rw(3), rw(2.4), rw(1.9), rw(1.4)) as number
              }
        ]}
      >
        {actionPoints}
      </Text>
    </ImageBackground>
  );
};
