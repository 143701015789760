// eslint-disable-next-line no-shadow
export enum RaritiesEnum {
  SILVER = 'plata',
  GOLD = 'oro',
  PLATINUM = 'platino'
}

/**
 * Rarity variable type declaration.
 *
 * @interface
 */
export class Rarity {
  id?: string;

  rarityName!: RaritiesEnum;

  constructor(properties?: RarityProperties) {
    if (properties) {
      this.rarityName = properties.rarityName;
    }
  }
}
export interface RarityProperties {
  id?: string;
  rarityName: RaritiesEnum;
}
