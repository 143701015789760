import { Card } from '../../dtos/card.dto';
import { CardEnhanceEffect } from '../../dtos/enhance.dto';
import { Rule } from '../../dtos/rule.dto';
import { slugRuleCardId } from './rules';

function removeEnhaceCard(rule: Rule, boardCards: Card[]): Card[] {
  const { id: ruleId, defensePoints: ruleDefensePoints, damagePoints: ruleDamagePoints } = rule;
  const updatedBoardCards = boardCards.map((boardCard: Card) => {
    const { id: boardCardId, enhance, damagePoints: cardDamagePoints, defensePoints: cardDefensePoints } = boardCard;
    const ruleCardId = slugRuleCardId(boardCardId, ruleId);
    const isRemovableEnhace = enhance && (enhance[ruleCardId] || enhance[`${boardCardId}__${boardCardId}`]);

    if (isRemovableEnhace) {
      if (ruleDamagePoints && ruleDamagePoints > 0) {
        boardCard.damagePoints = (boardCard.damagePoints as number) - ruleDamagePoints;
        if (cardDamagePoints && cardDamagePoints < 0) {
          boardCard.damagePoints = 0;
        }
      }
      if (ruleDefensePoints && ruleDefensePoints > 0) {
        boardCard.defensePoints = (cardDefensePoints || 0) - ruleDefensePoints;
      }
      boardCard.upgradedFirstTurn = false;
      delete enhance[ruleCardId];
    }
    return boardCard;
  });
  return updatedBoardCards;
}

function saveEnhanceCard(boardCard: Card, rule: Rule, enhanceEffect?: CardEnhanceEffect[]) {
  if (!boardCard.enhance) {
    boardCard.enhance = {};
  }

  const { id: ruleId, ruleName, ruleType, ruleText, defensePoints, damagePoints } = rule;
  const ruleCardId = slugRuleCardId(boardCard.id, ruleId);

  boardCard.enhance[ruleCardId] = {
    id: ruleId,
    ruleName,
    ruleType,
    ruleText,
    ruleDefensePoints: defensePoints,
    ruleDamagePoints: damagePoints,
    enhanceEffect: enhanceEffect !== undefined && enhanceEffect.length ? enhanceEffect : null,
    appliedEnhance: false
  };
}

const enhanceUtils = {
  saveEnhanceCard,
  removeEnhaceCard
};

export default enhanceUtils;
