import { ImageSourcePropType } from 'react-native';
import { Collection } from './collection.dto';
import { AppliedEnhanceRule, CardEnhanceEffect } from './enhance.dto';
import { Rarity } from './rarity.dto';
import { BasicRuleProperties } from './rule.dto';
import { Subject } from './subject.dto';
import { Tag, TagsEnum } from './tag.dto';
import { Token } from './token.dto';
import { Tweet } from './tweet.dto';
import { Type } from './type.dto';

/**
 * Card variable type declaration.
 *
 * @interface
 */
export class Card implements CardProperties {
  id!: string;
  cardName!: string;
  cardCode!: string;
  cardText!: string;
  explanation!: string;
  illustration!: string;
  imageSource?: ImageSourcePropType;
  color!: number;
  fakeText?: string;
  tweet!: Tweet;
  actionPoints!: number;
  damagePoints!: number | null;
  defensePoints!: number | null;
  quantity?: number;
  collection!: Collection | null;
  rarity!: Rarity | null;
  subject!: Subject | null;
  type!: Type | null;
  tags!: Tag[] | null;
  isPlayingCard?: boolean;
  isWaiting?: boolean;
  isUndetectable!: boolean;
  firstTurn?: boolean;
  isAttacking?: boolean;
  isAttackable?: boolean;
  useRequirementColor?: number | null;
  useRequirementSubject?: Subject | null;
  useRequirementType?: Type | null;
  requirementIsBonus?: number;
  rules!: BasicRuleProperties[] | null;
  enhance!: AppliedEnhanceRule | null;
  token!: Token | null;
  idUnique?: number | string;
  upgradedFirstTurn?: boolean;
  enhanceEffect?: CardEnhanceEffect[];
  isToken?: boolean;
  isDead?: boolean;

  constructor(properties?: CardProperties) {
    if (properties) {
      this.id = properties.id;
      this.cardName = properties.cardName;
      this.cardCode = properties.cardCode;
      this.cardText = properties.cardText;
      this.explanation = properties.explanation;
      this.illustration = properties.illustration;
      this.imageSource = properties.imageSource;
      this.color = properties.color;
      this.actionPoints = properties.actionPoints;
      this.damagePoints = properties.damagePoints;
      this.defensePoints = properties.defensePoints;
      this.collection = properties.collection ? new Collection(properties.collection) : null;
      this.rarity = properties.rarity ? new Rarity(properties.rarity) : null;
      this.subject = properties.subject ? new Subject(properties.subject) : null;
      this.type = properties.type ? new Type(properties.type) : null;
      this.tags = properties.tags ? properties.tags.map(tag => new Tag(tag)) : null;
      this.isPlayingCard = properties.isPlayingCard;
      this.isWaiting = properties.isWaiting;
      this.isUndetectable = properties?.tags?.some(tag => tag.tagName === TagsEnum.UNDETECTABLE) ?? false;
      this.fakeText = properties.fakeText;
      this.tweet = new Tweet();
      this.firstTurn = properties.firstTurn;
      this.isAttacking = properties.isAttacking;
      this.isAttackable = properties.isAttackable;
      this.useRequirementColor = properties.useRequirementColor;
      this.useRequirementSubject = properties.useRequirementSubject;
      this.useRequirementType = properties.useRequirementType;
      this.requirementIsBonus = properties.requirementIsBonus;
      this.rules = properties.rules;
      this.enhance = properties.enhance ? properties.enhance : {};
      this.token = properties.token;
      this.idUnique = properties.idUnique;
      this.upgradedFirstTurn = properties.upgradedFirstTurn;
      this.enhanceEffect = properties.enhanceEffect;
      this.isToken = properties.isToken;
      this.isDead = properties.isDead;
    }
  }
}

export interface CardProperties {
  id: string;
  cardName: string;
  cardCode: string;
  cardText: string;
  explanation: string;
  illustration: string;
  imageSource?: ImageSourcePropType;
  color: number;
  fakeText?: string;
  tweet: Tweet;
  actionPoints: number;
  damagePoints: number | null;
  defensePoints: number | null;
  collection: Collection | null;
  rarity: Rarity | null;
  subject: Subject | null;
  type: Type | null;
  tags: Tag[] | null;
  isPlayingCard?: boolean;
  isWaiting?: boolean;
  isUndetectable?: boolean;
  firstTurn?: boolean;
  isAttacking?: boolean;
  isAttackable?: boolean;
  useRequirementColor?: number | null;
  useRequirementSubject?: Subject | null;
  useRequirementType?: Type | null;
  requirementIsBonus?: number;
  rules: BasicRuleProperties[] | null;
  enhance: AppliedEnhanceRule | null;
  token: Token | null;
  idUnique?: number | string;
  upgradedFirstTurn?: boolean;
  enhanceEffect?: CardEnhanceEffect[];
  isToken?: boolean;
  isDead?: boolean;
}
