let timeout: ReturnType<typeof setTimeout> | null = null;
// ms
const delay = 5000;

export default ({ dispatch, getState }: { dispatch: any; getState: () => any }) => {
  return (next: Function) => (action: any) => {
    const state = getState();

    if (action.type === 'game-actions/nextGameAction') {
      if (timeout) clearTimeout(timeout);
      if (state.gameActions.gameActions.length === 0) {
        dispatch({ type: 'game-reactions/nextGameReaction' });
        timeout = setTimeout(() => {
          dispatch({ type: 'game-actions/clearCurrentGameAction' });
        }, delay);
      } else timeout = setTimeout(() => dispatch({ type: 'game-actions/nextGameAction' }), delay);
    }
    return next(action);
  };
};
