import axios from 'axios';
import { AvatarProperties } from '../commons/dtos/avatar.dto';
import { Card as CardType } from '../commons/dtos/card.dto';
import { Reward, RewardColorEnum, RewardRarityEnum, RewardTypeEnum } from '../commons/dtos/reward.dto';
import { TitleProperties } from '../commons/dtos/title.dto';

interface RewardRequest {
  rarity?: RewardRarityEnum;
  color?: RewardColorEnum;
  type: RewardTypeEnum;
  quantity?: number;
  playerId: string;
  id?: string;
}

export default class RewardsService {
  claimRewards = async (playerId: string, reward: Reward) => {
    const { rewardId, type, color, quantity, rarity } = reward;
    const rewardRequest: RewardRequest = {
      playerId,
      type,
      id: rewardId || undefined,
      color: color || undefined,
      quantity: quantity || undefined,
      rarity: rarity || undefined
    };

    const { data } = await axios.post(`${process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL}/rewards/claim`, rewardRequest);
    return data;
  };
  claimAvatar = (playerId: string, reward: Reward): Promise<Required<AvatarProperties>> => this.claimRewards(playerId, reward);
  claimTitle = (playerId: string, reward: Reward): Promise<Required<TitleProperties>> => this.claimRewards(playerId, reward);
  claimCard = (playerId: string, reward: Reward): Promise<Array<Required<CardType>>> => this.claimRewards(playerId, reward);
  claimCardPack = (playerId: string, reward: Reward): Promise<Array<Required<CardType>>> => this.claimRewards(playerId, reward);
}
