import { PlayerTypeEnum } from '../../constants/player';
import { opponentEvents, playerEvents } from '../../dtos/reaction-configuration.dto';
import { TweetsReactionConfiguration } from '../../dtos/tweet-reaction-configuration.dto';

export function isCurrentPlayerTarget(reactionConfig: TweetsReactionConfiguration, player: PlayerTypeEnum): boolean {
  return (
    (playerEvents.includes(reactionConfig.trigger.event) && player === PlayerTypeEnum.PLAYER) ||
    (opponentEvents.includes(reactionConfig.trigger.event) && player === PlayerTypeEnum.OPPONENT)
  );
}
