import type { Card } from '../../dtos/card.dto';
import { PlayerRoundEnum } from '../../dtos/player.dto';
import { AppliedRule, AppliedRules, Rule, WhenAppliedRules, WhenEnum } from '../../dtos/rule.dto';
import { TagsEnum } from '../../dtos/tag.dto';
import { TypesEnum } from '../../dtos/type.dto';
import { isNotNull } from '../../utils';
import enhanceUtils from './enhance';
import sideUtils from './side';

export const initialPlayerAppliedRules: WhenAppliedRules = { lose: {}, win: {}, alive: {}, stop: {}, play: {} };
export const initialOpponentAppliedRules: WhenAppliedRules = { lose: {}, win: {}, alive: {}, stop: {}, play: {} };
export const initialAppliedRules: AppliedRules = {
  player: initialPlayerAppliedRules,
  opponent: initialOpponentAppliedRules
};

export const contextAppliedRules = {
  appliedRules: { ...JSON.parse(JSON.stringify(initialAppliedRules)) }
};

const { getIsRival } = sideUtils;

export function slugRuleCardId(cardId: string, ruleId: string): string {
  return `${cardId}__${ruleId}`;
}

export function checkSomeAliveRule(rules: Rule[]): boolean {
  return rules.some(({ applyWhen }) => applyWhen === WhenEnum.ALIVE);
}

export function getCardRules(playedCard: Card, allRules: Rule[]): Rule[] {
  const { rules } = playedCard;
  if (!rules) return [];

  const populatedRules = rules.map(({ id }) => {
    const rule = allRules.find(rule => {
      // Check the all rules id
      return id.split('/')[0] === rule.id.split('/')[0];
    });

    if (!rule) return null;
    rule.id = id;
    return rule;
  });

  const filteredRules = populatedRules.filter(isNotNull);
  return filteredRules;
}

export function isActionOrSupportCard(card: Card): boolean {
  const { type } = card;
  return type ? [TypesEnum.ACTION, TypesEnum.SUPPORT].includes(type.typeName) : false;
}

export function getApplicableRules(card: Card, targetCard: Card, allRules: Rule[], isSelfRound?: boolean | null): Rule[] {
  if (!isActionOrSupportCard(card) || !card.rules) return [];
  const actionCardRules = getCardRules(card, allRules) as Rule[];
  return actionCardRules.filter(rule => isApplicableRuleToTarget(rule, targetCard, targetCard.id, isSelfRound));
}

function checkApplyToSameTypeTarget(boardCard: Card, rule: Rule): boolean {
  const { type } = boardCard;
  const { applyToType } = rule;

  if (applyToType?.length) {
    return applyToType?.some(({ typeName }) => typeName === type?.typeName);
  }
  return true;
}

function checkApplyToSameSubjectTarget(boardCard: Card, rule: Rule): boolean {
  const { subject } = boardCard;
  const { applyToSubject } = rule;

  if (applyToSubject?.length) {
    return applyToSubject.some(({ subjectName }) => subjectName === subject?.subjectName);
  }
  return true;
}

function checkApplyToSameColorTarget(boardCardColor: number, ruleColor: number | null): boolean {
  return ruleColor !== null ? ruleColor === boardCardColor : true;
}

function checkApplyToSamePlayer(rule: Rule, isSelfRound: boolean | null): boolean {
  const { applyToOpponent, applyToPlayer } = rule;
  return (
    (applyToOpponent && isSelfRound === false) ||
    (applyToPlayer && isSelfRound === true) ||
    (applyToOpponent === null && applyToPlayer === null) ||
    isSelfRound === null
  );
}

export function haveConditionAvatarLessDefensePoints({
  isOpponent,
  opponentLifePoints,
  playerLifePoints
}: {
  isOpponent: boolean;
  playerLifePoints: number;
  opponentLifePoints: number;
}): boolean {
  return isOpponent ? playerLifePoints > opponentLifePoints : playerLifePoints < opponentLifePoints;
}

export function haveConditionLessDamagePoints(rule: Rule, boardCard: Card): boolean {
  return rule?.haveConditionLessDamagePoints ? (boardCard?.damagePoints as number) <= (rule?.damagePoints as number) : true;
}

export function haveDestroyCardsDamagePoints(rule: Rule, boardCard: Card): boolean {
  return rule?.destroyCardsDamagePoints ? (boardCard?.defensePoints as number) <= (rule?.destroyCardsDamagePoints as number) : true;
}

export function isApplicableRuleCard(boardCard: Card, rule: Rule): boolean {
  const { id, defensePoints, color, enhance } = boardCard;
  const { id: ruleId, applyToColor } = rule;
  const ruleCardId = slugRuleCardId(id, ruleId);
  const _checkApplyToSameTypeTarget = checkApplyToSameTypeTarget(boardCard, rule);
  const _checkApplyToSameSubjectTarget = checkApplyToSameSubjectTarget(boardCard, rule);
  const _checkApplyToSameColorTarget = checkApplyToSameColorTarget(color, applyToColor ?? null);
  const _isAliveCard = defensePoints !== null && defensePoints > 0;
  const _hasNotEnhance = !enhance || (enhance && !Object.keys(enhance).includes(ruleCardId));
  return _checkApplyToSameTypeTarget && _checkApplyToSameSubjectTarget && _checkApplyToSameColorTarget && _isAliveCard && _hasNotEnhance;
}

export function checkApplyToAllCards(rule: Rule, boardCards: Card[]): boolean {
  const { applyToAllCards, applyToColor } = rule;
  if (applyToAllCards) {
    const isApplicableRuleToAllCards = boardCards.some(boardCard => {
      const _haveDestroyCardsDamagePoints = haveDestroyCardsDamagePoints(rule, boardCard);
      const _checkApplyToSameTypeTarget = checkApplyToSameTypeTarget(boardCard, rule);
      const _checkApplyToSameSubjectTarget = checkApplyToSameSubjectTarget(boardCard, rule);
      const _checkApplyToSameColorTarget = checkApplyToSameColorTarget(boardCard?.color, applyToColor ?? null);
      return _haveDestroyCardsDamagePoints && _checkApplyToSameTypeTarget && _checkApplyToSameSubjectTarget && _checkApplyToSameColorTarget;
    });
    return isApplicableRuleToAllCards;
  }
  return true;
}

export function isApplicableRuleToTarget(rule: Rule, boardCard: Card, targetId: string, isSelfRound: boolean | null = null): boolean {
  const { id, tags, color, enhance } = boardCard;
  const { id: ruleId, removeCapabilities, applyToColor } = rule;
  const ruleCardId = slugRuleCardId(id, ruleId);
  const _correctTarget = id === targetId;
  const _tagsUndetectable =
    !tags?.some(({ tagName }) => tagName === TagsEnum.UNDETECTABLE) ||
    !getIsRival(rule) ||
    (!!removeCapabilities && tags?.some(({ tagName }) => tagName === TagsEnum.UNDETECTABLE));
  const _haveConditionLessDamagePoints = haveConditionLessDamagePoints(rule, boardCard);
  const _checkApplyToSameTypeTarget = checkApplyToSameTypeTarget(boardCard, rule);
  const _checkApplyToSameSubjectTarget = checkApplyToSameSubjectTarget(boardCard, rule);
  const _checkApplyToSameColorTarget = checkApplyToSameColorTarget(color, applyToColor ?? null);
  const _checkApplyToSamePlayer = checkApplyToSamePlayer(rule, isSelfRound);
  const _hasNotEnhance = !enhance || (enhance && !Object.keys(enhance).includes(ruleCardId));

  return (
    _correctTarget &&
    _tagsUndetectable &&
    _haveConditionLessDamagePoints &&
    _checkApplyToSameTypeTarget &&
    _checkApplyToSameSubjectTarget &&
    _checkApplyToSameColorTarget &&
    _checkApplyToSamePlayer &&
    _hasNotEnhance
  );
}

function removeRule(playedCard: Card, queueRules: WhenAppliedRules, boardCards: Card[], applyWhen: WhenEnum, rule: Rule) {
  const ruleCardId = slugRuleCardId(playedCard.id, rule.id as string);
  const ruleToRemove = (queueRules[applyWhen as WhenEnum] as AppliedRule)[ruleCardId];
  if (ruleToRemove) {
    delete queueRules[applyWhen as WhenEnum][ruleCardId];
    return enhanceUtils.removeEnhaceCard(ruleToRemove as Rule, boardCards);
  }
  return boardCards;
}

export function getAllRulesByBoardCards(currentPlayerBoardCards: Card[], allRules: Rule[]) {
  return currentPlayerBoardCards.map(card => {
    return getCardRules(card, allRules).map(rule => rule.id);
  }) as unknown as string[];
}

export function checkIfNeccesaryRemoveCardRule(
  queueRules: WhenAppliedRules,
  playedCard: Card,
  boardCards: Card[],
  allRules: Rule[],
  appliedRules: AppliedRules
): [Card[], AppliedRules] {
  const playedCardRules = getCardRules(playedCard, allRules);

  let copyBoardCards = [...boardCards];

  if ((playedCard.defensePoints as number) <= 0) {
    Object.keys(queueRules).forEach(applyWhen => {
      playedCardRules.forEach(rule => {
        copyBoardCards = removeRule(playedCard, queueRules, copyBoardCards, applyWhen as WhenEnum, rule);
      });
    });

    return [copyBoardCards, appliedRules];
  }

  return [copyBoardCards, appliedRules];
}

export function getQueueRules(isOpponent: boolean, appliedRules: AppliedRules, isRival = false): WhenAppliedRules {
  const { player, opponent } = appliedRules;
  const isPlayer = sideUtils.isAppliedToPlayerBoardCards(isOpponent, isRival);

  return isPlayer ? player : opponent;
}

export function updateCardPoints({ boardCard, rule }: { boardCard: Card; rule: Rule }): { applied: boolean; boardCard: Card } {
  let applied = false;

  const { damagePoints: initialDamagePoints, defensePoints: initialDefensePoints } = boardCard;
  const { damagePoints, defensePoints } = rule;

  if (damagePoints) {
    applied = true;
    boardCard.damagePoints = (initialDamagePoints as number) + damagePoints;
    if (boardCard.damagePoints < 0) {
      boardCard.damagePoints = 0;
    }
  }
  if (defensePoints) {
    applied = true;
    boardCard.defensePoints = (initialDefensePoints as number) + defensePoints;
    if (boardCard.defensePoints < 0) {
      boardCard.damagePoints = 0;
    }
  }

  if (!boardCard.firstTurn && (boardCard.damagePoints as number) > 0 && initialDamagePoints === 0) {
    boardCard.isPlayingCard = true;
    boardCard.isWaiting = false;
  }

  return { applied, boardCard };
}

export function getPointsToRandomCardByRule(rule: Rule, boardCards: Card[]): Card | null {
  const applyToCard = boardCards.sort(() => 0.5 - Math.random()).filter(card => isApplicableRuleCard(card, rule));

  const [extractedCard] = applyToCard;

  return applyToCard.length > 0 ? extractedCard : null;
}

export function getPointsByRule(rule: Rule, boardCards: Card[]): Card[] {
  const applyToCard = boardCards.filter(boardCard => isApplicableRuleCard(boardCard, rule));

  return applyToCard;
}

export function checkDestroyCardByRule(rule: Rule, boardCards: Card[]) {
  return boardCards.filter(boardCard => haveDestroyCardsDamagePoints(rule, boardCard) && isApplicableRuleCard(boardCard, rule));
}

export function isStoppedCard(boardCardId: string, isOpponent: boolean, appliedRules: AppliedRules, round: number) {
  const currentAppliedRules = { ...appliedRules };
  const checkStoppedCards = (side: PlayerRoundEnum) => {
    return (
      currentAppliedRules[side].stop[boardCardId] &&
      currentAppliedRules[side].stop[boardCardId]?.stopByTurns + currentAppliedRules[side].stop[boardCardId]?.stoppedRound >= round
    );
  };
  return checkStoppedCards(isOpponent ? PlayerRoundEnum.OPPONENT : PlayerRoundEnum.PLAYER);
}

export function applyToTarget(targetId: string, rule: Rule): boolean {
  const { applyToCurrentCard, needTarget } = rule;
  return (targetId !== '' && needTarget!) || (targetId !== '' && applyToCurrentCard!);
}
