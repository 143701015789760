import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, Image } from 'react-native';
import useUnits from 'rxn-units';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { GameLogo, howSection1Bg, irregularBlock2, tutorialAfo } from '../../../../constants/Images';
import { isNativeMobile, isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import { useStylesHow } from '../useStylesHow';

const HowSectionStart = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();
  const { vh } = useUnits();

  return (
    <ImageBackground source={howSection1Bg} resizeMode="cover" style={[styles.section, { justifyContent: 'space-between' }]}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.4, 0.6, 1]}
        pointerEvents="none"
      />
      <Image
        resizeMode="contain"
        style={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: '20%',
          width: '25%',
          maxWidth: isNativeMobile ? 180 : 260,
          position: 'relative',
          zIndex: 100,
          marginTop: isNativeMobile ? vh(5) : vh(2),
          marginBottom: vh(5)
        }}
        source={GameLogo}
      />
      <ImageBackground style={[styles.block2, { maxWidth: isNativeOrPWA ? 440 : 620 }]} source={irregularBlock2} resizeMode="stretch">
        <Text style={styles.title}>{howTitle['tutorial-start-title']}</Text>
        <Text style={[styles.paragraph, { textAlign: 'center' }]}>
          <TextWithHTML text={howText['tutorial-start-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
        </Text>
      </ImageBackground>
      <Image
        style={{ maxHeight: 214, width: '100%', flexGrow: 1, flexShrink: 1, flexBasis: '100%' }}
        resizeMode="contain"
        source={tutorialAfo}
        accessible
        accessibilityLabel="afo image"
      />
    </ImageBackground>
  );
};

export default HowSectionStart;
