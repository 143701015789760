import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, View, Image } from 'react-native';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard, glass1, howCard } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import BotBlock from '../BotBlock/BotBlock';
import { useStylesHow } from '../useStylesHow';

const HowSectionThrow = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <View style={[styles.splitContainer, isNativeOrPWA && { maxWidth: '90%', marginHorizontal: 'auto' }]}>
        <View style={styles.splitView}>
          <BotBlock small withBot={!isNativeOrPWA} title={howTitle['throw-card-title']}>
            <Text style={styles.paragraph}>
              <TextWithHTML text={howText['throw-card-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
            </Text>
          </BotBlock>
        </View>
        <View style={styles.splitView}>
          <Image style={styles.card} resizeMode="contain" source={howCard} accessible accessibilityLabel="card image" />
          <Image
            style={{ position: 'absolute', left: isNativeOrPWA ? '4%' : '10%', top: '-14%', height: '45%', width: '45%' }}
            resizeMode="contain"
            source={glass1}
            accessible
            accessibilityLabel="card image"
          />
        </View>
      </View>
    </ImageBackground>
  );
};

export default HowSectionThrow;
