/* eslint-disable react-native/no-unused-styles */
import { useMemo } from 'react';
import { StyleSheet, LogBox } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { Card as CardType } from '../../../commons/dtos/card.dto';
import { AttackingStatus, ItemInfo } from '../../../types/drag';
import BoardCardsWrapper from './BoardCardsWrapper';
import { BoardCardsHookProps } from './useBoardCards';

LogBox.ignoreLogs(['ViewPropTypes will be removed']);

export interface BoardCardsProps {
  playerIsBeingAttacked: boolean;
  opponentIsBeingAttacked: boolean;
  AITarget: CardType | null;
  AISelectingTarget: CardType | null;
  config: BoardCardsHookProps;
  setBoardCards: (card: CardType, side: 'player' | 'opponent') => void;
  handleAddItem: (id: string, info: ItemInfo) => void;
  attackingStatus: AttackingStatus;
  setAttackingStatus: Function;
}

const BoardCardsMobile = (props: BoardCardsProps) => {
  const { config } = props;
  const { isPlayerRound } = config;
  const { rw } = useRatioDimensions();

  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        position: 'absolute',
        width: '70%',
        height: '70%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignContent: 'center',
        zIndex: 1
      },
      dropContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        zIndex: 1
      },
      dropContainerBackground: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        zIndex: 0,
        position: 'absolute',
        top: 0,
        left: 0
      },
      boardCardscontainer: {
        width: 'auto',
        height: '40%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
      cardsContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        zIndex: isPlayerRound ? 10 : 9
      },
      cardsContainerOpponent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
        zIndex: !isPlayerRound ? 10 : 9
      },
      card: {
        width: rw(8),
        height: rw(11.7),
        margin: rw(0.8)
      }
    });
  }, [rw]);

  return <BoardCardsWrapper props={props} styles={styles} />;
};

export default BoardCardsMobile;
