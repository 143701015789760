import { TouchableWithoutFeedback, StyleSheet, Image } from 'react-native';
import { fullScreenIcon, noFullScreenIcon } from '../../../constants/Images';
import useFullScreen from '../../../hooks/useFullScreen';
import adjust from '../../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';
import { Text, View } from '../Themed';

const FullScreenWebButton = ({ iconMode = false }: { iconMode?: boolean }) => {
  const { rw } = useRatioDimensions();
  const { isFullScreen, toggleFullScreen } = useFullScreen();

  const styles = StyleSheet.create({
    buttonText: {
      color: !isFullScreen ? '#444' : '#D3D3D3',
      fontWeight: '600',
      fontSize: adjust(18),
      textAlign: 'center'
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    },
    buttonContainer: {
      width: isNativeMobile ? '80%' : '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button: {
      padding: rw(1),
      paddingBottom: isNativeOrPWA ? rw(1.3) : rw(1.1),
      cursor: 'pointer'
    },
    buttonIcon: { height: isNativeOrPWA ? 18 : 32, width: isNativeOrPWA ? 18 : 32 }
  });

  if (iconMode) {
    return (
      <TouchableWithoutFeedback accessible accessibilityLabel="FullScreen button" onPress={toggleFullScreen}>
        <View>
          <ButtonShape square bgColor={isFullScreen ? 'black' : 'gray'} border heightBtn={isNativeOrPWA ? 34 : 56}>
            <View style={styles.button}>
              <Image style={styles.buttonIcon} source={isFullScreen ? noFullScreenIcon : fullScreenIcon} resizeMode={'contain'}></Image>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
    );
  }

  return (
    <TouchableWithoutFeedback accessible accessibilityLabel="FullScreen button" onPress={toggleFullScreen}>
      <View style={styles.buttonContainer}>
        <ButtonShape bgColor={!isFullScreen ? 'white' : 'dark'} border heightBtn={isNativeOrPWA ? 32 : 46}>
          <View style={styles.buttonInner}>
            <Text style={styles.buttonText}>{isFullScreen ? 'Desactivar' : 'Activar'}</Text>
          </View>
        </ButtonShape>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default FullScreenWebButton;
