import { ReactNode, useLayoutEffect, useRef } from 'react';
import { ImageBackground, StyleSheet, Animated, ViewStyle, Platform, Image } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { isNativeOrPWA } from '../../../commons/utils';
import {
  blueBubbleBottom,
  blueBubbleMiddle,
  blueBubbleTop,
  blueBubbleTriangle,
  yellowBubbleBottom,
  yellowBubbleMiddle,
  yellowBubbleTop,
  yellowBubbleTriangle
} from '../../../constants/Images';

interface DialogProps {
  children: ReactNode;
  color: 'yellow' | 'blue';
  renderText: boolean;
  style?: ViewStyle | ViewStyle[] | Animated.WithAnimatedObject<ViewStyle> | Animated.WithAnimatedArray<ViewStyle>;
}

const Bubble = ({ children, color, style, renderText }: DialogProps) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const position = useRef(new Animated.Value(0)).current;

  const { rw } = useRatioDimensions();

  const isYellow = color === 'yellow';
  const topImage = isYellow ? yellowBubbleTop : blueBubbleTop;
  const middleImage = isYellow ? yellowBubbleMiddle : blueBubbleMiddle;
  const bottomImage = isYellow ? yellowBubbleBottom : blueBubbleBottom;
  const triangleImage = isYellow ? yellowBubbleTriangle : blueBubbleTriangle;

  useLayoutEffect(() => {
    Animated.timing(position, {
      toValue: 1,
      duration: 300,
      delay: 100,
      useNativeDriver: Platform.OS !== 'web'
    }).start(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: Platform.OS !== 'web'
      }).start();
    });
  }, []);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      height: 'auto',
      width: isNativeOrPWA ? 220 : 260,
      top: 0,
      left: isNativeOrPWA ? rw(4) : rw(6),
      justifyContent: 'center'
    },
    top: {
      flexGrow: 0,
      width: '100%',
      height: isNativeOrPWA ? 10 : 14
    },
    bottom: {
      height: isNativeOrPWA ? 14 : 20,
      marginTop: -1,
      flexGrow: 0,
      width: '100%'
    },
    middle: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: 1,
      minHeight: 40,
      paddingHorizontal: isNativeOrPWA ? 16 : 30,
      paddingVertical: isNativeOrPWA ? 6 : 10,
      marginTop: -1
    },
    triangle: {
      width: isNativeOrPWA ? 9 : 12,
      height: isNativeOrPWA ? 14 : 18,
      position: 'absolute',
      left: isNativeOrPWA ? -9 : -12
    },
    childrenContainer: {
      width: '100%',
      height: 'auto',
      maxHeight: isNativeOrPWA ? 50 : 80,
      marginHorizontal: 'auto'
    }
  });

  return (
    <Animated.View
      pointerEvents="none"
      style={[
        styles.container,
        style,
        {
          opacity: position,
          transform: [
            {
              translateX: position.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 40]
              })
            }
          ]
        }
      ]}
    >
      <Image source={topImage} style={styles.top} resizeMode="stretch" />
      <ImageBackground source={middleImage} style={styles.middle} resizeMode="stretch">
        <Image source={triangleImage} style={styles.triangle} resizeMode="stretch" />
        {renderText && <Animated.View style={[styles.childrenContainer, { opacity: fadeAnim }]}>{children}</Animated.View>}
      </ImageBackground>
      <Image source={bottomImage} style={styles.bottom} resizeMode="stretch" />
    </Animated.View>
  );
};

export default Bubble;
