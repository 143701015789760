export class Avatar {
  id?: string;

  avatarName!: string;

  avatarImage!: string;

  constructor(properties?: AvatarProperties) {
    if (properties) {
      this.avatarName = properties.avatarName;
      this.avatarImage = properties.avatarImage;
    }
  }
}

export interface AvatarProperties {
  id?: string;
  avatarName: string;
  avatarImage: string;
}
