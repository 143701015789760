import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOnline: false
};

const isOnline = createSlice({
  initialState,
  name: 'isOnline',
  reducers: {
    showIsOnlineModal: state => {
      state.isOnline = false;
    },
    hideIsOnlineModal: state => {
      state.isOnline = true;
    }
  }
});

export const { reducer: isOnlineReducer } = isOnline;
export const { showIsOnlineModal, hideIsOnlineModal } = isOnline.actions;
