import { createSlice } from '@reduxjs/toolkit';

export interface VolumeConfig {
  general: number;
  music: number;
  dialogMusic: boolean;
  battleMusic: boolean;
}

export enum DifficultyLevel {
  EASY = 1,
  NORMAL = 3
}

export interface Config {
  volume: VolumeConfig;
  difficulty: DifficultyLevel;
}

const initialState: Config = {
  volume: {
    general: 1,
    music: 1,
    dialogMusic: true,
    battleMusic: true
  },
  difficulty: DifficultyLevel.NORMAL
};

const configSlice = createSlice({
  name: 'sound-volume',
  initialState,
  reducers: {
    setVolume(state, action: { payload: VolumeConfig; type: string }) {
      return { ...state, volume: action.payload };
    },
    setDifficulty(state, action: { payload: DifficultyLevel; type: string }) {
      return { ...state, difficulty: action.payload };
    }
  }
});

export const { setVolume, setDifficulty } = configSlice.actions;

export default configSlice;
