import React from 'react';
import { StyleSheet } from 'react-native';
import { useRatioDimensions } from '../../../../commons/components/AspectRatioView/useRatioDimensions';
import PolyShape from '../../../../commons/components/PolyShape/PolyShape';
import { View, Text } from '../../../../commons/components/Themed';
import { isGameActionHighlight } from '../../../../commons/events-management/game-actions/gameActions';

interface BoardActionPointsProps {
  actionPoints: number;
  maxActionPoints: number;
  isOpponent: boolean;
}

const BoardActionPoints = (props: BoardActionPointsProps) => {
  const { rw } = useRatioDimensions();
  const styles = StyleSheet.create({
    container: {
      width: rw(7),
      height: rw(3.5),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    actionPoints: {
      fontWeight: '900',
      zIndex: 2000,
      color: props.isOpponent ? '#0FD7E6' : '#FFBE08'
    },
    actionPointsDenominator: {
      fontWeight: '400',
      color: '#fff'
    },
    actionPointsHighlightOpponent: {
      borderWidth: 0,
      filter: 'drop-shadow(0px 10px 10px rgba(17, 215, 230, 1))'
    },
    actionPointsHighlightPlayer: {
      borderWidth: 0,
      filter: 'drop-shadow(0px 10px 10px rgba(233, 171, 4, 1))'
    }
  });
  return (
    <View
      style={[
        styles.container,
        props.isOpponent && isGameActionHighlight('opponent-action-points') ? styles.actionPointsHighlightOpponent : {},
        !props.isOpponent && isGameActionHighlight('player-action-points') ? styles.actionPointsHighlightPlayer : {}
      ]}
    >
      <PolyShape type={3} bgColor={'black'}>
        <Text style={[styles.actionPoints, { fontSize: rw(1.7) }]}>
          {props.actionPoints}
          <Text style={[styles.actionPointsDenominator, { fontSize: rw(1.5) }]}>/{props.maxActionPoints}</Text>
        </Text>
      </PolyShape>
    </View>
  );
};

export default BoardActionPoints;
