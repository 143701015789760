import LottieView from 'lottie-react-native';
import { useEffect, useRef, useState } from 'react';
import { Animated, Platform, StyleSheet, Easing } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import HandCard from '../../../commons/components/Card/CardTypes/HandCard';
import { CardSizesEnum } from '../../../commons/components/Card/utils';
import CardWithAccesory from '../../../commons/components/CardWithAccesory';
import { Card as CardType } from '../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { lottieUpgradeModal } from '../../../constants/Lotties';
import GameboardModal from './GameboardModal';

interface UpgradeCardModalProps {
  cardToUpgrade: CardType;
  cardUpgrade: CardType;
  enemyKey: string;
}

const UpgradeCardModal = ({ cardToUpgrade, cardUpgrade, enemyKey }: UpgradeCardModalProps) => {
  const { rw } = useRatioDimensions();
  const [showLottie, setShowLottie] = useState<boolean>(false);
  const translate = useRef<Animated.Value>(new Animated.Value(0)).current;
  const opacity = useRef<Animated.Value>(new Animated.Value(1)).current;

  const animation = Animated.timing(translate, {
    toValue: 1,
    useNativeDriver: Platform.OS !== 'web',
    duration: 350,
    easing: Easing.bezier(0.57, 0.07, 0.63, 0.89)
  });

  const opacityAnimation = Animated.timing(opacity, {
    toValue: 0,
    useNativeDriver: Platform.OS !== 'web',
    delay: 150,
    duration: 1500,
    easing: Easing.ease
  });

  useEffect(() => {
    animation.start(() => {
      setShowLottie(true);
      opacityAnimation.start();
    });
  }, []);

  const styles = StyleSheet.create({
    card: {
      width: rw(18),
      height: rw(25.2),
      marginVertical: '1%',
      marginHorizontal: '4%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  });

  return (
    <GameboardModal>
      <Animated.View
        key={`${cardToUpgrade.id}`}
        style={[
          styles.card,
          {
            transform: [
              {
                translateX: translate.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, rw(11.5)]
                })
              }
            ]
          }
        ]}
      >
        <CardWithAccesory card={cardToUpgrade} side={PlayerRoundEnum.OPPONENT} enemyKey={enemyKey} show animated />
      </Animated.View>
      <Animated.View
        key={`${cardUpgrade.id}`}
        style={[
          styles.card,
          {
            position: 'relative',
            opacity,
            transform: [
              {
                translateX: translate.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -rw(11.5)]
                })
              }
            ]
          }
        ]}
      >
        <HandCard card={cardUpgrade} cardSize={CardSizesEnum.VERY_BIG} />
        {showLottie ? (
          <LottieView
            autoPlay
            duration={500}
            source={lottieUpgradeModal}
            style={{
              zIndex: 999,
              position: 'absolute',
              width: '100%',
              height: '100%',
              elevation: 25,
              transform: [
                {
                  scale: 1.4
                }
              ]
            }}
          />
        ) : null}
      </Animated.View>
    </GameboardModal>
  );
};

export default UpgradeCardModal;
