import { Reaction } from './reaction.dto';

export enum ReactionEventEnum {
  //Eventos del jugador
  PLAYER_ROUND_START = 'jugador comienza turno',
  PLAYER_GET_CARD = 'jugador saca una carta',
  PLAYER_PLAY_CARD = 'jugador lanza una carta',
  PLAYER_DAMAGE_CARD = 'jugador hace daño con carta',
  PLAYER_DESTROY_CARD = 'destruir carta de oponente',
  PLAYER_AVATAR_DAMAGED = 'avatar de jugador dañado',
  PLAYER_CARD_DAMAGED = 'carta de jugador dañada',
  PLAYER_CARD_DESTROYED = 'carta de jugador destruida',
  PLAYER_WIN = 'jugador gana',
  //Eventos del oponente
  OPPONENT_ROUND_START = 'oponente comienza turno',
  OPPONENT_GET_CARD = 'oponente saca una carta',
  OPPONENT_PLAY_CARD = 'oponente lanza una carta',
  OPPONENT_DAMAGE_CARD = 'oponente hace daño con carta',
  OPPONENT_DESTROY_CARD = 'destruir carta de jugador',
  OPPONENT_AVATAR_DAMAGED = 'avatar de oponente dañado',
  OPPONENT_CARD_DAMAGED = 'carta de oponente dañada',
  OPPONENT_CARD_DESTROYED = 'carta de oponente destruida',
  OPPONENT_WIN = 'oponente gana'
}

export const playerEvents = [
  ReactionEventEnum.PLAYER_ROUND_START,
  ReactionEventEnum.PLAYER_GET_CARD,
  ReactionEventEnum.PLAYER_PLAY_CARD,
  ReactionEventEnum.PLAYER_DAMAGE_CARD,
  ReactionEventEnum.PLAYER_DESTROY_CARD,
  ReactionEventEnum.PLAYER_AVATAR_DAMAGED,
  ReactionEventEnum.PLAYER_CARD_DAMAGED,
  ReactionEventEnum.PLAYER_CARD_DESTROYED,
  ReactionEventEnum.PLAYER_WIN
];

export const opponentEvents = [
  ReactionEventEnum.OPPONENT_ROUND_START,
  ReactionEventEnum.OPPONENT_GET_CARD,
  ReactionEventEnum.OPPONENT_PLAY_CARD,
  ReactionEventEnum.OPPONENT_DAMAGE_CARD,
  ReactionEventEnum.OPPONENT_DESTROY_CARD,
  ReactionEventEnum.OPPONENT_AVATAR_DAMAGED,
  ReactionEventEnum.OPPONENT_CARD_DAMAGED,
  ReactionEventEnum.OPPONENT_CARD_DESTROYED,
  ReactionEventEnum.OPPONENT_WIN
];

export class ReactionConfiguration implements ReactionConfigurationProperties {
  reactions!: Reaction[];
  background?: string | undefined;
  trigger!: Trigger;
  constructor(properties?: ReactionConfigurationProperties) {
    if (properties) {
      this.reactions = properties.reactions;
      this.trigger = properties.trigger;
    }
  }
}

export interface Trigger {
  event: ReactionEventEnum;
  round?: number;
  onlyFirstTime?: boolean;
  extractCard?: boolean;
  lifePointsThreshold?: number;
  cardCode?: string;
  lifePoints?: number;
  done?: boolean;
}

export interface ReactionConfigurationProperties {
  reactions: Reaction[];
  round?: number;
  trigger?: any;
}

export interface ReactionsConfiguration {
  trigger: Trigger;
}
