import { reduxBatch } from '@manaflair/redux-batch';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import gameActionsMiddleware from './middleware/gameActions';
import { rootReducer, rootSaga } from './rootReducer';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blackList: ['timeAdvice']
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true
    })
      .concat(sagaMiddleware)
      .concat(gameActionsMiddleware),
  enhancers: [reduxBatch],
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
