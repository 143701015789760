import { ReactNode } from 'react';
import { View, ViewStyle } from 'react-native';
import { BASE_ASPECT_RATIO } from '../../../constants/Values';
import { useAspectRatio } from './useAspectRatio';

interface AspectRatioViewProps {
  aspectRatio?: number;
  style?: ViewStyle;
  children?: ReactNode;
}

const AspectRatioView = ({ aspectRatio = BASE_ASPECT_RATIO, style, children }: AspectRatioViewProps) => {
  const { viewWidth, viewHeight, onLayout } = useAspectRatio(aspectRatio);

  const viewStyle: ViewStyle = {
    ...style,
    position: 'absolute',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <View onLayout={onLayout} style={viewStyle}>
      <View style={{ width: viewWidth, height: viewHeight }}>{children}</View>
    </View>
  );
};

export default AspectRatioView;
