import { useSelector, useDispatch } from 'react-redux';
import { disableConsent, enableConsent } from '../redux/Consent/consent';

interface ConsentHandler {
  accept: () => void;
  reject: () => void;
  gotAnswer: boolean;
  gotConsent: boolean;
}

const useConsent = (): ConsentHandler => {
  const dispatch = useDispatch();
  const { consent } = useSelector((s: any) => s.consent);

  const gotAnswer: boolean = consent === true || consent === false;

  const setAnswer = (answer: boolean) => {
    dispatch(answer ? enableConsent() : disableConsent());
    if (gotAnswer && consent && !answer) {
      // Check if window can cause problems
      setTimeout(() => window.location.reload(), 0);
    }
  };

  return {
    accept: () => setAnswer(true),
    reject: () => setAnswer(false),
    gotAnswer,
    gotConsent: consent
  };
};

export default useConsent;
