import * as Crypto from 'expo-crypto';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import {
  scienceSubjectIconBlack,
  scienceSubjectIconWhite,
  conspiracySubjectIconBlack,
  conspiracySubjectIconWhite,
  hateSubjectIconBlack,
  hateSubjectIconWhite,
  scamSubjectIconBlack,
  scamSubjectIconWhite
} from '../constants/Images';
import { Deck } from '../screens/GameBoard/gameTypes';
import { CardInventoryFilter } from './dtos/card-inventory-filter.dto';
import { CardInventoryIncludesCard } from './dtos/card-inventory-includes-card.dto';
import { CardQuantity } from './dtos/card-quantity.dto';
import { Card } from './dtos/card.dto';
import { GendersEnum, PlayerRoundEnum } from './dtos/player.dto';
import { SubjectsEnum } from './dtos/subject.dto';
import { TagsEnum } from './dtos/tag.dto';
import { TitleProperties } from './dtos/title.dto';
import { deviceHeight, deviceWidth } from './styles/TextAdjust';

let copiesCount = 0;
export const isLandScape = deviceHeight < deviceWidth;
export const isPortrait = deviceHeight > deviceWidth;
export const isNativeMobile = Platform.OS === 'android' || Platform.OS === 'ios';
export const isPWA = (isLandScape && deviceHeight < 541 && deviceWidth < 961) || (isPortrait && deviceWidth < 541 && deviceHeight < 961);
export const smallMobile = deviceHeight < 361;
export const isNativeOrPWA = isNativeMobile || isPWA;
export const isMobile = deviceHeight <= 735 || deviceWidth <= 735 || isNativeMobile;
export const isSafari = (Device.modelName === 'iPhone' || Device.modelName === 'Macintosh') && Platform.OS === 'web';
export const onlyDesktop = Platform.OS === 'web' && ((isLandScape && deviceWidth > 1024) || (isPortrait && deviceHeight < 1024));

export const getRandomUUID = () => {
  return Crypto.randomUUID();
};

export const onFilterCardInventory = (
  fullCardInventory: CardInventoryIncludesCard[] | CardQuantity[],
  cardInventoryFilters: CardInventoryFilter
) => {
  if (!cardInventoryFilters) return;
  const filteredCardInventory = fullCardInventory.filter(card => {
    if (cardInventoryFilters.nameFilter && !card.card.cardName.toLowerCase().includes(cardInventoryFilters.nameFilter.toLowerCase())) {
      return false;
    }
    if (
      cardInventoryFilters.subjectFilter &&
      (!card.card.subject || card.card.subject.subjectName !== cardInventoryFilters.subjectFilter)
    ) {
      return false;
    }
    if (
      cardInventoryFilters.actionPointsFilter &&
      ((cardInventoryFilters.actionPointsFilter === 7 && card.card.actionPoints < 7) ||
        card.card.actionPoints != cardInventoryFilters.actionPointsFilter)
    ) {
      return false;
    }
    if (cardInventoryFilters.rarityFilter && (!card.card.rarity || card.card.rarity.rarityName !== cardInventoryFilters.rarityFilter)) {
      return false;
    }
    return true;
  });
  return filteredCardInventory;
};

export const getSubjectIcon = (card: Card) => {
  if (card.subject?.subjectName === SubjectsEnum.SCIENCE) {
    if (card.color) {
      return scienceSubjectIconBlack;
    }
    return scienceSubjectIconWhite;
  }
  if (card.subject?.subjectName === SubjectsEnum.CONSPIRACY) {
    if (card.color) {
      return conspiracySubjectIconBlack;
    }
    return conspiracySubjectIconWhite;
  }
  if (card.subject?.subjectName === SubjectsEnum.HATE) {
    if (card.color) {
      return hateSubjectIconBlack;
    }
    return hateSubjectIconWhite;
  }
  if (card.subject?.subjectName === SubjectsEnum.SCAM) {
    if (card.color) {
      return scamSubjectIconBlack;
    }
    return scamSubjectIconWhite;
  }
};

export const moveElement = (array: any[], oldIndex: number, newIndex: number) => {
  const element = array.splice(oldIndex, 1);
  array.splice(newIndex, 0, element[0]);
  return array;
};

export const downloadFile = (data: BlobPart, fileName: string, fileType: string) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const cardsAlphabeticalOrder = (card1: CardQuantity, card2: CardQuantity) => {
  const text1 = card1.card.cardName.toLowerCase();
  const text2 = card2.card.cardName.toLowerCase();
  return text1 < text2 ? -1 : text1 > text2 ? 1 : 0;
};

export const cardsCodeOrder = (card1: Card, card2: Card) => {
  const text1 = parseFloat(card1.cardCode);
  const text2 = parseFloat(card2.cardCode);
  return text1 < text2 ? -1 : text1 > text2 ? 1 : 0;
};

export const shuffle = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const wait = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export function getUniqueRandom(min: number, max: number, restItems: number[]): number {
  const random = randomIntFromInterval(min, max);
  if (!restItems.includes(random)) {
    restItems.push(random);
    return random;
  } else {
    return getUniqueRandom(min, max, restItems);
  }
}

export const processDuplicatedCards = (deck: Deck, player: PlayerRoundEnum): Deck => {
  const updatedDeck: Deck = deck.map(card => {
    const isUndetectable = card?.tags?.some(tag => tag.tagName === TagsEnum.UNDETECTABLE);
    if (isUndetectable) {
      card.isUndetectable = isUndetectable;
    }
    let duplicates = deck.slice(0).filter(sliceCard => sliceCard.id === card.id).length;
    if (duplicates > 0) {
      const UUID = getRandomUUID();
      let updatedId = `${card.id}/${player}-${UUID}/${duplicates}`;
      if (card.id.includes(`/${player}/`)) {
        const copyCardId = { ...card }.id.split('/');
        const lastDuplicated = copyCardId.pop();
        if (lastDuplicated) {
          duplicates = parseInt(lastDuplicated) + 1;
          updatedId = `${copyCardId.join('/')}-${UUID}/${duplicates}`;
        }
      }
      card.rules = card.rules
        ? card.rules?.map(rule => {
            if (rule.id === card.id) {
              rule.id = updatedId;
            }
            return rule;
          })
        : [];
      card.id = updatedId;
      card.idUnique = updatedId;
    }
    duplicates = duplicates - 1;
    return card;
  });

  return updatedDeck;
};

export const getDeckCards = (deck: CardQuantity[], random = false) => {
  const deckCards: Card[] = deck.reduce((accum, current) => {
    while (current.quantity) {
      accum.push(current.card);
      current.quantity--;
    }
    return accum;
  }, [] as Card[]);
  return random ? shuffle(deckCards) : deckCards;
};

export const getCopyCount = () => {
  copiesCount += 1;
  return copiesCount;
};

export const cardHasTag = (card: Card, tag: TagsEnum) => {
  return card?.tags?.some(cardTag => cardTag.tagName === tag);
};

export const chooseTitle = (gender: GendersEnum, title: TitleProperties | null | undefined): string => {
  if (!title) return '';
  const { femaleText, maleText } = title;
  return gender === GendersEnum.FEMALE ? femaleText : maleText;
};

export const replaceElementFromArray = <T extends { id: string }>(collection: T[], card: T) => {
  const targetIndex = collection.findIndex(cardCollection => cardCollection.id === card.id);
  const collectionCopy = [...collection];
  collectionCopy.splice(targetIndex, 1, card);
  return collectionCopy;
};

export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

export function findAndUpdate<T extends { id: string }>(items: T[], item: T, newItem: T) {
  const copyItems = [...items];
  const itemIndex = copyItems.findIndex(({ id }) => id === item.id);
  if (itemIndex >= 0) copyItems[itemIndex] = newItem;

  return copyItems;
}

export function divideArrayIntoChunks(array: any[], chunkSize: number): any[][] {
  const chunks: any[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}
