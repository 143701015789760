export const credits = [
  {
    sectionName: 'Desarrollo',
    positions: [
      {
        name: '<b>Pablo Álvarez Graña</b>',
        job: 'Tech Lead'
      },
      {
        name: '<b>Alejandro Bernárdez Ferrín</b>',
        job: 'Frontend'
      },
      {
        name: '<b>Abraham Carrera Groba</b>',
        job: 'Backend'
      },
      {
        name: '<b>Albert Palau Matillas</b>',
        job: 'Backend'
      },
      {
        name: '<b>Alejandro Sánchez Melero</b>',
        job: 'Frontend'
      }
    ]
  },
  {
    sectionName: 'Diseño y Artes Gráficas',
    positions: [
      {
        name: '<b>Daniel Belchí Lorente</b>',
        job: 'Ilustraciones de Escenarios y Mazos'
      },
      {
        name: '<b>Diana Díaz Reinoso</b>',
        job: 'UX y Arte Conceptual'
      },
      {
        name: '<b>Sandra Samaniego Moreno</b>',
        job: 'UX y Arte Conceptual'
      }
    ]
  },
  {
    sectionName: 'Información Educativa',
    positions: [
      {
        name: '<b>Tomás Rudich</b>',
        job: 'Coordinación y Revisión contenidos'
      },
      {
        name: '<b>Guillermo Infantes</b>',
        job: 'Contenido educativo'
      }
    ]
  },
  {
    sectionName: 'Producto',
    positions: [
      {
        name: '<b>Rubén Míguez Pérez</b>',
        job: 'Ideación y Dinámicas de juego'
      },
      {
        name: '<b>Joaquín Ortega Casariego</b>',
        job: 'Ideación y Revisión Editorial'
      },
      {
        name: '<b>Alejandro Olvera Madueño</b>',
        job: 'Guión, Historia y Escenarios'
      }
    ]
  }
];
