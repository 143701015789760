import { useEffect, useState } from 'react';
import { Animated, ImageBackground, Platform, StyleSheet, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';
import { switchBar, thumbIcon, thumbIcon2 } from '../../../constants/Images';
import { isNativeOrPWA } from '../../utils';

interface SwitchProps {
  containerStyle: ViewStyle;
  containerThumbStyle: ViewStyle;
  thumbStyle: ViewStyle;
  handleChange: (value: boolean) => void;
  initalValue?: boolean;
}

const Switch = ({ containerStyle, containerThumbStyle, thumbStyle, handleChange, initalValue = false }: SwitchProps) => {
  const [value, setValue] = useState<boolean>(initalValue);

  const [positionThumb] = useState<Animated.Value>(new Animated.Value(0));

  useEffect(() => {
    const animationOff = Animated.timing(positionThumb, { toValue: 0, useNativeDriver: Platform.OS !== 'web', duration: 300 });
    const animationOn = Animated.timing(positionThumb, { toValue: 1, useNativeDriver: Platform.OS !== 'web', duration: 300 });
    !value ? animationOff.start() : animationOn.start();
  }, [value]);

  const handleSwitch = () => {
    setValue(prevState => !prevState);
    handleChange(value);
  };

  const styles = StyleSheet.create({
    imageBar: {
      width: '100%',
      height: '100%',
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center'
    }
  });

  return (
    <TouchableWithoutFeedback onPress={() => handleSwitch()}>
      <View style={containerStyle}>
        <ImageBackground style={styles.imageBar} source={switchBar} resizeMode="stretch">
          <Animated.View
            style={[
              containerThumbStyle,
              {
                transform: [
                  {
                    translateX: positionThumb.interpolate({
                      inputRange: [0, 1],
                      outputRange: [isNativeOrPWA ? -4 : -6, isNativeOrPWA ? 22 : 24]
                    })
                  }
                ]
              }
            ]}
          >
            <ImageBackground source={value ? thumbIcon : thumbIcon2} style={thumbStyle} resizeMode="stretch" />
          </Animated.View>
        </ImageBackground>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default Switch;
