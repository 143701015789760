import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text } from 'react-native';
import { vh } from 'rxn-units';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard, irregularBlock2 } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import GameBoardElements from '../GameBoardElements/GameBoardElements';
import { useStylesHow } from '../useStylesHow';

const HowSectionBoard = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={[styles.section, { justifyContent: 'flex-start' }]}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <GameBoardElements all />
      <ImageBackground style={[styles.block2, { marginTop: isNativeOrPWA ? vh(2) : vh(12) }]} source={irregularBlock2} resizeMode="stretch">
        <Text style={styles.title}>{howTitle['board-title']}</Text>
        <Text style={[styles.paragraph, { textAlign: 'center' }]}>
          <TextWithHTML text={howText['board-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
        </Text>
      </ImageBackground>
    </ImageBackground>
  );
};

export default HowSectionBoard;
