import { useEffect, useRef, useState } from 'react';
import { Text, StyleSheet, ViewStyle } from 'react-native';

interface AnimatedTypingProps {
  textTotal: string;
  textStyles?: ViewStyle;
  render: boolean;
  typing?: boolean;
  timing?: number;
}

/**
 * Represents a typing animation without cursor
 * @param textTotal: text to be animated
 * @param onComplete: function to execute when animation is finished
 */
const AnimatedTyping = ({ textTotal, textStyles, render, typing = true }: AnimatedTypingProps) => {
  // Text that will be showed
  const [renderText, setRenderText] = useState<string>('');
  // Index to retrieve characters
  const [renderTextIndex, setTextRenderIndex] = useState<number>(0);

  // Refs too keep states values on setInterval
  const renderTextRef = useRef(renderText);
  renderTextRef.current = renderText;

  const renderTextIndexRef = useRef(renderTextIndex);
  renderTextIndexRef.current = renderTextIndex;

  useEffect(() => {
    const timeout = setInterval(() => {
      if (renderTextIndexRef.current < textTotal.length) {
        setRenderText(renderTextRef.current + textTotal.charAt(renderTextIndexRef.current));
        setTextRenderIndex(renderTextIndexRef.current + 1);
      } else {
        clearInterval(timeout);
      }
    }, 70);
    return () => {
      //Restore defaults values
      setTextRenderIndex(0);
      setRenderText('');
      clearInterval(timeout);
    };
  }, [textTotal]);

  const styles = StyleSheet.create({
    text: {
      color: '#FFF',
      fontSize: 14
    }
  });
  return renderText.includes(textTotal) || !render || !typing ? (
    <Text style={textStyles || {}}>{textTotal}</Text>
  ) : (
    <Text style={[styles.text, textStyles || {}]}>{renderText}</Text>
  );
};

export default AnimatedTyping;
