import { ImageBackground, Image, StyleSheet } from 'react-native';
import { rarityBlackContainer, rarityWhiteContainer } from '../../../constants/Images';
import { Card } from '../../dtos/card.dto';
import { WhenEnum } from '../../dtos/rule.dto';
import { getActionsIcon } from './utils';

interface ActionsSkillsProps {
  card: Card;
}

const Actions = ({ card }: ActionsSkillsProps) => {
  const styles = StyleSheet.create({
    actionCardContainerBackground: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '47%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    actionIcon: { width: '80%', height: '80%' }
  });

  const actionWin = card.rules?.some(rule => rule.applyWhen === WhenEnum.WIN);
  const actionLose = card.rules?.some(rule => rule.applyWhen === WhenEnum.LOSE);

  return actionWin || actionLose ? (
    <ImageBackground style={styles.actionCardContainerBackground} source={card.color ? rarityBlackContainer : rarityWhiteContainer}>
      <Image style={styles.actionIcon} source={getActionsIcon(actionWin ? WhenEnum.WIN : WhenEnum.LOSE)} />
    </ImageBackground>
  ) : null;
};

export default Actions;
