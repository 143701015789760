import { StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';

export const useLeagalPrivacyStyles = (isModal = false) => {
  const { vw } = useUnits();
  const styles = StyleSheet.create({
    mainContent: {
      width: '100%',
      maxWidth: isNativeOrPWA ? '100%' : 1360
    },
    center: {
      marginLeft: isNativeOrPWA ? 0 : 'auto',
      marginRight: isNativeOrPWA ? 0 : 'auto'
    },
    text: {
      color: '#FFF',
      fontSize: adjust(21),
      fontWeight: 'normal'
    },
    paragraph: {
      marginVertical: isNativeOrPWA ? 10 : 20
    },
    title: {
      fontSize: adjust(23),
      fontWeight: '700',
      textDecorationLine: 'underline'
    },
    title2: {
      fontSize: 22,
      fontWeight: '700'
    },
    textContainer: {
      flex: 1,
      paddingHorizontal: isModal ? 5 : 64
    },
    listMargin: {
      marginLeft: 48
    },
    listRow: {
      marginVertical: 4
    },
    url: {
      color: '#199EF3',
      textDecorationLine: 'underline',
      textDecorationColor: '#199EF3'
    },
    underline: {
      textDecorationLine: 'underline'
    },
    marginTop: {
      marginTop: 36
    },
    marginTitle: {
      marginTop: 20
    },
    marginLeft: {
      flexDirection: 'row',
      marginLeft: 16
    },
    boldText: {
      fontWeight: 'bold'
    },
    relative: {
      position: 'relative'
    },
    paddingEndText: {
      paddingBottom: isNativeOrPWA ? 90 : isModal ? 80 : 180
    },
    paddingStartText: {
      paddingTop: isNativeOrPWA || isModal ? 30 : 50
    },
    buttonColor: {
      fontSize: adjust(18),
      fontWeight: '700',
      color: '#fff'
    },
    button: {
      width: vw(25),
      maxWidth: 300,
      marginVertical: isNativeOrPWA ? 10 : 30
    }
  });
  return styles;
};
