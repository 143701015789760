import { useRef, useEffect } from 'react';
import { Animated, ImageBackground, Platform, Text, Image } from 'react-native';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { howSection1Bg, irregularBlock1, howBottom } from '../../../../constants/Images';
import TextWithHTML from '../../TextWithHTML';
import { useStylesHow } from '../useStylesHow';

const animationDown = {
  toValue: -10,
  duration: 1000,
  useNativeDriver: Platform.OS !== 'web'
};

const animationUp = {
  toValue: 0,
  duration: 1000,
  useNativeDriver: Platform.OS !== 'web'
};

const HowSectionIntro = () => {
  const translation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const sequence = Animated.sequence([Animated.timing(translation, animationDown), Animated.timing(translation, animationUp)]);
    const loop = Animated.loop(sequence);
    loop.start();
  }, []);

  const { styles, htmlStyles, introStyle } = useStylesHow();

  return (
    <ImageBackground source={howSection1Bg} resizeMode="cover" style={styles.section}>
      <ImageBackground style={[styles.block, styles.blockCenter]} source={irregularBlock1} resizeMode="stretch">
        <Text style={styles.extraTitle}>{howTitle['intro-title']}</Text>
        <Text style={styles.introParagraph}>
          <TextWithHTML text={howText['intro-text-1']} generalTextStyle={introStyle} htmlStyles={htmlStyles} />
          <Text style={styles.introText}></Text>
        </Text>
        <Text style={styles.paragraph}>
          <TextWithHTML text={howText['intro-text-2']} generalTextStyle={introStyle} htmlStyles={htmlStyles} />
        </Text>
      </ImageBackground>
      <Animated.View pointerEvents="none" style={{ transform: [{ translateY: translation }] }}>
        <Image style={styles.icon} resizeMode="contain" source={howBottom} accessible accessibilityLabel="bottom icon" />
      </Animated.View>
    </ImageBackground>
  );
};

export default HowSectionIntro;
