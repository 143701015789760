import { StyleSheet, ImageBackground, Text, LayoutChangeEvent } from 'react-native';
import { activeTitleBg, winLoseAvatarBackground } from '../../../constants/Images';
import { RankingPlayer } from '../../constants/ranking';
import { isNativeOrPWA } from '../../utils';
import Avatar from '../Avatar';
import { getAvatar } from '../Dialogues/utils';
import { View } from '../Themed';

interface RankingElementProps {
  player: RankingPlayer;
  selected?: boolean;
  onLayout: (event: LayoutChangeEvent) => void;
}

const RankingElement = ({ player, selected = false, onLayout }: RankingElementProps) => {
  let orderColor = 'white';
  if (+player.order === 1) {
    orderColor = '#02EDFF';
  } else if (+player.order === 2) {
    orderColor = '#FF7901';
  } else if (+player.order === 3) {
    orderColor = '#EFD804';
  }

  const styles = StyleSheet.create({
    container: {
      padding: isNativeOrPWA ? 4 : 6,
      marginVertical: 2,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    text: {
      fontSize: isNativeOrPWA ? 12 : 18,
      textAlign: 'center',
      fontWeight: '600',
      color: 'white'
    },
    avatar: {
      width: isNativeOrPWA ? 30 : 60,
      height: isNativeOrPWA ? 30 : 60,
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: 25,
      position: 'relative'
    },
    normal: {
      flexBasis: 'auto',
      flexGrow: 0,
      flexShrink: 0,
      paddingHorizontal: isNativeOrPWA ? 8 : 14
    },
    expanded: {
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
      textAlign: 'left',
      paddingHorizontal: isNativeOrPWA ? 8 : 14
    },
    experience: {
      fontSize: isNativeOrPWA ? 13 : 18,
      textAlign: 'center',
      fontWeight: '600',
      color: orderColor
    }
  });

  const content = (
    <>
      <Text style={[styles.text, styles.normal]}>{player.order}.</Text>
      <Avatar
        avatar={getAvatar(player!.avatar)}
        avatarStyle={[
          {
            bottom: isNativeOrPWA ? 3 : 5,
            width: '100%',
            height: isNativeOrPWA ? 26 : 50,
            position: 'absolute'
          },
          styles.normal
        ]}
        background={winLoseAvatarBackground}
        bgStyle={styles.avatar}
      />
      <Text style={[styles.text, styles.expanded]}>{player.playerName}</Text>
      <Text style={[styles.experience, styles.normal]}>{player.experience}XP</Text>
    </>
  );

  if (selected) {
    return (
      <View onLayout={onLayout}>
        <ImageBackground source={activeTitleBg} style={styles.container} resizeMode="stretch">
          {content}
        </ImageBackground>
      </View>
    );
  }
  return (
    <View onLayout={onLayout} style={styles.container}>
      {content}
    </View>
  );
};

export default RankingElement;
