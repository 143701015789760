import axios from 'axios';
import { CreateLogDto, EventLogs } from '../commons/dtos/create-log.dto';
import store from '../redux/store';

export default class LogsService {
  createLog = async (event: EventLogs, percentageCampaign?: number): Promise<void> => {
    try {
      const { auth } = store.getState();
      if (auth && auth.user) {
        const { user } = auth;
        const dateBorn = EventLogs.SIGNUP === event ? user.dateBorn : undefined;
        const createEvent: CreateLogDto = { event, type: user.type, userId: user.playerId!, percentageCampaign, dateBorn };
        await axios.post(`${process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL}/logs`, createEvent);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('No se pudo crear el log');
    }
  };
}
