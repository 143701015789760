import { StyleSheet, View, Image } from 'react-native';
import { howArrow } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';

interface ArrowProps {
  rotate: number;
}

const Arrow = ({ rotate = 0 }: ArrowProps) => {
  const styles = StyleSheet.create({
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      height: isNativeOrPWA ? 60 : 100,
      width: isNativeOrPWA ? 30 : 50,
      zIndex: 100,
      transform: [{ rotate: rotate + 'deg' }]
    },
    arrow: {
      height: '100%',
      width: '100%'
    }
  });

  return (
    <View style={styles.container}>
      <Image resizeMode="contain" source={howArrow} accessible accessibilityLabel="arrow image" style={styles.arrow} />
    </View>
  );
};

export default Arrow;
