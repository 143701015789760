import { Image, StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import { vw } from 'rxn-units';
import { GendersEnum } from '../../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { agenteFMaleAvatar, agenteFFemaleAvatar } from '../../../constants/Images';

interface LoginAvatarProps {
  setGender: Function;
  gender: GendersEnum | undefined;
}

const LoginAvatar = ({ setGender, gender }: LoginAvatarProps) => {
  const styles = StyleSheet.create({
    avatarContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    avatarCard: {
      marginTop: isNativeOrPWA ? 10 : 30,
      display: 'flex',
      flexDirection: 'column',
      marginHorizontal: isNativeOrPWA ? 16 : 26
    },
    avatarCardImage: {
      width: isNativeOrPWA ? vw(13) * 1.1 : vw(9) * 1.1,
      height: isNativeOrPWA ? vw(13) : vw(0),
      maxHeight: isNativeOrPWA ? 100 * 1.1 : 180 * 1.1,
      maxWidth: isNativeOrPWA ? 100 : 180,
      minWidth: isNativeOrPWA ? 0 : 146,
      minHeight: isNativeOrPWA ? 0 : 134
    },
    textDescription: {
      marginVertical: isNativeOrPWA ? 64 : 30,
      fontSize: 14,
      textAlign: 'center',
      display: isNativeOrPWA ? 'none' : 'flex',
      justifyContent: 'center',
      color: '#FFF',
      width: '90%',
      maxWidth: 440
    },
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  return (
    <View style={styles.container}>
      <View style={styles.avatarContainer}>
        <TouchableHighlight
          underlayColor="transparent"
          style={[styles.avatarCard, gender === GendersEnum.FEMALE && { opacity: 0.3 }]}
          onPress={() => setGender('male')}
        >
          <Image style={styles.avatarCardImage} source={agenteFMaleAvatar} resizeMode={'contain'} />
        </TouchableHighlight>

        <TouchableHighlight
          underlayColor="transparent"
          style={[styles.avatarCard, gender === GendersEnum.MALE && { opacity: 0.3 }]}
          onPress={() => setGender('female')}
        >
          <Image style={styles.avatarCardImage} source={agenteFFemaleAvatar} resizeMode={'contain'} />
        </TouchableHighlight>
      </View>

      <Text style={styles.textDescription}>
        Tu mayor talento es saber pasártelo bien. Un don que te vuelve insuperable organizando fiestas. Te encantan los gatos y los vídeos
        de gatitos en Internet. Eres una persona despreocupada, entusiasta, algo testaruda y muy habilidosa sacando de quicio a sus mayores.
        No te gusta la mentira, es como el maquillaje, puede cubrir defectos, pero siempre deja un rastro.
      </Text>
    </View>
  );
};

export default LoginAvatar;
