import Slider from '@react-native-community/slider';
import { useState } from 'react';
import { View, StyleSheet, Text, ImageBackground, Image, TouchableWithoutFeedback, Platform } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import { configBg, returnIcon } from '../../../constants/Images';
import { Config, DifficultyLevel, setDifficulty, setVolume } from '../../../redux/Configuration/configuration';
import store from '../../../redux/store';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';
import FullScreenWebButton from '../FullScreenWebButton/FullScreenWebButton';
import Switch from '../OptionsMenuModal/Switch';
import ConfigItem from './ConfigItem';

interface ConfigMusicVideoProps {
  handleShowedComponent: Function;
  fromGame?: boolean;
}

const ConfigMusicDifficulty = ({ handleShowedComponent, fromGame = false }: ConfigMusicVideoProps) => {
  const { vw } = useUnits();
  const config = useSelector(({ configuration }: { configuration: Config }) => configuration);
  const [configStore, setConfigStore] = useState<Config>(config);
  const { volume, difficulty } = configStore;
  const { general, dialogMusic, music, battleMusic } = volume;

  const styles = StyleSheet.create({
    optionTitle: {
      fontSize: adjust(28),
      fontWeight: '600',
      color: '#FFF',
      marginBottom: isNativeOrPWA ? 18 : 32
    },
    container: {
      width: '100%',
      maxHeight: '100%',
      flex: 1
    },
    topContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      flex: 0,
      flexBasis: 'auto',
      paddingTop: 14,
      paddingBottom: 14
    },
    optionsContainer: {
      flexDirection: 'row',
      paddingTop: isNativeOrPWA ? 6 : 32,
      paddingBottom: isNativeOrPWA ? 6 : 32,
      flex: 1,
      flexBasis: 'auto',
      minHeight: isNativeOrPWA ? '40%' : '70%',
      justifyContent: 'center'
    },
    bottomContainer: {
      flex: 0,
      flexBasis: 'auto',
      paddingVertical: 12
    },
    secondColumn: {
      justifyContent: 'center',
      marginLeft: 16,
      flexGrow: 1,
      maxWidth: vw(isNativeOrPWA ? 50 : 40)
    },
    exitContainer: {
      width: 130,
      height: isNativeOrPWA ? 35 : 45,
      position: 'absolute',
      left: 0,
      top: isNativeOrPWA ? 10 : -20
    },
    returnIcon: {
      width: 14,
      height: 14,
      marginBottom: 6
    },
    returnText: {
      fontWeight: '700',
      color: '#FFF',
      fontSize: adjust(16),
      marginLeft: 16,
      marginBottom: 6
    },
    title: {
      fontSize: adjust(22),
      fontWeight: '600',
      color: '#FFF',
      display: 'flex',
      marginHorizontal: 'auto'
    },
    bgConfigs: {
      paddingHorizontal: isNativeOrPWA ? 30 : 40,
      paddingVertical: 24,
      flex: 1
    },
    saveView: {
      width: 200,
      marginLeft: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    },
    saveText: { color: '#FFF', fontSize: adjust(19), fontWeight: '500', paddingBottom: 5 },
    sliderContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      flex: 1
    },
    switchContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flex: 1,
      marginRight: isNativeOrPWA ? '25%' : 0
    },
    slider: {
      height: 20,
      maxWidth: Platform.OS === 'ios' ? '75%' : isNativeOrPWA ? '80%' : fromGame ? '50%' : '80%',
      transform: [{ scale: isNativeOrPWA ? 0.65 : 1 }],
      flex: 1
    },
    androidSlider: {
      transform: [{ scale: 1 }]
    },
    buttonInner: {
      paddingHorizontal: 30,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    containerStyle: {
      width: isNativeOrPWA ? 48 : 50,
      height: isNativeOrPWA ? 20 : 24
    },
    containerThumbStyle: {
      width: isNativeOrPWA ? 28 : 32,
      height: isNativeOrPWA ? 22 : 24
    },
    thumbStyle: {
      width: isNativeOrPWA ? 28 : 32,
      height: isNativeOrPWA ? 22 : 24,
      transform: [
        {
          scale: 1
        }
      ]
    },
    firstColumn: {
      flexBasis: '40%'
    },
    difficultySubtitle: {
      fontWeight: '500',
      fontSize: 16,
      color: '#FFF',
      marginBottom: 16
    },
    optionsRadioContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%'
    },
    optionItem: { flexDirection: 'row', alignItems: 'center' },
    optionItemText: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold'
    }
  });

  const handleBackButton = () => handleShowedComponent(0);
  const handleSaveButton = () => {
    store.dispatch(setVolume(volume));
    store.dispatch(setDifficulty(difficulty));
    handleShowedComponent(0);
  };
  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <TouchableWithoutFeedback onPress={handleBackButton}>
          <View style={styles.exitContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 46}>
              <View style={styles.buttonInner}>
                <Image source={returnIcon} style={styles.returnIcon} resizeMode="contain" />
                <Text style={styles.returnText}>Volver</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
        <Text style={styles.title}>Configuración</Text>
      </View>

      <View style={styles.optionsContainer}>
        {!fromGame && (
          <View style={styles.firstColumn}>
            <ImageBackground style={styles.bgConfigs} source={configBg} resizeMode="stretch">
              <Text style={styles.optionTitle}>Juego</Text>
              <Text style={styles.difficultySubtitle}>Dificultad</Text>
              <RadioButton.Group
                onValueChange={value =>
                  setConfigStore({ ...configStore, difficulty: value === 'Fácil' ? DifficultyLevel.EASY : DifficultyLevel.NORMAL })
                }
                value={configStore.difficulty === DifficultyLevel.EASY ? 'Fácil' : 'Normal'}
              >
                <View style={styles.optionsRadioContainer}>
                  <View style={[styles.optionItem, { marginRight: isNativeOrPWA ? 12 : 24 }]}>
                    <RadioButton value="Fácil" color="#17FFE3" uncheckedColor="white" />
                    <Text style={styles.optionItemText}>Fácil</Text>
                  </View>
                  <View style={styles.optionItem}>
                    <RadioButton value="Normal" color="#17FFE3" uncheckedColor="white" />
                    <Text style={styles.optionItemText}>Normal</Text>
                  </View>
                </View>
              </RadioButton.Group>
              {!isNativeOrPWA && (
                <>
                  <Text style={[styles.difficultySubtitle, { marginTop: 40 }]}>Pantalla completa</Text>
                  <FullScreenWebButton />
                </>
              )}
            </ImageBackground>
          </View>
        )}

        <View style={styles.secondColumn}>
          <ImageBackground style={styles.bgConfigs} source={configBg} resizeMode="stretch">
            <Text style={styles.optionTitle}>Audio</Text>
            <ConfigItem label="Volumen general" parentStyle={styles.sliderContainer}>
              <Slider
                style={[styles.slider, Platform.OS === 'android' && styles.androidSlider]}
                maximumValue={1}
                minimumValue={0}
                minimumTrackTintColor="#17FFE3"
                maximumTrackTintColor="#1D1D1D"
                thumbTintColor="#17FFE3"
                value={general}
                onValueChange={value => setConfigStore({ ...configStore, volume: { ...volume, general: +value.toFixed(1) } })}
              />
            </ConfigItem>
            <ConfigItem label="Música en diálogos" parentStyle={styles.switchContainer}>
              <Switch
                containerStyle={styles.containerStyle}
                containerThumbStyle={styles.containerThumbStyle}
                thumbStyle={styles.thumbStyle}
                handleChange={value => setConfigStore({ ...configStore, volume: { ...volume, dialogMusic: !value } })}
                initalValue={dialogMusic}
              />
            </ConfigItem>
            <ConfigItem label="Música en batalla" parentStyle={styles.switchContainer}>
              <Switch
                containerStyle={styles.containerStyle}
                containerThumbStyle={styles.containerThumbStyle}
                thumbStyle={styles.thumbStyle}
                handleChange={value => setConfigStore({ ...configStore, volume: { ...volume, battleMusic: !value } })}
                initalValue={battleMusic}
              />
            </ConfigItem>
            <ConfigItem label="Volumen de la música" parentStyle={styles.sliderContainer}>
              <Slider
                style={[styles.slider, Platform.OS === 'android' && styles.androidSlider]}
                maximumValue={1}
                minimumValue={0}
                minimumTrackTintColor="#17FFE3"
                maximumTrackTintColor="#1D1D1D"
                thumbTintColor="#17FFE3"
                value={music}
                onValueChange={value => setConfigStore({ ...configStore, volume: { ...volume, music: +value.toFixed(1) } })}
              />
            </ConfigItem>
          </ImageBackground>
        </View>
      </View>
      <View style={styles.bottomContainer}>
        <TouchableWithoutFeedback onPress={handleSaveButton}>
          <View style={styles.saveView}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 40 : 50}>
              <View style={styles.buttonInner}>
                <Text style={styles.saveText}>Guardar</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
};

export default ConfigMusicDifficulty;
