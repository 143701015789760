import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { View, Text, Pressable } from 'react-native';

import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import GradientContent from '../../commons/components/GradientContent/GradientContent';
import { useLeagalPrivacyStyles } from '../../commons/components/LeagalPrivacy/useLeagalPrivacyStyles';

import { isNativeOrPWA } from '../../commons/utils';
import { RootStackParamList } from '../../types';

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Legal', undefined>;

const LegalText = () => {
  const navigation = useNavigation<Navigation>();
  const styles = useLeagalPrivacyStyles();
  const colorsScreen = ['#313131', 'transparent', 'transparent', '#313131'];

  const openPrivacyURL = async () => {
    navigation.navigate('PrivacyPolicy');
  };
  const openCookiesURL = async () => {
    navigation.navigate('Cookies');
  };

  return (
    <GradientContent
      colors={colorsScreen}
      parentStyle={[styles.mainContent, styles.relative, styles.center]}
      scrollViewStyle={styles.textContainer}
      locations={[0, 0.2, 0.8, 1]}
    >
      <>
        <View style={[styles.mainContent, styles.paddingStartText]}>
          <View style={styles.button} pointerEvents="none">
            <ButtonShape bgColor="black" heightBtn={isNativeOrPWA ? 34 : 50}>
              <Text style={styles.buttonColor}>Aviso Legal</Text>
            </ButtonShape>
          </View>
        </View>
        <View>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>El acceso, navegación y utilización del Sitio Web www.fakesover.com (en adelante, el “</Text>
            <Text style={styles.boldText}>Sitio Web</Text>
            <Text>
              ”) implica el conocimiento y la aceptación expresa de todos los términos contenidos en este Aviso Legal, teniendo la misma
              validez y eficacia que cualquier contrato celebrado por escrito y firmado.
            </Text>
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            Su observancia y cumplimiento será exigible respecto de cualquier persona que acceda, navegue o utilice el Sitio Web. Si Ud. no
            está de acuerdo con los términos expuestos, no acceda, navegue o utilice ninguna página del Sitio Web.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            Al usar la Plataforma Ud. se compromete, por tanto, a utilizar los servicios y contenidos de conformidad con la legislación
            vigente y con el presente Aviso Legal, y reconoce y acepta que el uso de los contenidos y/o servicios ofrecidos por su titular
            se realizará bajo su exclusivo riesgo y/o responsabilidad.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>1. DATOS DE LA COMPAÑÍA</Text>
          <View style={styles.listMargin}>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Titular: </Text>
              <Text>Newtral Media Audiovisual, S. L. U. (en adelante “</Text>
              <Text style={styles.boldText}>Newtral</Text>
              <Text>”)</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Domicilio social: </Text>
              <Text> Calle Vandergoten 1, 28014 – Madrid.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} NIF: </Text>
              <Text> B-87938304.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Teléfono: </Text>
              <Text> 910888600 </Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} E-mail: </Text>
              <Text style={styles.url}>info@newtral.es </Text>
            </Text>
          </View>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>2. OBJETO Y ÁMBITO DE APLICACIÓN</Text>
          <Text style={[styles.paragraph, styles.text]}>
            El presente Aviso Legal regula el acceso, navegación y utilización del Sitio Web, sin perjuicio de que el titular se reserve el
            derecho a modificar la presentación, configuración y contenido del Sitio Web, así como las condiciones requeridas para su acceso
            y/o utilización. El acceso y utilización de los contenidos del Sitio Web, tras la entrada en vigor de sus modificaciones o
            cambios, suponen la aceptación de los mismos.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            No obstante, el acceso a determinados contenidos y la utilización de determinados servicios puede encontrarse sometido a
            determinadas condiciones particulares, que serán, en todo caso, claramente mostradas y deberán ser aceptadas expresamente por
            parte de los usuarios. Estas condiciones particulares, podrán sustituir, completar o, en su caso, modificar el contenido del
            presente Aviso Legal.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            El titular se reserva el derecho a modificar los términos y condiciones aquí estipuladas, total o parcialmente, publicando
            cualquier cambio en la misma forma en que aparece este Aviso Legal, a través de cualquier tipo de comunicación dirigida a los
            usuarios.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>3. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</Text>
          <Text style={[styles.paragraph, styles.text]}>
            NEWTRAL es titular o, en su caso, cuenta con las licencias correspondientes sobre los derechos de explotación de propiedad
            intelectual e industrial del Sitio Web, así como de los derechos de propiedad intelectual e industrial sobre la información,
            materiales y contenidos de la misma, incluidos, entre otros, código fuente, diseños, estructura de navegación, imágenes,
            gráficos, bases de datos, música, sonido, fotografías, vídeos y textos escritos en cualquier lengua, incluyendo, asimismo,
            códigos de programación.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            En ningún caso se entenderá que el acceso, navegación y utilización del Sitio Web por parte del usuario implica una renuncia,
            transmisión, licencia o cesión total o parcial de dichos derechos por parte de NEWTRAL. El usuario dispone de un derecho de uso
            de los contenidos y/o servicios del Sitio Web dentro de un ámbito estrictamente doméstico. En ningún momento, salvo
            manifestación expresa en contrario, el acceso, navegación o utilización del Sitio Web y/o de sus contenidos, confiere al usuario
            derecho alguno sobre su contenido.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            NEWTRAL se reserva todos los derechos de propiedad intelectual e industrial sobre los contenidos y/o servicios del Sitio Web y,
            en particular, queda prohibido modificar, copiar, reproducir, comunicar públicamente, transformar o distribuir, por cualquier
            medio y bajo cualquier forma, la totalidad o parte de los contenidos incluidos en el Sitio Web, para propósitos públicos o
            comerciales si no se cuenta con la autorización previa, expresa y por escrito de NEWTRAL o, en su caso, del titular de los
            derechos correspondientes.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            Queda expresamente prohibida la utilización, sin previo consentimiento, de cualquier elemento del Sitio Web que sea objeto de
            protección de acuerdo con la legislación vigente relativa a propiedad intelectual o industrial. En particular, no podrán
            utilizarse marcas, nombres comerciales, rótulos de establecimientos, obras, denominaciones, logotipos, eslóganes o cualquier
            tipo de signo distintivo perteneciente a NEWTRAL.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            En el caso de que el usuario envíe información de cualquier tipo a NEWTRAL a través cualquiera de los canales habilitados al
            efecto, el usuario declara, garantiza y acepta que tiene derecho a hacerlo libremente, que dicha información no infringe ningún
            derecho de propiedad intelectual, industrial, secreto comercial o cualesquiera otros derechos de terceros, y que dicha
            información no tiene carácter confidencial ni es perjudicial para terceros.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            La utilización no autorizada de la información y/o materiales contenidos en el Sitio Web, así como cualquier tipo de lesión de
            los derechos de propiedad intelectual o industrial de NEWTRAL, dará lugar a las responsabilidades legalmente establecidas. En
            este sentido, el usuario mantendrá indemne a NEWTRAL por cualquier perjuicio derivado del incumplimiento de lo contenido en el
            presente Aviso Legal.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>4. ENLACES</Text>
          <Text style={[styles.paragraph, styles.text, styles.title2]}>4.1. Enlaces desde otras páginas web al Sitio Web</Text>
          <Text style={[styles.paragraph, styles.text]}>
            NEWTRAL no autoriza el establecimiento de un enlace al presente Sitio Web desde otras páginas web que contengan materiales,
            información o contenidos ilícitos, ilegales, degradantes, obscenos y, en general, que contravengan las leyes, la moral o al
            orden público, o las normas sociales generalmente aceptadas.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            En todo caso, los usuarios podrán establecer enlaces en sus respectivas páginas web hacia el Sitio web siempre y cuando
            soliciten la autorización previa y expresa a NEWTRAL.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            NEWTRAL no tiene facultad ni medios humanos y técnicos para conocer, controlar ni aprobar toda la información, contenidos,
            productos o servicios facilitados por otros sitios web que tengan establecidos enlaces con destino al presente Sitio Web.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            NEWTRAL no asume ningún tipo de responsabilidad por cualquier aspecto relativo a las páginas web que establecen ese enlace con
            destino al presente Sitio Web, en concreto, a título enunciativo y no limitativo, sobre su funcionamiento, acceso, datos,
            información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos,
            en general. En cualquier caso, NEWTRAL se reserva el derecho a prohibir los enlaces al Sitio Web y a exigir su retirada cuando
            estos no cumplan las condiciones exigidas en este apartado.
          </Text>

          <Text style={[styles.paragraph, styles.text, styles.title2]}>4.2. Enlaces desde la Plataforma a otros sitios web</Text>
          <Text style={[styles.paragraph, styles.text]}>
            En el caso de que en el Sitio Web se mostraran enlaces a sitios web ajenos a NEWTRAL por medio de diferentes enlaces, botones,
            banners o contenidos embebidos, NEWTRAL informa de que estos se encuentran directamente gestionados por los titulares de las
            respectivas páginas web, no teniendo NEWTRAL medios humanos ni técnicos para conocer de forma previa y/o controlar y/o aprobar
            toda la información, contenidos, productos o servicios facilitados por otros sitios web a los que se puedan establecer enlaces
            desde el Sitio Web, por lo que NEWTRAL no asume ningún tipo de responsabilidad por cualquier aspecto relativo a la página web
            enlazada.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            En este sentido, si el usuario tuviera conocimiento efectivo de que las actividades desarrolladas a través de tales páginas web
            de terceros son ilegales o contravienen la moral y/o el orden público, lo comunicará inmediatamente a NEWTRAL a los efectos de
            que se proceda a deshabilitar el enlace de acceso a las mismas, acción que se llevará a cabo en el menor tiempo posible.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            En cualquier caso, la publicación de cualquier enlace desde el Sitio Web a otro sitio web ajeno no implicará que exista algún
            tipo de relación, colaboración o dependencia entre NEWTRAL y el responsable de dicho sitio web.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>5. CONDICIONES DE USO DEL SITIO WEB</Text>
          <Text style={[styles.paragraph, styles.text]}>
            No está permitido y, por tanto, sus consecuencias serán de exclusiva responsabilidad del usuario, el acceso o la utilización del
            Sitio Web con fines ilegales o no autorizados, con o sin finalidad económica, y más específicamente, con carácter enunciativo,
            pero no limitativo, queda prohibido:
          </Text>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>1) </Text>
              <Text>
                Usar el Sitio Web en forma alguna que pueda provocar daños, interrupciones, ineficiencias o defectos en su funcionalidad o
                en el ordenador de un tercero;
              </Text>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>2) </Text>
              <Text>
                Usar el Sitio Web para la transmisión, la instalación o la publicación de cualquier virus, código malicioso u otros
                programas o archivos perjudiciales;
              </Text>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>3) </Text>
              <Text>Usar el Sitio Web para recoger datos de carácter personal de otros usuarios;</Text>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>4): </Text>
              <Text>Usar el Sitio Web para la realización de actividades publicitarias o de explotación comercial.</Text>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>5) </Text>
              <Text>
                Acceder sin autorización a cualquier sección del Sitio Web, a otros sistemas o redes conectadas al Sitio Web, a ningún
                servidor de NEWTRAL ni a los servicios ofrecidos a través del Sitio Web, por medio de pirateo o falsificación, extracción de
                contraseñas o cualquier otro medio ilegítimo;
              </Text>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>6) </Text>
              <Text>
                Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticación del Sitio Web o de cualquier red conectada al
                mismo, o las medidas de seguridad o protección inherentes a los contenidos ofrecidos en el Sitio Web;
              </Text>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text style={styles.boldText}>7) </Text>
              <Text>
                Llevar a cabo alguna acción que provoque una saturación desproporcionada o innecesaria en la infraestructura del Sitio Web o
                en los sistemas o redes de NEWTRAL, así como en los sistemas y redes conectados al Sitio Web.
              </Text>
            </Text>
          </View>
          <Text style={[styles.paragraph, styles.text]}>
            El incumplimiento por el usuario de cualquiera de estas condiciones de uso podrá llevar aparejada la adopción por NEWTRAL de las
            medidas oportunas amparadas en Derecho y en el ejercicio de sus derechos u obligaciones, pudiendo llegar a impedir el acceso al
            Sitio Web o la posibilidad de participar en los espacios habilitados en el mismo a los usuarios que incumplan estas condiciones,
            sin que medie posibilidad de indemnización alguna por los daños y perjuicios causados.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>6. RESPONSABILIDADES Y GARANTÍAS</Text>
          <Text style={[styles.paragraph, styles.text]}>
            NEWTRAL no garantiza ni se hace responsable de: (i) la veracidad y continuidad de los contenidos del Sitio Web; (ii) la ausencia
            de errores en dichos contenidos; (iii) la ausencia de errores, virus y/o demás componentes dañinos en el Sitio Web o en el
            servidor que lo suministra; (iv) la invulnerabilidad del Sitio Web y/o la imposibilidad de vulnerar las medidas de seguridad que
            se adopten en la misma; (v) la falta de utilidad o rendimiento de los contenidos del Sitio Web; y (vi) los daños o perjuicios
            que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que NEWTRAL
            establece en el Sitio Web o a través de la vulneración de los sistemas de seguridad del Sitio Web y (vii) los daños o perjuicios
            de cualquier tipo producidos en el usuario que traigan causa de fallos o desconexiones en las redes de telecomunicaciones que
            produzcan la suspensión, cancelación o interrupción del servicio del durante la prestación del mismo o con carácter previo.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            No obstante, NEWTRAL declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la
            técnica, para garantizar el funcionamiento del Sitio Web y reducir al mínimo los errores del sistema, tanto desde el punto de
            vista técnico, como de los contenidos publicados en el Sitio Web.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            NEWTRAL no asumirá responsabilidad en cuanto a hipotéticos perjuicios que pudieran originarse por el uso del Sitio Web. Si el
            usuario tuviera conocimiento de la publicación de algún dato inexacto, desactualizado y falso o de la existencia de algún
            contenido ilícito, ilegal, contrario a las leyes o que pudiera suponer una infracción de derechos de propiedad intelectual o
            industrial, de la normativa aplicable en material de protección de datos personales y/o de cualquier otro derecho, deberá
            notificarlo inmediatamente a NEWTRAL para que ésta pueda proceder a la adopción de las medidas oportunas.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>7. POLÍTICA DE PRIVACIDAD Y COOKIES</Text>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>
              De conformidad con lo dispuesto por la legislación vigente y aplicable en materia de protección de datos de carácter personal,
              todos los datos de carácter personal facilitados durante la utilización del Sitio Web serán tratados de conformidad con los
              dispuesto en la
            </Text>
            <Pressable onPress={openPrivacyURL}>
              <Text style={styles.url}> Política de Privacidad </Text>
            </Pressable>
            <Text> que todo usuario debe aceptar expresamente para poder utilizar y registrarse en el Sitio Web.</Text>
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            Determinadas funcionalidades del Sitio Web requieren el tratamiento de datos personales, los cuales observarán lo dispuesto en
            la Política de Privacidad. En este sentido, siempre que el usuario facilite datos personales, este será el único responsable de
            la veracidad y exactitud de dichos datos, de modo que deberá comunicar a NEWTRAL cualquier actualización de estos tan pronto
            como le sea posible.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>
              Asimismo, se informa al usuario de que NEWTRAL puede acceder a información sobre sus hábitos de navegación y sobre el uso que
              hace del Sitio Web de conformidad con lo indicado en la
            </Text>
            <Pressable onPress={openCookiesURL}>
              <Text style={styles.url}> Política de Cookies </Text>
            </Pressable>
            <Text>.</Text>
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>8. NULIDAD Y EFICACIA</Text>
          <Text style={[styles.paragraph, styles.text]}>
            Si cualquier cláusula incluida en el presente Aviso Legal fuese declarada total o parcialmente nula o ineficaz, tal nulidad o
            ineficacia tan solo afectará a dicha disposición o a la parte de ella que resulte nula o ineficaz, subsistiendo el presente
            Aviso Legal en todo lo demás, y considerándose tal disposición total o parcialmente por no incluida.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>9. LEY APLICABLE Y JURISDICCIÓN COMPETENTE</Text>
          <Text style={[styles.paragraph, styles.text]}>
            Lo dispuesto en este Aviso Legal se rige por la ley española. Las partes se someten expresamente, para la resolución de los
            conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales de Madrid capital, salvo disposición legal
            imperativa en contrario, en cuyo caso, se estará a su contenido.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            Para presentar reclamaciones en el uso de nuestros servicios, puede dirigirse por correo a la dirección electrónica o física
            indicada al inicio, comprometiéndonos a buscar en todo momento una solución amistosa del conflicto.
          </Text>
        </View>
        <View style={[styles.marginTop, styles.paddingEndText]}>
          <Text style={[styles.text, styles.paragraph, styles.boldText]}>Última actualización: 1 de marzo de 2023.</Text>
          <Text style={[styles.text, styles.paragraph, styles.boldText]}>
            © 2023 NEWTRAL MEDIA AUDIOVISUAL, S.L.U., Todos los derechos reservados.
          </Text>
        </View>
      </>
    </GradientContent>
  );
};

export default LegalText;
