import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, View, Image } from 'react-native';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { subjectsStats } from '../../../../constants/CardStatsFilters';
import { emptyGameboard, glass2, howCard } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import BotBlock from '../BotBlock/BotBlock';
import { useStylesHow } from '../useStylesHow';

const HowSectionSubjects = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <View style={[styles.splitContainer, isNativeOrPWA && { maxWidth: '90%', marginHorizontal: 'auto' }]}>
        <View style={styles.splitView}>
          <BotBlock small withBot={!isNativeOrPWA} title={howTitle['subjects-title']}>
            <Text style={[styles.paragraph, { marginBottom: 5 }]}>
              <TextWithHTML text={howText['subjects-text-1']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
            </Text>
            <Text style={[styles.paragraph, { marginTop: 5 }]}>
              <TextWithHTML text={howText['subjects-text-2']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
            </Text>
            <View style={{ flexDirection: 'row', marginBottom: 8 }}>
              {subjectsStats.map((stat, index) => {
                return <Image key={index} source={stat.inactiveImage} style={styles.statIcon} />;
              })}
            </View>
          </BotBlock>
        </View>
        <View style={styles.splitView}>
          <Image style={styles.card} resizeMode="contain" source={howCard} accessible accessibilityLabel="card image" />
          <Image
            style={{ position: 'absolute', left: isNativeOrPWA ? '3%' : '10%', top: '8%', height: '50%', width: '50%' }}
            resizeMode="contain"
            source={glass2}
            accessible
            accessibilityLabel="card image"
          />
        </View>
      </View>
    </ImageBackground>
  );
};

export default HowSectionSubjects;
