/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Home: '',
      Campaign: 'campaign',
      CardInventoryViewer: 'card-inventory-viewer',
      DeckEditor: 'deck-editor',
      DeckSelector: 'deck-selector',
      GameBoard: 'game-board',
      GameBoardInitializer: 'game-board-initializer',
      Login: 'login',
      Logout: 'logout',
      Landing: 'landing',
      LaunchBattle: 'launch-battle',
      RefreshToken: 'refresh-token',
      UserProfile: 'user-profile',
      MissionDialogue: 'mission-dialogue',
      ErrorScreen: 'error-screen',
      Ranking: 'ranking',
      HowToPlay: 'how-to-play',
      Legal: 'legal',
      Terms: 'terms',
      Cookies: 'cookies',
      PrivacyPolicy: 'privacy-policy',
      Tutorial: 'tutorial',
      LoginUpdate: 'login-update',
      Glossary: 'glossary',
      Credits: 'credits'
    }
  }
};

export default linking;
