import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import { isNativeMobile, isNativeOrPWA } from '../../../utils';
import { useRatioDimensions } from '../../AspectRatioView/useRatioDimensions';
import ButtonShape from '../../ButtonShape/ButtonShape';

interface CardSupportModalContentProps {
  handleSelection: (accept: boolean) => void;
}

const CardSupportModalContent = ({ handleSelection }: CardSupportModalContentProps) => {
  const { rh } = useRatioDimensions();

  const styles = StyleSheet.create({
    title: {
      color: '#FFF',
      fontSize: 32,
      fontWeight: '800'
    },
    explanation: {
      color: '#FFF',
      fontSize: 21,
      fontWeight: '500',
      textAlign: 'center'
    },
    buttons: {
      width: isNativeMobile ? '50%' : '80%',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: rh(1),
      display: 'flex',
      flexDirection: 'row'
    },
    buttonContainer: {
      width: isNativeMobile ? '50%' : '20%',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: rh(1),
      minWidth: !isNativeOrPWA ? 100 : 'auto',
      maxWidth: isNativeOrPWA ? 100 : 'auto',
      padding: 5
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    }
  });

  return (
    <>
      <Text style={styles.title}>¡Ups!</Text>
      <Text style={[styles.explanation, { maxWidth: isNativeOrPWA ? '80%' : 512 }]}>
        {'¡Cuidado! Ahora mismo esta carta no tiene efecto alguno y al lanzarla se gastan puntos de acción, ¿quieres continuar?'}
      </Text>
      <View style={styles.buttons}>
        <TouchableWithoutFeedback onPress={() => handleSelection(true)}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.explanation}>Sí</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={() => handleSelection(false)}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
              <View style={styles.buttonInner}>
                <Text style={styles.explanation}>No</Text>
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </>
  );
};

export default CardSupportModalContent;
