import { createSlice } from '@reduxjs/toolkit';

interface initialStateInterface {
  aspectRatio: { viewWidth: number; viewHeight: number } | null;
}
const initialState: initialStateInterface = {
  aspectRatio: null
};

const aspectRatioSlice = createSlice({
  name: 'aspectRatio',
  initialState,
  reducers: {
    enableAspectRatio(state, action: { payload: { viewWidth: number; viewHeight: number } }) {
      return { ...state, aspectRatio: action.payload };
    },
    disableAspectRatio(state) {
      state.aspectRatio = null;
    }
  }
});

export const { disableAspectRatio, enableAspectRatio } = aspectRatioSlice.actions;

export default aspectRatioSlice;
