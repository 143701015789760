import { ImageBackground, StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { irregularModalContainer } from '../../../constants/Images';
import adjust from '../../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA, isPWA, isPortrait } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';
import { HeaderLinks } from './ExternalHeader';
import { useExternalLinks } from './useExternalLinks';

interface LinksMenuModalProps {
  withLogo?: boolean;
  current?: HeaderLinks;
  closeModal: Function;
}

const LinksMenuModal = ({ current, closeModal }: LinksMenuModalProps) => {
  const { rw, rh } = useRatioDimensions();

  const { isAuthorized, openHow, openRanking, openLogin, openLanding, openHome } = useExternalLinks();

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
      elevation: 100,
      backgroundColor: isNativeOrPWA ? '#494949' : 'rgba(0, 0, 0, 0.5)'
    },
    modalBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: rw(isNativeOrPWA ? 100 : 58),
      minHeight: 300,
      height: isNativeOrPWA ? rh(100) : rw(58) / 1.32
    },
    modalSize: {
      minWidth: 920,
      minHeight: isPortrait ? 500 : 700,
      maxWidth: 920,
      maxHeight: isNativeOrPWA ? '99%' : 700
    },
    mainContentContainer: {
      paddingHorizontal: isPWA ? 20 : '10%',
      width: '100%',
      height: isNativeOrPWA ? '100%' : '70%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonText: {
      color: '#D3D3D3',
      fontWeight: '500',
      fontSize: adjust(18),
      textAlign: 'center',
      paddingBottom: 4,
      width: '100%'
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    },
    buttonContainer: {
      width: isNativeMobile ? '100%' : '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: rh(1),
      minWidth: !isNativeOrPWA ? 300 : 'auto',
      maxWidth: isNativeOrPWA ? 240 : 'auto'
    },
    current: {
      color: '#17FFE3'
    },
    sectionLinks: {
      flexDirection: 'column'
    }
  });

  return (
    <View style={styles.container}>
      <View style={[styles.modal, !isNativeOrPWA && styles.modalSize]}>
        {!isNativeOrPWA && <ImageBackground style={styles.modalBackground} source={irregularModalContainer} resizeMode="stretch" />}
        <View style={styles.mainContentContainer}>
          <View style={styles.sectionLinks}>
            {!isAuthorized ? (
              <TouchableOpacity
                accessible
                accessibilityLabel={'Link to Fakesover login page'}
                onPress={() => {
                  openLogin();
                  closeModal();
                }}
              >
                <View style={styles.buttonContainer}>
                  <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
                    <View style={styles.buttonInner}>
                      <Text style={[styles.buttonText, current === 'login' && styles.current]}>Login</Text>
                    </View>
                  </ButtonShape>
                </View>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                accessible
                accessibilityLabel={'Link to Fakesover home page'}
                onPress={() => {
                  openHome();
                  closeModal();
                }}
              >
                <View style={styles.buttonContainer}>
                  <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
                    <View style={styles.buttonInner}>
                      <Text style={styles.buttonText}>Home</Text>
                    </View>
                  </ButtonShape>
                </View>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              accessible
              accessibilityLabel={'Link to Fakesover landing page'}
              onPress={() => {
                openLanding();
                closeModal();
              }}
            >
              <View style={styles.buttonContainer}>
                <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
                  <View style={styles.buttonInner}>
                    <Text style={[styles.buttonText, current === 'landing' && styles.current]}>¿Qué es Fakesover?</Text>
                  </View>
                </ButtonShape>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              accessible
              accessibilityLabel={'Link to Fakesover How to play page'}
              onPress={() => {
                openHow();
                closeModal();
              }}
            >
              <View style={styles.buttonContainer}>
                <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
                  <View style={styles.buttonInner}>
                    <Text style={[styles.buttonText, current === 'how' && styles.current]}>¿Cómo jugar?</Text>
                  </View>
                </ButtonShape>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              accessible
              accessibilityLabel={'Link to Fakesover ranking page'}
              onPress={() => {
                openRanking();
                closeModal();
              }}
            >
              <View style={styles.buttonContainer}>
                <ButtonShape bgColor="dark" border heightBtn={isNativeOrPWA ? 42 : 56}>
                  <View style={styles.buttonInner}>
                    <Text style={[styles.buttonText, current === 'ranking' && styles.current]}>Ranking</Text>
                  </View>
                </ButtonShape>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

export default LinksMenuModal;
