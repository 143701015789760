import LottieView from 'lottie-react-native';
import { Animated, GestureResponderEvent, TouchableWithoutFeedback, Image, ImageBackground } from 'react-native';
import { DraxView } from 'react-native-drax';
import { PanGestureHandler } from 'react-native-gesture-handler';
import { useRatioDimensions } from '../../../../commons/components/AspectRatioView/useRatioDimensions';
import HandCard from '../../../../commons/components/Card/CardTypes/HandCard';
import { CardSizesEnum } from '../../../../commons/components/Card/utils';
import { View, Text } from '../../../../commons/components/Themed';
import { PlayerTypeEnum } from '../../../../commons/constants/player';
import { Card as CardType } from '../../../../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../../../../commons/dtos/player.dto';
import { Rule } from '../../../../commons/dtos/rule.dto';
import { TagsEnum } from '../../../../commons/dtos/tag.dto';
import { TypesEnum } from '../../../../commons/dtos/type.dto';
import { cardHasTag } from '../../../../commons/utils';
import { battlePointsContainer, deadCardIcon, target } from '../../../../constants/Images';
import { lottieTargetReached } from '../../../../constants/Lotties';
import { HandOfCardsProperties, useHandOfCards } from '../useHandOfCards';

interface DragProps {
  card: CardType;
  index: number;
}

const PlayerHandOfCardsMobile = (props: HandOfCardsProperties) => {
  const { rh, offsetRatioLeft } = useRatioDimensions();
  const {
    setDragCard,
    cardAppliesToTarget,
    onCardPressed,
    onCloseDetailModal,
    onDragMethods,
    playerHand,
    selectingTargetCard,
    selectingTargetDrawnCard,
    targetReached,
    getApplicableRules,
    opponentIsArtificialIntelligence,
    cardIsUpdating,
    isCurrentPlayerRound
  } = props;
  const { onDragDrop, onDragEnd, onDragExit, onDragStart } = onDragMethods;
  const { cardsHeight, cardsWidth, setCardsHeight, setCardsWidth, onDragOutAreaCloseDetailModal, styles } = useHandOfCards(
    isCurrentPlayerRound,
    !!opponentIsArtificialIntelligence
  );

  const onLongPress = (e: GestureResponderEvent, card: CardType) => {
    const element = e.nativeEvent;
    const left = element.pageX - (cardsWidth / 2 + element.locationX + offsetRatioLeft);
    const top = rh(100) - cardsHeight * 2 - 90;
    onCardPressed(card, PlayerRoundEnum.PLAYER, { left, top });
  };

  const DragUIComponent = ({ card, index }: DragProps) => {
    const { type } = card;
    let damagePoints = 0;
    let attackingPointsResult = 0;
    if (selectingTargetCard && selectingTargetCard === card && targetReached && cardAppliesToTarget(card, targetReached, props.allRules)) {
      damagePoints = getApplicableRules(card, targetReached, props.allRules).reduce(
        (accum: number, current: Rule) => (accum += current.defensePoints ? current.defensePoints : 0),
        0
      );
      attackingPointsResult =
        !cardHasTag(targetReached, TagsEnum.FACTCHECK) && targetReached.defensePoints ? targetReached.defensePoints + damagePoints : 0;
    }
    return (
      <Animated.View style={styles.cardContentDrag}>
        <DraxView
          style={styles.cardDrag}
          key={`${card.id}_${index}`}
          dragPayload={`${card.id}_${index}`}
          onDrag={({ dragTranslation }) => onDragOutAreaCloseDetailModal(dragTranslation, onCloseDetailModal)}
          onDragStart={({ dragged, dragAbsolutePosition }) => {
            setDragCard('');
            const left = dragAbsolutePosition.x - cardsWidth / 2 - dragged.dragOffset.x;
            const top = rh(100) - cardsHeight * 2 - 90;
            onDragStart(left, top, card, PlayerRoundEnum.PLAYER);
          }}
          onDragExit={onDragExit}
          onDragEnd={onDragEnd}
          onDragDrop={() => onDragDrop(card)}
          renderHoverContent={() => {
            return selectingTargetCard && selectingTargetCard === card ? (
              targetReached && cardAppliesToTarget(card, targetReached, props.allRules) ? (
                <>
                  {damagePoints < 0 && (
                    <View style={styles.battlePointsContainer}>
                      <ImageBackground style={styles.battlePointsBackground} source={battlePointsContainer}></ImageBackground>
                      {attackingPointsResult > 0 ? (
                        <Text style={styles.pointsText}>{damagePoints}</Text>
                      ) : (
                        <Image style={styles.deadCardIcon} source={deadCardIcon} resizeMode={'contain'}></Image>
                      )}
                    </View>
                  )}
                  <LottieView autoPlay={true} loop={true} style={styles.lottieEffect} source={lottieTargetReached}></LottieView>
                </>
              ) : (
                <Image style={styles.targetSelector} source={target} />
              )
            ) : type?.typeName === TypesEnum.SUPPORT || type?.typeName === TypesEnum.ACTION ? (
              <Image style={styles.targetSelector} source={target} />
            ) : (
              <HandCard card={card} cardSize={CardSizesEnum.SMALL} />
            );
          }}
        >
          {selectingTargetDrawnCard === card ? (
            <View style={styles.selectingTargetDrawnCardContainer}>
              {selectingTargetCard !== card && (
                <HandCard
                  card={card}
                  cardSize={CardSizesEnum.SMALL}
                  conditionalStyles={selectingTargetCard !== card ? styles.playableHandCardShadow : {}}
                />
              )}
            </View>
          ) : (
            selectingTargetCard !== card && (
              <HandCard
                card={card}
                cardSize={CardSizesEnum.SMALL}
                conditionalStyles={selectingTargetCard !== card ? styles.playableHandCardShadow : {}}
              />
            )
          )}
        </DraxView>
      </Animated.View>
    );
  };

  return (
    <Animated.View style={[styles.container, { bottom: '-5%' }]}>
      <Animated.View nativeID={'playerHand'} style={styles.cardsContainer}>
        {playerHand.map((card, index) => {
          return (
            <Animated.View
              onLayout={event => {
                if (cardsHeight < 1) {
                  const { width, height } = event.nativeEvent.layout;
                  setCardsWidth(width);
                  setCardsHeight(height);
                }
              }}
              key={`${card.id} ${playerHand.length}`}
              style={styles.card}
            >
              <PanGestureHandler maxPointers={1}>
                {(props.isCurrentPlayerRound(PlayerTypeEnum.PLAYER) && card.isPlayingCard && !props.selectingTargetDrawnCard) ||
                (!props.isCurrentPlayerRound(PlayerTypeEnum.PLAYER) && props.selectingTargetDrawnCard) ? (
                  DragUIComponent({ card, index })
                ) : (
                  <TouchableWithoutFeedback
                    onLongPress={e => {
                      if (cardIsUpdating) return;
                      onLongPress(e, card);
                    }}
                    onPressOut={() => {
                      if (cardIsUpdating) return;
                      onCloseDetailModal();
                    }}
                  >
                    <View style={styles.cardContent}>
                      <HandCard card={card} cardSize={CardSizesEnum.SMALL} />
                    </View>
                  </TouchableWithoutFeedback>
                )}
              </PanGestureHandler>
            </Animated.View>
          );
        })}
      </Animated.View>
    </Animated.View>
  );
};

export default PlayerHandOfCardsMobile;
