import { RaritiesEnum } from '../commons/dtos/rarity.dto';
import { SubjectsEnum } from '../commons/dtos/subject.dto';
import {
  emptyIcon,
  selectedEmptyIcon,
  scienceIcon,
  selectedScienceIcon,
  conspiracyIcon,
  selectedConspiracyIcon,
  hateIcon,
  selectedHateIcon,
  scamIcon,
  selectedScamIcon,
  oneNumber,
  twoNumber,
  threeNumber,
  fourNumber,
  fiveNumber,
  sixNumber,
  sevenMoreNumber,
  selectedNumberOneIcon,
  selectedNumberTwoIcon,
  selectedNumberThreeIcon,
  selectedNumberFourIcon,
  selectedNumberFiveIcon,
  selectedNumberSixIcon,
  selectedNumberSevenOrMoreIcon,
  rareIcon,
  selectedRareIcon,
  uniqueIcon,
  selectedUniqueIcon
} from './Images';

export const subjectsStats = [
  {
    name: 'Neutra',
    inactiveImage: emptyIcon,
    activeImage: selectedEmptyIcon,
    value: SubjectsEnum.NEUTRAL
  },
  {
    name: 'Ciencia',
    inactiveImage: scienceIcon,
    activeImage: selectedScienceIcon,
    value: SubjectsEnum.SCIENCE
  },
  {
    name: 'Teoría de la conspiración',
    inactiveImage: conspiracyIcon,
    activeImage: selectedConspiracyIcon,
    value: SubjectsEnum.CONSPIRACY
  },
  {
    name: 'Odio',
    inactiveImage: hateIcon,
    activeImage: selectedHateIcon,
    value: SubjectsEnum.HATE
  },
  {
    name: 'Estafa',
    inactiveImage: scamIcon,
    activeImage: selectedScamIcon,
    value: SubjectsEnum.SCAM
  }
];

export const actionPoints = [
  {
    name: '1',
    inactiveImage: oneNumber,
    activeImage: selectedNumberOneIcon,
    value: 1
  },
  {
    name: '2',
    inactiveImage: twoNumber,
    activeImage: selectedNumberTwoIcon,
    value: 2
  },
  {
    name: '3',
    inactiveImage: threeNumber,
    activeImage: selectedNumberThreeIcon,
    value: 3
  },
  {
    name: '4',
    inactiveImage: fourNumber,
    activeImage: selectedNumberFourIcon,
    value: 4
  },
  {
    name: '5',
    inactiveImage: fiveNumber,
    activeImage: selectedNumberFiveIcon,
    value: 5
  },
  {
    name: '6',
    inactiveImage: sixNumber,
    activeImage: selectedNumberSixIcon,
    value: 6
  },
  {
    name: '+7',
    inactiveImage: sevenMoreNumber,
    activeImage: selectedNumberSevenOrMoreIcon,
    value: 7
  }
];
export const rarities = [
  {
    name: 'común',
    inactiveImage: emptyIcon,
    activeImage: selectedEmptyIcon,
    value: RaritiesEnum.SILVER
  },
  {
    name: 'rara',
    inactiveImage: rareIcon,
    activeImage: selectedRareIcon,
    value: RaritiesEnum.GOLD
  },
  {
    name: 'única',
    inactiveImage: uniqueIcon,
    activeImage: selectedUniqueIcon,
    value: RaritiesEnum.PLATINUM
  }
];
