import { useMemo } from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import { useDispatch } from 'react-redux';
import useUnits from 'rxn-units';
import { hideTimeModal } from '../../../redux/Time-advice/timeAdvice';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';

const MSGS = [
  'Hey, hoy ya has jugado {hoursDiff}h, date un respiro, que siempre viene bien.',
  'Fakesover está bien, pero después de {hoursDiff}h jugando te recomendamos un break.',
  'Ya llevas {hoursDiff}h jugando, quizás sea bueno dejarlo por hoy, mañana nos vemos!',
  'Stop, stop, llevas {hoursDiff}h jugando sin parar, que tal si te das un respiro por hoy.',
  'Tras {hoursDiff}h jugando ya eres un Pro de Fakesover, por hoy está bien, es momento de tomar un descanso.'
];

const TimeModalContent = () => {
  const { vh } = useUnits();
  const dispatch = useDispatch();

  const message = useMemo(() => MSGS[Math.floor(Math.random() * MSGS.length)], []);
  const messageWithTime = message.replace('{hoursDiff}', '1');

  const handleClose = () => dispatch(hideTimeModal());

  const styles = StyleSheet.create({
    title: {
      color: '#FFF',
      fontSize: 32,
      fontWeight: '800'
    },
    explanation: {
      color: '#FFF',
      fontSize: 21,
      fontWeight: '500',

      textAlign: 'center'
    },
    buttonContainer: {
      width: isNativeMobile ? '50%' : '20%',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: vh(1),
      minWidth: !isNativeOrPWA ? 300 : 'auto',
      maxWidth: isNativeOrPWA ? 240 : 'auto'
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    }
  });

  return (
    <>
      <Text style={styles.title}>¡Cuidado!</Text>
      <Text style={[styles.explanation, { maxWidth: isNativeOrPWA ? '80%' : 512 }]}>{messageWithTime}</Text>
      <TouchableWithoutFeedback onPress={handleClose}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={styles.explanation}>Continuar</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
    </>
  );
};

export default TimeModalContent;
