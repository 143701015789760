import { useState } from 'react';
import { ImageBackground, StyleSheet, TouchableWithoutFeedback, View, Image, Text, TextInput, Platform, Pressable } from 'react-native';
import useUnits from 'rxn-units';
import { borderEditNameDeck, closeIconBlack, deckBackground, deckBackgroundSlides, irregularModalContainer } from '../../constants/Images';
import adjust from '../styles/TextAdjust';
import { isNativeOrPWA } from '../utils';
import ButtonShape from './ButtonShape/ButtonShape';
import SlidePicker from './SlidePicker';

interface EditDeckModalProps {
  closeModal: Function;
  deckNameFinal: string;
  handleDeckName: Function;
  deckImageFinal: string;
  handleDeckImage: Function;
}

const EditDeckModal = ({ closeModal, deckNameFinal, handleDeckName, deckImageFinal, handleDeckImage }: EditDeckModalProps) => {
  const { vw, vh } = useUnits();
  const [deckName, setDeckName] = useState<string>(deckNameFinal);
  const [deckImage, setDeckImage] = useState<string>(deckImageFinal || Object.keys(deckBackground)[0]);
  const [messageError, setMessageError] = useState<string>('');

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
      backgroundColor: isNativeOrPWA ? '#494949' : 'rgba(0, 0, 0, 0.5)'
    },
    closeContainer: {
      zIndex: 2300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'absolute',
      right: isNativeOrPWA ? vw(8) : vw(3),
      top: isNativeOrPWA ? vw(2) : vw(1)
    },
    modalBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: vw(isNativeOrPWA ? 100 : 58),
      minHeight: 300,
      height: isNativeOrPWA ? vh(100) : vw(58) / 1.1
    },
    modalSize: {
      maxWidth: 850,
      maxHeight: '99%'
    },
    editNameTitle: {
      fontWeight: '700',
      fontSize: adjust(19),
      color: '#FFF',
      zIndex: 2300,
      textAlign: 'left',
      marginBottom: vw(1.6)
    },
    mainContentContainer: {
      width: '60%',
      height: '90%',
      justifyContent: 'center',
      maxWidth: 420
    },
    deckNameInput: {
      height: vw(6),
      maxHeight: 61
    },
    boderImageInput: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    inputStyle: {
      height: isNativeOrPWA ? '100%' : '60%',
      width: isNativeOrPWA ? '95%' : '90%',
      color: '#FFF',
      fontWeight: '500'
    },
    spaces: {
      marginTop: vw(3),
      marginBottom: vw(2.5)
    },
    picker: {
      maxHeight: 155,
      position: 'relative',
      flexDirection: 'row',
      flexGrow: 1
    },
    buttonContainer: {
      width: 200,
      justifyContent: 'center',
      flexDirection: 'row',
      marginTop: vw(2.5),
      marginLeft: 'auto',
      flexGrow: 0,
      opacity: !deckName || messageError.length > 0 ? 0.5 : 1
    },
    buttonText: {
      color: '#fff',
      fontWeight: '700',
      fontSize: adjust(15),
      textAlign: 'center',
      marginBottom: isNativeOrPWA ? 2 : 4
    },
    errorText: {
      color: '#FF6F77',
      fontSize: adjust(14),
      textAlign: 'center',
      marginTop: 5
    },
    test: {
      width: '100%',
      height: '100%'
    },
    closeIcon: { height: isNativeOrPWA ? 18 : 30, width: isNativeOrPWA ? 18 : 30 }
  });

  const checkDeckName = (text: string): void => {
    const patternDeckName = /^[ñÑ a-zA-Z0-9_-]{3,25}$/;
    if (!patternDeckName.test(text)) {
      !messageError && setMessageError('De 3 a 25 caracteres, o contiene caracteres no permitidos.');
    } else {
      messageError && setMessageError('');
    }
    setDeckName(text);
  };

  const handleSave = () => {
    handleDeckName(deckName);
    setDeckName('');
    handleDeckImage(deckImage);
    closeModal();
  };

  return (
    <View style={styles.container}>
      <View style={[styles.modal, !isNativeOrPWA && styles.modalSize]}>
        {!isNativeOrPWA && <ImageBackground style={styles.modalBackground} source={irregularModalContainer} resizeMode="stretch" />}

        <TouchableWithoutFeedback onPress={() => closeModal()}>
          <View style={styles.closeContainer}>
            <ButtonShape bgColor="white" square heightBtn={isNativeOrPWA ? 34 : 56}>
              <View>
                <Image source={closeIconBlack} style={styles.closeIcon} resizeMode="contain" />
              </View>
            </ButtonShape>
          </View>
        </TouchableWithoutFeedback>

        <View style={styles.mainContentContainer}>
          <Text style={styles.editNameTitle}>Nombre del mazo</Text>
          <View style={styles.deckNameInput}>
            <ImageBackground source={borderEditNameDeck} resizeMode="stretch" style={styles.boderImageInput}>
              <TextInput
                style={[styles.inputStyle, Platform.OS === 'web' && { outline: 'none', outlineWidth: 0, outlineColor: 'transparent' }]}
                placeholderTextColor="white"
                autoFocus={true}
                underlineColorAndroid={'rgba(255, 255, 255, 0.5)'}
                keyboardAppearance={'dark'}
                numberOfLines={1}
                autoCorrect={false}
                spellCheck={false}
                disableFullscreenUI={true}
                value={deckName}
                onChangeText={(text: string) => checkDeckName(text)}
              />
            </ImageBackground>
            <Text style={styles.errorText}>{messageError}</Text>
          </View>
          <Text style={[styles.editNameTitle, styles.spaces]}>Imagen del mazo</Text>
          <SlidePicker
            parentStyle={styles.picker}
            indexSelected={(deckImage && Object.keys(deckBackground).indexOf(deckImage)) || 0}
            list={Object.keys(deckBackground)}
            onChange={(item: string) => setDeckImage(item)}
          >
            {Object.keys(deckBackground).map((item: any) => (
              <ImageBackground key={item} source={deckBackgroundSlides[item]} resizeMode="contain" style={styles.test} />
            ))}
          </SlidePicker>
          <Pressable onPress={() => handleSave()} disabled={!deckName || messageError.length > 0 || !deckImage}>
            <View style={styles.buttonContainer}>
              <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 56}>
                <Text style={styles.buttonText}>Guardar Mazo</Text>
              </ButtonShape>
            </View>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default EditDeckModal;
