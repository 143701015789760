import { LinearGradient } from 'expo-linear-gradient';
import { useState } from 'react';
import { LayoutChangeEvent, ScrollView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Profile } from '../../../redux/Profile/profileReducer';
import { RankingPlayer } from '../../constants/ranking';
import RankingElement from './RankingElement';

interface RankingListProps {
  list: RankingPlayer[] | undefined;
  gradColor?: string;
}

const RankingList = ({ list, gradColor }: RankingListProps) => {
  if (!list?.length) return <></>;
  const color = gradColor || '#4C4C4C';
  const player = useSelector(({ profile }: { profile: Profile }) => profile.player);
  const [ref, setRef] = useState<ScrollView>();

  const styles = StyleSheet.create({
    container: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      height: '100%',
      width: '100%',
      borderRadius: 10,
      overflow: 'hidden'
    },
    parentScrollView: {
      flex: 1
    },
    contentContainerStyle: {
      flexGrow: 1
    },
    linearGradientStyle: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 100
    },
    scrollContainer: {
      flexGrow: 1,
      paddingTop: 10,
      paddingBottom: 50
    }
  });
  const transparent = 'rgba(90, 90, 90, 0)';
  const locations = [0, 0.1, 0.9, 1];

  return (
    <View style={[styles.parentScrollView, styles.container]}>
      <LinearGradient
        style={[styles.linearGradientStyle, styles.linearGradientStyle]}
        colors={[color, transparent, transparent, color]}
        {...(locations ? { locations } : {})}
        pointerEvents="none"
      />
      <ScrollView
        style={styles.scrollContainer}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
        ref={ref => {
          setRef(ref as any);
        }}
      >
        {list.map(e => {
          const selected = e.playerId === player?.id;
          return (
            <RankingElement
              onLayout={(event: LayoutChangeEvent) => {
                const { layout } = event.nativeEvent;
                if (selected) {
                  ref?.scrollTo({
                    x: 0,
                    y: layout.y,
                    animated: false
                  });
                }
              }}
              selected={selected}
              key={e.playerId}
              player={e}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

export default RankingList;
