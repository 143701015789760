import { StyleSheet } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';

export const useBoardCardItemStyles = () => {
  const { rw } = useRatioDimensions();

  const styles = StyleSheet.create({
    battlePointsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: rw(4),
      height: rw(4),
      position: 'absolute'
    },
    battlePointsContainerOpponentAttacked: { left: rw(2), top: -rw(2) },
    battlePointsContainerPlayerAttacked: { left: rw(2), bottom: -rw(2) },
    hoverDraggingStyle: {
      opacity: 1,
      shadowOpacity: 0
    },
    attackingCardShadow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      shadowColor: '#FFFFFF',
      borderRadius: rw(1),
      shadowOffset: {
        width: 0,
        height: 12
      },
      shadowOpacity: 0.9,
      shadowRadius: 16.0,
      elevation: 24,
      opacity: 1
    },
    cardDrag: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 9,
      elevation: 24
    }
  });

  return {
    styles
  };
};
