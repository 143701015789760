import store from '../../../redux/store';

export const isGameActionHighlight = (id: string) => {
  const { gameActions } = store.getState();
  const { gameAction } = gameActions;

  if (!gameAction) return false;

  const { driver, configuration } = gameAction;
  // TODO: implement others drivers
  switch (driver) {
    case 'highlight':
      return configuration?.id === id;
    default:
      return false;
  }
};
