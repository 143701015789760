import { View, ViewStyle } from 'react-native';
import { DraxView } from 'react-native-drax';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import BoardCardsContainer from './BoardCardsContainer';
import { BoardCardsProps } from './BoardCardsMobile';
import useBoardCards from './useBoardCards';

interface BoardCardsWrapperProps {
  styles: {
    container: ViewStyle;
    dropContainer: ViewStyle;
    dropContainerBackground: ViewStyle;
    boardCardscontainer: ViewStyle;
    cardsContainer: ViewStyle;
    cardsContainerOpponent: ViewStyle;
    card: ViewStyle;
  };
  props: BoardCardsProps;
}

const BoardCardsWrapper = ({ styles, props }: BoardCardsWrapperProps) => {
  const {
    AISelectingTarget,
    AITarget,
    opponentIsBeingAttacked,
    playerIsBeingAttacked,
    config,
    setBoardCards,
    handleAddItem,
    attackingStatus,
    setAttackingStatus
  } = props;
  const {
    opponentBoardCards,
    playerBoardCards,
    dragCard,
    isCurrentPlayerRound,
    selectingTargetCard,
    isLaunchingCardFromHand,
    opponentIsArtificialIntelligence
  } = config;
  const {
    attackedCard,
    battleCardIndex,
    getAttackedPointsResult,
    getAttackingPointsResult,
    getCardConditions,
    getOpponentCardStyles,
    getOpponentConditions,
    getPlayerCardStyles,
    getPlayerConditions,
    onDrag,
    onMouseEnter,
    onMouseLeave,
    onPressOutHandler,
    onReceive,
    opponentOnHandlerStateChange,
    opponentOnLongPressHandler,
    playerOnHandlerStateChange,
    playerOnLongPressHandler,
    playerSuffix,
    showBattlePoints,
    getAttackedDefensePoints,
    getAttackingDefensePoints
  } = useBoardCards(config);

  return (
    <GestureHandlerRootView style={styles.container}>
      <View nativeID={'gameboard_drop_area'} style={styles.dropContainer}>
        <BoardCardsContainer
          side={PlayerRoundEnum.OPPONENT}
          boardCardParentStyle={styles.boardCardscontainer}
          parentStyle={styles.cardsContainerOpponent}
          AISelectingTarget={AISelectingTarget}
          AITarget={AITarget}
          cards={opponentBoardCards}
          getCardConditions={getCardConditions(false)}
          getCardStyles={getOpponentCardStyles}
          getConditions={getOpponentConditions}
          onHandlerStateChange={opponentOnHandlerStateChange}
          onLongPressHandler={opponentOnLongPressHandler}
          onMouseEnter={onMouseEnter}
          onMouseExit={onMouseLeave}
          onPressOutHandler={onPressOutHandler}
          attackedCard={attackedCard}
          getAttackedPointsResult={getAttackedPointsResult}
          isCurrentPlayerRound={isCurrentPlayerRound}
          onReceiveMethods={onReceive}
          playerSuffix={playerSuffix}
          showBattlePoints={showBattlePoints}
          dragCard={dragCard}
          isOpponentBeingAttacked={opponentIsBeingAttacked}
          isPlayerBeingAttacked={playerIsBeingAttacked}
          onDrag={onDrag}
          opponentIsArtificialIntelligence={opponentIsArtificialIntelligence}
          getAttackingPointsResult={getAttackingPointsResult}
          battleCards={battleCardIndex}
          cardStyle={styles.card}
          getAttackedDefensePoints={getAttackedDefensePoints}
          getAttackingDefensePoints={getAttackingDefensePoints}
          setBoardCards={setBoardCards}
          handleAddItem={handleAddItem}
          attackingStatus={attackingStatus}
          setAttacking={setAttackingStatus}
        />
        <BoardCardsContainer
          side={PlayerRoundEnum.PLAYER}
          boardCardParentStyle={styles.boardCardscontainer}
          parentStyle={styles.cardsContainer}
          cards={playerBoardCards}
          getCardConditions={getCardConditions(true)}
          onHandlerStateChange={playerOnHandlerStateChange}
          onMouseEnter={onMouseEnter}
          onMouseExit={onMouseLeave}
          AISelectingTarget={AISelectingTarget}
          AITarget={AITarget}
          getConditions={getPlayerConditions}
          getCardStyles={getPlayerCardStyles}
          onLongPressHandler={playerOnLongPressHandler}
          onPressOutHandler={onPressOutHandler}
          attackedCard={attackedCard}
          getAttackedPointsResult={getAttackedPointsResult}
          isCurrentPlayerRound={isCurrentPlayerRound}
          onReceiveMethods={onReceive}
          playerSuffix={playerSuffix}
          showBattlePoints={showBattlePoints}
          dragCard={dragCard}
          isOpponentBeingAttacked={opponentIsBeingAttacked}
          isPlayerBeingAttacked={playerIsBeingAttacked}
          onDrag={onDrag}
          opponentIsArtificialIntelligence={opponentIsArtificialIntelligence}
          getAttackingPointsResult={getAttackingPointsResult}
          battleCards={battleCardIndex}
          cardStyle={styles.card}
          getAttackedDefensePoints={getAttackedDefensePoints}
          getAttackingDefensePoints={getAttackingDefensePoints}
          setBoardCards={setBoardCards}
          handleAddItem={handleAddItem}
          attackingStatus={attackingStatus}
          setAttacking={setAttackingStatus}
        />
      </View>

      {!selectingTargetCard && isLaunchingCardFromHand && (
        <DraxView
          nativeID={'gameboard_drop_area'}
          key={'gameboard_drop_area'}
          style={styles.dropContainerBackground}
          onReceiveDragDrop={draggable => {
            return draggable.dragAbsolutePosition;
          }}
        />
      )}
    </GestureHandlerRootView>
  );
};

export default BoardCardsWrapper;
