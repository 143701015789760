/* eslint-disable camelcase */
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black
} from '@expo-google-fonts/inter';
import { Roboto_500Medium } from '@expo-google-fonts/roboto';
import axios from 'axios';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect } from 'react';
import { LogBox, StyleSheet } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { isNativeMobile } from './commons/utils';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import store, { persistor } from './redux/store';
import axiosInterceptor from './services/axiosInterceptor';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';

LogBox.ignoreLogs(['ViewPropTypes will be removed']);

// @ts-ignore
axiosInterceptor(axios, store);

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
    Roboto_500Medium,
    Inlanders: require('./assets/fonts/Inlanders.ttf'),
    'Pigeon-Post': require('./assets/fonts/Pigeon-Post.otf')
  });

  useEffect(() => {
    if (isNativeMobile) return;
    document.oncontextmenu = function () {
      return false;
    };
  }, []);

  const styles = StyleSheet.create({
    body: { overflow: 'hidden' }
  });

  if (!isLoadingComplete || !fontsLoaded) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <SafeAreaProvider style={styles.body}>
            <Navigation colorScheme={colorScheme} />
            <StatusBar hidden={true} />
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    );
  }
}
serviceWorkerRegistration.register();
