import { Text, StyleSheet, View, ImageBackground, Image, Platform } from 'react-native';
import SelectDropdown from 'react-native-select-dropdown';
import OvniWithAstronaut from '../../../assets/images/components/OvniWithAstronaut';
import boardCardsUtils from '../../../commons/utils/gameboard/boardCards';
import { angleLoginDate, borderDate } from '../../../constants/Images';
import { MAX_BOARD_CARDS_LENGTH } from '../../../constants/Values';
import {
  pickerTextStyle,
  pickerRowStyle,
  pickerTextRowStyle,
  pickerSelectedRowStyle,
  pickerSelectedTextRowStyle,
  pickerStyleExtended
} from '../../../screens/Auth/LoginForm/LogindropDownStyles';
import { BoardCards } from '../../../screens/GameBoard/gameTypes';
import { Card } from '../../dtos/card.dto';
import { isNativeOrPWA } from '../../utils';
import Modal from '../Modal';

interface LeftoverCardModalProps {
  cards: BoardCards;
  updateBoardOnSelectLeftover: (card: Card, boardCards: BoardCards) => BoardCards;
  closeModal: (param?: { action: 'CLOSE'; value: { selectedCard: Card; updatedBoardCards: BoardCards } }) => void;
}

const LeftoverCardModal = ({ cards, closeModal, updateBoardOnSelectLeftover }: LeftoverCardModalProps) => {
  const styles = StyleSheet.create({
    title: {
      fontWeight: 'bold',
      fontSize: 26,
      color: '#FFF',
      textAlign: 'center'
    },
    borderImage: {
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: isNativeOrPWA ? 0 : 20
    },
    borderButton: {
      maxHeight: isNativeOrPWA ? 35 : 60,
      marginHorizontal: '2%',
      minWidth: 128,
      maxWidth: 400,
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 75,
      width: '60%',

      minHeight: isNativeOrPWA ? 30 : 50
    },
    angleImage: {
      width: isNativeOrPWA ? 12 : 15,
      height: isNativeOrPWA ? 12 : 15
    },
    modalStyle: { flex: 1, margin: 48, justifyContent: 'space-evenly', position: 'relative' },
    selectWrapper: { flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%' },
    spaceCraftContainer: {
      width: '90%',
      height: '70%',
      backgroundColor: 'transparent',
      transform: [{ rotate: '-10deg' }],
      marginTop: -200
    }
  });

  return (
    <Modal styleParent={styles.modalStyle}>
      {!isNativeOrPWA ? <OvniWithAstronaut parentStyle={styles.spaceCraftContainer} /> : null}
      <Text style={styles.title}>{`Ya hay ${MAX_BOARD_CARDS_LENGTH} cartas en juego, ¿cuál quieres descartar?`}</Text>
      <View style={styles.selectWrapper}>
        <View style={styles.borderButton}>
          <ImageBackground style={styles.borderImage} resizeMode="stretch" source={borderDate}>
            <SelectDropdown
              buttonStyle={pickerStyleExtended}
              defaultButtonText={'Selecciona una carta'}
              buttonTextStyle={pickerTextStyle}
              rowStyle={pickerRowStyle}
              rowTextStyle={pickerTextRowStyle}
              selectedRowStyle={pickerSelectedRowStyle}
              selectedRowTextStyle={pickerSelectedTextRowStyle}
              data={cards.filter(boardCardsUtils.filterOnlyAliveCards)}
              onSelect={async (selectedItem: Card) => {
                const updatedBoardCards = updateBoardOnSelectLeftover(selectedItem, cards);
                closeModal({ action: 'CLOSE', value: { selectedCard: selectedItem, updatedBoardCards } });
              }}
              buttonTextAfterSelection={(selectedItem: Card) => {
                return selectedItem.cardName;
              }}
              rowTextForSelection={(item: { id: string; cardName: string }) => {
                return item.cardName;
              }}
              dropdownIconPosition="right"
              renderDropdownIcon={() =>
                Platform.OS === 'web' && <Image resizeMode="contain" source={angleLoginDate} style={styles.angleImage} />
              }
            />
          </ImageBackground>
        </View>
      </View>
    </Modal>
  );
};

export default LeftoverCardModal;
