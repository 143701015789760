import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { memo } from 'react';
import { StyleSheet, View, Text, Pressable, ImageBackground } from 'react-native';
import useUnits from 'rxn-units';
import { irregularBlock2 } from '../../../constants/Images';
import useConsent from '../../../hooks/useConsent';
import { RootStackParamList } from '../../../types';
import adjust from '../../styles/TextAdjust';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import ButtonShape from '../ButtonShape/ButtonShape';

type CookiesBannerProps = NativeStackNavigationProp<RootStackParamList, 'Cookies', undefined>;

const CookiesBanner = () => {
  const { vw } = useUnits();
  const { accept, reject } = useConsent();
  const navigation = useNavigation<CookiesBannerProps>();

  const openCookiesURL = () => navigation.navigate('Cookies');

  const handleAccept = () => accept();

  const handleReject = () => reject();

  const styles = StyleSheet.create({
    container: {
      margin: 20,
      maxHeight: 300,
      maxWidth: adjust(580),
      paddingHorizontal: isNativeOrPWA ? 40 : 70,
      paddingVertical: isNativeOrPWA ? 18 : 32,
      width: !isNativeMobile ? '100%' : 'auto',
      padding: isNativeOrPWA ? 30 : 10,
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      elevation: 1
    },
    texContainer: {
      marginVertical: 10
    },
    text: {
      fontSize: isNativeOrPWA ? adjust(15) : 16,
      lineHeight: isNativeOrPWA ? adjust(22) : 22,
      fontWeight: '400',
      color: 'white'
    },
    link: {
      fontSize: isNativeOrPWA ? adjust(16) : 17,
      color: '#00F0AB',
      fontWeight: 'bold',
      textDecorationLine: 'underline'
    },
    buttonContainer: {
      marginHorizontal: vw(0.5)
    },
    button: {
      paddingTop: vw(1),
      paddingHorizontal: 40,
      paddingBottom: vw(1.3),
      cursor: 'pointer'
    },
    textButton: {
      fontSize: isNativeOrPWA ? adjust(16) : 17,
      textTransform: 'uppercase',
      fontWeight: '700'
    },
    buttons: {
      flexDirection: 'row'
    }
  });

  return (
    <ImageBackground style={styles.container} source={irregularBlock2} resizeMode="stretch">
      <View style={styles.texContainer}>
        <Text style={styles.text}>
          <Text>
            Este sitio web utiliza cookies propias para identificar al usuario y para habilitar una experiencia de juego óptima. Igualmente,
            se usan cookies de terceros para analizar la navegación de los usuarios. Puedes pulsar “aceptar” para consentir el uso de las
            cookies o “rechazar” para rechazarlas. Puedes obtener más información y configurar tus preferencias{' '}
          </Text>
          <Pressable onPress={openCookiesURL}>
            <Text style={styles.link}>aquí</Text>
          </Pressable>
          <Text>.</Text>
        </Text>
      </View>
      <View style={styles.buttons}>
        <View style={styles.buttonContainer}>
          <Pressable onPress={handleAccept}>
            <ButtonShape bgColor="white" border heightBtn={isNativeOrPWA ? 34 : 56}>
              <View style={styles.button}>
                <Text style={styles.textButton}>Aceptar</Text>
              </View>
            </ButtonShape>
          </Pressable>
        </View>
        <Pressable onPress={handleReject}>
          <View style={styles.buttonContainer}>
            <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 34 : 56}>
              <View style={styles.button}>
                <Text style={[styles.textButton, { color: 'white' }]}>Rechazar</Text>
              </View>
            </ButtonShape>
          </View>
        </Pressable>
      </View>
    </ImageBackground>
  );
};

export default memo(CookiesBanner);
