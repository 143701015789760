import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import { useDispatch } from 'react-redux';
import { hideIsOnlineModal } from '../../../redux/Is-online/isOnline';
import { isNativeMobile, isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import ButtonShape from '../ButtonShape/ButtonShape';

const OfflineModalContent = () => {
  const { rh } = useRatioDimensions();
  const dispatch = useDispatch();

  const handleClose = () => dispatch(hideIsOnlineModal());

  const styles = StyleSheet.create({
    title: {
      color: '#FFF',
      fontSize: 32,
      fontWeight: '800'
    },
    explanation: {
      color: '#FFF',
      fontSize: 21,
      fontWeight: '500',

      textAlign: 'center'
    },
    buttonContainer: {
      width: isNativeMobile ? '50%' : '20%',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: rh(1),
      minWidth: !isNativeOrPWA ? 300 : 'auto',
      maxWidth: isNativeOrPWA ? 240 : 'auto'
    },
    buttonInner: {
      marginBottom: isNativeOrPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      width: !isNativeMobile ? '100%' : 'auto'
    }
  });

  return (
    <>
      <Text style={styles.title}>¡Ups!</Text>
      <Text style={[styles.explanation, { maxWidth: isNativeOrPWA ? '80%' : 512 }]}>
        {'Parece que no podemos conectar con nuestros servidores de verificación. Por favor, revisa tu conexión a internet'}
      </Text>
      <TouchableWithoutFeedback onPress={handleClose}>
        <View style={styles.buttonContainer}>
          <ButtonShape bgColor="gray" border heightBtn={isNativeOrPWA ? 42 : 56}>
            <View style={styles.buttonInner}>
              <Text style={styles.explanation}>Reintentar</Text>
            </View>
          </ButtonShape>
        </View>
      </TouchableWithoutFeedback>
    </>
  );
};

export default OfflineModalContent;
