import { useEffect, useState } from 'react';
import { Image, Text, StyleSheet, ImageBackground, TouchableWithoutFeedback } from 'react-native';
import useUnits from 'rxn-units';
import {
  avatarRewardProfile,
  cardRewardProfile,
  expRewardProfile,
  nextRewardProfile,
  nextRewardProfileMobile,
  titleRewardProfile
} from '../../constants/Images';
import { RewardTypeEnum } from '../dtos/reward.dto';
import adjust from '../styles/TextAdjust';
import { isNativeOrPWA } from '../utils';
import { View } from './Themed';
import ToolTip from './ToolTip';

type PosibleRewards = RewardTypeEnum | 'experience';

interface NextRewardProps {
  reward: PosibleRewards;
  short?: boolean;
}

interface RewardData {
  imageSource: any;
  text: string;
  textBold: string;
}

const posibleRewards: Record<PosibleRewards, RewardData> = {
  avatar: {
    imageSource: avatarRewardProfile,
    text: 'Nuevo',
    textBold: 'avatar'
  },
  card: {
    imageSource: cardRewardProfile,
    text: 'Nueva',
    textBold: 'carta'
  },
  cardPack: {
    imageSource: cardRewardProfile,
    text: 'Nuevo',
    textBold: 'pack'
  },
  title: {
    imageSource: titleRewardProfile,
    text: 'Nuevo',
    textBold: 'titulo'
  },
  experience: {
    imageSource: expRewardProfile,
    text: 'de energía',
    textBold: '+3 ptos'
  }
};

const NextReward = ({ reward, short = false }: NextRewardProps) => {
  const { vh } = useUnits();
  const { imageSource, text, textBold } = posibleRewards[reward];
  const [viewTooltip, setViewTooltip] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setViewTooltip(false), 1600);
    return () => clearTimeout(id);
  }, [viewTooltip]);

  const styles = StyleSheet.create({
    imageContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    rewardStyle: {
      marginVertical: short ? vh(2) : 0,
      marginRight: 16,
      paddingVertical: 8,
      paddingHorizontal: 12
    },
    mobileRewardStyle: {
      marginVertical: vh(2),
      paddingVertical: short ? 10 : 8,
      paddingHorizontal: 8,
      marginRight: short ? 10 : 0
    },
    iconImage: {
      width: short ? 18 : 24,
      height: short ? 18 : 24,
      marginLeft: short ? 2 : 0
    },
    text: {
      fontSize: isNativeOrPWA ? adjust(12) : 14,
      color: '#FFF',
      marginLeft: isNativeOrPWA ? 6 : 10,
      textTransform: short ? 'capitalize' : 'none'
    },
    textNormal: {
      fontWeight: '400'
    },
    textBold: {
      fontWeight: '800'
    },
    tooltipContainer: {
      top: '-125%'
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      padding: 5,
      flex: 1,
      maxWidth: 90,
      transform: [{ translateX: -25 }]
    },
    tooltipTip: {
      transform: [{ translateX: -45 }]
    },
    toolTipText: { color: 'black', fontWeight: 'bold', fontSize: 11 }
  });
  const rewardBg = short && isNativeOrPWA ? nextRewardProfileMobile : nextRewardProfile;

  return (
    <ImageBackground
      source={rewardBg}
      style={[styles.imageContainer, isNativeOrPWA ? styles.mobileRewardStyle : styles.rewardStyle]}
      resizeMode="stretch"
    >
      <TouchableWithoutFeedback style={styles.container} onPress={() => setViewTooltip(!viewTooltip)}>
        <View style={styles.container}>
          <Image source={imageSource} style={styles.iconImage} resizeMode="contain" />
          <Text style={styles.text}>
            {reward === 'experience' ? (
              <>
                <Text style={styles.textBold}>{`${textBold} `}</Text>
                <Text style={styles.textNormal}>{text}</Text>
              </>
            ) : (
              <>
                {!short && <Text style={styles.textNormal}>{`${text} `}</Text>}
                {(!short || (short && !isNativeOrPWA)) && <Text style={styles.textBold}>{textBold}</Text>}
              </>
            )}
          </Text>
          {short && isNativeOrPWA && viewTooltip && (
            <ToolTip
              animated={false}
              containerStyle={styles.tooltipContainer}
              tooltipStyle={styles.tooltip}
              tooltipTipStyle={styles.tooltipTip}
            >
              <Text style={styles.toolTipText}>{`${text} ${textBold}`}</Text>
            </ToolTip>
          )}
        </View>
      </TouchableWithoutFeedback>
    </ImageBackground>
  );
};

export default NextReward;
