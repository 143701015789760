import { useState, useEffect } from 'react';
import { Dimensions, LayoutChangeEvent } from 'react-native';
import { disableAspectRatio, enableAspectRatio } from '../../../redux/AspectRatio/aspectRatio';
import store from '../../../redux/store';
import { deviceWidth, deviceHeight } from '../../styles/TextAdjust';

export const useAspectRatio = (aspectRatio: number) => {
  const [viewWidth, setViewWidth] = useState<number>(deviceWidth);
  const [viewHeight, setViewHeight] = useState<number>(deviceHeight);

  const calculatedDimensions = (width: number, height: number) => {
    const calculatedWidth = height * aspectRatio;
    const calculatedHeight = width / aspectRatio;
    if (calculatedHeight <= height) {
      setViewWidth(width);
      setViewHeight(calculatedHeight);
    } else {
      setViewWidth(calculatedWidth);
      setViewHeight(height);
    }
  };

  useEffect(() => {
    const handleDimensionsChange = (newDimensions: { window: { width: number; height: number } }) => {
      const { width, height } = newDimensions.window;
      calculatedDimensions(width, height);
    };
    const dimensionsHandler = Dimensions.addEventListener('change', handleDimensionsChange);
    return () => dimensionsHandler.remove();
  }, [aspectRatio]);

  const onLayout = (event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;
    calculatedDimensions(width, height);
  };

  useEffect(() => {
    store.dispatch(enableAspectRatio({ viewHeight, viewWidth }));

    return () => {
      store.dispatch(disableAspectRatio());
    };
  }, [viewHeight, viewWidth]);

  return {
    viewWidth,
    viewHeight,
    onLayout
  };
};
