import { ImageBackground, StyleSheet, View, ViewStyle, Text, Image } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { battlePointsContainer, deadCardIcon } from '../../../constants/Images';

interface AttackedItemProps {
  parentStyle: ViewStyle[];
  condition: boolean;
  textContent: number;
}

const AttackedItem = ({ condition, textContent, parentStyle }: AttackedItemProps) => {
  const { rw } = useRatioDimensions();
  const styles = StyleSheet.create({
    battlePointsBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    pointsText: {
      position: 'absolute',
      fontSize: rw(2),
      color: '#FFFFFF',
      fontFamily: 'Inlanders',
      textAlign: 'center',
      textAlignVertical: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 200
    },
    deadCardIcon: { position: 'absolute', width: '60%', height: '60%', zIndex: 200 }
  });
  return (
    <View style={parentStyle}>
      <ImageBackground style={styles.battlePointsBackground} source={battlePointsContainer} />
      {condition ? (
        <Text style={styles.pointsText}>{textContent}</Text>
      ) : (
        <Image style={styles.deadCardIcon} source={deadCardIcon} resizeMode="contain" />
      )}
    </View>
  );
};

export default AttackedItem;
