import { Image, ImageBackground, Platform, StyleSheet } from 'react-native';
import SelectDropdown from 'react-native-select-dropdown';
import { View } from '../../../commons/components/Themed';
import { isNativeOrPWA } from '../../../commons/utils';
import { angleLoginDate, borderDate } from '../../../constants/Images';
import {
  pickerTextStyle,
  pickerRowStyle,
  pickerTextRowStyle,
  pickerSelectedRowStyle,
  pickerSelectedTextRowStyle,
  pickerStyleExtended
} from './LogindropDownStyles';

interface LoginScreenDateProps {
  setDay: Function;
  setMonth: Function;
  setYear: Function;
  day: number | null;
  month: number | null;
  year: number | null;
}

const LoginScreenDate = ({ setDay, setMonth, setYear, day, month, year }: LoginScreenDateProps) => {
  const range = (startAt = 0, size: number) => {
    return [...Array(size).keys()].map(i => {
      if (i + startAt > 9) {
        return i + startAt;
      } else {
        return '0' + (i + startAt);
      }
    });
  };
  const currentYear = new Date().getFullYear();
  const daysRange = range(1, 31);
  const monthsRange = range(1, 12);
  const startYear = currentYear - 100;
  const endYear = currentYear - startYear;
  const yearsRange = range(startYear, endYear).reverse();

  const styles = StyleSheet.create({
    form: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%'
    },
    borderImage: {
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: isNativeOrPWA ? 0 : 20
    },
    borderButton: {
      maxHeight: isNativeOrPWA ? 35 : 60,
      marginHorizontal: '2%',
      minWidth: isNativeOrPWA ? 65 : 130,
      maxWidth: 150,
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 75,
      width: '30%',
      height: '100%',
      minHeight: isNativeOrPWA ? 30 : 50
    },
    angleImage: {
      width: isNativeOrPWA ? 12 : 15,
      height: isNativeOrPWA ? 12 : 15
    }
  });

  return (
    <View accessible accessibilityLabel={'Select your born date'} style={styles.form}>
      <View style={styles.borderButton}>
        <ImageBackground style={styles.borderImage} resizeMode="stretch" source={borderDate}>
          <SelectDropdown
            buttonStyle={pickerStyleExtended}
            defaultButtonText={day?.toString() || 'Día'}
            buttonTextStyle={pickerTextStyle}
            rowStyle={pickerRowStyle}
            rowTextStyle={pickerTextRowStyle}
            selectedRowStyle={pickerSelectedRowStyle}
            selectedRowTextStyle={pickerSelectedTextRowStyle}
            data={daysRange}
            onSelect={selectedItem => {
              setDay(selectedItem);
            }}
            buttonTextAfterSelection={selectedItem => {
              return selectedItem;
            }}
            rowTextForSelection={item => {
              return item;
            }}
            dropdownIconPosition="right"
            renderDropdownIcon={() =>
              Platform.OS === 'web' && <Image resizeMode="contain" source={angleLoginDate} style={styles.angleImage} />
            }
          />
        </ImageBackground>
      </View>
      <View style={styles.borderButton}>
        <ImageBackground style={styles.borderImage} resizeMode="stretch" source={borderDate}>
          <SelectDropdown
            buttonStyle={pickerStyleExtended}
            defaultButtonText={month?.toString() || 'Mes'}
            buttonTextStyle={pickerTextStyle}
            rowStyle={pickerRowStyle}
            rowTextStyle={pickerTextRowStyle}
            selectedRowStyle={pickerSelectedRowStyle}
            selectedRowTextStyle={pickerSelectedTextRowStyle}
            data={monthsRange}
            onSelect={selectedItem => {
              setMonth(selectedItem);
            }}
            buttonTextAfterSelection={selectedItem => {
              return selectedItem;
            }}
            rowTextForSelection={item => {
              return item;
            }}
            dropdownIconPosition="right"
            renderDropdownIcon={() =>
              Platform.OS === 'web' && <Image resizeMode="contain" source={angleLoginDate} style={styles.angleImage} />
            }
          />
        </ImageBackground>
      </View>
      <View style={styles.borderButton}>
        <ImageBackground style={styles.borderImage} resizeMode="stretch" source={borderDate}>
          <SelectDropdown
            buttonStyle={pickerStyleExtended}
            defaultButtonText={year?.toString() || 'Año'}
            buttonTextStyle={pickerTextStyle}
            rowStyle={pickerRowStyle}
            rowTextStyle={pickerTextRowStyle}
            selectedRowStyle={pickerSelectedRowStyle}
            selectedRowTextStyle={pickerSelectedTextRowStyle}
            data={yearsRange}
            onSelect={selectedItem => {
              setYear(selectedItem);
            }}
            buttonTextAfterSelection={selectedItem => {
              return selectedItem;
            }}
            rowTextForSelection={item => {
              return item;
            }}
            dropdownIconPosition="right"
            renderDropdownIcon={() =>
              Platform.OS === 'web' && <Image resizeMode="contain" source={angleLoginDate} style={styles.angleImage} />
            }
          />
        </ImageBackground>
      </View>
    </View>
  );
};

export default LoginScreenDate;
