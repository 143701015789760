import { createSlice } from '@reduxjs/toolkit';
import { Player } from '../../commons/dtos/player.dto';

export interface Profile {
  player?: Player;
}

const initialState: Profile = {
  player: undefined
};

const profileSlice = createSlice({
  name: 'player-profile',
  initialState,
  reducers: {
    setProfile(state, action: { payload: Player; type: string }) {
      return { ...state, player: action.payload };
    },
    clearProfile() {
      return initialState;
    }
  }
});

export const { setProfile, clearProfile } = profileSlice.actions;

export default profileSlice;
