import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useSelector, shallowEqual } from 'react-redux';
import { authProps } from '../../../redux/Auth/authRedux';
import { RootStackParamList } from '../../../types';

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Login', undefined>;

export const useExternalLinks = () => {
  const navigation = useNavigation<Navigation>();

  const { isAuthorized } = useSelector(
    ({ auth }: authProps) => ({
      isAuthorized: auth.idToken != null
    }),
    shallowEqual
  );

  const openHome = () => navigation.navigate('Home');

  const openLanding = () => navigation.navigate('Landing');

  const openLogin = () => navigation.navigate('Login');

  const openRanking = () => navigation.navigate('Ranking');

  const openHow = () => navigation.navigate('HowToPlay');

  return {
    isAuthorized,
    openHow,
    openRanking,
    openLogin,
    openLanding,
    openHome
  };
};
