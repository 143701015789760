import { ImageBackground, View, Image, StyleSheet } from 'react-native';
import useUnits from 'rxn-units';
import LegalFooter from '../../commons/components/LegalFooter';
import { isNativeOrPWA } from '../../commons/utils';
import { GameLogo, LoginBackgroundImage, LoginFormBackgroundImage, catLogin, errorBot, familyLogin } from '../../constants/Images';
import { useLogin } from '../../hooks/useLogin';
import { LoginUpdateProps } from '../../types';
import LoginContinue from './LoginForm/LoginContinue';

const LoginUpdate = ({ route }: LoginUpdateProps) => {
  const { vh, vw } = useUnits();

  const { params } = route;
  const { player } = params;

  const { backStep, error, handleError, handleMessage, nextStep, step } = useLogin({ update: true });
  const styles = StyleSheet.create({
    formBackground: {
      width: '100%',
      height: '100%',
      position: 'absolute'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '95%',
      height: '90%',
      alignItems: 'center',
      overflow: 'hidden',
      paddingVertical: isNativeOrPWA ? 6 : 20
    },
    login: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      position: 'relative',
      flex: 1,
      maxHeight: isNativeOrPWA ? vh(84) : vh(92),
      width: isNativeOrPWA ? vh(75) : vh(80),
      minWidth: 300
    },
    background: {
      width: '100%',
      height: '100%',
      position: 'absolute'
    },
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      height: '100%'
    },
    familyImage: {
      maxWidth: 631,
      maxHeight: 890,
      position: 'absolute',
      left: -20,
      bottom: isNativeOrPWA ? -40 : -90,
      zIndex: 20,
      width: vh(68),
      height: vh(68) * 1.5,
      transform: [{ translateX: vh(68) > 631 ? -631 / 1.6 : -vh(68) / 1.4 }]
    },
    catImage: {
      maxWidth: 346,
      maxHeight: 402,
      position: 'absolute',
      bottom: isNativeOrPWA ? -15 : -40,
      right: 0,
      zIndex: 20,
      height: vh(45),
      width: vh(45) * 1.15,
      transform: [{ translateX: vh(45) > 346 ? 346 / 1.5 : vh(45) / 1.3 }]
    },
    gameLogo: {
      display: 'flex',
      position: 'absolute',
      top: isNativeOrPWA ? 10 : 30,
      left: isNativeOrPWA ? 18 : 60,
      maxHeight: isNativeOrPWA ? 66 : 194,
      maxWidth: isNativeOrPWA ? 145 : 426,
      width: vw(8) * 2.2,
      height: vw(8),
      zIndex: 10000
    },
    imageContainerCat: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      height: '100%'
    },
    imageContainerFamily: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      height: '100%',
      position: 'absolute',
      left: 0,
      bottom: 0
    },
    containerMainContent: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    footerContainer: {
      alignItems: 'center',
      flexDirection: 'row'
    }
  });
  return (
    <View style={styles.container}>
      <ImageBackground style={styles.background} source={LoginBackgroundImage} resizeMode="cover" />
      <Image source={GameLogo} accessible accessibilityLabel="Game logo" style={styles.gameLogo} />
      <View style={styles.containerMainContent}>
        <View style={styles.login}>
          <ImageBackground style={styles.formBackground} source={LoginFormBackgroundImage} resizeMode={'stretch'} />
          <View style={styles.form}>
            <LoginContinue
              nextStep={nextStep}
              backStep={backStep}
              setMessage={handleMessage}
              step={step}
              error={error}
              setError={handleError}
              player={player}
            />
          </View>

          <View style={styles.imageContainerCat} pointerEvents="none">
            {error ? (
              <Image style={styles.catImage} resizeMode="contain" source={errorBot} accessible accessibilityLabel="bot image" />
            ) : (
              <Image style={styles.catImage} source={catLogin} resizeMode="contain" accessible accessibilityLabel="cat image" />
            )}
          </View>
          <View style={styles.imageContainerFamily} pointerEvents="none">
            <Image style={styles.familyImage} source={familyLogin} resizeMode="contain" accessible accessibilityLabel="family image" />
          </View>
        </View>
      </View>
      <View style={styles.footerContainer}>
        <LegalFooter />
      </View>
    </View>
  );
};

export default LoginUpdate;
