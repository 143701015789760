import { memo } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { irregularModalContainer } from '../../../constants/Images';
import { isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import LoginModalContent from './LoginModalContent';

const LoginModal = () => {
  const { rw, rh } = useRatioDimensions();
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000,
      backgroundColor: isNativeOrPWA ? '#494949' : 'rgba(0, 0, 0, 0.5)'
    },
    modal: {
      width: rw(isNativeOrPWA ? 80 : 40),
      minHeight: isNativeOrPWA ? '90%' : 512,
      minWidth: isNativeOrPWA ? 320 : 812,
      height: isNativeOrPWA ? rh(80) : rw(32) / 1.32
    },
    image: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingVertical: isNativeOrPWA ? 0 : 64
    },
    mobileWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around'
    }
  });

  return (
    <View style={styles.container}>
      <View style={styles.modal}>
        {isNativeOrPWA ? (
          <View style={styles.mobileWrapper}>
            <LoginModalContent />
          </View>
        ) : (
          <ImageBackground source={irregularModalContainer} resizeMode="stretch" style={styles.image}>
            <LoginModalContent />
          </ImageBackground>
        )}
      </View>
    </View>
  );
};

export default memo(LoginModal);
