import { View, Text } from 'react-native';
import ButtonShape from '../../commons/components/ButtonShape/ButtonShape';
import GradientContent from '../../commons/components/GradientContent/GradientContent';
import { useLeagalPrivacyStyles } from '../../commons/components/LeagalPrivacy/useLeagalPrivacyStyles';
import { isNativeOrPWA } from '../../commons/utils';

interface PrivacyPolicyTextProps {
  isModal?: boolean;
}

const PrivacyPolicyText = ({ isModal = false }: PrivacyPolicyTextProps) => {
  const styles = useLeagalPrivacyStyles(isModal);
  const colorsModalMobile = ['#494949', 'transparent', 'transparent', '#494949'];
  const colorsModalWeb = ['#424242', 'transparent', 'transparent', '#424242'];
  const colorsScreen = ['#313131', 'transparent', 'transparent', '#313131'];

  return (
    <GradientContent
      colors={isModal ? (isNativeOrPWA ? colorsModalMobile : colorsModalWeb) : colorsScreen}
      parentStyle={[styles.mainContent, styles.relative, styles.center]}
      scrollViewStyle={styles.textContainer}
      locations={[0, 0.2, 0.8, 1]}
    >
      <>
        <View style={[styles.mainContent, styles.paddingStartText]}>
          <View style={styles.button} pointerEvents="none">
            <ButtonShape bgColor="black" heightBtn={isNativeOrPWA ? 34 : 50}>
              <Text style={styles.buttonColor}>Politica de Privacidad</Text>
            </ButtonShape>
          </View>
        </View>
        <View>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>La presente política de privacidad (en adelante,</Text>
            <Text style={styles.boldText}>“Política de Privacidad”</Text>
            <Text>) ha sido redactada por Newtral Media Audiovisual, S. L. (en adelante</Text>
            <Text style={styles.boldText}>“NEWTRAL”</Text>
            <Text>o el</Text>
            <Text style={styles.boldText}>“Responsable del Tratamiento”</Text>
            <Text>
              ) con el objeto de dar cumplimiento al deber de transparencia en el tratamiento de los datos personales que pueda llevar a
              cabo en calidad de responsable del tratamiento, conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo
              y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de
              datos personales y a la libre circulación de estos datos (en adelante,
            </Text>
            <Text style={styles.boldText}>“RGPD”</Text>
            <Text>).</Text>
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            <Text>
              La información contenida en esta Política de Privacidad permite consultar el detalle de los distintos tratamientos de tus
              datos personales que pueden realizarse como consecuencia del acceso y uso del sitio web www.fakesover.com (en adelante,
            </Text>
            <Text style={styles.boldText}>“Sitio web”</Text>
            <Text>), así como de las distintas relaciones, jurídicas o comerciales, que puedes mantener con NEWTRAL.</Text>
          </Text>
        </View>
        <View>
          <Text style={[styles.text, styles.title, styles.paragraph]}>NECESIDAD DE FACILITAR DATOS PERSONALES</Text>
          <Text style={[styles.paragraph, styles.text]}>
            Te informamos de que el mero acceso al Sitio web no implica la obligación de que facilites ninguna información personal, sin
            perjuicio de lo dispuesto en la Política de Cookies.
          </Text>
          <Text style={[styles.paragraph, styles.text]}>
            Sin embargo, la utilización de algunos servicios o funcionalidades del Sitio web sí requieren que facilites determinados datos
            personales, indicados en los formularios o modelos de contrato correspondientes, e implica su tratamiento con las finalidades y
            las bases de legitimación que se indican en esta Política de Privacidad.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            La negativa a proporcionar los datos requeridos en estos casos puede suponer la imposibilidad de prestarte algunos servicios o
            funcionalidades, especialmente en aquellos supuestos en que consten identificados como de cumplimentación obligatoria.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Como titular de los datos personales, serás responsable de que los datos que nos facilites a través del Sitio web sean
            verdaderos, exactos, completos y actualizados. A estos efectos, responderás de la veracidad de todos los datos que comuniques y
            deberás mantener debidamente actualizada la información facilitada, de tal forma que responda a tu situación real.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Igualmente, serás responsable de las informaciones falsas o inexactas que proporciones y de los daños y perjuicios, directos o
            indirectos, que ello cause al Responsable del Tratamiento o a terceros.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            En ningún caso podrán incluirse en el formulario datos personales correspondientes a terceras personas, salvo que hayas recabado
            con carácter previo su consentimiento, respondiendo personalmente del incumplimiento de esta obligación. Se te podrá requerir la
            documentación justificativa de esta autorización en cualquier momento.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>RESPONSABLE DEL TRATAMIENTO</Text>
          <View style={styles.listMargin}>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Titular: </Text>
              <Text>Newtral Media Audiovisual, S. L. U.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Domicilio social: </Text>
              <Text> Calle Vandergoten 1, 28014 – Madrid.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} NIF: </Text>
              <Text> B-87938304.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} Registro Público: </Text>
              <Text>Registro Mercantil de Madrid, al tomo 36538, folio 1, hoja M656277.</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.boldText}>{'\u2022'} E-mail: </Text>
              <Text style={styles.url}>info@newtral.es </Text>
            </Text>
          </View>
          <Text style={[styles.text, styles.paragraph]}>
            A continuación, te informamos del detalle de los tratamientos de datos personales que se pueden llevar a cabo en NEWTRAL.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            <Text>
              En cualquier caso, puedes solicitar más información sobre los tratamientos realizados escribiendo al responsable en materia de
              protección de datos de NEWTRAL en el correo electrónico{' '}
            </Text>
            <Text style={styles.url}>info@newtral.es</Text>
            <Text>, encargado de velar por el leal y transparente tratamiento de tus datos personales.</Text>
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title, styles.paragraph]}>FINALIDADES Y BASES DE LEGITIMACIÓN DEL TRATAMIENTO </Text>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>SERVICIO DE ATENCIÓN AL USUARIO</Text>
            <Text style={[styles.text, styles.paragraph]}>
              En el caso de que facilites tus datos personales mediante el formulario, teléfono o correo electrónico de contacto puesto a tu
              disposición en el Sitio web, tus datos serán tratados con la finalidad de gestionar tus solicitudes de información, resolver
              tus dudas, contestar a tus consultas o tramitar tus reclamaciones.
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              La legitimación del tratamiento es el consentimiento, prestado de forma expresa al completar el formulario de contacto, al
              llamarnos o escribirnos. De este modo, el tratamiento es necesario para permitir la adecuada tramitación de tus solicitudes de
              información, resolución de tus dudas, contestación a tus consultas o la tramitación de tus reclamaciones comunicadas y
              verificar el funcionamiento del servicio conforme a los procedimientos establecidos, así como para obtener una evidencia del
              contenido de las peticiones recibidas y del motivo del tratamiento de los datos personales que puedas facilitar.{' '}
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text>Tienes derecho a retirar en cualquier momento el consentimiento enviando un correo electrónico a </Text>
              <Text style={styles.url}>info@newtral.es</Text>
              <Text>
                . Por último, te informamos que la retirada del consentimiento no afectará a la licitud del tratamiento basada en el
                consentimiento previo a su retirada.
              </Text>
            </Text>
          </View>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>GESTIÓN DE LA PARTICIPACIÓN EN FAKESOVER</Text>
            <Text style={[styles.text, styles.paragraph]}>
              En el caso de que desees participar en el juego de cartas coleccionables digital FakesOver, a través del formulario de alta,
              tus datos personales serán tratados con la finalidad de formalizar, gestionar, mantener, desarrollar, supervisar y controlar
              la relación establecida entre el interesado y NEWTRAL de acuerdo con los Términos y Condiciones aceptados, incluyendo los
              siguientes tratamientos como parte integrante y necesaria de la relación contractual:
            </Text>
            <View>
              <Text style={[styles.text, styles.listRow]}>
                <Text>-</Text>
                <Text style={styles.listMargin}>Gestionar el alta-registro, mantenimiento y baja de la cuenta.</Text>
              </Text>
              <Text style={[styles.text, styles.listRow]}>
                <Text>-</Text>
                <Text style={styles.listMargin}>Gestionar las incidencias técnicas que puedas tener.</Text>
              </Text>
              <Text style={[styles.text, styles.listRow]}>
                <Text>-</Text>
                <Text style={styles.listMargin}>Gestionar las comunicaciones relacionadas con el propio juego y el Sitio web.</Text>
              </Text>
              <Text style={[styles.text, styles.listRow]}>
                <Text>-</Text>
                <Text style={styles.listMargin}>
                  Gestionar la publicación de la información de los participantes en el ranking situado en la web de Newtral.
                </Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.paragraph]}>
              La legitimación de los tratamientos descritos es la a la ejecución de un contrato derivado de la aceptación de los Términos y
              condiciones de participación.
            </Text>
          </View>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>ESTADÍSTICAS DEL SITIO WEB</Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text>
                Cuando otorgues tu consentimiento para el tratamiento de tus datos facilitados a través de la instalación de dispositivos de
                almacenamiento y recuperación de datos (en adelante,
              </Text>
              <Text style={styles.boldText}>“Cookies”</Text>
              <Text>
                ) con fines estadísticos cuando accedas al Sitio Web, o bien cuando indiques el tramo de edad en el que te encuentras, esta
                información será utilizada para realizar informes de datos agregados, con fines estadísticos y analíticos por parte de
                NEWTRAL. Respecto del uso de Cookies, puedes obtener más información en la Política de Cookies.
              </Text>
            </Text>
            <Text style={[styles.text, styles.paragraph]}>
              <Text>
                La legitimación de los tratamientos descritos es el consentimiento, que tienes derecho a retirar en cualquier momento
                enviando un correo electrónico a
              </Text>
              <Text style={styles.boldText}>info@newtral.es</Text>
              <Text>
                . Por último, te informamos que la retirada del consentimiento no afectará a la licitud del tratamiento basada en el
                consentimiento previo a su retirada.
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title]}>TIPOS DE DATOS PERSONALES TRATADOS</Text>
          <Text style={[styles.text, styles.paragraph]}>
            La utilización de algunos servicios o funcionalidades del Sitio web requieren que facilites determinados datos personales,
            indicados en los formularios correspondientes, e implica su tratamiento con las finalidades que se indican en cada caso.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            En cada formulario se te indicará cuáles son los campos obligatorios. La negativa a proporcionar estos datos supone la
            imposibilidad de tramitar las solicitudes realizadas.{' '}
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Por regla general las categorías de datos objeto de tratamiento serán las siguientes:
          </Text>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>SERVICIO DE ATENCIÓN AL USUARIO</Text>
            <Text style={[styles.text, styles.paragraph]}>
              Los datos tratados como consecuencia de ponerte en contacto con NEWTRAL son:
            </Text>
            <View style={styles.paragraph}>
              <Text style={styles.text}>{'\u2022'} Datos identificativos: nombre.</Text>
              <Text style={styles.text}>{'\u2022'} Datos de contacto: dirección de correo electrónico o teléfono.</Text>
              <Text style={styles.text}>{'\u2022'} Otros datos (en su caso): asunto y mensaje.</Text>
            </View>
          </View>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>GESTIÓN DE LA PARTICIPACIÓN EN FAKESOVER</Text>
            <Text style={[styles.text, styles.paragraph]}>
              El registro en el Sitio web debe realizarse en todo caso a través de Google, para lo cual el usuario deberá solicitar a Google
              que nos facilite la siguiente información, a través del botón que en el formulario de contacto se habilitará a tal efecto:
            </Text>
            <View style={styles.listMargin}>
              <Text style={styles.text}>{'\u2022'} Nombre y apellido.</Text>
              <Text style={styles.text}>{'\u2022'} Datos de contacto: dirección de correo electrónico.</Text>
            </View>
            <Text style={[styles.text, styles.paragraph]}>
              Los datos facilitados directamente por el usuario en el formulario de registro son:
            </Text>
            <View style={styles.listMargin}>
              <Text style={styles.text}>{'\u2022'} Nickname</Text>
              <Text style={styles.text}>{'\u2022'} Imagen de avatar.</Text>
            </View>
            <Text style={[styles.text, styles.paragraph]}>Otros datos que el usuario facilitará durante su uso del Sitio web son:</Text>
            <View style={styles.listMargin}>
              <Text style={styles.text}>
                {'\u2022'} Datos derivados del registro: imagen de avatar, progreso en los niveles, posición en el ranking.
              </Text>
            </View>
          </View>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>ESTADÍSTICAS DEL SITIO WEB</Text>
            <Text style={[styles.text, styles.paragraph]}>Los datos obtenidos a través de Cookies son los siguientes:</Text>
            <View style={styles.listMargin}>
              <Text style={styles.text}>{'\u2022'} Identificadores online y dirección IP.</Text>
              <Text style={styles.text}>
                {'\u2022'} Datos de navegación (modo de acceso al Sitio Web, páginas visitadas y tiempo transcurrido en cada visita, clics,
                etc.).
              </Text>
            </View>
            <Text style={[styles.text, styles.paragraph]}>
              Los datos recogidos a través de cuestionarios o formularios serán los siguientes:
            </Text>
            <View style={styles.listMargin}>
              <Text style={styles.text}>{'\u2022'} Datos relativos a características personales: rango de edad.</Text>
            </View>
          </View>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title]}>TIEMPO DE CONSERVACIÓN DE LOS DATOS PERSONALES</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Los datos personales objeto de tratamiento serán conservados durante el tiempo necesario para dar cumplimiento a las finalidades
            informadas, conforme a lo indicado a continuación.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            En caso de supresión, el Responsable del tratamiento mantendrá tus datos bloqueados hasta que prescriban las eventuales
            responsabilidades derivadas de su tratamiento, tras lo cual se eliminarán definitivamente.
          </Text>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>SERVICIO DE ATENCIÓN AL USUARIO</Text>
            <Text style={[styles.text, styles.paragraph]}>
              Los datos personales facilitados mediante el formulario de contacto serán conservados durante el tiempo necesario para la
              adecuada gestión de tu solicitud de información, resolución de tus dudas, contestación a tus consultas o la tramitación de tu
              reclamación, o hasta que retires tu consentimiento.
            </Text>
          </View>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>GESTIÓN DE LA PARTICIPACIÓN EN FAKESOVER</Text>
            <Text style={[styles.text, styles.paragraph]}>
              Los datos personales facilitados durante la participación en FakesOver serán conservados hasta que solicites la baja.
              Posteriormente, mantendremos tus datos bloqueados durante el plazo de prescripción de las acciones legales correspondientes,
              salvo que autorices su tratamiento por un plazo superior.
            </Text>
          </View>
          <View style={styles.listMargin}>
            <Text style={[styles.text, styles.title2, styles.marginTitle]}>ESTADÍSTICAS DEL SITIO WEB</Text>
            <Text style={[styles.text, styles.paragraph]}>
              Los datos relativos a las Cookies serán conservados de acuerdo con la Política de Cookies, mientras que los datos sobre tu
              rango de edad serán conservados mientras permanezcas dado de alto en el Sitio web o hasta que retires tu consentimiento.
            </Text>
          </View>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title]}>DESTINATARIOS DE LOS DATOS</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Por regla general, los datos personales tratados no serán comunicados a terceros, salvo en el supuesto de terceros implicados en
            la prestación de los servicios y en la medida en la que dicha comunicación sea necesaria para el cumplimiento de las finalidades
            anteriormente indicadas o, en su caso, a las Organismos y Autoridades Públicas pertinentes, cuando sea legalmente obligatorio.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Cuando resulte indispensable contratar a un tercero, NEWTRAL velará porque cumplan con la normativa de aplicación y, en el
            supuesto de ser necesario, firmará el correspondiente contrato de encargo de tratamiento.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title]}>TRANSFERENCIAS INTERNACIONALES </Text>
          <Text style={[styles.text, styles.paragraph]}>
            No está prevista la comunicación de tus datos personales a destinatarios ubicados en países fuera del Espacio Económico Europeo.
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            En el caso de que la intervención de alguno de los proveedores del Responsable del tratamiento implicados en la prestación de
            los servicios pueda suponer una transferencia internacional de datos, la misma será adecuadamente regularizada e informada en la
            presente Política de Privacidad, adoptando las garantías adecuadas para su realización.
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title]}>DERECHOS DEL TITULAR DE LOS DATOS </Text>
          <Text style={[styles.text, styles.paragraph]}>
            Como titular de los datos personales, tienes derecho a dirigirte, en cualquier momento y de forma gratuita, al Responsable del
            tratamiento, mediante escrito remitido a las direcciones indicadas en esta Política de Privacidad, para ejercer los siguientes
            derechos:
          </Text>
          <View>
            <View style={styles.listMargin}>
              <Text style={[styles.marginLeft, styles.text]}>
                <Text>
                  {'\u2022'}
                  {'    '}
                </Text>
                <Text style={styles.underline}>Derecho de Acceso</Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.listMargin, styles.paragraph]}>
              Tienes derecho a que el Responsable del Tratamiento te informe sobre si está tratando o no tus datos personales y, en tal
              caso, poder acceder a dichos datos y recibir información sobre los fines para los que son tratados, las categorías de datos
              afectados por el tratamiento, los destinatarios a los que se comunicaron tus datos personales y el plazo previsto de
              conservación de los datos, entre otra información.
            </Text>
            <View style={styles.listMargin}>
              <Text style={[styles.marginLeft, styles.text]}>
                <Text>
                  {'\u2022'}
                  {'    '}
                </Text>
                <Text style={styles.underline}>Derecho de Rectificación</Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.listMargin, styles.paragraph]}>
              En cualquier momento, podrás solicitar al Responsable del Tratamiento la rectificación, sin dilación indebida, de los datos
              personales inexactos que te conciernan, así como a que se completen los datos objeto de tratamiento.
            </Text>
            <View style={styles.listMargin}>
              <Text style={[styles.marginLeft, styles.text]}>
                <Text>
                  {'\u2022'}
                  {'    '}
                </Text>
                <Text style={styles.underline}>Derecho de Revocación del consentimiento</Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.listMargin, styles.paragraph]}>
              Podrás revocar los consentimientos otorgados en cualquier momento respecto a los tratamientos de “Gestión de la participación
              en FakesOver” y “Estadísticas del sitio web” sin necesidad de justificación alguna. La revocación no afectará a la licitud de
              los tratamientos efectuados con anterioridad.
            </Text>
            <View style={styles.listMargin}>
              <Text style={[styles.marginLeft, styles.text]}>
                <Text>
                  {'\u2022'}
                  {'    '}
                </Text>
                <Text style={styles.underline}>Derecho de oposición total o parcial al tratamiento</Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.listMargin, styles.paragraph]}>
              Tienes derecho a oponerte al tratamiento de tus datos personales en determinadas circunstancias y por motivos relacionados con
              su situación particular. En estos casos, el Responsable del Tratamiento dejará de tratar los datos personales, salvo que
              acredite motivos legítimos para el tratamiento que prevalezcan sobre tus intereses, derechos y libertades, o para la
              formulación, el ejercicio o la defensa de reclamaciones.
            </Text>
            <View style={styles.listMargin}>
              <Text style={[styles.marginLeft, styles.text]}>
                <Text>
                  {'\u2022'}
                  {'    '}
                </Text>
                <Text style={styles.underline}>Derecho de portabilidad de tus datos</Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.listMargin, styles.paragraph]}>
              Tienes derecho a recibir los datos personales que hayas facilitado al Responsable del Tratamiento en un formato estructurado,
              de uso común y de lectura mecánica, y a poder transmitirlos a otro responsable del tratamiento sin que el responsable al que
              se los hubieras facilitado te lo impida, en los supuestos legalmente previstos a estos efectos.
            </Text>
            <View style={styles.listMargin}>
              <Text style={[styles.marginLeft, styles.text]}>
                <Text>
                  {'\u2022'}
                  {'    '}
                </Text>
                <Text style={styles.underline}>Derecho de limitación del tratamiento</Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.listMargin, styles.paragraph]}>
              En determinadas circunstancias (por ejemplo, en caso de que impugnes la exactitud de tus datos, mientras se verifica la
              exactitud de estos), puedes solicitar que se limite el tratamiento de tus datos personales, siendo estos únicamente tratados
              para el ejercicio o la defensa de reclamaciones.
            </Text>
            <View style={styles.listMargin}>
              <Text style={[styles.marginLeft, styles.text]}>
                <Text>
                  {'\u2022'}
                  {'    '}
                </Text>
                <Text style={styles.underline}>Derecho de Supresión</Text>
              </Text>
            </View>
            <Text style={[styles.text, styles.listMargin, styles.paragraph]}>
              Tienes derecho a solicitar la supresión de tus datos personales siempre que se cumplan los requisitos legales de aplicación,
              entre otros motivos, que éstos ya no sean necesarios para los fines para los que fueron recogidos.
            </Text>
          </View>
          <Text style={[styles.text, styles.paragraph]}>
            <Text>
              En cualquier caso, para cualquier duda o cuestión adicional sobre el ejercicio de tus derechos o, en general, sobre el
              tratamiento de tus datos personales, puedes contactar con nuestro responsable en materia de Protección de Datos en{' '}
            </Text>
            <Text style={styles.url}>info@newtral.es</Text>
            <Text>.</Text>
          </Text>
          <Text style={[styles.text, styles.paragraph]}>
            <Text>
              Adicionalmente, en el caso de que entiendas vulnerado cualquiera de tus derechos en materia de protección de datos personales
              puedes presentar una reclamación ante la Agencia Española de Protección de Datos (
            </Text>
            <Text style={styles.url}>www.aepd.es</Text>
            <Text>).</Text>
          </Text>
        </View>
        <View style={styles.marginTop}>
          <Text style={[styles.text, styles.title]}>MEDIDAS DE SEGURIDAD</Text>
          <Text style={[styles.text, styles.paragraph]}>
            Tus datos personales serán tratados de forma absolutamente confidencial y guardando el preceptivo deber de secreto respecto de
            los mismos, de conformidad con lo previsto en la normativa de aplicación, adoptando al efecto las medidas de índole técnica y
            organizativas necesarias que garanticen la seguridad de los datos y eviten su alteración, pérdida, tratamiento o acceso no
            autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están
            expuestos.
          </Text>
        </View>
        <View style={[styles.marginTop, styles.paddingEndText]}>
          <Text style={[styles.text, styles.title]}>ACTUALIZACIÓN DE LA POLÍTICA DE PRIVACIDAD</Text>
          <Text style={[styles.text, styles.paragraph]}>
            El Responsable del tratamiento se reserva el derecho a modificar la presente Política de Privacidad a fin de adaptarla a sus
            necesidades o las novedades legislativas que fueran procedentes. En dichos supuestos, anunciará los cambios introducidos y los
            publicará en este Sitio web, de modo que puedas acceder a ellos en todo momento y de forma sencilla.
          </Text>
        </View>
      </>
    </GradientContent>
  );
};

export default PrivacyPolicyText;
