import { useState, useEffect, useRef } from 'react';
import { Linking, StyleSheet, TouchableOpacity, View, Text, ImageBackground, Image } from 'react-native';
import ButtonShape from '../../../commons/components/ButtonShape/ButtonShape';
import LegalFooter from '../../../commons/components/LegalFooter';
import adjust, { deviceHeight, deviceWidth } from '../../../commons/styles/TextAdjust';
import { isLandScape, isMobile, isPWA, isSafari } from '../../../commons/utils';
import { GameLogo, LoginBackgroundImage, pwaLinkBg } from '../../../constants/Images';
import { BeforeInstallPromptEvent } from '../../../types';
import PWAPrompt from './PWAPrompt';

const PWALink = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(isSafari);
  const [showInstallButton, setShowInstallButton] = useState<boolean>(true);
  const deferredPrompt = useRef<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    if (
      !window.matchMedia ||
      !(window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches)
    )
      return;
    setShowInstallButton(false);
  }, []);

  useEffect(() => {
    if (!window || !window.addEventListener || !window.matchMedia) return;
    const listener = (event: BeforeInstallPromptEvent) => {
      deferredPrompt.current = event;
    };
    window.addEventListener('beforeinstallprompt', e => listener(e as BeforeInstallPromptEvent));
    return () => window.removeEventListener('beforeinstallprompt', e => listener(e as BeforeInstallPromptEvent));
  }, []);

  useEffect(() => {
    if (!('getInstalledRelatedApps' in window.navigator)) return;
    const relatedApps = (navigator as any).getInstalledRelatedApps();
    if (!relatedApps.length) return;
    const webapp = relatedApps.find((app: any) => app.platform === 'webapp');
    if (webapp) setShowInstallButton(false);
  }, []);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center'
    },
    mainContent: {
      justifyContent: 'center',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      paddingHorizontal: 20,
      marginHorizontal: 'auto'
    },
    footerContainer: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 'auto',
      alignItems: 'center'
    },
    buttonInner: {
      paddingHorizontal: 10,
      marginBottom: isPWA ? 1 : 5,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'auto'
    },
    buttonContainer: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 0,
      marginBottom: 35,
      maxWidth: 200,
      marginHorizontal: 'auto'
    },
    gameLogo: {
      height: adjust(90),
      marginVertical: 40,
      marginBottom: 15
    },
    title: {
      textAlign: 'center',
      fontSize: isPWA ? adjust(26) : 32,
      color: '#ffffff',
      marginVertical: isPWA ? adjust(22) : 26
    },
    text: {
      fontSize: isPWA ? adjust(18) : 20,
      fontWeight: 'bold',
      maxWidth: 400,
      marginHorizontal: 'auto',
      textAlign: isLandScape ? 'center' : 'left'
    }
  });

  const url = '/';
  const handleLink = () => Linking.openURL(url);

  const source = deviceHeight > deviceWidth ? pwaLinkBg : LoginBackgroundImage;

  const handleInstallLink = () => {
    if (deferredPrompt.current === null) return;
    deferredPrompt.current.prompt();
    deferredPrompt.current.userChoice.then(({ outcome }) => {
      if (outcome !== 'accepted') return;
      deferredPrompt.current = null;
    });
  };

  return (
    <ImageBackground style={styles.container} source={source} resizeMode="cover">
      <View style={styles.mainContent}>
        <Image source={GameLogo} accessible accessibilityLabel="Game logo" resizeMode="contain" style={styles.gameLogo} />
        <View>
          <Text style={[styles.title, { fontWeight: 'bold' }]}>IMPORTANTE</Text>
          {isSafari && <PWAPrompt modalVisible={modalVisible} closeModal={() => setModalVisible(false)} />}
          {showInstallButton && (
            <>
              <Text style={[styles.text, { color: 'white', marginBottom: 16 }]}>
                Para jugar desde el móvil añade antes FakesOver a tu pantalla de inicio pulsando en este botón:
              </Text>
              <TouchableOpacity onPress={handleInstallLink}>
                <View style={styles.buttonContainer}>
                  <ButtonShape bgColor="black" heightBtn={isMobile ? 38 : 52}>
                    <View style={styles.buttonInner}>
                      <Text style={[styles.text, { color: 'white' }]}>Añadir FakesOver</Text>
                    </View>
                  </ButtonShape>
                </View>
              </TouchableOpacity>
            </>
          )}
          <Text style={[styles.text, { color: 'white', marginBottom: 16 }]}>
            Pulsa en este botón para empezar a jugar si ya has añadido FakesOver a tu pantalla de inicio
          </Text>
          <TouchableOpacity onPress={handleLink}>
            <View style={styles.buttonContainer}>
              <ButtonShape bgColor="white" heightBtn={isMobile ? 38 : 52}>
                <View style={styles.buttonInner}>
                  <Text style={styles.text}>Jugar a FakesOver</Text>
                </View>
              </ButtonShape>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.footerContainer}>
        <LegalFooter />
      </View>
    </ImageBackground>
  );
};

export default PWALink;
