import { createSlice } from '@reduxjs/toolkit';
import { ActorTweetEnum } from '../../commons/dtos/actor.dto';
import { TweetReaction } from '../../commons/dtos/tweet-reaction.dto';

export interface TweetReactionInterface {
  reactions: TweetReaction[];
}

const initialState: TweetReactionInterface = {
  reactions: []
};

const tweetReactionsSlice = createSlice({
  name: 'tweet-reactions',
  initialState,
  reducers: {
    addTweetReaction(state, action: { payload: TweetReaction }) {
      const { reactions } = state;
      return { ...state, reactions: [...reactions, action.payload] };
    },
    clearTweetReactionBySide(state, action: { payload: ActorTweetEnum }) {
      const { reactions } = state;
      return { ...state, reactions: reactions.filter(reaction => reaction.side !== action.payload) };
    },
    resetTweetReactions() {
      return initialState;
    }
  }
});

export const { addTweetReaction, resetTweetReactions, clearTweetReactionBySide } = tweetReactionsSlice.actions;

export default tweetReactionsSlice;
