import { StyleSheet, View } from 'react-native';
import { useRatioDimensions } from '../../../commons/components/AspectRatioView/useRatioDimensions';
import { PlayerRoundEnum } from '../../../commons/dtos/player.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { TweetItem } from '../../../commons/utils/gameboard/tweets';
import TweetMsg from './TweetMsg';

interface ThreadTweetsProps {
  isOpponent: boolean;
  tweets: TweetItem[];
  nativeID: PlayerRoundEnum;
}

const ThreadTweets = ({ tweets, isOpponent, nativeID }: ThreadTweetsProps) => {
  const { rw } = useRatioDimensions();
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      top: !isOpponent ? undefined : 0,
      bottom: isOpponent ? undefined : 0,
      height: 'auto',
      width: isNativeOrPWA ? 295 : 360,
      left: isNativeOrPWA ? rw(4) : rw(6),
      justifyContent: 'center',
      zIndex: 100,
      elevation: 100
    }
  });

  return (
    <View pointerEvents="none" style={styles.container}>
      {tweets.map((tweet: TweetItem, index: number) => (
        <TweetMsg key={index} tweet={tweet} index={index} total={tweets.length} isOpponent={isOpponent} nativeID={nativeID} />
      ))}
    </View>
  );
};
export default ThreadTweets;
