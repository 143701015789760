import { ImageSourcePropType } from 'react-native';
import { randomIntFromInterval } from '../utils';

export class Tweet {
  likes: number;
  retweets: number;
  responses: number;
  user: string | null;
  image: ImageSourcePropType | null;

  constructor() {
    this.user = null;
    this.image = null;
    this.likes = randomIntFromInterval(0, 12);
    this.responses = randomIntFromInterval(0, 12);
    this.retweets = randomIntFromInterval(0, 12);
  }
}
