import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { StyleSheet } from 'react-native';
import { settingsIcon, userIcon } from '../../constants/Images';
import { RootStackParamList } from '../../types';
import { View } from './Themed';
import UserMenuButton from './UserMenu/UserProfileButton';

interface UserMenuProps {
  openModal: Function;
}

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Logout', undefined>;

const UserMenu = ({ openModal }: UserMenuProps) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  });

  const navigation = useNavigation<Navigation>();

  return (
    <View style={styles.container}>
      <UserMenuButton handleNavigate={() => navigation.navigate('UserProfile')} imageSource={userIcon} />
      <UserMenuButton handleNavigate={() => openModal()} imageSource={settingsIcon} />
    </View>
  );
};

export default UserMenu;
