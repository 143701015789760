import { RouteProp, useRoute } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { StyleSheet, ImageBackground } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import CardStatsFilterModal from '../../commons/components/CardStatsFilter/CardStatsFilterModal';
import CardsViewer from '../../commons/components/CardsViewer';
import InventoryTopHeader from '../../commons/components/InventoryTopHeader/InventoryTopHeader';
import SnackBar from '../../commons/components/SnackBar';
import { View, Text } from '../../commons/components/Themed';
import { CardInventoryFilter } from '../../commons/dtos/card-inventory-filter.dto';
import { CardInventoryIncludesCard } from '../../commons/dtos/card-inventory-includes-card.dto';
import { CardQuantity } from '../../commons/dtos/card-quantity.dto';
import { Card } from '../../commons/dtos/card.dto';
import { DeckData } from '../../commons/dtos/deck-data.dto';
import { ApiError } from '../../commons/errors/api-error';
import { isNativeOrPWA, onFilterCardInventory } from '../../commons/utils';
import { backgroundDeckEditor, blockImage } from '../../constants/Images';
import { useMessageExtended } from '../../hooks/useMessageExtended';
import { Profile } from '../../redux/Profile/profileReducer';
import CardInventoriesService from '../../services/card-inventories.service';
import CardsService from '../../services/cards.service';
import DecksService from '../../services/decks.service';
import { CardInventoryViewerProps, RootStackParamList } from '../../types';
import DecksListViewer from './DecksListViewer/DecksListViewer';

const cardInventoriesService = new CardInventoriesService();
const decksService = new DecksService();
const cardsService = new CardsService();
type Route = RouteProp<RootStackParamList, 'CardInventoryViewer'>;

const CardInventoryViewer = ({ navigation }: CardInventoryViewerProps) => {
  const [cardsViewerPage, setCardsViewerPage] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [cardInventory, setCardInventory] = useState<CardInventoryIncludesCard[]>([]);
  const [fullCardInventory, setFullCardInventory] = useState<CardInventoryIncludesCard[]>([]);
  const [loading, setLoading] = useState(true);
  const [decks, setDecks] = useState<DeckData[]>([]);
  const [missedCards, setMissedCards] = useState<CardQuantity[]>([]);
  const [fullMissedCards, setFullMissedCards] = useState<CardQuantity[]>([]);
  const [cardInventoryFilters, setCardInventoryFilters] = useState<CardInventoryFilter>({});
  const [showAllCards, setShowAllCards] = useState(false);
  const route = useRoute<Route>();
  const { updateData } = route.params;

  const { vh } = useUnits();

  const { player } = useSelector(({ profile }: { profile: Profile }) => profile);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);

  const { msg, detail, setMessage, clearMessageError } = useMessageExtended();

  useEffect(() => {
    const filteredCardInventory = onFilterCardInventory(fullCardInventory, cardInventoryFilters) as CardInventoryIncludesCard[];
    if (filteredCardInventory) setCardInventory(filteredCardInventory);
    const filteredMissedCards = onFilterCardInventory(fullMissedCards, cardInventoryFilters) as CardQuantity[];
    if (filteredMissedCards) setMissedCards(filteredMissedCards);
    setCardsViewerPage(0);
  }, [cardInventoryFilters]);

  useEffect(() => {
    setCardsViewerPage(0);
  }, [showAllCards]);

  useEffect(() => {
    const fetchCards = async () => {
      setLoading(true);
      try {
        const cardInventoryData = await cardInventoriesService.getCardInventory(player!.id!);
        setCardInventory(cardInventoryData);
        setFullCardInventory(cardInventoryData);
        const decksData = await decksService.getDecks(player!.id!);
        const reg = /Mazo_\d{10,}/i;
        const renamedDecks = decksData.map(data => {
          data.deck.deckName = reg.test(data.deck.deckName) ? '' : data.deck.deckName;
          return data;
        });
        setDecks(renamedDecks);
        const allCardsData = await cardsService.getCards();
        getMissedCards(allCardsData, cardInventoryData);
      } catch (err) {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(
            `[Código: ${ex.httpCode}]: Ocurrió un problema recuperando los mazos o el inventario de cartas`,
            `[Detalle]: ${ex.detail}`
          );
        } else {
          setMessage(`Error: ${err}`);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchCards();
  }, [checkShowLoginModal, updateData]);

  const getMissedCards = (currentAllCards: Card[], inventoryCards: CardInventoryIncludesCard[]) => {
    const currentMissedCards = currentAllCards
      .filter(card => {
        return !inventoryCards.filter(inventoryCard => {
          return inventoryCard.card.cardName === card.cardName;
        }).length;
      })
      .map(missedCard => {
        return { quantity: -1, card: missedCard };
      });

    setMissedCards(currentMissedCards);
    setFullMissedCards(currentMissedCards);
  };

  const onCreateNewDeck = () => {
    navigation.navigate('DeckEditor', { isNewDeck: true });
  };

  const onGoBack = () => navigation.navigate('Home');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: '#363636',
      position: 'relative'
    },
    block: {
      position: 'absolute',
      width: '74%',
      height: '86%',
      left: 0,
      paddingLeft: '8%',
      paddingRight: '6%',
      bottom: '2%',
      color: 'white',
      paddingTop: vh(3),
      paddingBottom: vh(1)
    },
    blockImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    background: { width: '100%', height: '100%' },
    noCardsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'center',
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    },
    noCardsTitle: {
      fontSize: isNativeOrPWA ? 20 : 34,
      textAlign: 'center',
      maxWidth: isNativeOrPWA ? 420 : 580,
      fontWeight: 'bold',
      lineHeight: isNativeOrPWA ? 32 : 60
    }
  });

  return (
    <GestureHandlerRootView style={styles.container}>
      <ImageBackground style={styles.background} source={backgroundDeckEditor} resizeMode="cover" />
      <InventoryTopHeader
        onGoBack={onGoBack}
        cardInventoryFilters={cardInventoryFilters}
        setCardInventoryFilters={setCardInventoryFilters}
        title="Baraja"
        showFilter={setShowFilterModal}
      />

      <View style={styles.block}>
        <ImageBackground style={styles.blockImage} source={blockImage} resizeMode="stretch" />
        {fullCardInventory.length ? (
          <CardsViewer
            cardsViewerPage={cardsViewerPage}
            setCardsViewerPage={setCardsViewerPage}
            cardsInventory={cardInventory}
            missedCards={showAllCards ? missedCards : []}
          />
        ) : (
          <View style={styles.noCardsContainer}>
            {!loading ? (
              <Text style={styles.noCardsTitle}>Juega la campaña y acaba todas las misiones para poder crear tu mazo.</Text>
            ) : (
              <Text style={styles.noCardsTitle}>Cargando...</Text>
            )}
          </View>
        )}
      </View>
      <DecksListViewer onCreateNewDeck={onCreateNewDeck} decks={decks} />
      <SnackBar clearMessageError={clearMessageError} msg={msg} detail={detail} />
      {showFilterModal && (
        <CardStatsFilterModal
          onCloseModal={setShowFilterModal}
          showAllCards={showAllCards}
          setShowAllCards={setShowAllCards}
          showViewAllFilter={true}
          cardInventoryFilters={cardInventoryFilters}
          setCardInventoryFilters={setCardInventoryFilters}
        />
      )}
    </GestureHandlerRootView>
  );
};

export default CardInventoryViewer;
