import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, View } from 'react-native';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import Arrow from '../Arrow/Arrow';
import BotBlock from '../BotBlock/BotBlock';
import GameBoardElements from '../GameBoardElements/GameBoardElements';
import { useStylesHow } from '../useStylesHow';

const HowSectionPlayButton = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <GameBoardElements playButton />
      <BotBlock small withBot={!isNativeOrPWA} title={howTitle['play-button-title']}>
        <Text style={styles.paragraph}>
          <TextWithHTML text={howText['play-button-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
        </Text>
        <View style={{ position: 'absolute', top: '20%', right: isNativeOrPWA ? -40 : -80 }}>
          <Arrow rotate={270} />
        </View>
      </BotBlock>
    </ImageBackground>
  );
};

export default HowSectionPlayButton;
