import { createSlice } from '@reduxjs/toolkit';
import { Reaction } from '../../commons/dtos/reaction.dto';
import { randomIntFromInterval } from '../../commons/utils';

export interface GameReaction {
  reactions: Reaction[];
  currentReaction: Reaction | null;
}

const initialState: GameReaction = {
  currentReaction: null,
  reactions: []
};

const gameReactionsSlice = createSlice({
  name: 'game-reactions',
  initialState,
  reducers: {
    addGameReaction(state, action: { payload: Reaction; type: string }) {
      const { reactions, currentReaction } = state;
      const copyReaction = { ...action.payload };
      const { text: reactionText } = action.payload;

      if (Array.isArray(reactionText)) {
        const randomText = reactionText[randomIntFromInterval(0, reactionText.length - 1)];
        copyReaction.text = randomText;
      }

      if (!currentReaction) return { ...state, currentReaction: copyReaction };

      return { ...state, reactions: [...reactions, copyReaction] };
    },
    nextGameReaction(state) {
      const { reactions } = state;
      if (reactions.length === 0) return { ...state, currentReaction: null };

      const [reaction, ...restReactions] = reactions;
      return { ...state, currentReaction: reaction, reactions: restReactions };
    },
    clearGameReaction(state) {
      return { ...state, currentReaction: null, isShowed: true };
    },
    resetGameReactions() {
      return initialState;
    }
  }
});

export const { addGameReaction, nextGameReaction, clearGameReaction, resetGameReactions } = gameReactionsSlice.actions;

export default gameReactionsSlice;
