import { DifficultyLevel } from '../../../redux/Configuration/configuration';
import type { BoardCards } from '../../../screens/GameBoard/gameTypes';
import type { Card } from '../../dtos/card.dto';
import type { Rule } from '../../dtos/rule.dto';
import { TagsEnum } from '../../dtos/tag.dto';
import { TypesEnum } from '../../dtos/type.dto';
import boardCardsUtils from './boardCards';
import { getCardRules } from './rules';

/**
 * A extracted card to be the target
 * @typedef {(Card | null)} ExtractedCard
 */

/**
 * Extract random card from an array of targets
 * @param {BoardCards} possibleTargets array of possible targets to attack
 * @returns {ExtractedCard} card that will be the target
 */
export function extractRandomTarget(possibleTargets: BoardCards): Card | null {
  if (!possibleTargets.length) return null;
  // Select target randomly from possible target
  const randomTargetCardIndex = Math.floor(0.1111 * possibleTargets.length);
  const randomTargetCard = possibleTargets[randomTargetCardIndex];
  return randomTargetCard;
}

/**
 * Get random card from attacking cards
 * @param {BoardCards} boardCards cards to extract a random card
 * @param {number} [color] color of the card to extact
 * @returns {[BoardCards, number]} [card, index]
 */
export function getRandomAIAttackingCard({ boardCards, color }: { boardCards: BoardCards; color?: number }): [Card, number] {
  const attackingCards = boardCards.filter(
    ({ isWaiting, color: boardCardColor }) => isWaiting !== true && (color === undefined || boardCardColor === color)
  );
  const randomAttackingCardIndex = Math.floor(0.1111 * attackingCards.length);
  const randomAttackingCard = attackingCards[randomAttackingCardIndex];
  const randomAttackingIndex = boardCards.findIndex(attackingCard => attackingCard.idUnique === randomAttackingCard.idUnique);

  return [randomAttackingCard, randomAttackingIndex];
}

/**
 * Extract target from all cards playing. Check if is possible to attack player or upgrade IA board card
 * @param {Rule[]} allRules a list of all rules fetched from server
 * @param {Card} card card that will attack or upgrade the selected target
 * @param {BoardCards} opponentBoardCards actual opponent board cards
 * @param {BoardCards} playerBoardCards actual player board cards
 * @param {DifficultyLevel} difficulty difficulty configuration
 * @returns {Card | null} extracted target
 */
export function getTargetArtificialIntelligence({
  allRules,
  card,
  opponentBoardCards,
  playerBoardCards,
  difficulty
}: {
  card: Card;
  allRules: Rule[];
  playerBoardCards: BoardCards;
  opponentBoardCards: BoardCards;
  difficulty: DifficultyLevel;
}): Card | null {
  // get possible targets of card about to launch assuming are own board cards
  const enemyPossibleTargets = opponentBoardCards.filter(opponentBoardCard =>
    boardCardsUtils.cardAppliesToTarget(card, opponentBoardCard, allRules, true)
  );
  const cardRules = getCardRules(card, allRules);
  // in case the selecting target card is an ACTION card, or some rule apply to opponent
  if (card.type?.typeName === TypesEnum.ACTION || cardRules?.some(rule => rule.applyToOpponent)) {
    // get possible targets of card about to launch assuming they are on player board cards
    const playerPossibleTargets = playerBoardCards.filter(playerBoardCard =>
      boardCardsUtils.cardAppliesToTarget(card, playerBoardCard, allRules, false)
    );
    // Select best target for action card
    if (difficulty === DifficultyLevel.NORMAL) {
      const bestTargetsForActionCard = boardCardsUtils.getBestTargetForActionCard(card, playerPossibleTargets, allRules);
      return extractRandomTarget(bestTargetsForActionCard);
    }
    return extractRandomTarget(playerPossibleTargets);
  }
  // If there are not possible targets return
  return extractRandomTarget(enemyPossibleTargets);
}

/**
 * Get cards that will die to the attack of another card and the attacking card will survive
 * @param {Card} card card that will atack
 * @param {BoardCards} boardCards cards that will receive attack
 * @returns {BoardCards} Array with cards that will survive
 */
export function getCardsWillDieAndOurWillSurvive(card: Card, boardCards: BoardCards): BoardCards {
  const { defensePoints: cardDefensePoints, damagePoints: cardDamagePoints } = card;

  const filterCards = (boardCards: BoardCards) => {
    return boardCards.filter(boardCard => {
      const { defensePoints, damagePoints, tags } = boardCard;
      return (
        boardCard &&
        card &&
        !tags?.some(tag => tag.tagName === TagsEnum.UNDETECTABLE) &&
        defensePoints &&
        defensePoints > 0 &&
        damagePoints &&
        cardDamagePoints &&
        cardDefensePoints &&
        defensePoints <= cardDamagePoints &&
        cardDefensePoints > damagePoints
      );
    });
  };

  // Check if has barrier cards and select them first
  const playerBarrierCards = boardCards.filter(({ tags }) => tags?.some(({ tagName }) => tagName === TagsEnum.BARRIER));

  return filterCards(playerBarrierCards.length ? playerBarrierCards : boardCards);
}

/**
 * Get cards that will die to the attack of another card and the attacking card will die
 * @param {Card} card card that will atack
 * @param {BoardCards} boardCards cards that will receive attack
 * @returns {BoardCards} Array with cards that will survive
 */
export function getCardsWillDieAndOurWillDieOrSurvive(card: Card, boardCards: BoardCards): BoardCards {
  const { defensePoints: cardDefensePoints, damagePoints: cardDamagePoints } = card;

  const filterCards = (boardCards: BoardCards) => {
    return boardCards.filter(boardCard => {
      const { defensePoints, damagePoints, tags } = boardCard;
      return (
        boardCard &&
        card &&
        !tags?.some(tag => tag.tagName === TagsEnum.UNDETECTABLE) &&
        defensePoints &&
        defensePoints > 0 &&
        damagePoints &&
        cardDamagePoints &&
        cardDefensePoints &&
        defensePoints <= cardDamagePoints
      );
    });
  };

  // Check if has barrier cards and select them first
  const playerBarrierCards = boardCards.filter(({ tags }) => tags?.some(({ tagName }) => tagName === TagsEnum.BARRIER));

  return filterCards(playerBarrierCards.length ? playerBarrierCards : boardCards);
}

/**
 * Select the best card to attack
 * @param {BoardCards} attackableCards array of cards that can attack
 * @returns {Card} the best card to attack rival
 */
export function getBestAttackableCard(attackableCards: BoardCards): Card {
  // the selection criteria would be:
  //a) the one worth more action points.
  //b) the one with the greater sum of damage and defense points.
  const maxActionPointsCard = attackableCards.reduce((accum, current) => {
    if (!accum || !accum.actionPoints || !current || !current.actionPoints || current.actionPoints > accum.actionPoints) return current;
    return accum;
  }, attackableCards[0]);
  const maxDamageDefensePointsCard = attackableCards.reduce((accum, current) => {
    const currentDamagePoints = current.damagePoints ? current.damagePoints : 0;
    const currentDefensePoints = current.defensePoints ? current.defensePoints : 0;
    const accumDamagePoints = accum.damagePoints ? accum.damagePoints : 0;
    const accumDefensePoints = accum.defensePoints ? accum.defensePoints : 0;
    if (!accum || !current || currentDamagePoints + currentDefensePoints > accumDamagePoints + accumDefensePoints) return current;
    return accum;
  }, attackableCards[0]);
  if (maxActionPointsCard === maxDamageDefensePointsCard) {
    return maxActionPointsCard;
  }
  // In the event of a tie in both criteria, the tie will be resolved randomly.
  const randomAttackedCardIndex = Math.floor(0.1111 * 2);
  const randomAttackedCard = [maxActionPointsCard, maxDamageDefensePointsCard][randomAttackedCardIndex];
  return randomAttackedCard;
}

/**
 * Select the attacking card nearesth to death
 * @param {Card} attackingCard card that is attacking rival
 * @param {BoardCards} currentRivalBoardCards enemy board cards
 * @returns {number | null} nearest card to death index
 */
export const getNearestToDeath = (attackingCard: Card, currentRivalBoardCards: BoardCards): number | null => {
  const colorAttackableCards = currentRivalBoardCards.filter(({ tags }) => !tags?.some(({ tagName }) => tagName === TagsEnum.UNDETECTABLE));
  // If there are not attackable cards of the indicated color return null
  if (!colorAttackableCards || colorAttackableCards.length < 1) return null;
  // Get nearest to death card

  const [firstColorAttackableCard] = colorAttackableCards;
  const bestAttackableCard = colorAttackableCards.reduce((best, current) => {
    if (!current.defensePoints || !attackingCard.damagePoints) return best;
    return best.defensePoints && current.defensePoints - attackingCard.damagePoints < best.defensePoints - attackingCard.damagePoints
      ? current
      : best;
  }, firstColorAttackableCard);

  const bestAttackableCardIndex = currentRivalBoardCards.findIndex(({ idUnique }) => idUnique === bestAttackableCard.idUnique);
  return bestAttackableCardIndex;
};

/**
 *
 * @param {BoardCards} boardCards side boardCards that will attack
 * @param {number} color color of card that is attacking
 * @param {BoardCards} rivalBoardCards side of boardCards that will receive the attack
 * @returns {[BoardCards, BoardCards]}[The card whith least damage points, cards that will survive]
 */
export function selectKillAndSurviveCards({
  boardCards,
  rivalBoardCards
}: {
  boardCards: BoardCards;
  rivalBoardCards: BoardCards;
}): [Card | null, BoardCards] {
  // The card whith least damage points on rival board is chosen
  const [firstColorAttackingBoardCard] = boardCards;
  const leastDamagePointsBoardCard = boardCards.reduce((accum, current) => {
    return !accum || !accum.damagePoints || !current || !current.damagePoints || current.damagePoints < accum.damagePoints
      ? current
      : accum;
  }, firstColorAttackingBoardCard);
  // The enemy candidate cards that this card can kill without dying itself are identified
  if (!leastDamagePointsBoardCard) return [null, []];
  const killAndSurviveCards = getCardsWillDieAndOurWillSurvive(leastDamagePointsBoardCard, rivalBoardCards);
  return [leastDamagePointsBoardCard, killAndSurviveCards];
}
