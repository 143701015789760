import { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import CardModal from '../../../commons/components/Card/CardModal';
import Slides from '../../../commons/components/Slides';
import { Card, Card as CardType } from '../../../commons/dtos/card.dto';
import { isNativeOrPWA } from '../../../commons/utils';
import { paginate } from '../../../commons/utils/paginate';
import { useCardModal } from '../../../hooks/useCardModal';
import RewardCard from '../RewardCard';
import { CardReveal } from '../useReward';
import { useRewardReveal } from '../useRewardReveal';

export interface CardTypeRewards extends CardType {
  reveal: boolean;
}

interface RewardCardDeckOpenProps {
  cards: Array<Required<CardReveal>>;
  updateCardPack: (cardPack: Array<Required<CardReveal>>) => void;
  handleActiveBack: () => void;
}

interface RewardCardOpenShow {
  cards: Array<Required<CardReveal>>;
  handleReveal: (card: Required<CardReveal>) => void;
  handleSelectModal: (card: Card) => void;
}

export const RewardsDeckOpen = ({ cards, updateCardPack, handleActiveBack }: RewardCardDeckOpenProps) => {
  const { handleMultipleReveal } = useRewardReveal(updateCardPack, cards, handleActiveBack);
  const { detailCard, detailCardVisible, handleSelectCardModal, handleVisibilityDetailCard } = useCardModal();

  return (
    <>
      <CardModal detailCardVisible={detailCardVisible} hide={handleVisibilityDetailCard.hide} showTagsInfo={false} card={detailCard} />
      {isNativeOrPWA ? (
        <RewardDeckOpenRowsMobile cards={cards} handleReveal={handleMultipleReveal} handleSelectModal={handleSelectCardModal} />
      ) : (
        <RewardDeckOpenRowsDesktop cards={cards} handleReveal={handleMultipleReveal} handleSelectModal={handleSelectCardModal} />
      )}
    </>
  );
};

export const RewardDeckOpenRowsDesktop = ({ cards, handleReveal, handleSelectModal }: RewardCardOpenShow) => {
  const splitedCards = useMemo(() => paginate(cards, 3), [cards]);

  const style = StyleSheet.create({
    rowsWrapper: {
      width: 'auto',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    row: {
      width: 'auto',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginVertical: 8
    }
  });

  return (
    <View style={style.rowsWrapper}>
      {splitedCards.map((row, index) => (
        <View key={index} style={style.row}>
          {row.map((card, index) => (
            <RewardCard card={card} key={index} handleReveal={handleReveal} handleSelectModal={handleSelectModal} />
          ))}
        </View>
      ))}
    </View>
  );
};

export const RewardDeckOpenRowsMobile = ({ cards, handleReveal, handleSelectModal }: RewardCardOpenShow) => {
  const style = StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 16
    }
  });

  if (cards.length <= 3) {
    return (
      <View>
        <View style={style.container}>
          {cards.map((card, index) => (
            <View style={{ paddingHorizontal: 6 }} key={index}>
              <RewardCard card={card} handleReveal={handleReveal} handleSelectModal={handleSelectModal} />
            </View>
          ))}
        </View>
      </View>
    );
  }

  return (
    <View>
      <Slides itemsToRender={3} parentStyle={style.container} initialStep={0}>
        {cards.map((card, index) => (
          <View style={{ paddingHorizontal: 6 }} key={index}>
            <RewardCard card={card} handleReveal={handleReveal} handleSelectModal={handleSelectModal} />
          </View>
        ))}
      </Slides>
    </View>
  );
};
