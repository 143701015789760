import axios from 'axios';
import { AvatarProperties } from '../commons/dtos/avatar.dto';
import { Player } from '../commons/dtos/player.dto';
import { MissionProgressStatusEnum } from '../commons/dtos/progress.dto';
import { RewardTypeEnum } from '../commons/dtos/reward.dto';
import { TitleProperties } from '../commons/dtos/title.dto';
import store from '../redux/store';

const base = process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL;

interface InventoryItem {
  rewardInventoryId: string;
  quantity: number;
}
export interface UpdateInventoryItem {
  type: RewardTypeEnum;
  objectId: string;
  quantity?: number;
}

export interface TitleInventory extends InventoryItem, TitleProperties {}
export interface AvatarInventory extends InventoryItem, AvatarProperties {}

export interface Inventory {
  id: string;
  titles: TitleInventory[];
  avatars: AvatarInventory[];
}

export default class PlayerService implements PlayerServiceProperties {
  isPlaying: boolean;

  constructor(properties?: PlayerServiceProperties) {
    this.isPlaying = properties?.isPlaying ? properties.isPlaying : false;
  }

  getProfile = async (): Promise<Player> => {
    const { auth } = store.getState();
    return (
      await axios.get(`${base}/players/email/${auth.user?.email}`, {
        headers: { playing: this.isPlaying }
      })
    ).data;
  };
  getInventory = async (): Promise<Inventory> => {
    const { auth } = store.getState();
    return (await axios.get(`${base}/inventories/player/${auth.user?.playerId}`)).data;
  };
  getUserRanking = async (): Promise<any[]> => {
    const { auth } = store.getState();
    return (await axios.get(`${base}/ranking/${auth.user?.playerId}`)).data;
  };
  updatePlayer = async (playerId: string, content: Partial<Player>): Promise<Player> =>
    (await axios.patch(`${base}/players/${playerId}`, content)).data;
  updateProgress = async (
    missionId: string,
    missionStatus: MissionProgressStatusEnum,
    opponentLifePoint?: number,
    round?: number
  ): Promise<Player | undefined> => {
    const { profile } = store.getState();
    if (profile && profile.player) {
      const { player } = profile;
      if (player) {
        return (
          await axios.put(
            `${base}/players/progress/${player.id}`,
            {
              missionId,
              missionStatus,
              opponentLifePoint,
              round
            },
            {
              headers: { playing: this.isPlaying }
            }
          )
        ).data;
      }
    }
  };
}

export interface PlayerServiceProperties {
  isPlaying?: boolean;
}
