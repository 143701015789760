import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginModal: false
};

const loginModal = createSlice({
  initialState,
  name: 'loginModal',
  reducers: {
    showLoginModal: state => {
      state.loginModal = true;
    },
    hideLoginModal: state => {
      state.loginModal = false;
    }
  }
});

export const { reducer: loginModalReducer } = loginModal;
export const { showLoginModal, hideLoginModal } = loginModal.actions;
