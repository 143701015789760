import { ImageBackground, Image, StyleSheet } from 'react-native';
import { rarityBlackContainer, rarityWhiteContainer } from '../../../constants/Images';
import { Card } from '../../dtos/card.dto';
import { TagsEnum } from '../../dtos/tag.dto';
import { getTagsIcon } from './utils';

interface ActionsSkillsProps {
  card: Card;
}

const ActionsSkills = ({ card }: ActionsSkillsProps) => {
  const styles = StyleSheet.create({
    actionCardContainerBackground: {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '47%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    actionIcon: { width: '80%', height: '80%' }
  });
  const tags = card.tags?.filter(tag => tag.tagName === TagsEnum.IMPACT || tag.tagName === TagsEnum.FACTCHECK) || [];

  return (
    <>
      {tags.map(tag => (
        <ImageBackground
          key={tag.id}
          style={styles.actionCardContainerBackground}
          source={card.color ? rarityBlackContainer : rarityWhiteContainer}
        >
          <Image style={styles.actionIcon} source={getTagsIcon(tag.tagName)} />
        </ImageBackground>
      ))}
    </>
  );
};

export default ActionsSkills;
