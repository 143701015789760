import { DISCARD_CORRECTION_FACTOR, DISCARD_MAX_MATCH, DISCARD_MIN_MATCH } from '../../../constants/Values';
import { Deck } from '../../../screens/GameBoard/gameTypes';
import { Card } from '../../dtos/card.dto';

export function getAutoDiscardSelection(discardCards: Deck, actionPoints: number): number[] {
  const cardIndexes: number[] = [];
  let max = DISCARD_CORRECTION_FACTOR;
  if (max < actionPoints) {
    max = actionPoints;
    if (max + DISCARD_CORRECTION_FACTOR >= DISCARD_MAX_MATCH) {
      max = DISCARD_MIN_MATCH;
    }
  }
  discardCards.forEach((card: Card, index: number) => {
    if (card.actionPoints <= DISCARD_MAX_MATCH && card.actionPoints >= max) {
      cardIndexes.push(index);
    }
  });

  return cardIndexes;
}
