import { useCallback, useMemo, useRef, useState } from 'react';
import { Animated, Platform } from 'react-native';
import useUnits from 'rxn-units';
import type { Card } from '../commons/dtos/card.dto';
import { PlayerRoundEnum } from '../commons/dtos/player.dto';
import { Rule } from '../commons/dtos/rule.dto';
import { SubjectsEnum } from '../commons/dtos/subject.dto';
import { Tweet } from '../commons/dtos/tweet.dto';
import { TypesEnum } from '../commons/dtos/type.dto';
import { processDuplicatedCards, shuffle, wait } from '../commons/utils';
import boardCardsUtils from '../commons/utils/gameboard/boardCards';
import { MAX_HAND_OF_CARDS_LENGTH } from '../constants/Values';
import type { BoardCards, Deck, Hand } from '../screens/GameBoard/gameTypes';

interface useHandProps {
  initialDeck: Card[];
  side: 'player' | 'opponent';
  random: boolean;
  initialHandSize: number;
  selectedToDiscardCards: number[];
  offset?: number;
}

/**
 * Extract card from deck
 * @param deck deck to extract one card
 * @param type card type to extract
 * @returns deck without extracted card and the extracted card
 */
const extractCardFromDeckSide = (deck: Card[], type?: TypesEnum, subject?: SubjectsEnum): [Card[], Card] => {
  if (!type && !subject) {
    const [extractedCard, ...restDeck] = deck;
    return [restDeck, extractedCard];
  }

  const copyDeck = [...deck];
  let extractedCard: Card = {} as Card;

  copyDeck.forEach((card, index) => {
    if (type && card.type?.typeName === type && !Object.keys(extractedCard).length) {
      extractedCard = card;
      copyDeck.splice(index, 1);
    }
    if (subject && card.subject?.subjectName === subject && !Object.keys(extractedCard).length) {
      extractedCard = card;
      copyDeck.splice(index, 1);
    }
  });

  return [copyDeck, extractedCard];
};

export const handHelper = <T>(set: React.Dispatch<React.SetStateAction<T[]>>) => ({
  add: (card: T) => set(prev => [...prev, card]),
  reset: () => set([]),
  replace: (cards: T[]) => set(cards),
  replaceCb: (cb: (cards: T[]) => T[]) => set(prev => cb(prev))
});

export const useHand = ({ initialDeck, side, initialHandSize, selectedToDiscardCards, random, offset = 0 }: useHandProps) => {
  const { vw, vh } = useUnits();

  const prevDeck = useMemo(() => {
    const generatedIds = initialDeck.map((card, index) => ({ ...card, idUnique: index + offset, tweet: new Tweet() }));
    const deck = processDuplicatedCards(generatedIds, side === 'player' ? PlayerRoundEnum.PLAYER : PlayerRoundEnum.OPPONENT);
    return random ? shuffle(deck) : deck;
  }, [initialDeck, random]);

  const [deck, setDeck] = useState<Deck>(prevDeck);

  const [hand, setHand] = useState<Hand>([]);

  const [cardIsTravelling, setCardIsTravelling] = useState<boolean>(false);

  const travelingCardPosition = useRef<Animated.ValueXY>(new Animated.ValueXY({ x: 0, y: 0 })).current;

  const handleDeck = handHelper(setDeck);
  const handleHand = handHelper(setHand);

  const handHasMovements = useMemo(() => hand.some(card => card.isPlayingCard === true), [hand]);

  const animateTravellingCard = () => {
    const animateTravellingCard = Animated.timing(travelingCardPosition, {
      toValue: { x: -vw(40), y: side === PlayerRoundEnum.PLAYER ? vh(20) : -vh(20) },
      duration: 500,
      useNativeDriver: Platform.OS !== 'web'
    });
    animateTravellingCard.start(({ finished }) => {
      travelingCardPosition.setValue({ x: 0, y: 0 });
      if (finished) {
        animateTravellingCard.reset();
      }
    });
  };

  const onConfirmDiscard = useCallback((): [Deck, Deck] => {
    const oldDeck = [...deck];
    const mutableDeck = [...deck];
    let newDeck = [...deck];
    for (const cardIndex of selectedToDiscardCards.sort()) {
      const discardedCard = { ...deck[cardIndex] };
      const newCard = mutableDeck.splice(initialHandSize, 1);
      [mutableDeck[cardIndex]] = newCard;
      newDeck = [
        ...mutableDeck.slice(0, initialHandSize),
        ...shuffle([...mutableDeck.slice(initialHandSize - selectedToDiscardCards.length), discardedCard])
      ];
    }
    handleDeck.replace(newDeck);
    return [oldDeck, newDeck];
  }, [deck, initialHandSize, selectedToDiscardCards]);

  const extractCardFromDeck = async (
    prevHand: Hand,
    actionPoints: number,
    isPlayerRound: boolean,
    opponentBoardCards: BoardCards,
    playerBoardCards: BoardCards,
    allRules: Rule[],
    deck: Deck,
    type?: TypesEnum,
    subject?: SubjectsEnum
  ): Promise<{ extractedCard: Card; hand: Hand; deck: Deck }> => {
    setCardIsTravelling(true);
    animateTravellingCard();
    await wait(500);
    const [newDeck, extractedCard] = extractCardFromDeckSide(deck, type, subject);

    handleDeck.replace(newDeck);

    if (prevHand.length < MAX_HAND_OF_CARDS_LENGTH) {
      const newHand = new Set(extractedCard ? [...prevHand, extractedCard] : [...prevHand]) as unknown as Card[];
      const checkedHand = boardCardsUtils.checkPlayingCards({
        actualActionPoints: actionPoints,
        allRules,
        hand: [...newHand],
        isPlayerRound,
        opponentBoardCards,
        playerBoardCards
      });
      handleHand.replace(checkedHand);
      return {
        extractedCard,
        hand: checkedHand,
        deck: newDeck
      };
    }
    // eslint-disable-next-line no-console
    console.warn(`Reached max length of cards in hand for ${isPlayerRound ? 'player' : 'opponent'}`);

    setCardIsTravelling(false);
    return {
      extractedCard,
      hand: prevHand,
      deck: newDeck
    };
  };

  const handMethods = { extractCardFromDeck, onConfirmDiscard };

  return {
    deck,
    hand,
    handleDeck,
    handleHand,
    cardIsTravelling,
    handMethods,
    travelingCardPosition,
    handHasMovements
  };
};
