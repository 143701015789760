import { LinearGradient } from 'expo-linear-gradient';
import { ImageBackground, Text, View, Image } from 'react-native';
import { howTitle, howText } from '../../../../assets/data/configuration/howToplay';
import { emptyGameboard, glass4, howCard } from '../../../../constants/Images';
import { isNativeOrPWA } from '../../../utils';
import TextWithHTML from '../../TextWithHTML';
import BotBlock from '../BotBlock/BotBlock';
import { useStylesHow } from '../useStylesHow';

const HowSectionAttackDefense = () => {
  const { styles, htmlStyles, generalStyle } = useStylesHow();

  return (
    <ImageBackground source={emptyGameboard} resizeMode="contain" style={styles.section}>
      <LinearGradient
        style={styles.linearGradientStyle}
        colors={['#171717', 'transparent', 'transparent', '#171717']}
        locations={[0, 0.2, 0.8, 1]}
        pointerEvents="none"
      />
      <View style={[styles.splitContainer, isNativeOrPWA && { maxWidth: '90%', marginHorizontal: 'auto' }]}>
        <View style={styles.splitView}>
          <BotBlock small withBot={!isNativeOrPWA} title={howTitle['attack-title']}>
            <Text style={styles.paragraph}>
              <Text style={[styles.paragraph, { marginBottom: 5 }]}>
                <TextWithHTML text={howText['attack-text']} generalTextStyle={generalStyle} htmlStyles={htmlStyles} />
              </Text>
            </Text>
          </BotBlock>
        </View>
        <View style={styles.splitView}>
          <Image style={styles.card} resizeMode="contain" source={howCard} accessible accessibilityLabel="card image" />
          <Image
            style={{
              position: 'absolute',
              left: isNativeOrPWA ? '5%' : '20%',
              top: '16%',
              height: '60%',
              width: isNativeOrPWA ? '93%' : '60%'
            }}
            resizeMode="contain"
            source={glass4}
            accessible
            accessibilityLabel="glass image"
          />
        </View>
      </View>
    </ImageBackground>
  );
};

export default HowSectionAttackDefense;
