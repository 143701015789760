import { StyleSheet, Image, View } from 'react-native';
import {
  boardcardsHowToPlay,
  boardcardsHowBlackToPlay,
  boardcardsHowWhiteToPlay,
  avatarsHowToPlay,
  playButtonsHowToPlay,
  deckcardsHowToPlay,
  pointsHowToPlay,
  handcardsHowToPlay,
  enemycardsHowToPlay,
  boardcardHowToPlay
} from '../../../../constants/Images';

interface GameBoardElementsProps {
  avatars?: boolean;
  playButton?: boolean;
  deckCards?: boolean;
  handCards?: boolean;
  enemyCards?: boolean;
  boardCards?: boolean;
  points?: boolean;
  boardColor?: 'w' | 'b' | null;
  all?: boolean;
}

const GameBoardElements = ({
  avatars = false,
  playButton = false,
  deckCards = false,
  handCards = false,
  boardCards = false,
  points = false,
  all = false,
  boardColor = null,
  enemyCards = false
}: GameBoardElementsProps) => {
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  });

  let boardCardsBG = boardcardsHowToPlay;
  if (boardCards && boardColor) {
    boardCardsBG = boardColor === 'b' ? boardcardsHowBlackToPlay : boardcardsHowWhiteToPlay;
  }

  return (
    <View style={styles.container}>
      {(all || avatars) && (
        <Image resizeMode="contain" source={avatarsHowToPlay} accessible accessibilityLabel="Gameboard avatars" style={styles.image} />
      )}
      {(all || playButton) && (
        <Image
          resizeMode="contain"
          source={playButtonsHowToPlay}
          accessible
          accessibilityLabel="Gameboard play Button"
          style={styles.image}
        />
      )}
      {deckCards && (
        <Image resizeMode="contain" source={deckcardsHowToPlay} accessible accessibilityLabel="Gameboard deck Cards" style={styles.image} />
      )}
      {(all || points) && (
        <Image resizeMode="contain" source={pointsHowToPlay} accessible accessibilityLabel="Gameboard hand Cards" style={styles.image} />
      )}
      {(all || handCards) && (
        <Image resizeMode="contain" source={handcardsHowToPlay} accessible accessibilityLabel="Gameboard hand Cards" style={styles.image} />
      )}
      {(all || enemyCards) && (
        <Image
          resizeMode="contain"
          source={enemycardsHowToPlay}
          accessible
          accessibilityLabel="Gameboard enemy Cards"
          style={styles.image}
        />
      )}
      {boardCards && (
        <Image resizeMode="contain" source={boardCardsBG} accessible accessibilityLabel="Gameboard cards" style={styles.image} />
      )}
      {all && (
        <Image resizeMode="contain" source={boardcardHowToPlay} accessible accessibilityLabel="Gameboard cards" style={styles.image} />
      )}
    </View>
  );
};

export default GameBoardElements;
