import LottieView from 'lottie-react-native';
import { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Image, Animated, Platform, Text } from 'react-native';
import { useSelector } from 'react-redux';
import useUnits from 'rxn-units';
import Avatar from '../../commons/components/Avatar';
import { getAvatar } from '../../commons/components/Dialogues/utils';
import { PlayerExperienceAnimated } from '../../commons/components/PlayerExperienceAnimated';
import { isNativeMobile, isNativeOrPWA } from '../../commons/utils';
import {
  winLoseAvatarBackground,
  ellipseWinLose,
  loseLeft,
  loseRight,
  loseTitle,
  winBg,
  winLeft,
  winRight,
  winTitle
} from '../../constants/Images';
import { lottieConfetiWin } from '../../constants/Lotties';
import { loseSound, winSound } from '../../constants/Sound';
import { useMusic } from '../../hooks/useMusic';
import { Config } from '../../redux/Configuration/configuration';
import store from '../../redux/store';
import { WinLoseScreenProps } from '../../types';

const WinLoseScreen = ({ navigation, route }: WinLoseScreenProps) => {
  const { vw, vh } = useUnits();
  const { gameConfig, winner: win = true, level } = route.params;

  const { dialogMusic } = useSelector(
    ({
      configuration: {
        volume: { dialogMusic }
      }
    }: {
      configuration: Config;
    }) => ({ dialogMusic })
  );
  const { unloadSound, sound } = useMusic({
    loop: false,
    play: dialogMusic,
    soundMusic: win ? winSound : loseSound
  });
  const {
    profile: { player }
  } = store.getState();

  const rotation = useRef(new Animated.Value(0)).current;
  const scale = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(0)).current;
  const textMove = useRef(new Animated.Value(0)).current;

  const [updateText, setUpdateText] = useState<boolean>(false);

  const { previousProgress } = route.params;

  const {
    level: { level: prevLevel }
  } = previousProgress;

  const { progress } = player!;

  const {
    level: { level: currentLevel }
  } = progress;

  const diffExperience = progress.experience - previousProgress.experience;

  useEffect(() => {
    const rotationAnimation: Animated.TimingAnimationConfig = {
      toValue: 1,
      duration: 30000,
      useNativeDriver: Platform.OS !== 'web'
    };

    const scaleAndOpacityAnimation: Animated.TimingAnimationConfig = {
      toValue: 1,
      duration: 1000,
      useNativeDriver: Platform.OS !== 'web'
    };

    const loop = Animated.loop(Animated.timing(rotation, rotationAnimation));
    const parallel = Animated.parallel([
      Animated.timing(scale, scaleAndOpacityAnimation),
      Animated.timing(opacity, scaleAndOpacityAnimation)
    ]);
    loop.start();
    parallel.start();
  }, []);

  useEffect(() => {
    if (currentLevel <= prevLevel) return;
    const animation = Animated.timing(textMove, {
      toValue: 1,
      useNativeDriver: Platform.OS !== 'web',
      duration: 1500,
      delay: 2400
    });
    animation.start();
    const to = setTimeout(() => setUpdateText(true), 2600);
    return () => clearTimeout(to);
  }, [currentLevel, prevLevel]);

  useEffect(() => {
    const navigate = async () => {
      await unloadSound();
      const state = navigation.getState();
      if (state.routes.length > 0) {
        navigation.pop(0);
      }

      if (win && gameConfig && gameConfig.rewards && gameConfig.rewards.length > 0) {
        navigation.navigate('Rewards', { gameConfig, level });
      } else {
        if (level) {
          navigation.navigate('Rewards', { level });
        } else {
          if (!gameConfig) return navigation.navigate('Home');
          const { isFreeGame } = gameConfig;
          navigation.navigate(isFreeGame ? 'DeckSelector' : 'Campaign');
        }
      }
    };
    const to = setTimeout(async () => await navigate(), 9000);
    return () => clearTimeout(to);
  }, [sound, win, level, gameConfig?.rewards, navigation.navigate]);

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#313131',
      zIndex: 1000,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden'
    },
    rays: {
      width: isNativeOrPWA ? '100%' : '100%',
      height: isNativeOrPWA ? '100%' : '100%',
      position: 'absolute',
      maxHeight: 530,
      marginTop: -vh(8),
      opacity: 0.7
    },
    containerImages: {
      position: 'relative',
      zIndex: 1000
    },
    medalsLeft: {
      position: 'absolute',
      left: isNativeOrPWA ? -80 : -130,
      bottom: isNativeOrPWA ? 30 : 30,
      width: isNativeOrPWA ? 230 : 270,
      height: isNativeOrPWA ? 240 : 280,
      zIndex: -10
    },
    medalsRight: {
      position: 'absolute',
      right: isNativeOrPWA ? -110 : -160,
      bottom: isNativeOrPWA ? 30 : 30,
      width: isNativeOrPWA ? 230 : 270,
      height: isNativeOrPWA ? 240 : 280,
      zIndex: -10
    },
    mainContent: {
      alignItems: 'center'
    },
    avatar: {
      width: isNativeOrPWA ? 150 : vw(10),
      height: isNativeOrPWA ? 150 : vw(10),
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 25,
      position: 'relative'
    },
    title: {
      width: isNativeOrPWA ? vw(50) : vw(50),
      height: isNativeOrPWA ? vw(50) : vw(50),
      maxHeight: 120,
      maxWidth: 336,
      marginTop: -24,
      zIndex: -1
    },
    lottieWin: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      elevation: 25,
      zIndex: 2000
    },
    ellipse: {
      marginTop: isNativeMobile ? -100 : -100,
      width: isNativeMobile ? 300 : 300,
      height: isNativeMobile ? 300 : 300,
      position: 'absolute'
    }
  });

  if (!player) return <></>;

  return (
    <View style={styles.container}>
      <Animated.Image
        source={winBg}
        resizeMode="contain"
        style={[
          styles.rays,
          {
            transform: [
              {
                rotate: rotation.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', '360deg']
                })
              }
            ]
          }
        ]}
      />
      <Image source={ellipseWinLose} resizeMode="contain" style={styles.ellipse} />

      <View style={styles.containerImages}>
        <Animated.Image
          source={win ? winLeft : loseLeft}
          resizeMode="contain"
          style={[styles.medalsLeft, { opacity, transform: [{ scale }] }]}
        />

        <Animated.Image
          source={win ? winRight : loseRight}
          resizeMode="contain"
          style={[styles.medalsRight, { opacity, transform: [{ scale }] }]}
        />

        <View style={styles.mainContent}>
          <Avatar
            avatar={getAvatar(player!.avatar!.avatarImage, player!.gender!, gameConfig?.isFreeGame)}
            avatarStyle={{
              bottom: isNativeMobile ? vw(1) : -3,
              width: '90%',
              height: '100%',
              opacity: win ? 1 : 0.7,
              position: 'absolute'
            }}
            background={winLoseAvatarBackground}
            bgStyle={styles.avatar}
          />

          <Image source={win ? winTitle : loseTitle} resizeMode="contain" style={styles.title} />
        </View>
      </View>
      <View style={{ marginTop: vh(5) }}>
        {updateText ? (
          <>
            <Animated.Text
              style={{
                fontSize: 18,
                textAlign: 'center',
                opacity: textMove.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 0]
                }),
                transform: [
                  {
                    translateY: textMove.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, -30]
                    })
                  }
                ]
              }}
            >
              <Text style={{ color: '#FFBE08', fontWeight: '700' }}>{`+${diffExperience}`} XP </Text>
              <Text style={{ color: '#FFFFFF', fontWeight: '400' }}>de recompensa</Text>
            </Animated.Text>
            <Animated.Text
              style={{
                fontSize: 18,
                textAlign: 'center',
                color: '#FFBE08',
                fontWeight: '700',
                opacity: textMove,
                transform: [
                  {
                    translateY: textMove.interpolate({
                      inputRange: [0, 1],
                      outputRange: [30, -35]
                    })
                  }
                ]
              }}
            >
              ¡Subes de nivel!
            </Animated.Text>
          </>
        ) : (
          <Text
            style={{
              fontSize: 18,
              textAlign: 'center',
              marginBottom: vh(3)
            }}
          >
            <Text style={{ color: '#FFBE08', fontWeight: '700' }}>{`+${diffExperience}`} XP </Text>
            <Text style={{ color: '#FFFFFF', fontWeight: '400' }}>de recompensa</Text>
          </Text>
        )}

        <PlayerExperienceAnimated previousProgress={route.params.previousProgress} />
      </View>
      {win && <LottieView autoPlay loop style={styles.lottieWin} source={lottieConfetiWin}></LottieView>}
    </View>
  );
};

export default WinLoseScreen;
