import { StyleSheet, View, Text, Pressable, Modal, Image } from 'react-native';
import adjust from '../../../commons/styles/TextAdjust';
import { iosAddHome, iosShare } from '../../../constants/Images';

interface PWAPromptProps {
  modalVisible: boolean;
  closeModal: Function;
}

const PWAPrompt = ({ modalVisible, closeModal }: PWAPromptProps) => {
  // const copyShareButtonLabel = "Presiona el botón 'Compartir'";
  const copyAddHomeButtonLabel = '2) Presiona "Add to Home Screen"';
  const copyShareButtonLabel = '1) Presiona el botón de "Share"';
  const copyTitle = 'Añadir a la pantalla principal';
  const copyBody =
    'Este sitio web tiene funcionalidad de aplicación. Agrégalo a su pantalla de inicio para usarlo en pantalla completa y sin conexión.';
  const copyClosePrompt = 'Cerrar';

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      margin: 10
    },
    modalView: {
      backgroundColor: '#fff',
      borderRadius: 10,
      borderWidth: 1,
      borderColor: '#fff',
      paddingHorizontal: 25,
      paddingTop: 0,
      paddingBottom: 30
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginVertical: adjust(22)
    },
    title: {
      fontWeight: 'bold',
      fontSize: adjust(22)
    },
    text: {
      paddingTop: 16,
      fontSize: adjust(18),
      lineHeight: adjust(26),
      color: '#888',
      borderTopColor: '#eee',
      borderTopWidth: 1
    },
    closeText: {
      color: '#007AFF'
    },
    steps: {
      marginTop: 16,
      paddingTop: 16,
      borderTopColor: '#eee',
      borderTopWidth: 1
    },
    textStep: {
      flex: 1,
      fontSize: adjust(19),
      marginLeft: 14,
      color: '#888'
    },
    step: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 8
    },
    icon: {
      width: 28,
      height: 28
    }
  });

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        closeModal();
      }}
    >
      <View style={styles.container}>
        <View style={styles.modalView}>
          <View style={styles.header}>
            <Text style={styles.title}>{copyTitle}</Text>
            <Pressable onPress={() => closeModal()}>
              <Text style={styles.closeText}>{copyClosePrompt}</Text>
            </Pressable>
          </View>
          <Text style={styles.text}>{copyBody}</Text>
          <View style={styles.steps}>
            <View style={styles.step}>
              <Image style={styles.icon} source={iosShare} resizeMode="contain" accessible accessibilityLabel="iOs share icon" />
              <Text style={styles.textStep}>{copyShareButtonLabel}</Text>
            </View>
            <View style={styles.step}>
              <Image style={styles.icon} source={iosAddHome} resizeMode="contain" accessible accessibilityLabel="iOs add to home icon" />
              <Text style={styles.textStep}>{copyAddHomeButtonLabel}</Text>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default PWAPrompt;
