import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { RewardTypeEnum } from '../../commons/dtos/reward.dto';
import Reward, { RewardProps } from './Reward';
import { RewardData } from './useReward';

interface RewardListProps {
  handleRewardChange: (elem?: number) => void;
  rewards: RewardData[];
}

const RewardsList = ({ handleRewardChange, rewards }: RewardListProps) => {
  const style = StyleSheet.create({
    rewardsContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'center',
      cursor: 'pointer'
    }
  });

  useEffect(() => {
    if (!rewards.length) return;
    const [reward] = rewards;
    if (!(rewards.length === 1 && (reward?.type === RewardTypeEnum.CARD || reward?.type === RewardTypeEnum.CARD_PACK))) return;
    handleRewardChange(reward.index);
  }, [rewards]);

  const possibleRewards = (reward: RewardData): Record<RewardTypeEnum, RewardProps> => ({
    title: {
      source: RewardTypeEnum.TITLE,
      text: 'Titulo',
      handleRewardChange: () => handleRewardChange(reward.index)
    },
    cardPack: {
      source: RewardTypeEnum.CARD_PACK,
      text: 'Pack',
      handleRewardChange: () => handleRewardChange(reward.index)
    },
    avatar: {
      source: RewardTypeEnum.AVATAR,
      text: 'Avatar',
      handleRewardChange: () => handleRewardChange(reward.index)
    },
    card: {
      source: RewardTypeEnum.CARD,
      text: 'Carta',
      handleRewardChange: () => handleRewardChange(reward.index)
    }
  });

  return (
    <View style={style.rewardsContainer}>
      {rewards.map((reward, index) => (
        <Reward {...possibleRewards(reward)[reward.type]} key={index} />
      ))}
    </View>
  );
};

export default RewardsList;
