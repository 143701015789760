import { useCallback, useState } from 'react';
import { Card } from '../commons/dtos/card.dto';
import { wait } from '../commons/utils';
import { getAutoDiscardSelection } from '../commons/utils/gameboard/discard';
import { Deck } from '../screens/GameBoard/gameTypes';

export function useDiscard() {
  const [selectedToDiscardCards, setSelectedToDiscardCards] = useState<number[]>([]);
  const [discardModalText, setDiscardModalText] = useState<string>('Elige qué cartas quieres sustituir');
  const [showDiscardModalButton, setShowDiscardModalButton] = useState<boolean>(true);
  const [discardCards, setDiscardCards] = useState<Card[]>([]);
  const [hiddenCards, setHiddenCards] = useState<number[]>([]);
  const [showDiscard, setShowDiscard] = useState<boolean>(false);
  let _discardCards: Card[] = [];

  /**
   * Replace the current cards on discard modal and show the new deck
   * @param newDeck new deck that will be shown
   * @param oldDeck previus deck
   * @param selectedToDiscardCards cards that was selected to be discard
   * @param handSize initial size of the hand
   */
  const showInitialHand = useCallback(
    async ({
      newDeck,
      oldDeck,
      handSize,
      selectedToDiscardCards
    }: {
      newDeck: Deck;
      oldDeck: Deck;
      selectedToDiscardCards: number[];
      handSize: number;
    }) => {
      setSelectedToDiscardCards([]);
      setDiscardModalText('');
      setShowDiscardModalButton(false);
      _discardCards = oldDeck.slice(0, handSize);
      setDiscardCards(_discardCards);
      setHiddenCards(selectedToDiscardCards);
      await wait(500);
      _discardCards = newDeck.slice(0, handSize);
      setDiscardCards(_discardCards);
      setHiddenCards([]);
      await wait(1500);
    },
    []
  );

  const onConfirmDiscard = useCallback(async (nextTurn: () => Promise<void>) => {
    setSelectedToDiscardCards([]);
    await nextTurn();
    setShowDiscard(false);
  }, []);

  const onReplaceDiscard = useCallback(
    async (handSize: number, onConfirmDiscardMethod: () => [Deck, Deck], nextTurn: () => Promise<void>) => {
      const [oldDeck, newDeck] = onConfirmDiscardMethod();
      if (selectedToDiscardCards.length) await showInitialHand({ handSize, newDeck, oldDeck, selectedToDiscardCards });
      await wait(1500);
      await onConfirmDiscard(nextTurn);
    },
    [selectedToDiscardCards, showInitialHand]
  );

  const initialDiscardStep = useCallback(
    (deck: Deck, handSize: number) => {
      _discardCards = deck.slice(0, handSize);
      setDiscardCards(_discardCards);
      setDiscardModalText('Elige qué cartas quieres sustituir');
      setShowDiscardModalButton(true);
    },
    [discardCards]
  );

  const onGetAutoDiscardSelection = useCallback(
    (actionPoints: number) => {
      const cardIndexesToDiscard = getAutoDiscardSelection(_discardCards, actionPoints);
      setSelectedToDiscardCards(cardIndexesToDiscard);
    },
    [discardCards]
  );

  const handleShowDiscard = useCallback((show: boolean) => setShowDiscard(show), []);
  const handleSelectedToDiscardCards = useCallback((cards: number[]) => setSelectedToDiscardCards(cards), []);

  return {
    onReplaceDiscard,
    initialDiscardStep,
    discardCards,
    hiddenCards,
    discardModalText,
    showDiscardModalButton,
    onConfirmDiscard,
    showDiscard,
    handleShowDiscard,
    selectedToDiscardCards,
    onGetAutoDiscardSelection,
    handleSelectedToDiscardCards
  };
}
