import { useCallback, useMemo } from 'react';
import { Text, TextStyle } from 'react-native';
import { HTMLResult, HTMLTypeElements, parseHTML } from '../utils/parser';
import ParsedHTMLElement from './ParsedHTMLElement';

interface TextWithUrlProps {
  text: string;
  generalTextStyle: TextStyle | TextStyle[];
  htmlStyles: Partial<Record<HTMLTypeElements, TextStyle | TextStyle[]>>;
}

const TextWithHTML = ({ text, generalTextStyle, htmlStyles }: TextWithUrlProps) => {
  const generateText = useCallback((element: HTMLResult, index: number) => {
    const { childs, parent } = element;
    const processedChilds = childs.map((value, index) => <ParsedHTMLElement element={value} index={index} key={index} />);
    return (
      <ParsedHTMLElement element={parent} index={index} key={index}>
        {processedChilds}
      </ParsedHTMLElement>
    );
  }, []);

  const memoedHTML = useMemo(() => parseHTML(text, htmlStyles), [text]);

  const memoedText = useMemo(() => memoedHTML.map(generateText), [memoedHTML, generateText]);

  return <Text style={generalTextStyle}>{memoedText}</Text>;
};

export default TextWithHTML;
