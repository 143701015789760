import axios from 'axios';

export const paginate = <T>(array: T[], limit: number): T[][] => {
  const result: T[][] = [[]];

  array.forEach((element, index) => {
    const position = Math.floor(index / limit);
    if (!result[position]) {
      result[position] = [];
    }
    result[position].push(element);
  });
  return result;
};

export const getPaginated = async <T, K extends { page: number }>({ url, params }: { url: string; params: K }): Promise<T[]> => {
  const { page } = params;
  const { data, headers } = await axios.get<T[]>(`${process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL}${url}`, { params });
  if (headers['pagination-page'] >= headers['pagination-total-pages']) {
    return data.length ? data : [];
  }
  return [...data, ...((await getPaginated({ url, params: { ...params, page: page + 1 } })) as T[])];
};
