import { LinearGradient } from 'expo-linear-gradient';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { useSelector } from 'react-redux';
import { ActorTypeEnum } from '../../../commons/dtos/actor.dto';
import { GameActionInterface, nextGameAction } from '../../../redux/GameBoard/gameAction';
import { GameReaction, nextGameReaction } from '../../../redux/GameBoard/reactions';
import store from '../../../redux/store';
import GameCommunication from '../GameCommunication/GameCommunication';
import MessageContainer from '../MessageContainer/MessageContainer';

interface GameReactionContainerProps {
  isFreeGame: boolean;
}

const GameReactionContainer = ({ isFreeGame }: GameReactionContainerProps) => {
  const reactions = useSelector((state: { reactions: GameReaction }) => state.reactions);
  const gameActionsState = useSelector((state: { gameActions: GameActionInterface }) => state.gameActions);

  const { currentReaction: currentGameReaction, reactions: gameReactions } = reactions;
  const { gameAction, gameActions } = gameActionsState;

  const styles = StyleSheet.create({
    background: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      top: 0,
      left: 0,
      flex: 1
    },

    gradient: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  });

  const nextMessage = () => {
    if (gameActions.length > 0) {
      store.dispatch(nextGameAction());
    } else {
      store.dispatch(nextGameReaction());
    }
  };

  const showAvatarReaction = currentGameReaction && currentGameReaction.type === ActorTypeEnum.AVATAR;
  const showMessageReaction = currentGameReaction && currentGameReaction.type !== ActorTypeEnum.AVATAR;

  if ((!currentGameReaction && gameReactions.length === 0) || gameAction) return null;

  return (
    <>
      <LinearGradient style={styles.gradient} start={{ x: 0, y: 0.5 }} end={{ x: 1, y: 0.5 }} colors={['transparent', 'black']} />
      {showMessageReaction && <GameCommunication message={currentGameReaction} next={nextMessage} isFreeGame={isFreeGame} />}
      {showAvatarReaction && <MessageContainer next={nextMessage} message={currentGameReaction} />}
      <TouchableWithoutFeedback style={styles.background} onPress={nextMessage}>
        <View style={styles.gradient} />
      </TouchableWithoutFeedback>
    </>
  );
};

export default GameReactionContainer;
