import React, { useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, View, Text } from 'react-native';
import { GestureHandlerRootView, TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { titleBackgroundProfile } from '../../../../constants/Images';
import { Profile } from '../../../../redux/Profile/profileReducer';
import PlayerService from '../../../../services/player.service';
import RankingService from '../../../../services/ranking.service';
import { RankingPlayer } from '../../../constants/ranking';

import { ApiError } from '../../../errors/api-error';
import { isNativeOrPWA } from '../../../utils';
import RankingList from '../../RankingList/RankingList';

const playerService = new PlayerService();
const rankingService = new RankingService();

interface UserProfileRankingProps {
  setMessage: Function;
}
const UserProfileRanking = ({ setMessage }: UserProfileRankingProps) => {
  const [rankingType, setrankingType] = useState<'global' | 'top'>('global');
  const [ranking, setRanking] = useState<RankingPlayer[] | null>();
  const isGlobal = rankingType === 'global';
  const player = useSelector(({ profile }: { profile: Profile }) => profile.player);
  const checkShowLoginModal = useSelector((s: { loginModal: { loginModal: boolean } }) => s.loginModal.loginModal);

  useEffect(() => {
    const fetchRanking = async () => (isGlobal && player ? await playerService.getUserRanking() : await rankingService.getRanking());
    fetchRanking()
      .then(data => setRanking(data))
      .catch((err: any) => {
        if (err instanceof ApiError) {
          const ex = err as ApiError;
          setMessage(`[Código: ${ex.httpCode}]: No se pudo recuperar el ranking de usuarios`, `[Detalle]: ${ex.detail}`);
        } else {
          setMessage(`Error: ${err}`);
        }
      });
  }, [rankingType, checkShowLoginModal]);

  if (!ranking) return <></>;

  const styles = StyleSheet.create({
    container: {
      flexGrow: 1,
      maxWidth: isNativeOrPWA ? 420 : 860,
      minWidth: isNativeOrPWA ? 200 : 600,
      paddingHorizontal: 15,
      alignItems: 'center',
      flex: 1,
      marginBottom: -6
    },
    header: {
      flexShrink: 0,
      flexGrow: 0,
      flexBasis: 'auto',
      flexDirection: 'row',
      padding: isNativeOrPWA ? 8 : 16
    },
    title: {
      fontSize: isNativeOrPWA ? 18 : 24,
      color: 'white',
      fontWeight: '600',
      paddingVertical: 10,
      marginRight: isNativeOrPWA ? 18 : 24
    },
    selected: {
      textDecorationColor: 'white',
      textDecorationStyle: 'solid',
      textDecorationLine: 'underline'
    },
    rankingWrapper: {
      flexShrink: 1,
      flexGrow: 1,
      flexBasis: 'auto',
      paddingTop: isNativeOrPWA ? 5 : 55,
      paddingHorizontal: isNativeOrPWA ? 20 : 50,
      maxWidth: isNativeOrPWA ? 420 : 800,
      width: '100%',
      height: '100%'
    }
  });
  const handleTab = (type: 'global' | 'top') => {
    setrankingType(type);
    setRanking(null);
  };
  return (
    <ImageBackground source={titleBackgroundProfile} style={styles.container} resizeMode="stretch">
      <View style={styles.rankingWrapper}>
        <GestureHandlerRootView style={styles.header}>
          <TouchableWithoutFeedback onPress={() => handleTab('global')}>
            <Text style={[styles.title, isGlobal && styles.selected]}>Global</Text>
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback onPress={() => handleTab('top')}>
            <Text style={[styles.title, !isGlobal && styles.selected]}>Top 50</Text>
          </TouchableWithoutFeedback>
        </GestureHandlerRootView>
        {ranking && <RankingList key={rankingType} list={ranking} />}
      </View>
    </ImageBackground>
  );
};

export default UserProfileRanking;
