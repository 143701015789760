import { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import useUnits from 'rxn-units';
import Avatar from '../../../commons/components/Avatar';
import { getAvatar } from '../../../commons/components/Dialogues/utils';
import { rewardAvatarBackground } from '../../../constants/Images';
import { AvatarReveal } from '../useReward';
import { useRewardReveal } from '../useRewardReveal';

interface RewardAvatarOpenProps {
  avatar: Required<AvatarReveal> | null;
  updateAvatar: (avatar: Required<AvatarReveal>) => void;
  handleActiveBack: () => void;
}

const RewardAvatarOpen = ({ avatar, updateAvatar, handleActiveBack }: RewardAvatarOpenProps) => {
  const { vw, vh } = useUnits();

  const { handleSingleReveal } = useRewardReveal(updateAvatar, avatar, handleActiveBack);

  useEffect(() => {
    avatar && handleSingleReveal(avatar);
  }, []);

  const style = StyleSheet.create({
    container: {
      width: vw(40),
      height: vh(40),
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 360,
      maxHeight: 360
    },
    bgImage: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    avatar: {
      width: '95%',
      height: '95%'
    },
    text: {
      color: '#FFF',
      fontWeight: '600',
      fontSize: 24,
      marginTop: vw(3)
    }
  });

  if (avatar) {
    const { avatarName, avatarImage } = avatar;
    return (
      <View style={style.container}>
        <Avatar avatar={getAvatar(avatarImage)} avatarStyle={style.avatar} background={rewardAvatarBackground} bgStyle={style.bgImage} />
        <Text style={style.text}>{avatarName}</Text>
      </View>
    );
  } else {
    return <></>;
  }
};

export default RewardAvatarOpen;
