import { ImageBackground, StyleSheet, View, Text } from 'react-native';
import { whatsappCardImages } from '../../../constants/Images';
import { isNativeOrPWA } from '../../utils';

interface WhatsappCardProps {
  text: string;
}

export const WhatsappCard = ({ text }: WhatsappCardProps) => {
  const { audio, emoticon, goBack, message, sendMessage } = whatsappCardImages;
  const style = StyleSheet.create({
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    goBack: {
      width: isNativeOrPWA ? 18 : 32,
      height: isNativeOrPWA ? 18 : 32,
      marginLeft: isNativeOrPWA ? 12 : 0
    },
    side: {
      flexShrink: 0,
      flexGrow: 1,
      height: isNativeOrPWA ? 12 : 22,
      width: '100%'
    },
    middle: {
      flexGrow: 1,
      minHeight: 60,
      marginTop: -2,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: isNativeOrPWA ? 4 : 16
    },
    whatsappContent: {
      color: '#626262',
      fontWeight: '400',
      fontSize: isNativeOrPWA ? 9 : 13
    },
    whatsappSendMessage: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: isNativeOrPWA ? '65%' : '85%'
    },
    whatsappSendMessageSide: {
      height: isNativeOrPWA ? 18 : 32,
      width: isNativeOrPWA ? 9 : 16,
      flexGrow: 0
    },
    whatsappSendMessageMiddle: {
      minWidth: 60,
      flexGrow: 1,
      marginLeft: -1,
      alignItems: 'center',
      flexDirection: 'row'
    },
    sendMessageText: {
      fontWeight: '400',
      fontSize: isNativeOrPWA ? 11 : 14,
      color: '#C4C4C4'
    }
  });
  return (
    <View>
      <View style={style.row}>
        <View style={{ width: isNativeOrPWA ? '65%' : '85%' }}>
          <ImageBackground source={message.top} style={style.side} resizeMode="stretch" />
          <ImageBackground style={style.middle} source={message.middle} resizeMode="stretch">
            <Text style={style.whatsappContent}>{text}</Text>
          </ImageBackground>
          <ImageBackground source={message.bottom} style={[style.side, { marginTop: -2 }]} resizeMode="stretch" />
        </View>

        <ImageBackground style={style.goBack} source={goBack} />
      </View>

      <View style={[style.row, { marginTop: isNativeOrPWA ? 6 : 12 }, !isNativeOrPWA && { width: '100%' }]}>
        <View style={style.whatsappSendMessage}>
          <ImageBackground source={sendMessage.left} style={[style.whatsappSendMessageSide, { marginRight: -1 }]} resizeMode="stretch" />
          <ImageBackground source={sendMessage.middle} style={style.whatsappSendMessageMiddle} resizeMode="stretch">
            <ImageBackground
              source={emoticon}
              style={{ width: isNativeOrPWA ? 12 : 18, height: isNativeOrPWA ? 12 : 18, marginRight: isNativeOrPWA ? 4 : 16 }}
            />
            <Text style={style.sendMessageText}>Escribir mensaje</Text>
          </ImageBackground>
          <ImageBackground source={sendMessage.right} style={[style.whatsappSendMessageSide, { marginLeft: -1 }]} resizeMode="stretch" />
        </View>
        <ImageBackground source={audio} style={style.goBack} />
      </View>
    </View>
  );
};
