import { StyleSheet, TouchableWithoutFeedback, Text, ImageBackground } from 'react-native';
import { Dialogue } from '../../../commons/dtos/dialog.dto';
import adjust from '../../../commons/styles/TextAdjust';
import { isNativeOrPWA } from '../../../commons/utils';
import { landingSection2Bg } from '../../../constants/Images';
import store from '../../../redux/store';

interface CurtainProps {
  title: string | undefined;
  interlocutor?: Dialogue | null;
  next: Function;
}

const Curtain = ({ next, title }: CurtainProps) => {
  const {
    profile: { player }
  } = store.getState();
  const message = title?.toString().replace('[PLAYER]', player!.playerName || '') || '...';
  const handleStep = () => {
    next();
  };

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1000,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden'
    },
    textTitleStyle: {
      textAlign: 'center',
      fontSize: isNativeOrPWA ? adjust(42) : 64,
      fontFamily: 'Inlanders',
      color: '#ffffff',
      textShadowColor: '#000000',
      textShadowOffset: { width: isNativeOrPWA ? -2 : -3, height: isNativeOrPWA ? 3 : 6 },
      textShadowRadius: 0,
      marginVertical: isNativeOrPWA ? adjust(42) : 64
    }
  });

  return (
    <TouchableWithoutFeedback onPress={handleStep}>
      <ImageBackground source={landingSection2Bg} style={styles.container}>
        <Text style={styles.textTitleStyle}>{message}</Text>
      </ImageBackground>
    </TouchableWithoutFeedback>
  );
};

const CurtainWrapper = ({ interlocutor, next, title: message }: CurtainProps) =>
  interlocutor === null ? <Curtain interlocutor={interlocutor} next={next} title={message} /> : null;

export default CurtainWrapper;
