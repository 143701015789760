import React, { ReactNode } from 'react';
import { View, Text, StyleSheet, ViewStyle } from 'react-native';
import adjust from '../../styles/TextAdjust';
import { isNativeOrPWA } from '../../utils';

interface ConfigItemProps {
  label: string;
  children: ReactNode;
  parentStyle: ViewStyle;
}

const ConfigItem = ({ children, label, parentStyle }: ConfigItemProps) => {
  const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: isNativeOrPWA ? 16 : 24
    },
    optionText: {
      fontSize: adjust(18),
      fontWeight: '500',
      color: '#FFF',
      width: 185
    }
  });
  return (
    <View style={styles.row}>
      <Text style={styles.optionText}>{label}</Text>
      <View style={parentStyle}>{children}</View>
    </View>
  );
};

export default ConfigItem;
