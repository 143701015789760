import { ImageBackground, StyleSheet } from 'react-native';
import { tagExplanationContainerBorderBottom, tagExplanationContainerBorderTop } from '../../../constants/Images';
import { isNativeOrPWA } from '../../utils';
import { useRatioDimensions } from '../AspectRatioView/useRatioDimensions';
import { View, Text } from '../Themed';
import { CardSizesEnum, selectSize } from './utils';

export interface TagInfoProps {
  content: string;
  tagName: string;
  cardSize: CardSizesEnum;
}

const TagInfo = ({ content, tagName, cardSize }: TagInfoProps) => {
  const { rw } = useRatioDimensions();
  const length = tagName.length + content.length;
  const styles = StyleSheet.create({
    tagInfoContainer: {
      position: 'relative',
      top: 0,
      bottom: 0,
      height: isNativeOrPWA ? (length > 70 ? 50 : 32) : length > 70 ? 95 : 70,
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 0,
      lineHeight: 0,
      padding: 0,
      margin: 0,
      display: 'flex'
    },
    tagInfoContainerBorder: {
      width: '100%',
      top: 0,
      bottom: 0,
      padding: 0,
      margin: 0,
      height: '16%',
      position: 'relative',
      display: 'flex'
    },
    tagInfoContainerBorderTop: {
      position: 'relative',
      top: 0,
      bottom: 0,
      fontSize: 0,
      lineHeight: 0,
      padding: 0,
      marginBottom: isNativeOrPWA ? -1 : -2,
      margin: 0,
      display: 'flex'
    },
    tagInfoContainerBorderBottom: {
      position: 'relative',
      top: 0,
      bottom: 0,
      fontSize: 0,
      lineHeight: 0,
      display: 'flex',
      marginTop: isNativeOrPWA ? -1 : -2,
      margin: 0,
      padding: 0
    },
    tagInfoContainerBorderImage: {
      width: '100%',
      height: '100%',
      fontSize: 0,
      lineHeight: 0,
      display: 'flex',
      margin: 0,
      padding: 0
    },
    tagInfoContainerMiddle: {
      heigh: isNativeOrPWA ? (length > 70 ? 30 : 18) : length > 70 ? 50 : 40,
      display: 'flex',
      gap: 0,
      justifyContent: 'center',
      backgroundColor: '#687987',
      borderColor: '#82A1B9',
      borderLeftWidth: rw(0.15),
      borderRightWidth: rw(0.15),
      fontSize: 0,
      lineHeight: 0,
      margin: 0,
      padding: 0,
      width: '100%',
      marginTop: 1
    },
    tagInfo: {
      textAlignVertical: 'center',
      fontWeight: '500',
      letterSpacing: 0,
      textAlign: 'left'
    },
    tagInfoBold: {
      fontSize: selectSize(
        cardSize,
        tagName.length > 30 ? rw(0.7) : rw(0.8),
        tagName.length > 30 ? rw(0.7) : rw(0.8),
        tagName.length > 20 ? rw(0.6) : rw(0.7),
        rw(0.7)
      ) as number,
      fontWeight: '900',
      textAlign: 'left',
      position: 'relative',
      left: -rw(0.2)
    },
    tagInfoProp: {
      fontSize: selectSize(cardSize, rw(0.8), rw(0.8), rw(0.7), rw(0.7)) as number,
      color: '#FFFFFF',
      paddingHorizontal: rw(1),
      paddingVertical: rw(0.2)
    }
  });

  return (
    <View style={styles.tagInfoContainer}>
      <View style={[styles.tagInfoContainerBorder, styles.tagInfoContainerBorderTop]}>
        <ImageBackground style={styles.tagInfoContainerBorderImage} source={tagExplanationContainerBorderTop} resizeMode={'stretch'} />
      </View>
      <View style={styles.tagInfoContainerMiddle}>
        <Text selectable={false} style={[styles.tagInfo, styles.tagInfoProp]}>
          <Text selectable={false} style={styles.tagInfoBold}>
            {tagName}
          </Text>
          <Text selectable={false}>{content}</Text>
        </Text>
      </View>
      <View style={[styles.tagInfoContainerBorder, styles.tagInfoContainerBorderBottom]}>
        <ImageBackground style={styles.tagInfoContainerBorderImage} source={tagExplanationContainerBorderBottom} resizeMode={'stretch'} />
      </View>
    </View>
  );
};

export default TagInfo;
