import { ReactNode } from 'react';
import { View, StyleSheet, ImageSourcePropType } from 'react-native';
import Svg, { ClipPath, Defs, G, Path, Image } from 'react-native-svg';
import useUnits from 'rxn-units';
import { defaultDeckImage } from '../../../constants/Images';
import { isNativeMobile } from '../../utils';

interface PolyShapeImageProps {
  imageUri?: ImageSourcePropType;
  children: ReactNode;
  polyType?: number;
}

const PolyShapeImage = ({ imageUri = defaultDeckImage, children, polyType = 0 }: PolyShapeImageProps) => {
  const { vw } = useUnits();

  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      overflow: 'hidden',
      cursor: 'pointer',
      height: vw(10.5),
      width: vw(21)
    },
    veil: {
      opacity: isNativeMobile ? 0.65 : 0.5
    },
    childrenContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  let path;
  if (polyType === 1) {
    path = 'M333.5 143L355.5 127.5L360.479 86.5V30.2461L340.5 0H14.7978L-1 35L2 79L-1 120L22.9965 143H333.5Z';
  } else if (polyType === 2) {
    path = 'M345.148 143L360.479 119.445V30.2461L356.88 9.23444L339.016 0H14.7978L0 22.0154V118.91L22.9965 143H345.148Z';
  } else if (polyType === 3) {
    path = 'M7.5 0L0 23.1257V65.5V95.5L3.53476 131.33L21.0777 140.397H339.468L354 118.782V35L345 0H130L109.5 7.5L54.5 0H7.5Z';
  } else if (polyType === 0) {
    path = 'M333.5 143L360.479 130.5V30.2461L342.5 3L277 0H14.7978L-1 30.2461V79.5L7.5 127.5L22.9965 143H333.5Z';
  }

  return (
    <View style={styles.container}>
      <Svg fill="none" preserveAspectRatio="none" height="100%" viewBox="0 0 365 151" width="100%">
        <Defs>
          <ClipPath id={`clipPath-${polyType}`}>
            <Path d={path} />
          </ClipPath>
        </Defs>
        <G clipPath={`url(#clipPath-${polyType})`}>
          <Image x="0" y="0" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" href={imageUri} />
        </G>
      </Svg>
      <Svg
        style={[styles.childrenContainer, styles.veil]}
        fill="#000000"
        preserveAspectRatio="none"
        height="100%"
        viewBox="0 0 365 151"
        width="100%"
      >
        <Path d={path} />
      </Svg>
      <View style={styles.childrenContainer}>{children}</View>
    </View>
  );
};

export default PolyShapeImage;
