import axios from 'axios';
import { Card } from '../commons/dtos/card.dto';
import { TagsEnum } from '../commons/dtos/tag.dto';
import { cards } from '../constants/Images';

const base = process.env.FAKES_OVER_SVC_URL ?? FAKES_OVER_SVC_URL;

export default class CardsService {
  getCards = async () => {
    let cardsList: Card[] = [];
    let cardsPageContent: Card[] = [];
    let page = 1;

    while (true) {
      const params = { page };
      cardsPageContent = (await axios.get(`${base}/cards`, { params })).data.map((card: Card) => {
        card.imageSource = cards[card.illustration as string];
        const isUndetectable = card?.tags?.some(tag => tag.tagName === TagsEnum.UNDETECTABLE);
        if (isUndetectable) {
          card.isUndetectable = isUndetectable;
        }
        return card;
      });

      if (!cardsPageContent.length) break;

      cardsList = [...cardsList, ...cardsPageContent];
      page += 1;
    }
    return cardsList;
  };

  getCardById = async (id: string): Promise<Card> => {
    return (await axios.get(`${base}/cards/${id}`)).data;
  };
}
