import { StyleSheet, View } from 'react-native';
import SideCharacter from '../../../commons/components/Dialogues/SideCharacter';
import useDialogue from '../../../commons/components/Dialogues/useDialogue';
import { prepareDialogue } from '../../../commons/components/Dialogues/utils';
import { Dialogue } from '../../../commons/dtos/dialog.dto';
import store from '../../../redux/store';

interface InterCommunicatorProps {
  current: Dialogue;
  interlocutor?: Dialogue | null;
  next: () => void;
  isFreeGame: boolean;
}

const InterCommunicator = ({ current, next, isFreeGame }: InterCommunicatorProps) => {
  const {
    profile: { player }
  } = store.getState();
  const reactionCurrent = prepareDialogue(current, isFreeGame, player);
  const { dataLeft, dataRight, renderText, actual }: any = useDialogue({
    current: reactionCurrent,
    next,
    // The interlocutor has to be null for issue #FKOVER-227
    interlocutor: null
  });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden'
    }
  });

  return (
    <View style={styles.container}>
      <SideCharacter data={dataLeft} actual={actual} render={renderText} />
      <SideCharacter data={dataRight} actual={actual} render={renderText} />
    </View>
  );
};

const InterCommunicatorWrapper = ({ current, next, isFreeGame }: InterCommunicatorProps) =>
  !!current && <InterCommunicator current={current} next={next} isFreeGame={isFreeGame} />;

export default InterCommunicatorWrapper;
